import React, { useCallback, useEffect, useState } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import isEmpty from 'lodash/fp/isEmpty';

export interface ShapeProps {
  position:
    | 'left-far'
    | 'left-near'
    | 'left-near-title'
    | 'left-terms-partial'
    | 'right-terms-overflow'
    | 'right-far'
    | 'right-far-mid'
    | 'right-near'
    | 'left-top'
    | 'right-top'
    | 'right-top-twoTextTable'
    | 'left-about-banner'
    | 'right-about-banner'
    | 'right-far-top'
    | 'right-far-top-job-details'
    | 'right-far-top-cash-prize'
    | 'right-home-ins'
    | 'right-home-team'
    | 'left-home-ins'
    | 'left-jobs'
    | 'left-jobs-MK'
    | 'left-bottom-team'
    | 'left-bottom'
    | 'left-twoTextTable'
    | 'right-pay-gap'
    | 'left-pay-gap'
    | 'top-right'
    | 'top-right-homepage-contact'
    | 'bottom-right'
    | 'left-header'
    | 'right-header'
    | 'left-product-table'
    | 'top-right-product-table'
    | 'left-cookies-redesign'
    | 'top-right-terms'
    | 'left-prize-terms'
    | 'left-not-found'
    | 'right-not-found'
    | 'left-pet-product-table'
    | 'right-policy-books'
    | 'right-pet-terms'
    | 'left-pet-terms'
    | 'left-policy-books'
    | 'top-left-homepage-contact'
    | 'top-right-renewals';
}

const Shape = ({ position }: ShapeProps) => {
  const [style, setStyle] = useState<React.CSSProperties>({});
  const { isMobile, isTablet, isTabletLarge, isDesktop } = useBreakpoint();
  const buildPositionStyle = useCallback(
    (
      shapePosition: ShapeProps['position'],
      isMobileBreakpoint: boolean,
      isTabletBreakpoint: boolean,
      isTabletLargeBreakpoint: boolean,
      isDesktopBreakpoint: boolean
    ) => {
      switch (shapePosition) {
        case 'right-top':
          setStyle({
            ...style,
            height: '80%',
            width: '574.48px',
            right: '-300px',
            top: '18%',
            transform: 'translateY(-50%)',
            maxHeight: '350px',
          });
          break;
        case 'right-top-twoTextTable':
          setStyle({
            ...style,
            height: '80%',
            width: '574.48px',
            right: '-350px',
            top: '25%',
            transform: 'translateY(-50%)',
            maxHeight: '350px',
          });
          break;
        case 'left-top':
          setStyle({
            ...style,
            height: '80%',
            width: '574.48px',
            left: '-300px',
            top: '-30%',
            transform: 'translateY(-50%)',
          });
          break;

        case 'left-far':
          setStyle({
            ...style,
            left: '-280px',
            top: '50%',
            height: '300px',
            width: '574.48px',
            transform: 'translateY(-50%)',
            zIndex: 0,
          });
          break;

        case 'left-near':
          setStyle({
            ...style,
            height: '80%',
            width: '574.48px',
            left: '',
            top: '50%',
            transform: 'translateY(-50%)',
          });
          break;

        case 'left-near-title':
          setStyle({
            ...style,
            left: '',
            top: '50%',
            height: '600px',
            width: '574.48px',
            transform: 'translateY(-50%)',
          });
          break;

        case 'left-terms-partial':
          setStyle({
            ...style,
            height: '600px',
            width: '403px',
            left: '-260px',
            top: '25%',
            maxHeight: '',
            transform: 'translateY(-50%)',
          });
          break;

        case 'right-terms-overflow':
          setStyle({
            ...style,
            height: '600px',
            width: '403px',
            right: '-265px',
            top: '-105px',
            maxHeight: '',
            zIndex: 1,
            transform: 'translateY(-50%)',
          });
          break;

        case 'right-far':
          setStyle({
            ...style,
            height: '80%',
            width: '574.48px',
            right: '-380px',
            top: '80%',
            transform: 'translateY(-50%)',
            zIndex: 0,
          });
          break;

        case 'right-far-mid':
          setStyle({
            ...style,
            height: '80%',
            width: '574.48px',
            right: '-380px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 0,
          });
          break;

        case 'right-far-top':
          setStyle({
            ...style,
            height: '80%',
            width: '574.48px',
            right: '-380px',
            top: '30%',
            transform: 'translateY(-50%)',
            zIndex: 0,
          });
          break;

        case 'right-far-top-cash-prize':
          setStyle({
            ...style,
            height: '80%',
            width: '636.48px',
            right: '-380px',
            top: '39%',
            transform: 'translateY(-50%)',
            zIndex: 0,
          });
          break;

        case 'right-far-top-job-details':
          setStyle({
            ...style,
            right: '-320px',
            top: '5%',
            transform: 'translateY(-50%)',
            height: '250px',
            width: '574.48px',
            zIndex: 0,
          });
          break;

        case 'right-near':
          setStyle({
            ...style,
            height: '80%',
            width: '574.48px',
            right: '-75px',
            top: '50%',
            transform: 'translateY(-50%)',
          });
          break;
        case 'right-about-banner':
          setStyle({
            ...style,
            right: isMobileBreakpoint ? '-350px' : '-350px',
            top: isMobileBreakpoint ? '240px' : '400px',
            height: isMobileBreakpoint ? '400px' : '600px',
            width: '574.48px',
            display: !isDesktopBreakpoint ? 'none' : '',
            transform: 'translateY(-50%)',
            zIndex: 0,
          });
          break;
        case 'right-home-ins':
          setStyle({
            ...style,
            right: isMobileBreakpoint ? '-350px' : '-300px',
            top: isMobileBreakpoint ? '240px' : '400px',
            height: isMobileBreakpoint ? '400px' : '590px',
            width: '574.48px',
            display: isTabletBreakpoint ? 'none' : '',
            transform: 'translateY(-50%)',
            zIndex: 0,
          });
          break;
        case 'right-home-team':
          setStyle({
            ...style,
            right: isMobileBreakpoint
              ? '-350px'
              : isTabletBreakpoint
              ? '-200px'
              : isTabletLargeBreakpoint
              ? '-400px'
              : '-350px',
            top: isMobileBreakpoint ? '240px' : '400px',
            height: isMobileBreakpoint ? '400px' : '590px',
            width: '574.48px',
            display: isTabletBreakpoint ? 'none' : '',
            transform: 'translateY(-50%)',
            zIndex: 0,
          });
          break;
        case 'left-home-ins':
          setStyle({
            ...style,
            left: isMobileBreakpoint ? '-350px' : '-380px',
            top: isMobileBreakpoint ? '250px' : '400px',
            height: isMobileBreakpoint ? '450px' : '600px',
            width: '574.48px',
            transform: 'translateY(-50%)',
            zIndex: 0,
            display: isTabletBreakpoint ? 'none' : '',
          });
          break;
        case 'left-bottom':
          setStyle({
            ...style,
            left: isMobileBreakpoint ? '-350px' : '-380px',
            bottom: isMobileBreakpoint ? '150px' : '200px',
            height: isMobileBreakpoint ? '450px' : '600px',
            width: '574.48px',
            zIndex: 0,
          });
          break;
        case 'left-bottom-team':
          setStyle({
            ...style,
            left: isMobileBreakpoint ? '-330px' : '-380px',
            bottom: isMobileBreakpoint ? '200px' : '100px',
            height: isMobileBreakpoint ? '300px' : '600px',
            width: '574.48px',
            zIndex: 0,
          });
          break;
        case 'left-about-banner':
          setStyle({
            ...style,
            left: isMobileBreakpoint ? '-350px' : '-350px',
            top: isMobileBreakpoint ? '250px' : '400px',
            height: isMobileBreakpoint ? '450px' : '600px',
            width: '574.48px',
            transform: 'translateY(-50%)',
            zIndex: 0,
            display: !isDesktopBreakpoint ? 'none' : '',
          });
          break;
        case 'left-jobs':
          setStyle({
            ...style,
            left: isMobileBreakpoint ? '-350px' : '-150px',
            top: isMobileBreakpoint ? '520px' : '740px',
            height: isMobileBreakpoint ? '450px' : '600px',
            width: '574.48px',
            transform: 'translateY(-50%)',
            zIndex: 0,
            display: isTabletBreakpoint ? 'none' : '',
          });
          break;
        case 'left-jobs-MK':
          setStyle({
            ...style,
            left: isMobileBreakpoint ? '-350px' : '-150px',
            top: isMobileBreakpoint ? '620px' : '850px',
            height: isMobileBreakpoint ? '450px' : '600px',
            width: '574.48px',
            transform: 'translateY(-50%)',
            zIndex: 0,
            display: isTabletBreakpoint ? 'none' : '',
          });
          break;
        case 'right-pay-gap':
          setStyle({
            ...style,
            right: isMobileBreakpoint ? '-350px' : '-300px',
            top: isMobileBreakpoint ? '240px' : '600px',
            height: isMobileBreakpoint ? '400px' : '590px',
            width: '574.48px',
            display: isTabletBreakpoint ? 'none' : '',
            transform: 'translateY(-50%)',
            zIndex: 0,
          });
          break;
        case 'left-pay-gap':
          setStyle({
            ...style,
            left: isMobileBreakpoint ? '-350px' : '-330px',
            bottom: isMobileBreakpoint ? '150px' : '200px',
            height: isMobileBreakpoint ? '450px' : '600px',
            width: '574.48px',
            zIndex: 0,
          });
          break;
        case 'left-twoTextTable':
          setStyle({
            ...style,
            left: isMobileBreakpoint ? '-350px' : '-330px',
            bottom: isMobileBreakpoint
              ? '200px'
              : isDesktopBreakpoint
              ? '100px'
              : '50px',
            height: isMobileBreakpoint ? '450px' : '600px',
            width: '574.48px',
            zIndex: 0,
          });
          break;
        case 'left-product-table':
          setStyle({
            ...style,
            left: '-240px',
            bottom: 0,
            height: '600px',
            width: '403px',
            zIndex: 0,
            maxHeight: '',
          });
          break;
        case 'top-right':
          setStyle({
            ...style,
            right: '-300px',
            top: '-370px',
            height: '437px',
            width: '574.48px',
            zIndex: 102,
            marginBottom: '-437px',
            position: 'relative',
            display: isMobileBreakpoint ? 'none' : 'block',
            float: 'right',
          });
          break;
        case 'top-right-homepage-contact':
          setStyle({
            ...style,
            right: '-250px',
            top: '-430px',
            height: '650px',
            width: '437px',
            zIndex: 102,
            position: 'absolute',
            maxHeight: '',
            display: isDesktopBreakpoint ? 'block' : 'none',
          });
          break;
        case 'bottom-right':
          setStyle({
            ...style,
            right: '-255px',
            top: '360px',
            height: '600px',
            width: '403px',
            position: 'absolute',
            zIndex: 101,
            display:
              isMobileBreakpoint || isTabletBreakpoint ? 'none' : 'block',
          });
          break;
        case 'left-header':
          setStyle({
            ...style,
            left: isMobileBreakpoint
              ? '-78px'
              : isTabletBreakpoint
              ? '-150px'
              : '-240px',
            top: isMobileBreakpoint ? '-3px' : '0px',
            height: isMobileBreakpoint
              ? '180px'
              : isTabletBreakpoint
              ? '350px'
              : '536px',
            width: isMobileBreakpoint
              ? '121px'
              : isTabletBreakpoint
              ? '235px'
              : '360px',
          });
          break;
        case 'right-header':
          setStyle({
            ...style,
            right: isMobileBreakpoint
              ? '-70px'
              : isTabletBreakpoint
              ? '-110px'
              : '-180px',
            bottom: isMobileBreakpoint
              ? '70px'
              : isTabletBreakpoint
              ? '115px'
              : '15px',
            height: isMobileBreakpoint
              ? '160px'
              : isTabletBreakpoint
              ? '250px'
              : '424px',
            width: isMobileBreakpoint
              ? '107px'
              : isTabletBreakpoint
              ? '168px'
              : '285px',
          });
          break;
        case 'top-right-product-table':
          setStyle({
            ...style,
            right: isTabletBreakpoint ? '-150px' : '-300px',
            top: isTabletBreakpoint ? '-170px' : '-290px',
            height: isTabletBreakpoint ? '360px' : '700px',
            width: isTabletBreakpoint ? '241px' : '470px',
            zIndex: 102,
            maxHeight: '',
            display: isMobileBreakpoint ? 'none' : 'block',
          });
          break;
        case 'left-cookies-redesign':
          setStyle({
            ...style,
            left: '79px',
            top: '515px',
            height: '520px',
            width: '369px',
            transform: 'translateY(-50%)',
          });
          break;
        case 'top-right-terms':
          setStyle({
            ...style,
            height: isTabletBreakpoint ? '280px' : '390px',
            width: isTabletBreakpoint ? '189px' : '263px',
            right: isTabletBreakpoint ? '-105px' : '-140px',
            top: isTabletBreakpoint ? '50px' : '60px',
          });
          break;
        case 'left-prize-terms':
          setStyle({
            ...style,
            height: '202px',
            width: '300px',
            left: '-160px',
            bottom: '60px',
          });
          break;
        case 'left-not-found':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '160px'
              : isTabletBreakpoint
              ? '180px'
              : '300px',
            width: isMobileBreakpoint
              ? '108px'
              : isTabletBreakpoint
              ? '121px'
              : '202px',
            left: isMobileBreakpoint
              ? '-65px'
              : isTabletBreakpoint
              ? '-70px'
              : '-120px',
            top: isMobileBreakpoint
              ? '140px'
              : isTabletBreakpoint
              ? '195px'
              : '300px',
            transform: 'translateY(-50%)',
          });
          break;
        case 'right-not-found':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '160px'
              : isTabletBreakpoint
              ? '180px'
              : '300px',
            width: isMobileBreakpoint
              ? '108px'
              : isTabletBreakpoint
              ? '121px'
              : '202px',
            right: isMobileBreakpoint
              ? '-70px'
              : isTabletBreakpoint
              ? '-65px'
              : '-110px',
            top: isMobileBreakpoint
              ? '100px'
              : isTabletBreakpoint
              ? '120px'
              : '190px',
            transform: 'translateY(-50%)',
          });
          break;
        case 'left-pet-product-table':
          setStyle({
            ...style,
            left: '-240px',
            top: '800px',
            height: '600px',
            width: '403px',
            zIndex: 0,
            display:
              isMobileBreakpoint || isTabletBreakpoint ? 'none' : 'block',
          });
          break;
        case 'right-pet-terms':
          setStyle({
            ...style,
            height: '390px',
            width: '262px',
            right: '-150px',
            top: '340px',
            display:
              isMobileBreakpoint || isTabletBreakpoint ? 'none' : 'block',
          });
          break;
        case 'left-pet-terms':
          setStyle({
            ...style,
            height: '390px',
            width: '262px',
            left: '-150px',
            top: '1350px',
            display:
              isMobileBreakpoint || isTabletBreakpoint ? 'none' : 'block',
          });
          break;
        case 'right-policy-books':
          setStyle({
            ...style,
            height: '300px',
            width: '202px',
            right: '-125px',
            top: '280px',
            maxHeight: '',
            zIndex: 1,
            transform: 'translateY(-50%)',
          });
          break;
        case 'left-policy-books':
          setStyle({
            ...style,
            height: '390px',
            width: '262px',
            left: '-150px',
            bottom: '130px',
            maxHeight: '',
            zIndex: 1,
          });
          break;
        case 'top-left-homepage-contact':
          setStyle({
            ...style,
            left: '-280px',
            bottom: '735px',
            height: '600px',
            width: '403px',
            zIndex: 102,
            position: 'absolute',
            maxHeight: '',
            display: isDesktopBreakpoint ? 'block' : 'none',
          });
          break;
        case 'top-right-renewals':
          setStyle({
            ...style,
            right: '-240px',
            top: '55px',
            height: '650px',
            width: '380px',
            zIndex: 102,
            position: 'absolute',
            maxHeight: '',
            display: isDesktopBreakpoint ? 'block' : 'none',
          });
          break;
        default:
          break;
      }
    },
    [setStyle, style]
  );

  useEffect(() => {
    buildPositionStyle(position, isMobile, isTablet, isTabletLarge, isDesktop);
  }, [position, isMobile, isTablet, isTabletLarge, isDesktop]);

  if (isEmpty(style)) {
    return null;
  }

  return (
    <div
      className={`${
        position === 'left-top' || 'right-top' ? 'absolute block' : 'hidden '
      } lg:absolute lg:block w-smallShape h-smallShape md:w-largeShape md:h-largeShape`}
      style={{ maxHeight: '560px', ...style }}
      data-testid="shape"
    >
      <svg
        id="Layer_1"
        width="100%"
        height="100%"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 396 588.64497"
      >
        <path
          style={{ fill: '#ff00a0' }}
          d="M368.39726,137.52518,166.46426,12.77076s-36.89893-21.3077-67.61117,10.491S87.17119,97.35,87.17119,97.35l29.60275,68.72065a62.96162,62.96162,0,0,1,5.77137,25.31557c0,13.80235-4.71413,27.54648-4.71413,27.54648L86.114,289.76786s-17.60081,45.01585,14.4589,73.25549c33.11694,30.35412,63.77692,10.26654,63.77692,10.26654L368.39726,247.47823S398,231.61962,398,191.87979C398,158.67,368.39726,137.52518,368.39726,137.52518Z"
          transform="translate(-2 -5.78265)"
        />
        <path
          style={{ fill: '#6214be' }}
          d="M31.60274,462.68509l201.933,124.75442s36.89893,21.30771,67.61117-10.491,11.6819-74.0883,11.6819-74.0883l-29.60275-68.72065a62.9616,62.9616,0,0,1-5.77137-25.31556c0-13.80235,4.71413-27.54648,4.71413-27.54648l31.71723-70.83514s17.60081-45.01585-14.4589-73.25549c-33.11694-30.35411-63.77692-10.26654-63.77692-10.26654L31.60274,352.732S2,368.59066,2,408.33048C2,441.54027,31.60274,462.68509,31.60274,462.68509Z"
          transform="translate(-2 -5.78265)"
        />
        <path
          style={{ fill: '#620077' }}
          d="M318.59658,278.18605l-154.243,95.10382s-30.6659,20.08862-63.77815-10.26255c-13.84585-12.19124-18.434-27.51908-19.17507-40.99951L235.65351,226.924s30.65575-20.08862,63.77815,10.26255C313.27751,249.38794,317.85552,264.70568,318.59658,278.18605Z"
          transform="translate(-2 -5.78265)"
        />
      </svg>
    </div>
  );
};

export default Shape;
