import React from 'react';

import { PetComplainDetailsDataType } from '../../pages/how-to-complain';
import {
  ComplaintsBackgroundContainer,
  ComplaintsCardsContainer,
  ComplaintsCardsHeader,
  ComplaintsDetailsContainer,
  ComplaintsDetailsWrapper,
  ComplaintsHeaderContainer,
  ComplaintsSectionHeader,
} from './PetComplaintsDetailsBanner.style';

const PetComplaintsDetailsBanner = ({
  data,
}: {
  data: PetComplainDetailsDataType;
}) => {
  return (
    <ComplaintsBackgroundContainer $backgroundColor={data.bgColor}>
      <ComplaintsDetailsContainer>
        <ComplaintsDetailsWrapper>
          <ComplaintsHeaderContainer>
            <ComplaintsSectionHeader data-testid="complaint-section-header">
              {data.sectionHeader}
            </ComplaintsSectionHeader>
          </ComplaintsHeaderContainer>
          <ComplaintsCardsContainer>
            {data.cards.map((item) => (
              <div key={item.header} data-testid="complaint-container">
                <ComplaintsCardsHeader>{item.header}</ComplaintsCardsHeader>

                {item.body}
              </div>
            ))}
          </ComplaintsCardsContainer>
        </ComplaintsDetailsWrapper>
      </ComplaintsDetailsContainer>
    </ComplaintsBackgroundContainer>
  );
};

export default PetComplaintsDetailsBanner;
