import React from 'react';

import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../components/headerBanner/HeaderBanner';
import ImageWithCarouselReveal from '../components/imageWithCarouselReveal/ImageWithCarouselReveal';
import ImageWithTextReveal, {
  ImageWithTextRevealType,
} from '../components/imageWithTextReveal/ImageWithTextReveal';
import TwoColumnTextBanner, {
  TwoColumnTextBannerType,
} from '../components/twoColumnTextBanner/TwoColumnTextBanner';
import useDisclaimer from '../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../images/about_us_sm.png`}
        alt={`Insurance for people like you`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../images/about_us_md.png`}
        alt={`Insurance for people like you`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../images/about_us_lg.png`}
      alt={`Insurance for people like you`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const aboutusPageDisclaimer = (
  <>
    <br />
    Δ The number of customers insured by Policy Expert - July 2024.
    <br />
    *Policy Expert is rated the number 1 home insurance provider in the UK on
    independent review website – Reviewcentre.com. Based on 58,736 customer
    reviews and a 96.6% recommendation rating, June 2024.
  </>
);

const AboutUsPage: React.FC<PageProps> = () => {
  useDisclaimer(aboutusPageDisclaimer);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const AboutUsImageQuery = useStaticQuery(graphql`
    query AboutUsImages {
      Kidsout: imageSharp(original: { src: { regex: "/careers_kidsout/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      OurPeople: imageSharp(original: { src: { regex: "/ourpeople/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const aboutUsTwoColumnTextBannerData: TwoColumnTextBannerType = {
    bgColor: 'bg-pxWhite',
    textColor: 'text-pxBlack',
    headerText: 'Why Policy Expert?',
    subText: [
      'We felt that insurance had become a bit lazy. Behind the times. Stuck in a rut, fairly scary and needlessly confusing.',
      'So we launched Policy Expert in 2011. We wanted to create a different kind of insurance company. That understood what people like you wanted, and that looked after them better. ',
      'We think we’re doing alright. We’re now trusted by over 1.5 million home & car insurance customers across the UK. Δ',
      'Apparently, you think we’re doing alright too. Because you’ve rated us the No. 1 UK home insurance provider on Review Centre for the last 10 years.*',
      'That’s really great! But we can do better. That’s why we constantly invest back into our business, listening to your feedback and improving all the time. Jargon-free policies. Online claims processes. UK based customer service.',
      'All of these because we’re a service for you that’s driven by you.',
    ],
    btns: [{ label: 'Awards', url: '/about-us/awards', primary: true }],
  };

  const aboutUsImageWithCarouselRevealData: ImageWithTextRevealType = {
    imageLeft: false,
    imgName: 'ourpeople.png',
    imageFile: AboutUsImageQuery.OurPeople.gatsbyImageData,
    bgColor: 'bg-pxGrey',
    headerText: 'Our people',
    subText: [
      'We’re building the future of insurance.',
      'And we believe that future revolves around our people.',
      'When you put people at the heart of everything you do - success will follow.',
      'So we’re creating a culture of inclusivity where every employee feels welcome, and different perspectives are valued and encouraged. ',
    ],
    btn: {
      label: 'Meet the team',
      url: '/about-us/careers',
    },
  };

  const aboutUsImageWithTextRevealData: ImageWithTextRevealType = {
    imageLeft: true,
    bgColor: 'bg-pxGrey',
    imgName: 'careers_kidsout.jpg',
    imageFile: AboutUsImageQuery.Kidsout.gatsbyImageData,
    headerText: 'Our charity work',
    subText: [
      'Policy Expert are delighted to support KidsOut, a fantastic charity that provides disadvantaged children across the UK with exciting memories to cherish.',
      'Whether helping out in simple ways or volunteering our time, skills and creative abilities, Policy Expert employees have the opportunity to make a meaningful difference in the lives of others and have an enduring impact on the children helped by KidsOut.',
      'Internally we run a scheme called Policy Expert Giveback, a programme created by a dedicated group of employees (with the backing of our management team) which helps support the passion of our employees - to make a positive impact.',
    ],
    btn: {
      label: 'More about KidsOut',
      url: 'https://www.kidsout.org.uk/',
    },
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxMint"
        headerImage={headerImage}
        maxHeight="max-h-750"
        styling="pt-18px px-26px md:pt-25px md:px-28 lg:pt-59px lg:px-6 lg:max-w-964"
      />

      <TwoColumnTextBanner data={aboutUsTwoColumnTextBannerData} />
      <ImageWithCarouselReveal
        shape
        shapePosition="right-about-banner"
        data={aboutUsImageWithCarouselRevealData}
      />
      <ImageWithTextReveal
        shape
        shapePosition="left-about-banner"
        data={aboutUsImageWithTextRevealData}
      />
    </>
  );
};

export default AboutUsPage;

export const Head: HeadFC = () => (
  <>
    <title>About us | Policy Expert</title>
    <meta
      name="description"
      content="Read more about Policy Expert, what we do and what we stand for. Policy Expert, insurance for people like you."
    />
    <html lang="en" />
  </>
);
