import { css, styled } from 'styled-components';

import Car from '../../../static/svgIcons/Car.svg';
import House from '../../../static/svgIcons/House.svg';
import Key from '../../../static/svgIcons/Key.svg';
import Leak from '../../../static/svgIcons/Leak.svg';
import Hammer from '../../../static/svgIcons/LegalExpensesIcon.svg';
import { colors, fontFamilyCamptonMedium } from '../../styles/sharedStyled';

export const SvgButtonColors = css`
  stroke: ${colors.purple7};
  fill: ${colors.purple7};
`;

export const LeakSvg = styled(Leak)`
  ${SvgButtonColors};
  width: 32px;
  height: 30px;

  @media (min-width: 768px) {
    width: 30px;
    height: 28px;
  }

  @media (min-width: 1024px) {
    width: 37px;
    height: 35px;
    margin-right: 2px;
  }
`;

export const HouseSvg = styled(House)`
  ${SvgButtonColors};
  width: 33px;
  height: 35px;

  @media (min-width: 768px) {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 1024px) {
    width: 38px;
    height: 40px;
    margin-right: 2px;
  }
`;

export const KeySvg = styled(Key)`
  ${SvgButtonColors};
  width: 38px;
  height: 30px;

  @media (min-width: 768px) {
    width: 35px;
    height: 28px;
  }

  @media (min-width: 1024px) {
    width: 35px;
    height: 35px;
  }
`;

export const HammerSvg = styled(Hammer)`
  width: 38px;
  height: 30px;

  @media (min-width: 768px) {
    width: 35px;
    height: 28px;
  }

  @media (min-width: 1024px) {
    width: 44px;
    height: 35px;
  }
`;

export const CarSvg = styled(Car)`
  width: 43px;
  height: 22px;

  @media (min-width: 1024px) {
    width: 68px;
    height: 35px;
  }
`;

export const ButtonsGridContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-flow: row wrap;
    margin-bottom: 40px;
    justify-content: center;
  }

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
`;

export const Button = styled.button<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: ${fontFamilyCamptonMedium};
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 16px;
  height: 65px;
  background-color: ${({ $isActive }) =>
    $isActive ? colors.purple11 : colors.white};
  border: 2px solid
    ${({ $isActive }) => ($isActive ? colors.purple7 : colors.gray1)};
  padding: 15px 25px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 371px) and (max-width: 767px) {
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 250px;
    height: 60px;
    padding: 15px 20px;
    margin-right: 15px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    width: 200px;
    height: 116px;
    padding: 18px 20px;
    margin: 0 15px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ButtonContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 210px;

  @media (min-width: 371px) and (max-width: 767px) {
    margin-right: 40px;
  }

  @media (min-width: 1024px) {
    flex-direction: column;
  }
`;

export const ButtonIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: center;
  margin: 0 20px 0 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    width: auto;
    margin: 0 15px 0 0;
  }

  @media (min-width: 1024px) {
    width: auto;
    min-height: 40px;
    margin: 0 0 15px;
  }
`;
