import { css, styled } from 'styled-components';

import TickSvg from '../../../static/svgIcons/CircleWithTick.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonRegular,
} from '../../styles/sharedStyled';

const normalFontStyle = css`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: normal;
  margin-bottom: 0;
`;

export const PerilContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-color: ${colors.gray7};
  border-bottom-width: 1px;
  padding-bottom: 10px;
`;

export const PerilName = styled.p`
  ${normalFontStyle}
`;

export const Tick = styled(TickSvg)`
  height: 15px;
  width: 15px;
`;

export const PreviousQuestionsList = styled.ul``;

export const PreviousQuestionContainer = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  border-style: solid;
  border-color: ${colors.gray7};
  border-bottom-width: 1px;
  margin-bottom: 0;
  padding: 10px 0;
`;

export const PreviousQuestion = styled.p`
  ${normalFontStyle}
`;

export const PreviousAnswer = styled.p`
  ${normalFontStyle}
  color: ${colors.purple7};
  padding-left: 15px;
`;

export const Question = styled.p`
  font-family: ${fontFamilyCamptonRegular};
  font-weight: 500;
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  margin-bottom: 0;
  padding-top: 15px;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 21px;
    padding-top: 20px;
  }
`;

export const QuestionSubtitle = styled.p`
  ${normalFontStyle}
  padding-top: 5px;
`;
