import { styled } from 'styled-components';

import MaxDesktop from '../../static/svgIcons/pe_upsell_max.svg';
import MaxMobile from '../../static/svgIcons/pe_upsell_max_mobile.svg';

export const MaxDesktopSvg = styled(MaxDesktop)`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    width: 82px;
    height: 65px;
  }
`;

export const MaxMobileSvg = styled(MaxMobile)`
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
`;
