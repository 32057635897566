import React, { ReactNode } from 'react';

import Icon from '../icons/Icon';
import { NoClaimHeaderRowType } from '../productTable/NoClaimsDiscountTable';
import { ImpactV2ProductTableDataType } from '../productTable/ProductTable';

type Props = {
  data: ImpactV2ProductTableDataType;
  column: number;
  img?: ReactNode;
  title?: any;
  iconSize?: 1 | 2 | 3 | 4 | 5 | 6;
  type?: 'home' | 'car';
  heading?: NoClaimHeaderRowType;
};

const ProductCard = ({
  data,
  column,
  img,
  title,
  iconSize = 5,
  type,
  heading,
}: Props) => {
  return (
    <div
      className={`bg-white h-full  ${
        type === 'car' ? 'min-w-312 md:min-w-754' : 'max-w-60'
      } rounded-lg flex flex-col`}
    >
      <div className="bg-pxGrey w-full rounded-t-lg">
        {title && (
          <div className="flex flex-row md:h-20">
            <p className="body-copy mb-0 p-32px  w-1/2 text-finerDetailsBodyCopy font-semibold text-center border-r border-pxWhite">
              {title[0].tableTitle}
            </p>
            <p className="body-copy mb-0 p-32px  w-1/2 text-finerDetailsBodyCopy font-semibold text-center">
              {title[column + 1].tableTitle}
            </p>
          </div>
        )}
      </div>
      {img && (
        <div className="p-20px">
          <Icon type="primary" size={iconSize} className="mx-auto h-full">
            {img}
          </Icon>
        </div>
      )}

      {heading && (
        <div
          className={`flex flex-row w-full justify-between items-center border-pxGrey border-t ${
            heading.label.length === 0 ? 'col-subheader' : 'px-0'
          }`}
        >
          <div className="w-1/2 h-full flex flex-col justify-center border-r border-pxGrey py-15px px-20px items-start">
            {heading.label}
          </div>
          <div className={` w-1/2 flex items-center justify-center`}>
            {heading.included}
          </div>
        </div>
      )}

      {data.productRows.map((row) => (
        <div
          key={row.label}
          className={`flex flex-row w-full justify-between items-center border-pxGrey border-t ${
            row.label.length === 0 ? 'col-subheader' : 'px-0'
          }`}
        >
          <div
            className={`w-1/2 h-full flex flex-col justify-center border-r border-pxGrey py-15px px-20px items-start
             ${row.label.length === 0 && 'bg-pxMenu'}`}
          >
            <p className="text-fontSize-15 font-normal font-camptonLight tracking-normal mb-0">
              {row.label}
            </p>
            {row.subLabel && (
              <p className="text-footer pt-1 mb-0 w-3/4">{row.subLabel}</p>
            )}
          </div>
          <div
            className={`w-1/2 min-h-47 flex items-center justify-center ${
              row.hasLightGreyBackground && 'bg-pxMenu'
            }`}
          >
            <p
              className={`text-fontSize-15 font-normal font-camptonLight tracking-normal text-center text-pxPurple m-2 ${
                row.hasBlackText && 'text-pxBlack'
              }`}
            >
              {row.included[column]}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductCard;
