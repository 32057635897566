import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import {
  DocumentIcon,
  DocumentIconWrapper,
  DocumentLink,
  DocumentLinkContainer,
  PolicyExpertBronze,
  PolicyExpertGold,
  PolicyExpertLogo,
  PolicyExpertPlus,
  PolicyExpertProtect,
  PolicyExpertSelect,
  PolicyExpertSilver,
  ProductContainer,
  ProductDocumentsContainer,
} from './PolicyDocuments.style';

export type ProductName = 'Home' | 'Car' | 'Pet';

type PolicyType =
  | 'bronze'
  | 'silver'
  | 'gold'
  | 'protect'
  | 'select'
  | 'plus'
  | 'key';

export type PolicyDocumentsType = {
  policyType: PolicyType;
  policyWordingLink: string;
  ipidLink: string;
};

type Props = {
  products: PolicyDocumentsType[];
};

const getProductPolicyIcon = (policyType: PolicyType) => {
  switch (policyType) {
    case 'bronze':
      return <PolicyExpertBronze data-testid="bronze-icon" />;
    case 'silver':
      return <PolicyExpertSilver data-testid="silver-icon" />;
    case 'gold':
      return <PolicyExpertGold data-testid="gold-icon" />;
    case 'protect':
      return <PolicyExpertProtect data-testid="protect-icon" />;

    case 'select':
      return <PolicyExpertSelect data-testid="select-icon" />;
    case 'plus':
      return <PolicyExpertPlus data-testid="plus-icon" />;
    case 'key':
      return <PolicyExpertLogo data-testid="home-key-cover-icon" />;
    default:
      return null;
  }
};

const PolicyDocuments = ({ products }: Props) => {
  const isMobile = useBreakpoint();

  return (
    <ProductDocumentsContainer>
      {products?.map((productDocuments) => {
        const policyIcon = getProductPolicyIcon(productDocuments.policyType);

        return (
          <ProductContainer key={`${productDocuments.policyType} documents`}>
            {policyIcon}
            <DocumentLinkContainer>
              <DocumentIconWrapper>
                <DocumentIcon />
              </DocumentIconWrapper>
              <DocumentLink
                $hasMargin
                href={productDocuments.policyWordingLink}
                target="_blank"
                rel="noreferrer"
              >
                Policy wording
              </DocumentLink>
            </DocumentLinkContainer>
            <DocumentLinkContainer>
              <DocumentIconWrapper>
                <DocumentIcon />
              </DocumentIconWrapper>

              <DocumentLink
                href={productDocuments.ipidLink}
                target="_blank"
                rel="noreferrer"
              >
                Insurance product{isMobile && <br />} information document
              </DocumentLink>
            </DocumentLinkContainer>
          </ProductContainer>
        );
      })}
    </ProductDocumentsContainer>
  );
};

export default PolicyDocuments;
