import { styled } from 'styled-components';

import { colors, fontFamilyCamptonBook } from '../styles/sharedStyled';

export const NotFoundBannerContainer = styled.div`
  background-color: ${colors.gray3};
`;

export const MessageHeaderBannerText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: ${colors.white};
  padding: 0 20px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    font-size: 20px;
    margin-bottom: 50px;
  }
`;
