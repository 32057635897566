import React, { useState } from 'react';

import { AwardYearType } from '../../pages/about-us/awards';

export type AwardsYearPropsType = {
  yearData: AwardYearType;
  key: number;
};

const AwardsYear = ({ yearData, key }: AwardsYearPropsType) => {
  const [maxAwards, setMaxAwards] = useState<number>(9);

  return (
    <div key={key} data-testid={`awards-${yearData.year}`}>
      <h4 className="py-32px">{yearData.year}</h4>
      <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-4 grid-gap-space-small">
        {yearData.awards &&
          yearData.awards.map((award, index) => {
            if (index >= maxAwards - 1) {
              return null;
            }
            return (
              <div
                key={`${yearData.year}_${award.title}`}
                className="card flex flex-col mb-32px md:mb-32px md:grid grid-rows-1n2 border-b border-black md:border-0 pb-32px"
              >
                <div className="bg-white even-round max-w-2/3 md:max-w-full max-h-150 mb-32px">
                  <img
                    className="p-32px max-h-150 mx-auto"
                    src={award.img}
                    alt={`${award.title}${
                      award.subTitle && `, ${award.subTitle}`
                    }`}
                  />
                </div>
                <p className="text-small font-semibold mb-4">{award.title}</p>
                <p className="text-small mb-0">{award.subTitle}</p>
              </div>
            );
          })}
      </div>
      <button
        className={`${maxAwards >= yearData.awards.length + 1 ? 'hidden' : ''}`}
        onClick={() => setMaxAwards(maxAwards + 4)}
        aria-label="Show more"
      >
        <p className="text-small text-pxPink font-bold">Show more</p>
      </button>
    </div>
  );
};

export default AwardsYear;
