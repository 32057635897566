import { styled } from 'styled-components';

import { colors, fontFamilyCamptonMedium } from '../../styles/sharedStyled';

export const HecBannerContainer = styled.div`
  background-color: ${colors.gray3};
  margin-bottom: -35px;
  padding: 60px 24px 90px;

  @media (min-width: 768px) {
    padding: 100px 40px 180px;
    margin-bottom: -80px;
  }

  @media (min-width: 1024px) {
    padding: 130px 0 210px;
  }
`;

export const HecBannerTitle = styled.h2`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 35px;
  margin-bottom: 50px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 50px;
    margin-bottom: 70px;
  }

  @media (min-width: 1024px) {
    font-size: 80px;
    margin-bottom: 90px;
  }
`;
