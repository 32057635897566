import React, { FC, useContext } from 'react';
import Markdown from 'react-markdown';

import { PhoneNumbersContext } from '../../context/PhoneNumbersProvider';
import formatPhoneNumber from '../../helpers/formatPhoneNumber';
import { pushHecTriageEventToDataLayer } from '../../services/windowService';
import {
  CallUsText,
  CoverInformationContainer,
  OpenText,
  PhoneLink,
} from './MightHaveCover.style';

type MightHaveCoverProps = {
  coverInformation?: string;
};

const MightHaveCover: FC<MightHaveCoverProps> = ({ coverInformation }) => {
  const phoneNumbers = useContext(PhoneNumbersContext);
  const homeEmergencyNumber = phoneNumbers.home?.homeEmergencyCoverTriage;

  pushHecTriageEventToDataLayer('show_you_have_cover');

  return (
    <>
      {!!coverInformation && (
        <CoverInformationContainer>
          <Markdown>{coverInformation}</Markdown>
        </CoverInformationContainer>
      )}
      <CallUsText>Call us now. We’re ready to help.</CallUsText>
      <PhoneLink
        href={`tel:${formatPhoneNumber(homeEmergencyNumber)}`}
        onClick={() => pushHecTriageEventToDataLayer('call_hec_you_have_cover')}
      >
        {homeEmergencyNumber}
      </PhoneLink>
      <OpenText>Lines open 24hrs a day</OpenText>
    </>
  );
};

export default MightHaveCover;
