import React, { useEffect, useState } from 'react';

import { Section } from '../../pages/contact';
import Accordion from '../accordion/Accordion';
import { ContactTableData } from './ContactTable';

interface ContactMobileTableProps {
  data: ContactTableData[];
  section: Section;
}

const ContactMobileTable = ({ data, section }: ContactMobileTableProps) => {
  const [openIndex, setOpenIndex] = useState<number | undefined>(undefined);
  const handleOnClick = (index?: number) =>
    setOpenIndex(openIndex === index ? undefined : index);
  useEffect(() => {
    setOpenIndex(undefined);
  }, [section]);

  return (
    <div className="flex lg:hidden flex-col">
      {data.map((tab, index) => {
        const body = <div>{tab.content({ section })}</div>;
        return (
          <Accordion
            key={tab.title}
            title={tab.title}
            body={body}
            marker
            handleOnClick={handleOnClick}
            index={index}
            openIndex={openIndex}
          />
        );
      })}
      <Accordion
        key={'Make a claim'}
        title={'Make a claim'}
        body={<></>}
        marker
        navigateTo="/claims"
      />
    </div>
  );
};

export default ContactMobileTable;
