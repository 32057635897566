import { css, styled } from 'styled-components';

import PlusSvg from '../../../static/svgIcons/home-emergency-plus.svg';
import SelectSvg from '../../../static/svgIcons/home-emergency-select.svg';
import { colors, fontFamilyCamptonSemiBold } from '../../styles/sharedStyled';

const coverButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 15px;
  border-radius: 15px;
  background-color: ${colors.white};
`;

const iconStyle = css`
  width: 70px;
  height: 55px;
`;

export const CoverButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 5px;
  }
`;

export const SelectButton = styled.button`
  ${coverButtonStyle}
`;

export const PlusButton = styled.button`
  ${coverButtonStyle}
  margin-left: 15px;

  @media (min-width: 768px) {
    margin-left: 30px;
  }
`;

export const SelectImage = styled(SelectSvg)`
  ${iconStyle}
`;
export const PlusImage = styled(PlusSvg)`
  ${iconStyle}
`;

export const NotSureButton = styled.button`
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 16px;
  line-height: 18.62px;
`;
