import React from 'react';

import { Link } from 'gatsby';

import Icon from '../icons/Icon';
import ImageComponent from '../imageComponent/ImageComponent';
import { ImageWithTextDataType } from '../imageWithText/ImageWithText';
import InsuranceReviews from '../insuranceReviews/InsuranceReviews';
import ReviewCarousel from '../reviewCarousel/ReviewCarousel';

type Props = {
  data: ImageWithTextDataType;
  topCoverColor?: string;
  topCoverColorMobile?: string;
  extraPaddingTop?: boolean;
  smallBodyText?: boolean;
  isCareers?: boolean;
  isCareersHP?: boolean;
  isCareersWorks?: boolean;
  imgPosition?: string;
};

const ImageWithTextLandingPage = ({
  data,
  topCoverColor = 'white',
  topCoverColorMobile = 'white',
  extraPaddingTop = false,
  smallBodyText = false,
  isCareers = false,
  isCareersHP = false,
  isCareersWorks = false,
  imgPosition,
}: Props) => (
  <div
    className={`${data.bgColor}  relative ${
      isCareersHP && isCareersHP ? 'pt-10' : ' '
    } md:-mt-32px ${data.imageLeft ? '' : 'overflow-hidden'}`}
  >
    <span
      className={`md:hidden absolute w-full h-24 sm:h-24 md:h-24 bg-${topCoverColorMobile} top-0 left-0 ${
        extraPaddingTop ? 'pt-5' : ''
      }`}
    />

    <span
      className={`hidden md:block absolute w-full h-12 sm:h-12 md:h-24 bg-${topCoverColor} top-0 left-0`}
    />

    <div
      className={`padding-x md:pb-32px grid gap-x-10 grid-cols-1 md:grid-cols-2 relative`}
    >
      <div className="hidden md:block">
        <ImageComponent
          imageFile={data.imageFile}
          alt={data.imgName}
          imgPosition={imgPosition}
          className={``}
        />
      </div>

      <div
        className={` ${
          data.imageLeft ? 'md:pl-32px lg:pl-130px' : 'md:pr-32px lg:pr-130px'
        } ${isCareersHP && isCareersHP ? 'md:pt-0' : 'md:pt-32px'} ${
          isCareersWorks && isCareersWorks ? 'pt-82px' : ''
        }`}
      >
        {data.copyBlock.map((item: any) => (
          <div key={item.headerText} className="w-full ">
            <h2
              className={`text-pxCharcoal ${
                isCareersWorks && isCareersWorks ? '' : 'mt-32px'
              } mb-32px ${isCareers ? 'md:mb-16' : 'md:mb-82px'} md:${
                data.blendsWithTop ? 'mt-130px' : 'mt-0'
              } lg:pt-130px`}
            >
              {item.headerText}{' '}
            </h2>

            {item.subHeader && <p>{item.subHeader}</p>}

            {item.listText && (
              <ul className={`${isCareers ? 'careers-list' : ''}`}>
                {item.listText.map((listItem: string) => (
                  <li key={listItem} className={'mb-4'}>
                    {listItem}
                  </li>
                ))}
              </ul>
            )}

            {item.subTextIcon &&
              item.subTextIcon.map((item: any) => (
                <div
                  key={item.iconName}
                  className="pb-32px grid grid-rows-1 grid-flow-col grid-cols-min justify-start"
                >
                  <Icon type="primary" size={2}>
                    {item.icon}
                  </Icon>
                  <div>
                    {item.benefits.map((benefit: any) => (
                      <p key={benefit} className="pl-5 mb-3">
                        {benefit}
                      </p>
                    ))}
                  </div>
                </div>
              ))}

            {item.subText &&
              item.subText.map((item: string) => {
                if (item[0] === '<') {
                  return (
                    <p
                      key={item}
                      dangerouslySetInnerHTML={{ __html: item }}
                      className={`mb-32px ${isCareers ? 'max-w-469' : ''} ${
                        smallBodyText
                          ? 'text-finerDetailsBodyCopy md:text-bodyCopy'
                          : ''
                      }`}
                    />
                  );
                }

                return (
                  <div
                    key={item}
                    className={`mb-32px ${isCareers ? 'max-w-469' : ''} ${
                      smallBodyText
                        ? 'text-finerDetailsBodyCopy md:text-bodyCopy'
                        : ''
                    }`}
                  >
                    {item}
                  </div>
                );
              })}
          </div>
        ))}
        {data.link && (
          <Link className="font-semibold text-pxPink" to={data.link.url}>
            {data.link.label}
          </Link>
        )}
        {data.buttons &&
          data.buttons.map((btn: any) => (
            <Link
              key={btn.label}
              to={btn.url}
              className={`btn-${
                btn.smallestSize || 'md'
              } lg:btn-lg lg:mr-32px mb-32px last:mb-0 btn-${
                btn.primary ? 'primary' : 'secondary'
              }`}
            >
              {btn.label}
            </Link>
          ))}
      </div>
    </div>
    <div>
      <ReviewCarousel cards={InsuranceReviews} />
    </div>
    <div className="block md:hidden">
      <ImageComponent
        imageFile={data.imageFile}
        alt={data.imgName}
        imgPosition={imgPosition}
        className={``}
      />
    </div>
  </div>
);

export default ImageWithTextLandingPage;
