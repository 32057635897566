import { styled } from 'styled-components';

import { colors, fontFamilyCamptonSemiBold } from '../../styles/sharedStyled';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: start;
  padding-top: 15px;
`;

export const YesButton = styled.button`
  display: flex;
  background-color: ${colors.pink2};
  color: ${colors.white};
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  height: 40px;
  padding: 10px 0;
  width: 50%;
  border-color: ${colors.pink2};
  border-width: 2px;
  border-radius: 15px;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:hover {
    background-color: ${colors.white};
    color: ${colors.pink2};
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 18.62px;
    height: 50px;
    padding: 15px;
    width: 150px;
  }
`;

export const NoButton = styled.button`
  display: flex;
  background-color: ${colors.white};
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  height: 40px;
  padding: 10px 0;
  width: 50%;
  border-color: ${colors.pink2};
  border-width: 2px;
  border-radius: 15px;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  margin-left: 10px;

  &:hover {
    background-color: ${colors.pink2};
    color: ${colors.white};
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 18.62px;
    height: 50px;
    padding: 15px;
    width: 150px;
    margin-left: 20px;
  }
`;
