import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import ShapeX from '../ShapeX';
import ReviewSection from './ReviewSection';

type Props = {
  data?: {
    headerText: string;
  };
  cards: JSX.Element[];
  shape: boolean;
  marginBottom?: string;
  isHomePage?: boolean;
  isRenewal?: boolean;
};

const ReviewBanner = ({ cards, data, shape, isHomePage, isRenewal }: Props) => {
  const { isMobile, isTablet, isTabletLarge, isDesktop } = useBreakpoint();
  const showShapeX = shape && (!isHomePage || isTablet);

  return (
    <>
      <div
        style={{ overflowX: 'clip' }}
        className={`bg-pxMint overflow-x-hidden relative z-100 max-w-screen md:pl-40px lg:pl-0 flex flex-col md:flex-col-reverse lg:flex-row md:pb-180px lg:pb-210px ${
          isHomePage && 'md:mb-80-minus'
        }`}
      >
        <ReviewSection cards={cards} data={data} isRenewal={isRenewal} />
        {showShapeX && (
          <div className="hidden md:block">
            <ShapeX
              position={`${
                (isDesktop || isTabletLarge) && !isTablet
                  ? 'bottom-right-reviews'
                  : 'bottom-left-reviews'
              }`}
            />
          </div>
        )}
        {!isMobile && isTablet && (
          <div className="bg-pxMint">
            <StaticImage
              src="../../images/girl_on_laptop.png"
              alt="People love what we do..."
              className="h-639px w-511 mt-240-minus right-50px md:w-573 md:h-716 lg:w-1027 lg:h-1283 md:right-240-minus lg:right-40px relative lg:absolute"
              layout="fullWidth"
              loading="eager"
              placeholder="blurred"
            />
          </div>
        )}
      </div>
      {isMobile && (
        <div className="bg-pxMint">
          <StaticImage
            src="../../images/girl_on_laptop.png"
            alt="People love what we do..."
            className="z-101 h-639 w-511 mt-220-minus right-50px relative"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        </div>
      )}
    </>
  );
};

export default ReviewBanner;
