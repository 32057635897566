import { useEffect, useState } from 'react';

import axios from 'axios';

export const useJobs = () => {
  const [isCareersLoading, setIsCareersLoading] = useState(true);
  const [postsLastModified, setPostsLastModifided] = useState<null | string>(
    null
  );
  const [careers, setCareers] = useState<null | any[]>(null);
  const [motherwellCareers, setMotherwellCareers] = useState<null | any[]>(
    null
  );
  const [miltonKeynesCareers, setMiltonKeynesCareers] = useState<null | any[]>(
    null
  );
  const [londonCareers, setLondonCareers] = useState<null | any[]>(null);

  const fetchData = async () => {
    const allJobs = [];
    const motherwellJobs = [];
    const miltonKeynesJobs = [];
    const londonJobs = [];

    const data = await axios
      .get(
        'https://blog.policyexpert.co.uk/wp-content/themes/pe-wordpress-2021/data-public/wordpress_careers_data.json'
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
        return null;
      });
    if (data === null) {
      console.error('Something went wrong whilst fetching jobs!');
      return;
    }
    setIsCareersLoading(false);
    setPostsLastModifided(data.posts_modified);
    for (const locations of Object.entries(data.posts)) {
      // @ts-ignore
      for (const jobs of Object.entries(locations[1])) {
        allJobs.push(jobs[1]);
      }
    }
    for (const jobs of Object.entries(data.posts.Motherwell)) {
      motherwellJobs.push(jobs[1]);
    }
    for (const jobs of Object.entries(data.posts['Milton Keynes'])) {
      miltonKeynesJobs.push(jobs[1]);
    }
    for (const jobs of Object.entries(data.posts.London)) {
      londonJobs.push(jobs[1]);
    }
    setCareers(allJobs);
    setMotherwellCareers(motherwellJobs);
    setMiltonKeynesCareers(miltonKeynesJobs);
    setLondonCareers(londonJobs);
  };

  useEffect(() => {
    // Only run once
    if (isCareersLoading === false) {
      return;
    }
    fetchData();
  }, []);

  return {
    isCareersLoading,
    careers,
    motherwellCareers,
    miltonKeynesCareers,
    londonCareers,
    postsLastModified,
  };
};
