import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';

import ResizeObserver from 'resize-observer-polyfill'; // using polyfill for gatsby build.

import AboutOurExec from '../aboutOurExec/AboutOurExec';
import DropShapeCard from '../dropShapeCard/DropShapeCard';
import ImageComponent from '../imageComponent/ImageComponent';
import { ImageWithTextRevealType } from '../imageWithTextReveal/ImageWithTextReveal';
import Shape, { ShapeProps } from '../Shape';

type Props = {
  data: ImageWithTextRevealType;
  shape?: boolean;
  shapePosition?: ShapeProps['position'];
  noParagraphs?: boolean;
};

export type AboutUsEmployee = {
  name: string;
  role: string;
  image: any;
};

export function useHeight({ on = true /* no value means on */ } = {} as any) {
  const ref = useRef<any>();
  const [height, set] = useState(0);
  const heightRef = useRef(height);
  const [ro] = useState(
    () =>
      new ResizeObserver(() => {
        if (ref.current && heightRef.current !== ref.current.offsetHeight) {
          heightRef.current = ref.current.offsetHeight;
          set(ref.current.offsetHeight);
        }
      })
  );
  useLayoutEffect(() => {
    if (on && ref.current) {
      set(ref.current.offsetHeight);
      ro.observe(ref.current);
    }
    return () => ro.disconnect();
  }, [on, ref.current]);
  return [ref, height as any];
}

const ImageWithCarouselReveal = ({
  data,
  shape,
  shapePosition,
  noParagraphs,
}: Props) => {
  const [showCarousel, setShowCarousel] = useState(false);
  const [ref, height] = useHeight();

  const expandAnimationProps = useSpring({
    config: { duration: 300, ...config.molasses },
    from: { height: 0, opacity: 0 },
    to: { height: showCarousel ? height : 0, opacity: showCarousel ? 1 : 0 },
  });

  useEffect(() => {
    if (showCarousel) {
      // delay until expand animation is complete
      setTimeout(
        () =>
          document
            .getElementById('about-execs')
            ?.scrollIntoView({ behavior: 'smooth' }),
        350
      );
    }
  }, [showCarousel]);

  return (
    <div
      className={`${data.bgColor} pt-32px md:pt-130px relative overflow-hidden`}
    >
      <div
        className={`padding-x flex flex-col justify-between   md:${
          data.imageLeft ? 'flex-row' : 'flex-row-reverse'
        } relative
        ${!showCarousel ? 'pb-70px' : 'pt-60px md:pt-100px lg:pt-130px'}
        `}
      >
        <div
          className={`lg:${
            data.imageLeft ? 'pr-82px' : 'pl-82px'
          } max-h-743 lg:h-743 md:w-1/2 relative z-10`}
        >
          <DropShapeCard className="h-full max-h-380 lg:max-h-743" fullWidth>
            <ImageComponent
              imageFile={data.imageFile}
              className="w-full h-full"
              alt={data.imgName}
            />
          </DropShapeCard>
        </div>

        <div className="flex flex-col w-full md:w-5/12 relative z-10 pt-82px md:pt-0">
          <h2 className="mb-32px md:mb-82px">{data.headerText} </h2>
          <div className={` overflow-y-hidden`}>
            {data.subText.map((item: string) => (
              <p
                key={item}
                className={noParagraphs && noParagraphs ? 'mb-0' : 'mb-4'}
              >
                {item}
              </p>
            ))}
          </div>

          {data.btn && (
            <button
              onClick={() => {
                setShowCarousel(!showCarousel);
              }}
              className={`mt-25px md:mt-40px btn-${
                data.btn.primary ? 'primary' : 'secondary'
              } btn-md md:btn-lg`}
            >
              {data.btn.label}
            </button>
          )}
        </div>
      </div>
      {shape && shapePosition ? <Shape position={shapePosition} /> : null}

      <animated.div
        className={`${
          showCarousel ? 'mt-60px md:mt-100px lg:mt-130px w-full' : ''
        } `}
        style={expandAnimationProps}
      >
        <div ref={ref}>
          <AboutOurExec />
        </div>
      </animated.div>
    </div>
  );
};

export default ImageWithCarouselReveal;
