import React, { useContext } from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import CtaBanner from '../components/ctaBanner/CtaBanner';
import CtaBannerImpactV2 from '../components/ctaBanner/CtaBannerImpactV2';
import ErrorBanner from '../components/errorBanner/ErrorBanner';
import MessageHeaderBanner from '../components/messageHeaderBanner/MessageHeaderBanner';
import MessageHeaderBannerImpactV2 from '../components/messageHeaderBanner/MessageHeaderBannerImpactV2';
import config from '../config';
import { PhoneNumbersContext } from '../context/PhoneNumbersProvider';
import formatPhoneNumber from '../helpers/formatPhoneNumber';
import useDisclaimer from '../hooks/useDisclaimer';
import usePetChanges from '../hooks/usePetChanges';
import { colors } from '../styles/sharedStyled';
import { MessageHeaderBannerText, NotFoundBannerContainer } from './404.style';

const NotFoundPage: React.FC<PageProps> = () => {
  const { isMobile, isTablet } = useBreakpoint();

  useDisclaimer(null);
  const phoneNumbers = useContext(PhoneNumbersContext);
  const displayPetChanges = usePetChanges();

  const errorBannerData = {
    bgColor: 'bg-pxGrey',
    textColor: 'text-pxBlack',
    headerText: 'Why Policy Expert',
    headerSubText: 'Try these links or call us on',
    subContentLink: {
      url: `tel:${formatPhoneNumber(phoneNumbers.main)}`,
      label: `${phoneNumbers.main}`,
    },
    btns: [
      { label: 'Log in', url: '/login', primary: false },
      { label: 'Change your policy', url: '/contact', primary: false },
      {
        label: 'Help centre',
        url: config.HELP_CENTRE_URL,
        primary: false,
      },
    ],
  };

  const headerData = {
    title: 'Oh!',
    message: displayPetChanges ? (
      <MessageHeaderBannerText>
        Well this is awkward.
        {isMobile && <br />} It looks like the page you're looking for doesn't
        exist
      </MessageHeaderBannerText>
    ) : (
      "Well this is awkward. It looks like the page you're looking for doesn't exist"
    ),
  };

  const height = displayPetChanges
    ? {
        mobile: '499px',
        tablet: '404px',
        desktop: '492px',
      }
    : {
        mobile: '340px',
        tablet: '530px',
        desktop: '700px',
      };

  const marginTop = displayPetChanges
    ? { mobile: '40px', tablet: '50px', desktop: '130px' }
    : {
        mobile: '40px',
        tablet: '80px',
        desktop: '140px',
      };

  return (
    <>
      <NotFoundBannerContainer>
        {displayPetChanges ? (
          <>
            <MessageHeaderBannerImpactV2
              backgroundColor={colors.charcoal}
              height={height}
              marginTop={marginTop}
              shapePosition1="left-not-found"
              shapePosition2="right-not-found"
              data={headerData}
            />
            {((!isTablet && !isMobile) || (isTablet && !isMobile)) && (
              <CtaBannerImpactV2 isErrorPage />
            )}
          </>
        ) : (
          <>
            <MessageHeaderBanner
              backgroundColor={colors.charcoal}
              height={height}
              marginTop={marginTop}
              shapePosition1="left-header"
              shapePosition2="right-header"
              data={headerData}
            />
            <CtaBanner marginTop="mt-75-minus md:mt-180-minus lg:mt-240-minus" />
          </>
        )}
      </NotFoundBannerContainer>

      <ErrorBanner data={errorBannerData} />
    </>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => (
  <>
    <title>Not found</title>
    <html lang="en" />
  </>
);
