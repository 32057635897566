import { styled } from 'styled-components';

import { fontFamilyCamptonBook } from '../../styles/sharedStyled';

export const ExecCardContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  text-align: center;
`;

export const ExecRole = styled.p`
  margin-bottom: 20px;
  font-family: ${fontFamilyCamptonBook};
  font-size: 14px;
  max-width: 155px;
  line-height: 16px;

  @media (min-width: 768px) {
    margin-bottom: -10px;
    font-size: 16px;
    max-width: 216px;
    line-height: 19px;
  }
`;

export const ExecImage = styled.img`
  width: 155px;
  height: 140px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 200px;
    height: 180px;
  }

  @media (min-width: 1024px) {
    width: 221px;
    height: 200px;
  }
`;

export const ExecName = styled.p`
  margin-bottom: 5px;
  font-family: ${fontFamilyCamptonBook};
  font-size: 18px;
  line-height: 21px;

  @media (min-width: 768px) {
    font-size: 25px;
    line-height: 29px;
  }
`;
