import { styled } from 'styled-components';

import Car from '../../static/svgIcons/Car.svg';
import House from '../../static/svgIcons/House.svg';
import Pet from '../../static/svgIcons/Pet.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonRegular,
  fontFamilyCamptonSemiBold,
} from '../styles/sharedStyled';

export const CarSvg = styled(Car)`
  width: 50px;
  height: 25px;

  @media (min-width: 768px) {
    width: 43px;
    height: 22px;
  }

  @media (min-width: 1024px) {
    width: 68px;
    height: 35px;
  }
`;

export const HouseSvg = styled(House)`
  width: 33px;
  height: 35px;
  stroke: ${colors.purple7};
  fill: ${colors.purple7};

  @media (min-width: 768px) {
    width: 28px;
    height: 30px;
  }

  @media (min-width: 1024px) {
    width: 37px;
    height: 40px;
  }
`;

export const PetSvg = styled(Pet)`
  width: 50px;
  height: 23px;

  @media (min-width: 768px) {
    width: 47px;
    height: 22px;
  }

  @media (min-width: 1024px) {
    width: 75px;
    height: 35px;
  }
`;

export const HeroBannerHeader = styled.h1`
  color: ${colors.white};
  font-size: 30px;
  padding: 0 10px;
  margin-bottom: 15px;
  text-align: center;
  font-family: ${fontFamilyCamptonRegular};

  @media (min-width: 768px) {
    font-size: 35px;
    margin-bottom: 25px;
    padding: 0;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
    margin-bottom: 30px;
  }
`;

export const HeroBannerText = styled.p`
  color: ${colors.white};
  font-size: 18px;
  text-align: center;
  font-family: ${fontFamilyCamptonBook};
  margin-bottom: 50px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 25px;
  }
`;

export const SectionContainer = styled.div`
  background-color: ${colors.purple7};
  margin-bottom: -35px;

  @media (min-width: 768px) {
    margin-bottom: -80px;
  }
`;

export const StaticImageContainer = styled.div`
  height: 100%;
  width: 445px;
  margin: -100px 0 0 24px;

  @media (min-width: 768px) {
    width: 543px;
    margin: -250px 0 0 241px;
  }
`;

export const PetContentText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  line-height: normal;
  font-size: 16px;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const LinkText = styled.a<{ $hasTelNumber?: boolean }>`
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: ${({ $hasTelNumber }) => ($hasTelNumber ? '' : '18px')};
  }

  @media (min-width: 1024px) {
    font-size: ${({ $hasTelNumber }) => ($hasTelNumber ? '18px' : '20px')};
  }
`;

export const PetContactText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  margin-bottom: 0;
  line-height: normal;

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;
