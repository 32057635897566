import React from 'react';
import { useFormContext } from 'react-hook-form';

import carIcon from '../../../static/icons/car-icon.png';
import homeIcon from '../../../static/icons/home.svg';
import petIcon from '../../../static/icons/pet-icon.svg';
import RenewalDropDown from '../../components/renewalDropDown/RenewalDropDown';
import {
  CarRegistrationInput,
  CarRegistrationInputContainer,
  CarRegistrationTextContainer,
  RenderProductSectionContainer,
  RenderProductSectionIcon,
  RenderProductSectionIconContainer,
  RenderProductSectionText,
} from './renewals.style';

type RenderProductSectionProps = {
  product: string;
};

const RenderProductSection: React.FC<RenderProductSectionProps> = ({
  product,
}) => {
  const { register } = useFormContext();

  switch (product) {
    case 'car':
      return (
        <RenderProductSectionContainer id="car">
          <RenderProductSectionIconContainer>
            <RenderProductSectionIcon src={carIcon} alt="Car icon" />
          </RenderProductSectionIconContainer>
          <RenderProductSectionText>Car insurance</RenderProductSectionText>
          <RenewalDropDown
            name="fhins_xsell_car_month"
            required
            product="car"
          />
          <CarRegistrationInputContainer>
            <CarRegistrationTextContainer>UK</CarRegistrationTextContainer>
            <CarRegistrationInput
              type="text"
              {...register('fhins_xsell_number_plate', { required: false })}
              id="fhins_xsell_number_plate"
              className="uppercase"
              placeholder="ENTER REG"
            />
          </CarRegistrationInputContainer>
        </RenderProductSectionContainer>
      );
    case 'home':
      return (
        <RenderProductSectionContainer id="home">
          <RenderProductSectionIconContainer>
            <RenderProductSectionIcon src={homeIcon} $isHome alt="Home icon" />
          </RenderProductSectionIconContainer>
          <RenderProductSectionText>Home insurance</RenderProductSectionText>
          <RenewalDropDown
            name="fhins_xsell_home_month"
            required
            product="home"
          />
        </RenderProductSectionContainer>
      );
    case 'pet':
      return (
        <RenderProductSectionContainer id="pet">
          <RenderProductSectionIconContainer>
            <RenderProductSectionIcon src={petIcon} $isPet alt="Pet icon" />
          </RenderProductSectionIconContainer>
          <RenderProductSectionText>Pet insurance</RenderProductSectionText>
          <RenewalDropDown
            name="fhins_xsell_pet_month"
            required={false}
            product="pet"
          />
        </RenderProductSectionContainer>
      );
    default:
      return null;
  }
};

export default RenderProductSection;
