import useHypertune from './useHypertune';
import useQueryParams from './useQueryParams';

const useEnableImpactV2Update = () => {
  const { useImpactV2Update } = useQueryParams();

  const rootNode = useHypertune();

  if (useImpactV2Update === 'false') {
    return false;
  }

  return (
    rootNode.enableImpactV2Update().get(false) || useImpactV2Update === 'true'
  );
};

export default useEnableImpactV2Update;
