import React, { useEffect } from 'react';

import { HeadFC, PageProps } from 'gatsby';

import Shape from '../../components/Shape';
import ShapeX from '../../components/ShapeX';
import useDisclaimer from '../../hooks/useDisclaimer';

const getUri =
  'https://tag.mention-me.com/api/v2/referreroffer/mm3debec24?situation=landingpage&locale=en_GB';

const IntroduceAFriendPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  useEffect(() => {
    async function createScriptTag() {
      try {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = getUri;
        document.body.appendChild(s);
      } catch (error) {
        console.error(error);
      }
    }
    createScriptTag();
  }, []);

  return (
    <>
      <div className="mmContainer bg-pxGrey py-82px md:py-130px overflow-hidden relative ">
        <Shape position="left-far" />
        <div id="mmWrapper" className="relative z-40 mb-40"></div>
        <ShapeX position="bottom-right" />
      </div>
    </>
  );
};

export default IntroduceAFriendPage;

export const Head: HeadFC = () => (
  <>
    <title>Refer a friend | Policy Expert</title>
    <meta
      name="description"
      content="Introduce us to your friends! If they join us, we’ll give you £50 Caffè Nero or Amazon.co.uk vouchers."
    />
    <html lang="en" />
    <meta name="robots" content="noindex"></meta>
  </>
);
