import React from 'react';

import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import BreakArea from '../../../../static/svgIcons/BreakArea.svg';
import Games from '../../../../static/svgIcons/Games.svg';
import GlassesCelebrate from '../../../../static/svgIcons/GlassesCelebrate.svg';
import HeartHealth from '../../../../static/svgIcons/HeartHealth.svg';
import HotDrinks from '../../../../static/svgIcons/HotDrinks.svg';
import IconicBuilding from '../../../../static/svgIcons/IconicBuilding.svg';
import ThumbsUp from '../../../../static/svgIcons/ThumbsUp.svg';
import TrainStation from '../../../../static/svgIcons/TrainStation.svg';
import CareersNavigation from '../../../components/careersNavigation/CareersNavigation';
import HeaderBanner from '../../../components/headerBanner/HeaderBanner';
import HelpingYouThrive, {
  HelpingYouThriveType,
} from '../../../components/helpingYouThrive/HelpingYouThrive';
import ImageWithText, {
  ImageWithTextDataType,
} from '../../../components/imageWithText/ImageWithText';
import JobsMK from '../../../components/jobs/JobsMK';
import OfficeHighlights, {
  OfficeHighlightsDataType,
} from '../../../components/officeHighlights/OfficeHighlights';
import TwoColumnHeaderGrid, {
  TwoColumnHeaderGridType,
} from '../../../components/twoColumnHeaderGrid/TwoColumnHeaderGrid';
import TwoColumnHTMLBanner from '../../../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../../images/milton_keynes_sm.png`}
        alt={`Milton Keynes office. Home insurance HQ`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../../images/milton_keynes_md.png`}
        alt={`Milton Keynes office. Home insurance HQ`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../../images/milton_keynes_lg.png`}
      alt={`Milton Keynes office. Home insurance HQ`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const officeHighlightsData: OfficeHighlightsDataType = {
  bgColor: 'bg-pxGrey',
  headerText: 'Office highlights',
  subHeaderText: [
    'Located a short walk away from ‘The Hub’, our bright, modern, open plan office provides an environment of collaboration and teamwork.',
  ],
  location: 'Silbury Boulevard, Milton Keynes',
  commuteDetails: [
    {
      icon: <TrainStation stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Milton Keynes Central station <br className="md:hidden" />
          <span className="text-footerCopy md:text-finerDetailsBodyCopy">
            (0.7 miles)
          </span>
        </p>
      ),
    },
    {
      icon: <TrainStation stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Bletchley <br className="md:hidden" />
          <span className=" text-footerCopy md:text-finerDetailsBodyCopy">
            (4 miles)
          </span>
        </p>
      ),
    },
  ],
  locationBenefits: [
    {
      icon: <IconicBuilding stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Open plan office
        </p>
      ),
    },
    {
      icon: <HotDrinks stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Free hot/cold drinks and fruit
        </p>
      ),
    },
    {
      icon: <BreakArea stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Break out area to relax and unwind
        </p>
      ),
    },
    {
      icon: <Games stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Pool tables and games consoles
        </p>
      ),
    },
  ],
  glassdoor: true,
};

const videoData = {
  bgColor: 'bg-pxGrey',
  heading: 'Want to see more?',
  subText: 'Watch our video!',
  body: (
    <div>
      <div className="relative h-0 pb-56.25">
        <iframe
          className="absolute top-0 left-0 w-full h-full border-0 border-none"
          src="https://player.vimeo.com/video/729915158"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Policy Expert - insurance for people like you - October 2021"
        />
      </div>
    </div>
  ),
};

const helpingYouThriveData: HelpingYouThriveType = {
  bgColor: 'pxMint',
  header: 'Helping you thrive',
  strongCopy:
    'Everyone who joins our team in Milton Keynes goes through an induction programme delivered by our team of dedicated coaches.',
  introCopy: [
    'This process is designed to ensure you have all the skills and knowledge necessary to add value in your role',
    'During your induction, you’ll get to know the world of insurance, our products and how we deliver an exceptional customer experience.',
    'Training will be delivered in a variety of ways: including both face to face and online sessions. You’ll always be in a safe and supportive atmosphere to help you get the best out of your induction.',
    'Don’t worry, after your induction there’ll be plenty of people around to provide you with support and answer any questions you have.',
  ],
  listTitle: 'Continuous learning',
  listParagraph:
    'We want to help you progress and build your skills to get you where you want to be. Here are the key highlights of our learning and development programme:',
  listItems: [
    {
      icon: 'Tick',
      text: (
        <p className="text-base md:text-xl ml-3 flex-1">
          We’re passionate about helping you to develop your industry knowledge
          – and recognise the value of professional qualifications. This is why
          we offer a range of opportunities to study towards{' '}
          <span className="font-camptonSemiBold">CII qualifications</span>.
        </p>
      ),
    },
    {
      icon: 'Tick',
      text: (
        <p className="text-base md:text-xl ml-3 flex-1">
          Our online{' '}
          <span className="font-camptonSemiBold">
            Learning Management System
          </span>{' '}
          also offers an extensive range of personal development topics in the
          form of eLearning.
        </p>
      ),
    },
    {
      icon: 'Tick',
      text: (
        <p className="text-base md:text-xl ml-3 flex-1">
          <span className="font-camptonSemiBold">On the job learning</span>{' '}
          plays a key part in role development. Your buddies, team managers and
          learning and development colleagues will support you every step of the
          way through further training and refresher sessions.
        </p>
      ),
    },
  ],
};

const miltonKeynesPageDisclaimer = (
  <>
    <br />
    *Based upon over 150 Glassdoor ratings as of January 2021
  </>
);

const MiltonKeynesPage: React.FC<PageProps> = () => {
  useDisclaimer(miltonKeynesPageDisclaimer);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const MiltonKeynesImageQuery = useStaticQuery(graphql`
    query MiltonKeynesImages {
      ManOnChair: imageSharp(
        original: { src: { regex: "/mk_office_manonchair/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      DaniValente: imageSharp(original: { src: { regex: "/dani_valente/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Waqas: imageSharp(original: { src: { regex: "/waqas/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      MKOfficeChill: imageSharp(
        original: { src: { regex: "/mk_office_chill/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const whoWorksHereData: ImageWithTextDataType = {
    bgColor: 'bg-white',
    imageLeft: false,
    blendsWithTop: true,
    copyBlock: [
      {
        headerText: 'Who works here',
        subHeader:
          'Our talented customer facing teams in Milton Keynes are responsible for assisting our home insurance customers.',
        listText: [
          'New Business',
          'Renewals',
          'Customer Service',
          'Underwriting',
          'Learning and Development',
          'People Experience',
        ],
      },
    ],
    imgName: 'mk_office_manonchair.jpg',
    imageFile: MiltonKeynesImageQuery.ManOnChair.gatsbyImageData,
    imgMaxHeightMob: 'max-h-360',
    imgHeightMob: 'h-360',
    imgHeight: 'md:h-860',
  };

  const locationTeam: TwoColumnHeaderGridType = {
    bgColor: 'white',
    header: 'What’s it like to work here?',
    subText: 'Meet some of the Milton Keynes team',
    items: [
      {
        imgName: 'dani_valente.jpg',
        imageFile: MiltonKeynesImageQuery.DaniValente.gatsbyImageData,
        header: 'Dani Valente',
        subText: ['Customer Service', 'Milton Keynes'],
        button: {
          label: 'Read more',
          url:
            '/about-us/careers/working-here/#selected-section?name=' +
            'DaniValente' +
            '',
          primary: false,
        },
      },
      {
        imgName: 'waqas.jpg',
        imageFile: MiltonKeynesImageQuery.Waqas.gatsbyImageData,
        header: 'Waqas Javeed',
        subText: ['Team Manager', 'Milton Keynes'],
        button: {
          label: 'Read more',
          url:
            '/about-us/careers/working-here/#selected-section?name=' +
            'WaqasJaveed' +
            '',
          primary: false,
        },
      },
    ],
  };

  const companyBenefits: ImageWithTextDataType = {
    bgColor: 'bg-pxTan',
    imageLeft: false,
    blendsWithTop: false,
    copyBlock: [
      {
        headerText: 'Company benefits',
        subTextIcon: [
          {
            icon: <ThumbsUp stroke="#6214bE" fill="#6214bE" />,
            iconName: 'ThumbsUp',
            benefits: [
              'Competitive salary',
              'Holidays',
              'Pension',
              'Great central location',
            ],
          },
          {
            icon: <HeartHealth stroke="#6214bE" fill="#6214bE" />,
            iconName: 'HeartHealth',
            benefits: [
              'Health and wellbeing scheme',
              'Free fruit and healthy snacks',
              <>
                Perkbox{' '}
                <span className="text-finerDetailsBodyCopy">
                  (online discount scheme)
                </span>
              </>,
            ],
          },
          {
            icon: <GlassesCelebrate stroke="#6214bE" fill="#6214bE" />,
            iconName: 'GlassesCelebrate',
            benefits: [
              ' Excellent reward and recognition scheme',
              'Regular social events',
            ],
          },
        ],
      },
    ],
    imgName: 'mk_office_chill.jpg',
    imageFile: MiltonKeynesImageQuery.MKOfficeChill.gatsbyImageData,
    imgMaxHeightMob: 'max-h-360',
    imgHeightMob: 'h-360',
    imgHeight: 'md:h-780',
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxPurple"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        maxHeight="max-h-680"
        styling="pl-7 md:pt-93px md:pl-70px lg:pt-85px lg:pl-27px lg:pr-5px max-w-1350 relative z-1 lg:z-0"
      />
      <CareersNavigation />
      <OfficeHighlights data={officeHighlightsData} isMK />
      <ImageWithText
        data={whoWorksHereData}
        topCoverColorMobile="white"
        topCoverColor="pxGrey"
        isCareers
        isCareersWorks
      />
      <TwoColumnHeaderGrid shape="none" data={locationTeam} isLocation />
      <TwoColumnHTMLBanner data={videoData} />
      <HelpingYouThrive data={helpingYouThriveData} />
      <ImageWithText
        data={companyBenefits}
        topCoverColorMobile="pxTan"
        topCoverColor="pxTan"
        isCareers
        isCareersWorks
      />
      <JobsMK />
    </>
  );
};

export default MiltonKeynesPage;

export const Head: HeadFC = () => (
  <>
    <title>Careers with Policy Expert</title>
    <meta
      name="description"
      content="Become part of the team at Policy Expert - an award winning car and home insurance provider."
    />
    <html lang="en" />
  </>
);
