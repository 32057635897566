import React from 'react';

import scrollTo from 'gatsby-plugin-smoothscroll';

import RightSvg from '../../../static/svgIcons/Right-arrow-icon.svg';
import { Section } from '../../pages/contact';
import {
  BackToTopButton,
  HeaderLinksWrapper,
  TermsBlockContainer,
  TermsBlockLinks,
  TermsBlockLinkTitle,
  TermsBlockSection,
  TermsBlockSectionBody,
  TermsBlockSectionContainer,
  TermsBlockSectionTitle,
  TermsBlockWrapper,
  TermsLinkContainer,
  TermsLinkHeader,
} from './ImpactV2TermsBlock.style';

type ImpactV2TermsDataType = {
  heading: string;
  anchorLinkName?: string;
  body: React.JSX.Element;
}[];

export type ImpactV2TermsBlockDataType = {
  description?: React.JSX.Element;
  termsInformation: ImpactV2TermsDataType;
};

type Props = {
  data: ImpactV2TermsBlockDataType;
  disclosurePage?: boolean;
  section: Section;
};

const ImpactV2TermsBlock = ({ data, disclosurePage, section }: Props) => {
  const isExcludedHeading = (heading: string) =>
    (section === 'home' && heading === 'No-claim discount') ||
    (section === 'pet' &&
      (heading === 'No-claim discount' || heading === 'Our charges'));

  return (
    <section id="links-top">
      <TermsBlockContainer id={section}>
        <TermsBlockWrapper>
          {data.description}

          <HeaderLinksWrapper>
            <TermsLinkHeader>Jump to the relevant section</TermsLinkHeader>
            <TermsLinkContainer $disclosurePage={disclosurePage}>
              {data.termsInformation.map(
                (item, index) =>
                  !isExcludedHeading(item.heading) && (
                    <TermsBlockLinkTitle
                      key={`${item.heading}_${index}`}
                      href={`#${item.anchorLinkName ?? item.heading}`}
                    >
                      <RightSvg />
                      <TermsBlockLinks>{item.heading}</TermsBlockLinks>
                    </TermsBlockLinkTitle>
                  )
              )}
            </TermsLinkContainer>
          </HeaderLinksWrapper>

          {data.termsInformation.map(
            (item, index) =>
              !isExcludedHeading(item.heading) && (
                <section
                  key={`${item.heading}_${index}`}
                  id={item.anchorLinkName ?? item.heading}
                >
                  <TermsBlockSectionContainer $disclosurePage={disclosurePage}>
                    <TermsBlockSection
                      $hasTable={
                        item.heading === 'No-claim discount' ||
                        item.heading === 'Our charges'
                      }
                    >
                      {item.heading && (
                        <TermsBlockSectionTitle
                          $hasTitlePadding={
                            item.heading === 'No-claim discount' ||
                            item.heading === 'Our charges'
                          }
                        >
                          {item.heading}
                        </TermsBlockSectionTitle>
                      )}

                      <TermsBlockSectionBody>
                        {item.body}
                        {!disclosurePage && (
                          <BackToTopButton
                            onClick={() => scrollTo('#links-top')}
                            $hasTable={
                              item.heading === 'No-claim discount' ||
                              item.heading === 'Our charges'
                            }
                          >
                            Back to top
                          </BackToTopButton>
                        )}
                      </TermsBlockSectionBody>
                    </TermsBlockSection>
                  </TermsBlockSectionContainer>
                </section>
              )
          )}
        </TermsBlockWrapper>
      </TermsBlockContainer>
    </section>
  );
};

export default ImpactV2TermsBlock;
