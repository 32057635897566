import { styled } from 'styled-components';

import Minus from '../../../static/svgIcons/MinusV2.svg';
import Plus from '../../../static/svgIcons/PlusV2.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
} from '../../styles/sharedStyled';

export const AccordionContainer = styled.div<{
  $isExpanded: boolean;
  $isProductPage?: boolean;
}>`
  padding: ${({ $isProductPage }) =>
    $isProductPage ? '25px 20px' : '30px 20px'};
  border-bottom-width: 1px;
  border-color: ${colors.charcoal};
  background-color: ${({ $isExpanded }) =>
    $isExpanded ? `${colors.white}` : `${colors.gray3}`};

  &:first-child {
    border-radius: 20px 20px 0 0;
  }

  &:last-child {
    border-radius: 0 0 20px 20px;
    border: 0;
  }

  @media (min-width: 768px) {
    padding: ${({ $isProductPage }) => $isProductPage && '25px 30px'};
  }
`;

export const AccordionHeader = styled.p<{
  $isExpanded: boolean;
}>`
  color: ${({ $isExpanded }) =>
    $isExpanded ? `${colors.purple7}` : `${colors.pink2}`};
  font-size: 16px;
  font-family: ${({ $isExpanded }) =>
    $isExpanded ? `${fontFamilyCamptonMedium}` : `${fontFamilyCamptonBook}`};
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  padding-bottom: ${({ $isExpanded }) => ($isExpanded ? '32px' : '0')};
  border-bottom-width: ${({ $isExpanded }) => ($isExpanded ? '2px' : '0')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MinusIcon = styled(Minus)`
  height: 15px;
  width: 15px;
`;

export const PlusIcon = styled(Plus)`
  height: 15px;
  width: 15px;
`;
