import React, { ReactNode } from 'react';

import Icon from '../icons/Icon';
import ShapeX from '../ShapeX';

type ListType = {
  icon: ReactNode;
  title: string;
  content: string;
  contentLink?: {
    name: string;
    url: string;
  };
};

export type TwoColumnIconListDataType = {
  header: string;
  bgColor: string;
  content: string;
  list: ListType[];
};

type Props = {
  data: TwoColumnIconListDataType;
  shape?: boolean;
};

export default function TwoColumnIconList({ data, shape }: Props) {
  return (
    <div
      className={`${data.bgColor} pt-70px pb-32px md:pb-82px md:pt-130px relative overflow-hidden `}
    >
      <div className="padding-x grid md:grid-cols-2 relative md:gap-x-10 pb-8 md:pb-8">
        <div className="flex flex-col w-full">
          <h2 className=" max-w-510 text-pxCharcoal">{data.header} </h2>

          <p className="my-32px 1 max-w-510 text-pxCharcoal">{data.content} </p>
        </div>

        <ul className="flex flex-col w-full max-w-510 list-none">
          {data.list.map((item) => (
            <>
              <div className="flex justify-between" key={item.title}>
                <div className="mr-4 mt-3">
                  <Icon type="primary" size={1}>
                    {item.icon}
                  </Icon>
                </div>
                <div>
                  <li className="mb-4 w-5/6 pt-2 font-semibold text-pxCharcoal">
                    {item.title}
                  </li>
                  <p className="text-base md:text-xl text-pxCharcoal">
                    {item.content} {` `}
                    {item.contentLink && (
                      <a
                        href={item.contentLink.url}
                        className="text-base md:text-xl font-bold"
                      >
                        {item.contentLink.name}
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </>
          ))}
        </ul>
      </div>
      {shape ? <ShapeX position="bottom-left" /> : null}
    </div>
  );
}
