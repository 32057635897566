import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { ProductBannerDataType } from '../petProductBanner/PetProductBanner';
import { ProductBannerInformationWrapper } from '../petProductBanner/PetProductBanner.style';
import ProductTable from '../petProductTable/PetProductTable';
import ProductBannerPolicyDocuments, {
  ProductBannerPolicyDocumentsDataType,
} from '../productBannerPolicyDocuments/ProductBannerPolicyDocuments';
import Shape from '../Shape';
import ShapeX from '../ShapeX';
import { MainContainer, ProductBannerTitle } from './ProductBanner.style';

interface Props extends Pick<ProductBannerDataType, 'tableData'> {
  policyDocuments: ProductBannerPolicyDocumentsDataType;
  backgroundColor: string;
  v2Refresh?: boolean;
}

const ProductBanner = ({
  tableData,
  policyDocuments,
  backgroundColor,
  v2Refresh,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoint();
  const showShapeX = isTablet && !isMobile;

  return (
    <MainContainer
      $backgroundColor={backgroundColor}
      data-testid="product-banner"
    >
      <ProductBannerTitle>Take your pick</ProductBannerTitle>
      <ProductBannerInformationWrapper>
        <ProductTable tableData={tableData} />
        <ProductBannerPolicyDocuments
          {...policyDocuments}
          v2Refresh={v2Refresh}
        />
      </ProductBannerInformationWrapper>
      <Shape position="left-pet-product-table" />
      {showShapeX && <ShapeX position="bottom-left-product-table" />}
    </MainContainer>
  );
};

export default ProductBanner;
