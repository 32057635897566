import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../components/headerBanner/HeaderBanner';
import TwoColumnHTMLBanner from '../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../types/breakpoints';
import TermsCopyType from '../types/terms';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src="../images/payment_tc_pcl_sm.png"
        alt="Finance agreement. Premium Credit"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src="../images/payment_tc_pcl_md.png"
        alt="Finance agreement. Premium Credit"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src="../images/payment_tc_pcl_lg.png"
      alt="Finance agreement. Premium Credit"
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const debitCopy: TermsCopyType = {
  heading: 'The Direct Debit Guarantee',
  body: (
    <div>
      <p className="text-small">
        The Guarantee is offered by all banks and building societies that accept
        instruction to pay Direct Debits.
      </p>
      <p className="text-small">
        If there are any changes to the amount, date or frequency of your Direct
        Debit, Premium Credit Limited will notify you 5 working days in advance
        of your account being debited or as otherwise agreed.
      </p>
      <p className="text-small">
        If you request Premium Credit Limited to collect a payment, confirmation
        of the amount and date will be given to you at the time of the request.
      </p>
      <p className="text-small">
        If an error is made in the payment of your Direct Debit by Premium
        Credit Limited or your bank or building society, you are entitled to a
        full and immediate refund of the amount paid from your bank or building
        society.
      </p>
      <p className="text-small">
        If you receive a refund you are not entitled to, you must pay it back
        when Premium Credit Limited asks you to.
      </p>
      <p className="text-small mb-0">
        You can cancel a Direct Debit at any time by simply contacting your bank
        or building society. Written confirmation may be required. Please also
        notify Premium Credit Limited.
      </p>
    </div>
  ),
};

const loanCopy: TermsCopyType = {
  bgColor: 'bg-pxMint',
  heading: 'Loan Agreement Features',
  body: (
    <div>
      <p className="text-small">
        Policy Expert is registered with the Financial Conduct Authority as a
        Credit Broker and will arrange credit facilities for payment of your
        insurance by monthly instalments through an arrangement with the lender
        Premium Credit subject to your credit status. We need to share your data
        with Premium Credit and they may carry out credit, affordability and/or
        anti-money laundering checks on you. If they do, then a record of the
        search or other information may show on your credit file and might
        affect your credit rating. Policy Expert receives a commission from
        Premium Credit for facilitating your credit agreement but you do not get
        charged for this.
      </p>
      <p className="text-small">
        Your personal information and bank details will be passed to Premium
        Credit to set up this agreement and they will send you a welcome pack by
        email or post no later than 5 working days before the first collection.
        This contains important information including the mandatory 'Standard
        European Consumer Credit Information' document, a copy of the credit
        agreement, the Direct Debit guarantee and the terms and conditions.
        Please read all the information carefully to ensure it is right for you
        before signing the credit agreement.
      </p>
      <p className="text-small">
        You can withdraw free of charge from this agreement as detailed in the
        welcome pack.
      </p>
      <p className="text-small">
        If Premium Credit can’t collect a monthly payment they will charge a
        default fee of £25. This can affect your credit rating and might lead to
        the cancellation of your credit agreement. If this happened you would
        need to pay for your insurance in one payment and Policy Expert reserve
        the right to cancel the policy.
      </p>
      <p className="text-small">
        If you have any questions about the agreement, want to repay the loan or
        discuss making any changes to the agreement you can do so by contacting
        Premium Credit Limited on{' '}
        <a href="tel:03447369836" className="font-semibold">
          0344 736 9836
        </a>
        , emailing them at{' '}
        <a href="mailto:customer.services@pcl.co.uk" className="font-semibold">
          customer.services@pcl.co.uk
        </a>{' '}
        or by writing to them at: Premium Credit Limited, Ermyn House, Ermyn
        Way, Leatherhead KT22 8UX
      </p>
      <p className="font-semibold text-small mb-0">
        Payment Terms &amp; Conditions last updated: 2 August 2018
      </p>
    </div>
  ),
};

const PaymentTermsAndConditionsPCLPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxMint"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        styling="pt-2.5 pl-7 md:pt-26px md:pl-57px md:pr-24px lg:pt-9 lg:pl-23px max-w-1038"
      />
      <TwoColumnHTMLBanner data={debitCopy} />
      <TwoColumnHTMLBanner data={loanCopy} />
    </>
  );
};

export default PaymentTermsAndConditionsPCLPage;

export const Head: HeadFC = () => (
  <>
    <title>Payment terms and conditions | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
