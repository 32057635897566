import React from 'react';

import DeskTopImg from '../../../static/icons/ourjourney_desktop.svg';
import MobileTopImg from '../../../static/icons/ourjourney_mobile.png';

export type ImageWithTitleData = {
  bgColor: string;
  headerText: string;
  headerSubText?: string;
  imgName: string;
  imgNameMobile?: string;
};

type Props = {
  data: ImageWithTitleData;
  careers?: boolean;
};

const ImageWithTitle = ({ data, careers }: Props) => (
  <div className={`${data.bgColor}  ${careers ? '' : 'pb-82px'} md:pb-130px`}>
    <div className="padding-x flex flex-col justify-between">
      <div className="flex flex-col w-full md:w-1/2">
        {data.headerText && (
          <h2 className="my-32px md:mt-0 text-pxCharcoal">
            {data.headerText}{' '}
          </h2>
        )}
        {data.headerSubText && <p className="mb-32px">{data.headerSubText} </p>}
      </div>
      <div>
        {careers ? (
          <>
            <img
              className="mx-auto my-82px hidden md:block -mb-82px"
              src={DeskTopImg}
              alt="Diagram for Desktop"
            />
            <img
              className="mx-auto md:hidden"
              src={MobileTopImg}
              alt="Diagram for Mobile"
            />
          </>
        ) : null}
      </div>
    </div>
  </div>
);

export default ImageWithTitle;
