import React, { useEffect, useState } from 'react';

type Props = {
  title: React.ReactNode;
  body: React.ReactNode;
  first?: boolean;
  last?: boolean;
  handleOnClick: (index?: number) => void;
  index?: number;
  openIndex?: number;
  tableId: string;
  openTable?: string;
};

const CookieAccordion = ({
  title,
  body,
  first,
  last,
  handleOnClick,
  index,
  openIndex,
  tableId,
  openTable,
}: Props) => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (index !== undefined && index === openIndex && tableId === openTable) {
      setExpand(true);
      setTimeout(() => {
        const target = document.querySelector(
          `#${tableId}-${index}`
        ) as HTMLElement;
        window.scrollTo({
          top: target.getBoundingClientRect().top + window.scrollY,
          behavior: 'smooth',
        });
      }, 150);
    } else {
      setExpand(false);
    }
  }, [openIndex, openTable]);

  return (
    <div
      id={`${tableId}-${index}`}
      className={`p-30px border-b-2 border-pxMint bg-white ${
        first ? 'rounded-t-radius-5' : ''
      }  ${last ? 'rounded-b-radius-5 border-b-0' : ''}`}
    >
      <div
        onClick={() => {
          handleOnClick(index);
        }}
        className={'cursor-pointer mb-0 relative'}
      >
        {title}
        {expand ? (
          <div>{body}</div>
        ) : (
          <p className="text-fontSize-15 font-camptonSemiBold text-pxPink mb-0">
            More information
          </p>
        )}
      </div>
    </div>
  );
};
export default CookieAccordion;
