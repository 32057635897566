import React from 'react';

import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';

import WeatherAlertPost from '../components/weatherAlert/WeatherAlertPost';
import useDisclaimer from '../hooks/useDisclaimer';

const WeatherAlertPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const WeatherAlertImageQuery = useStaticQuery(graphql`
    query WeatherAlertImages {
      WeatherArticle1: imageSharp(
        original: { src: { regex: "/weatherArticle1/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      WeatherArticle2: imageSharp(
        original: { src: { regex: "/weatherArticle2/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      WeatherArticle3: imageSharp(
        original: { src: { regex: "/weatherArticle3/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const weatherData = {
    bgColor: 'bg-pxGrey',
    date: '1st March 2022',
    alertLevel: 2,
    heading: 'Flood warnings have been issued in your area',
    warning: 'Flooding is expected, immediate action required.',
    advice: (
      <p className="mb-0 text-small">
        For your own safety, please read and follow the government’s{' '}
        <a href="#" className="font-semibold">
          advice on what to do next.
        </a>
      </p>
    ),
    imgName: 'luke.jpg',
    announcements: [
      {
        heading: 'Areas affected',
        content:
          'Fishlake including Trundle Lane, Far Bank Lane, East Field Road, Dirty Lane, Main Street, Church Lane, Grove Road, Pinfold Lane, Sour Lane and Wood Lane.',
      },
      {
        heading: 'Updates',
        content:
          ' River levels remain high but are falling sowly at the Mythe Bridge river gauge as a result of recent heavy rainfall. Consequently, flooding continues. We expect flooding to afffect Mill Avon riverside property, minor roads at Tirlyey, Chaceley, Hasfield, Hawbridge, Lower Lode, Ashleworth, Deerhurst, Wainlode and Apperley. Also affected are properties and roads in and around Severn Ham, Tewkesbury. Access to Abbey Mill is compromised. The Upper Pound gauge peaked at 4.25am on Thursday afternoon, 24/02/2022 and the Mythe gauge peaked at 4.32pm on Thursday afternoon, 24/02/2022.',
      },
    ],
    buttons: [
      {
        label: 'Make a claim',
        url: '/claims',
        primary: 'primary',
      },
    ],
    relatedArticles: [
      {
        title: 'What to do when flooding is expected',
        imgName: 'weatherArticle1.jpg',
        imageFile: WeatherAlertImageQuery.WeatherArticle1.gatsbyImageData,
        url: 'https://blog.policyexpert.co.uk/house-home/what-to-do-when-flooding-is-expected/',
      },
      {
        title: 'Looking after your car in winter',
        imgName: 'weatherArticle2.jpg',
        imageFile: WeatherAlertImageQuery.WeatherArticle2.gatsbyImageData,
        url: 'https://blog.policyexpert.co.uk/motoring-cars/how-to-keep-your-car-happy-in-winter/',
      },
      {
        title: 'Tackling treacherous black ice this winter',
        imgName: 'weatherArticle3.jpg',
        imageFile: WeatherAlertImageQuery.WeatherArticle3.gatsbyImageData,
        url: 'https://blog.policyexpert.co.uk/motoring-cars/tackling-black-ice/',
      },
    ],
  };

  return (
    <>
      <WeatherAlertPost data={weatherData} />
    </>
  );
};

export default WeatherAlertPage;

export const Head: HeadFC = () => (
  <>
    <title>Weather Alert | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
