import React from 'react';

import { Section } from '../../pages/contact';
import ShapeX, { ShapePosition } from '../ShapeX';

type HeroBannerData = {
  title: React.JSX.Element;
  text: React.JSX.Element;
};

type HeaderBannerProps = {
  backgroundColor: string;
  heroBannerData: HeroBannerData;
  shapePosition?: ShapePosition;
  styling: string;
  maxHeight?: string;
  children: React.ReactNode;
  section: Section;
};

const ImpactButtonsV2HeaderBanner = ({
  backgroundColor,
  shapePosition,
  styling,
  maxHeight,
  heroBannerData,
  children,
  section,
}: HeaderBannerProps) => {
  return (
    <div
      className={`${backgroundColor} ${
        maxHeight ? maxHeight : 'max-h-700'
      } relative overflow-hidden ${section && 'mb-35-minus md:mb-80-minus '} `}
    >
      <div
        className={`${styling} flex flex-col items-center px-24px pt-40px pb-100px md:px-40px lg:pt-140px`}
      >
        <div>
          {heroBannerData.title}
          {heroBannerData.text}
        </div>
        <div className="w-full">{children}</div>
      </div>
      {shapePosition && <ShapeX position={shapePosition} />}
    </div>
  );
};
export default ImpactButtonsV2HeaderBanner;
