import React from 'react';

import { HeadFC } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import ImpactLoginV2HeaderBanner from '../components/headerBanner/ImpactLoginV2HeaderBanner';
import { ProductData } from '../components/productButtons/LoginProductButtons';
import LoginProductButtonsBanner, {
  LoginProductButtonsType,
} from '../components/productButtonsBanner/LoginProductButtonsBanner';
import config from '../config';
import useDisclaimer from '../hooks/useDisclaimer';
import usePetChanges from '../hooks/usePetChanges';
import { HeroBannerText, HeroBannerTitle, ImageContainer } from './login.style';

const heroBannerData = {
  title: <HeroBannerTitle>Let's get you logged in</HeroBannerTitle>,
  text: <HeroBannerText>Please select your product</HeroBannerText>,
};

const productSelectionData: LoginProductButtonsType = [
  {
    buttonHeader: 'Home insurance',
    product: 'home',
    url: config.HOME_LOGIN_URL,
  },
  {
    buttonHeader: 'Car insurance',
    product: 'car',
    url: config.CAR_LOGIN_URL,
  },
];

const LoginPage: React.FC = () => {
  useDisclaimer(null);
  const isPetChangesEnabled = usePetChanges();

  const headerImage = (
    <ImageContainer $isPetChangesEnabled={isPetChangesEnabled}>
      <StaticImage
        src="../images/login_hero.png"
        alt="Log in"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    </ImageContainer>
  );

  const petSelectionData: ProductData = {
    buttonHeader: 'Pet insurance',
    product: 'pet',
    url: config.HOME_LOGIN_URL,
  };

  const impactProductSelectionData: LoginProductButtonsType =
    isPetChangesEnabled
      ? [...productSelectionData, petSelectionData]
      : productSelectionData;

  return (
    <div className="overflow-hidden">
      <ImpactLoginV2HeaderBanner
        backgroundColor="bg-pxTan"
        headerImage={headerImage}
        heroBannerData={heroBannerData}
        shapePosition={'bottom-left-claims-header-V2'}
        styling={`m-auto px-24px lg:p-0`}
      >
        <LoginProductButtonsBanner
          productSelectionData={impactProductSelectionData}
        />
      </ImpactLoginV2HeaderBanner>
    </div>
  );
};

export default LoginPage;

export const Head: HeadFC = () => (
  <>
    <title>Customer login</title>
    <meta
      name="description"
      content="Login to your home or car account where you can access your policy documents, find important information and claims telephone numbers."
    />
    <html lang="en" />
  </>
);
