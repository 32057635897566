import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { HecBannerDataType } from '../hecProductBanner/HecProductBanner';
import {
  DetailsCell,
  HeaderCellWrapper,
  HeaderWrapper,
  InfoCloseIcon,
  InfoIconContainer,
  InfoOpenIcon,
  LabelCell,
  LabelContainer,
  Row,
  RowContainer,
  TableContainer,
  TableRowsContainer,
  ValueCell,
} from './HomeEmergencyTable.style';

const HomeEmergencyTable = ({
  tableData,
}: Pick<HecBannerDataType, 'tableData'>) => {
  const [expanded, setExpanded] = React.useState<number | boolean>(false);

  const { isDesktop } = useBreakpoint();

  const onClick = (index: number) => {
    const expandedRow = document.querySelector(`#row-${index}`);
    if (expanded === index) {
      setExpanded(false);
    } else {
      setExpanded(index);
      if (expandedRow && typeof window !== 'undefined') {
        setTimeout(() => {
          window.scrollTo({
            top:
              (expandedRow.getBoundingClientRect().top || 0) +
              window.scrollY -
              (isDesktop ? 80 : 0),
            behavior: 'smooth',
          });
        }, 15);
      }
    }
  };

  return (
    <TableContainer>
      <HeaderWrapper>
        {tableData.tableHeaderIcons.map((icon, index) => (
          <HeaderCellWrapper key={`${icon!.toString()}_${index}`}>
            {icon}
          </HeaderCellWrapper>
        ))}
      </HeaderWrapper>
      <TableRowsContainer>
        {tableData.tableContent.map((rowData, index) => {
          const isExpanded = expanded === index;
          return (
            <RowContainer
              $isExpanded={isExpanded}
              key={`${rowData.label.toString()}_container_${index}`}
            >
              {rowData.hasInfoRow && tableData.tableInfoRow}
              <Row
                $isExpanded={isExpanded}
                role="row"
                id={`row-${index}`}
                key={`${rowData.label.toString()}_${index}`}
              >
                <LabelCell
                  onClick={() => {
                    onClick(index);
                  }}
                  $isExpanded={isExpanded}
                  role="cell"
                >
                  <LabelContainer $isExpanded={isExpanded}>
                    {rowData.label}
                    <InfoIconContainer>
                      {isExpanded ? (
                        <InfoCloseIcon data-testid="info-close" />
                      ) : (
                        <InfoOpenIcon data-testid="info-open" />
                      )}
                    </InfoIconContainer>
                  </LabelContainer>
                </LabelCell>
                <DetailsCell $isExpanded={isExpanded} role="cell">
                  {rowData.details}
                </DetailsCell>
                {rowData.values.map((value, index) => {
                  return (
                    <ValueCell
                      $isExpanded={isExpanded}
                      key={`${value.toString()}_${index}`}
                    >
                      {value}
                    </ValueCell>
                  );
                })}
              </Row>
            </RowContainer>
          );
        })}
      </TableRowsContainer>
    </TableContainer>
  );
};

export default HomeEmergencyTable;
