import React, { ReactNode } from 'react';

interface IconsProps {
  children: ReactNode;
  style?: any;
  stroke?: string;
  fill?: string;
  type?: 'secondary' | 'primary';
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string;
}

const Icon = (props: IconsProps) => {
  const { children, type, size, className } = props;
  if (!children || children === null) {
    return <div />;
  }

  return (
    <div className={`icons-${type}${size} z-50 ${className || ''}`}>
      {children}
    </div>
  );
};

export default Icon;
