import { styled } from 'styled-components';

import { fontFamilyCamptonBook } from '../../styles/sharedStyled';

export const EmergencyDefinition = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: normal;
  margin-bottom: 15px;
`;

export const ReasonsList = styled.ul`
  padding-left: 24px;
`;

export const Reason = styled.li`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: normal;
  margin-bottom: 0;
  list-style-position: outside;
`;
