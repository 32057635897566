import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export type SlantDirection =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';
type Props = {
  backgroundColor: string;
  margin?: string;
  slantDirection: SlantDirection;
  height?: boolean;
  children?: React.ReactNode;
  id?: string;
  hideOverflow?: boolean;
};

type GetClipPathProps = {
  slantDirection: SlantDirection;
  isMobile: boolean;
  isTablet: boolean;
  height?: boolean;
};

const getClipPath = ({
  slantDirection,
  isMobile,
  isTablet,
  height,
}: GetClipPathProps) => {
  switch (slantDirection) {
    case 'top-left':
      if (height) {
        return 'polygon(0% 90%, 100% 0%, 100% 100%, 0% 100%)';
      }
      if (isMobile || isTablet) {
        return 'polygon(0% 14%, 100% 0%, 100% 100%, 0% 100%)';
      }
      return 'polygon(0% 10%, 100% 0%, 100% 100%, 0% 100%)';
    case 'top-right':
      if (height) {
        return 'polygon(0% 0%, 100% 90%, 100% 100%, 0% 100%)';
      }
      return 'polygon(0% 0%, 100% 20%, 100% 100%, 0% 100%)';
    case 'bottom-left':
      return 'polygon(0 0, 100% 0, 100% 92%, 0% 100%)';
    case 'bottom-right':
      if (!height) {
        return 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 92%)';
      }
      return isMobile
        ? 'polygon(0 0, 100% 15%, 100% 100%, 0% 100%)'
        : 'polygon(0 0, 100% 40%, 100% 100%, 0% 100%)';
  }
};

const SlantedBackground: React.FC<Props> = ({
  backgroundColor,
  margin,
  height,
  slantDirection,
  children,
  id,
  hideOverflow,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoint();
  const clipPath = getClipPath({
    slantDirection,
    isMobile,
    isTablet,
    height,
  });
  return (
    <div
      id={id}
      className={`${backgroundColor} relative z-100 ${
        hideOverflow ? 'overflow-hidden' : ''
      } ${margin ? margin : ''} ${height ? 'h-35px md:h-80px' : ''}`}
      style={{
        clipPath: clipPath,
        transform: 'translateZ(0)',
      }}
    >
      {children}
    </div>
  );
};

export default SlantedBackground;
