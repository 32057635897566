import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../components/headerBanner/HeaderBanner';
import TwoColumnHTMLBanner from '../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../types/breakpoints';
import TermsCopyType from '../types/terms';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src="../images/payment_tc_pcl_sm.png"
        alt="Finance agreement. Premium Credit"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src="../images/payment_tc_pcl_md.png"
        alt="Finance agreement. Premium Credit"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src="../images/payment_tc_pcl_lg.png"
      alt="Finance agreement. Premium Credit"
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const loanCopy: TermsCopyType = {
  heading: 'Loan terms and conditions',
  body: (
    <div>
      <p className="text-small">
        Your personal information and bank details will be passed to Premium
        Credit to set up this agreement and they will send you a welcome pack by
        email or post no later than 5 working days before the first collection.
        This contains important information including the mandatory 'Standard
        European Consumer Credit Information' document, a copy of the credit
        agreement, the Direct Debit guarantee and the terms and conditions.
        Please read all the information carefully to ensure it is right for you
        before signing the credit agreement.
      </p>
      <p className="text-small">
        Within 14 days of entering into the credit agreement you have the right
        to withdraw without charge. If you do so, then your credit agreement
        will be cancelled as if it never existed and will not appear on your
        credit report. If you cancel after 14 days, then your credit agreement
        will still show on your credit report as a closed loan.
      </p>
      <p className="text-small">
        If you have questions about the agreement, want to repay the loan or
        discuss making any changes to the agreement you can do so by contacting
        PCL on:
      </p>
      <p className="text-small mb-0">
        Phone:{' '}
        <a href="tel:03447369836" className="font-semibold">
          0344 736 9836
        </a>
      </p>
      <p className="text-small mb-0">
        Email:{' '}
        <a href="mailto:customer.services@pcl.co.uk" className="font-semibold">
          customer.services@pcl.co.uk
        </a>
      </p>
      <p className="text-small mb-0">
        Website:{' '}
        <a href="https://www.premiumcredit.com/" className="font-semibold">
          https://www.premiumcredit.com/
        </a>
      </p>
      <p className="text-small mb-0">
        Letter:{' '}
        <span className="font-semibold">
          Premium Credit Limited, Ermyn House, Ermyn Way, Leatherhead, KT22 8UX
        </span>
      </p>
    </div>
  ),
};

const debitCopy: TermsCopyType = {
  bgColor: 'bg-pxMint',
  heading: 'Direct Debit Guarantee',
  body: (
    <div>
      <p className="text-small">
        The Guarantee is offered by all banks and building societies that accept
        instruction to pay Direct Debits.
      </p>
      <p className="text-small">
        If there are any changes to the amount, date or frequency of your Direct
        Debit, Premium Credit Limited will notify you 5 working days in advance
        of your account being debited or as otherwise agreed.
      </p>
      <p className="text-small">
        If you request Premium Credit Limited to collect a payment, confirmation
        of the amount and date will be given to you at the time of the request.
      </p>
      <p className="text-small">
        If an error is made in the payment of your Direct Debit by Premium
        Credit Limited or your bank or building society, you are entitled to a
        full and immediate refund of the amount paid from your bank or building
        society.
      </p>
      <p className="text-small">
        If you receive a refund you are not entitled to, you must pay it back
        when Premium Credit Limited asks you to.
      </p>
      <p className="text-small">
        You can cancel a Direct Debit at any time by simply contacting your bank
        or building society. Written confirmation may be required. Please also
        notify Premium Credit Limited.
      </p>
    </div>
  ),
};

const PaymentTermsAndConditionsPCLMotorPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxMint"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        styling="pt-2.5 pl-7 md:pt-26px md:pl-57px md:pr-24px lg:pt-9 lg:pl-23px max-w-1038"
      />
      <TwoColumnHTMLBanner data={loanCopy} />
      <TwoColumnHTMLBanner data={debitCopy} />
    </>
  );
};

export default PaymentTermsAndConditionsPCLMotorPage;

export const Head: HeadFC = () => (
  <>
    <title>Payment terms and conditions | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
