import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import PetProductTable from '../petProductTable/PetProductTable';
import ProductBannerPolicyDocuments, {
  ProductBannerPolicyDocumentsDataType,
} from '../productBannerPolicyDocuments/ProductBannerPolicyDocuments';
import Shape from '../Shape';
import {
  CatTailImageContainer,
  PawPrintsBottomImageContainer,
  ProductBannerBackgroundContainer,
  ProductBannerImagesContainer,
  ProductBannerInformationWrapper,
  TermsAndConditionsStatement,
} from './PetProductBanner.style';

type TableContentType = {
  header?: React.JSX.Element;
  productRows: {
    label: React.JSX.Element;
    values: React.JSX.Element[];
  }[];
};

type TableDataType = {
  tableHeaderIcons: React.ReactNode[];
  tableContent: TableContentType[];
};

export type ProductBannerDataType = {
  bannerTitle: React.ReactNode;
  bannerText: React.ReactNode;
  tableData: TableDataType;
};

type Props = {
  productBannerData: ProductBannerDataType;
  policyDocuments: ProductBannerPolicyDocumentsDataType;
  backgroundColor: string;
};

const pawPrintsBottomImage = (
  <PawPrintsBottomImageContainer>
    <StaticImage
      src="../../images/paw_prints_bottom.png"
      alt="Pattern of paw prints"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  </PawPrintsBottomImageContainer>
);

const catTailImage = (
  <CatTailImageContainer>
    <StaticImage
      src="../../images/cat_tail.png"
      alt="Cat tail"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  </CatTailImageContainer>
);

const PetProductBanner = ({
  policyDocuments,
  backgroundColor,
  productBannerData,
}: Props) => {
  return (
    <ProductBannerBackgroundContainer
      $backgroundColor={backgroundColor}
      data-testid="product-banner"
    >
      <Shape position="left-pet-product-table" />
      <ProductBannerInformationWrapper>
        {productBannerData.bannerTitle}
        {productBannerData.bannerText}
        <PetProductTable tableData={productBannerData.tableData} />
        <TermsAndConditionsStatement>
          Terms and conditions apply. For more information, check out the policy
          wording below.
        </TermsAndConditionsStatement>
      </ProductBannerInformationWrapper>
      <ProductBannerPolicyDocuments {...policyDocuments} />
      <ProductBannerImagesContainer>
        {pawPrintsBottomImage}
        {catTailImage}
      </ProductBannerImagesContainer>
    </ProductBannerBackgroundContainer>
  );
};

export default PetProductBanner;
