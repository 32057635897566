import { styled } from 'styled-components';

import { colors, fontFamilyCamptonMedium } from '../../styles/sharedStyled';

export const ImpactButtons = styled.button<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: ${fontFamilyCamptonMedium};
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 16px;
  height: 65px;
  background-color: ${({ $isActive }) =>
    $isActive ? colors.purple11 : colors.white};
  border: 2px solid
    ${({ $isActive }) => ($isActive ? colors.purple7 : colors.gray1)};
  padding: 13px 25px;

  @media (min-width: 371px) and (max-width: 767px) {
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 220px;
    height: 60px;
    padding: 13px 18px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    width: 200px;
    height: 116px;
    padding: 18px 20px;
  }
`;

export const ContentsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 200px;

  @media (min-width: 371px) and (max-width: 767px) {
    margin-right: 40px;
  }

  @media (min-width: 1024px) {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: center;
  margin: 0 20px 0 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    width: auto;
    margin: 0 15px 0 0;
  }

  @media (min-width: 1024px) {
    width: auto;
    min-height: 40px;
    margin: 0 0 15px;
  }
`;
