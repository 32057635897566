import { css, styled } from 'styled-components';

import PetBronze from '../../../static/svgIcons/PetBronze.svg';
import PetGold from '../../../static/svgIcons/PetGold.svg';
import PetSilver from '../../../static/svgIcons/PetSilver.svg';
import Tick from '../../../static/svgIcons/TickAngles.svg';
import Cross from '../../../static/svgIcons/xTablePink.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

const tableText = styled.p`
  margin-bottom: 0;
  line-height: normal;
`;

const tableIconsSize = css`
  height: 55px;
  width: 55px;

  @media (min-width: 768px) {
    height: 63px;
    width: 63px;
  }
`;

export const TableContainer = styled.div`
  margin: 0 15px;
  z-index: 100;
  position: relative;

  @media (min-width: 768px) {
    margin: 0 40px;
  }

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

export const TableRowsContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 0 0 10px 10px;
  border-bottom: 1px solid ${colors.gray3};

  @media (min-width: 768px) {
    border-radius: 20px 0 20px 20px;
  }

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

export const TableSection = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray3};
  }
`;

export const HeaderRow = styled.div<{ $isFirstHeader: boolean }>`
  position: relative;
  width: 100%;
  background-color: ${colors.purple7Alpha5};
  padding: 15px 10px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 20px 30px;
    text-align: left;
    border-bottom: 1px solid ${colors.gray3};
    border-radius: ${({ $isFirstHeader }) =>
      $isFirstHeader ? '20px 0 0 0' : '0'};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  border-bottom: 1px solid ${colors.gray3};
  background: linear-gradient(
    180deg,
    ${colors.gray10Translucent} 0.02%,
    ${colors.transparentPurple} 10%
  );

  @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
    background: none;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  width: 100px;
  flex: 1;
  padding: 15px 20px;

  &:first-child {
    border-bottom: 1px solid ${colors.gray3};
    flex-basis: 100%;
    text-align: center;
    justify-content: center;

    @media (min-width: 768px) {
      flex-grow: 1;
      margin-right: auto;
      text-align: left;
      justify-content: flex-start;
      padding: 20px 30px;
      border: none;
    }
  }

  @media (min-width: 768px) {
    min-width: 150px;

    &:not(:last-child) {
      border-right: 1px solid ${colors.gray3};
    }
  }

  &:not(:first-child)&:not(:last-child) {
    border-right: 1px solid ${colors.purple7Alpha30};

    @media (min-width: 768px) {
      border-right: 1px solid ${colors.gray3};
    }
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  border-radius: 10px 10px 0 0;

  @media only screen and (min-width: 768px) {
    max-width: 450px;
    justify-content: flex-end;
    margin-right: 0;
    border-radius: 20px 20px 0 0;
  }
`;

export const HeaderCellWrapper = styled.div`
  width: 100px;
  position: relative;
  border-bottom: 1px solid ${colors.gray3};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;

  @media (min-width: 768px) {
    padding: 35px 0;
  }

  &:not(:last-child) {
    border-right: 1px solid ${colors.purple7Alpha30};

    @media (min-width: 768px) {
      border-right: 1px solid ${colors.gray3};
    }
  }
`;

export const ProductTableHeader = styled(tableText)`
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 15px;
  color: ${colors.purple7};
`;

export const ProductTableHeaderSpan = styled.span`
  font-family: ${fontFamilyCamptonBook};
`;

export const CrossIcon = styled(Cross)`
  height: 14px;
  width: 14px;
`;

export const TickIcon = styled(Tick)`
  height: 12px;
  width: 17px;
`;

export const ProductTableLabel = styled(tableText)`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 15px;

  @media (min-width: 768px) {
    font-family: ${fontFamilyCamptonBook};
  }
`;

export const ProductTableLabelSubText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 10px;
  margin: 0;
  line-height: normal;
`;

export const ProductTableValue = styled(tableText)`
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  color: ${colors.purple7};
`;

export const PetBronzeIcon = styled(PetBronze)`
  ${tableIconsSize}
`;

export const PetSilverIcon = styled(PetSilver)`
  ${tableIconsSize}
  margin-top: 6px;
`;

export const PetGoldIcon = styled(PetGold)`
  ${tableIconsSize}
`;
