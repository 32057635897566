import React from 'react';

import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import BreakArea from '../../../../static/svgIcons/BreakArea.svg';
import Car from '../../../../static/svgIcons/Car.svg';
import GlassesCelebrate from '../../../../static/svgIcons/GlassesCelebrate.svg';
import HeartHealth from '../../../../static/svgIcons/HeartHealth.svg';
import HotDrinks from '../../../../static/svgIcons/HotDrinks.svg';
import IconicBuilding from '../../../../static/svgIcons/IconicBuilding.svg';
import Parking from '../../../../static/svgIcons/Parking.svg';
import ThumbsUp from '../../../../static/svgIcons/ThumbsUp.svg';
import CareersNavigation from '../../../components/careersNavigation/CareersNavigation';
import HeaderBanner from '../../../components/headerBanner/HeaderBanner';
import HelpingYouThrive, {
  HelpingYouThriveType,
} from '../../../components/helpingYouThrive/HelpingYouThrive';
import ImageWithText, {
  ImageWithTextDataType,
} from '../../../components/imageWithText/ImageWithText';
import JobsMotherwell from '../../../components/jobs/JobsMotherwell';
import OfficeHighlights, {
  OfficeHighlightsDataType,
} from '../../../components/officeHighlights/OfficeHighlights';
import TwoColumnHeaderGrid, {
  TwoColumnHeaderGridType,
} from '../../../components/twoColumnHeaderGrid/TwoColumnHeaderGrid';
import TwoColumnHTMLBanner from '../../../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../../images/motherwell_sm.png`}
        alt={`Motherwell office. Car insurance HQ`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../../images/motherwell_md.png`}
        alt={`Motherwell office. Car insurance HQ`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../../images/motherwell_lg.png`}
      alt={`Motherwell office. Car insurance HQ`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const officeHighlightsData: OfficeHighlightsDataType = {
  bgColor: 'bg-pxGrey',
  headerText: 'Office highlights',
  subHeaderText: [
    'Our newly refurbished contact centre in Motherwell provides an environment of collaboration and teamwork.',
    'The Motherwell office is packed full of opportunities to deliver exceptional service to customers and progress your career.',
  ],
  location: 'Eurocentral, Motherwell',
  commuteDetails: [
    {
      icon: <Car className="w-32px h-20px" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Glasgow <br className="md:hidden" />
          <span className="text-footerCopy md:text-finerDetailsBodyCopy">
            (13 miles)
          </span>
        </p>
      ),
    },
    {
      icon: <Car className="w-32px h-20px" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Edinburgh <br className="md:hidden" />
          <span className=" text-footerCopy md:text-finerDetailsBodyCopy">
            (34 miles)
          </span>
        </p>
      ),
    },
  ],
  locationBenefits: [
    {
      icon: <IconicBuilding stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          New, spacious, open plan office
        </p>
      ),
    },
    {
      icon: <HotDrinks stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Free hot/cold drinks and fruit
        </p>
      ),
    },
    {
      icon: <BreakArea stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Break out and dedicated quiet areas
        </p>
      ),
    },
    {
      icon: <Parking stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Free parking and subsidised local bus service
        </p>
      ),
    },
  ],
};

const videoData = {
  bgColor: 'bg-pxGrey',
  heading: 'Want to see more?',
  subText: 'Watch our video!',
  body: (
    <div>
      <div className="relative h-0 pb-56.25">
        <iframe
          className="absolute top-0 left-0 w-full h-full border-0 border-none"
          src="https://player.vimeo.com/video/729915158"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Policy Expert - insurance for people like you - October 2021"
        />
      </div>
    </div>
  ),
};

const helpingYouThriveData: HelpingYouThriveType = {
  bgColor: 'pxMint',
  header: 'Helping you thrive',
  strongCopy:
    'Everyone who joins our team in Motherwell goes through an induction programme delivered by our team of dedicated coaches.',
  introCopy: [
    'This process is designed to ensure you have all the skills and knowledge necessary to add value in your role.',
    'During your induction, you’ll get to know the world of insurance, our products and how we deliver an exceptional customer experience.',
    'Training will be delivered in a variety of ways: including both face to face and online sessions. You’ll always be in a safe and supportive atmosphere to help you get the best out of your induction.',
    'Don’t worry, after your induction there’ll be plenty of people around to provide you with support and answer any questions you have.',
  ],
  listTitle: 'Continuous learning',
  listParagraph:
    'We want to help you progress and build your skills to get you where you want to be. Here are the key highlights of our learning and development programme:',
  listItems: [
    {
      icon: 'Tick',
      text: (
        <p className="text-base md:text-xl ml-3 flex-1">
          We’re passionate about helping you to develop your industry knowledge
          – and recognise the value of professional qualifications. Which is why
          we offer a range of opportunities to study towards{' '}
          <span className="font-camptonSemiBold">CII qualifications</span>.
        </p>
      ),
    },
    {
      icon: 'Tick',
      text: (
        <p className="text-base md:text-xl ml-3 flex-1">
          Our online{' '}
          <span className="font-camptonSemiBold">
            Learning Management System
          </span>{' '}
          also offers an extensive range of personal development topics in the
          form of eLearning.
        </p>
      ),
    },
    {
      icon: 'Tick',
      text: (
        <p className="text-base md:text-xl ml-3 flex-1">
          <span className="font-camptonSemiBold">On the job learning</span>{' '}
          plays a key part in role development. Your buddies, team managers and
          learning and development colleagues will support you every step of the
          way through further training and refresher sessions.
        </p>
      ),
    },
  ],
};

const MotherwellPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const MotherwellImageQuery = useStaticQuery(graphql`
    query MotherwellImages {
      MotherwellOfficeChillout: imageSharp(
        original: { src: { regex: "/motherwell_office_chillout/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Gary: imageSharp(original: { src: { regex: "/gary/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Vicky: imageSharp(original: { src: { regex: "/vicky/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      MotherwellOfficeKitchen: imageSharp(
        original: { src: { regex: "/motherwell_office_kitchen/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const whoWorksHereData: ImageWithTextDataType = {
    bgColor: 'bg-white',
    imageLeft: false,
    blendsWithTop: true,
    copyBlock: [
      {
        headerText: 'Who works here',
        subHeader:
          'Our talented customer facing teams in Motherwell are responsible for assisting our car insurance operation.',
        listText: [
          'New Business',
          'Renewals',
          'Customer Service',
          'Learning and Development',
        ],
      },
    ],
    imgName: 'motherwell_office_chillout.jpg',
    imageFile: MotherwellImageQuery.MotherwellOfficeChillout.gatsbyImageData,
    imgMaxHeightMob: 'max-h-360',
    imgHeightMob: 'h-360',
    imgHeight: 'md:h-860',
  };

  const locationTeam: TwoColumnHeaderGridType = {
    bgColor: 'white',
    header: 'What’s it like to work here?',
    subText: 'Meet some of the Motherwell team',
    items: [
      {
        imgName: 'gary.jpg',
        imageFile: MotherwellImageQuery.Gary.gatsbyImageData,
        header: 'Gary Elliot',
        subText: ['L&D Manager', 'Motherwell'],
        button: {
          label: 'Read more',
          url:
            '/about-us/careers/working-here/#selected-section?name=' +
            'GarryElliot' +
            '',
          primary: false,
        },
      },
      {
        imgName: 'vicky.jpg',
        imageFile: MotherwellImageQuery.Vicky.gatsbyImageData,
        header: 'Vicky Imrie',
        subText: ['Sales & Service Manager', 'Motherwell'],
        button: {
          label: 'Read more',
          url:
            '/about-us/careers/working-here/#selected-section?name=' +
            'VickyImrie' +
            '',
          primary: false,
        },
      },
    ],
  };

  const companyBenefits: ImageWithTextDataType = {
    bgColor: 'bg-pxTan',
    imageLeft: false,
    blendsWithTop: false,
    copyBlock: [
      {
        headerText: 'Company benefits',
        subTextIcon: [
          {
            icon: <ThumbsUp stroke="#6214bE" fill="#6214bE" />,
            iconName: 'ThumbsUp',
            benefits: [
              'Competitive salary',
              'Holidays',
              'Pension',
              'Free parking',
            ],
          },
          {
            icon: <HeartHealth stroke="#6214bE" fill="#6214bE" />,
            iconName: 'HeartHealth',
            benefits: [
              'Access to mental health support',
              'Health and wellbeing scheme',
              'Free fruit and healthy snacks',
              'Free lifestyle discounts',
              'Gym discounts',
            ],
          },
          {
            icon: <GlassesCelebrate stroke="#6214bE" fill="#6214bE" />,
            iconName: 'GlassesCelebrate',
            benefits: [
              ' Excellent reward and recognition scheme',
              'Regular social events',
            ],
          },
        ],
      },
    ],
    imgName: 'motherwell_office_kitchen.jpg',
    imageFile: MotherwellImageQuery.MotherwellOfficeKitchen.gatsbyImageData,
    imgMaxHeightMob: 'max-h-360',
    imgHeightMob: 'h-360',
    imgHeight: 'md:h-780',
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxTan"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        maxHeight="max-h-680"
        styling="pt-42px md:pt-110px lg:pt-125px lg:pl-38px lg:pr-17px max-w-1214 relative z-1"
      />
      <CareersNavigation />
      <OfficeHighlights data={officeHighlightsData} />
      <ImageWithText
        data={whoWorksHereData}
        topCoverColorMobile="white"
        topCoverColor="pxGrey"
        isCareers
        isCareersWorks
      />
      <TwoColumnHeaderGrid shape="none" data={locationTeam} isLocation />
      <TwoColumnHTMLBanner data={videoData} />
      <HelpingYouThrive data={helpingYouThriveData} />
      <ImageWithText
        data={companyBenefits}
        topCoverColorMobile="pxTan"
        topCoverColor="pxTan"
        isCareers
        isCareersWorks
      />
      <JobsMotherwell />
    </>
  );
};

export default MotherwellPage;

export const Head: HeadFC = () => (
  <>
    <title>Careers with Policy Expert</title>
    <meta
      name="description"
      content="Become part of the team at Policy Expert - an award winning car and home insurance provider."
    />
    <html lang="en" />
  </>
);
