import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Ipid, { IpidProps } from '../../components/ipid/Ipid';
import { IpidContent } from '../../components/ipid/Ipid.style';
import IpidHeader from '../../components/ipidHeader/IpidHeader';
import {
  IpidHeaderTitle,
  IpidSubHeaderContainer,
  IpidSubHeaderText,
} from '../../components/ipidHeader/IpidHeader.style';
import Shape from '../../components/Shape';
import config from '../../config';
import useDisclaimer from '../../hooks/useDisclaimer';
import { MotorDocsContainer, SemiBoldSpan } from '../../styles/sharedStyled';

const headerData = {
  header: <IpidHeaderTitle>Max policy wording</IpidHeaderTitle>,
  subHeader: (
    <IpidSubHeaderContainer>
      <IpidSubHeaderText>
        The products outlined in this booklet only apply to you if shown in your
        Insurance Welcome Letter and Optional Extras Schedule.
        <SemiBoldSpan>
          View the document based on when you bought your policy.
        </SemiBoldSpan>
      </IpidSubHeaderText>
    </IpidSubHeaderContainer>
  ),
};

const carData: IpidProps = {
  sectionTitle: 'Car insurance',
  data: [
    {
      heading: 'If your purchase date is on or after the 1st June 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_0323.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between the 1st Jul 2022 and the 31st of May 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_0622.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 19th Nov 2021 and 30th Jun 2022',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_1021.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 23rd Jun 2021 and 18th Nov 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_jun21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 3rd Feb 2021 and 22nd Jun 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_jan21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 10th Nov 2020 and 2nd Feb 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_sept20.pdf`,
        },
      ],
    },
    {
      heading: 'Between 1st Jul 2020 and 9th Nov 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_max_car.pdf`,
        },
      ],
    },
    {
      heading: 'Between 14th January 2020 and 30th Jun 2020',
      links: [
        {
          linkText: 'For policy numbers starting with STB',
          url: `${config.DOCUMENTS_URL}/motor/surething_optionalextras_max_14jan20_stabilis.pdf`,
        },
        {
          url: `${config.DOCUMENTS_URL}/motor/surething_optionalextras_max_14jan20.pdf`,
          linkText: 'For all other policy numbers',
        },
      ],
    },
  ],
};

const vanData: IpidProps = {
  sectionTitle: 'Van insurance',

  data: [
    {
      heading: 'If your purchase date is on or after the 1st June 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_0323.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between the 1st Jul 2022 and the 31st of May 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_0622.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 19th Nov 2021 and 30th Jun 2022',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_1021.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 23rd Jun 2021 and 18th Nov 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_jun21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 3rd Feb 2021 and 22nd Jun 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/max_wording_jan21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 10th Nov 2020 and 2nd Feb 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_max_car.pdf`,
        },
      ],
    },
    {
      heading: 'Between 14th January 2020 and 30th Jun 2020',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/surething_optionalextras_max_14jan20.pdf`,
          linkText: 'View document',
        },
      ],
    },
  ],
};

const MotorDocsMaxPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isDesktop } = useBreakpoint();

  return (
    <MotorDocsContainer>
      <IpidHeader {...headerData} />
      <IpidContent>
        <Ipid {...carData} />
        <Ipid {...vanData} />
      </IpidContent>
      {isDesktop && <Shape position="right-policy-books" />}
      {isDesktop && <Shape position="left-policy-books" />}
    </MotorDocsContainer>
  );
};

export default MotorDocsMaxPage;

export const Head: HeadFC = () => (
  <>
    <title>Car insurance documents | Policy Expert</title>
    <meta
      name="description"
      content="Car insurance policy documents. Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
