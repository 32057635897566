import React, { ReactNode } from 'react';

import { ButtonType } from '../../types/button';
import {
  ImpactContactBanner,
  ImpactContactBannerCardButtons,
  ImpactContactBannerCardHeader,
  ImpactContactBannerCardInformation,
  ImpactContactBannerCardSubText,
  ImpactContactBannerCardWrapper,
  ImpactContactBannerHeader,
  ImpactContactBannerImageContainer,
} from './ImpactV2UpdateContactBanner.style';

// import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type CardsType = {
  cardBgColor: string;
  cardHeaderText: string;
  cardSubText: ReactNode;
  buttons: ButtonType[];
  image: ReactNode;
  isReverse?: boolean;
};

export type ImpactV2UpdateContactBannerData = {
  bgColor: string;
  headerText: ReactNode;
  cards: CardsType[];
};

type Props = {
  data: ImpactV2UpdateContactBannerData;
};

const ImpactV2UpdateContactBanner = ({ data }: Props) => {
  return (
    <ImpactContactBanner $backgroundColor={data.bgColor}>
      <ImpactContactBannerHeader>{data.headerText}</ImpactContactBannerHeader>
      {data.cards.map((item) => (
        <ImpactContactBannerCardWrapper
          key={item.cardHeaderText}
          $backgroundColor={item.cardBgColor}
          $isReverse={item.isReverse}
        >
          <>
            <ImpactContactBannerCardInformation>
              <ImpactContactBannerCardHeader>
                {item.cardHeaderText}
              </ImpactContactBannerCardHeader>
              <ImpactContactBannerCardSubText>
                {item.cardSubText}
              </ImpactContactBannerCardSubText>
              {item.buttons && (
                <>
                  {item.buttons.map((btn: ButtonType) => (
                    <ImpactContactBannerCardButtons
                      key={btn.label}
                      // @ts-ignore
                      to={btn.url}
                    >
                      {btn.label}
                    </ImpactContactBannerCardButtons>
                  ))}
                </>
              )}
            </ImpactContactBannerCardInformation>
            <ImpactContactBannerImageContainer
              $isReverse={item.isReverse}
              data-testid="contact-banner-image"
            >
              {item.image}
            </ImpactContactBannerImageContainer>
          </>
        </ImpactContactBannerCardWrapper>
      ))}
    </ImpactContactBanner>
  );
};

export default ImpactV2UpdateContactBanner;
