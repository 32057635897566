import { css, styled } from 'styled-components';

import ChartIcon from '../../../static/svgIcons/Chart.svg';
import PlusIcon from '../../../static/svgIcons/home-emergency-plus.svg';
import SelectIcon from '../../../static/svgIcons/home-emergency-select.svg';
import Logo from '../../../static/svgIcons/PolicyExpert_SVG.svg';
import BronzeIcon from '../../../static/svgIcons/PolicyExpertBronze.svg';
import GoldIcon from '../../../static/svgIcons/PolicyExpertGold.svg';
import ProtectIcon from '../../../static/svgIcons/PolicyExpertProtect.svg';
import SilverIcon from '../../../static/svgIcons/PolicyExpertSilver.svg';
import { colors, fontFamilyCamptonSemiBold } from '../../styles/sharedStyled';

const iconSize = css`
  width: 76px;
  height: 60px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const ProductDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px;
  background-color: ${colors.white};
  border-radius: 20px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 60px;
    width: 754px;
    flex-flow: row wrap;
  }

  @media (min-width: 1024px) {
    padding: 60px;
    width: 840px;
  }

  & > div:not(:last-child) {
    border-bottom: solid 2px ${colors.gray3};

    @media (min-width: 768px) {
      border-bottom: none;
    }
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding-bottom: 30px;
  align-items: center;

  @media (min-width: 768px) {
    gap: 15px;
    padding-bottom: 0;
    width: 191px;
    align-items: normal;
  }

  @media (min-width: 1024px) {
    width: 220px;

    &:nth-child(4) {
      margin-top: 10px;
    }

    &:nth-child(5) {
      margin-top: 10px;
    }
  }
`;

export const DocumentLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DocumentIconWrapper = styled.div`
  @media (min-width: 768px) {
    margin-right: 12px;
  }
`;

export const DocumentIcon = styled(ChartIcon)`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 15px;
    height: 20px;
  }
`;

export const DocumentLink = styled.a<{ $hasMargin?: boolean }>`
  margin-top: ${({ $hasMargin }) => $hasMargin && '10px'};
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.pink2};
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
    margin-top: 0;
  }
`;

export const PolicyExpertBronze = styled(BronzeIcon)`
  ${iconSize}
`;

export const PolicyExpertSilver = styled(SilverIcon)`
  ${iconSize}
`;

export const PolicyExpertGold = styled(GoldIcon)`
  ${iconSize}
`;

export const PolicyExpertProtect = styled(ProtectIcon)`
  ${iconSize}
`;

export const PolicyExpertSelect = styled(SelectIcon)`
  ${iconSize}
`;

export const PolicyExpertPlus = styled(PlusIcon)`
  ${iconSize}
`;

export const PolicyExpertLogo = styled(Logo)`
  ${iconSize}
`;
