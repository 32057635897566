import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import AwardsGrid from '../../components/awardsGrid/AwardsGrid';
import HeaderBanner from '../../components/headerBanner/HeaderBanner';
import SectionHeadingBanner, {
  SectionHeadingBannerDataType,
} from '../../components/sectionHeadingBanner/SectionHeadingBanner';
import useDisclaimer from '../../hooks/useDisclaimer';
import acq2018 from '../../images/acq5_logo.png';
import bia2021 from '../../images/bia_personallinesinsurer.jpg';
import icaAward2022 from '../../images/ica_2022.png';
import ica_bronze2022 from '../../images/ica_bronze_2022.jpg';
import ica_silver2022 from '../../images/ica_silver_2022.jpg';
import ica2020 from '../../images/ICA-2020_Winner-Badge_Personal-Ins-Broker_low-res.png';
import ioty2021 from '../../images/innovatoroftheyear_2021.jpg';
import itta2021 from '../../images/insurancetimes21_techawards.jpg';
import itoty2021 from '../../images/insuretechoftheyear.jpg';
import itae2021 from '../../images/ita_excellenceintech.jpg';
import ita_ioty2021 from '../../images/ita_innovationoftheyear.jpg';
import lsa2022 from '../../images/lanarkshireawards_winner2022.png';
import cti2017 from '../../images/logo_1000_companies_to_inspire.png';
import acq2017 from '../../images/logo_acq_2017.png';
import acq2019 from '../../images/logo_acq_innovative_company_of_the_year_2019.png';
import amz2020 from '../../images/logo_amazon_scaleup_awards.jpg';
import bco2018 from '../../images/logo_best_companies.svg';
import bia2023 from '../../images/logo_bia_2023.png';
import bia2020 from '../../images/logo_bia_finalist_2020.png';
import cam2019 from '../../images/logo_city_am_2019.jpg';
import ctm2020 from '../../images/logo_ctm_simples_awards_service_winner.png';
import dis2019 from '../../images/logo_disruption50.png';
import eca2019 from '../../images/logo_ecommerce_awards_winner_2019.jpg';
import eba2019 from '../../images/logo_european_business_awards_winner_2019.png';
import gba2019 from '../../images/logo_growing_business_award_2019.jpg';
import ica2019_1 from '../../images/logo_ICA_2019_best_insurance_broker.png';
import ica2019_2 from '../../images/logo_ICA_2019_best_personal_insurance.png';
import ica2018 from '../../images/logo_insurance_award.png';
import ica2017 from '../../images/logo_insurance_awards_2017_winner.png';
import ica2019_3 from '../../images/logo_insurance_awards_2019_commended.png';
import itg2019 from '../../images/logo_insurance_awards_2019_winner.jpg';
import cio2019 from '../../images/logo_insurance_CIO_startups_award.jpg';
import ipost from '../../images/logo_insurance_post_2019.jpg';
import its2019 from '../../images/logo_insurance_times_tech_silver.jpg';
import mfc2020 from '../../images/logo_moneyfacts_2020.jpg';
import otw2019 from '../../images/logo_ones_to_watch_2019.jpg';
import qce2017 from '../../images/logo_quality_customer_experience.jpg';
import rc2020 from '../../images/logo_review_center.svg';
import itimes2019 from '../../images/logo_ta19_finalist.jpg';
import bta2019 from '../../images/logo_uk_business_tech_awards.jpg';
import cxa2019 from '../../images/logo_uk_customer_experience_awards.png';
import ukccf2019 from '../../images/logo_UKCCF_award.png';
import mfc2021 from '../../images/MFC-2021_Win-homeins2.jpg';
import mf2022 from '../../images/moneyfacts_2022.png';
import mf2023 from '../../images/moneyfacts_2023.png';
import HeaderImageBreakpoints from '../../types/breakpoints';

type AwardType = {
  img: string;
  title: string;
  subTitle: string;
};

export type AwardYearType = {
  year: number;
  awards: AwardType[];
};

export interface AwardsDataType {
  title: string;
  year: AwardYearType[];
}

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../images/about_us_awards_sm.png`}
        alt={`Awards we're proud of`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../images/about_us_awards_md.png`}
        alt={`Awards we're proud of`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../images/about_us_awards_lg.png`}
      alt={`Awards we're proud of`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const sectionHeadingBannerData: SectionHeadingBannerDataType = {
  headerText: 'Awards... thanks to you',
  subText:
    'Yes, there are actually awards for insurance companies. And yes, we’ve picked up quite a few. But that’s all down to the wonderful people that work with us. And the wonderful people like you who voted for us. So, thanks to the team and thanks to you!',
};

const awardsData: AwardsDataType = {
  title: 'Awards',
  year: [
    {
      year: 2023,
      awards: [
        {
          img: mf2023,
          title: 'Home Insurance Provider of the Year',
          subTitle: 'Moneyfacts Consumer Awards',
        },
        {
          img: bia2023,
          title: 'Customer Experience Winner',
          subTitle: 'British Insurance Awards',
        },
      ],
    },
    {
      year: 2022,
      awards: [
        {
          img: ica_silver2022,
          title: 'Insurer Innovation of the Year - Silver',
          subTitle: 'Insurance Times Awards 2022',
        },
        {
          img: ica_bronze2022,
          title: 'Marketing Campaign of the Year - Bronze',
          subTitle: 'Insurance Times Awards 2022',
        },
        {
          img: mf2022,
          title: 'Home Insurance Provider of the Year',
          subTitle: 'Moneyfacts Consumer Awards',
        },
        {
          img: lsa2022,
          title: 'Excellence in Customer Service',
          subTitle: 'Lanarkshire Business Excellence Awards',
        },
        {
          img: icaAward2022,
          title: 'Best Home Insurance Provider',
          subTitle: 'Insurance Choice Awards',
        },
      ],
    },
    {
      year: 2021,
      awards: [
        {
          img: mfc2021,
          title: 'Home Insurance Provider of the Year',
          subTitle: 'Moneyfacts Consumer Awards',
        },
        {
          img: ioty2021,
          title: 'Innovator of the Year - Christine Minetou',
          subTitle: 'UK FinTech Awards',
        },
        {
          img: itoty2021,
          title: 'InsurTech of the Year - Finalist',
          subTitle: 'UK FinTech Awards',
        },
        {
          img: bia2021,
          title: 'Personal Lines Insurer of the Year - Finalist',
          subTitle: 'British Insurance Awards',
        },
        {
          img: ita_ioty2021,
          title: 'Insurer Innovation of the Year - Bronze',
          subTitle: 'Insurance Times Awards',
        },
        {
          img: itae2021,
          title: 'Excellence in Technology - Finalist',
          subTitle: 'Insurance Times Awards',
        },
        {
          img: itta2021,
          title: 'Best Use of Technology for Customer Experience - Finalist',
          subTitle: 'Insurance Times Tech and Innovation Awards',
        },
        {
          img: itta2021,
          title: 'Data Analytics Excellence Awards - Finalist',
          subTitle: 'Insurance Times Tech and Innovation Awards',
        },
        {
          img: rc2020,
          title: 'Number 1 rated home insurer, 2013 - 2021',
          subTitle: 'Review Centre',
        },
      ],
    },
    {
      year: 2020,
      awards: [
        {
          img: ica2020,
          title: 'Best Personal Insurance Broker',
          subTitle: 'Insurance Choice Awards',
        },
        {
          img: bia2020,
          title: 'Personal Lines Insurer of the Year - Finalist',
          subTitle: 'British Insurance Awards',
        },
        {
          img: ctm2020,
          title: 'Winners in the Service category for Home Insurance',
          subTitle: 'Compare the Market Simples Awards',
        },
        {
          img: amz2020,
          title: 'Retail/e-commerce business award finalist',
          subTitle: 'Amazon ScaleUp Awards',
        },
        {
          img: ipost,
          title: 'Company of the Year & Insurance Brand of the Year',
          subTitle: 'Data Analytics Award, Digital Insurance',
        },
        {
          img: mfc2020,
          title:
            'Highly Commended in the Best Home Insurance Provider category',
          subTitle: 'Moneyfacts Consumer Awards',
        },
      ],
    },
    {
      year: 2019,
      awards: [
        {
          img: ica2019_1,
          title: 'Best Overall Insurance Broker for Car Insurance',
          subTitle: 'Insurance Choice Awards',
        },
        {
          img: ica2019_2,
          title: 'Best Personal Insurance Broker for Car Insurance',
          subTitle: 'Insurance Choice Awards',
        },
        {
          img: itg2019,
          title: 'Customer Champion of The Year – Insurer Winner',
          subTitle: 'Insurance Times Awards',
        },
        {
          img: its2019,
          title: 'Excellence in Technology Silver Award',
          subTitle: 'Insurance Times Awards',
        },
        {
          img: itimes2019,
          title:
            'Technology Innovation of the Year and Best Use of Technology for Customer Experience Finalist',
          subTitle: 'Insurance Times Tech and Innovation Awards',
        },
        {
          img: ica2019_3,
          title: 'Highly Commended in the Best Home Insurance category',
          subTitle: 'Insurance Choice Awards',
        },
        {
          img: ipost,
          title: 'Customer Experience Award',
          subTitle: 'Insurance Post Creative & Innovation Awards',
        },
        {
          img: ukccf2019,
          title:
            'Best Customer Experience Finalist, Best Medium-sized Contact Centre Finalist',
          subTitle: 'UK Contact Centre Forum Awards',
        },
        {
          img: eca2019,
          title: 'Best Financial Services eCommerce Winner',
          subTitle: 'Ecommerce Awards 2019',
        },
        {
          img: eba2019,
          title: 'Digital Technology Award National Winner',
          subTitle: 'European Business Awards',
        },
        {
          img: acq2019,
          title: 'UK Innovation Company of The Year',
          subTitle: 'ACQ5 Global Awards',
        },
        {
          img: bta2019,
          title: 'Best Use of Data Finalist',
          subTitle: 'UK Business Tech Awards',
        },
        {
          img: otw2019,
          title: "Ones To Watch - Named as 'Ones to Watch' in Europe",
          subTitle: 'European Business Awards',
        },
        {
          img: dis2019,
          title: 'Top 100 most disruptive companies',
          subTitle: 'Selected by D/SRUPTION50',
        },
        {
          img: cxa2019,
          title: 'Best Use of Insight and Feedback Finalist',
          subTitle: 'UK Customer Experience Awards 2019',
        },
        {
          img: gba2019,
          title:
            'Growing Business of the Year: Mid-sized Company (Turnover £25m-£50m) Finalist',
          subTitle: 'Growing Business Awards',
        },
        {
          img: cio2019,
          title: 'Top 10 Insurtech Startups in UK 2019',
          subTitle: '',
        },
        {
          img: cam2019,
          title: 'Insurance Company of the Year Finalist',
          subTitle: 'City AM Awards',
        },
      ],
    },
    {
      year: 2018,
      awards: [
        {
          img: ica2018,
          title: 'Best Overall Insurance Broker for Car Insurance',
          subTitle: 'Insurance Choice Awards 2018',
        },
        {
          img: ica2018,
          title: 'Best Home Insurance Provider',
          subTitle: 'Insurance Choice Awards 2018',
        },
        {
          img: bco2018,
          title: 'One star accreditation',
          subTitle: 'Best Companies to Work For 2018',
        },
        {
          img: acq2018,
          title: 'Innovative company of the year (insurance)',
          subTitle: 'ACQ5 Global Awards',
        },
        {
          img: acq2018,
          title: 'Growth company of the year (insurance)',
          subTitle: 'ACQ5 Global Awards',
        },
        {
          img: acq2018,
          title: 'Game-changer of the year (insurance)',
          subTitle: 'ACQ5 Global Awards',
        },
      ],
    },
    {
      year: 2017,
      awards: [
        {
          img: ica2017,
          title: 'One star accreditation',
          subTitle: 'Best Companies to Work For 2018',
        },
        {
          img: acq2017,
          title: 'Innovative company of the year (insurance)',
          subTitle: 'ACQ5 Global Awards',
        },
        {
          img: cti2017,
          title: 'Top 1000 companies to inspire Britain',
          subTitle: 'London Stock Exchange Group',
        },
        {
          img: qce2017,
          title: 'Rising Star',
          subTitle: 'National Quality & Customer Experience Awards 2017',
        },
      ],
    },
  ],
};

const AwardsPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxTan"
        headerImage={headerImage}
        shapePosition="bottom-left-header"
        styling="pt-30px pl-6 pr-7 md:pt-45px md:px-38px lg:pt-9 lg:px-6 max-w-1347"
      />
      <SectionHeadingBanner bgColor="pxWhite" data={sectionHeadingBannerData} />
      <AwardsGrid data={awardsData} />
    </>
  );
};

export default AwardsPage;

export const Head: HeadFC = () => (
  <>
    <title>Award winning Car and Home insurance | Policy Expert</title>
    <meta
      name="description"
      content="Policy Expert is a multi-award winning home and car insurance provider. Read more about our awards."
    />
    <html lang="en" />
  </>
);
