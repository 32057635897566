import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const TermsBlockContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  padding: 60px 0 95px;
  margin-bottom: ${({ id }) => id !== '' && '35px'};
  background-color: ${colors.gray3};

  @media (min-width: 768px) {
    padding: 40px 0 120px;
    margin-bottom: ${({ id }) => id !== '' && '80px'};
  }

  @media (min-width: 1024px) {
    padding: 130px 0 210px;
  }
`;

export const TermsBlockWrapper = styled.div`
  @media (min-width: 1024px) {
    width: 1060px;
    margin: 0 auto;
    justify-content: center;
  }
`;

export const HeaderLinksWrapper = styled.div`
  padding: 0 24px;

  @media (min-width: 768px) {
    padding: 0 40px;
  }

  @media (min-width: 1024px) {
    padding-left: 30px;
    padding-right: 0;
  }
`;

export const TermsLinkContainer = styled.div<{ $disclosurePage?: boolean }>`
  @media (min-width: 768px) {
    display: grid;
    grid-template-rows: ${({ $disclosurePage }) =>
      $disclosurePage
        ? 'repeat(5, minmax(0, 1fr))'
        : 'repeat(8, minmax(0, 1fr))'};
    grid-auto-flow: column;
    grid-column-gap: 20px;
  }
`;

export const TermsLinkHeader = styled.h4`
  color: ${colors.purple7};
  font-size: 18px;
  font-family: ${fontFamilyCamptonMedium};
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 30px;
  padding-top: 10px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const TermsBlockLinkTitle = styled.a`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 10px;
  gap: 10px;

  & > svg {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

export const TermsBlockLinks = styled.p`
  color: ${colors.black};
  font-size: 16px;
  font-family: ${fontFamilyCamptonBook};
  font-weight: 500;
  margin-bottom: 0;
  letter-spacing: 0;
  line-height: normal;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const TermsBlockSectionContainer = styled.div<{
  $disclosurePage?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: ${({ $disclosurePage }) =>
    $disclosurePage
      ? '60px 0; @media (min-width: 768px) {130px 0}'
      : '70px 0 0'};

  @media (min-width: 1024px) {
    padding-top: 100px;
  }
`;

export const TermsBlockSection = styled.div<{ $hasTable?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ $hasTable }) => ($hasTable ? '0' : '0 24px')};

  @media (min-width: 768px) {
    padding: ${({ $hasTable }) => ($hasTable ? '0' : '0 40px')};
  }

  @media (min-width: 1024px) {
    padding: ${({ $hasTable }) => ($hasTable ? '0' : '0 30px')};
  }
`;

export const TermsBlockSectionTitle = styled.h2<{
  $disclosurePage?: boolean;
  $hasTitlePadding?: boolean;
}>`
  font-size: 30px;
  font-family: ${fontFamilyCamptonMedium};
  font-weight: 500;
  margin-bottom: ${({ $disclosurePage }) => ($disclosurePage ? '0' : '30px')};
  letter-spacing: 0;
  line-height: normal;
  padding: ${({ $hasTitlePadding }) => $hasTitlePadding && '0 24px'};

  @media (min-width: 768px) {
    font-size: 35px;
    padding: ${({ $hasTitlePadding }) => $hasTitlePadding && '0 40px'};
  }

  @media (min-width: 1024px) {
    font-size: 50px;
    padding: ${({ $hasTitlePadding }) => $hasTitlePadding && '0 30px'};
  }
`;

export const TermsBlockSectionBody = styled.div<{ $disclosurePage?: boolean }>`
  margin: ${({ $disclosurePage }) =>
    $disclosurePage ? '40px 0 0 @media (min-width: 768px) {0}' : '0'};

  @media (min-width: 1280px) {
    padding-right: ${({ $disclosurePage }) => $disclosurePage && '24px'};
  }
`;

export const BackToTopButton = styled.button<{ $hasTable?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 2px solid ${colors.pink2};
  background-color: ${colors.white};
  color: ${colors.pink2};
  width: 131px;
  height: 45px;
  margin: ${({ $hasTable }) => ($hasTable ? '50px 24px 0' : '50px 0 0')};
  font-size: 18px;
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 768px) {
    margin: ${({ $hasTable }) => $hasTable && '50px 40px 0'};
  }

  @media (min-width: 1024px) {
    margin: ${({ $hasTable }) => $hasTable && '50px 30px 0'};
  }
`;
