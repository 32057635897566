import React from 'react';

import { graphql, HeadFC, Link, PageProps, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import CareersNavigation from '../../components/careersNavigation/CareersNavigation';
import HeaderBanner from '../../components/headerBanner/HeaderBanner';
import ImageWithText, {
  ImageWithTextDataType,
} from '../../components/imageWithText/ImageWithText';
import ImageWithTextCareers, {
  ImageWithTextCareersDataType,
} from '../../components/imageWithTextCareers/ImageWithTextCareers';
import ImageWithTitle, {
  ImageWithTitleData,
} from '../../components/imageWithTitle/ImageWithTitle';
import TwoColumnHeaderGrid, {
  TwoColumnHeaderGridType,
} from '../../components/twoColumnHeaderGrid/TwoColumnHeaderGrid';
import TwoColumnHTMLBanner from '../../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../images/careers_sm.png`}
        alt={`Together we're building the future of insurance`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../images/careers_md.png`}
        alt={`Together we're building the future of insurance`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../images/careers_lg.png`}
      alt={`Together we're building the future of insurance`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const ourJourneyData: ImageWithTitleData = {
  bgColor: 'bg-pxGrey',
  headerText: 'Our journey',
  imgName: '',
  imgNameMobile: '',
};

const videoData = {
  bgColor: 'bg-pxTan',
  heading: 'It’s a great time to join Policy Expert',
  subText: 'Check out our video showcasing the London office!',
  subText2: (
    <p className={'max-w-510'}>
      If you’re interested in London opportunities, click{' '}
      <Link to="/about-us/careers/london">this link</Link>.
    </p>
  ),
  subText3: (
    <p className={'max-w-510'}>
      If it’s Motherwell you’re after,{' '}
      <Link to="/about-us/careers/motherwell">it’s here</Link>.
    </p>
  ),
  subText4: (
    <p className={'max-w-510'}>
      For Milton Keynes, it’s{' '}
      <Link to="/about-us/careers/milton-keynes">this one</Link>.
    </p>
  ),
  ShapeX: false,
  body: (
    <div>
      <div className="relative h-0 pb-56.25">
        <iframe
          className="absolute top-0 left-0 w-full h-full border-0 border-none"
          src="https://player.vimeo.com/video/662624298?h=a25a129215"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Policy Expert - insurance for people like you - October 2021"
        />
      </div>
    </div>
  ),
};

const careersPageDisclaimer = (
  <>
    <br />
    *Policy Expert is rated the number 1 home insurance provider in the UK on
    independent review website – Reviewcentre.com. Based on 55,000 customer
    reviews and a 96.7% recommendation rating, Mar 2023.
    <br />
    **Based upon over 150 Glassdoor ratings as of January 2021
  </>
);

const CareersPage: React.FC<PageProps> = () => {
  useDisclaimer(careersPageDisclaimer);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const CareersImageQuery = useStaticQuery(graphql`
    query CareersImages {
      LondonBuilding: imageSharp(
        original: { src: { regex: "/london-building/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      OurCulture: imageSharp(original: { src: { regex: "/ourculture/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      CareersKidsout: imageSharp(
        original: { src: { regex: "/careers_kidsout/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Luke: imageSharp(original: { src: { regex: "/luke/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Vicky: imageSharp(original: { src: { regex: "/vicky/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Waqas: imageSharp(original: { src: { regex: "/waqas/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      LondonOfficeSmall: imageSharp(
        original: { src: { regex: "/london_office_small/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      MKOfficeSmall: imageSharp(
        original: { src: { regex: "/mk_office_small/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      MotherwellOfficeSmall: imageSharp(
        original: { src: { regex: "/motherwell_office_small/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const ourStoryData: ImageWithTextDataType = {
    bgColor: 'bg-pxGrey',
    imageLeft: false,
    blendsWithTop: false,
    copyBlock: [
      {
        headerText: 'Our story',
        subText: [
          'We launched Policy Expert in 2011 because we wanted to put people first. From the very beginning our philosophy has been simple – put people at the heart of everything we do and success will follow.',
          'Within two years we were rated the No. 1 home insurance provider* in the UK, an accolade we’re proud to say we’ve held ever since. We’ve also been awarded a range of customer and industry awards that we’re incredibly proud of.',
          'We’re currently recognised as one of the best employers in Milton Keynes, based on reviews from past and present team members on Glassdoor**.',
          'We know our success comes from our talented workforce and as a business we pride ourselves on going that extra mile for our people.',

          '<b class="font-bold">After all, together we’re building the future of insurance.</b>',
        ],
      },
    ],
    imgName: 'london-building.jpg',
    imageFile: CareersImageQuery.LondonBuilding.gatsbyImageData,
    imgMaxHeightMob: 'max-h-430',
    imgHeightMob: 'h-430',
    imgHeight: 'md:h-800',
  };

  const ourCultureData: ImageWithTextDataType = {
    bgColor: 'bg-white',
    imageLeft: true,
    blendsWithTop: true,
    copyBlock: [
      {
        headerText: 'Our culture',
        subText: [
          'We’ve worked hard to create an environment where everyone can do their best work and be their best selves.',
          'Working as a passionate, collaborative and hardworking team, our people are encouraged to be themselves and bring out the best in one another.',
          "We believe in being open and honest - everyone's voice is heard, in any team or role. We value what makes you unique and encourage you to pursue your ideas.",
          'We’ll help you to develop your strengths and talents within the company- whether that is developing a more specialised skillset or advancing to a management role.',
        ],
      },
    ],
    imgName: 'ourculture.jpg',
    imageFile: CareersImageQuery.OurCulture.gatsbyImageData,
    imgMaxHeightMob: 'max-h-380',
    imgHeightMob: 'h-380',
    imgHeight: 'md:h-860',
  };

  const ourCommunitiesData: ImageWithTextCareersDataType = {
    bgColor: 'bg-white',
    imageLeft: false,
    noPaddingTop: true,
    blendsWithTop: false,
    headerTextFirst: 'Diversity and inclusivity',
    subTextFirst: (
      <div>
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy max-w-400 mb-0">
          We’re committed to creating a culture of inclusivity where every
          employee feels welcome, and different perspectives are valued and
          encouraged.
          <br />
          <br />
          We respect and value people's differences. In fact, we welcome it, as
          this brings a wider range of ideas, opinions and experience.
        </p>
      </div>
    ),

    headerTextSecond: 'Our communities',
    subTextSecond: (
      <div>
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy max-w-400">
          We’re delighted to support KidsOut, a fantastic charity that provides
          disadvantaged children across the UK with exciting memories to
          cherish. Whether helping in simple ways or volunteering our time,
          skills and creative abilities, Policy Expert employees have made and
          continue to make a meaningful difference in the lives of disadvantaged
          children.
        </p>
      </div>
    ),
    imgName: 'careers_kidsout.jpg',
    imageFile: CareersImageQuery.CareersKidsout.gatsbyImageData,
    imgMaxHeightMob: 'max-h-380',
    imgHeightMob: 'h-380',
    imgHeight: 'md:h-1030',
    buttons: [],
  };

  const twoColumnHeaderGridTeam: TwoColumnHeaderGridType = {
    header: 'What’s it like to work here?',
    bgColor: 'pxMint',
    subText: 'Meet some of the team',
    items: [
      {
        header: 'Luke Pomeroy',
        imgName: 'luke.jpg',
        imageFile: CareersImageQuery.Luke.gatsbyImageData,
        subText: ['Reporting Analyst', 'London'],
        button: {
          label: 'Read more',
          url:
            '/about-us/careers/working-here/#selected-section?name=' +
            'LukePomeroy' +
            '',
          primary: false,
        },
      },
      {
        header: 'Vicky Imrie',
        imgName: 'vicky.jpg',
        imageFile: CareersImageQuery.Vicky.gatsbyImageData,
        subText: ['Sales & Service Manager', 'Motherwell'],
        button: {
          label: 'Read more',
          url:
            '/about-us/careers/working-here/#selected-section?name=' +
            'VickyImrie' +
            '',
          primary: false,
        },
      },
      {
        header: 'Waqas Javeed',
        imgName: 'waqas.jpg',
        imageFile: CareersImageQuery.Waqas.gatsbyImageData,
        subText: ['Team Manager', 'Milton Keynes'],
        button: {
          label: 'Read more',
          url:
            '/about-us/careers/working-here/#selected-section?name=' +
            'WaqasJaveed' +
            '',
          primary: false,
        },
      },
    ],
    link: {
      header: 'from employees across our other locations',
      linkText: 'Read more',
      link: '/about-us/careers/working-here',
    },
  };

  const twoColumnHeaderGridOffices: TwoColumnHeaderGridType = {
    header: 'Our offices',
    subText:
      'We are based across three different locations, each home to their own areas of expertise:',
    bgColor: 'pxTan',
    items: [
      {
        header: 'London',
        imgName: 'london_office_small.jpg',
        imageFile: CareersImageQuery.LondonOfficeSmall.gatsbyImageData,
        subText: ['Head office'],
        link: {
          header: 'See this',
          linkText: 'office',
          link: '/about-us/careers/london',
        },
        button: {
          label: 'View available jobs',
          url: '/about-us/careers/jobs?location=london',
          primary: true,
        },
      },
      {
        header: 'Milton Keynes',
        imgName: 'mk_office_small.jpg',
        imageFile: CareersImageQuery.MKOfficeSmall.gatsbyImageData,
        subText: ['Home insurance'],
        link: {
          header: 'See this',
          linkText: 'office',
          link: '/about-us/careers/milton-keynes',
        },
        button: {
          label: 'View available jobs',
          url: '/about-us/careers/jobs?location=milton_keynes',
          primary: true,
        },
      },
      {
        header: 'Motherwell',
        imgName: 'motherwell_office_small.jpg',
        imageFile: CareersImageQuery.MotherwellOfficeSmall.gatsbyImageData,
        subText: ['Car insurance'],
        link: {
          header: 'See this',
          linkText: 'office',
          link: '/about-us/careers/motherwell',
        },
        button: {
          label: 'View available jobs',
          url: '/about-us/careers/jobs?location=motherwell',
          primary: true,
        },
      },
    ],
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxPurple"
        headerImage={headerImage}
        shapePosition="bottom-left-header"
        maxHeight="max-h-680"
        styling="pt-17px pl-30px md:pt-10 md:pl-8 md:pr-38px lg:pt-62px lg:px-6 max-w-1148 relative z-1"
      />
      <CareersNavigation />
      <ImageWithText
        data={ourStoryData}
        topCoverColorMobile="pxGrey"
        topCoverColor="pxGrey"
        extraPaddingTop
        smallBodyText
        isCareers
        isCareersHP
      />
      <ImageWithTitle careers data={ourJourneyData} />
      <ImageWithText
        data={ourCultureData}
        topCoverColorMobile="pxGrey"
        topCoverColor="pxGrey"
        smallBodyText
        extraPaddingTop={false}
        isCareers
        isCareersHP
        imgPosition="bottom"
      />
      <TwoColumnHTMLBanner data={videoData} />
      <ImageWithTextCareers data={ourCommunitiesData} isCareers />
      <TwoColumnHeaderGrid shape="left-right" data={twoColumnHeaderGridTeam} />
      <TwoColumnHeaderGrid
        shape="bottom-left-cards"
        data={twoColumnHeaderGridOffices}
      />
    </>
  );
};

export default CareersPage;

export const Head: HeadFC = () => (
  <>
    <title>Careers with Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
