import { styled } from 'styled-components';

import DocDownload from '../../../static/svgIcons/DocDownload.svg';
import {
  colors,
  fontFamilyCamptonSemiBold,
  ImpactSecondaryButton,
  PrimaryButton,
  SecondaryButton,
} from '../../styles/sharedStyled';

export const GridHeaderContainer = styled.div<{ $attached?: boolean }>`
  position: relative;
  background-color: ${colors.purple7};
  padding-top: 60px;

  @media (min-width: 768px) {
    padding: 60px 0 130px;
    margin-top: ${({ $attached }) => ($attached ? '-130px' : '0')};
  }

  @media (min-width: 1024px) {
    padding: 130px 0 210px;
  }
`;

export const GridHeaderWrapper = styled.div<{ $attached?: boolean }>`
  padding: 0 24px;
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin-top: ${({ $attached }) => ($attached ? '-130px' : '0')};
  }
`;

export const GridSectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export const GridSectionTitle = styled.h2`
  color: ${colors.white};
  font-weight: 400;
  font-size: 35px;
  padding-bottom: 30px;

  @media (min-width: 1024px) {
    font-size: 80px;
    padding-bottom: 50px;
  }
`;

export const GridContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding-bottom: 70px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 90px;
  }
`;

export const GridSectionText = styled.p`
  color: ${colors.white};
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 0;
    margin-right: 15px;
  }
`;

export const GridSectionButton = styled(SecondaryButton)`
  width: 180px;

  @media (min-width: 768px) {
    width: 140px;
  }
`;

export const GridSectionContainer = styled.div<{ $isHome?: boolean }>`
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 60px;
    align-items: ${({ $isHome }) => $isHome && 'center'};
    justify-content: ${({ $isHome }) => $isHome && 'center'};
  }
`;

export const GridCardContentContainer = styled.div`
  position: relative;
  padding: 65px 25px 0;

  @media (min-width: 768px) {
    padding: 70px 40px 0;
  }
`;

export const GridCardHeader = styled.h3<{ $isPet?: boolean }>`
  font-family: ${fontFamilyCamptonSemiBold};
  padding-bottom: 0;
  line-height: normal;
  font-size: 20px;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    font-size: 25px;
    margin-bottom: ${({ $isPet }) => $isPet && '30px'};
  }

  @media (min-width: 1024px) {
    font-size: 30px;
  }
`;

export const GridCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GridCardText = styled.p`
  font-size: 16px;
  margin-bottom: 25px;
  line-height: normal;

  @media (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const GridButtonContainer = styled.div<{ $isPet?: boolean }>`
  display: flex;
  flex-direction: column;
  position: ${({ $isPet }) => $isPet && 'relative'};
  margin-bottom: 25px;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    flex: 1 1 auto;
    gap: 30px;
  }
`;

export const DocDownloadSvg = styled(DocDownload)`
  position: absolute;
  top: 12px;
  left: 35px;
  width: 20px;
  height: 25px;
  fill: ${colors.white};

  @media (min-width: 768px) {
    top: 18px;
    width: 25px;
    height: 30px;
  }
`;

export const GridClaimsButton = styled(PrimaryButton)`
  width: 100%;
  height: 50px;
  font-size: 16px;
  padding: 15px 0;
  border-radius: 15px;

  &:hover > ${DocDownloadSvg} {
    fill: ${colors.pink2};
  }

  @media (min-width: 768px) {
    width: 320px;
    height: 68px;
    font-size: 20px;
    padding: 25px 35px;
  }
`;

export const GridButtonLabel = styled.p`
  font-family: ${fontFamilyCamptonSemiBold};
  position: absolute;
  margin-bottom: 0;
  top: 14px;
  left: 65px;
  font-size: 16px;

  @media (min-width: 768px) {
    top: 23px;
    left: 77px;
    font-size: 20px;
  }

  @media (min-width: 1024px) {
    top: 20px;
  }
`;

export const LargeTelephoneLink = styled.a`
  font-size: 20px;
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 768px) {
    font-size: 25px;
  }

  @media (min-width: 1024px) {
    font-size: 30px;
  }
`;

export const SmallContactContent = styled.p<{
  $hasPadding?: boolean;
  $isKeyCover?: boolean;
}>`
  font-size: 15px;
  margin-bottom: 0;
  line-height: normal;
  color: ${({ $isKeyCover }) => $isKeyCover && colors.purple7};
  padding-top: ${({ $hasPadding }) => $hasPadding && '5px'};
`;

export const HomeContactContent = styled.p`
  font-size: 16px;
  margin-bottom: 0;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const HomeContactNumberLink = styled.a`
  font-size: 16px;
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

export const ClaimsPrimaryButton = styled(PrimaryButton)<{
  $isFallback?: boolean;
}>`
  width: 100%;
  height: 50px;
  font-size: 16px;
  padding: ${({ $isFallback }) => ($isFallback ? '0 15px' : '25px 32px')};
  border-radius: 15px;
  align-items: center;
  font-family: ${fontFamilyCamptonSemiBold};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    height: 68px;
    margin: 0 30px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: 1024px) {
    margin-right: 0;
    width: ${({ $isFallback }) => ($isFallback ? '100%' : '50%')};
  }
`;

export const ClaimsSecondaryButton = styled(ImpactSecondaryButton)`
  font-family: ${fontFamilyCamptonSemiBold};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  text-align: center;
  justify-content: center;
  padding: 25px 32px;
  font-size: 16px;
  width: 100%;
  height: 50px;
  border-radius: 15px;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    height: 68px;
    margin-bottom: 0;
  }

  @media (min-width: 1024px) {
    margin-right: 0;
    width: 50%;
  }
`;

export const FallBackButtonContainer = styled.div`
  @media (min-width: 768px) {
    width: 50%;
    padding-right: 15px;
  }
`;

export const CarOptionalsContainer = styled.div`
  text-align: center;
  padding: 40px 0;

  @media (min-width: 1024px) {
    padding: 60px 0;
    text-align: left;
  }
`;

export const CarOptionalsHeader = styled.h2`
  color: ${colors.white};
  font-size: 30px;

  @media (min-width: 1024px) {
    font-size: 50px;
    padding-top: 15px;
  }
`;

export const CarOptionalsText = styled.p`
  color: ${colors.white};
  font-size: 15px;
  margin-bottom: 0;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;

export const OptionalsCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  padding-top: 40px;
  gap: 32px;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    padding-top: 60px;
  }
`;

export const OptionalsCardWrapper = styled.div<{ $isNotDesktop?: boolean }>`
  position: relative;
  padding: 70px 25px 0;

  @media (min-width: 768px) {
    padding: ${({ $isNotDesktop }) => $isNotDesktop && '70px 40px 0'};
  }
`;

export const OptionalsCardTextWrapper = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom-width: 1px;

  &:last-child {
    border-width: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 40px;
    padding-bottom: 0;
  }
`;

export const OptionalsCardText = styled.p<{ $isDesktop?: boolean }>`
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: ${({ $isDesktop }) => ($isDesktop ? '25px' : '20px')};
  margin-bottom: ${({ $isDesktop }) => ($isDesktop ? '20px' : '10px')};

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  @media (min-width: 1024px) {
    font-size: 25px;
    margin-bottom: 30px;
    max-width: ${({ $isDesktop }) => $isDesktop && '66.6%'};
  }
`;

export const OptionalsCardPhonelink = styled.a<{ $isDesktop?: boolean }>`
  font-size: ${({ $isDesktop }) => ($isDesktop ? '25px' : '20px')};
  font-family: ${fontFamilyCamptonSemiBold};
`;
