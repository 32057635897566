import React, { useState } from 'react';

import PlusLogo from '../../../static/svgIcons/home-emergency-plus.svg';
import SelectLogo from '../../../static/svgIcons/home-emergency-select.svg';
import {
  MinusSvg,
  PlusSvg,
  TickCleanSvg,
} from './HomeEmergencyAccordion.style';

type ItemType = {
  title: string;
  includedIn: string[];
  whatsCovered: any;
  whatsNotCovered: any;
};

export type HomeEmergencyAccordionDataType = {
  items: ItemType[];
  selectTitle: string;
  selectImage: string;
  selectImageAlt: string;
  plusTitle: string;
  plusImage: string;
  plusImageAlt: string;
};

export type Props = {
  data: any;
};

const HomeEmergencyAccordion = ({ data }: Props) => {
  const [activePlan, setActivePlan] = useState<string>('');
  const [activeItem, setActiveItem] = useState<boolean | number>(false);

  const toggle = (index: number) => {
    if (activeItem === index) {
      setActiveItem(false);
    } else {
      setActiveItem(index);
    }
  };

  return (
    <div className="bg-pxGrey py-60px md:py-100px lg:pt-130px lg:pb-127px">
      <div className="padding-x flex flex-col items-center">
        <h3 className="font-campton font-medium text-center text-pxBlack text-sectionAccordionHeadingMobile md:text-sectionAccordionHeadingTablet lg:text-sectionAccordionHeading p-0">
          Select your Home Emergency cover
        </h3>
        <div className="flex flex-row justify-center w-full mt-35px md:mt-10 lg:mt-60px">
          <label
            htmlFor="select"
            className={`w-full max-w-250 lg:max-w-300 rounded-radius-25 flex justify-center items-center cursor-pointer ${
              activePlan === 'select'
                ? 'bg-pxPurple bg-opacity-10 border-2 border-pxPurple'
                : 'bg-pxWhite'
            }`}
          >
            <SelectLogo
              title={data.selectImageAlt}
              alt={data.selectImageAlt}
              className="cursor-pointer block py-10 px-8 w-auto h-auto max-w-full"
            />
          </label>
          <input
            type="radio"
            name="plan"
            value="select"
            id="select"
            onChange={() => setActivePlan('select')}
            className="absolute h-0 w-0 opacity-0"
          />

          <label
            htmlFor="plus"
            className={`w-full max-w-250 lg:max-w-300 ml-5 md:ml-30px flex justify-center items-center rounded-radius-25 cursor-pointer ${
              activePlan === 'plus'
                ? 'bg-pxPurple bg-opacity-10 border-2 border-pxPurple'
                : 'bg-pxWhite'
            }`}
          >
            <PlusLogo
              title={data.plusImageAlt}
              alt={data.plusImageAlt}
              className="cursor-pointer py-10 px-8 w-auto h-auto max-w-full"
            />
          </label>
          <input
            type="radio"
            name="plan"
            value="select"
            id="plus"
            onChange={() => setActivePlan('plus')}
            className="absolute h-0 w-0 opacity-0"
          />
        </div>

        {data && activePlan && (
          <>
            <p className="text-accordionSubHeadingMobile lg:text-accordionSubHeading font-campton font-medium mt-10 md:mt-60px lg:mt-80px mb-0">
              What's covered?
            </p>
            <div className="flex flex-col w-full xl:w-1200 bg-pxWhite rounded-radius-10 md:rounded-radius-20 mt-5 lg:mt-30px overflow-hidden">
              <p className=" text-pxPurple font-campton font-medium text-accordionTitleMobile md:text-accordionTitle text-center my-30px mx-25px md:my-10 md:mx-50px lg:my-9">
                {data[activePlan + 'Title']}
              </p>
              {data.items.map(
                (item: any, i: number) =>
                  item.includedIn.includes(activePlan) && (
                    <div
                      key={i}
                      className="flex flex-row items-center w-full border-t border-pxGrey"
                    >
                      <div className="p-5 self-start">
                        <TickCleanSvg />
                      </div>

                      <div
                        className={`w-full flex flex-col border-l border-pxGrey ${
                          activeItem === i && 'bg-pxPurple bg-opacity-5'
                        }`}
                      >
                        <div
                          onClick={() => toggle(i)}
                          className={`cursor-pointer flex flex-row justify-between items-center mx-5 py-5
                    ${
                      activeItem === i &&
                      'border-b-2 border-pxWhite border-opacity-70'
                    }`}
                        >
                          <p
                            className={`font-camptonSemiBold text-accordionBody m-0 ${
                              activeItem === i
                                ? 'text-pxPurple font-medium'
                                : 'font-normal'
                            }`}
                          >
                            {item.title}
                          </p>

                          <div>
                            {activeItem === i ? <MinusSvg /> : <PlusSvg />}
                          </div>
                        </div>

                        <div
                          className={`cursor-pointer py-5 px-5 home-emergency-accordion-content ${
                            activeItem === i ? 'flex flex-col' : 'hidden'
                          }`}
                        >
                          <p className="text-accordionBody font-camptonSemiBold font-medium text-pxBlack mb-10px">
                            What's covered?
                          </p>
                          {item.whatsCovered}
                          <p className="text-accordionBody font-camptonSemiBold font-medium text-pxBlack mb-10px mt-20px">
                            What isn't covered?
                          </p>
                          {item.whatsNotCovered}
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HomeEmergencyAccordion;
