import React from 'react';

import { HeadFC, Link, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../../components/headerBanner/HeaderBanner';
import TwoColumnHTMLBanner from '../../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../types/breakpoints';
import TermsCopyType from '../../types/terms';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../images/terms_and_conditions_sm.png`}
        alt={`Terms and conditions`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../images/terms_and_conditions_md.png`}
        alt={`Terms and conditions`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../images/terms_and_conditions_lg.png`}
      alt={`Terms and conditions`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const termsCopy: TermsCopyType = {
  heading:
    '12 months of free home emergency cover along with free legal assistance for the lifetime of your policy.',
  body: (
    <div>
      <ul className="text-small">
        <li className="text-small">
          Only customers purchasing or renewing a Silver Home Insurance, Gold
          Home Insurance, Policy Expert Home Essentials, Policy Expert Home
          Premier, Policy Expert Home Select and Policy Expert Home Plus policy
          from us will receive Home Legal Select and Home Emergency Select cover
          for free.
        </li>
        <li>
          Home Legal Select and Home Emergency Select cover is provided to you
          free of charge if you purchase a new home insurance policy.
        </li>
        <li>
          Home Legal Select and Home Emergency Select cover is provided to you
          free of charge if you are renewing a home insurance policy and you
          have been invited to renew from 1 January 2022.
        </li>
        <li>
          If your qualifying home insurance policy is cancelled for any reason,
          your free Home Legal Select and free Home Emergency Select cover will
          also be cancelled.
        </li>
        <li>
          If your home free Home Legal Select and/or your free Home Emergency
          Select cover is cancelled for any reason, you will not receive any
          premium refund and the premium for your home insurance policy will not
          decrease.
        </li>
        <li>
          This offer cannot be used in conjunction with any other offer or
          promotion.
        </li>
        <li>
          We may withdraw this offer at any time, but any policies in effect
          will continue to run until their expiration date.
        </li>
        <li>
          Policy wording and summaries can be found on the 
          <Link to="/home-insurance/policy-documents/">
            Policy Document page
          </Link>
          . Please ensure you read these documents to understand the
          restrictions and conditions of cover.
        </li>
      </ul>
    </div>
  ),
};

const notesCopy: TermsCopyType = {
  bgColor: 'bg-pxMint',
  heading: 'Please note the following restrictions on your free cover:',
  body: (
    <div>
      <h3 className="text-pxPurple">Legal assistance</h3>
      <ul className="text-small">
        <li>
          Cover is for policy holders; the husband, wife or partner of the
          policy holder (who shares financial responsibility); and the children
          &amp; parents of the policy holder who normally reside at the risk
          address.
        </li>
      </ul>
      <h3 className="text-pxPurple">Home emergency</h3>
      <ul className="text-small">
        <li>
          If your boiler is more than 15-years old, the Primary Heating System
          (section 2) detailed in your policy wording is excluded.
        </li>
        <li>
          Claims within the first 7 days of cover will be declined unless you
          have continuous Home emergency cover from another provider that will
          be cancelled when our policy starts.
        </li>
        <li>
          If you are purchasing a ‘contents only’ policy you must have a
          buildings insurance policy with another provider. Proof may be
          required in the event of a claim.
        </li>
        <li>
          Cover only applies to main residence and not second homes or rented
          properties and properties that are occupied.
        </li>
        <li>
          Legal assistance and Home emergency cover is provided to you free of
          charge if you purchase a Home Insurance policy directly through
          Moneysupermarket.com.
        </li>
        <li>
          We may withdraw this offer at any time, but any policies in effect
          will continue to run until their expiration date.
        </li>
        <li className="mb-0">
          Please ensure you read your policy documentation to understand the
          restrictions and conditions of cover.
        </li>
      </ul>
    </div>
  ),
};

const moneySupermarketCs: TermsCopyType = {
  bgColor: 'bg-pxTan',
  heading: 'Money supermarket conditions',
  body: (
    <div>
      <p className="text-small">
        Moneysupermarket Limited does not accept any responsibility or liability
        under or in connection with this offer (including without limitation as
        a result of technical fault or computer or electronic malfunction (or
        incomplete, illegible, lost or fraudulent entries).
      </p>
      <ul className="text-small">
        <li>
          You acknowledge that Moneysupermarket Limited is not a party to any
          contract(s) in relation to the provision of Home Insurance entered
          into between you and Policy Expert. Moneysupermarket Limited will not
          be responsible for any damages, claims or losses arising out of, or in
          connection with any such contract(s) (including any services provided
          under any such contract(s)).
        </li>
        <li>
          If any paragraph in these terms and conditions is found by any court
          or administrative body of competent jurisdiction to be invalid or
          unenforceable, such invalidity or unenforceability shall not affect
          the other paragraphs of these terms and conditions, which shall remain
          in full force and effect.
        </li>
        <li className="mb-0">
          These terms and conditions shall be governed by and construed in
          accordance with the laws of England and Wales. The Courts of England
          and Wales shall have exclusive jurisdiction in connection with these
          terms and conditions.
        </li>
      </ul>
    </div>
  ),
};

const MoneySupermarketTCSHeclecPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxTan"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        styling="pt-20px pl-3px md:pt-30px md:px-54px lg:pt-9 lg:px-6 max-w-1243 relative z-1"
      />
      <TwoColumnHTMLBanner data={termsCopy} />
      <TwoColumnHTMLBanner data={notesCopy} />
      <TwoColumnHTMLBanner data={moneySupermarketCs} />
    </>
  );
};

export default MoneySupermarketTCSHeclecPage;

export const Head: HeadFC = () => (
  <>
    <title>Offer terms and conditions | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
