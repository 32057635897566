import React from 'react';

import Shape from '../Shape';
import ShapeX from '../ShapeX';

const TwoColumnHTMLBanner = ({ data }: any) => (
  <div
    className={`${data.bgColor} py-82px md:py-130px bg-left-bottom bg-no-repeat bg-80 md:bg-50 relative overflow-hidden`}
  >
    <div
      className={`${data.textColor} padding-x justify-between flex flex-col md:flex-row relative z-40 `}
    >
      <div className="w-full z-20 md:w-5/12">
        <div>
          {data.heading && (
            <h2 className=" mb-32px break-words	">{data.heading} </h2>
          )}
          {data.subText && <p className={'max-w-510'}>{data.subText}</p>}
          {data.subText2 && data.subText2 ? <div>{data.subText2}</div> : null}
          {data.subText3 && data.subText3 ? <div>{data.subText3}</div> : null}
          {data.subText4 && data.subText4 ? <div>{data.subText4}</div> : null}
        </div>
        <div className="hidden lg:block">
          {data.titleImage && <Shape position="left-near-title" />}
        </div>
      </div>
      <div className="w-full z-20 md:w-5/12">{data.body}</div>
    </div>
    {data.ShapeX && <ShapeX position={data.ShapeXPosition} />}
  </div>
);

export default TwoColumnHTMLBanner;
