import React from 'react';

import { Link } from 'gatsby';

import { ButtonType } from '../../types/button';

export type ContactBannerDataType = {
  headerText: string;
  subText: string;
  buttons: ButtonType[];
};

type Props = {
  data: ContactBannerDataType[];
  bgColor?: string;
  paddingTop?: string;
};

const ContactBanner = ({ data, bgColor, paddingTop }: Props) => {
  return (
    <div
      className={`bg-${bgColor ? bgColor : 'pxTan'} overflow-hidden ${
        paddingTop ? paddingTop : ''
      } w-full`}
    >
      <div className="max-w-1390 padding-x md:px-40px lg:px-24px pt-170px pb-95px md:pt-130px flex flex-col md:flex-row justify-end relative">
        {data.map((card: ContactBannerDataType) => (
          <div
            className={`flex w-full flex-col md:last:mt-0 pb-50px last:pb-0 last:pt-50px md:pb-0 md:last:pt-0 border-b border-pxCharcoalTransparent50 last:border-0 md:border-r md:border-b-0 md:pr-50px md:last:pl-50px md:last:pr-0 lg:pr-80px lg:last:pl-80px`}
            key={card.headerText}
          >
            <h2 className="mb-20px md:mb-30px lg:mb-50px text-fontSize-35 md:text-fontSize-50 lg:text-fontSize-80">
              {card.headerText}
            </h2>
            <p className="mb-40px md:mb-50px lg:mb-80px py-auto lg:py-0 text-fontSize-20 lg:text-fontSize-25">
              {card.subText}
            </p>
            {card.buttons && (
              <div className="flex flex-col items-center md:items-start">
                {card.buttons.map((btn: ButtonType) => (
                  <Link
                    key={btn.label}
                    // @ts-ignore
                    to={btn.url}
                    className={`btn-md lg:btn-lg md:mr-32px w-60 mb-15px last:mb-0 md:last:mr-0 w-full md:max-w-300 ${
                      btn.primary ? 'btn-primary' : 'btn-secondary'
                    }`}
                  >
                    {btn.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactBanner;
