import React, { FC } from 'react';
import { useWizard } from 'react-use-wizard';

import { pushHecTriageEventToDataLayer } from '../../services/windowService';
import {
  CoverButtonsContainer,
  NotSureButton,
  PlusButton,
  PlusImage,
  SelectButton,
  SelectImage,
} from './CoverSelector.style';

export type CoverType = 'Select' | 'Plus' | null;

type CoverSelectorProps = {
  setCoverType: (coverType: CoverType) => void;
};

const CoverSelector: FC<CoverSelectorProps> = ({ setCoverType }) => {
  const { goToStep } = useWizard();

  return (
    <>
      <CoverButtonsContainer>
        <SelectButton
          data-testid="Select-button"
          aria-label="Policy Expert Select"
          onClick={() => {
            pushHecTriageEventToDataLayer('click_hec_select');
            setCoverType('Select');
            goToStep(2);
          }}
        >
          <SelectImage />
        </SelectButton>
        <PlusButton
          data-testid="Plus-button"
          aria-label="Policy Expert Plus"
          onClick={() => {
            pushHecTriageEventToDataLayer('click_hec_plus');
            setCoverType('Plus');
            goToStep(2);
          }}
        >
          <PlusImage />
        </PlusButton>
      </CoverButtonsContainer>
      <NotSureButton
        data-testid="not-sure-button"
        onClick={() => {
          pushHecTriageEventToDataLayer('click_not_sure');
          goToStep(1);
        }}
      >
        Not sure which cover you have?
      </NotSureButton>
    </>
  );
};

export default CoverSelector;
