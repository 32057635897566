import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const DeclineReason = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  margin-top: -5px;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 25px;
  }
`;

export const MayHaveCoverContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  padding: 20px;
  margin-top: 5px;
`;

export const MayHaveCoverText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  margin-bottom: 0;
`;

export const MayHaveCoverLink = styled.a`
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  text-decoration: none;
  color: ${colors.pink2};
`;
