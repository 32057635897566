import React from 'react';

import { Link } from 'gatsby';

import { ButtonType } from '../../types/button';
import { IconTextType } from '../../types/icon';
import LinkType from '../../types/link';
import Icon from '../icons/Icon';
import ShapeX from '../ShapeX';

export type TwoColumnIconBannerDataType = {
  bgColor: string;
  headerText: string;
  headerSubtext?: string;
  icons: IconTextType[];
  link?: LinkType;
  buttons?: ButtonType[];
};

type Props = {
  data: TwoColumnIconBannerDataType;
  shape?: boolean;
};

const TwoColumnIconBanner = ({ data, shape }: Props) => (
  <div
    className={`${data.bgColor} pt-82px pb-32px md:pb-82px md:pt-130px relative overflow-hidden `}
  >
    <div className="padding-x grid md:grid-cols-2 relative z-20 md:grid-gap-82px">
      <div className="flex flex-col w-full">
        <h2 className="lg:pr-32px">{data.headerText} </h2>

        <p className="my-32px pr-82px lg:pr-32px">{data.headerSubtext} </p>
      </div>

      <div className="flex flex-col w-full ">
        {data.icons.map((item) => (
          <>
            <div
              className="flex flex-row justify-between"
              key={item.text?.toString()}
            >
              <div className="md:w-1/6">
                <Icon type="primary" size={2}>
                  {item.icon}
                </Icon>
              </div>

              <p className="mb-32px w-5/6 pt-2">{item.text}</p>
            </div>
          </>
        ))}

        {data.link ? (
          <p className="text-small ml-14 md:ml-28">
            <Link to="./" className="text-pxPink font-bold">
              Find out more
            </Link>
          </p>
        ) : null}
        {data.buttons &&
          data.buttons.map((btn: any) => {
            return (
              <a
                key={btn.label}
                href={btn.url}
                className={`btn-${
                  btn.smallestSize || 'md'
                } lg:btn-lg lg:mr-32px mb-12px md:mb-82px mt-32px
              btn-${btn.primary ? 'primary' : 'secondary'}`}
                target={btn.target ? btn.target : '_self'}
              >
                {btn.label}
              </a>
            );
          })}
      </div>
    </div>

    {shape ? <ShapeX position="bottom-left" /> : null}
  </div>
);

export default TwoColumnIconBanner;
