import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
} from '../../styles/sharedStyled';

export const PetBlueCrossBannerBackgroundContainer = styled.div`
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
  padding: 60px 0 95px;
  margin-bottom: -35px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 100px 0 180px;
    margin-bottom: -80px;
  }

  @media (min-width: 1024px) {
    padding: 130px 0 210px;
  }
`;

export const PetBlueCrossBannerContainer = styled.div`
  padding: 0 24px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;

  @media (min-width: 768px) {
    padding: 0 40px;
  }

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

export const PetBlueCrossBannerTitle = styled.h2`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 35px;
  margin-bottom: 50px;
  line-height: normal;

  @media (min-width: 768px) {
    font-size: 40px;
    margin-bottom: 70px;
  }

  @media (min-width: 1024px) {
    font-size: 65px;
    margin-bottom: 90px;
  }
`;

export const PetBlueCrossContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
  }

  @media (min-width: 1024px) {
    gap: 50px;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const PetBlueCrossContentTitle = styled.h3`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 25px;
  color: ${colors.purple7};
  line-height: normal;
  margin-bottom: 30px;
  text-align: center;
  padding: 0;

  @media (min-width: 768px) {
    text-align: left;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 35px;
    padding-right: 100px;
  }
`;

export const PetBlueCrossContentText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 18px;
  margin-bottom: 0;

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ImageContainer = styled.div`
  height: 266px;
  width: 312px;
  flex-shrink: 0;

  @media (min-width: 768px) {
    height: 255px;
    width: 300px;
  }

  @media (min-width: 1024px) {
    height: 425px;
    width: 500px;
  }
`;
