import React, { Fragment, useContext } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import { ReviewsContext } from '../../context/ReviewsProvider';
import Carousel from '../carousel/ImpactCarousel';
import ShapeX from '../ShapeX';
import {
  CarouselContainer,
  DesktopReviewsContainer,
  ImageContainer,
  PetReviewBannerBackgroundContainer,
  PetReviewBannerDesktopContainer,
  PetReviewBannerTitle,
  TrustpilotContainer,
  TrustpilotIcon,
  TrustpilotText,
} from './PetReviewBanner.style';
import { getOpacityOffset } from './ReviewSection';

export type PetReviewBannerProps = {
  data?: {
    headerText: string;
  };
  cards: JSX.Element[];
};

const PetReviewBanner = ({ cards, data }: PetReviewBannerProps) => {
  const { isMobile, isTablet } = useBreakpoint();
  const opacityOffset = getOpacityOffset(isMobile, isTablet);

  const trustpilotReviews = useContext(ReviewsContext);

  const reviewBannerImage = (
    <ImageContainer>
      <StaticImage
        src="../../images/pet_review.png"
        alt="People love what we do..."
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    </ImageContainer>
  );

  return (
    <PetReviewBannerBackgroundContainer>
      <PetReviewBannerDesktopContainer>
        <PetReviewBannerTitle>{data?.headerText}</PetReviewBannerTitle>
        <CarouselContainer data-testid="reviews-carousel">
          <Carousel
            pagination={{ arrows: false, color: 'pxWhite' }}
            cards={cards}
            title=""
            paddingTop={false}
            opacityOffset={opacityOffset}
            slidesOffsetAfter={isMobile ? 0 : 40}
            slidesOffsetBefore={isMobile ? 0 : 40}
            petMargins
          />
        </CarouselContainer>
        <DesktopReviewsContainer data-testid="reviews-desktop">
          {cards.map((card: JSX.Element) => (
            <Fragment key={card.key}>{card}</Fragment>
          ))}
        </DesktopReviewsContainer>

        <TrustpilotContainer data-testid="trustpilot-component">
          {trustpilotReviews.score && trustpilotReviews.numberOfReviews ? (
            <>
              <TrustpilotIcon />
              <TrustpilotText>
                {`${
                  trustpilotReviews.score.trustScore
                }/5 from over ${trustpilotReviews.numberOfReviews.total.toLocaleString()} reviews. 96.7% of users recommend us.`}
              </TrustpilotText>
            </>
          ) : null}
        </TrustpilotContainer>
      </PetReviewBannerDesktopContainer>
      <ShapeX position={'bottom-right-pet-reviews'} />
      {reviewBannerImage}
    </PetReviewBannerBackgroundContainer>
  );
};

export default PetReviewBanner;
