import React, { ReactNode } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import useEnableImpactV2Update from '../../hooks/useEnableImpactV2Update';
import { IconTextType } from '../../types/icon';
import Icon from '../icons/Icon';
import ShapeX from '../ShapeX';
import {
  IconBannerBackgroundContainer,
  IconBannerGrid,
  IconBannerHeader,
  IconBannerInformationContainer,
  IconBannerInformationWrapper,
  IconBannerTextContainer,
} from './IconsBanner.style';

export type IconsBannerData = {
  bgColor: string;
  headerText: ReactNode;
  icons: IconTextType[];
};

type Props = {
  data: IconsBannerData;
  shape?: boolean;
  hideShapeDesktop?: boolean;
  isHomepage?: boolean;
};

const IconsBanner = ({ data, shape, hideShapeDesktop, isHomepage }: Props) => {
  const { isMobile, isTablet, isTabletLarge, isDesktop } = useBreakpoint();

  const v2Refresh = useEnableImpactV2Update();

  const hideShapeOnDesktop =
    (isDesktop || (isTabletLarge && !isMobile)) && hideShapeDesktop;
  const hideShape = (shape && isTablet && !isMobile) || hideShapeOnDesktop;

  const v2RefreshHideShape =
    shape && v2Refresh ? isTabletLarge || isTablet || isMobile : '';

  return (
    <IconBannerBackgroundContainer
      $backgroundColor={data.bgColor}
      $isHomepage={isHomepage}
      $v2Refresh={v2Refresh}
    >
      <IconBannerInformationWrapper>
        <IconBannerHeader $v2Refresh={v2Refresh} $isHomepage={isHomepage}>
          {data.headerText}
        </IconBannerHeader>
        <IconBannerGrid $v2Refresh={v2Refresh} $isHomepage={isHomepage}>
          {data.icons.map((item) => (
            <IconBannerInformationContainer
              key={item.text?.toString()}
              data-testid="icon-banner"
              $v2Refresh={v2Refresh}
              $isHomepage={isHomepage}
            >
              <Icon>{item.icon}</Icon>
              <IconBannerTextContainer
                $v2Refresh={v2Refresh}
                $isHomepage={isHomepage}
              >
                {item.text}
              </IconBannerTextContainer>
            </IconBannerInformationContainer>
          ))}
        </IconBannerGrid>
      </IconBannerInformationWrapper>
      {isDesktop && v2Refresh && isHomepage ? (
        <ShapeX position={'bottom-right-icon-banner'} />
      ) : v2RefreshHideShape && isHomepage ? (
        ''
      ) : (
        !hideShape && <ShapeX position={'bottom-icon-banner'} />
      )}

      {<></> ? '' : <></> ? '' : ''}
    </IconBannerBackgroundContainer>
  );
};

export default IconsBanner;
