import Modal from 'react-overlays/Modal';

import { styled } from 'styled-components';

import { colors } from '../../styles/sharedStyled';

export const ModalBackdrop = styled.div`
  background-color: ${colors.gray14};
  position: fixed;
  z-index: 2040;
  inset: 0;
`;

export const ModalComponent = styled(Modal)`
  background-color: transparent;
  position: fixed;
  z-index: 2040;
  height: 100%;
  width: 100%;
  top: 0;
  border: 0;
  right: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 0;
  overflow-y: scroll;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TriageModalWrapper = styled.div`
  padding: 25px 25px 0;

  @media (min-width: 768px) {
    padding: 10px 0 0;
    max-height: 570px;
    height: 100%;
  }
`;

export const TriageModalContainer = styled.div`
  background-color: ${colors.gray3};
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 600px;
  }
`;
