import React, { useEffect, useState } from 'react';

import { Link } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { ButtonType } from '../../types/button';
import DropShapeCard from '../dropShapeCard/DropShapeCard';
import ImageComponent from '../imageComponent/ImageComponent';
import Shape, { ShapeProps } from '../Shape';

export type ImageWithTextRevealType = {
  headerText: string;
  subText?: any;
  imgName: string;
  imageFile: IGatsbyImageData;
  imageLeft?: boolean;
  bgColor?: string;
  btn?: ButtonType;
  body?: any;
};

type Props = {
  data: ImageWithTextRevealType;
  shape?: boolean;
  shapePosition?: ShapeProps['position'];
  noParagraphs?: boolean;
  hideShowMoreWithImgBottomOnMobile?: boolean;
};

const ImageWithTextReveal = ({
  data,
  shape,
  shapePosition,
  noParagraphs,
  hideShowMoreWithImgBottomOnMobile,
}: Props) => {
  const [showMore, setShowMore] = useState(false);
  const [rowsToShow, setRowsToShow] = useState(4);

  useEffect(() => {
    if (data.subText && data.subText.length <= 4) {
      setRowsToShow(2);
    }
  }, []);

  const handleShowMore = () => {
    if (data.subText && data.subText.length > 4) {
      showMore ? setRowsToShow(4) : setRowsToShow(data.subText.length);
    } else {
      showMore ? setRowsToShow(2) : setRowsToShow(data.subText.length);
    }

    setShowMore(!showMore);
  };

  return (
    <div
      className={`${data.bgColor} py-32px relative overflow-x-hidden
                ${
                  hideShowMoreWithImgBottomOnMobile
                    ? 'md:pb-60px md:pt-130px'
                    : 'md:py-130px'
                }
      `}
    >
      <div
        className={`padding-x flex justify-between
        ${
          hideShowMoreWithImgBottomOnMobile ? 'flex-col-reverse' : 'flex-col'
        }   
        md:${data.imageLeft ? 'flex-row' : 'flex-row-reverse'} 
        
       
          relative`}
      >
        <div
          className={`
          lg:${data.imageLeft ? 'pr-82px' : 'pl-82px'} 
          ${hideShowMoreWithImgBottomOnMobile ? '' : 'max-h-743 lg:h-743'}
           md:w-1/2 relative z-10`}
        >
          <DropShapeCard className={`h-full max-h-380 lg:max-h-743`} fullWidth>
            <ImageComponent
              imageFile={data.imageFile}
              className="w-full h-full"
              alt={data.imgName}
            />
          </DropShapeCard>
        </div>

        <div
          className={`flex flex-col w-full md:w-5/12 md:pt-0 relative z-10 
                      ${
                        hideShowMoreWithImgBottomOnMobile
                          ? 'pt-60px pb-60px md:pb-0'
                          : 'pt-82px'
                      }
        `}
        >
          <h2
            className={`mb-32px ${
              hideShowMoreWithImgBottomOnMobile
                ? 'md:mb-32px lg:mb-82px'
                : 'md:mb-82px'
            }`}
          >
            {data.headerText}{' '}
          </h2>
          <div className={` overflow-y-hidden`}>
            {data.body && data.body}
            {hideShowMoreWithImgBottomOnMobile
              ? null
              : data.subText &&
                data.subText.slice(0, rowsToShow).map((item: string) => (
                  <p
                    key={item}
                    className={noParagraphs && noParagraphs ? 'mb-0' : 'mb-4'}
                  >
                    {item}
                  </p>
                ))}
          </div>
          {hideShowMoreWithImgBottomOnMobile ? null : (
            <p
              onClick={() => handleShowMore()}
              className="text-bodyCopy text-pxPink font-semibold cursor-pointer"
            >
              {showMore ? 'Show less' : 'Show more'}
            </p>
          )}

          {data.btn && data.btn.url ? (
            <Link
              to={data.btn.url}
              className={`btn-${
                data.btn.primary ? 'primary' : 'secondary'
              } btn-md md:btn-lg`}
            >
              {data.btn.label}
            </Link>
          ) : null}
        </div>
      </div>
      {shape && shapePosition ? <Shape position={shapePosition} /> : null}
    </div>
  );
};

export default ImageWithTextReveal;
