import React from 'react';

import Tick from '../../../static/svgIcons/Tick.svg';
import ShapeX from '../ShapeX';

type OrderedListType = {
  title: string;
  innerList: string[];
};

export type TwoColumnListDblDataType = {
  header: string;
  content: string[];
  bgColor: string;
  list: OrderedListType[];
};

type Props = {
  data: TwoColumnListDblDataType;
  shape?: boolean;
};

export default function TwoColumnListDbl({ data, shape }: Props) {
  return (
    <div
      className={`${data.bgColor} pt-82px pb-32px md:pb-82px md:pt-130px relative overflow-hidden `}
      id="interview-tips"
    >
      <div className="padding-x grid md:grid-cols-2 relative z-20 md:gap-x-10">
        <div className="flex flex-col max-w-510">
          <h2 className="1 pb-32px text-pxCharcoal">{data.header} </h2>

          {data.content.map((item) => (
            <p key={item}>{item}</p>
          ))}
        </div>

        <ol className="flex flex-col w-full max-w-510 ol-list-item list-decimal">
          {data.list.map((item) => (
            <>
              <li
                className="count-item font-semibold text-pxCharcoal"
                key={item.title}
              >
                {item.title}
              </li>
              <ul className="icon-list">
                {item.innerList.map((innerItem) => (
                  <li className="flex" key={innerItem}>
                    <div className="icons-primary1 z-50 inline-block relative">
                      <Tick
                        className="inline-block relative"
                        stroke="#6214bE"
                        fill="#6214bE"
                      />
                    </div>
                    <p className="text-base md:text-xl ml-3 flex-1 mb-0 md:mb-4 text-pxCharcoal">
                      {innerItem}
                    </p>
                  </li>
                ))}
              </ul>
            </>
          ))}
        </ol>
      </div>
      {shape ? <ShapeX position="bottom-left" /> : null}
    </div>
  );
}
