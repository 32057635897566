import React from 'react';

import { Link } from 'gatsby';

export default function OtherJobs() {
  return (
    <div className="bg-pxMint">
      <div className="padding-x py-32px flex flex-col md:flex-row items-center justify-center">
        <p className="mb-0">See what jobs are available</p>

        <Link
          className="btn-secondary text-finerDetailsBodyCopy md:text-bodyCopy font-bold btn-sm md:btn-lg mt-4 md:mt-0 md:ml-32px cursor-pointer px-1 md:max-w-full "
          style={{ width: 304 }}
          to="/about-us/careers/jobs"
        >
          {' '}
          View all jobs
        </Link>
      </div>
    </div>
  );
}
