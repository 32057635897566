import { Link } from 'gatsby';
import { styled } from 'styled-components';

import ComingSoon from '../../../static/svgIcons/ComingSoonMobile.svg';
import { colors, fontFamilyCamptonMedium } from '../../styles/sharedStyled';

export const MobileInsuranceCardButton = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  background-color: ${colors.white};
  color: ${colors.black};
  border-radius: 16px;
  padding-left: 25px;
  font-size: 16px;
  line-height: 18.62px;

  @media (min-width: 1024px) {
    flex-direction: column;
  }
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 371px) and (max-width: 767px) {
    width: 230px;
    margin: auto;
  }
`;

export const InsuranceCardHeader = styled.h3`
  font-size: 16px;
  line-height: normal;
  font-family: ${fontFamilyCamptonMedium};
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;
  padding: 0 0 0 20px;
  letter-spacing: 0;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.pink2};
  padding: 10px;
  height: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const ComingSoonSvg = styled(ComingSoon)`
  position: absolute;
  right: 36px;
`;
