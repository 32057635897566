import { styled } from 'styled-components';

import ArrowRight from '../../../static/svgIcons/ArrowRight.svg';
import { colors, fontFamilyCamptonBook } from '../../styles/sharedStyled';

export const ListOfPerils = styled.ul``;

export const Row = styled.li<{
  $notFirstPeril: boolean;
  $notLastPeril: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  border-style: solid;
  border-color: ${colors.gray7};
  margin-bottom: 0;
  cursor: pointer;
  padding-top: ${({ $notFirstPeril }) => $notFirstPeril && '10px'};
  padding-bottom: ${({ $notLastPeril }) => $notLastPeril && '10px'};
  border-bottom-width: ${({ $notLastPeril }) => $notLastPeril && '1px'};
`;

export const Peril = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  margin-bottom: 0;
`;

export const Arrow = styled(ArrowRight)`
  fill: ${colors.pink2};
`;
