import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import PolicyBooks, {
  PolicyDocumentsData,
} from '../../components/policyBooks/PolicyBooks';
import config from '../../config';
import usePetChanges from '../../hooks/usePetChanges';
import NotFoundPage from '../404';

const documentsData: PolicyDocumentsData = {
  productName: 'Pet',
  productTypeNames: ['Default'],
  productTypeData: {
    Default: [
      {
        policyType: 'bronze',
        policyWordingLink: '',
        ipidLink: `${config.DOCUMENTS_URL}/pet/qgl101/pet_pebronzepet_ipid_0324.pdf`,
      },
      {
        policyType: 'silver',
        policyWordingLink: '',
        ipidLink: `${config.DOCUMENTS_URL}/pet/qgl102/pet_pesilverpet_ipid_0324.pdf`,
      },
      {
        policyType: 'gold',
        policyWordingLink: '',
        ipidLink: `${config.DOCUMENTS_URL}/pet/qgl103/pet_pegoldpet_ipid_0324.pdf`,
      },
    ],
  },
};

const PetPolicyDocumentsPage: React.FC<PageProps> = () => {
  const isPetChangesEnabled = usePetChanges();

  return isPetChangesEnabled ? (
    <PolicyBooks documentsData={documentsData} />
  ) : (
    //@ts-ignore
    <NotFoundPage />
  );
};

export default PetPolicyDocumentsPage;

export const Head: HeadFC = () => (
  <>
    <title>Pet insurance policy documents | Policy Expert</title>
    <meta
      name="description"
      content="Pet insurance policy documents. Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
