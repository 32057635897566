import React from 'react';

import Shape from '../Shape';

export type CookiesBannerProps = {
  data: {
    bgColor: string;
    heading: string;
    titleImage?: boolean;
    body: React.JSX.Element;
  };
};

const CookiesBanner = ({ data }: CookiesBannerProps) => (
  <div
    className={`${data.bgColor} py-60px px-24px md:py-100px md:px-30px lg:py-130px bg-left-bottom bg-no-repeat bg-80 md:bg-50 relative overflow-hidden`}
  >
    <div className="justify-between flex flex-col md:flex-row relative z-40 lg:max-w-1450 m-auto">
      <div className="w-full z-20 md:w-5/12">
        <div>
          <h2 className="mb-25px text-fontSize-40 md:text-fontSize-45 lg:text-fontSize-80">
            {data.heading}
          </h2>
        </div>
        <div className="hidden lg:block">
          {data.titleImage && <Shape position="left-cookies-redesign" />}
        </div>
      </div>
      <div className="w-full z-20 md:w-5/12">{data.body}</div>
    </div>
  </div>
);

export default CookiesBanner;
