import { styled } from 'styled-components';

import ArrowRight from '../../../static/svgIcons/ArrowRight.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const IpidContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 840px;
  }
`;

export const SectionTitle = styled.h2`
  font-family: ${fontFamilyCamptonMedium};
  line-height: normal;
  font-size: 25px;
  margin-bottom: 30px;
  color: ${colors.purple7};
  letter-spacing: 0;

  @media (min-width: 1024px) {
    font-size: 35px;
  }
`;

export const LinkContainer = styled.div<{ $hasMargin: boolean }>`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.gray15};

  &:last-child {
    margin-bottom: ${({ $hasMargin }) => ($hasMargin ? '20px' : '0')};
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LinkHeading = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  line-height: normal;
  margin-bottom: 12px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const Link = styled.a<{ $hasMargin: boolean }>`
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 16px;
  color: ${colors.pink2};
  text-decoration: none;
  line-height: normal;
  display: block;

  &:first-child {
    margin-bottom: ${({ $hasMargin }) => ($hasMargin ? '10px' : '0')};
  }
`;

export const DocumentsContainer = styled.div`
  @media (min-width: 768px) {
    text-align: right;
  }
`;

export const ShowHideButton = styled.div`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 16px;
  line-height: normal;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.gray15};
  display: flex;
  justify-content: space-between;
`;

export const Arrow = styled(ArrowRight)<{ $isExpanded: boolean }>`
  fill: ${colors.pink2};
  width: 11px;
  height: 20px;
  margin-right: 4px;
  transform: ${({ $isExpanded }) =>
    $isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'};
`;
