import useHypertune from './useHypertune';
import useQueryParams from './useQueryParams';

const useUpdatedKeyCareDocuments = () => {
  const { enableUpdatedKeyCareDocuments } = useQueryParams();

  const rootNode = useHypertune();

  if (enableUpdatedKeyCareDocuments === 'false') {
    return false;
  }

  return (
    rootNode.enableUpdatedKeyCareDocuments().get(false) ||
    enableUpdatedKeyCareDocuments === 'true'
  );
};

export default useUpdatedKeyCareDocuments;
