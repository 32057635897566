import { Link } from 'gatsby';
import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonRegular,
} from '../../styles/sharedStyled';

export const MultiTabTableMobileContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ExtraInformationContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 30px;
  background-color: ${colors.white};
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const ExtraInformation = styled.div`
  width: 100%;
  padding: 25px;
  border-radius: 20px;
  background-color: ${colors.purple14};
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;

  & > span {
    color: ${colors.purple7};
    font-family: ${fontFamilyCamptonRegular};
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
  }
`;

export const TableTabContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

export const TableTabInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
`;

export const IconContainer = styled.div`
  margin: 32px;
`;

export const TableTabInformationText = styled.p`
  text-align: center;
  font-size: 15px;
  line-height: normal;
`;

export const HecLinkContainer = styled.div`
  margin: 0 28px;
  text-align: center;
  padding-top: 20px;
`;

export const HecLink = styled(Link)`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 15px;
`;
