import React, { ReactNode, useContext } from 'react';

import { HeadFC, Link, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import Address from '../../static/svgIcons/Address.svg';
import Email from '../../static/svgIcons/Email.svg';
import PhoneClean from '../../static/svgIcons/PhoneClean.svg';
import ComplaintsDetailsBanner from '../components/complaintsDetailsBanner/ComplaintsDetailsBanner';
import PetComplaintsDetailsBanner from '../components/complaintsDetailsBanner/PetComplaintsDetailsBanner';
import ComplaintsTable, {
  ComplaintsTableDataType,
} from '../components/complaintsTable/ComplaintsTable';
import HeaderBanner from '../components/headerBanner/HeaderBanner';
import ImageWithHTML from '../components/imageWithHTML/ImageWithHTML';
import TwoColumnTextBanner from '../components/twoColumnTextBanner/TwoColumnTextBanner';
import { PhoneNumbersContext } from '../context/PhoneNumbersProvider';
import formatPhoneNumber from '../helpers/formatPhoneNumber';
import useDisclaimer from '../hooks/useDisclaimer';
import usePetChanges from '../hooks/usePetChanges';
import { colors } from '../styles/sharedStyled';
import HeaderImageBreakpoints from '../types/breakpoints';
import {
  ComplaintsContactUsContainer,
  ComplaintsContactUsList,
  ComplaintsContactUsText,
  ComplaintsExternalLink,
  ComplaintsTelephoneLink,
  ComplaintsText,
} from './how-to-complain.style';

type CardCopyType = {
  icon: ReactNode;
  text: any;
};

type ComplainCardType = {
  header: string;
  cardCopy: CardCopyType[];
};

type PetComplainCardType = {
  header: string;
  body?: React.JSX.Element;
};

export type ComplainDetailsDataType = {
  bgColor: string;
  sectionHeader: string;
  cards: ComplainCardType[];
};

export type PetComplainDetailsDataType = {
  bgColor: string;
  sectionHeader: string;
  cards: PetComplainCardType[];
};

type textListType = {
  text: string;
};

export type ComplaintInstructionsType = {
  bgColor: string;
  borderTop: boolean;
  sectionHeader: string;
  textList: textListType[];
  learnMore: any;
  image: string;
};

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../images/how_to_complain_sm.png`}
        alt={`How to complain`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../images/how_to_complain_md.png`}
        alt={`How to complain`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../images/how_to_complain_lg.png`}
      alt={`How to complain`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const twoColumnTextBannerData = {
  bgColor: 'bg-pxTan',
  textColor: 'text-pxBlack',
  headerText: 'Only the highest of standards will do',
  subText: [
    'Policy Expert is committed to delivering the highest standard of service to our customers. Our dealings with you should always be clear and easy to understand. However, we know that sometimes things go wrong, so we want to know if our service does not meet your expectations, and we’ll do our best to put it right.',
    'We’re so focused on making our service better that we treat any expression of dissatisfaction as a complaint, and will provide a response from our Complaints Department.',
  ],
};

const complaintInstructions = {
  bgColor: 'bg-pxGrey',
  borderTop: true,
  sectionHeader:
    'We’ll need a few things from you to look into your complaint:',
  textList: [
    {
      text: 'Your Contact details, including full name, address, postcode, phone number and email address (if you have one);',
    },
    {
      text: 'Your contact preference – please let us know how you want us to contact you regarding your complaint;',
    },
    {
      text: 'Your Policy Expert account number (and type of policy). This will help us find your records;',
    },
    {
      text: 'Details of what went wrong, in as much detail as you can. If you know the dates of your interactions with us, or key events which are relevant to your complaint, please include these in your complaint.',
    },
  ],
  learnMore: (
    <p className="text-bodyCopy">
      For more details on our complaints procedure, please{' '}
      <Link to="/complaints-procedure" className="text-pxPink font-semibold">
        click here
      </Link>
      .
    </p>
  ),
  image: '',
};

const productTableData: ComplaintsTableDataType = {
  headerText: 'Complaints data publication',
  rightPanelInfo: [
    {
      infoTitle: '',
      info: 'The table below shows how we’re handling customer complaints:',
    },
    { infoTitle: 'Firm Name:', info: 'Policy Expert' },
    { infoTitle: 'Group: ', info: 'QMetric Group Limited' },
    { infoTitle: 'Other Firms included in this report: ', info: 'N/A' },
    {
      infoTitle: 'Period covered in this report:',
      info: '1st October 2023 – 31st March 2024',
    },
  ],
  columns: 8,
  tableTitles: [
    {
      tableTitle: 'Products/ service grouping',
    },
    {
      tableTitle: 'Intermediation (within the reporting period)',
    },
    {
      tableTitle: 'Number of complaints opened',
    },
    {
      tableTitle: 'Number of complaints closed',
    },
    {
      tableTitle: 'Percentage closed within 3 days',
    },
    {
      tableTitle: 'Percentage closed after 3 days but within 8 weeks',
    },
    {
      tableTitle: 'Percentage upheld',
    },
    {
      tableTitle: 'Main cause of complaints opened',
    },
  ],
  productRows: [
    {
      label: 'Banking and credit cards',
      included: ['-', '-', '-', '-', '-', '-', '-'],
    },
    {
      label: 'Home finance',
      included: ['-', '-', '-', '-', '-', '-', '-'],
    },
    {
      label: 'Insurance and pure protection',
      included: [
        '1.44 per 1000 policies sold',
        '3280',
        '3141',
        '18%',
        '77%',
        '31%',
        'Category 2: General Admin & Customer Service',
      ],
    },
    {
      label: 'Decumulation and pensions',
      included: ['-', '-', '-', '-', '-', '-', '-'],
    },
    {
      label: 'Investments',
      included: ['-', '-', '-', '-', '-', '-', '-'],
    },
    {
      label: 'Credit related',
      included: [
        '0.0013',
        '3',
        '5',
        '20%',
        '80%',
        '60%',
        'Category 1: Terms & disputed sums & charges',
      ],
    },
  ],
};

const HowToComplainPage: React.FC<PageProps> = () => {
  useDisclaimer(null);
  const phoneNumbers = useContext(PhoneNumbersContext);

  const isPetChangesEnabled = usePetChanges();

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const complaintDetailsData: ComplainDetailsDataType = {
    bgColor: 'bg-pxGrey',
    sectionHeader: 'How to make a complaint',
    cards: [
      {
        header: 'Home insurance',
        cardCopy: [
          {
            icon: <Email stroke="#6214bE" fill="#6214bE" />,
            text: (
              <a
                className="mb-32px w-5/6 md:text-bodyCopy text-pxPink font-semibold"
                href="mailto: home-complaints@policyexpert.co.uk"
              >
                home-complaints@policyexpert.co.uk
              </a>
            ),
          },
          {
            icon: <PhoneClean stroke="#6214bE" fill="#6214bE" />,
            text: (
              <a
                className="mb-32px w-5/6 md:text-bodyCopy text-black"
                href={`tel:${formatPhoneNumber(phoneNumbers.home?.mainMenu)}`}
              >
                {phoneNumbers.home?.mainMenu}
              </a>
            ),
          },
          {
            icon: <Address stroke="#6214bE" fill="#6214bE" />,
            text: (
              <p className="w-5/6 md:text-bodyCopy pb-32px">
                Customer Relations
                <br />
                Policy Expert Home
                <br />
                1st Floor
                <br />
                Exchange House
                <br />
                450 Midsummer Boulevard
                <br />
                Milton Keynes
                <br />
                MK9 2EA
              </p>
            ),
          },
        ],
      },
      {
        header: 'Car insurance',
        cardCopy: [
          {
            icon: <Email stroke="#6214bE" fill="#6214bE" />,
            text: (
              <a
                className="mb-32px w-5/6 md:text-bodyCopy text-pxPink font-semibold"
                href="mailto:motor-complaints@policyexpert.co.uk"
              >
                motor-complaints@policyexpert.co.uk
              </a>
            ),
          },
          {
            icon: <PhoneClean stroke="#6214bE" fill="#6214bE" />,
            text: (
              <a
                className="mb-32px w-5/6 md:text-bodyCopy text-black"
                href={`tel:${formatPhoneNumber(phoneNumbers.motor?.mainMenu)}`}
              >
                {phoneNumbers.motor?.mainMenu}
              </a>
            ),
          },
          {
            icon: <Address stroke="#6214bE" fill="#6214bE" />,
            text: (
              <p className=" w-5/6 md:text-bodyCopy md:mb-0">
                Customer Relations
                <br />
                Policy Expert Motor <br />2 Parklands Avenue
                <br />
                Maxim 3<br />
                Eurocentral
                <br />
                ML1 4WQ
              </p>
            ),
          },
        ],
      },
    ],
  };

  const petComplaintDetailsData: PetComplainDetailsDataType = {
    bgColor: `${colors.gray3}`,
    sectionHeader: 'How to make a complaint',
    cards: [
      {
        header: 'Home insurance',
        body: (
          <ComplaintsContactUsContainer>
            <ComplaintsContactUsList>
              <ComplaintsContactUsText>Email:</ComplaintsContactUsText>
              <ComplaintsExternalLink
                href={'mailto:home-complaints@policyexpert.co.uk'}
              >
                home-complaints@policyexpert.co.uk
              </ComplaintsExternalLink>
            </ComplaintsContactUsList>
            <ComplaintsContactUsList>
              <ComplaintsContactUsText>Tel:</ComplaintsContactUsText>
              <ComplaintsTelephoneLink
                href={`tel:${formatPhoneNumber(phoneNumbers.home?.mainMenu)}`}
              >
                {`${phoneNumbers.home?.mainMenu}`}
              </ComplaintsTelephoneLink>
            </ComplaintsContactUsList>
            <ComplaintsContactUsList>
              <ComplaintsContactUsText>Write:</ComplaintsContactUsText>
              <ComplaintsText>
                Customer Relations,
                <br />
                Policy Expert Home,
                <br />
                1st Floor,
                <br />
                Exchange House,
                <br />
                450 Midsummer Boulevard,
                <br />
                Milton Keynes,
                <br />
                MK9 2EA
              </ComplaintsText>
            </ComplaintsContactUsList>
          </ComplaintsContactUsContainer>
        ),
      },

      {
        header: 'Car insurance',
        body: (
          <ComplaintsContactUsContainer>
            <ComplaintsContactUsList>
              <ComplaintsContactUsText>Email:</ComplaintsContactUsText>
              <ComplaintsExternalLink
                href={'mailto:motor-complaints@policyexpert.co.uk'}
              >
                motor-complaints@policyexpert.co.uk
              </ComplaintsExternalLink>
            </ComplaintsContactUsList>
            <ComplaintsContactUsList>
              <ComplaintsContactUsText>Tel:</ComplaintsContactUsText>
              <ComplaintsTelephoneLink
                href={`tel:${formatPhoneNumber(phoneNumbers.motor?.mainMenu)}`}
              >
                {`${phoneNumbers.motor?.mainMenu}`}
              </ComplaintsTelephoneLink>
            </ComplaintsContactUsList>
            <ComplaintsContactUsList>
              <ComplaintsContactUsText>Write:</ComplaintsContactUsText>
              <ComplaintsText>
                Policy Expert Motor,
                <br />
                2 Parklands Avenue,
                <br />
                Maxim 3,
                <br />
                Eurocentral,
                <br />
                Motherwell,
                <br />
                ML1 4WQ
              </ComplaintsText>
            </ComplaintsContactUsList>
          </ComplaintsContactUsContainer>
        ),
      },
      {
        header: 'Pet insurance',
        body: (
          <ComplaintsContactUsContainer $isPetInsurance>
            <ComplaintsContactUsList>
              <ComplaintsContactUsText>Email:</ComplaintsContactUsText>
              <ComplaintsExternalLink
                href={'mailto:pet-complaints@policyexpert.co.uk'}
              >
                pet-complaints@policyexpert.co.uk
              </ComplaintsExternalLink>
            </ComplaintsContactUsList>
            <ComplaintsContactUsList>
              <ComplaintsContactUsText>Tel:</ComplaintsContactUsText>
              <ComplaintsTelephoneLink
                href={`tel:${formatPhoneNumber(phoneNumbers.pet?.mainMenu)}`}
              >
                {`${phoneNumbers.pet?.mainMenu}`}
              </ComplaintsTelephoneLink>
            </ComplaintsContactUsList>
            <ComplaintsContactUsList>
              <ComplaintsContactUsText>Write:</ComplaintsContactUsText>
              <ComplaintsText>
                Customer Relations,
                <br />
                Policy Expert Home,
                <br />
                1st Floor,
                <br />
                Exchange House,
                <br />
                450 Midsummer Boulevard,
                <br />
                Milton Keynes,
                <br />
                MK9 2EA
              </ComplaintsText>
            </ComplaintsContactUsList>
          </ComplaintsContactUsContainer>
        ),
      },
    ],
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxCharcoal"
        headerImage={headerImage}
        styling="pt-30px pl-6 pr-21px md:px-100px lg:pt-66px lg:px-6 lg:max-w-1034"
      />
      <TwoColumnTextBanner data={twoColumnTextBannerData} />
      {isPetChangesEnabled ? (
        <PetComplaintsDetailsBanner data={petComplaintDetailsData} />
      ) : (
        <ComplaintsDetailsBanner data={complaintDetailsData} />
      )}
      <ImageWithHTML data={complaintInstructions} />
      <ComplaintsTable data={productTableData} multiTables={false} />
    </>
  );
};

export default HowToComplainPage;

export const Head: HeadFC = () => (
  <>
    <title>How to complain | Policy Expert</title>
    <meta name="description" content="How to complain with Policy Expert." />
    <html lang="en" />
  </>
);
