import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import './carousel.css';

import React, { ReactNode, useState } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// install Swiper modules
SwiperCore.use([Autoplay]);

type CarouselType = {
  title?: string;
  cards: any;
  slidesPerView?: number;
  slidePadding?: boolean;
  pagination?: {
    color: string;
    arrows: boolean;
  };
  pag?: boolean;
  offsetfirstslide?: boolean;
  opacityOffset?: number;
  autoPlay?: boolean;
  paddingTop?: boolean;
  type?: 'home' | 'car';
};

const Carousel = ({
  title,
  cards,
  slidesPerView,
  pagination,
  pag = true,
  opacityOffset = 3,
  autoPlay,
  paddingTop = true,
  type,
}: CarouselType) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isMobile } = useBreakpoint();

  const paginationStats = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class=" ' + className + '"></span>';
    },
  };

  const handleChangeSlide = (event: any) => {
    setCurrentSlide(event.realIndex);
  };

  return (
    <div
      className={`w-full relative z-50 overflow-visible swiper-container max-w-screen-2xl mx-auto ${
        type === 'car'
          ? 'ml-7-minus md:ml-21-minus'
          : type === 'home'
          ? 'ml-5-minus md:ml-10-minus'
          : ''
      }`}
    >
      {title && <h4 className="text-forestgreen px-4">{title}</h4>}

      <Swiper
        wrapperClass={`w-screen-xl ${
          paddingTop
            ? 'pt-32px'
            : type === 'car'
            ? 'pt-0 md:justify-center'
            : ''
        } flex flex-row relative bg-blue swiper-wrapper`}
        navigation={pagination?.arrows ? pagination.arrows : false}
        autoplay={autoPlay ? { delay: 2000 } : false}
        spaceBetween={10}
        uniqueNavElements
        breakpoints={{
          768: { spaceBetween: 20, centeredSlides: false },
        }}
        slidesPerView={slidesPerView ? slidesPerView : 'auto'}
        pagination={pag ? paginationStats : false}
        scrollbar={false}
        centeredSlides
        slideToClickedSlide
        onSlideChange={handleChangeSlide}
        slidesOffsetBefore={type === 'home' && !isMobile ? 47 : 0}
        slidesOffsetAfter={type === 'home' && !isMobile ? 47 : 0}
      >
        {cards.map((item: ReactNode, index: number) => {
          return (
            <SwiperSlide
              style={{ width: 'auto', maxWidth: '100%', height: 'auto' }}
              className={`mb-30px mt-20px ${
                index === currentSlide + opacityOffset ? 'opacity-50' : ''
              }`}
              key={index}
            >
              {item}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Carousel;
