import React from 'react';

import { Link } from 'gatsby';

import { ButtonType } from '../../types/button';
import ShapeX, { ShapeType } from '../ShapeX';

export type TwoColumnTextBannerType = {
  headerText?: string;
  headerSubText?: string;
  subText?: any[];
  btns?: ButtonType[];
  bgColor?: string;
  textColor?: string;
  subContentLink?: {
    label: string;
    url?: string;
    homeUrl?: string;
    carUrl?: string;
  };
  subBody?: any;
};

type Props = {
  data: TwoColumnTextBannerType;
  shape?: boolean;
  shapeLocation?: ShapeType['position'];
  attached?: boolean;
  home?: boolean | null;
  setHome?: (home: boolean | null) => void;
  section172?: boolean;
  lastElement?: boolean;
  noHR?: boolean;
  disclosurePage?: boolean;
};

const TwoColumnTextBanner = ({
  section172,
  noHR,
  disclosurePage,
  lastElement,
  data,
  attached,
  shape = true,
  shapeLocation = 'bottom-left',
  home,
  setHome,
}: Props) => {
  let subHeader;

  if (home !== undefined && home) {
    subHeader = 'Are you a car customer?';
  } else if (home !== undefined && !home) {
    subHeader = 'Are you a home customer?';
  } else {
    subHeader = '';
  }

  return (
    <div
      id={home ? 'home' : 'anchor'}
      className={`
        ${data.bgColor}
        ${
          section172 && !lastElement
            ? 'pb-60px md:pb-130px'
            : 'pb-82px md:pb-130px'
        }
        ${lastElement ? 'pb-140px md:pb-300px' : ''}

        overflow-hidden bg-left-bottom bg-no-repeat bg-80 md:bg-50 relative`}
    >
      <div className="max-w-1390 padding-x">
        {section172 && !noHR ? (
          <div className="bg-pxCharcoal w-full h-1px mb-60px md:mb-130px" />
        ) : (
          <div className="mb-60px md:mb-130px" />
        )}
        <div
          className={`
          ${data.textColor} 
          ${noHR ? 'mt-82px md:mt-130px' : ''}
          ${attached ? 'border-t border-dividerGrey pt-82px md:pt-130px' : ''} 
          justify-between flex flex-col ${
            section172 && !noHR ? 'lg:flex-row' : 'md:flex-row'
          }`}
        >
          <div
            className={`w-full ${
              disclosurePage
                ? 'lg:w-1/2'
                : section172 && !noHR
                ? 'lg:w-5/12'
                : 'md:w-5/12'
            }  relative z-50`}
          >
            {data.headerText && home != null && (
              <h2 className=" mb-32px">
                {home ? data.headerText : 'Manage your car policy'}
              </h2>
            )}
            {data.headerText && home == null && (
              <h2
                className={`${
                  section172
                    ? 'text-blackBannerTitle md:text-sectionHeading'
                    : 'mb-32px'
                }`}
              >
                {data.headerText}
              </h2>
            )}
            {data.headerSubText && (
              <p
                className={` ${
                  section172
                    ? 'text-bannerCopy md:text-sectionSubHeadingMobile mb-0 mt-32px'
                    : 'mb-32px'
                }`}
              >
                {data.headerSubText}{' '}
              </p>
            )}
            {data.subContentLink && data.subContentLink.url && (
              <Link
                className="text-pxPink font-semibold"
                to={data.subContentLink.url}
                onClick={() => setHome && setHome(!home)}
              >
                {data.subContentLink.label}
              </Link>
            )}
            {!data.subContentLink && (
              <button
                className={`text-small text-pxPink font-semibold ${
                  section172 ? '' : 'mt-32px'
                }`}
                onClick={() => setHome && setHome(!home)}
                aria-label="Pick your product type"
              >
                {subHeader}
              </button>
            )}
          </div>
          <div
            className={`w-full ${
              section172 && !noHR ? 'lg:w-5/12' : 'md:w-5/12'
            } ${data.subText ? '' : ''}`}
          >
            {data.subBody ? data.subBody : null}
            {data.subText != null
              ? data.subText.map((textOrHtml) => {
                  if (typeof textOrHtml !== 'string') {
                    return textOrHtml;
                  }
                  return (
                    <p
                      className={` 
                              ${
                                section172 &&
                                'text-bannerCopy md:text-sectionSubHeadingMobile'
                              }
                              ${lastElement ? '' : 'mb-32px'}
                              `}
                      key={textOrHtml}
                      dangerouslySetInnerHTML={{ __html: textOrHtml }}
                    ></p>
                  );
                })
              : ''}
            {data.btns != null
              ? data.btns.map((button) => (
                  <Link
                    className={`
                        ${button.primary ? 'btn-primary' : 'btn-secondary'} 
                        btn-md md:btn-lg mx-auto md:ml-0 mb-32px last:mb-0 first:mt-32px md:first:mt-0`}
                    // @ts-ignore
                    to={
                      button.url
                        ? button.url
                        : button.homeUrl &&
                          button.carUrl &&
                          (home ? button.homeUrl : button.carUrl)
                    }
                    key={'bannerbutton' + button.label}
                  >
                    {button.label}
                  </Link>
                ))
              : ''}
          </div>
        </div>
      </div>
      {shape ? <ShapeX position={shapeLocation} /> : null}
    </div>
  );
};

export default TwoColumnTextBanner;
