import { styled } from 'styled-components';

import CarSvg from '../../static/svgIcons/Car.svg';
import HouseSvg from '../../static/svgIcons/House.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
} from '../styles/sharedStyled';

export const HeroBannerTitle = styled.h1`
  font-family: ${fontFamilyCamptonMedium};
  font-weight: 500;
  font-size: 30px;
  line-height: 34.92px;
  padding: 0 25px;
  margin-bottom: 15px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 35px;
    line-height: 41px;
    padding: 0;
    margin-bottom: 25px;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 30px;
    text-align: left;
  }
`;

export const HeroBannerText = styled.p`
  color: ${colors.purple7};
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 25px;
    line-height: 29.1px;
    text-align: left;
  }
`;

export const HouseIcon = styled(HouseSvg)`
  stroke: ${colors.purple7};
  fill: ${colors.purple7};
  height: 40px;
  width: 68px;
`;

export const CarIcon = styled(CarSvg)`
  height: 40px;
`;

export const ImageContainer = styled.div<{ $isPetChangesEnabled: boolean }>`
  width: 231px;
  height: 280px;

  @media (min-width: 768px) {
    width: 300px;
    height: 363px;
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 100%;
    max-width: 497px;
    max-height: 600px;

    ${({ $isPetChangesEnabled }) =>
      $isPetChangesEnabled &&
      'margin-left: 30px; margin-bottom: 55px; @media (min-width: 1100px) {margin-bottom: 0;} @media (min-width: 1200px) {margin-left: 0;}'}
  }
`;
