import React, { useState } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import MobileCookieTable from './MobileCookieTable';

export type TableRowData = { name: string; setBy: string; purpose: string };

export type TableData = {
  tableTitle: string;
  tableId: string;
  tableRows: TableRowData[];
};

type Props = {
  data: {
    bgColor: string;
    heading: string;
    subHeading: string;
    tableHeadings: string[];
    tables: TableData[];
    additionalDisclaimer: React.JSX.Element;
    disclaimer: string;
  };
};

const CookieTable = ({ data }: Props) => {
  const { isMobile } = useBreakpoint();
  const [openTable, setOpenTable] = useState<string | undefined>(undefined);

  return (
    <div
      className={`${data.bgColor} pt-60px md:pt-100px lg:pt-130px px-24px md:px-30px`}
    >
      <div className="lg:max-w-1450 m-auto">
        <div className="justify-between flex flex-col md:flex-row mb-60px md:mb-80px lg:mb-130px">
          <div className="w-full md:w-45">
            {data.heading && (
              <h2 className="mb-25px md:mb-0">{data.heading} </h2>
            )}
          </div>
          <div className="w-full md:w-5/12 md:mb-0">
            <p className="mb-0">{data.subHeading}</p>
          </div>
        </div>
        {isMobile ? (
          <>
            {data.tables.map((table: TableData) => (
              <MobileCookieTable
                key={table.tableId}
                table={table}
                tableHeadings={data.tableHeadings}
                openTable={openTable}
                setOpenTable={setOpenTable}
              />
            ))}
          </>
        ) : (
          <>
            {data.tables.map((table: TableData) => (
              <div key={table.tableId} data-testid={`${table.tableId}`}>
                <h3 className="mb-25px text-fontSize-25 text-pxPurple font-camptonMedium font-medium lg:text-fontSize-30 ">
                  {table.tableTitle}
                </h3>
                <table className="flex flex-col rounded-xl mb-60px">
                  <thead>
                    <tr className="grid redesign-grid-template-table-standard bg-pxGrey rounded-t-2xl">
                      {data.tableHeadings.map((title: string) => (
                        <td
                          key={title}
                          className="border-white border-r last:border-0"
                        >
                          <p
                            className={
                              'text-center py-30px text-fontSize-15 font-camptonSemiBold mb-0'
                            }
                          >
                            {title}
                          </p>
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="flex flex-col  bg-white rounded-b-2xl text-pxBlack font-camptonBook">
                    {table.tableRows.map((item: TableRowData) => (
                      <tr
                        key={item.name}
                        className="grid redesign-grid-template-table-standard"
                      >
                        <td className="px-15px break-all flex items-center text-pxPurple font-bold py-20px border-pxGrey border-b border-r text-fontSize-15 mb-0">
                          {item.name}
                        </td>
                        <td className="border-pxGrey border-t flex font-normal justify-center items-center px-15px py-20px text-pxPurple text-fontSize-15 mb-0">
                          {item.setBy}
                        </td>
                        <td className="border-pxGrey border-t border-l flex items-center font-normal px-15px py-20px text-pxPurple text-fontSize-15 mb-0">
                          {item.purpose}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </>
        )}

        <div>{data.additionalDisclaimer}</div>
        <p className="text-fontSize-20 mb-0">{data.disclaimer}</p>
      </div>
    </div>
  );
};

export default CookieTable;
