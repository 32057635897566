import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const ComplaintsBackgroundContainer = styled.div<{
  $backgroundColor: string;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const ComplaintsDetailsContainer = styled.div`
  display: flex;
  padding: 60px 24px;

  @media (min-width: 768px) {
    padding: 100px 30px;
  }

  @media (min-width: 1024px) {
    max-width: 1440px;
    margin: 0 auto;
  }
`;

export const ComplaintsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ComplaintsHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 30px;

  @media (min-width: 768px) {
    padding-bottom: 40px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 80px;
  }
`;

export const ComplaintsSectionHeader = styled.h1`
  font-size: 35px;
  font-family: ${fontFamilyCamptonMedium};

  @media (min-width: 768px) {
    font-size: 45px;
  }

  @media (min-width: 1024px) {
    font-size: 80px;
  }
`;

export const ComplaintsCardsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    gap: 60px;
    flex-flow: wrap;
  }
`;

export const ComplaintsCardsHeader = styled.h4`
  font-size: 20px;
  color: ${colors.black};
  font-family: ${fontFamilyCamptonSemiBold};
  padding-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 25px;
    padding-bottom: 25px;
  }
`;
