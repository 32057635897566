import { useEffect, useState } from 'react';

import { useLocation } from '@reach/router';

const useIsCampaignPage = () => {
  const [isCampaignPage, setIsCampaignPage] = useState<boolean | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const isCampaignPage =
      pathname.includes('/campaign/') &&
      !pathname.includes('/renewalprice/') &&
      !pathname.includes('/home-renewalprice/');
    setIsCampaignPage(isCampaignPage);
  }, [pathname]);
  return isCampaignPage;
};

export default useIsCampaignPage;
