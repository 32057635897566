import React from 'react';

import {
  ContentsContainer,
  IconContainer,
  ImpactButtons,
} from './ProductButtons.style';

type Props = {
  svg: React.JSX.Element;
  buttonHeader: string;
  onClick: () => void;
  isActive: boolean;
};
const ProductButtons = ({ svg, buttonHeader, onClick, isActive }: Props) => {
  return (
    <ImpactButtons
      onClick={onClick}
      $isActive={isActive}
      data-testid="product-button"
    >
      <ContentsContainer>
        <IconContainer>{svg}</IconContainer>
        {buttonHeader}
      </ContentsContainer>
    </ImpactButtons>
  );
};

export default ProductButtons;
