import React from 'react';

import { Link } from 'gatsby';

import { ButtonType } from '../../types/button';
import { ProductTableDataType } from '../productTable/ProductTable';

interface ProductTableProps {
  data: ProductTableDataType;
}

const NoCarouselTable = ({ data }: ProductTableProps) => (
  <div
    className={`${data.bgColor} py-82px md:py-130px relative overflow-hidden`}
  >
    <div className="padding-x relative z-20">
      <div className={`flex flex-col lg:flex-row ${data.textColor} pb-82px`}>
        <h2 className="pb-32px lg:pb-0 lg:w-1/2 ">{data.headerText}</h2>
        <div className="flex flex-col lg:w-1/3 lg:ml-auto">
          {data.rightPanelInfo
            ? data.rightPanelInfo.map((item) => (
                <div
                  key={item.infoTitle || item.info}
                  className="flex flex-row"
                >
                  {item.infoTitle && (
                    <p className="font-semibold text-small text-left mr-1">
                      {item.infoTitle}
                    </p>
                  )}
                  {item.info && <p className="text-small">{item.info}</p>}
                </div>
              ))
            : null}
        </div>
      </div>

      <table className="bg-white rounded-xl table-fixed relative overflow-hidden mx-auto">
        <tbody className="flex flex-col">
          <tr className={`bg-pxGrey  grid grid-cols-${data.columns}`}>
            {data.tableTitles
              ? data.tableTitles.map((item) => (
                  <td
                    key={item.tableTitle}
                    className="w-full border-pxWhite border-r"
                  >
                    <p className="body-copy mb-0 p-32px text-finerDetailsBodyCopy font-semibold text-center">
                      {item.tableTitle}
                    </p>
                  </td>
                ))
              : ''}
          </tr>

          {data.productRows.map((item: any) => (
            <tr key={item.label} className={`grid grid-cols-${data.columns} `}>
              <td className="border-pxGrey border flex items-center ">
                <p className="body-copy mb-0 px-32px  text-finerDetailsBodyCopy">
                  {item.label}
                </p>
              </td>

              {!item.includedHTML &&
                item.included.map((includes: any) => (
                  <td
                    key={includes}
                    className="pt-32px px-32px border-pxGrey border "
                  >
                    <p className=" text-left text-finerDetailsBodyCopy text-pxPurple mb-2">
                      {includes}
                    </p>

                    {item.link && (
                      <p className="text-finerDetailsBodyCopy">
                        You can view their privacy policy here:{' '}
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.link}
                        </a>
                      </p>
                    )}
                  </td>
                ))}
              {item.includedHTML && (
                <td
                  key={item.includedHTML}
                  className="pt-32px px-32px border-pxGrey border "
                >
                  {item.includedHTML}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <div className="relative z-40 padding-x flex mt-32px md:mt-82px justify-center flex-col md:flex-row items-center">
      {data.buttons
        ? data.buttons.map((btn: ButtonType) => (
            <Link
              key={btn.label}
              // @ts-ignore
              to={btn.url}
              className={`btn-md md:btn-lg md:mr-32px mb-32px last:mr-0 btn-${
                btn.primary ? 'primary' : 'secondary'
              }`}
            >
              {btn.label}
            </Link>
          ))
        : null}
    </div>
  </div>
);

export default NoCarouselTable;
