import { Link } from 'gatsby';
import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const ImpactContactBanner = styled.div<{ $backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 24px 95px;
  text-align: center;
  overflow: hidden;
  background-color: ${({ $backgroundColor }) => $backgroundColor || ''};
  margin-bottom: -70px;

  @media (min-width: 768px) {
    padding: 100px 0;
  }

  @media (min-width: 1024px) {
    padding: 130px 0 90px;
  }
`;

export const ImpactContactBannerHeader = styled.h2`
  font-size: 35px;
  line-height: normal;
  color: ${colors.white};
  letter-spacing: 0;
  margin-bottom: 50px;
  font-family: ${fontFamilyCamptonMedium};

  @media (min-width: 768px) {
    font-size: 40px;
    margin-bottom: 90px;
  }
  @media (min-width: 1024px) {
    font-size: 65px;
  }
`;

export const ImpactContactBannerCardWrapper = styled.div<{
  $backgroundColor: string;
  $isReverse?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor || ''};
  margin-bottom: 30px;
  padding: 40px 20px 0px 20px;
  border-radius: 15px;
  position: relative;
  max-width: 390px;
  height: 518px;

  @media (min-width: 768px) {
    max-width: none;
    width: 754px;
    height: 304px;
    padding: 50px 40px;
    margin-bottom: 90px;
    border-radius: 40px;
    flex-direction: ${({ $isReverse }) => ($isReverse ? 'row-reverse' : 'row')};
  }

  @media (min-width: 1024px) {
    width: 800px;
    height: 338px;
    padding: 60px;
    border-radius: 40px;
  }
`;

export const ImpactContactBannerCardImageWrapper = styled.div`
  width: 272px;
  height: 267px;

  @media (min-width: 768px) {
    width: 346px;
    height: 340px;
  }

  @media (min-width: 1024px) {
    width: 376px;
    height: 370px;
  }
`;

export const ImpactContactBannerCardInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    text-align: left;
    width: 320px;
    align-items: flex-start;
  }
`;

export const ImpactContactBannerCardHeader = styled.h3`
  line-height: normal;
  letter-spacing: 0;
  padding: 0;
  margin-bottom: 15px;
  font-size: 25px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
`;

export const ImpactContactBannerCardSubText = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  line-height: normal;
`;

export const ImpactContactBannerCardButtons = styled(Link)`
  width: 100%;
  height: 50px;
  padding: 15px 0;
  border-color: ${colors.pink2};
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 18px;
  background-color: ${colors.white};
  border-width: 2px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  text-align: center;
  color: ${colors.pink2};
  justify-content: center;
  border-radius: 15px;
  align-items: center;

  &:hover {
    background-color: ${colors.pink2};
    color: ${colors.white};
  }

  &:disabled {
    opacity: 0.5;
    background-color: ${colors.white};
    color: ${colors.pink2};
  }

  @media (min-width: 768px) {
    width: 250px;
    height: 68px;
    padding: 25px 0;
    font-size: 20px;
  }

  @media (min-width: 1024px) {
    width: 320px;
  }
`;

export const ImpactContactBannerImageContainer = styled.div<{
  $isReverse?: boolean;
}>`
  position: absolute;
  bottom: 0;

  @media (min-width: 768px) {
    top: -36px;
    right: ${({ $isReverse }) => ($isReverse ? '' : '30px')};
    left: ${({ $isReverse }) => ($isReverse ? '30px' : '')};
  }

  @media (min-width: 1024px) {
    top: -32px;
    right: ${({ $isReverse }) => ($isReverse ? '' : '-30px')};
    left: ${({ $isReverse }) => ($isReverse ? '-30px' : '')};
  }
`;
