import useHypertune from './useHypertune';
import useQueryParams from './useQueryParams';

const useEnableHECLandingPage = () => {
  const { enableHecLandingPage } = useQueryParams();

  const rootNode = useHypertune();

  if (enableHecLandingPage === 'false') {
    return false;
  }

  return (
    rootNode.enableHECLandingPage().get(false) ||
    enableHecLandingPage === 'true'
  );
};

export default useEnableHECLandingPage;
