import React, { useState } from 'react';

import { Link } from 'gatsby';

import ArrowDown from '../../../static/svgIcons/ArrowDown.svg';
import CareersNavigationBlock from './CareersNavigationBlock';

const CareersNavigation = () => {
  const [hover, setHover] = useState(false);

  const onMouseEnter = () => {
    setHover(true);
  };
  const onMouseLeave = () => {
    setHover(false);
  };

  const [mobileDropDownIsOpen, setMobileDropDownIsOpen] = useState(false);
  const links = [
    {
      label: 'Careers home',
      link: '/about-us/careers',
    },
    {
      label: 'London office',
      link: '/about-us/careers/london',
    },
    {
      label: 'Milton Keynes office',
      link: '/about-us/careers/milton-keynes',
    },
    {
      label: 'Motherwell office',
      link: '/about-us/careers/motherwell',
    },
    {
      label: 'Working here',
      link: '/about-us/careers/working-here',
    },
    {
      label: 'Application process',
      link: '/about-us/careers/application-process',
    },
    {
      label: 'View jobs',
      link: '/about-us/careers/jobs',
    },
  ];

  return (
    <div className={'relative z-10 bg-white '} style={{ height: 60 }}>
      <div className="bg-white relative padding-x flex-row align-around mx-auto transition-all hidden lg:flex">
        {links.map((v, i) => (
          <CareersNavigationBlock
            label={v.label}
            url={v.link}
            key={v.label}
            isLast={links.length - 1 === i}
          />
        ))}
      </div>

      <div
        className="bg-white relative padding-x flex flex-row items-center justify-start transition-all lg:hidden w-full"
        style={{ height: 60 }}
      >
        <Link
          to={links[0].link}
          className={'inline-block text-pxPink font-bold mr-5'}
        >
          <p className="m-0 font-semibold">{links[0].label}</p>
        </Link>
        {/* Drop down container */}
        <div className={'inline-block'}>
          <button
            onClick={() => setMobileDropDownIsOpen(!mobileDropDownIsOpen)}
            style={{ width: '91px', paddingLeft: 14, paddingRight: 16 }}
            className={
              'border-2 flex flex-row border-pxPink bg-pxPink hover:bg-white relative text-white font-bold text-lg hover:text-pxPink rounded-full'
            }
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onTouchStart={onMouseEnter}
          >
            <div>
              <span className="text-careersButton font-camptonSemiBold">
                More
              </span>
            </div>
            <div style={{ maxWidth: 12, paddingLeft: 7 }}>
              <span
                className={`transform inline-block ${
                  mobileDropDownIsOpen ? 'rotate-180' : 'rotate-0'
                } `}
                style={{ maxWidth: 12 }}
              >
                <ArrowDown
                  stroke={`${hover ? '#FF00A0' : 'white'}`}
                  style={{ maxWidth: 12 }}
                  className="z-50"
                  fill="#6214bE"
                />
              </span>
            </div>
          </button>
          <div
            className={`absolute bg-white shadow-xl rouned-xl pt-3.5 ${
              mobileDropDownIsOpen ? 'block' : 'hidden'
            }`}
          >
            {links.slice(1, links.length).map((v) => {
              return (
                <Link
                  to={v.link}
                  className={
                    'py-2 px-5 border-b border-gray-400 block font-camptonSemiBold'
                  }
                  key={'mob-' + v.label}
                >
                  {v.label}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersNavigation;
