import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
} from '../../styles/sharedStyled';

export const HecHeaderBannerImageContainer = styled.div`
  height: 300px;
  width: 248px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    height: 350px;
    width: 289px;
    margin-bottom: 0;
  }

  @media (min-width: 1024px) {
    height: 500px;
    width: 413px;
  }
`;

export const HecHeaderBannerTitle = styled.h1`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 40px;
  margin: 40px 0 10px;
  color: ${colors.white};

  @media (min-width: 768px) {
    font-size: 50px;
    margin: 0 0 25px;
  }

  @media (min-width: 1024px) {
    font-size: 80px;
    margin: 0 0 30px;
  }
`;

export const HecHeaderBannerText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  color: ${colors.white};

  @media (min-width: 768px) {
    font-size: 24px;
    margin-bottom: 0;
    text-align: left;
  }

  @media (min-width: 1024px) {
    font-size: 28px;
  }
`;

export const CoverLink = styled.a``;
