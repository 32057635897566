import React from 'react';

import PolicExpertBSG from '../../../static/svgIcons/PolicyExpertBSG.svg';
import PolicExpertProtect from '../../../static/svgIcons/PolicyExpertProtect.svg';
import Carousel from '../carousel/Carousel';
import Icon from '../icons/Icon';
import ProductCard from '../productCard/ProductCard';

type ProductRowType = {
  label: string;
  included: string[];
  hasBlackText?: boolean;
};

export type ProductDataType = {
  bgColor: string;
  textColor?: string;
  headerText: string;
  type: 'home' | 'car';
  isMaxWidth: boolean;
  columns: number;
  subColumns: number;
  productRows: ProductRowType[];
};

type ProductChargesTableProps = {
  data: ProductDataType;
};

const ProductChargesTable = ({ data }: ProductChargesTableProps) => {
  const icons = [
    <PolicExpertBSG key="policy-expert-bsg" className="mx-auto" />,
    data.columns === 2 ? (
      <PolicExpertProtect key="policy-expert-protect" className="mx-auto" />
    ) : null,
  ];

  const cards: JSX.Element[] = [];
  const secondaryCards: JSX.Element[] = [];

  if (data.type === 'home') {
    data.productRows[0].included
      .slice(0, 4)
      .forEach((item: any, index: number) => {
        const productCard = (
          <ProductCard
            key={`carousel_${item}_${index}`}
            data={data}
            img={index < 2 ? icons[0] : icons[1]}
            column={index}
            iconSize={4}
          />
        );

        (index < 2 ? cards : secondaryCards).push(productCard);
      });
  } else {
    data.productRows[0].included.forEach((item: any, index: number) => {
      cards.push(
        <ProductCard
          key={`carousel_${item}_${index}`}
          data={data}
          img={icons[index]}
          column={index}
          iconSize={4}
          type={data.type}
        />
      );
    });
  }

  return (
    <div className="md:pt-10px md:pb-15px lg:px-30px lg:pt-20px lg:pb-40px product-charges-table">
      <div className="relative z-20">
        <div className="lg:w-3/4 px-24px md:px-40px lg:px-30px lg:relative">
          <h3 className="text-pxPurple font-camptonBook text-fontSize-25 lg:text-fontSize-30 lg:absolute lg:bottom-140-minus lg:left-0 lg:z-50">
            {data.headerText}
          </h3>
        </div>
        <table
          className={`${
            data.isMaxWidth ? 'w-full' : 'max-w-992'
          } hidden  lg:block bg-white rounded-xl relative overflow-hidden`}
        >
          <thead
            className="flex flex-col"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <tr className={`grid grid-cols-1n${data.columns}`}>
              <td
                className={`w-full border-pxGrey border-r border-b hide ${
                  (data.productRows[0].label === '' || data.type === 'car') &&
                  'bg-pxGrey'
                }
                `}
              />

              {icons.map((item, index) => {
                if (!item) {
                  return null;
                }
                return (
                  <td
                    key={`${item.key}_${index}`}
                    className="w-full py-32px border-b border-pxGrey border-r border-l"
                  >
                    <Icon type="primary" size={4} className="mx-auto">
                      {icons[index]}
                    </Icon>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody
            className="flex flex-col"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {data.productRows.map((item: any, index: number) => (
              <tr
                key={`${item.label}_${index}`}
                className={`grid grid-cols-1n${data.subColumns}`}
              >
                <td
                  className={`border-pxGrey border items-center flex flex-col justify-center ${
                    item.label.length === 0 ? 'hide' : ''
                  } ${item.label.length === 0 && 'bg-pxGrey'}`}
                >
                  <p className="body-copy mb-0 px-32px text-finerDetailsBodyCopy w-full">
                    {item.label}
                  </p>
                  {item.subLabel && (
                    <p className="text-footer mb-0 px-32px w-full">
                      {item.subLabel}
                    </p>
                  )}
                </td>

                {item.included.map((includes: any, index: number) => (
                  <td
                    key={`${includes}_${index}`}
                    className="pt-32px border-pxGrey border "
                  >
                    <p
                      className={`text-center text-finerDetailsBodyCopy ${
                        item.hasBlackText ? 'text-pxBlack' : 'text-pxPurple'
                      }`}
                    >
                      {includes}
                    </p>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="lg:hidden ">
        <Carousel
          slidesPerView={1.2}
          offsetfirstslide
          cards={cards}
          type={data.type}
          paddingTop={false}
        />
      </div>

      {data.type === 'home' && (
        <div className="lg:hidden ">
          <Carousel
            slidesPerView={1.2}
            offsetfirstslide
            cards={secondaryCards}
            type={data.type}
            paddingTop={false}
          />
        </div>
      )}
    </div>
  );
};

export default ProductChargesTable;
