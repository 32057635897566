import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HelpCentre from '../../../static/svgIcons/Helpcentre.svg';
import ShapeX from '../ShapeX';

export interface HelpSectionProps {
  header: string;
  text: string;
  button: { url: string; label: string };
}

const HelpSection = ({ header, text, button }: HelpSectionProps) => {
  const { isMobile, isTablet } = useBreakpoint();
  return (
    <div className="bg-pxWhite lg:mt-0 relative z-100 lg:flex lg:flex-row lg:justify-center lg:items-start pb-95px mb-35-minus lg:pt-130px md:pt-60px md:pb-140px md:mb-80-minus lg:pb-100px xl:pb-0 pt-60px">
      <div className="text-center flex flex-col items-center lg:relative lg:left-100px">
        <h2 className="font-medium text-fontSize-35 lg:text-fontSize-80 pb-50px lg:pb-90px">
          {header}
        </h2>
        <HelpCentre
          data-testid="help-centre-icon"
          className="h-65px w-83px md:h-70px md:w-90px lg:h-100px lg:w-128px"
        />
        <p className="text-fontSize-20 pt-30px mb-30px lg:text-fontSize-25">
          {text}
        </p>
        <a
          href={button.url}
          key={button.label}
          className="btn-secondary btn-sm-impact w-260px h-50px md:h-68px text-fontSize-16 md:text-fontSize-20 pt-20px md:pt-17px md:w-250px mb-0"
        >
          {button.label}
        </a>
      </div>
      <StaticImage
        src="../../images/woman_on_phone.png"
        alt="Help Centre"
        className="hidden lg:block w-full max-h-900 max-w-887.73px z-100 right-85-minus mt-300-minus lg:top-155px xl:top-0"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
      {(isMobile || isTablet) && <ShapeX position="bottom-left-help" />}
    </div>
  );
};

export default HelpSection;
