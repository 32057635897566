import { styled } from 'styled-components';

import StarsArray from '../../../static/svgIcons/StarsArray.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
} from '../../styles/sharedStyled';

export const ReviewCardContainer = styled.div`
  padding: 70px 30px 0;
`;

export const ReviewCardTitle = styled.h4`
  font-size: 22px;
  text-align: left;
  color: ${colors.black};
  padding-bottom: 30px;
  line-height: normal;
  font-family: ${fontFamilyCamptonMedium};
`;

export const ReviewCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewCardText = styled.p`
  font-size: 16px;
  line-height: normal;
  margin-bottom: 30px;
  font-family: ${fontFamilyCamptonBook};
`;

export const StarsArrayIcon = styled(StarsArray)`
  z-index: 50;
  height: 15px;
  width: 95px;
  margin-bottom: 10px;
`;

export const ReviewCardAuthor = styled.p`
  font-size: 13px;
  line-height: normal;
  font-family: ${fontFamilyCamptonBook};
  margin-bottom: 0;
`;
