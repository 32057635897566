import { styled } from 'styled-components';

import { colors, fontFamilyCamptonMedium } from '../../styles/sharedStyled';

export const AboutOurExecContainer = styled.div`
  background-color: ${colors.green1};
  width: 100%;
  padding: 60px 0 100px;

  @media (min-width: 768px) {
    padding-top: 130px;
  }
`;

export const AboutOurExecWrapper = styled.div`
  padding: 0 24px;
  max-width: 1440px;
  margin: 0 auto;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  text-align: center;
  color: ${colors.purple7};
  padding-bottom: 25px;
  font-family: ${fontFamilyCamptonMedium};
  font-size: 30px;

  @media (min-width: 768px) {
    padding-bottom: 40px;
    font-size: 40px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 50px;
    font-size: 50px;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 332px;
  margin: auto;

  @media (min-width: 768px) {
    padding-bottom: 40px;
    gap: 50px;
    max-width: 700px;
  }

  @media (min-width: 1024px) {
    max-width: 1034px;
  }
`;
