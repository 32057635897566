import React, { useEffect } from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import CookiesBanner from '../components/cookiesBanner/CookiesBanner';
import CookieTable from '../components/cookieTable/CookieTable';
import HeaderBanner from '../components/headerBanner/HeaderBanner';
import { useCookieModal } from '../context/CookieModalProvider';
import useDisclaimer from '../hooks/useDisclaimer';
import { pushCookieConsentEventToDataLayer } from '../services/windowService';
import HeaderImageBreakpoints from '../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../images/cookie_policy_sm.png`}
        alt={`Cookie policy`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../images/cookie_policy_md.png`}
        alt={`Cookie policy`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../images/cookie_policy_lg.png`}
      alt={`Cookie policy`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const cookieBannerData = {
  heading: 'Cookies',
  titleImage: true,
  body: (
    <div>
      <h3 className="text-pxPurple font-semibold text-fontSize-20 md:text-fontSize-30 lg:text-fontSize-32 mb-15px pb-0">
        Making our website smarter to improve your experience
      </h3>

      <p className="text-fontSize-16 md:text-fontSize-20 mb-40px">
        The following section provides more information about how cookies are
        used to help make our website user friendly and easy to use. But rest
        assured, none of the cookies used on Policy Expert store personally
        identifiable information.
      </p>

      <h3 className="text-pxPurple font-semibold text-fontSize-20 md:text-fontSize-30 lg:text-fontSize-32 mb-15px pb-0">
        What is a cookie?
      </h3>

      <p className="text-fontSize-16 md:text-fontSize-20 mb-15px">
        A cookie is a small text file placed on your computer by websites or
        when you open an email. These files are widely used across the internet
        to improve your experience by customising web pages and the content you
        see online. Cookies are not used to collect any personal information
        stored on your computer.
      </p>

      <p className="text-fontSize-16 md:text-fontSize-20 mb-40px">
        You can read more information about cookies and how they are used at
        <a
          href="http://www.allaboutcookies.org"
          target="_blank"
          className="font-semibold"
          rel="noreferrer"
        >
          {' '}
          http://www.allaboutcookies.org
        </a>
        .
      </p>

      <h3 className="text-pxPurple font-semibold text-fontSize-20 md:text-fontSize-30 lg:text-fontSize-32 mb-15px pb-0">
        How does Policy Expert use cookies?
      </h3>

      <p className="text-fontSize-16 md:text-fontSize-20 font-camptonMedium mb-15px">
        Policy Expert use two types of cookies to enhance our website:
      </p>
      <ul className="text-fontSize-16 md:text-fontSize-20 pl-20px">
        <li className="mb-15px text-fontSize-16 md:text-fontSize-20 list-outside">
          Persistent cookies are used to help identify customers when they
          return to the Policy Expert website. We use this information to
          remember your preferences and serve content tailored to your
          interests. These cookies are stored on your computer until you
          manually delete them.
        </li>
        <li className="list-outside text-fontSize-16 md:text-fontSize-20 mb-0">
          Session cookies allow customers to carry information across pages of
          the website without having to re-enter information. For example if you
          login to your account and then visit our blog, you will be able to go
          back into your account without having to log back in. These cookies
          are temporary and are deleted when you close your browser.
        </li>
      </ul>
    </div>
  ),
  bgColor: 'bg-white',
};

const cookieTableData = {
  bgColor: 'bg-pxMint',
  heading: 'Which cookies do we use?',
  subHeading:
    'The following table explains which cookies might be set by Policy Expert when you visit our website, and which cookies may be set by suppliers used by Policy Expert to deliver our services to you.',
  tableHeadings: ['Cookie Name', 'Cookie set by', 'Purpose'],
  tables: [
    {
      tableTitle: 'Necessary cookies',
      tableId: 'necessary-cookies',
      tableRows: [
        {
          name: 'qmg_consent',
          setBy: 'Policy Expert',
          purpose: 'Allows us to collect and manage your cookie preferences',
        },
        {
          name: 'jsessionid',
          setBy: 'Policy Expert',
          purpose:
            'Once a customer logs into their Policy Expert account on the website this cookie allows the customer to remain logged in as they navigate the site. This expires after 30 minutes of inactivity and the customer is logged out.',
        },
        {
          name: 'i18next',
          setBy: 'Policy Expert',
          purpose: 'Sets the language of the website to English (“en-GB”).',
        },
        {
          name: 'er or er3',
          setBy: 'Policy Expert',
          purpose:
            'This cookie notes how the visitor arrived at the Policy Expert site (e.g. from a search engine). If you disable this cookie then any voucher codes you are using won’t work.',
        },
        {
          name: 'connect.sid',
          setBy: 'Policy Expert',
          purpose:
            'This cookie ensures that you’re the only person who is able to view the personal details you’ve provided when requesting a quote.',
        },
        {
          name: '__cfruid and __cf_bm',
          setBy: 'CloudFlare',
          purpose:
            'We use a company called Cloudflare to identify trusted traffic visiting our website and to provide access to content for customers in various parts of the UK / world.',
        },
        {
          name: '__zlcmid',
          setBy: 'Live chat',
          purpose:
            'The LiveChatPurpose cookie is used to enhance the functionality and user experience of the live chat feature on our website. It is a first-party cookie that is set when a user interacts with the live chat system.',
        },
        {
          name: 'awsalb and awsalbcors',
          setBy: 'Amazon',
          purpose:
            'We use cookies from Amazon to minimise interruptions to your experience on our website, when we’re improving our services.',
        },
        {
          name: 'OMGSession, OMG-Channel-272222, OMGID',
          setBy: 'Policy Expert',
          purpose:
            "These cookies are in place to ensure we're able to track a customers sales from Affiliate pages and make sure any cashback is paid in a timely manner.",
        },
        {
          name: '_gid, _ga, _gat, _gat_ <container-id> and _dc_gtm_ <container-id>',
          setBy: 'Google Analytics',
          purpose:
            'Our website uses Google Analytics which is a web analytics service provided by Google, Inc. Google Analytics sets cookies in order to allow us to evaluate use of our website, such as how long customers spend on a particular web page. All the data gathered by Google Analytics is anonymous, and no personal data of customers is stored.',
        },
        {
          name: 'api.eu1.exponea.com',
          setBy: 'Policy Expert',
          purpose:
            'Helps us to better understand our customers’ behaviour on site, allowing us to tailor and improve your experience with us.',
        },
        {
          name: 'optimizelyEndUserId',
          setBy: 'Optimizely',
          purpose:
            'This cookie ensures that you’re the only person who is able to view the personal details you’ve provided when requesting a quote.',
        },
        {
          name: 'optimizelyRedirectData',
          setBy: 'Optimizely',
          purpose:
            'After Optimizely Experimentation has executed a redirect experiment, it stores various data from the original page so that Optimizely Experimentation still has access to it on the new page.',
        },
        {
          name: 'optimizelyDomainTestCookie',
          setBy: 'Optimizely',
          purpose:
            'When Optimizely Experimentation loads a URL, the snippet places the cookie to get the current domain for determining whether cross-domain syncing is possible. If successful, the cookie is immediately removed.',
        },
      ],
    },
    {
      tableTitle: 'Performance cookies',
      tableId: 'performance-cookies',
      tableRows: [
        {
          name: 'sessionCorrelationId',
          setBy: 'Policy Expert',
          purpose:
            'If you run into an error, it will help us understand how it happened.',
        },
        {
          name: '_cs_id, _cs_s, _cs_vars, _cs_ex, _cs_c, _cs_optout and _cs_mk',
          setBy: 'ContentSquare',
          purpose:
            "Stores a visitor's unique Optimizely Experimentation identifier. It is a combination of a timestamp and a random number. No other information about you or your visitors is stored inside.",
        },
        {
          name: '_hj{various}',
          setBy: 'Hotjar',
          purpose:
            'Our website uses Hotjar to capture anonymous feedback and improve the overall user experience.',
        },
      ],
    },
    {
      tableTitle: 'Targeting / Advertising cookies',
      tableId: 'targeting-cookies',
      tableRows: [
        {
          name: 'Facebook',
          setBy: 'Policy Expert',
          purpose:
            'The Facebook Pixel is used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.',
        },
        {
          name: '_gcl_{region}',
          setBy: 'Google',
          purpose:
            'This cookie tracks the use of our website by users who have also engaged with our advertising.',
        },
      ],
    },
  ],
  additionalDisclaimer: (
    <p className="text-fontSize-20 mb-15px">
      In addition to the above, we also use cookies from Mention Me to run our
      refer-a-friend initiative. You can check the information that Mention Me
      collects via their{' '}
      <a
        className="font-camptonSemiBold"
        href="https://mention-me.com/help/privacy_policy_s#cookies"
        target="_blank"
        rel="noreferrer"
      >
        Cookie Policy
      </a>{' '}
      and their{' '}
      <a
        className="font-camptonSemiBold"
        href="https://mention-me.com/help/privacy_policy_s#visitors"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy.
      </a>
    </p>
  ),
  disclaimer:
    'The Policy Expert website and emails also contain ‘share’ buttons which enable you to share information from the Policy Expert website with friends across a number of social networks. When you log in to their service these sites may also set one or more cookies. You should check the relevant privacy and cookie policies for those social network sites to determine what information they are gathering.',
};

const CookieBannerBottom = () => {
  const { setShowModal } = useCookieModal();

  useEffect(() => {
    setShowModal(false);
  }, []);

  const handleCookieSettings = () => {
    setShowModal(true);
    pushCookieConsentEventToDataLayer(`open_cookie_settings`);
  };

  const data = {
    heading: 'How to manage your cookies',
    body: (
      <div>
        <p className="text-bodyCopy mb-32px">
          If you choose to restrict or block cookies some parts of the Policy
          Expert website may not function properly, or in some cases, will not
          be accessible at all. Removing or blocking cookies may also prevent us
          from determining whether you are eligible for promotions we may run
          from time to time.
        </p>
        <p className="text-bodyCopy mb-32px">
          It’s easy to amend your cookie preferences. Click the button below to
          get started.
        </p>
        <button
          type="button"
          onClick={handleCookieSettings}
          className="btn-secondary-impact w-full md:w-250px py-10px md:py-4 rounded-radius-15 justify-center items-center font-camptonSemiBold h-37px md:h-50px mb-10px last:mb-0 md:mb-0 text-fontSize-15 md:text-fontSize-16"
        >
          Cookie settings
        </button>
      </div>
    ),
    bgColor: 'bg-pxMint',
    textColor: 'black',
  };

  return (
    <div
      className={`${data.bgColor} py-82px md:py-130px bg-left-bottom bg-no-repeat bg-80 md:bg-50 relative overflow-hidden`}
    >
      <div
        className={`${data.textColor} padding-x justify-between flex flex-col md:flex-row relative z-40 `}
      >
        <div className="w-full z-20 md:w-5/12">
          <div>
            {data.heading && (
              <h2 className=" mb-32px break-words ">{data.heading} </h2>
            )}
          </div>
        </div>
        <div className="w-full z-20 md:w-5/12">{data.body}</div>
      </div>
    </div>
  );
};

const CookiePolicyPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxTan"
        headerImage={headerImage}
        shapePosition="bottom-mixed-header"
        styling="pl-35px md:pt-5px md:pl-80px md:pr-7 lg:pt-9 lg:px-6 max-w-1226 relative z-1"
      />
      <CookiesBanner data={cookieBannerData} />
      <CookieTable data={cookieTableData} />
      <CookieBannerBottom />
    </>
  );
};

export default CookiePolicyPage;

export const Head: HeadFC = () => (
  <>
    <title>Cookie policy | Policy Expert</title>
    <meta name="description" content="Policy Expert cookie policy" />
    <html lang="en" />
  </>
);
