import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ProductBannerPolicyDocuments, {
  ProductBannerPolicyDocumentsDataType,
} from '../productBannerPolicyDocuments/ProductBannerPolicyDocuments';
import ProductTable, {
  ProductTableDataType,
} from '../productTable/ImpactProductTable';
import ShapeX from '../ShapeX';
import { MainContainer } from './ProductBanner.style';

interface Props {
  productTableData: ProductTableDataType;
  policyDocuments: ProductBannerPolicyDocumentsDataType;
}

const ProductBanner = ({ productTableData, policyDocuments }: Props) => {
  const { isMobile, isTablet } = useBreakpoint();
  const showShapeX = isTablet && !isMobile;

  return (
    <MainContainer
      $backgroundColor={productTableData.bgColor}
      data-testid="product-banner"
    >
      <ProductTable data={productTableData} home bottomPadding={false} />
      <ProductBannerPolicyDocuments {...policyDocuments} />
      {showShapeX && <ShapeX position="bottom-left-product-table" />}
    </MainContainer>
  );
};

export default ProductBanner;
