import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { SemiBoldSpan } from '../../styles/sharedStyled';
import ShapeX from '../ShapeX';
import {
  ImageContainer,
  PetPictureHeader,
  PetPictureInformationContainer,
  PetPicturesContainer,
  PetPictureSubHeader,
  PetPictureText,
  PolicyExpertPetsBackgroundContainer,
  PolicyExpertPetsHeaderText,
  PolicyExpertPetsHeaderTextContainer,
  PolicyExpertPetsInformationWrapper,
  PolicyExpertPetsTitle,
} from './PolicyExpertPetsBanner.style';

const PolicyExpertPetsBanner = () => {
  const petPicturesBanner = [
    {
      picture: (
        <StaticImage
          src="../../images/pet_picture_1.png"
          alt="Picture of a dog"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      ),
      header: <PetPictureHeader>Mylo</PetPictureHeader>,
      subHeader: (
        <PetPictureSubHeader $maxWidth>
          The leader of the pack
        </PetPictureSubHeader>
      ),
      text: (
        <PetPictureText>
          His favourite thing is playing fetch, but delivering top-class service
          to pets and their owners comes pretty close.
        </PetPictureText>
      ),
    },
    {
      picture: (
        <StaticImage
          src="../../images/pet_picture_2.png"
          alt="Picture of a cat"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      ),
      header: <PetPictureHeader>Nala</PetPictureHeader>,
      subHeader: (
        <PetPictureSubHeader>
          Peace of mind customer service
        </PetPictureSubHeader>
      ),
      text: (
        <PetPictureText>
          Loves to snooze on the sofa, but you won’t find her catnapping if you
          have a query about your pet’s policy.
        </PetPictureText>
      ),
    },
    {
      picture: (
        <StaticImage
          src="../../images/pet_picture_3.png"
          alt="Picture of a dog"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      ),

      header: <PetPictureHeader>Bruno</PetPictureHeader>,
      subHeader: (
        <PetPictureSubHeader $maxWidth>
          Claims team to the rescue
        </PetPictureSubHeader>
      ),
      text: (
        <PetPictureText>
          We hope you never need to make a claim, but Bruno and the team are
          here to help guide you through the process.
        </PetPictureText>
      ),
    },
  ];

  return (
    <PolicyExpertPetsBackgroundContainer>
      <PolicyExpertPetsInformationWrapper>
        <PolicyExpertPetsTitle>
          Our team loves pets.{' '}
          <SemiBoldSpan>
            You can <SemiBoldSpan $isPurple>trust us</SemiBoldSpan> with yours.
          </SemiBoldSpan>
        </PolicyExpertPetsTitle>
        <PolicyExpertPetsHeaderTextContainer>
          <PolicyExpertPetsHeaderText>
            <SemiBoldSpan>Over 80%**</SemiBoldSpan> of our team have pets of
            their own. So, we know a few things about looking after, insuring,
            and loving our furry family members, and we've created our insurance
            products with them in mind. Get to know our team a little better -
            here are a few of our Policy Expert pets!
          </PolicyExpertPetsHeaderText>
        </PolicyExpertPetsHeaderTextContainer>
        <PetPicturesContainer>
          {petPicturesBanner.map((item, index) => (
            <PetPictureInformationContainer
              key={`${item.text.toString()}_${index}`}
              data-testid="pet-picture-container"
            >
              <ImageContainer>{item.picture}</ImageContainer>
              {item.header}
              {item.subHeader}
              {item.text}
            </PetPictureInformationContainer>
          ))}
        </PetPicturesContainer>
      </PolicyExpertPetsInformationWrapper>
      <ShapeX position={'bottom-left-pe-pets-banner'} />
    </PolicyExpertPetsBackgroundContainer>
  );
};

export default PolicyExpertPetsBanner;
