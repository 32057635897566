import React from 'react';

import { ReviewData } from '../../types/reviews';
import DropShapeCard from '../dropShapeCard/ImpactDropShapeCard';
import {
  ReviewCardAuthor,
  ReviewCardContainer,
  ReviewCardText,
  ReviewCardTextContainer,
  ReviewCardTitle,
  StarsArrayIcon,
} from './ReviewCard.style';

type ReviewCardProps = {
  data: ReviewData;
  petCard?: boolean;
};

const ReviewCard = ({ data, petCard }: ReviewCardProps) => (
  <DropShapeCard petCard={petCard}>
    <ReviewCardContainer data-testid="review-card">
      <ReviewCardTitle>{data.title}</ReviewCardTitle>
      <ReviewCardTextContainer>
        <ReviewCardText>{data.review}</ReviewCardText>
        <StarsArrayIcon />
        <ReviewCardAuthor>
          {data.author} - {data.date}
        </ReviewCardAuthor>
      </ReviewCardTextContainer>
    </ReviewCardContainer>
  </DropShapeCard>
);

export default ReviewCard;
