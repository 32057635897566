import { Link } from 'gatsby';
import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonSemiBold,
} from '../styles/sharedStyled';

const boldFontFamily = `
  font-family: ${fontFamilyCamptonSemiBold};
`;

const bookFontFamily = `
  font-family: ${fontFamilyCamptonBook};
`;

export const Text = styled.p`
  ${bookFontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const PrivacyPolicyIntroductionWrapper = styled.div`
  padding: 0 24px;

  @media (min-width: 768px) {
    padding: 0 40px;
  }

  @media (min-width: 1024px) {
    padding: 0 30px;
  }
`;

export const PrivacyPolicyIntroduction = styled.p`
  ${bookFontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const PrivacyPolicyLink = styled(Link)`
  ${boldFontFamily};
`;

export const Bold = styled.span`
  ${boldFontFamily};
`;

export const PurpleSpan = styled.span`
  color: ${colors.purple7};
`;

export const BoldPurpleText = styled.p`
  ${boldFontFamily};
  color: ${colors.purple7};
`;

export const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const ContactUsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ContactUsText = styled.p`
  ${bookFontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  min-width: 90px;
  margin: 0 15px 0 0;
`;

export const AddressLine = styled.span`
  &:not(:last-of-type)::after {
    content: ',';
  }
`;

export const BulletPoint = styled.li<{ marginBottom?: string }>`
  ${bookFontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};

  @media (min-width: 768px) {
    font-size: 18px;
  }

  text-indent: -1.3em;
  padding-left: 1.3em;
`;

export const SubSectionHeader = styled.p`
  ${boldFontFamily};
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 20px;
  scroll-margin-top: 25px;

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    scroll-margin-top: 95px;
  }
`;
