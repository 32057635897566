import React, { useContext } from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import TrustpilotHorizontalWhite from '../../../static/svgIcons/TrustPilotHorizontalWhite.svg';
import CtaBannerImpactV2 from '../../components/ctaBanner/CtaBannerImpactV2';
import ImpactV2HeaderBanner from '../../components/headerBanner/HeaderBannerV2';
import { HeaderWithCarouselDataType } from '../../components/headerWithCarousel/HeaderWithCarousel';
import InsuranceReviews from '../../components/insuranceReviews/InsuranceReviews';
import NewProductCarousel from '../../components/newProductCarousel/NewProductCarousel';
import { ReviewsContext } from '../../context/ReviewsProvider';
import useDisclaimer from '../../hooks/useDisclaimer';

const getHeaderImage = (isMobile: boolean, isTablet: boolean) => {
  if (isMobile) {
    return (
      <StaticImage
        src="../../images/raf_hero_banner_lg.png"
        alt="What type of cover do you want?"
        style={{
          width: '330px',
          height: '370px',
        }}
        imgStyle={{
          objectFit: 'scale-down',
        }}
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }

  if (isTablet) {
    return (
      <StaticImage
        src={`../../images/raf_hero_banner_lg.png`}
        alt="What type of cover do you want?"
        style={{
          width: '600px',
          height: '600px',
          marginLeft: '-45px',
        }}
        imgStyle={{
          objectFit: 'scale-down',
        }}
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }

  return (
    <StaticImage
      src={`../../images/raf_hero_banner_lg.png`}
      alt="What type of cover do you want?"
      style={{
        width: '600px',
        height: '600px',
      }}
      imgStyle={{
        objectFit: 'scale-down',
      }}
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const headerWithCarouselData: HeaderWithCarouselDataType = {
  header: 'What people say',
  slidesPerView: 3,
  links: [
    {
      name: 'Review Centre',
      link: 'https://www.reviewcentre.com/reviews264344.html',
      target: '_blank',
    },
    {
      name: 'Trustpilot',
      link: 'https://uk.trustpilot.com/review/www.policyexpert.co.uk',
      target: '_blank',
    },
    {
      name: 'Reviews.io',
      link: 'https://www.reviews.io/company-reviews/store/gc-policyexpert',
      target: '_blank',
    },
  ],
};

const RafSelectAProductPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage(isMobile, isTablet);

  const trustpilotReviews = useContext(ReviewsContext);
  const isHighTrustScore =
    parseFloat(trustpilotReviews.score?.trustScore) >= 4.8;

  return (
    <>
      <ImpactV2HeaderBanner
        headerImage={headerImage}
        backgroundColor="bg-pxPurple"
        slantDirection="bottom-left"
        displayTrustpilot={false}
        heroBannerData={{
          title: (
            <h2
              className={`text-${
                isMobile ? 'center' : 'left'
              } text-pxWhite lg:text-fontSize-65 sm:text-fontSize-sectionHeadingMobile mb-5 mt-20px`}
            >
              What type of cover do you need?
            </h2>
          ),
          text: (
            <>
              <p className="lg:text-left sm:text-center text-pxWhite">
                Join us today. <span className="font-bold">Get a quote</span>
              </p>
              {isMobile && <CtaBannerImpactV2 isRAFPage />}
              {trustpilotReviews.score && trustpilotReviews.numberOfReviews && (
                <>
                  <div
                    className={`flex ${
                      isMobile ? 'align-middle justify-center' : ''
                    }`}
                  >
                    <TrustpilotHorizontalWhite
                      className="icons-primary7 z-50 flex text-pxWhite"
                      fill={isHighTrustScore ? '#00b67a' : '#dcdce6'}
                    />
                  </div>
                  <p className="text-small text-center md:text-left mt-10 text-pxWhite">
                    {trustpilotReviews.score.trustScore}/5 from over{' '}
                    {trustpilotReviews.numberOfReviews.total.toLocaleString()}{' '}
                    reviews. 96.7% of users recommend us.
                  </p>
                </>
              )}
            </>
          ),
        }}
      />

      {!isMobile && <CtaBannerImpactV2 isRAFPage />}

      <NewProductCarousel
        data={headerWithCarouselData}
        cards={InsuranceReviews}
      />
    </>
  );
};

export default RafSelectAProductPage;

export const Head: HeadFC = () => (
  <>
    <title>Policy Expert: Home and Car insurance for people like you</title>
    <meta
      name="description"
      content="Low price insurance, easy to understand policies and stress free claims. That’s why 86% of home customers stay with us every year. Insurance for people like you."
    />
    <html lang="en" />
    <meta name="robots" content="noindex"></meta>
  </>
);
