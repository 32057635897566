import React, { useState } from 'react';

import Arrow from '../../../static/svgIcons/RoundedArrow.svg';
import { Section } from '../../pages/contact';
import ContactUs from '../contactUs/ContactUs';
import TalkToUs from '../talkToUs/TalkToUs';

type Props = {
  section?: Section;
};

const Accordion = ({ section }: Props) => {
  const [expand, setExpand] = useState(false);

  return (
    <div className="pt-40px border-0 cursor-pointer border-b last:border-0">
      <div
        tabIndex={0}
        onClick={() => setExpand(!expand)}
        role="button"
        className="font-camptonBook text-fontSize-16 lg:text-fontSize-20 mb-0 relative border-b-2 pb-20px"
      >
        More ways to contact us
        <Arrow
          className={`w-20px z-50 absolute top-2px right-0 transform ${
            expand ? 'rotate-0' : 'rotate-180'
          }`}
        />
      </div>

      {expand && (
        <div className="lg:flex lg:flex-row">
          <div className="lg:w-1/2 lg:pr-50px lg:mt-50px border-r">
            <ContactUs section={section} />
          </div>
          <div className="lg:w-1/2 lg:pl-50px lg:pt-10px">
            <TalkToUs section={section} />
          </div>
        </div>
      )}
    </div>
  );
};
export default Accordion;
