import React from 'react';

import HomeEmergencyTable from '../homeEmergencyTable/HomeEmergencyTable';
import { HecBannerContainer } from './HecProductBanner.style';

type TableContentType = {
  label: React.JSX.Element;
  values: React.JSX.Element[];
  details: React.JSX.Element;
  hasInfoRow?: boolean;
};

type TableDataType = {
  tableHeaderIcons: React.ReactNode[];
  tableContent: TableContentType[];
  tableInfoRow?: React.ReactNode;
};

export type HecBannerDataType = {
  bannerTitle: React.ReactNode;
  tableData: TableDataType;
};

const HecProductBanner = ({ bannerTitle, tableData }: HecBannerDataType) => {
  return (
    <HecBannerContainer>
      {bannerTitle}
      <HomeEmergencyTable tableData={tableData} />
    </HecBannerContainer>
  );
};

export default HecProductBanner;
