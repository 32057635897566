import React, { useContext } from 'react';

import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';

import HeartOne from '../../../static/svgIcons/HeartOne.svg';
import Screen from '../../../static/svgIcons/Screen.svg';
import CampaignHeaderBanner from '../../components/campaignHeaderBanner/CampaignHeaderBanner';
import ImageWithTextReveal, {
  ImageWithTextRevealType,
} from '../../components/imageWithTextReveal/ImageWithTextReveal';
import InsuranceReviews from '../../components/insuranceReviews/InsuranceReviews';
import ReviewCarousel from '../../components/reviewCarousel/ReviewCarousel';
import TwoColumnIconText, {
  TwoColumnIconTextDataType,
} from '../../components/twoColumnIconText/TwoColumnIconText';
import TwoColumnTextTable, {
  TwoColumnTextTableType,
} from '../../components/twoColumnTextTable/TwoColumnTextTable';
import { ReviewsContext } from '../../context/ReviewsProvider';
import useDisclaimer from '../../hooks/useDisclaimer';

const twoColumnTextTableData: TwoColumnTextTableType = {
  headerText: (
    <h2 className="font-medium text-pxBlack">
      <span className="text-pxPurple">£42 cashback</span> when you buy Bronze
      home insurance
    </h2>
  ),
  subText: (
    <p className="font-medium text-pxBlack text-center md:text-left lg:text-center lg:text-bodyCopy mt-10 lg:mt-50px mb-5">
      <span className="text-pxPurple font-semibold">£42 cashback</span> with
      your home insurance
    </p>
  ),
  btn: {
    label: 'Get a quote',
    url: 'https://insurance.policyexpert.co.uk/home',
    primary: true,
  },
  rowItem: [
    {
      label: 'Buildings insurance sum covered',
      included: '£600,000',
    },
    {
      label: 'Contents insurance sum covered',
      included: '£60,000',
    },
    {
      label: 'New for old cover as standard',
      included: '✔',
    },
    {
      label: 'Alternative accommodation (buildings)',
      included: 'Up to £75,000',
    },
    {
      label: 'Alternative accommodation (contents)',
      included: '£10,000',
    },
    {
      label: 'Credit cards in and away from home',
      included: '£1,000',
    },
    {
      label: 'Personal money in the home',
      included: '£250',
    },
    {
      label: 'Deep freezer contents',
      included: '£300',
    },
    {
      label: 'Property owners liability',
      included: '£2,000,000',
    },
    {
      label: 'Replacement locks or keys',
      included: '£300',
    },
  ],
};

const quidco42PageDisclaimer = (
  <>
    <br />
    <br />
    <p className="text-footerCopyMobile md:text-footer font-medium font-camptonMedium text-pxBlack mb-0">
      Cashback terms and conditions
    </p>
    <ul className="ml-5 mb-2">
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        Cashback is only available to UK residents.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        Cashback is only paid where your most recent quote was generated through
        a link provided via a cashback website.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        You will not receive cashback if you retrieve and purchase a quote
        generated via a different channel e.g. a price comparison website.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        Cashback payments are subject to a 90-day validation period. If your
        policy is cancelled within 90-days of your policy start date you will
        not qualify for cashback.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        Cashback will not be paid in conjunction with any other offer.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        Cashback is only available for new customers.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        The amount of cashback you receive is determined by when a quotation is
        generated and not when the policy is purchased. For example, if you
        generate a quote on 1st January when the cashback rate is £40 and
        purchase on 15th January when the cashback rate is £30 you will receive
        £40 cashback. Similarly, if you generate a quote on 1st January when the
        cashback rate is £30 and purchase on 15th January when the cashback rate
        is £40 you will receive £30 cashback.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        To qualify for cashback, you must purchase a combined buildings &
        contents policy. Buildings only or contents only policies do not qualify
        for cashback. 
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        To qualify for cashback, you must purchase a Policy Expert Bronze
        policy.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        Cashback must be redeemed within 13-months of the policy purchase date.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        Policy Expert offer one rate of cashback. If you purchase a Bronze
        policy and add home emergency or legal cover, you will not receive a
        higher cashback rate.
      </li>
      <li className="text-footerCopyMobile md:text-footer mb-0 list-outside">
        Quotes from Policy Expert are valid for 30 days.{' '}
      </li>
    </ul>
    <p className="text-footerCopyMobile md:text-footer mb-2">
      *Policy Expert is rated the number 1 home insurance provider in the UK on
      independent review website – Reviewcentre.com. Based on 55,000 customer
      reviews and a 96.7% recommendation rating, Mar 2023
    </p>
  </>
);

const Quidco42Page: React.FC<PageProps> = () => {
  useDisclaimer(quidco42PageDisclaimer);
  const trustpilotReviews = useContext(ReviewsContext);

  const Quidco42ImageQuery = useStaticQuery(graphql`
    query Quidco42Images {
      ReviewGarden: imageSharp(
        original: { src: { regex: "/review_image_garden/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const twoColumnIconTextData: TwoColumnIconTextDataType = {
    icons: [
      {
        icon: <HeartOne stroke="white" />,
        text: 'We’re rated the UK’s no.1 home insurance provider*',
        link: 'Reviewcentre.com',
        linkUrl: 'https://www.reviewcentre.com/reviews264344.html',
      },
      {
        icon: <Screen stroke="white" />,
        text: `Rated ${
          trustpilotReviews.score?.trustScore
        } out of 5 from over ${trustpilotReviews.numberOfReviews?.total.toLocaleString()} customer reviews`,
        link: 'Trustpilot.com',
        linkUrl: 'https://uk.trustpilot.com/review/www.policyexpert.co.uk',
      },
    ],
  };

  const quidcoImageWithTextData: ImageWithTextRevealType = {
    imageLeft: true,
    bgColor: 'bg-pxMint',
    imgName: 'review_image_garden.jpg',
    imageFile: Quidco42ImageQuery.ReviewGarden.gatsbyImageData,
    headerText: 'You first',
    body: (
      <>
        <p>At Policy Expert we put you, the customer, first.</p>
        <p>
          Don't just take our word for it,{' '}
          <span className="font-medium font-campton">
            we’re trusted by over 1 million customers!
          </span>{' '}
        </p>
        <p className="font-medium font-campton">
          Join us today and get{' '}
          <span className="text-pxPurple">£42 cashback.</span>
        </p>
      </>
    ),
    btn: {
      label: 'Get a quote',
      url: 'https://insurance.policyexpert.co.uk/home',
      primary: true,
    },
  };

  return (
    <>
      <CampaignHeaderBanner headerImageName="quidco" />
      <TwoColumnTextTable data={twoColumnTextTableData} />
      {trustpilotReviews.score && trustpilotReviews.numberOfReviews ? (
        <TwoColumnIconText data={twoColumnIconTextData} />
      ) : null}
      <ImageWithTextReveal
        data={quidcoImageWithTextData}
        hideShowMoreWithImgBottomOnMobile
      />
      <ReviewCarousel cards={InsuranceReviews} />
    </>
  );
};

export default Quidco42Page;

export const Head: HeadFC = () => (
  <>
    <title>Home insurance for people like you</title>
    <meta
      name="description"
      content="Award winning home insurance from Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
