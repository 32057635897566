import React from 'react';

import ShapeX, { ShapePosition } from '../ShapeX';

type TextHeaderData = {
  title: string;
  description: string;
};

type TextHeaderBannerProps = {
  backgroundColor: string;
  shapePosition?: ShapePosition;
  data: TextHeaderData;
};

const TextHeaderBanner = ({
  backgroundColor,
  shapePosition,
  data,
}: TextHeaderBannerProps) => (
  <div className={`${backgroundColor} relative overflow-hidden`}>
    <div className="impact-text-header">
      <h1>{data.title}</h1>
      <p>{data.description}</p>
    </div>
    {shapePosition && <ShapeX position={shapePosition} />}
  </div>
);
export default TextHeaderBanner;
