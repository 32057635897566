import React, { useContext } from 'react';

import { OpeningHoursContext } from '../../context/OpeningHoursProvider';
import { PhoneNumbersContext } from '../../context/PhoneNumbersProvider';
import formatPhoneNumber from '../../helpers/formatPhoneNumber';
import { Section } from '../../pages/contact';

type Props = {
  section?: Section;
};

const TalkToUs = ({ section }: Props) => {
  const phoneNumbers = useContext(PhoneNumbersContext);
  const openingHours = useContext(OpeningHoursContext);
  const weeksOpeningHours =
    section === 'home' || section === 'pet'
      ? openingHours.weekOpeningHours.home
      : openingHours.weekOpeningHours.motor;

  return (
    <>
      <div
        className={`pt-40px flex flex-col relative z-40 pb-40px ${
          section === 'home' && 'border-b'
        }`}
      >
        <p className="mb-0 pb-15px lg:pb-20px font-camptonMedium text-fontSize-25 lg:text-fontSize-30">
          Give us a ring
        </p>
        <a
          className="block text-fontSize-25 font-camptonSemiBold pb-20px lg:pb-25px"
          href={
            section === 'home'
              ? `tel:${formatPhoneNumber(phoneNumbers.main)}`
              : section === 'car'
              ? `tel:${formatPhoneNumber(phoneNumbers.motor?.mainMenu)}`
              : `tel:${formatPhoneNumber(phoneNumbers.pet?.mainMenu)}`
          }
        >
          {section === 'home'
            ? `${phoneNumbers.main}`
            : section === 'car'
            ? `${phoneNumbers.motor?.mainMenu}`
            : `${phoneNumbers.pet?.mainMenu}`}
        </a>
        {weeksOpeningHours?.map(({ day, openingHours }) => (
          <div
            key={day}
            className="flex flex-row justify-between pb-10px lg:pb-15px"
          >
            <p className="text-fontSize-16 lg:fontSize-18 mb-0">{day}</p>
            <p className="text-fontSize-16 lg:fontSize-18 mb-0">
              {openingHours}
            </p>
          </div>
        ))}
      </div>
      {section === 'home' && (
        <div className="pt-40px flex flex-col relative z-40">
          <p className="mb-0 pb-15px lg:pb-20px font-camptonMedium text-fontSize-25 lg:text-fontSize-30">
            SMS text
          </p>
          <a
            className="block text-fontSize-25 font-camptonSemiBold pb-10px"
            href={'sms:07492885130'}
          >
            07492 885130
          </a>
          <p className="text-fontSize-16 lg:text-fontSize-18 mb-0">
            We aim to reply to all text messages within 24hrs
          </p>
          <p className="text-fontSize-14 lg:text-fontSize-16 mb-0 pt-15px">
            (Please include your policy number)
          </p>
        </div>
      )}
    </>
  );
};

export default TalkToUs;
