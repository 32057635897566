import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import MessageHeaderBanner from '../components/messageHeaderBanner/MessageHeaderBanner';
import TwoColumnTextBanner from '../components/twoColumnTextBanner/TwoColumnTextBanner';
import config from '../config';
import useDisclaimer from '../hooks/useDisclaimer';
import { colors } from '../styles/sharedStyled';

const headerData = {
  title: 'Sorry!',
  message: 'Our website is being updated',
};

const MaintenancePage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const twoColumnTextBannerData = {
    bgColor: 'bg-pxGrey',
    textColor: 'black',
    headerText: `We'll be back soon`,
    subText: [
      `We're doing a bit of work on our website at the moment. Thanks for your patience whilst we finish the job.`,
      `You can always take a look at our help centre - otherwise, we'll be back up and running again soon.`,
    ],
    btns: [
      {
        url: config.HELP_CENTRE_URL,
        label: 'Help centre',
      },
    ],
  };

  const height = {
    mobile: '300px',
    tablet: '340px',
    desktop: '440px',
  };

  const marginTop = {
    mobile: '44px',
    tablet: '75px',
    desktop: '100px',
  };

  return (
    <>
      <MessageHeaderBanner
        backgroundColor={colors.purple7}
        height={height}
        marginTop={marginTop}
        shapeXPosition="bottom-left-header-long-mobile"
        data={headerData}
      />
      <TwoColumnTextBanner data={twoColumnTextBannerData} shape={false} />
    </>
  );
};

export default MaintenancePage;

export const Head: HeadFC = () => (
  <>
    <title>Maintenance | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
