import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
} from '../../styles/sharedStyled';

export const PolicyExpertPetsBackgroundContainer = styled.div`
  width: 100vw;
  margin: 0 auto;
  position: relative;
  text-align: center;
  background-color: ${colors.gray3};
  overflow: hidden;
  margin-bottom: -34px;
  padding: 60px 24px 95px;

  @media (min-width: 768px) {
    margin-bottom: -79px;
    padding: 100px 40px 180px;
  }

  @media (min-width: 1024px) {
    padding: 130px 0 210px;
  }
`;

export const PolicyExpertPetsInformationWrapper = styled.div`
  margin: 0 auto;

  @media (min-width: 1024px) {
    max-width: 1390px;
  }
`;

export const PolicyExpertPetsTitle = styled.h2`
  font-size: 30px;
  font-family: ${fontFamilyCamptonBook};
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0 auto 30px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
    margin-bottom: 50px;
  }
`;

export const PolicyExpertPetsHeaderTextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PolicyExpertPetsHeaderText = styled.p`
  font-size: 18px;
  font-family: ${fontFamilyCamptonBook};
  margin-bottom: 30px;
  line-height: normal;
  max-width: 1100px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    margin-bottom: 50px;
  }
`;

export const IconBannerText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  margin-bottom: 0;
  font-size: 18px;
  line-height: normal;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const PetPicturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 40px;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-top: 30px;
  }

  @media (min-width: 1024px) {
    gap: 60px;
    padding-top: 40px;
    width: auto;
  }
`;

export const PetPictureInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
`;

export const PetPictureHeader = styled.h3`
  font-size: 30px;
  font-family: ${fontFamilyCamptonMedium};
  margin: 55px 0 5px;
  padding: 0;
  line-height: normal;

  @media (min-width: 768px) {
    margin-top: 30px;
    font-size: 35px;
  }
`;
export const PetPictureSubHeader = styled.p<{ $maxWidth?: boolean }>`
  font-size: 18px;
  font-family: ${fontFamilyCamptonMedium};
  color: ${colors.purple7};
  margin-bottom: 15px;
  line-height: normal;

  @media (min-width: 768px) {
    padding: 0 30px;
    max-width: ${({ $maxWidth }) => ($maxWidth ? '195px' : 'none')};
  }

  @media (min-width: 1024px) {
    padding: 0;
    max-width: none;
  }
`;

export const PetPictureText = styled.p`
  font-size: 16px;
  font-family: ${fontFamilyCamptonBook};
  line-height: normal;
  margin: 0;

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

export const ImageContainer = styled.div`
  height: 201px;
  width: 290px;

  @media (min-width: 768px) {
    height: 174px;
    width: 224px;
  }

  @media (min-width: 1024px) {
    max-height: 271px;
    max-width: 350px;
    height: 100%;
    width: 100%;
  }
`;
