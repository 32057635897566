import React, { ReactNode } from 'react';

import TickAngles from '../../../static/svgIcons/TickAngles.svg';
import XTable from '../../../static/svgIcons/XTable.svg';
import Icon from '../icons/Icon';
import { ProductTableDataType } from '../productTable/ProductTable';

type Props = {
  data: ProductTableDataType;
  column: number;
  img?: ReactNode;
};

const ProductCard = ({ data, column, img }: Props) => (
  <div className="bg-white h-full max-w-60 rounded-radius-20 flex flex-col">
    {img && (
      <div className="py-20px md:py-35px">
        <Icon className="mx-auto h-70px w-89px">{img}</Icon>
      </div>
    )}

    {data.productRows.map((row) => (
      <div
        key={row.label}
        className={`flex flex-row w-full justify-between items-center border-pxGrey border-t ${
          row.label.length === 0 ? 'col-subheader' : ''
        }`}
      >
        <div className="w-1/2 h-full flex flex-col justify-center md:items-start border-r border-pxGrey py-15px px-20px md:p-30px md:pr-0">
          <p className="font-campton text-fontSize-15 mb-0">{row.label}</p>
          {row.subLabel && (
            <p className="text-fontSize-13 pt-1 mb-0">{row.subLabel}</p>
          )}
        </div>
        <div className=" w-1/2 flex items-center justify-center">
          {row.included[column] === '✔' || row.included[column] === '✖' ? (
            row.included[column] === '✔' ? (
              <Icon type="secondary" size={3} className="mx-auto">
                <TickAngles />
              </Icon>
            ) : (
              <Icon type="secondary" size={3} className="mx-auto">
                <XTable />
              </Icon>
            )
          ) : (
            <p className="font-campton  text-center text-fontSize-15 text-pxPurple mb-0">
              {row.included[column]}
            </p>
          )}
        </div>
      </div>
    ))}
  </div>
);

export default ProductCard;
