import React from 'react';

import {
  ChartIcon,
  PolicyDocumentsButton,
  PolicyDocumentsContainer,
  PolicyDocumentsHeaderText,
  PolicyDocumentsHeaderTitle,
} from './ProductBannerPolicyDocuments.style';

export type ProductBannerPolicyDocumentsDataType = {
  headerTitle: string;
  headerText: string;
  button: {
    label: string;
    url: string;
  };
  v2Refresh?: boolean;
};

const ProductBannerPolicyDocuments = ({
  headerTitle,
  headerText,
  button,
  v2Refresh,
}: ProductBannerPolicyDocumentsDataType) => (
  <PolicyDocumentsContainer $v2Refresh={v2Refresh}>
    {v2Refresh && <ChartIcon />}
    <PolicyDocumentsHeaderTitle $v2Refresh={v2Refresh}>
      {headerTitle}
    </PolicyDocumentsHeaderTitle>
    <PolicyDocumentsHeaderText $v2Refresh={v2Refresh}>
      {headerText}
    </PolicyDocumentsHeaderText>
    <PolicyDocumentsButton key={button.label} to={button.url}>
      {button.label}
    </PolicyDocumentsButton>
  </PolicyDocumentsContainer>
);

export default ProductBannerPolicyDocuments;
