import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { PolicyDataType } from '../../pages/motor-docs';
import Shape from '../Shape';
import {
  Arrow,
  Button,
  ButtonContentContainer,
  ButtonIconContainer,
  ButtonsGridContainer,
  ButtonText,
  CarPolicyContainer,
  IpidLink,
  IpidList,
  IpidListContainer,
  IpidListItem,
  IpidTitle,
  OptionalExtrasSubHeader,
  OptionalExtrasTitle,
} from './CarPolicy.style';

interface CarPolicyProps {
  data: PolicyDataType;
}

const CarPolicy = ({ data }: CarPolicyProps) => {
  const { isDesktop } = useBreakpoint();

  return (
    <CarPolicyContainer>
      <OptionalExtrasTitle>
        Optional extras policy documents
      </OptionalExtrasTitle>
      <OptionalExtrasSubHeader>
        Please select your product
      </OptionalExtrasSubHeader>
      <ButtonsGridContainer>
        {data.wordingLinks.map(({ url, text, icon }) => (
          <Button key={url} to={url} $hasText={text}>
            <ButtonContentContainer $hasText={text}>
              <ButtonIconContainer $hasText={text}>
                {icon.map((icon) => icon)}
              </ButtonIconContainer>
              <ButtonText>{text}</ButtonText>
            </ButtonContentContainer>
          </Button>
        ))}
      </ButtonsGridContainer>
      <IpidListContainer>
        <IpidTitle>Insurance product information document</IpidTitle>
        <IpidList>
          {data.products.map((item) => (
            <IpidLink key={item.title} to={item.link}>
              <Arrow alt=">" />
              <IpidListItem>{item.title}</IpidListItem>
            </IpidLink>
          ))}
        </IpidList>
      </IpidListContainer>
      {isDesktop && <Shape position="right-policy-books" />}
      {isDesktop && <Shape position="left-policy-books" />}
    </CarPolicyContainer>
  );
};

export default CarPolicy;
