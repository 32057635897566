import React, { useState } from 'react';

import { navigate } from 'gatsby';

import { Section } from '../../pages/contact';
import ContactMobileTable from './ContactMobileTable';
import { QuestionsTabProps } from './HomeTabs';

export type ContactTableData = {
  title: string;
  content:
    | (() => React.JSX.Element)
    | (({ section }: QuestionsTabProps) => React.JSX.Element);
};

interface ContactTableProps {
  data: ContactTableData[];
  section: Section;
}

const ContactTable = ({ data, section }: ContactTableProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div
      className={
        'bg-pxPurple px-24px md:px-40px pb-95px md:pb-120px lg:pb-210px pt-60px md:pt-40px lg:pt-130px relative overflow-hidden z-100'
      }
    >
      <div
        className="w-full max-w-1200 hidden lg:block bg-pxWhite rounded-radius-20 overflow-hidden m-auto"
        data-testid="contact-table"
      >
        <div className="flex flex-row bg-pxGrey rounded-t-radius-20 relative z-10 overflow-y-hidden">
          {data.map((tabtop, index) => (
            <div
              className={`py-30px xl:px-4 flex-1 flex justify-center flex-col cursor-pointer
                  ${
                    selectedTab == index
                      ? 'bg-pxWhite rounded-t-radius-20 shadow-left-right'
                      : 'border-0 border-r'
                  }`}
              key={tabtop.title}
              id={`${index}`}
              onClick={() => setSelectedTab(index)}
              tabIndex={index}
              role="button"
            >
              <p
                className={`text-fontSize-20 w-full mb-0 text-center 
                    ${
                      selectedTab == index
                        ? 'font-camptonSemiBold text-pxPurple'
                        : 'text-pxPink font-campton'
                    }`}
              >
                {tabtop.title}
              </p>
            </div>
          ))}
          <div
            className={
              'py-30px xl:px-4 flex-1 flex justify-center flex-col cursor-pointer border-0 border-r'
            }
            onClick={() => navigate('/claims')}
            role="button"
            tabIndex={0}
          >
            <p
              className={
                'text-fontSize-20 font-campton w-full mb-0 text-center text-pxPink'
              }
            >
              Make a claim
            </p>
          </div>
        </div>
        <div className="relative z-20">
          {data[selectedTab].content({ section })}
        </div>
      </div>
      <ContactMobileTable data={data} section={section} />
    </div>
  );
};

export default ContactTable;
