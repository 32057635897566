import React, { useContext } from 'react';

import TrustPilot from '../../../static/svgIcons/TrustPilot.svg';
import TrustPilotPurple from '../../../static/svgIcons/TrustPilotPurple.svg';
import { ReviewsContext } from '../../context/ReviewsProvider';
import { ButtonType } from '../../types/button';
import Shape from '../Shape';
import ShapeX from '../ShapeX';

type Props = {
  data: SectionHeadingBannerDataType;
  bgColor?: string;
  shapeX?: boolean;
  hideShape?: boolean;
};

export type SectionHeadingBannerDataType = {
  headerText: string;
  subText: string;
  trustpilot?: boolean;
  icon?: string;
  purple?: boolean;
  buttons?: ButtonType[];
};

const SectionHeadingBanner = ({ data, bgColor, shapeX, hideShape }: Props) => {
  const trustpilotReviews = useContext(ReviewsContext);
  const isHighTrustScore =
    parseFloat(trustpilotReviews.score?.trustScore) >= 4.8;

  return (
    <div
      className={`
    bg-${bgColor ? bgColor : 'pxGrey'} 
    ${hideShape ? 'pt-80px pb-60px md:py-130px' : 'py-82px md:py-130px'}
    bg-none relative overflow-hidden`}
    >
      <div className="padding-x flex static flex-col md:flex-row relative z-30">
        <div className={`${hideShape ? '' : 'lg:w-1/2 xl:w-2/3'}`}>
          <h2
            className={`mb-32px md:mb-32px ${
              hideShape ? 'text-blackBannerTitle md:text-sectionHeading' : ''
            }`}
          >
            {data.headerText}{' '}
          </h2>

          <p
            className={` ${
              hideShape
                ? 'text-bannerCopy md:text-accordionSubHeading mt-25px md:mt-60px mb-0'
                : 'text-small my-32px'
            }`}
          >
            {data.subText}
          </p>

          {data.trustpilot ? (
            <div className="w-130px">
              {data.purple ? (
                <TrustPilotPurple
                  className="icons-primary5 z-50 h-auto"
                  fill={isHighTrustScore ? '#6214be' : '#dcdce6'}
                />
              ) : (
                <TrustPilot
                  className="icons-primary5 z-50 h-auto"
                  fill={isHighTrustScore ? '#00b67a' : '#dcdce6'}
                />
              )}
            </div>
          ) : null}

          {data.buttons &&
            data.buttons.map((btn: any) => {
              return (
                <a
                  key={btn.label}
                  href={btn.url}
                  className={`btn-${
                    btn.smallestSize || 'md'
                  } lg:btn-lg lg:mr-32px mb-12px mt-32px md:mt-82px
              btn-${btn.primary ? 'primary' : 'secondary'}`}
                  target={btn.target ? btn.target : '_self'}
                >
                  {btn.label}
                </a>
              );
            })}
        </div>
      </div>
      {hideShape ? null : (
        <>
          {shapeX ? (
            <div className="hidden lg:block">
              <ShapeX position="bottom-right" />
            </div>
          ) : (
            <div className="hidden lg:block">
              <Shape position="right-near" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SectionHeadingBanner;
