import React from 'react';

import { Link } from 'gatsby';

import {
  CardContainer,
  CardIconContainer,
  CardLinks,
  CardLinksContainer,
  CardTitle,
  ComingSoonSvg,
} from './InsuranceCardImpactV2.style';

export type InsuranceCardImpactV2Data = {
  heading: string;
  testId: string;
  icon: React.ReactNode;
  ctaHeading: string;
  ctaUrl: string;
  link1text: string;
  link1Url: string;
  link2text?: string;
  link2Url?: string;
  isComingSoon?: boolean;
};

type Props = { data: InsuranceCardImpactV2Data };

const InsuranceCardImpactV2 = ({ data }: Props) => (
  <CardContainer data-testid={`${data.testId}-insurance-card`}>
    {data.isComingSoon && <ComingSoonSvg data-testid="card-coming-soon-svg" />}
    <CardIconContainer data-testid="card-icon">{data.icon}</CardIconContainer>
    <CardTitle data-testid="card-title">{data.heading}</CardTitle>
    <Link
      to={data.ctaUrl}
      className="btn-primary btn-cta md:mx-auto mb-15px relative z-10 font-camptonSemiBold"
      data-testid={`${data.testId}-insurance-button`}
    >
      {data.ctaHeading}
    </Link>
    <CardLinksContainer>
      <CardLinks data-testid="card-link-1" to={data.link1Url} $isFirst>
        {data.link1text}
      </CardLinks>
      {data.link2text && data.link2Url && (
        <CardLinks data-testid="card-link-2" to={data.link2Url}>
          {data.link2text}
        </CardLinks>
      )}
    </CardLinksContainer>
  </CardContainer>
);

export default InsuranceCardImpactV2;
