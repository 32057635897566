import React, { useEffect, useState } from 'react';

import { Link } from '@reach/router';

import { useJobs } from '../../hooks/useJobs';
import Shape from '../Shape';
import JobCard from './JobCard';

function Jobs() {
  const [selectedCareers, setSelectedCareers] = useState<null | any[]>(null);

  const { isCareersLoading, miltonKeynesCareers } = useJobs();

  useEffect(() => {
    if (isCareersLoading === true) {
      return;
    }
    setSelectedCareers(miltonKeynesCareers);
  }, [isCareersLoading, miltonKeynesCareers]);

  return (
    <div className="bg-pxCharcoal py-82px md:py-130px relative overflow-hidden ">
      <div className="padding-x">
        <div className="grid grid-cols-1  gap-x-10 md:grid-cols-2 ">
          <div className="w-full max-w-lg relative">
            <h1 className="pb-32px md:pb-82px text-white">
              Jobs in Milton Keynes
            </h1>
            <p className="text-white">
              Build a rewarding career in home insurance
            </p>
            <div className="hidden lg:block">
              <Shape position="left-jobs-MK" />
            </div>
          </div>
          <div className="w-full">
            {selectedCareers !== null ? (
              selectedCareers.map((item: any) => (
                <JobCard
                  title={item.title}
                  location={item.location}
                  department={item.department}
                  closing_date={item.closing_date}
                  status={item.status}
                  key={item.title}
                ></JobCard>
              ))
            ) : (
              <></>
            )}
            <div className="flex flex-row items-center">
              <Link
                to="/about-us/careers/jobs/"
                className="btn-sm btn-secondary text-bodyCopy"
              >
                View Jobs
              </Link>
              <p className="text-white mb-0 text-bodyCopy ml-3">
                across all our offices
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jobs;
