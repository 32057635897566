import React from 'react';

type Props = { children: React.ReactNode };

const RenewalVideoHeader: React.FC<Props> = ({ children }: Props) => {
  return (
    <div className="bg-pxPurple max-h-780 lg:h-780 px-20px md:px-40px lg:px-0 pt-60px md:pt-100px lg:pt-130px pb-100px md:pb-250px lg:pb-0 mb-35-minus md:mb-80-minus">
      <div className="max-w-800 mx-auto">
        <div className="relative overflow-hidden w-full pt-56.25">
          {children}
        </div>
      </div>
    </div>
  );
};

export default RenewalVideoHeader;
