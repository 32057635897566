import React, { useContext } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import plus from '../../../static/icons/home-emergency-comparison-plus.png';
import { PhoneNumbersContext } from '../../context/PhoneNumbersProvider';
import formatPhoneNumber from '../../helpers/formatPhoneNumber';
import useDisclaimer from '../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../types/breakpoints';
import HomeEmergencyAccordion, {
  HomeEmergencyAccordionDataType,
} from '../accordion/HomeEmergencyAccordion';
import HeaderBanner from '../headerBanner/HeaderBanner';
import TwoColumnTextBanner, {
  TwoColumnTextBannerType,
} from '../twoColumnTextBanner/TwoColumnTextBanner';
import TwoColumnTextImage from '../twoColumnTextImage/TwoColumnTextImage';
import { CoverLink } from './home-emergency-comparison.style';
import {
  CoverList,
  CoverListItem,
  CoverText,
} from './old-home-emergency-comparison.style';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../images/home_emergency_comparison_sm.png`}
        alt={`Home emergency information`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../images/home_emergency_comparison_md.png`}
        alt={`Home emergency information`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../images/home_emergency_comparison_lg.png`}
      alt={`Home emergency information`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const twoColumnTextBannerData: TwoColumnTextBannerType = {
  headerText: 'What is it?',
  subText: [
    'Home Emergency cover is there for sudden emergencies in your home. ',
    'With just one call, we can send a qualified tradesperson out to help you. We’ve got your back whether your boiler’s broken, your roof is damaged or you’ve got plumbing problems.',
  ],
};

const accordionData: HomeEmergencyAccordionDataType = {
  selectTitle: 'Up to £300 per callout. Maximum 2 callouts per year.',
  selectImage: plus,
  selectImageAlt: 'Policy Expert select plan',
  plusTitle: 'Up to £1,000 per callout. Unlimited callouts per year.',
  plusImage: plus,
  plusImageAlt: 'Policy Expert plus plan',
  items: [
    {
      title: 'Boiler',
      includedIn: ['select', 'plus'],
      whatsCovered: (
        <>
          <CoverText>
            You are covered if your boiler suffers complete failure or
            breakdown, resulting in no hot water and/or heating. This includes a
            water leak from your boiler, or if your boiler doesn’t produce any
            hot water or heating, or doesn’t switch on.
          </CoverText>
          <CoverText>
            With home emergency cover, a contractor will come to your home and
            attempt to complete a temporary repair of your boiler if possible.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>
            Non-domestic heating systems or boilers with an output of over
            60kW/hr.
          </CoverListItem>
          <CoverListItem>Boilers over 15 years old.</CoverListItem>
          <CoverListItem>
            Lighting boilers, operating them correctly, or adjusting the time or
            temperature controls, or replacement controls where the boiler can
            be safely operated manually.
          </CoverListItem>
          <CoverListItem>
            Adjusting the water pressure that is not caused by a fault in your
            boiler.
          </CoverListItem>
          <CoverListItem>
            Descaling and any work (including power flushing) that is needed as
            a result of hard-water deposits or damage caused by hard water or
            sludge from corrosion.
          </CoverListItem>
          <CoverListItem>
            Bleeding or clearing the airlocks from any radiators.
          </CoverListItem>
          <CoverListItem>Repairing heated towel rails.</CoverListItem>
          <CoverListItem>
            Repairing any appliance that is not designed for heating (for
            example, a cooker).
          </CoverListItem>
          <CoverListItem>
            Any boiler or system that is not serviced by an authorised
            tradesperson every 15 months or more often if the manufacturer
            recommends this (we will need proof of the service before we carry
            out any work).
          </CoverListItem>
          <CoverListItem>
            Repairing any boiler that we assess to be uneconomical.
          </CoverListItem>
          <CoverListItem>
            Any loss or damage caused as a result of lack of fuel.
          </CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'Plumbing',
      includedIn: ['select', 'plus'],
      whatsCovered: (
        <>
          <CoverText>
            You are covered if you experience blocked or leaking pipes or joints
            in your home which is causing an emergency.
          </CoverText>
          <CoverText>
            You are also covered for a blocked or leaking water storage tank if
            it’s causing an emergency in your home.
          </CoverText>
          <CoverText>
            Our contractor will visit your home and attempt to complete a
            temporary repair, if possible, such as repairing a leaking pipe or
            joint, clearing a blocked sink or isolating a leaking hot water
            cylinder, water storage tank or radiator.
          </CoverText>
          <CoverText>
            If our contractor cannot repair a leak, for example from a radiator,
            they may be able to isolate it so you can turn your heating back on.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>Repairs to any outside pipes and taps.</CoverListItem>
          <CoverListItem>
            Repairs to overflows which are not causing water damage inside your
            home.
          </CoverListItem>
          <CoverListItem>
            Cleaning or descaling and any work arising from deposits of
            hard-water scale.
          </CoverListItem>
          <CoverListItem>
            Any dripping or leaking taps, nozzles or any other parts of the
            plumbing system where water is safely escaping down a drain.
          </CoverListItem>
          <CoverListItem>
            Replacement of radiators (but our contractor may be able to isolate
            the radiator to prevent the leak).
          </CoverListItem>
          <CoverListItem>Failure of waste-disposal units.</CoverListItem>
          <CoverListItem>
            Frozen pipes which have not caused any permanent damage.
          </CoverListItem>
          <CoverListItem>
            Water leaking from swimming pools or hot tubs.
          </CoverListItem>
          <CoverListItem>
            Repairs to domestic appliances that are leaking water, including
            burst or leaking flexible hoses.
          </CoverListItem>
          <CoverListItem>
            Any pipework leading to a Saniflo or similar system.
          </CoverListItem>
          <CoverListItem>
            ‘Trace and Access’ – Home Emergency doesn’t cover invasive
            exploratory work, like digging up or removing your floor or
            panelling to find the source of an emergency. But, trace and access
            may be included in your main home insurance policy.
          </CoverListItem>
          <CoverListItem>
            Your garage and outbuildings aren’t covered with home emergency
            cover, so blocked or leaking pipes in these buildings won’t be
            covered. However, they may be included in your main home insurance
            policy.
          </CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'Toilets',
      includedIn: ['select', 'plus'],
      whatsCovered: (
        <>
          <CoverText>
            You are covered if an issue with your toilet creates an emergency.
            For example, if the only toilet you can access in your home has
            damage to the flushing mechanism or a blockage, or if any toilet in
            your home is overflowing, creating a risk to health.
          </CoverText>
          <CoverText>
            Our contractors will come and help contain the emergency by making
            repairs to a blocked or leaking toilet, or to a toilet which isn’t
            flushing.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>
            If you have another toilet in your home that you are able to use,
            and the issue is not causing a risk to health, it isn’t an emergency
            and so isn’t included under our home emergency cover.
          </CoverListItem>
          <CoverListItem>
            Blocked toilets if the blockage has been caused by deliberate
            misuse, such as flushing baby wipes down the toilet.
          </CoverListItem>
          <CoverListItem>Saniflo toilets or similar systems.</CoverListItem>
          <CoverListItem>The cost of replacement ceramics.</CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'Drainage',
      includedIn: ['select', 'plus'],
      whatsCovered: (
        <>
          <CoverText>
            We can help if you have an emergency caused by blocked waste pipes
            and/or external drains on your property which can be cleared by
            rodding or jetting.
          </CoverText>
          <CoverText>
            Where possible, our contractors will be able to help you through
            rodding (where a cable is inserted into the pipe) or jetting (where
            the water is flushed through the pipe).
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>
            Public sewers, drains and pipes which are maintained by local
            utility companies or under a service agreement.
          </CoverListItem>
          <CoverListItem>Soakaways, guttering and downpipes.</CoverListItem>
          <CoverListItem>
            Damage to drains caused by structures which don’t meet local
            building regulations.
          </CoverListItem>
          <CoverListItem>
            Frozen pipes that haven’t caused a permanent blockage.
          </CoverListItem>
          <CoverListItem>
            The cleaning or descaling of your drains or any work arising from
            deposits of hard-water scale.
          </CoverListItem>
          <CoverListItem>
            Clearing drains if the issue was caused by misuse, such as flushing
            baby wipes down the toilet or putting grease or cooking oil down the
            drain.
          </CoverListItem>
          <CoverListItem>
            Any pipework leading to a Saniflo or similar system.
          </CoverListItem>
          <CoverListItem>
            Any drains which allow water to escape safely, however slowly.
          </CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'Security',
      includedIn: ['select', 'plus'],
      whatsCovered: (
        <>
          <CoverText>
            Home Emergency will cover damage to external doors or windows which
            stops you from securing your home. For example, broken or cracked
            external windows, damage to an external door or lock damage or
            failure on external doors or windows.
          </CoverText>
          <CoverText>
            The temporary repairs we will do to contain the emergency may
            include boarding up a broken window or door or providing a temporary
            lock and single set of keys if there isn’t any other way to make
            your home secure.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>
            The security cover doesn’t include locks on windows which can’t be
            accessed, like windows on the 2nd floor or above.
          </CoverListItem>
          <CoverListItem>
            Any damage caused deliberately by you or your family.
          </CoverListItem>
          <CoverListItem>
            Double glazing where one pane has been broken, but the other is
            intact.
          </CoverListItem>
          <CoverListItem>
            Fences, gates, outbuildings and detached garages.
          </CoverListItem>
          <CoverListItem>
            Garage doors (we will secure the door between your garage and the
            main building).
          </CoverListItem>
          <CoverListItem>Lost keys where your home is secure.</CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'Pest infestation',
      includedIn: ['plus'],
      whatsCovered: (
        <>
          <CoverText>
            We’ll help you with an infestation in your home which stops you from
            using one or more rooms in your home. We can help with wasps,
            hornets, mice, rats and cockroaches.
          </CoverText>
          <CoverText>
            Our contractor will determine the best way to remove your pest
            infestation, which may include poison or bait.
          </CoverText>
          <CoverText>
            If you have a pest infestation in your home that we don’t cover,
            please check to see if your local council provides more information
            to help control that specific pest:{' '}
            <CoverLink
              href={'https://www.gov.uk/report-pest-problem'}
              target="_blank"
            >
              https://www.gov.uk/report-pest-problem
            </CoverLink>
          </CoverText>
          <CoverText>
            Please note, we will only pay for two incidents in any period of
            insurance.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>
            Pests in outbuildings and detached garages.
          </CoverListItem>
          <CoverListItem>
            Repeat claims where you have failed to follow previous guidance from
            us or the contractor designed to prevent continued or further
            infestation.
          </CoverListItem>
          <CoverListItem>
            Removal of the nest itself if not required to remove the pest.
          </CoverListItem>
          <CoverListItem>Damage caused by the pests.</CoverListItem>
          <CoverListItem>
            Permanent proofing works to the property to prevent further
            infestation.
          </CoverListItem>
          <CoverListItem>
            Pest infestation by any pests not listed above.
          </CoverListItem>
          <CoverListItem>
            Bees: Please note that bees aren’t usually seen as pests – if you
            have a swarm of bees, visit the British Beekeepers Association’s
            website:{' '}
            <CoverLink href={'https://www.bbka.org.uk/'} target="_blank">
              https://www.bbka.org.uk/
            </CoverLink>
            .
          </CoverListItem>
          <CoverListItem>
            Bats: If you have bats, please note that they are a protected
            species in the UK. You will need to contact the Bat Conservation
            Trust (more information can be found at{' '}
            <CoverLink href={'https://www.bats.org.uk/'} target="_blank">
              https://www.bats.org.uk/
            </CoverLink>
            , or call the National Bat Helpline:{' '}
            <CoverLink href={`tel:${formatPhoneNumber('0345 1300 228')}`}>
              0345 1300 228
            </CoverLink>
            .
          </CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'Electrics',
      includedIn: ['plus'],
      whatsCovered: (
        <>
          <CoverText>
            Home Emergency Plus covers you for an emergency relating to a
            failure of your electricity supply from the electric meter in your
            home to fuse boxes, sockets, switches and light fittings. For
            example, failed wiring to immersion heaters and boilers, failed
            wiring to sockets, switches and light fittings, the breakdown of a
            fuse box or the loss of power to a circuit.
          </CoverText>
          <CoverText>
            When covered, we’ll pay for emergency work to be carried out to
            contain the emergency. For example, by isolating the faulty
            electrics to make the area safe.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>
            The interruption or disconnection of public services to your home
            for whatever reason.
          </CoverListItem>
          <CoverListItem>
            Wiring that’s outside your home. This includes outside lighting and
            electricity supplies to outbuildings or garages.
          </CoverListItem>
          <CoverListItem>
            Replacing light bulbs or fuses in plugs and any other routine tasks
            to maintain items which use electricity.
          </CoverListItem>
          <CoverListItem>
            Failure of the electricity supply to burglar or fire alarms, CCTV
            systems or garage doors.
          </CoverListItem>
          <CoverListItem>
            Breakdown, loss, damage or repairs to domestic appliances, like
            washing machines, kettles, televisions.
          </CoverListItem>
          <CoverListItem>Wiring or electrics in shared areas.</CoverListItem>
          <CoverListItem>
            The plumbing and filtration system for swimming pools.
          </CoverListItem>
          <CoverListItem>Solar-powered electricity systems.</CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'Gas',
      includedIn: ['plus'],
      whatsCovered: (
        <>
          <CoverText>
            We will cover damaged gas supply pipes in your home, once the gas
            supply has been isolated by the National Gas Emergency Service. We
            will repair or replace a damaged gas supply pipe or cooker hose.
          </CoverText>
          <CoverText>
            We aren’t able to restore your gas supply or replace the gas supply
            pipe before the meter. Please contact your gas supplier who can
            arrange this for you.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>The gas supply pipe before the meter.</CoverListItem>
          <CoverListItem>
            The interruption or disconnection of public services to your home
            for whatever reason.
          </CoverListItem>
          <CoverListItem>
            The breakdown, loss, damage or repairs to domestic appliances.
          </CoverListItem>
          <CoverListItem>
            Any pipe that you are not responsible for.
          </CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'Roofing',
      includedIn: ['plus'],
      whatsCovered: (
        <>
          <CoverText>
            We cover storm damage to the roof of your home if it is causing
            damage to (or is likely to cause damage to) the inside of your home
            as a result of water coming into your home.
          </CoverText>
          <CoverText>
            Our contractors will come to try and contain the emergency -
            remember that Home Emergency cover is only there to provide
            temporary repairs. Steps taken may include preventing water from
            getting into your home (by using tarpaulin, for example) or
            preventing any water that enters your home from causing damage (by
            arranging for it to be collected or diverted, for example).
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <>
          <CoverList>
            <CoverListItem>
              Your Home Emergency cover doesn’t cover water leaks caused by poor
              roof maintenance, blocked guttering or wear and tear.
            </CoverListItem>
            <CoverListItem>
              We don’t cover water leaks through flat, felt and glass roofs.
            </CoverListItem>
            <CoverListItem>
              Damage to property or contents as a result of water entering your
              home is not included as part of your Home Emergency cover.
            </CoverListItem>
            <CoverListItem>
              While permanent repairs or replacements aren’t included in your
              Home Emergency cover, it may be provided within your Building and
              Contents insurance in your main home insurance policy.
            </CoverListItem>
          </CoverList>
          <CoverText $marginTop>
            Please note, that when we say storm, we mean a period of violent
            weather defined by
          </CoverText>
          <CoverList>
            <CoverListItem>
              A gale of Force 10 or above (as defined under the internationally
              recognised Beaufort Scale) reaching wind speeds of at least 55
              mph; or
            </CoverListItem>
            <CoverListItem>
              Torrential rain that falls at a rate of at least 25mm per hour; or
            </CoverListItem>
            <CoverListItem>
              Snow that falls to a depth of at least 30cm in 24 hours; or
            </CoverListItem>
            <CoverListItem>
              Hail so severe that it causes damage to hard surfaces or breaks
              glass.
            </CoverListItem>
          </CoverList>
          <CoverText $marginTop>
            Please understand that in the event of storms, our contractor will
            only be able to attend once the storm has passed and it is safe to
            carry out works.
          </CoverText>
        </>
      ),
    },
    {
      title: 'Alternative heating contribution',
      includedIn: ['select', 'plus'],
      whatsCovered: (
        <>
          <CoverText>
            If we have accepted a Boiler claim and we can’t get your heating
            working six hours after you reported your claim to us, or after our
            contractor has left your home, we’ll contribute up to £100
            (including VAT) towards the cost of buying or hiring alternative
            heating sources if we consider it necessary based on the
            temperatures in your home.
          </CoverText>
          <CoverText>
            Please note that you must provide an original receipt for the
            alternative heating for us to process this claim. This cover only
            allows for a temporary heat source within your home and not the
            payment towards the utility bill of another home, should you opt to
            stay elsewhere.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>
            We will only pay for one incident of alternative heating in any
            period of insurance.
          </CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'New boiler contribution',
      includedIn: ['plus'],
      whatsCovered: (
        <>
          <CoverText>
            If we have accepted a Boiler claim and we and the contractor believe
            it’s uneconomical to repair your boiler, we’ll contribute towards
            the cost of a brand new, replacement boiler.
          </CoverText>
          <CoverText $marginTop>
            The amount we will pay depends on the age of the boiler you have
            made a claim on. The most we will pay in any one period of
            insurance, including VAT, is:
          </CoverText>
          <CoverList>
            <CoverListItem>£500 for a boiler aged up to 4 years;</CoverListItem>
            <CoverListItem>£300 for a boiler aged 5-7 years old;</CoverListItem>
            <CoverListItem>
              £200 for a boiler aged 8-10 years old; or
            </CoverListItem>
            <CoverListItem>
              £150 for a boiler aged 11-15 years old.
            </CoverListItem>
          </CoverList>
          <CoverText $marginTop>
            We must approve this before you make a claim. You can claim our
            contribution by sending us a copy of your receipt for the new boiler
            within 90 days of the original emergency.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>
            Claims for boilers which are over 15 years old.
          </CoverListItem>
          <CoverListItem>
            Any claim for boiler replacement contribution that is more than 90
            days after the original emergency.
          </CoverListItem>
        </CoverList>
      ),
    },
    {
      title: 'Overnight accommodation',
      includedIn: ['plus'],
      whatsCovered: (
        <>
          <CoverText>
            If we agree that, because of an emergency, your home is
            uninhabitable, we’ll pay up to £1,000 (including VAT) in any one
            period of insurance for overnight accommodation (on a room only
            basis) for you, your family and any dogs and cats that normally live
            with you.
          </CoverText>
          <CoverText>
            We’ll also cover reasonable travel expenses to and from that
            accommodation. You must provide original receipts showing these
            costs.
          </CoverText>
        </>
      ),
      whatsNotCovered: (
        <CoverList>
          <CoverListItem>
            The cost of overnight accommodation for anyone who is not a member
            of your family and does not permanently live with you in your home.
          </CoverListItem>
          <CoverListItem>
            More than two nights of hotel accommodation.
          </CoverListItem>
        </CoverList>
      ),
    },
  ],
};

const HomeEmergencyComparisonPage: React.FC = () => {
  useDisclaimer(null);
  const phoneNumbers = useContext(PhoneNumbersContext);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const HomeEmergencyComparisonImageQuery = useStaticQuery(graphql`
    query HomeEmergencyComparisonImages {
      HecPhoneLady: imageSharp(
        original: { src: { regex: "/hec_phone_lady/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const questionsData = {
    headerText: 'Still got questions?',
    img: 'hec_phone_lady.jpg',
    imageFile: HomeEmergencyComparisonImageQuery.HecPhoneLady.gatsbyImageData,
    subBody: (
      <p className="text-blackBannerCopyMobile lg:text-blackBannerCopyDesktop font-campton font-light mb-0">
        Talk to one of our experts on{' '}
        <a
          className="font-normal"
          href={`tel:${formatPhoneNumber(phoneNumbers.main)}`}
        >
          {phoneNumbers.main}
        </a>{' '}
        - we'll be happy to help.
      </p>
    ),
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxMint"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        styling="pt-7 pl-7 md:pt-61px md:pl-72px lg:pt-66px lg:px-9 max-w-1107"
      />
      <TwoColumnTextBanner data={twoColumnTextBannerData} shape={false} />
      <HomeEmergencyAccordion data={accordionData} />
      <TwoColumnTextImage
        data={questionsData}
        imgPosition={'left'}
        bgColor={'pxCharcoal'}
        textColor={'pxWhite'}
        shapeX
      />
    </>
  );
};

export default HomeEmergencyComparisonPage;
