import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Carousel from '../carousel/Carousel';
import ProductCard from '../productCard/ProductCard';
import {
  CarouselContainer,
  ColumnHeaderRow,
  ColumnTitle,
  ColumnTitleContainer,
  HeaderAndTableContainer,
  HeaderContainer,
  HeaderText,
  MainContainer,
  MainTable,
  PanelInformationText,
  PanelInformationTitle,
  PanelRowContainer,
  RightPanel,
  RowContentContainer,
  RowContentText,
  RowTitle,
  RowTitleContainer,
  TableBody,
  TableRow,
} from './ComplaintsTable.style';

export type ComplaintsTableDataType = {
  headerText?: string;
  rightPanelInfo?: {
    infoTitle: string;
    info: string;
  }[];
  firstRowDark?: boolean;
  firstColLarge?: boolean;
  columns: number;
  tableTitles: {
    tableTitle: string;
  }[];
  productRows: {
    label: string;
    included: string[];
  }[];
};
interface ProductTableProps {
  data: ComplaintsTableDataType;
  multiTables: boolean;
}

const ComplaintsTable = ({ data, multiTables }: ProductTableProps) => {
  const { isTablet } = useBreakpoint();
  const cards: JSX.Element[] = [];
  // @ts-ignore
  data.productRows[0].included.forEach((item, index) => {
    cards.push(
      <ProductCard
        key={`carousel-`}
        data={data}
        title={data.tableTitles}
        column={index}
      />
    );
  });

  return (
    <MainContainer $multipleTables={multiTables}>
      <HeaderAndTableContainer $multipleTables={multiTables}>
        {data.headerText && (
          <HeaderContainer>
            <HeaderText>{data.headerText}</HeaderText>
            <RightPanel>
              {data.rightPanelInfo
                ? data.rightPanelInfo.map((item) => (
                    <PanelRowContainer key={item.infoTitle || item.info}>
                      {item.infoTitle && (
                        <PanelInformationTitle>
                          {item.infoTitle}
                        </PanelInformationTitle>
                      )}
                      {item.info && (
                        <PanelInformationText>{item.info}</PanelInformationText>
                      )}
                    </PanelRowContainer>
                  ))
                : null}
            </RightPanel>
          </HeaderContainer>
        )}
        {!isTablet && (
          <MainTable data-testid="complaints-table">
            <thead>
              <ColumnHeaderRow
                $numberOfColumns={data.columns}
                $isFirstColumnLarge={!!data.firstColLarge}
              >
                {data.tableTitles.map((item, index) => (
                  <ColumnTitleContainer
                    key={item.tableTitle}
                    $isLargeColumn={!!data.firstColLarge && index === 0}
                  >
                    <ColumnTitle>{item.tableTitle}</ColumnTitle>
                  </ColumnTitleContainer>
                ))}
              </ColumnHeaderRow>
            </thead>

            <TableBody>
              {data.productRows.map((item: any, index) => (
                <TableRow
                  key={item.label}
                  $numberOfColumns={data.columns}
                  $isFirstColumnLarge={!!data.firstColLarge}
                  $isFirstRowDark={!!data.firstRowDark && index === 0}
                >
                  <RowTitleContainer
                    $multipleTables={multiTables}
                    $isFirstColumnLarge={!!data.firstColLarge}
                  >
                    <RowTitle>{item.label}</RowTitle>
                  </RowTitleContainer>

                  {item.included.map((includes: any) => (
                    <RowContentContainer key={includes}>
                      <RowContentText>{includes}</RowContentText>
                    </RowContentContainer>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </MainTable>
        )}
      </HeaderAndTableContainer>

      {isTablet && (
        <CarouselContainer data-testid="carousel-container">
          <Carousel
            slidesPerView={1}
            cards={cards}
            pagination={{ arrows: true, color: 'white' }}
          />
        </CarouselContainer>
      )}
    </MainContainer>
  );
};

export default ComplaintsTable;
