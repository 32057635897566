import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Link } from 'gatsby';

import config from '../../config';
import useQueryParams from '../../hooks/useQueryParams';
import DropShapeCardBlogPost from '../dropShapeCardBlogPost/DropShapeCardBlogPost';
import ShapeX from '../ShapeX';
import RelatedArticles from './RelatedArticles';
import WeatherAlertHeading from './WeatherAlertHeading';

const transformToDate = (date: string) =>
  new Date(date).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
const getUrlImage = (alertId: string) =>
  `https://www.stabilismga.co.uk/flood_alerts/${alertId}.png`;
const getUrlData = (alertId: string) =>
  `https://www.stabilismga.co.uk/flood_alerts/${alertId}.json`;

type WeatherData = {
  publishedDate: string;
  severity: string;
  severityLevel: string;
  description: string;
  message: string;
  url: string;
};

const useAlertData = () => {
  const [alertData, setAlertData] = useState<WeatherData>();
  const { id: alertId } = useQueryParams();

  useEffect(() => {
    async function loadWeatherData() {
      try {
        const response = await axios.get(getUrlData(alertId));
        const {
          description,
          message,
          severity,
          severityLevel,
          timeRaised,
          id_url,
        } = response.data;

        setAlertData({
          severity,
          severityLevel,
          description,
          message,
          publishedDate: transformToDate(timeRaised),
          url: id_url,
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (alertId) {
      loadWeatherData();
    }
  }, [alertId]);

  return {
    alertId,
    alertData,
  };
};

const WeatherAlertPost = ({ data }: any) => {
  const { alertData, alertId } = useAlertData();

  return (
    <>
      {config.WEATHER_FLAG === 'true' ? (
        <div
          className={`bg-pxGrey pt-82px lg:pt-130px relative overflow-hidden`}
        >
          {alertData ? (
            <div className="padding-x">
              <WeatherAlertHeading
                severityLevel={alertData.severityLevel}
                severity={alertData.severity}
              />
              <div className="flex flex-col md:flex-row md:gap-20 mt-32px md:mt-0 pb-32px md:pb-82px">
                <div className="mb-0 md:mb-32px w-full md:w-1/2">
                  <DropShapeCardBlogPost fullWidth className="blogImage">
                    <img
                      src={getUrlImage(alertId)}
                      alt="Alert map"
                      className="w-full h-full"
                    />
                  </DropShapeCardBlogPost>
                </div>
                <div className="w-full md:w-1/2">
                  <div className="pt-32px">
                    <p className="text-small text-pxPurple font-semibold">
                      Date: {alertData.publishedDate}
                    </p>
                  </div>
                  <div>
                    {alertData.description && (
                      <>
                        <div className="mt-32px">
                          <p className="font-semibold text-small">
                            Areas affected
                          </p>
                          <p className="text-small">{alertData.description}</p>
                        </div>
                        <div className="mt-32px">
                          <p className="font-semibold text-small">Updates</p>
                          <p className="text-small">{alertData.message}</p>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <p className="text-sm">
                      The information on this page is source from the
                      Environment Agency.
                      <a
                        href={alertData.url}
                        className="font-semibold"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        Visit their site here.
                      </a>
                    </p>
                  </div>
                  <Link
                    to="/claims"
                    className="w-full btn-md
                    lg:btn-lg lg:mr-32px mb-12px last:mb-0
                    btn-primary"
                  >
                    Make a claim
                  </Link>
                </div>
              </div>
              <RelatedArticles data={data} />
            </div>
          ) : null}
          <ShapeX position="bottom-right" />
        </div>
      ) : null}
    </>
  );
};

export default WeatherAlertPost;
