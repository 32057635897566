import React from 'react';

type Props = {
  left: boolean;
  children: React.ReactNode;
  color: string;
};

const RoundCard = ({ children, left, color }: Props) => (
  <div className={`round-shape-${left ? 'left' : 'right'} bg-${color} p-32px`}>
    {children}
  </div>
);

export default RoundCard;
