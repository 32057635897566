import React, { useEffect, useState } from 'react';

import { navigate } from 'gatsby';

import {
  AccordionContainer,
  AccordionHeader,
  MinusIcon,
  PlusIcon,
} from './Accordion.style';

type Props = {
  title: string;
  body: React.ReactNode;
  marker?: boolean;
  navigateTo?: string;
  handleOnClick?: (index?: number) => void;
  index?: number;
  openIndex?: number;
  isProductPage?: boolean;
};

const Accordion = ({
  title,
  body,
  marker,
  navigateTo,
  handleOnClick,
  index,
  openIndex,
  isProductPage,
}: Props) => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (index !== undefined && index === openIndex) {
      setExpand(true);
      setTimeout(() => {
        const target = document.querySelector(
          `#section-${index}`
        ) as HTMLElement;
        window.scrollTo({
          top: target.getBoundingClientRect().top + window.scrollY,
          behavior: 'smooth',
        });
      }, 150);
    } else {
      setExpand(false);
    }
  }, [openIndex]);

  return (
    <AccordionContainer
      id={`section-${index}`}
      $isExpanded={expand}
      $isProductPage={isProductPage}
      data-testid="accordion"
    >
      <AccordionHeader
        onClick={() => {
          navigateTo
            ? navigate(navigateTo)
            : handleOnClick && handleOnClick(index);
        }}
        $isExpanded={expand}
      >
        {title}
        {marker &&
          (expand ? (
            <MinusIcon data-testid="minus-icon" />
          ) : (
            <PlusIcon data-testid="plus-icon" />
          ))}
      </AccordionHeader>
      {expand && <>{body}</>}
    </AccordionContainer>
  );
};
export default Accordion;
