import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import TextHeaderBanner from '../components/textHeaderBanner/TextHeaderBanner';
import TwoColumnHTMLBanner from '../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../hooks/useDisclaimer';
import {
  DataWrapper,
  SectionHeader,
  SectionText,
  StatementList,
  StatementListText,
} from './modern-slavery-statement.style';

const slaveryBannerData = {
  heading: 'Modern Slavery',
  body: (
    <DataWrapper>
      <SectionHeader>The Policy</SectionHeader>
      <SectionText>
        This policy applies to all persons working for Policy Expert or on our
        behalf in any capacity, including employees at all levels, directors,
        officers, agency workers, seconded workers, volunteers, agents,
        contractors and suppliers.
      </SectionText>
      <SectionText>
        Policy Expert strictly prohibits the use of modern slavery and human
        trafficking in our operations and supply chain. We have and will
        continue to be committed to implementing systems and controls aimed at
        ensuring that modern slavery is not taking place anywhere within our
        organisation or in any of our supply chains. We expect that our
        suppliers will hold their own suppliers to the same high standards.
      </SectionText>
      <SectionHeader>Modern Slavery and Human Trafficking</SectionHeader>
      <SectionText>
        Modern slavery is a term used to encompass slavery, servitude, forced
        and compulsory labour, bonded and child labour and human trafficking.
      </SectionText>
      <SectionText>
        Human trafficking is where a person arranges or facilitates the travel
        of another person with a view to that person being exploited. Modern
        slavery is a crime and a violation of fundamental human rights.
      </SectionText>
      <SectionHeader>Our Commitment</SectionHeader>
      <SectionText>
        We are a company that expects everyone working with us or on our behalf
        to support and uphold the following measures to safeguard against modern
        slavery:
      </SectionText>
      <StatementList>
        <StatementListText>
          We have a zero-tolerance approach to modern slavery in our
          organisation and our supply chains.
        </StatementListText>
        <StatementListText>
          The prevention, detection and reporting of modern slavery in any part
          of our organisation or supply chain is the responsibility of all those
          working for us or on our behalf. Employees must not engage in,
          facilitate or fail to report any activity that might lead to, or
          suggest, a breach of this policy.
        </StatementListText>
        <StatementListText>
          We are committed to engaging with our stakeholders and suppliers to
          address the risk of modern slavery in our operations and supply chain.
        </StatementListText>
        <StatementListText>
          We take a risk-based approach to our contracting processes and keep
          them under review.
        </StatementListText>
        <StatementListText>
          We assess whether the circumstances warrant the inclusion of specific
          prohibitions against the use of modern slavery and trafficked labour
          in our contracts with third parties. Using our risk-based approach, we
          will also assess the merits of writing to suppliers requiring them to
          comply with our Policy Statement
        </StatementListText>
      </StatementList>
      <SectionText>
        Consistent with our risk-based approach we may require:
      </SectionText>
      <StatementList>
        <StatementListText>
          Employment and recruitment agencies and other third parties supplying
          workers to our company to confirm their compliance with modern slavery
          laws.
        </StatementListText>
        <StatementListText>
          Suppliers engaging workers through a third party to obtain that third
          parties’ agreement to adhere to modern slavery laws.
        </StatementListText>
        <StatementListText>
          As part of our ongoing risk assessment and due diligence processes, we
          will consider whether circumstances warrant us carrying out audits of
          suppliers for their compliance with this policy and modern slavery
          laws.
        </StatementListText>
      </StatementList>
      <SectionText>
        If we find that other individuals or organisations working on our behalf
        have breached this policy, we will ensure that we take appropriate
        action. This may range from considering the possibility of breaches
        being remediated and whether that might represent the best outcome for
        those individuals impacted by the breach to terminating such
        relationships.
      </SectionText>
    </DataWrapper>
  ),
  bgColor: 'bg-white',
  textColor: 'black',
};

const headerData = {
  title: 'Modern Slavery Policy',
  description:
    'This statement sets out the work that QMetric Group Limited (trading as Policy Expert and referred to as Policy Expert from here on in this policy) does to understand all potential modern slavery risks related to its business, and to put in place steps that are aimed at ensuring that there is no slavery or human trafficking in its own business and its supply chains.',
};

const MondernSlaveryStatementPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  return (
    <>
      <TextHeaderBanner
        backgroundColor="bg-pxCharcoal"
        shapePosition="bottom-left-header-low"
        data={headerData}
      />
      <TwoColumnHTMLBanner data={slaveryBannerData} />
    </>
  );
};

export default MondernSlaveryStatementPage;

export const Head: HeadFC = () => (
  <>
    <title>Modern slavery statement | Policy Expert</title>
    <meta
      name="description"
      content="Modern slavery policy. Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
