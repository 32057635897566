import { styled } from 'styled-components';

import { colors, fontFamilyCamptonSemiBold } from '../../styles/sharedStyled';
import { StyleProps } from './MessageHeaderBanner';

export const MessageHeaderBannerContainer = styled.div<{
  $backgroundColor: string;
  $height: StyleProps;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  height: ${({ $height }) => $height.mobile};
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    height: ${({ $height }) => $height.tablet};
  }

  @media (min-width: 1024px) {
    height: ${({ $height }) => $height.desktop};
  }
`;

export const MessageHeaderBannerDataContainer = styled.div<{
  $marginTop: StyleProps;
}>`
  padding: 0 20px;
  margin-top: ${({ $marginTop }) => $marginTop.mobile};
  z-index: 10;

  @media (min-width: 768px) {
    max-width: 585px;
    margin-top: ${({ $marginTop }) => $marginTop.tablet};
  }

  @media (min-width: 1024px) {
    max-width: 100%;
    margin-top: ${({ $marginTop }) => $marginTop.desktop};
  }
`;

export const MessageHeaderBannerTitle = styled.h1`
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 90px;
  font-weight: 600;
  line-height: 1;
  color: ${colors.white};

  @media (min-width: 768px) {
    font-size: 120px;
  }

  @media (min-width: 1024px) {
    font-size: 150px;
  }
`;
