import React from 'react';

import { Link } from 'gatsby';

import PolicyExpertBronze from '../../../static/svgIcons/PolicyExpertBronze.svg';
import TickAngles from '../../../static/svgIcons/TickAngles.svg';
import { ButtonType } from '../../types/button';
import Shape from '../Shape';

type RowItemType = {
  label: string;
  included: string;
};

export type TwoColumnTextTableType = {
  headerText: JSX.Element;
  btn: ButtonType;
  subText: JSX.Element;
  rowItem: RowItemType[];
};

type Props = {
  data: TwoColumnTextTableType;
};

const TwoColumnTextTable = ({ data }: Props) => {
  return (
    <div className="py-60px md:py-100px lg:py-160px bg-pxGrey relative overflow-x-hidden">
      <div className="padding-x">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="mb-30px lg:mb-0 w-full md:w-10/12 lg:w-5/12 block">
            {data.headerText}
          </div>

          <div className="w-full md:w-10/12 lg:w-5/12">
            <table className="w-full lg:block bg-white rounded-xl relative overflow-hidden">
              <thead className="w-full md:flex md:flex-row">
                <tr className="block md:grid md:grid-cols-2 md:w-full">
                  <td className="hidden md:block" />
                  <td className="w-full flex mx-auto py-32px border-pxGrey  md:border-l">
                    <div className="icons-primary5 z-50 mx-auto">
                      <PolicyExpertBronze
                        className="mx-auto"
                        stroke="#6214bE"
                        fill="#6214bE"
                      />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody className="flex flex-col">
                {data.rowItem.map((item, i) => (
                  <tr
                    key={i}
                    className="grid grid-cols-2 border-pxGrey border-t"
                  >
                    <td className="font-camptonBook font-normal text-pxBlack text-finerDetailsBodyCopy p-5 border-r border-pxGrey">
                      {item.label}
                    </td>
                    <>
                      {item.included === '✔' ? (
                        <td className="flex p-0">
                          <div className="icons-secondary3 z-50 mx-auto my-auto p-0">
                            <TickAngles
                              className="mx-auto my-auto p-0"
                              stroke="#6214bE"
                              fill="#6214bE"
                            />
                          </div>
                        </td>
                      ) : (
                        <td className="font-camptonBook font-normal p-5 text-finerDetailsBodyCopy text-center flex justify-center items-center text-pxPurple">
                          {item.included}
                        </td>
                      )}
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
            {data.subText && data.subText}
            {data.btn && (
              <div className="w-full">
                <Link
                  to={`${data.btn.url}`}
                  className={`btn-${
                    data.btn.primary ? 'primary' : 'secondary'
                  } w-full btn-md md:btn-lg lg:mx-auto`}
                >
                  {data.btn.label}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="hidden lg:block">
        <Shape position="left-twoTextTable" />
      </div>
      <div className="hidden md:block lg:hidden">
        <Shape position="right-top-twoTextTable" />
      </div>
    </div>
  );
};

export default TwoColumnTextTable;
