import { styled } from 'styled-components';

import Car from '../../../static/svgIcons/Car.svg';
import House from '../../../static/svgIcons/House.svg';
import Pet from '../../../static/svgIcons/Pet.svg';
import { colors } from '../../styles/sharedStyled';

export const CtaContainer = styled.div`
  width: 100%;
  max-width: 1330px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin: 0 auto;
  }

  @media (min-width: 1024px) {
    padding-left: 30px;
  }
`;

export const CtaButtonsContainer = styled.div<{
  $isErrorPage?: boolean;
  $v2Refresh?: boolean;
  $isHomepage?: boolean;
  $isRAFPage?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 100;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 9px;
    margin-top: ${({ $isErrorPage, $v2Refresh, $isHomepage, $isRAFPage }) =>
      $isErrorPage
        ? '-142px'
        : $v2Refresh && $isHomepage
        ? '-210px'
        : $isRAFPage
        ? '-180px'
        : '-115px'};
  }

  @media (min-width: 1024px) {
    justify-content: ${({ $isErrorPage }) =>
      $isErrorPage ? 'center' : 'flex-start'};
    gap: 4px;
    margin-top: ${({ $isErrorPage, $v2Refresh, $isHomepage, $isRAFPage }) =>
      $isErrorPage
        ? '-142px'
        : $v2Refresh && $isHomepage
        ? '-155px'
        : $isRAFPage
        ? '-180px'
        : '-220px'};
  }

  @media (min-width: 1300px) {
    margin-top: ${({ $isErrorPage, $isRAFPage }) =>
      $isErrorPage ? '-142px' : $isRAFPage ? '-180px' : '-220px'};
  }
`;

export const CarSvg = styled(Car)`
  width: 50px;
  z-index: 50;
  height: 25px;

  @media (min-width: 768px) {
    height: 30px;
    width: 100%;
  }
`;

export const HouseSvg = styled(House)`
  width: 50px;
  z-index: 50;
  height: 35px;
  fill: ${colors.purple7};
  stroke: ${colors.purple7};

  @media (min-width: 768px) {
    height: 40px;
    width: 100%;
  }
`;

export const PetSvg = styled(Pet)`
  width: 50px;
  z-index: 50;
  height: 25px;

  @media (min-width: 768px) {
    height: 35px;
    width: 100%;
  }
`;
