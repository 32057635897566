import React from 'react';

import { ReviewData } from '../../types/reviews';
import ReviewCard from '../reviewCard/ReviewCard';

const reviews: ReviewData[] = [
  {
    title: 'Jolly good price',
    author: 'Joan',
    date: '09/04/2024',
    review:
      'Just spoke and answered all my questions in language I could understand, no rush and at the end a very good price which I was over the moon with.',
  },
  {
    title: 'Brilliant customer service',
    author: 'Mr P Windows',
    date: ' 20/03/2024',
    review:
      'Good value policy with great customer service. Unfortunately had a claim last year when a lorry damaged our property, but Policy Expert quickly sorted it out.',
  },
  {
    title: 'Easy and straight forward',
    author: 'Trevor Von Trilsbach',
    date: '03/04/2024',
    review:
      'Easy and straight forward to take up my Motor Insurance quote which was very competitively priced at £331.08. Would highly recommend Policy Expert for Motor and Home Insurance as I now have both policies with them.',
  },
  {
    title: 'Efficient claims process',
    author: 'Eileen B',
    date: '03/04/2024',
    review:
      'I made one claim for damage to a shower room a few years back which was dealt with efficiently. This year I auto renewed as I could not find a more competitive deal elsewhere. When I have contacted the staff they have always been very knowledgeable, helpful and polite.',
  },
  {
    title: 'Helpful Staff',
    author: 'Jill',
    date: '09/04/2024',
    review:
      'Good prices and the staff on the phone are very efficient and helpful. I renewed my policy as they are still the best price, and the service and cover are excellent',
  },
  {
    title: 'Competitive renewals',
    author: 'Valued Customer',
    date: '04/04/2024',
    review:
      'Very competitive renewal offer. Happy to stay with this company for another year, which makes 10 consecutive years. Have the policies that meet my needs, at a price I can afford.',
  },
  {
    title: 'Easy to understand',
    author: 'Mrs Hadri',
    date: '09/04/2024',
    review:
      'The policy wording was easy to understand. Information was clear and accurate. No jargon used or any hidden fees.',
  },
  {
    title: 'Excellent savings',
    author: 'Karen',
    date: '10/04/2024',
    review:
      'Excellent experience, I saved £200 with policy expert on my building and home contents cover. Would definitely recommend to family and friends.',
  },
  {
    title: 'Informative and patient',
    author: 'Mrs Pat Aston',
    date: '10/04/2024',
    review:
      'I would not hesitate in recommending Policy Expert to anyone looking to take out insurance with them due to the sheer patience and help I received. Excellent service and price.',
  },
];

const NineInsuranceReviews: JSX.Element[] = [];
reviews.map((item) => {
  NineInsuranceReviews.push(
    <ReviewCard key={`carousel-${item.review}`} data={item} />
  );
});

export default NineInsuranceReviews;
