import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Shape from '../../components/Shape';
import ShapeX from '../../components/ShapeX';
import TermsList from '../../components/termsList/TermsList';

const PrizeTerms: React.FC = () => {
  const { isDesktop } = useBreakpoint();
  return (
    <>
      <title>Terms and conditions Christmas Giveaway | Policy Expert </title>
      <div className="overflow-hidden bg-pxGrey relative">
        <div className="max-w-700 mx-24px md:mx-auto pb-20px md:pb-170px">
          <h3 className="font-campton pb-0 mt-70px text-fontSize-25 lg:text-fontSize-35 md:mt-100px">
            Terms and Conditions
          </h3>
          <p className="font-campton text-fontSize-16 md:text-fontSize-20 text-purple-800 my-30px md:my-40px">
            £500 Christmas Cash Giveaway - December 2023
          </p>
          <TermsList />
        </div>
        <ShapeX position="bottom-left-cash-prize" />
        {isDesktop && <Shape position="right-far-top-cash-prize" />}
      </div>
    </>
  );
};

export default PrizeTerms;
