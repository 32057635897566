import { Link } from 'gatsby';
import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonRegular,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const BackgroundContainer = styled.div`
  background-color: ${colors.gray3};
  position: relative;
  overflow: hidden;
`;

export const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px;

  @media (min-width: 768px) {
    padding: 50px 40px;
    width: 650px;
  }

  @media (min-width: 1024px) {
    width: 800px;
    margin: 0 auto;
    padding: 130px 0;
  }
`;

export const TermsHeader = styled.h3`
  font-size: 25px;
  padding-bottom: 0;
  font-weight: 500;
  font-family: ${fontFamilyCamptonRegular};
  margin-bottom: 30px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    font-size: 35px;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
  }
`;

export const TermsListContainer = styled.ul`
  padding-left: 25px;
`;

export const TermsListItem = styled.li<{
  $isBold?: boolean;
  $isSubHeader?: boolean;
  $isPurple?: boolean;
}>`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: ${({ $isBold }) =>
    $isBold ? fontFamilyCamptonSemiBold : fontFamilyCamptonBook};
  list-style-position: outside;
  line-height: normal;
  letter-spacing: 0;
  list-style-type: ${({ $isSubHeader }) => $isSubHeader && 'none'};
  color: ${({ $isPurple }) => ($isPurple ? colors.purple7 : colors.black)};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const TermsInternalLink = styled(Link)`
  margin-bottom: 0;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0;
  font-weight: 600;
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const TermsExternalLink = styled.a`
  margin-bottom: 0;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0;
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const TermsList = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: ${fontFamilyCamptonBook};
  line-height: normal;
  letter-spacing: 0;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;
