import React from 'react';

import ShapeX, { ShapePosition } from '../ShapeX';
import SlantedBackground, {
  SlantDirection,
} from '../slantedBackground/SlantedBackground';

type HeaderBannerProps = {
  backgroundColor: string;
  slantDirection?: SlantDirection;
  headerImage: React.JSX.Element;
  shapePosition?: ShapePosition;
  styling: string;
  maxHeight?: string;
};

const HeaderBanner = ({
  backgroundColor,
  slantDirection,
  headerImage,
  shapePosition,
  styling,
  maxHeight,
}: HeaderBannerProps) =>
  slantDirection ? (
    <SlantedBackground
      backgroundColor={backgroundColor}
      slantDirection={slantDirection}
    >
      <div className={`my-0 mx-auto ${styling}`}>{headerImage}</div>
      {shapePosition && <ShapeX position={shapePosition} />}
    </SlantedBackground>
  ) : (
    <div
      className={`${backgroundColor} ${
        maxHeight ? maxHeight : 'max-h-700'
      } relative overflow-hidden`}
    >
      <div className={`my-0 mx-auto ${styling}`}>{headerImage}</div>
      {shapePosition && <ShapeX position={shapePosition} />}
    </div>
  );
export default HeaderBanner;
