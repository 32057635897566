import React, { useEffect, useRef, useState } from 'react';

import { useJobs } from '../../hooks/useJobs';
import Shape from '../Shape';
import ShapeX from '../ShapeX';
import JobCard from './JobCard';

function Jobs() {
  const ref = useRef<HTMLSelectElement>(null);

  const [selectedCareers, setSelectedCareers] = useState<null | any[]>(null);

  const {
    isCareersLoading,
    careers,
    motherwellCareers,
    miltonKeynesCareers,
    londonCareers,
  } = useJobs();
  const getLocation = (e: any) => {
    const currentLocation = e.target.value;

    if (currentLocation === 'all-locations') {
      setSelectedCareers(careers);
    }
    if (currentLocation === 'Motherwell') {
      setSelectedCareers(motherwellCareers);
    }
    if (currentLocation === 'Milton Keynes') {
      setSelectedCareers(miltonKeynesCareers);
    } else if (currentLocation === 'London') {
      setSelectedCareers(londonCareers);
    }
  };

  useEffect(() => {
    if (isCareersLoading === true) {
      return;
    }
    const params = new URLSearchParams(location.search);
    const locationString = params.get('location');
    if (locationString === null) {
      setSelectedCareers(careers);
    }
    if (locationString === 'motherwell') {
      setSelectedCareers(motherwellCareers);
      // @ts-ignore
      ref.current.value = 'Motherwell';
    }
    if (locationString === 'milton_keynes') {
      setSelectedCareers(miltonKeynesCareers);
      // @ts-ignore
      ref.current.value = 'Milton Keynes';
    } else if (locationString === 'london') {
      setSelectedCareers(londonCareers);
      // @ts-ignore
      ref.current.value = 'London';
    }
  }, [
    isCareersLoading,
    careers,
    motherwellCareers,
    miltonKeynesCareers,
    londonCareers,
  ]);

  return (
    <div className="bg-pxGrey py-82px md:py-130px relative overflow-hidden">
      <div className="padding-x">
        <div className="grid grid-cols-1 md:grid-cols-2 ">
          <div className="w-full max-w-lg">
            <h1 className="pb-5 md:pb-82px text-pxCharcoal">Job Vacancies</h1>
            <p className={'text-pxCharcoal'}>
              Use the drop-down function to filter jobs based on your preferred
              location
            </p>
          </div>
          <div className="w-full">
            <div className="pb-82px selectdiv">
              {/* @ts-ignore */}
              <select
                ref={ref}
                className="w-full px-3 py-2 md:py-5 mr-5 bg-white rounded-full md:rounded-lg border-2 text-finerDetailsBodyCopy border-gray-200"
                onChange={getLocation}
              >
                <option value="all-locations">
                  All jobs, no location specified
                </option>
                <option value="Motherwell">Motherwell</option>
                <option value="Milton Keynes">Milton Keynes</option>
                <option value="London">London</option>
              </select>
            </div>
            {selectedCareers !== null ? (
              selectedCareers.map((item: any) => (
                <JobCard
                  title={item.title}
                  location={item.location}
                  department={item.department}
                  closing_date={item.closing_date}
                  status={item.status}
                  key={item.title}
                ></JobCard>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {selectedCareers && selectedCareers.length >= 3 ? (
        <div className="hidden lg:block">
          <Shape position="left-near" />
        </div>
      ) : null}
      <ShapeX position="bottom-left-cards" />
    </div>
  );
}

export default Jobs;
