import React from 'react';

import { navigate } from 'gatsby';

import {
  Button,
  ButtonContentContainer,
  ButtonIconContainer,
  ButtonsGridContainer,
  CarSvg,
  HammerSvg,
  HouseSvg,
  KeySvg,
  LeakSvg,
} from './BusinessLineButtons.style';

type PolicyType =
  | 'Home insurance'
  | 'Legal expenses'
  | 'Home emergency'
  | 'Key cover'
  | 'Car insurance'
  | 'Optional extras';

type Props = {
  selectedTab: string;
  productNames: string[];
  productName: string;
  onClick: (businessLineProduct: string) => void;
};

const getBusinessLinePolicyIcon = (policyType: PolicyType) => {
  switch (policyType) {
    case 'Home insurance':
      return <HouseSvg data-testid="house-icon" />;
    case 'Legal expenses':
      return <HammerSvg data-testid="hammer-icon" />;
    case 'Home emergency':
      return <LeakSvg data-testid="leak-icon" />;
    case 'Key cover':
      return <KeySvg data-testid="key-icon" />;
    case 'Car insurance':
      return <CarSvg data-testid="car-icon" />;
    case 'Optional extras':
      return <HammerSvg data-testid="hammer-icon" />;
    default:
      return null;
  }
};

const BusinessLineButtons = ({
  selectedTab,
  productNames,
  productName,
  onClick,
}: Props) => {
  return (
    <ButtonsGridContainer>
      {productNames.map((product) => {
        const productIcon = getBusinessLinePolicyIcon(product as PolicyType);

        return (
          <Button
            key={product}
            onClick={() => {
              if (productName === 'Car' && product === 'Optional extras') {
                navigate(`/motor-docs`);
              } else {
                onClick(product);
              }
            }}
            $isActive={product === selectedTab}
          >
            <ButtonContentContainer>
              <ButtonIconContainer>{productIcon}</ButtonIconContainer>
              {product}
            </ButtonContentContainer>
          </Button>
        );
      })}
    </ButtonsGridContainer>
  );
};

export default BusinessLineButtons;
