import { styled } from 'styled-components';

import DropdownArrow from '../../../static/icons/dropdown-arrow.svg';
import AmazonCardMobileSvg from '../../../static/svgIcons/AmazonCardMobile.svg';
import ArrowRight from '../../../static/svgIcons/ArrowRight.svg';
import TickCircleIconSvg from '../../../static/svgIcons/TickCircleIcon.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const MainBackground = styled.div`
  background-color: ${colors.purple7};
  position: relative;
  overflow: hidden;
  padding: 40px 24px 100px;

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 50px 40px 200px;
  }

  @media (min-width: 1024px) {
    padding: 80px 25px 210px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  align-self: stretch;

  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
    max-width: 754px;
    justify-content: space-between;
    margin: auto;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    max-width: 1200px;
    justify-content: center;
    margin: auto;
  }
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 434px;
    gap: 30px;
    margin-right: 15px;
  }

  @media (min-width: 1024px) {
    max-width: 753px;
    gap: 35px;
    margin-right: 80px;
  }
`;

export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;

  @media (min-width: 768px) and (max-width: 1023px) {
    align-items: start;
  }

  @media (min-width: 1024px) {
    align-items: start;
    gap: 20px;
  }
`;

export const Title = styled.h1`
  color: ${colors.white};
  text-align: center;
  font-family: ${fontFamilyCamptonMedium};
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    text-align: start;
  }

  @media (min-width: 1024px) {
    text-align: start;
    font-size: 50px;
  }
`;

export const Subtitle = styled.h2`
  color: ${colors.white};
  text-align: center;
  font-family: ${fontFamilyCamptonBook};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    text-align: start;
  }

  @media (min-width: 1024px) {
    text-align: start;
    font-size: 25px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
`;

export const DescriptionTitle = styled.h3`
  color: ${colors.white};
  text-align: center;
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  padding-bottom: 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    text-align: start;
  }

  @media (min-width: 1024px) {
    text-align: start;
    font-size: 20px;
  }
`;

export const DescriptionText = styled.p`
  color: ${colors.white};
  text-align: center;
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  letter-spacing: 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    text-align: start;
  }

  @media (min-width: 1024px) {
    text-align: start;
    font-size: 20px;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;

  @media (min-width: 1024px) {
    max-width: 530px;
  }
`;

export const EmailInput = styled.input`
  border-radius: 5px;
  border: 1px solid ${colors.gray7};
  background-color: ${colors.white};
  height: 50px;
  padding: 10px;
  font-family: ${fontFamilyCamptonMedium};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0;

  &::placeholder {
    color: ${colors.gray8};
  }
`;

export const SelectAndSubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
    gap: 15px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const MonthSelector = styled.select<{ $hasValue: boolean }>`
  appearance: none;
  border-radius: 5px;
  border: 1px solid ${colors.gray7};
  background-color: ${colors.white};
  height: 50px;
  padding: 10px;
  color: ${({ $hasValue }) =>
    $hasValue ? `${colors.black}` : `${colors.gray8}`};
  font-family: ${fontFamilyCamptonMedium};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-image: url(${DropdownArrow});
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
  cursor: pointer;

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    max-width: 300px;
  }

  @media (min-width: 1024px) {
    width: 100%;
    max-width: 350px;
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  height: 50px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border-width: 2px;
  border-color: ${colors.pink2};
  background-color: ${colors.pink2};
  color: ${colors.white};
  text-align: center;
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  &:hover:enabled {
    background: ${colors.white};
    color: ${colors.pink2};
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 119px;
  }

  @media (min-width: 1024px) {
    width: 160px;
  }
`;

export const PrizeDrawContainer = styled.div`
  padding: 20px 24px 21px;
  background-color: ${colors.pink2};

  @media (min-width: 768px) {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
`;

export const PrizeBannerContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-top: 5px;
  }
`;

export const PrizeInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  position: relative;

  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) {
    padding: 20px;
    margin-bottom: 0;
    height: 115px;
  }
`;

export const PrizeInformationTitle = styled.p`
  color: ${colors.white};
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 18px;
    margin-right: 5px;
  }

  @media (min-width: 1024px) and (max-width: 1190px) {
    font-size: 22px;
  }

  @media (min-width: 1191px) {
    font-size: 24px;
  }
`;

export const PrizeInformationNote = styled.p`
  color: ${colors.white};
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const PrizeInformationText = styled.p`
  color: ${colors.white};
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 75%;
  margin: 25px 0;
  letter-spacing: 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 395px;
    margin: 10px 0 22px;
  }

  @media (min-width: 1024px) and (max-width: 1190px) {
    max-width: 100%;
    margin: 8px 0 0;
    font-size: 14px;
  }

  @media (min-width: 1191px) {
    max-width: 100%;
    margin: 8px 0 0;
  }
`;

export const PrizeBannerDesktopContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 753px;
  border-radius: 10px;
  background-color: ${colors.pink2};
`;

export const VoucherSpan = styled.span`
  color: ${colors.white};
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 1024px) and (max-width: 1190px) {
    font-size: 14px;
  }
`;

export const ShowMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ShowMoreText = styled.p`
  color: ${colors.white};
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 0;
`;

export const ShowMoreArrow = styled(ArrowRight)<{ $isShowMoreOpen: boolean }>`
  fill: ${colors.white};
  transform: rotate(90deg);
  transform: ${({ $isShowMoreOpen }) =>
    $isShowMoreOpen ? 'rotate(270deg)' : 'rotate(90deg)'};
  height: 11px;
  width: 6px;
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  height: 190px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${colors.white};
  background-color: ${colors.purple12};

  @media (min-width: 768px) and (max-width: 1023px) {
    height: 115px;
  }

  @media (min-width: 1024px) {
    height: 120px;
    max-width: 530px;
  }
`;

export const ConfirmationTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

export const ConfirmationTitle = styled.p`
  color: ${colors.white};
  text-align: center;
  font-family: ${fontFamilyCamptonMedium};
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  letter-spacing: 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    font-family: ${fontFamilyCamptonBook};
  }
`;

export const ConfirmationMessage = styled.p`
  color: ${colors.white};
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  letter-spacing: 0;
`;

export const AmazonCardMobile = styled(AmazonCardMobileSvg)`
  position: absolute;
  top: 80px;
  right: 0;
`;

export const AmazonCardDesktop = styled.img`
  width: 140px;
`;

export const TickCircleIcon = styled(TickCircleIconSvg)`
  margin: 0 12px 6px 0;
`;
