import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Arrow,
  ErrorTextContainer,
  RenewalDropDownContainer,
  RenewalDropDownSelect,
} from './RenewalDropDown.style';

type Props = {
  name: string;
  required: boolean;
  product: string;
};

const RenewalDropDown: FC<Props> = ({ name, required, product }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <RenewalDropDownContainer>
      <RenewalDropDownSelect
        {...register(name, {
          required: {
            value: required,
            message: `Please select a month`,
          },
        })}
        id={name}
        $hasError={!!errors[name]}
      >
        <option disabled selected hidden value="">
          Renewal month
        </option>

        <option value="January">January</option>
        <option value="February">February</option>
        <option value="March">March</option>
        <option value="April">April</option>
        <option value="May">May</option>
        <option value="June">June</option>
        <option value="July">July</option>
        <option value="August">August</option>
        <option value="September">September</option>
        <option value="October">October</option>
        <option value="November">November</option>
        <option value="December">December</option>
        {product === 'pet' && <option value="">I don't have a pet</option>}
      </RenewalDropDownSelect>
      <Arrow />

      {errors[name] && (
        <ErrorTextContainer>
          {errors[name]?.message as string}
        </ErrorTextContainer>
      )}
    </RenewalDropDownContainer>
  );
};
``;
export default RenewalDropDown;
