import React from 'react';

import { AwardsDataType } from '../../pages/about-us/awards';
import AwardsYear from './AwardsYear';

type Props = {
  data: AwardsDataType;
};

const AwardsGrid = ({ data }: Props) => {
  return (
    <div className="py-82px md:py-130px bg-pxGrey">
      <div className="padding-x">
        <h2 className="pb-32px md:pb-82px">{data.title}</h2>
        {data.year &&
          data.year.map((year) => (
            <AwardsYear yearData={year} key={year.year} />
          ))}
      </div>
    </div>
  );
};

export default AwardsGrid;
