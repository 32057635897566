import React, { useEffect, useState } from 'react';

import Carousel from '../carousel/Carousel';
import Shape from '../Shape';

type fullScreenCarouselCardType = {
  header?: string;
  content: string;
  imgName?: string;
};

export type FullScreenCarouselDataType = {
  header: string;
  cards: fullScreenCarouselCardType[];
};

type Props = {
  data: FullScreenCarouselDataType;
};

const FullScreenCarousel = ({ data }: Props) => {
  const [cards, setCards] = useState<any>([]);

  useEffect(() => {
    const cards = data.cards.map((card) => (
      <div key={card.content} className="padding-x mx-auto w-full relative">
        <div className="md:w-1/2">
          <p className="text-white">{card.content}</p>
        </div>
      </div>
    ));
    setCards(cards);
  }, []);

  return (
    <div className="full-w-carousel bg-pxCharcoal py-82px md:py-130px overflow-hidden">
      <div className="relative">
        <div className="padding-x relative z-50">
          <h2 className="text-white mb-82px md:w-1/2">{data.header}</h2>
        </div>
        <Carousel
          pagination={{ arrows: true, color: 'pxPurple' }}
          slidesPerView={1}
          cards={cards}
        />
        <Shape position="right-near" />
      </div>
    </div>
  );
};

export default FullScreenCarousel;
