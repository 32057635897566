import React from 'react';

import { ProductBannerDataType } from '../petProductBanner/PetProductBanner';
import {
  Cell,
  HeaderCellWrapper,
  HeaderRow,
  HeaderWrapper,
  Row,
  TableContainer,
  TableRowsContainer,
  TableSection,
} from './PetProductTable.style';

const PetProductTable = ({
  tableData,
}: Pick<ProductBannerDataType, 'tableData'>) => (
  <TableContainer>
    <HeaderWrapper>
      {tableData.tableHeaderIcons.map((icon, index) => (
        <HeaderCellWrapper key={`${icon!.toString()}_${index}`}>
          {icon}
        </HeaderCellWrapper>
      ))}
    </HeaderWrapper>
    <TableRowsContainer>
      {tableData.tableContent.map((tableData, index) => {
        return (
          <TableSection key={`${tableData.header?.toString()}_${index}`}>
            {tableData.header && (
              <HeaderRow $isFirstHeader={index === 0}>
                {tableData.header}
              </HeaderRow>
            )}
            {tableData.productRows.map((productRow, index) => {
              return (
                <Row role="row" key={`${productRow.label.toString()}_${index}`}>
                  <Cell role="cell">{productRow.label}</Cell>
                  {productRow.values.map((value, index) => {
                    return (
                      <Cell key={`${value.toString()}_${index}`}>{value}</Cell>
                    );
                  })}
                </Row>
              );
            })}
          </TableSection>
        );
      })}
    </TableRowsContainer>
  </TableContainer>
);

export default PetProductTable;
