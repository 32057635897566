import React, { useEffect, useState } from 'react';

import { useLocation } from '@reach/router';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import BurgerMenu from '../../../static/svgIcons/BurgerMenu.svg';
import useIsCampaignPage from '../../hooks/useIsCampaignPage';
import usePetChanges from '../../hooks/usePetChanges';
import { bottomNavLinks, formatUrl } from '../pageLayout/pageLinks';
import BottomNavMenu from './BottomNavMenu';
import CtaBar from './CtaBar';
import NavLink from './NavLink';

const BottomNav = () => {
  const { isMobile, isTablet } = useBreakpoint();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const isCampaignPage = useIsCampaignPage();
  const isPetChangesEnabled = usePetChanges();

  const isCtaShowing =
    pathname === '/home-insurance/' || pathname === '/car-insurance/';

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
      // Safari block scrolling
      document.ontouchmove = function (e) {
        e.preventDefault();
      };
    } else {
      document.body.style.overflow = 'visible';
      // Safari resume scrooling
      document.ontouchmove = function () {
        return true;
      };
    }
  }, [isMenuOpen]);

  return isTablet ? (
    <>
      {isMenuOpen && <BottomNavMenu closeMenu={closeMenu} />}
      <div
        className={`z-1000 sticky bottom-0 bg-white w-full flex flex-col ${
          !isMenuOpen && 'shadow-topLg'
        }`}
      >
        {!isMenuOpen && <CtaBar />}
        {!isCampaignPage && (
          <div className="w-full h-65px md:h-70px flex flex-row">
            {bottomNavLinks[0].map(({ url, label, icon, isExternal }) => (
              <NavLink
                key={`${label}_nav`}
                url={formatUrl({ url, isPetChangesEnabled })}
                isExternal={isExternal}
                onClick={closeMenu}
                className={`bottom-nav-button ${
                  url === pathname && !isMenuOpen && 'bg-pxMenu'
                } ${
                  ((url !== pathname && isCtaShowing) || isMenuOpen) &&
                  'bottom-nav-top-border'
                }`}
              >
                {icon}
                {label}
              </NavLink>
            ))}
            {!isMobile && (
              <>
                {bottomNavLinks[1].map(({ url, label, icon, isExternal }) => (
                  <NavLink
                    key={`${label}_nav`}
                    url={url}
                    isExternal={isExternal}
                    className={`bottom-nav-button ${
                      (isMenuOpen || isCtaShowing) && 'bottom-nav-top-border'
                    }`}
                  >
                    {icon}
                    {label}
                  </NavLink>
                ))}
              </>
            )}
            {bottomNavLinks[2].map(({ url, label, icon, isExternal }) => (
              <NavLink
                key={`${label}_nav`}
                url={url}
                isExternal={isExternal}
                onClick={closeMenu}
                className={`bottom-nav-button  ${
                  url === pathname && !isMenuOpen && 'bg-pxMenu'
                } ${(isMenuOpen || isCtaShowing) && 'bottom-nav-top-border'}`}
              >
                {icon}
                {label}
              </NavLink>
            ))}
            <div
              onClick={() => setIsMenuOpen((current) => !current)}
              className={`bottom-nav-button ${isMenuOpen && 'bg-pxMenu'} ${
                isCtaShowing && !isMenuOpen && 'bottom-nav-top-border'
              }`}
            >
              <BurgerMenu className="h-15px mb-1" alt="Menu icon" />
              Menu
            </div>
          </div>
        )}
      </div>
    </>
  ) : null;
};

export default BottomNav;
