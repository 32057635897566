import React from 'react';

import CarIcon from '../../static/svgIcons/Car.svg';
import HouseIcon from '../../static/svgIcons/Home.svg';
import PetIcon from '../../static/svgIcons/Pet.svg';
import { ImpactV2ProductSelectionData } from '../components/productButtonsBanner/ProductButtonsBanner';
import ProductButton from './useProductSelection.style';

const useProductSelection = (
  products: string[]
): ImpactV2ProductSelectionData => {
  return products.map((product) => {
    let Icon;
    switch (product) {
      case 'home':
        Icon = HouseIcon;
        break;
      case 'car':
        Icon = CarIcon;
        break;
      case 'pet':
        Icon = PetIcon;
        break;
      default:
        throw new Error(`Unknown product type: ${product}`);
    }
    return {
      product,
      buttonHeader: `${product.charAt(0).toUpperCase()}${product.slice(
        1
      )} insurance`,
      svg: <ProductButton as={Icon} className={product} />,
    };
  });
};

export default useProductSelection;
