import React from 'react';

import { ComplaintInstructionsType } from '../../pages/how-to-complain';
import Shape from '../Shape';

type Props = {
  data: ComplaintInstructionsType;
};

const ImageWithList = ({ data }: Props) => (
  <div className={`${data.bgColor}`}>
    <div
      className={`py-82px md:py-130px padding-x flex flex-col justify-between md:flex-row overflow-hidden ${
        data.borderTop ? 'border-t border-pxBlack' : ''
      }`}
    >
      <div className="flex flex-col w-full lg:w-1/2">
        <h3 className="sectionSubHeadingMobile md:sectionSubHeading lg:w-2/3 pb-82px">
          {data.sectionHeader}
        </h3>
        <div>
          <ol className="text-bodyCopy text-small">
            {data.textList.map((item: any) => (
              <li key={item.text}>{item.text}</li>
            ))}
          </ol>
          {data.learnMore}
        </div>
      </div>
      <div className="hidden lg:block lg:w-1/2 relative">
        <Shape position="right-near" />
      </div>
    </div>
  </div>
);

export default ImageWithList;
