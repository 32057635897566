import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import ComplaintsTable from '../components/complaintsTable/ComplaintsTable';
import Shape from '../components/Shape';
import TwoColumnTextBanner, {
  TwoColumnTextBannerType,
} from '../components/twoColumnTextBanner/TwoColumnTextBanner';
import useDisclaimer from '../hooks/useDisclaimer';

const twoColumnTextBannerData: TwoColumnTextBannerType = {
  headerText: 'Gender pay gap',
  bgColor: 'bg-pxGrey',
  subText: [
    'The gender pay gap at QMetric Group Limited (trading as Policy Expert) is based on a snapshot of hourly rates of pay at 5 April 2023. The bonus gap is based on bonus pay in the 12 month period leading up to April 2023.',
    'I confirm that the figures in our disclosure are accurate and have been calculated in accordance with gender pay gap reporting requirements as outlined in the legislation and accompanying guidance.',
    <div className="text-black " key={'kchidwick'}>
      <p className="text-bannerCopy lg:text-accordionSubHeadingMobile">
        <span className="font-bold">Kevin Chidwick</span>
        <br /> Director
      </p>
    </div>,
  ],
};

const multiTablesData = {
  bgColor: 'bg-pxCharcoal',
  tables: [
    {
      columns: 3,
      tableTitles: [
        {
          tableTitle: 'Gender pay gap',
        },
        {
          tableTitle: 'Apr-22',
        },
        {
          tableTitle: 'Apr-23',
        },
      ],
      productRows: [
        {
          label: 'Mean',
          included: ['17.7%', '21.2%'],
        },
        {
          label: 'Median',
          included: ['6.8%', '8.2%'],
        },
      ],
    },
    {
      columns: 3,
      tableTitles: [
        {
          tableTitle: 'Gender bonus gap',
        },
        {
          tableTitle: 'Apr-22',
        },
        {
          tableTitle: 'Apr-23',
        },
      ],
      productRows: [
        {
          label: 'Mean',
          included: ['-13.6%', '5.6%'],
        },
        {
          label: 'Median',
          included: ['34.1%', '0.0%'],
        },
      ],
    },
    {
      columns: 3,
      tableTitles: [
        {
          tableTitle: '% Employee receiving a bonus',
        },
        {
          tableTitle: 'Apr-22',
        },
        {
          tableTitle: 'Apr-23',
        },
      ],
      productRows: [
        {
          label: 'Male',
          included: ['23.5%', '69.7%'],
        },
        {
          label: 'Female',
          included: ['36.9%', '86.8%'],
        },
      ],
    },
    {
      firstRowDark: true,
      firstColLarge: true,
      columns: 5,
      tableTitles: [
        {
          tableTitle: 'Proportion of males and females in each pay quartile',
        },
        {
          tableTitle: 'Apr-22',
        },
        {
          tableTitle: 'Apr-22',
        },
        {
          tableTitle: 'Apr-23',
        },
        {
          tableTitle: 'Apr-23',
        },
      ],
      productRows: [
        {
          label: ' ',
          included: ['Male', 'Female', 'Male', 'Female'],
        },
        {
          label: 'Top Quartile',
          included: ['63.8%', '36.2%', '62.5%', '37.5%'],
        },
        {
          label: 'Second Quartile',
          included: ['52.9%', '47.1%', '56.1%', '43.9%'],
        },
        {
          label: 'Third Quartile',
          included: ['48.6%', '51.4%', '45.1%', '54.9%'],
        },
        {
          label: 'Fourth Quartile',
          included: ['48.6%', '51.4%', '50.6%', '49.4%'],
        },
      ],
    },
  ],
};

const GenderPayGapPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  return (
    <>
      <TwoColumnTextBanner data={twoColumnTextBannerData} section172 noHR />
      <div
        className={`${multiTablesData.bgColor} py-82px pb-32px md:py-130px relative overflow-hidden`}
      >
        <div className="padding-x">
          <div className="mx-auto">
            {multiTablesData.tables &&
              multiTablesData.tables.map((table: any) => (
                <ComplaintsTable
                  data={table}
                  multiTables
                  key={table.tableTitles[0]}
                />
              ))}
          </div>
        </div>
        <div className="hidden lg:block">
          <Shape position="right-pay-gap" />
        </div>
        <div className={`hidden lg:block`}>
          <Shape position="left-pay-gap" />
        </div>
      </div>
    </>
  );
};

export default GenderPayGapPage;

export const Head: HeadFC = () => (
  <>
    <title>Gender pay gap | Policy Expert</title>
    <meta
      name="description"
      content="Policy Expert gender pay gap information"
    />
    <html lang="en" />
  </>
);
