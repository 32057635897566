import React, { ReactNode } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import {
  MobileTableCell,
  MobileTableHeader,
  MobileTableWrapper,
  TableStyle,
} from './Table.style';

type Props = {
  headers: string[];
  rows: ReactNode[][];
};

export const Table = ({ headers, rows }: Props) => {
  const { isTablet, isMobile } = useBreakpoint();

  if (isMobile) {
    return rows.map((row) => (
      // eslint-disable-next-line react/jsx-key
      <MobileTableWrapper>
        {row.map((cell, index) => (
          <>
            <MobileTableHeader>{headers[index]}</MobileTableHeader>
            <MobileTableCell>{cell}</MobileTableCell>
          </>
        ))}
      </MobileTableWrapper>
    ));
  }

  return (
    <TableStyle>
      <colgroup>
        <col span={1} style={{ width: isTablet ? '35%' : '400px' }} />
        <col span={1} style={{ width: isTablet ? '32.5%' : '300px' }} />
        <col span={1} style={{ width: isTablet ? '32.5%' : '300px' }} />
      </colgroup>
      <thead>
        <tr>
          {headers.map((header) => (
            <td key={header}>{header}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((cells) => (
          // eslint-disable-next-line react/jsx-key
          <tr>
            {cells.map((cell) => (
              // eslint-disable-next-line react/jsx-key
              <td>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  );
};
