import React from 'react';

import { IconTextType } from '../../types/icon';
import Icon from '../icons/Icon';

interface LandingIconsType extends IconTextType {
  link: string;
  linkUrl: string;
}

export type TwoColumnIconTextDataType = {
  icons: LandingIconsType[];
};

type Props = {
  data: TwoColumnIconTextDataType;
};

const TwoColumnIconText = ({ data }: Props) => {
  return (
    <section className="bg-pxPurple py-82px text-center  text-white">
      <div className="flex flex-row text-center items-center justify-center gap-4 lg:gap-36 px-6 md:px-24">
        {data.icons.map((item) => (
          <div
            className="flex justify-center items-center flex-col max-w-435"
            key={item.text?.toString()}
          >
            <div className="mb-5">
              <Icon type="primary" size={3}>
                {item.icon}
              </Icon>
            </div>

            <div>
              <p className="text-bannerCopy lg:text-leadBodyCopy">
                {item.text}
              </p>
              <hr className="mb-2" />
              <a
                href={item.linkUrl}
                className="text-white text-footerCopy lg:text-bannerCopy font-camptonBook font-normal"
              >
                {item.link}
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TwoColumnIconText;
