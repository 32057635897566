import React, { FC } from 'react';
import { useWizard } from 'react-use-wizard';

import { CoverType } from './CoverSelector';
import { PerilDataType } from './HecData';
import { Arrow, ListOfPerils, Peril, Row } from './PerilList.style';

type PerilListProps = {
  perilData: PerilDataType;
  coverType: CoverType;
  setDeclineReason: (declineReason: string) => void;
};

const PerilList: FC<PerilListProps> = ({
  perilData,
  coverType,
  setDeclineReason,
}) => {
  const { goToStep, stepCount } = useWizard();
  const declineStep = stepCount - 1;

  if (coverType === 'Select') {
    setDeclineReason(
      'Electrics, Gas and Roofing are only covered under Home Emergency Plus.'
    );
  }

  return (
    <ListOfPerils>
      {perilData.map((perilType, index) => {
        const isFirstPeril = index === 0;
        const isLastPeril = index === perilData.length - 1;

        const goToStepLocation = () => {
          if (coverType) {
            if (perilType.stepLocation[coverType] === 'Decline') {
              goToStep(declineStep);
            } else {
              goToStep(perilType.stepLocation[coverType] as number);
            }
          }
        };

        return (
          <Row
            key={perilType.peril}
            data-testid="peril-list-row"
            $notFirstPeril={!isFirstPeril}
            $notLastPeril={!isLastPeril}
            onClick={goToStepLocation}
          >
            <Peril>{perilType.peril}</Peril>
            <Arrow />
          </Row>
        );
      })}
    </ListOfPerils>
  );
};

export default PerilList;
