import React from 'react';

import { HeadFC } from 'gatsby';

import InsuranceReviews from '../../components/insuranceReviews/InsuranceReviews';
import RenewalVideoHeader from '../../components/renewalVideoHeader/RenewalVideoHeader';
import ReviewBanner from '../../components/reviewBanner/ReviewBanner';
import SlantedBackground from '../../components/slantedBackground/SlantedBackground';

const reviewsTitle = { headerText: 'Why people like you stay with us...' };

const HomeRenewalPricePage: React.FC = () => {
  return (
    <div className="overflow-hidden">
      <RenewalVideoHeader>
        <iframe
          src="https://player.vimeo.com/video/849475764?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Insurance for people like you"
          className="border-0 border-none absolute top-0 left-0 bottom-0 right-0 w-full h-full"
        />
      </RenewalVideoHeader>
      <SlantedBackground
        backgroundColor={'bg-pxMint'}
        slantDirection={'top-right'}
        margin="mb-1-minus"
        height
      />
      <ReviewBanner
        cards={InsuranceReviews}
        data={reviewsTitle}
        shape
        isRenewal
      />
    </div>
  );
};

export default HomeRenewalPricePage;

export const Head: HeadFC = () => (
  <>
    <title>Policy Expert: Home and Car insurance for people like you</title>
    <meta
      name="description"
      content="Low price insurance, easy to understand policies and stress free claims. That’s why 86% of home customers stay with us every year. Insurance for people like you."
    />
    <html lang="en" />
  </>
);
