import { styled } from 'styled-components';

import Right from '../../../static/svgIcons/Right.svg';
import { colors, SvgIcon } from '../../styles/sharedStyled';

export const RightSvg = styled(Right)`
  ${SvgIcon};
  fill: ${colors.pink2};
  stroke: ${colors.pink2};
`;
