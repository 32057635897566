import React, { useContext } from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../components/headerBanner/HeaderBanner';
import TwoColumnHTMLBanner from '../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import { PhoneNumbersContext } from '../context/PhoneNumbersProvider';
import useDisclaimer from '../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../types/breakpoints';
import TermsCopyType from '../types/terms';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src="../images/payment_tc_cb_sm.png"
        alt="Finance agreement. Close Brothers"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src="../images/payment_tc_cb_md.png"
        alt="Finance agreement. Close Brothers"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src="../images/payment_tc_cb_lg.png"
      alt="Finance agreement. Close Brothers"
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const PaymentTermsAndConditionsMotorCBPage: React.FC<PageProps> = () => {
  useDisclaimer(null);
  const phoneNumbers = useContext(PhoneNumbersContext);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const qaCopy: TermsCopyType = {
    heading: 'The Direct Debit Guarantee',
    body: (
      <div>
        <p className="text-small">
          <span className="font-semibold">
            Q: Are there any uses for which this credit agreement is not
            suitable? If so, what are they?
          </span>
          <br />
          <br />
          A: The running account credit agreement gives you an easy way to
          spread the cost of your insurance premium over a period of time. It is
          specifically designed for the repayment of insurance premiums and
          isn't suitable for any other use.
        </p>

        <p className="text-small">
          <span className="font-semibold">Q: What will it cost me?</span>
          <br />
          <br />
          A: You will have to pay back the money you draw down under the
          agreement and pay interest, a facility fee (if charged) and any credit
          arrangement fee you may have been charged by your broker if you have
          chosen to fund this with us. Your minimum monthly payments are
          calculated by adding interest to the amount you’ve drawn down and
          dividing that total by the number of months over which the money is to
          be repaid. These have already been provided to you and will be
          confirmed in your Welcome Pack sent by Close Brothers Premium Finance
          on completion of your application.
        </p>

        <p className="text-small">
          <span className="font-semibold">
            Q: Are there any features of the credit agreement which could have
            an adverse impact on me?
          </span>
          <br />
          <br />
          A: Yes. As the insurance policy forms the security for your credit,
          your insurance policy is at risk of cancellation if you fail to meet
          your repayment obligations. If your insurance policy is cancelled in
          such circumstances, Close Brothers Premium Finance has the right to
          receive any proceeds or refunds paid. They will use these funds to
          reduce the amount you owe.
        </p>

        <p className="text-small">
          <span className="font-semibold">
            Q: What will happen if I do not pay my minimum monthly payments on
            time?
          </span>
          <br />
          <br />
          A: You may have to pay a charge plus interest if Close Brothers
          Premium Finance doesn’t receive a minimum monthly payment on time. If
          you are in breach of the terms of the credit agreement, Close Brothers
          Premium Finance may have a right to terminate it and in such
          circumstances a termination fee may be charged. These fees are set out
          in the Standard European Consumer Credit Information (SECCI). Close
          Brothers Premium Finance may enforce any security given under the
          terms of the credit agreement, which will result in the cancellation
          of your insurance policy. You’ll be liable to pay all sums owing to
          Close Brothers Premium Finance, as well as any charges. Your credit
          record may be affected, and you may find it harder to borrow from
          Close Brothers Premium Finance or another lender.
        </p>

        <p className="text-small">
          <span className="font-semibold">
            Q: Do I have any right of withdrawal? If so, what are the terms?
          </span>
          <br />
          <br />
          A: You have the right under the Consumer Credit Act 1974 to withdraw
          from your credit agreement without giving any reason before the end of
          14 days beginning on the day after:
          <br />
          <br />
          <span className="font-semibold">a)</span> the day you sign a credit
          agreement that has already been signed by Close Brothers Premium
          Finance
          <br />
          <br />
          or
          <br />
          <br />
          <span className="font-semibold">b)</span> when you sign the credit
          agreement before Close Brothers Premium Finance your right starts when
          you receive a copy of the agreement signed by both of us.
          <br />
          <br />
          If you wish to withdraw you must give Close Brothers Premium Finance
          notice by one of the following methods:
        </p>
        <ul className="text-small">
          <li>
            by telephone on{' '}
            <a href="tel:03333218566" className="font-semibold">
              0333 321 8566
            </a>
          </li>
          <li>
            by e-mail to{' '}
            <a
              href="mailto:customerservicespf@closebrothers.com"
              className="font-semibold"
            >
              customerservicespf@closebrothers.com
            </a>
            ; or
          </li>
          <li>
            by post or delivery to Close Brothers Premium Finance, Wimbledon
            Bridge House, 1 Hartfield Road, London, SW19 3RU.
          </li>
        </ul>
        <p className="text-small">
          If you withdraw as described, the credit agreement is treated as if it
          was never entered into and you must repay the amount of credit within
          30 days beginning with the day after the day you notified Close
          Brothers Premium Finance of your intention to withdraw. You will not
          have to pay Close Brothers Premium Finance any interest or charges but
          you will have to make alternative arrangements to pay for your
          insurance.
        </p>

        <p className="text-small mb-0">
          <span className="font-semibold">
            Q: How can I get further information about my credit agreement?
          </span>
          <br />
          <br />
          A: If you have any questions or you would like more information, you
          can call Close Brothers Premium Finance on 0333 321 8566 at any time
          between 9:00am and 5:30pm, Monday to Friday. You can also contact Sure
          Thing! on {phoneNumbers.motor?.mainMenu} or by emailing{' '}
          <a href="mailto:motor@policyexpert.co.uk" className="font-semibold">
            motor@policyexpert.co.uk
          </a>
          .
        </p>
      </div>
    ),
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxMint"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        styling="pt-2.5 pl-7 md:pt-26px md:pl-57px md:pr-24px lg:pt-9 lg:pl-23px max-w-1038"
      />
      <TwoColumnHTMLBanner data={qaCopy} />
    </>
  );
};

export default PaymentTermsAndConditionsMotorCBPage;

export const Head: HeadFC = () => (
  <>
    <title>Payment terms and conditions | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
