import React, { useContext } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { PhoneNumbersContext } from '../../context/PhoneNumbersProvider';
import formatPhoneNumber from '../../helpers/formatPhoneNumber';
import { Section } from '../../pages/contact';
import { pushHecTriageEventToDataLayer } from '../../services/windowService';
import { ImpactSecondaryButton } from '../../styles/sharedStyled';
import { CardButtonLinkType, CardButtonToggleType } from '../../types/button';
import DropShapeCard from '../dropShapeCard/ImpactDropShapeCard';
import {
  CarOptionalsContainer,
  CarOptionalsHeader,
  CarOptionalsText,
  ClaimsPrimaryButton,
  ClaimsSecondaryButton,
  DocDownloadSvg,
  FallBackButtonContainer,
  GridButtonContainer,
  GridButtonLabel,
  GridCardContentContainer,
  GridCardContentWrapper,
  GridCardHeader,
  GridCardText,
  GridClaimsButton,
  GridContentContainer,
  GridHeaderContainer,
  GridHeaderWrapper,
  GridSectionButton,
  GridSectionContainer,
  GridSectionText,
  GridSectionTitle,
  GridSectionTitleWrapper,
  HomeContactContent,
  HomeContactNumberLink,
  LargeTelephoneLink,
  OptionalsCardContainer,
  OptionalsCardPhonelink,
  OptionalsCardText,
  OptionalsCardTextWrapper,
  OptionalsCardWrapper,
  SmallContactContent,
} from './GridWithHeader.style';

export type CardData = {
  header: string;
  content: string | React.JSX.Element;
  contactContent?: string | React.JSX.Element;
  buttons?: Array<CardButtonLinkType | CardButtonToggleType>;
  number?: string;
  callDetails?: string;
  numberDescription?: string;
};

type HelpButton = {
  label: string;
  section: string;
};

export type OptionalsData = {
  text: string;
  phone: string;
};

export type GridWithHeaderData = {
  grid?: number;
  header?: string;
  subHeader: string;
  button: HelpButton;
  attached?: boolean;
  cards: CardData[];
  cardsCar: CardData[];
  optionals: OptionalsData[];
  cardsPet: CardData[];
};

type Props = {
  data: GridWithHeaderData;
  section: Section;
};

const GridWithHeader = ({ data, section }: Props) => {
  const { isMobile, isTablet } = useBreakpoint();
  const phoneNumbers = useContext(PhoneNumbersContext);

  return (
    <GridHeaderContainer
      id={section}
      data-testid={section}
      $attached={data.attached}
    >
      <GridHeaderWrapper>
        <GridSectionTitleWrapper>
          {data.header ? (
            <GridSectionTitle>{data.header}</GridSectionTitle>
          ) : null}
          <GridContentContainer>
            <GridSectionText>{data.subHeader}</GridSectionText>
            <GridSectionButton
              onClick={() => {
                scrollTo(`${data.button.section}`);
              }}
            >
              {data.button.label}
            </GridSectionButton>
          </GridContentContainer>
        </GridSectionTitleWrapper>
        {section === 'home' && (
          <GridSectionContainer $isHome>
            {data.cards?.map((card) => (
              <DropShapeCard
                fullWidth
                className="mb-40px"
                key={card.header}
                largeShape={!isMobile}
              >
                <GridCardContentContainer>
                  <GridCardHeader>{card.header}</GridCardHeader>
                  <GridCardContentWrapper>
                    <GridCardText>{card.content}</GridCardText>
                    {card.buttons && (
                      <>
                        <GridCardText>{card.contactContent}</GridCardText>

                        <GridButtonContainer>
                          {card.buttons.map((button) =>
                            'url' in button ? (
                              button.primary ? (
                                <ClaimsPrimaryButton
                                  href={button.url}
                                  key={button.label}
                                >
                                  {button.label}
                                </ClaimsPrimaryButton>
                              ) : (
                                <ClaimsSecondaryButton
                                  href={button.url}
                                  key={button.label}
                                >
                                  {button.label}
                                </ClaimsSecondaryButton>
                              )
                            ) : (
                              <FallBackButtonContainer key={button.label}>
                                {button.primary ? (
                                  <ClaimsPrimaryButton
                                    onClick={button.handleToggle}
                                    $isFallback
                                  >
                                    {button.label}
                                  </ClaimsPrimaryButton>
                                ) : (
                                  <ImpactSecondaryButton
                                    onClick={button.handleToggle}
                                  >
                                    {button.label}
                                  </ImpactSecondaryButton>
                                )}
                              </FallBackButtonContainer>
                            )
                          )}
                        </GridButtonContainer>
                      </>
                    )}

                    {card.buttons ? (
                      <HomeContactContent>
                        {card.numberDescription}{' '}
                        <HomeContactNumberLink
                          href={`tel:${formatPhoneNumber(card.number)}`}
                          className=""
                          onClick={() => {
                            if (
                              card.number ===
                              phoneNumbers.home?.homeEmergencyCover
                            ) {
                              pushHecTriageEventToDataLayer(
                                'call_hec_outside_triage'
                              );
                            }
                          }}
                        >
                          {card.number}
                        </HomeContactNumberLink>
                      </HomeContactContent>
                    ) : (
                      <>
                        <LargeTelephoneLink
                          href={`tel:${formatPhoneNumber(card.number)}`}
                        >
                          {card.number}
                        </LargeTelephoneLink>
                        {card.contactContent && (
                          <SmallContactContent $hasPadding>
                            {card.contactContent}
                          </SmallContactContent>
                        )}
                      </>
                    )}

                    {card.callDetails && (
                      <SmallContactContent $hasPadding $isKeyCover>
                        {card.callDetails}
                      </SmallContactContent>
                    )}
                  </GridCardContentWrapper>
                </GridCardContentContainer>
              </DropShapeCard>
            ))}
          </GridSectionContainer>
        )}
        {section === 'car' && (
          <>
            <GridSectionContainer>
              {data.cardsCar &&
                data.cardsCar.map((card, index) => (
                  <DropShapeCard
                    fullWidth
                    key={card.header}
                    largeShape={!isMobile}
                    className={`${index === 0 && 'mb-40px lg:mb-0'}`}
                  >
                    <GridCardContentContainer>
                      <GridCardHeader>{card.header}</GridCardHeader>
                      <GridCardContentWrapper>
                        <GridCardText>{card.content}</GridCardText>
                        <LargeTelephoneLink
                          href={`tel:${formatPhoneNumber(card.number)}`}
                        >
                          {card.number}
                        </LargeTelephoneLink>
                        {card.contactContent && (
                          <SmallContactContent $hasPadding>
                            {card.contactContent}
                          </SmallContactContent>
                        )}
                      </GridCardContentWrapper>
                    </GridCardContentContainer>
                  </DropShapeCard>
                ))}
            </GridSectionContainer>
            {data.optionals[0].phone !== 'undefined' && (
              <CarOptionalsContainer>
                <CarOptionalsHeader>Optional extras</CarOptionalsHeader>
                <CarOptionalsText>Lines open 24hrs a day</CarOptionalsText>
                <OptionalsCardContainer>
                  {isMobile || isTablet ? (
                    <DropShapeCard fullWidth largeShape={!isMobile}>
                      <OptionalsCardWrapper $isNotDesktop>
                        {data.optionals.map((card) => (
                          <OptionalsCardTextWrapper key={card.text}>
                            <OptionalsCardText>{card.text}</OptionalsCardText>
                            <OptionalsCardPhonelink
                              href={`tel:${formatPhoneNumber(card.phone)}`}
                            >
                              {card.phone}
                            </OptionalsCardPhonelink>
                          </OptionalsCardTextWrapper>
                        ))}
                      </OptionalsCardWrapper>
                    </DropShapeCard>
                  ) : (
                    data.optionals.map((card) => (
                      <DropShapeCard claimsCard key={card.phone}>
                        <OptionalsCardWrapper>
                          <OptionalsCardText $isDesktop>
                            {card.text}
                          </OptionalsCardText>
                          <OptionalsCardPhonelink
                            $isDesktop
                            href={`tel:${formatPhoneNumber(card.phone)}`}
                          >
                            {card.phone}
                          </OptionalsCardPhonelink>
                        </OptionalsCardWrapper>
                      </DropShapeCard>
                    ))
                  )}
                </OptionalsCardContainer>
              </CarOptionalsContainer>
            )}
          </>
        )}
        {section === 'pet' && (
          <GridSectionContainer>
            {data.cardsPet &&
              data.cardsPet.map((card, index) => (
                <DropShapeCard
                  fullWidth
                  key={card.header}
                  largeShape={!isMobile}
                  className={`${index === 0 && 'mb-40px lg:mb-0'}`}
                >
                  <GridCardContentContainer>
                    <GridCardHeader $isPet>{card.header}</GridCardHeader>
                    <GridCardContentWrapper>
                      {card.content}

                      {card.buttons && (
                        <>
                          <GridButtonContainer $isPet>
                            {card.buttons.map(
                              (button) =>
                                'url' in button && (
                                  <>
                                    <GridClaimsButton
                                      href={button.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <DocDownloadSvg />
                                      <GridButtonLabel>
                                        {button.label}
                                      </GridButtonLabel>
                                    </GridClaimsButton>
                                  </>
                                )
                            )}
                          </GridButtonContainer>
                        </>
                      )}

                      {card.header === 'Pet loss support service' ? (
                        <>
                          <LargeTelephoneLink
                            href={`tel:${formatPhoneNumber(card.number)}`}
                            data-testid="pet-loss-support-number"
                          >
                            {card.number}
                          </LargeTelephoneLink>

                          <SmallContactContent>
                            {card.contactContent}
                          </SmallContactContent>
                        </>
                      ) : (
                        <>{card.contactContent}</>
                      )}
                    </GridCardContentWrapper>
                  </GridCardContentContainer>
                </DropShapeCard>
              ))}
          </GridSectionContainer>
        )}
      </GridHeaderWrapper>
    </GridHeaderContainer>
  );
};

export default GridWithHeader;
