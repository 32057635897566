import React, { useEffect, useState } from 'react';

import { HeadFC, navigate } from 'gatsby';

import ContactTable from '../components/contactTable/ContactTable';
import {
  ManagePolicyTab,
  QuestionsTab,
} from '../components/contactTable/HomeTabs';
import ImpactButtonsV2HeaderBanner from '../components/headerBanner/ImpactButtonsV2HeaderBanner';
import ProductButtonsBanner from '../components/productButtonsBanner/ProductButtonsBanner';
import SlantedBackground from '../components/slantedBackground/SlantedBackground';
import useDisclaimer from '../hooks/useDisclaimer';
import usePetChanges from '../hooks/usePetChanges';
import useProductSelection from '../hooks/useProductSelection';
import useQueryParams from '../hooks/useQueryParams';

export type Section = 'home' | 'car' | 'pet' | '';

const homeTableData = [
  { title: 'Manage your policy online', content: ManagePolicyTab },
  { title: 'Got a question?', content: QuestionsTab },
];

const heroBannerData = {
  title: (
    <h1 className=" text-pxBlack text-fontSize-30 md:text-fontSize-35 lg:text-fontSize-50 font-medium mb-15px md:mb-25px lg:mb-30px text-center">
      Let's talk
    </h1>
  ),
  text: (
    <p className="text-pxPurple text-fontSize-18 lg:text-fontSize-25 text-center font-normal mb-50px md:mb-40px">
      Please select your product
    </p>
  ),
};

const ContactPage: React.FC = () => {
  useDisclaimer(null);
  const isPetChangesEnabled = usePetChanges();
  const { product } = useQueryParams();

  const renderAllProductSelection = useProductSelection(['home', 'car', 'pet']);
  const renderTwoProductSelection = useProductSelection(['home', 'car']);
  const [section, toggleSection] = useState<Section>('');

  const handlePathUpdate = (product: Section) => {
    navigate(`/contact/?product=${product}`);
  };

  useEffect(() => {
    if (product === 'home' || product === 'car' || product === 'pet') {
      toggleSection(product);
    } else {
      toggleSection('');
    }
  }, [product]);

  return (
    <div className="overflow-hidden">
      <ImpactButtonsV2HeaderBanner
        backgroundColor="bg-pxMint"
        heroBannerData={heroBannerData}
        shapePosition={'bottom-left-claims-header-V2'}
        section={section}
        styling={`m-auto px-24px ${
          section ? 'md:h-347px lg:h-613px' : 'md:h-676px lg:h-743px'
        } lg:p-0`}
      >
        <ProductButtonsBanner
          productSelectionData={
            isPetChangesEnabled
              ? renderAllProductSelection
              : renderTwoProductSelection
          }
          toggleSection={handlePathUpdate}
          section={section}
        />
      </ImpactButtonsV2HeaderBanner>
      {section !== '' && (
        <>
          <SlantedBackground
            backgroundColor="bg-pxPurple"
            margin="mb-1-minus"
            slantDirection="top-left"
            height
            id={section}
          />
          <ContactTable data={homeTableData} section={section} />
        </>
      )}
    </div>
  );
};

export default ContactPage;

export const Head: HeadFC = () => (
  <>
    <title>Contact us</title>
    <meta
      name="description"
      content="Find out how to contact Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
