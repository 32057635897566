import { styled } from 'styled-components';

const ProductButton = styled.div`
  display: inline-block;

  &.home {
    width: 33px;
    height: 35px;

    @media (min-width: 768px) {
      width: 28px;
      height: 30px;
    }

    @media (min-width: 1024px) {
      width: 37px;
      height: 40px;
    }
  }

  &.car {
    width: 50px;
    height: 25px;

    @media (min-width: 768px) {
      width: 43px;
      height: 22px;
    }

    @media (min-width: 1024px) {
      width: 68px;
      height: 35px;
    }
  }

  &.pet {
    width: 50px;
    height: 23px;

    @media (min-width: 768px) {
      width: 47px;
      height: 22px;
    }

    @media (min-width: 1024px) {
      width: 75px;
      height: 35px;
    }
  }
`;

export default ProductButton;
