import { css, styled } from 'styled-components';

import Monthly from '../../../static/svgIcons/Monthly.svg';
import PetCallCentre from '../../../static/svgIcons/PetCallCentre.svg';
import PetClaims from '../../../static/svgIcons/PetClaims.svg';
import PetExperts from '../../../static/svgIcons/PetExperts.svg';
import PetRatedNo1 from '../../../static/svgIcons/PetRatedNo1.svg';
import Vet from '../../../static/svgIcons/Vet.svg';
import { colors, fontFamilyCamptonBook } from '../../styles/sharedStyled';

export const iconsBannerIconSize = css`
  height: 80px;
  width: 77px;
  flex-shrink: 0;
  align-self: flex-start;

  @media (min-width: 1024px) {
    height: 90px;
    width: 86px;
    flex-shrink: auto;
    align-self: center;
  }
`;

export const IconBannerBackgroundContainer = styled.div<{
  $backgroundColor: string;
  $hasImage: boolean;
}>`
  width: 100vw;
  position: relative;
  text-align: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor || ''};
  margin-bottom: -34px;
  padding: 60px 24px ${({ $hasImage }) => ($hasImage ? '344px' : '95px')};

  @media (min-width: 768px) {
    margin-bottom: -79px;
    padding: 100px 40px 180px;
  }

  @media (min-width: 1024px) {
    padding: 130px 0 210px;
  }
`;

export const IconBannerInformationWrapper = styled.div`
  z-index: 20;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 700px;
  }

  @media (min-width: 1024px) {
    max-width: 1178px;
  }
`;

export const IconBannerHeader = styled.h2`
  font-size: 35px;
  line-height: normal;

  @media (min-width: 768px) {
    font-size: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 65px;
  }
`;

export const IconBannerText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  margin-bottom: 0;
  font-size: 18px;
  line-height: normal;
`;

export const IconBannerMoreInfo = styled.span`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
  line-height: normal;
  color: ${colors.pink2};
  margin: 0;
  cursor: pointer;

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

export const IconBannerSmallText = styled.span`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  margin-bottom: 0;
  font-size: 14px;
  line-height: normal;
`;

export const IconBannerGrid = styled.div<{ $hasImage: boolean }>`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 30px;
  padding: 50px 0 30px;

  @media (min-width: 768px) {
    align-items: flex-start;
    padding: 70px 0 0;
    width: ${({ $hasImage }) => ($hasImage ? '50%' : '100%')};
    ${({ $hasImage }) =>
      !$hasImage &&
      'grid-template-columns: repeat(2, minmax(0, 1fr)); row-gap:40px; column-gap:50px;'}
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(
      ${({ $hasImage }) => ($hasImage ? 2 : 3)},
      minmax(0, 1fr)
    );
    gap: ${({ $hasImage }) => ($hasImage ? '60px' : '50px')};
    padding: 90px 0 0;
    width: auto;
  }
`;

export const MonthlyIcon = styled(Monthly)`
  ${iconsBannerIconSize}
`;

export const PetCallCentreIcon = styled(PetCallCentre)`
  ${iconsBannerIconSize}
`;

export const PetClaimsIcon = styled(PetClaims)`
  ${iconsBannerIconSize}
`;

export const PetExpertsIcon = styled(PetExperts)`
  ${iconsBannerIconSize}
`;

export const PetRatedNo1Icon = styled(PetRatedNo1)`
  ${iconsBannerIconSize}
`;

export const VetBackgroundIcon = styled(Vet)`
  ${iconsBannerIconSize}
`;

export const IconBannerInformationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: 1024px) {
    max-width: 270px;
    margin: 0 auto;
    flex-direction: column;
  }
`;

export const IconBannerTextContainer = styled.div`
  text-align: left;

  @media (min-width: 1024px) {
    margin-top: 20px;
    text-align: center;
  }
`;

export const InfoBannerContent = styled.div<{ $hasImage: boolean }>`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 88px;
  }

  @media (min-width: 1024px) {
    justify-content: ${({ $hasImage }) =>
      $hasImage ? 'flex-start' : 'center'};
    gap: 85px;
    height: ${({ $hasImage }) => ($hasImage ? ' 747px' : 'auto')};
  }
`;

export const ImagePlaceholder = styled.div`
  height: 400px;
  width: 169px;
  bottom: -55px;
  position: absolute;
  z-index: 101;

  @media (min-width: 768px) {
    height: 548px;
    width: 230px;
    top: 145px;
    position: relative;
  }

  @media (min-width: 1024px) {
    height: 900px;
    width: 380px;
    top: 125px;
  }
`;

export const ImageContainer = styled.div`
  height: 400px;
  width: 169px;
  bottom: -55px;
  position: absolute;
  z-index: 101;

  @media (min-width: 768px) {
    height: 548px;
    width: 230px;
    top: 145px;
    position: relative;
  }

  @media (min-width: 1024px) {
    height: 900px;
    width: 380px;
    top: 125px;
  }
`;
