import React from 'react';

import HomepageArrow from '../../../static/svgIcons/HomepageArrow.svg';
import { InsuranceCardImpactV2Data } from './InsuranceCardImpactV2';
import {
  ArrowContainer,
  ComingSoonSvg,
  IconTextContainer,
  InsuranceCardHeader,
  MobileInsuranceCardButton,
} from './MobileInsuranceCardImpactV2.style';

type Props = { data: InsuranceCardImpactV2Data };

const MobileInsuranceCardImpactV2 = ({ data }: Props) => (
  <MobileInsuranceCardButton
    to={data.link1Url}
    key={`carousel-${data.heading}-InsuranceCard`}
    data-testid={`${data.testId}-mobile-insurance-card`}
  >
    <IconTextContainer>
      {data.icon}
      <InsuranceCardHeader>{data.heading}</InsuranceCardHeader>
    </IconTextContainer>
    {data.isComingSoon && <ComingSoonSvg />}
    <ArrowContainer>
      <HomepageArrow />
    </ArrowContainer>
  </MobileInsuranceCardButton>
);

export default MobileInsuranceCardImpactV2;
