import React, { useState } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import config from '../../config';
import BusinessLineButtons from '../businessLineButtons/BusinessLineButtons';
import PolicyDocuments, {
  PolicyDocumentsType,
  ProductName,
} from '../policyDocuments/PolicyDocuments';
import Shape from '../Shape';
import {
  CurrentVersionText,
  DocumentInformation,
  ExistingCustomerText,
  LoginContainer,
  LoginLink,
  MainContainer,
  MainHeader,
  PolicyBooksWrapper,
  ProductHeader,
} from './PolicyBooks.style';

export type PolicyDocumentsData = {
  productName: ProductName;
  productTypeNames: string[];
  productTypeData: { [key: string]: PolicyDocumentsType[] };
};

type Props = {
  documentsData: PolicyDocumentsData;
};

const PolicyBooks = ({ documentsData }: Props) => {
  const { isDesktop } = useBreakpoint();
  const [selectedTab, setSelectedTab] = useState(
    documentsData.productTypeNames[0]
  );
  const hasMultipleProducts = documentsData.productTypeNames.length > 1;

  return (
    <PolicyBooksWrapper>
      <MainContainer>
        <MainHeader>
          {documentsData.productName} insurance policy wording
        </MainHeader>
        <LoginContainer>
          <CurrentVersionText>
            Below are our current versions
          </CurrentVersionText>
          <ExistingCustomerText>
            Existing customers please{' '}
            <LoginLink
              href={
                documentsData.productName === 'Car'
                  ? config.CAR_LOGIN_URL
                  : config.HOME_LOGIN_URL
              }
            >
              login
            </LoginLink>{' '}
            to view your policy documents.
          </ExistingCustomerText>
        </LoginContainer>
        <DocumentInformation>
          Before you buy an insurance policy, it's important you understand what
          you’re buying. The Insurance Product Information Document gives an
          overview of key covers and exclusions. The Policy Wording is the full
          contract document.
        </DocumentInformation>
        {hasMultipleProducts && (
          <ProductHeader>Please select your product</ProductHeader>
        )}
        {documentsData.productTypeNames.length > 1 && (
          <BusinessLineButtons
            selectedTab={selectedTab}
            productNames={documentsData.productTypeNames}
            productName={documentsData.productName}
            onClick={setSelectedTab}
          />
        )}
        <PolicyDocuments
          products={documentsData.productTypeData[selectedTab]}
        />
      </MainContainer>
      {isDesktop && <Shape position="right-policy-books" />}
      {isDesktop && <Shape position="left-policy-books" />}
    </PolicyBooksWrapper>
  );
};

export default PolicyBooks;
