import React, { useContext } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import TrustPilotNoStars from '../../../static/svgIcons/TrustPilotNoStars.svg';
import { ReviewsContext } from '../../context/ReviewsProvider';
import Carousel from '../carousel/Carousel';
import { HeaderWithCarouselDataType } from '../headerWithCarousel/HeaderWithCarousel';

type Props = {
  data: HeaderWithCarouselDataType;
  cards: any;
};

const NewProductCarousel = ({ data, cards }: Props) => {
  const { isDesktop } = useBreakpoint();

  const trustpilotReviews = useContext(ReviewsContext);
  const isHighTrustScore =
    parseFloat(trustpilotReviews.score?.trustScore) >= 4.8;

  return (
    <>
      <div className="bg-pxGrey py-82px md:py-130px md:-mt-165px mt-100-minus">
        <div className="padding-x flex justify-center align-middle md:mt-40px">
          <h2 className="mt-82px md:max-w-1/2">{data.header}</h2>
        </div>

        <div>
          <Carousel
            cards={cards}
            pagination={{ color: 'white', arrows: true }}
            offsetfirstslide
            opacityOffset={isDesktop ? 5 : 3}
          />

          {trustpilotReviews.score && trustpilotReviews.numberOfReviews ? (
            <div className="padding-x pt-32px pb-82px flex flex-col-reverse md:flex-row justify-center items-center">
              <div className="md:mr-32px mr-0 mt-32px md:mt-0">
                <TrustPilotNoStars
                  className="icons-primary6 z-50 flex"
                  fill={isHighTrustScore ? '#00b67a' : '#dcdce6'}
                />
              </div>
              <p className="text-small text-center md:text-left mb-0">
                {trustpilotReviews.score.trustScore}/5 from over{' '}
                {trustpilotReviews.numberOfReviews.total.toLocaleString()}{' '}
                reviews. 96.7% of users recommend us.
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default NewProductCarousel;
