import { styled } from 'styled-components';

import { fontFamilyCamptonBook } from '../../styles/sharedStyled';

export const CoverText = styled.p<{
  $marginTop?: boolean;
}>`
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
  margin-top: ${({ $marginTop }) => ($marginTop ? '10px' : '0')};
`;

export const CoverList = styled.ul``;

export const CoverListItem = styled.li`
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  line-height: 19px;
  list-style-position: outside;
  margin: 0 0 5px 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CoverLink = styled.a``;
