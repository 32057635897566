import { Link } from 'gatsby';
import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonSemiBold,
} from '../styles/sharedStyled';

export const TermsText = styled.p<{
  hasPurpleText?: boolean;
  hasMarginTop?: boolean;
}>`
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 20px;
  color: ${({ hasPurpleText }) => hasPurpleText && colors.purple7};
  margin-top: ${({ hasMarginTop }) => hasMarginTop && '30px'};

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    margin: ${({ hasMarginTop }) => hasMarginTop && '0 0 40px 0'};
  }
`;

export const TermsListWrapper = styled.ul`
  margin-bottom: 20px;
`;

export const NumberedTermsListWrapper = styled.ol`
  margin-bottom: 20px;
`;

export const TermsListData = styled.li`
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  list-style-position: outside;
  margin: 0 0 0 25px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const TermsInternalLink = styled(Link)`
  margin-bottom: 0;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0;
  font-weight: 600;
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const TermsExternalLink = styled.a`
  margin-bottom: 0;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0;
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const TermsTelephoneLink = styled.a`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  color: ${colors.purple7};
  font-family: ${fontFamilyCamptonBook};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const TermsTextHeader = styled.p`
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const TermsContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TermsContactUsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TermsContactUsText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  min-width: 90px;
  margin: 0 15px 0 0;
`;

export const SectionTextWrapper = styled.div`
  padding: 0 24px;

  @media (min-width: 768px) {
    padding: 0 40px;
  }

  @media (min-width: 1024px) {
    padding: 0 30px;
  }
`;
