import React, { useEffect, useState } from 'react';

import { Link } from 'gatsby';

import Carousel from '../carousel/Carousel';

type HeaderWithSideCarouselCardType = {
  header?: string;
  content: string;
  imgName?: string;
};

export type HeaderWithSideCarouselDataType = {
  header: string;
  cards: HeaderWithSideCarouselCardType[];
};

type Props = {
  data: HeaderWithSideCarouselDataType;
};

const HeaderWithSideCarousel = ({ data }: Props) => {
  const [cards, setCards] = useState<any>([]);

  useEffect(() => {
    const cards = data.cards.map((card) => {
      return (
        <div key={card.header}>
          <div className="lg:max-w-3/4">
            <div className="py-5 px-82px bg-white even-round h-full w-full flex justify-center">
              <img
                className="max-h-130 mx-auto"
                src={card.imgName}
                alt={card.imgName}
              />
            </div>
          </div>
          <p className="pt-32px text-small font-semibold">{card.header}</p>

          <p className="text-small mb-0">{card.content}</p>
        </div>
      );
    });
    setCards(cards);
  }, []);

  return (
    <div className="bg-pxGrey py-82px md:py-130px">
      <div className="padding-x flex flex-col md:flex-row">
        <div className="md:w-3/5 left">
          <h2 className="md:max-w-xl">{data.header}</h2>
        </div>

        <div className="md:w-2/5">
          <div className="right">
            <Carousel
              slidesPerView={1}
              cards={cards}
              pag={false}
              pagination={{ color: 'white', arrows: true }}
            />
          </div>
          <Link className="text-pxPink font-semibold" to="/about-us/awards">
            View all awards
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithSideCarousel;
