import React from 'react';

import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

interface CareersNavigationBlockInterface {
  label: string;
  url: string;
  isLast: boolean;
}

const CareersNavigationBlock: React.FC<CareersNavigationBlockInterface> = ({
  label,
  url,
  isLast,
}) => {
  const location = useLocation();
  return (
    <Link
      to={url}
      className={`${isLast ? 'border-r' : ''} 
        ${
          url === location.pathname
            ? 'bg-pxPink text-white border-pxPink '
            : 'text-pxCharcoal hover:border-pxPink hover:text-white hover:bg-pxPink '
        }
        border-l inline-flex align-center items-center transition flex-grow `}
      style={{ height: 60 }}
    >
      <span className={'text-center w-full font-camptonSemiBold pt-1'}>
        {label}
      </span>
    </Link>
  );
};

export default CareersNavigationBlock;
