import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const MainContainer = styled.div<{ $multipleTables: boolean }>`
  background-color: ${colors.charcoal};
  position: relative;
  overflow: hidden;
  margin-bottom: ${({ $multipleTables }) => $multipleTables && '32px'};
  padding: ${({ $multipleTables }) => ($multipleTables ? '0' : '82px 0 64px')};

  @media (min-width: 768px) {
    margin-bottom: ${({ $multipleTables }) => $multipleTables && '100px'};
    padding: ${({ $multipleTables }) =>
      $multipleTables ? '0' : '130px 0 212px'};
  }
`;

export const HeaderAndTableContainer = styled.div<{ $multipleTables: boolean }>`
  position: relative;
  z-index: 20;
  ${({ $multipleTables }) =>
    !$multipleTables && 'padding: 0 24px; max-width: 1440px; margin: 0 auto;'}
`;

export const CarouselContainer = styled.div`
  padding: 0 32px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.white};

  @media (min-width: 768px) {
    padding-bottom: 82px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const HeaderText = styled.h2`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 35px;
  font-weight: 400;
  letter-spacing: -0.87px;
  line-height: 41px;
  margin: 0;
  padding-bottom: 32px;
  padding-right: 82px;

  @media (min-width: 768px) {
    font-size: 45px;
    letter-spacing: -0.9px;
    line-height: 53px;
  }

  @media (min-width: 1024px) {
    font-size: 80px;
    letter-spacing: 1px;
    line-height: 86px;
    padding-bottom: 0;
    width: 50%;
  }
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    width: 50%;
  }
`;

export const PanelRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PanelInformationTitle = styled.p`
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.4px;
  line-height: 16px;
  margin-bottom: 0;
  margin-right: 4px;

  @media (min-width: 768px) {
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 25px;
  }
`;

export const PanelInformationText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.4px;
  line-height: 16px;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 25px;
  }
`;

export const MainTable = styled.table`
  display: table;
  background-color: ${colors.white};
  border-radius: 12px;
  table-layout: fixed;
  position: relative;
  overflow: hidden;
  width: 100%;
`;

export const ColumnHeaderRow = styled.tr<{
  $numberOfColumns: number;
  $isFirstColumnLarge: boolean;
}>`
  background-color: ${colors.gray3};
  display: grid;
  grid-template-columns: ${({ $numberOfColumns, $isFirstColumnLarge }) =>
    `repeat(${
      $isFirstColumnLarge ? $numberOfColumns + 2 : $numberOfColumns
    }, minmax(0, 1fr));`};
`;

export const ColumnTitleContainer = styled.td<{ $isLargeColumn: boolean }>`
  width: 100%;
  border-color: ${colors.white};
  border-right-width: 1px;
  grid-column: ${({ $isLargeColumn }) => $isLargeColumn && 'span 3 / span 3'};
`;

export const ColumnTitle = styled.p`
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.4px;
  text-align: center;
  margin: 0;
  padding: 32px;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
`;

export const TableRow = styled.tr<{
  $numberOfColumns: number;
  $isFirstColumnLarge: boolean;
  $isFirstRowDark: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ $numberOfColumns, $isFirstColumnLarge }) =>
    `repeat(${
      $isFirstColumnLarge ? $numberOfColumns + 2 : $numberOfColumns
    }, minmax(0, 1fr));`};
  color: ${({ $isFirstRowDark }) =>
    $isFirstRowDark ? colors.black : colors.purple7};
`;

export const RowTitleContainer = styled.td<{
  $multipleTables: boolean;
  $isFirstColumnLarge: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  border-color: ${colors.gray3};
  border-width: 1px;
  justify-content: ${({ $multipleTables }) => $multipleTables && 'center'};
  grid-column: ${({ $isFirstColumnLarge }) =>
    $isFirstColumnLarge && 'span 3 / span 3'};
`;

export const RowTitle = styled.p`
  color: ${colors.black};
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.4px;
  margin: 0;
  padding: 0 32px;
`;

export const RowContentContainer = styled.td`
  padding: 32px;
  background-color: ${colors.white};
  border-color: ${colors.gray3};
  border-width: 1px;
`;

export const RowContentText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.4px;
  text-align: center;
  margin: 0;
`;
