import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonLight,
  fontFamilyCamptonRegular,
} from '../../styles/sharedStyled';

export const RenewalDropDownSelect = styled.select<{ $hasError?: boolean }>`
  position: relative;
  font-family: ${fontFamilyCamptonRegular};
  border-width: ${({ $hasError }) => ($hasError ? '2px' : '1px')};
  border-style: solid;
  border-color: ${({ $hasError }) =>
    $hasError ? `${colors.red1}` : `${colors.purple7}`};
  background-color: ${({ $hasError }) =>
    $hasError ? `${colors.red1}` : `${colors.gray2}`};
  appearance: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  border-radius: 5px;
  width: 240px;
  height: 50px;
  box-sizing: border-box;
  padding: 0px 8px;
  cursor: pointer;
  color: ${({ $hasError }) =>
    $hasError ? `${colors.red10}` : `${colors.black}`};

  &:focus {
    ${({ $hasError }) => $hasError && 'outline:none'};
  }
`;

export const Arrow = styled.span`
  pointer-events: none;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 15px;

  @media only screen and (min-width: 768px) {
    right: 10px;
    top: 16px;
  }

  &::before {
    border-style: solid;
    border-width: 3px 3px 0 0;
    border-color: #f04e98;
    content: '';
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 10px;
    width: 10px;
    transform: rotate(135deg);

    @media only screen and (min-width: 768px) {
      width: 12px;
      height: 12px;
    }
  }
`;

export const ErrorTextContainer = styled.div`
  color: ${colors.red10};
  max-width: 240px;
  margin-top: 6px;
  font-family: ${fontFamilyCamptonLight};
`;

export const RenewalDropDownContainer = styled.div`
  position: relative;
`;
