import { styled } from 'styled-components';

import CarSvg from '../../../static/svgIcons/Car.svg';
import HouseSvg from '../../../static/svgIcons/House.svg';
import PetSvg from '../../../static/svgIcons/Pet.svg';
import { colors, fontFamilyCamptonMedium } from '../../styles/sharedStyled';

export const ImpactLinks = styled.a`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: ${fontFamilyCamptonMedium};
  color: ${colors.black};
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 16px;
  height: 65px;
  background-color: ${colors.white};
  border: 2px solid ${colors.gray1};
  padding: 13px 25px;

  @media (min-width: 371px) and (max-width: 767px) {
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 220px;
    height: 60px;
    padding: 13px 18px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    width: 200px;
    height: 116px;
    padding: 18px 20px;
  }
`;

export const ContentsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 200px;

  @media (min-width: 371px) and (max-width: 767px) {
    margin-right: 40px;
  }

  @media (min-width: 1024px) {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: center;
  margin: 0 20px 0 0;

  @media (min-width: 768px) and (max-width: 1023px) {
    width: auto;
    margin: 0 15px 0 0;
  }

  @media (min-width: 1024px) {
    width: auto;
    min-height: 40px;
    margin: 0 0 15px;
  }
`;

export const HouseIcon = styled(HouseSvg)`
  stroke: ${colors.purple7};
  fill: ${colors.purple7};
  width: 50px;
  height: 35px;

  @media (min-width: 768px) {
    width: 28px;
    height: 30px;
  }

  @media (min-width: 1024px) {
    width: 37px;
    height: 40px;
  }
`;

export const CarIcon = styled(CarSvg)`
  width: 50px;
  height: 25px;

  @media (min-width: 768px) {
    width: 43px;
    height: 22px;
  }

  @media (min-width: 1024px) {
    width: 68px;
    height: 35px;
    margin: 2.5px 0;
  }
`;

export const PetIcon = styled(PetSvg)`
  width: 50px;
  height: 36px;

  @media (min-width: 768px) {
    width: 47px;
    height: 22px;
  }

  @media (min-width: 1024px) {
    width: 76px;
    height: 36px;
  }
`;
