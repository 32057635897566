import React from 'react';

type Props = {
  children: React.ReactNode;
  color?: string;
  fullWidth?: boolean;
  background?: string;
  className?: string;
};

const DropShapeCard = ({
  children,
  color,
  background,
  className,
  fullWidth = false,
}: Props) => {
  const backgroundStyle = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  if (background) {
    // @ts-ignore
    backgroundStyle.backgroundImage = `url(${background})`;
  }

  return (
    <>
      <div
        className={`dropShapeCardBlogPost overflow-hidden ${
          fullWidth ? '' : `max-w-240 md:max-w-284 lg:max-w-413`
        } relative  ${color ? color : 'bg-white'} ${className || ''}`}
        style={backgroundStyle}
      >
        {children}
      </div>

      <svg className="DropShapeCardBlogPost">
        <clipPath id="DropShapeCardBlogPost" clipPathUnits="objectBoundingBox">
          <path d="M0.87,0.871 C0.807,0.882,0.073,1,0.073,1 A0.073,0.108,0,0,1,0,0.892 V0.207 A0.139,0.207,0,0,1,0.139,0 H0.861 A0.139,0.207,0,0,1,1,0.207 V0.672 C1,0.786,0.933,0.861,0.87,0.871"></path>
        </clipPath>
      </svg>
    </>
  );
};

export default DropShapeCard;
