import React from 'react';

const termsData = [
  'Entrants must be aged 16 or over.',
  'Entrants must be a UK resident (excludes Northern Ireland).',
  'The competition will run from Tuesday 5th December until Friday 8th December 2023.',
  'Employees or family members of Policy Expert, agencies, or anyone else connected with the competition may not enter.',
  'Only one entry per person.',
  'The winner will be chosen at random and contacted via Facebook.',
  'To enter, entrants must have commented on our social post to tell us what makes Christmas special to them. Deadline for entry is 23:59:59 on 7th December. The winner will be announced on the 8th December after 11:00:00 and will receive £500 in cash via bank transfer.',
  'Entries received after the above deadline times will not be considered.',
  'If the winner does not respond within 7 days of being notified, the winner’s prize will be forfeited, and another winner will be selected in accordance with the process described above (that winner will have 7 days to respond to notification of their win or they will also forfeit the prize).',
  'The prize is non-exchangeable, non-transferable.',
  'By entering the competition, you agree to the collection, retention, usage and distribution of your personal information to process and contact you about your competition entry. The winner will be announced on our social media accounts.',
  'This promotion is not sponsored, endorsed or administered by anyone other than Policy Expert.',
  'Please allow up to 5 working days for receipt of the funds via bank transfer.',
  'The winner must have a personal UK bank account, in their own name.',
  'Entrants to the competition will be deemed to have accepted these terms and conditions.',
];
const TermsList = () => (
  <ol className="mb-100px">
    {termsData.map((item) => (
      <li className="mb-0 text-fontSize-16" key={item}>
        {item}
      </li>
    ))}
  </ol>
);

export default TermsList;
