import React from 'react';

import Shape, { ShapeProps } from '../Shape';
import ShapeX, { ShapePosition } from '../ShapeX';
import {
  MessageHeaderBannerContainer,
  MessageHeaderBannerDataContainer,
  MessageHeaderBannerText,
  MessageHeaderBannerTitle,
} from './MessageHeaderBanner.style';

type MessageHeaderData = {
  title: string;
  message: string | React.ReactNode;
};

export type StyleProps = {
  mobile: string;
  tablet: string;
  desktop: string;
};

export type MessageHeaderBannerProps = {
  backgroundColor: string;
  height: StyleProps;
  marginTop: StyleProps;
  shapePosition1?: ShapeProps['position'];
  shapePosition2?: ShapeProps['position'];
  shapeXPosition?: ShapePosition;
  data: MessageHeaderData;
};

const MessageHeaderBanner = ({
  backgroundColor,
  height,
  marginTop,
  shapePosition1,
  shapePosition2,
  shapeXPosition,
  data,
}: MessageHeaderBannerProps) => (
  <MessageHeaderBannerContainer
    $backgroundColor={backgroundColor}
    $height={height}
  >
    <MessageHeaderBannerDataContainer $marginTop={marginTop}>
      <MessageHeaderBannerTitle>{data.title}</MessageHeaderBannerTitle>
      <MessageHeaderBannerText>{data.message}</MessageHeaderBannerText>
    </MessageHeaderBannerDataContainer>
    {shapeXPosition && <ShapeX position={shapeXPosition} />}
    {shapePosition1 && <Shape position={shapePosition1} />}
    {shapePosition2 && <Shape position={shapePosition2} />}
  </MessageHeaderBannerContainer>
);
export default MessageHeaderBanner;
