import { css, styled } from 'styled-components';

import PlusIcon from '../../../static/svgIcons/home-emergency-plus.svg';
import SelectIcon from '../../../static/svgIcons/home-emergency-select.svg';
import InfoClose from '../../../static/svgIcons/InfoClose.svg';
import InfoOpen from '../../../static/svgIcons/InfoOpen.svg';
import Tick from '../../../static/svgIcons/TickAngles.svg';
import Cross from '../../../static/svgIcons/xTablePink.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

const headerIconSize = css`
  width: 76px;
  height: 60px;
`;

export const PolicyExpertSelect = styled(SelectIcon)`
  ${headerIconSize}
`;

export const PolicyExpertPlus = styled(PlusIcon)`
  ${headerIconSize}
`;

export const CoverDetailContainer = styled.div`
  padding: 20px 15px;

  @media (min-width: 768px) {
    padding: 20px 30px;
  }
`;

export const CrossIcon = styled(Cross)`
  height: 12px;
  width: 14px;
`;

export const TickIcon = styled(Tick)`
  height: 12px;
  width: 17px;
`;

export const InfoOpenIcon = styled(InfoOpen)`
  height: 16px;
  width: 16px;
`;

export const InfoCloseIcon = styled(InfoClose)`
  height: 16px;
  width: 16px;
`;

export const InfoIconContainer = styled.div`
  margin-bottom: 3px;

  @media (min-width: 768px) {
    margin-right: 10px;
  }
`;

export const CoverDetailSection = styled.div``;

export const CoverDetailTitle = styled.h4<{ $isPink?: boolean }>`
  font-family: ${fontFamilyCamptonSemiBold};
  color: ${({ $isPink }) => ($isPink ? colors.pink2 : colors.black)};
  margin-top: ${({ $isPink }) => ($isPink ? '25px' : 0)};
  font-size: 15px;
  line-height: normal;
  margin-bottom: 10px;
`;

export const CoverText = styled.p<{
  $marginTop?: boolean;
}>`
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  line-height: normal;
  margin-bottom: 5px;
  margin-top: ${({ $marginTop }) => ($marginTop ? '10px' : '0')};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CoverList = styled.ul``;

export const CoverListItem = styled.li`
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  line-height: normal;
  list-style-position: outside;
  margin: 0 0 5px 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const HecTableLabel = styled.p`
  font-family: inherit;
  color: inherit;
  font-size: 15px;
  margin: 0 0 0 16px;
  flex-grow: 1;
  line-height: normal;

  @media (min-width: 768px) {
    flex-grow: 0;
    margin: 0;
  }
`;

export const HecTableValue = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  color: ${colors.purple7};
  margin: 0;
  line-height: normal;
`;

export const TableContainer = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    max-width: 900px;
    margin: 0 auto;
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  border-radius: 10px 10px 0 0;
  box-shadow: 0 5px 5px 0 rgba(51, 51, 51, 0.12);

  @media only screen and (min-width: 768px) {
    max-width: 360px;
    justify-content: flex-end;
    margin-right: 0;
    border-radius: 20px 20px 0 0;
    box-shadow: none;
  }
`;

export const HeaderCellWrapper = styled.div`
  width: 100px;
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;

  @media (min-width: 768px) {
    padding: 35px 0;
    border-bottom: 1px solid ${colors.gray3};
  }

  &:not(:last-child) {
    border-right: 1px solid ${colors.purple7Alpha30};

    @media (min-width: 768px) {
      border-right: 1px solid ${colors.gray3};
    }
  }
`;

export const TableRowsContainer = styled.div`
  overflow: hidden;
  background-color: ${colors.white};
  border-radius: 0 0 10px 10px;
  border-bottom: 1px solid ${colors.gray3};

  @media (min-width: 768px) {
    border-radius: 20px 0 20px 20px;
  }

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

export const Row = styled.div<{ $isExpanded?: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  box-shadow: 0 5px 5px 0 rgba(51, 51, 51, 0.12);

  @media (min-width: 768px) {
    flex-wrap: ${({ $isExpanded }) => ($isExpanded ? 'wrap' : 'nowrap')};
    box-shadow: none;
    border: 0;
  }

  @media (min-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export const RowContainer = styled.div<{ $isExpanded?: boolean }>`
  border-bottom: ${({ $isExpanded }) =>
    $isExpanded ? `1px solid ${colors.purple7}` : 0};
  border-top: ${({ $isExpanded }) =>
    $isExpanded ? `1px solid ${colors.purple7}` : 0};

  @media (min-width: 768px) {
    border-top: 0;
    border-bottom: 1px solid ${colors.gray3};
  }
  &:last-child {
    border-bottom: 0;
  }
`;

const Cell = styled.div<{ $isExpanded?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  width: 100px;
  flex: 1;
  padding: 15px 20px;
  background-color: ${({ $isExpanded }) =>
    $isExpanded ? colors.purple7Alpha5 : 'inherit'};

  @media (min-width: 768px) {
    min-width: 180px;
  }
`;

export const LabelCell = styled(Cell)`
  font-family: ${({ $isExpanded }) =>
    $isExpanded ? fontFamilyCamptonSemiBold : fontFamilyCamptonMedium};
  color: ${({ $isExpanded }) => ($isExpanded ? colors.purple7 : colors.black)};
  flex-basis: 100%;
  text-align: center;
  justify-content: center;
  padding: 0;
  border-bottom: ${({ $isExpanded }) =>
    $isExpanded ? 0 : `1px solid ${colors.gray3}`};

  @media (min-width: 768px) {
    font-family: ${({ $isExpanded }) =>
      $isExpanded ? fontFamilyCamptonSemiBold : fontFamilyCamptonBook};
  }

  @media (min-width: 768px) {
    margin-right: auto;
    text-align: left;
    justify-content: flex-start;
    padding: 20px 30px 0;
    border-right: 1px solid ${colors.gray3};
    border-bottom: 0;
    flex-basis: 40%;
  }

  @media (min-width: 1024px) {
    flex-basis: 50%;
  }
`;

export const LabelContainer = styled.div<{ $isExpanded?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 0;
  margin: 0 20px;
  border-bottom: ${({ $isExpanded }) =>
    $isExpanded ? `2px solid ${colors.white}` : 0};

  @media (min-width: 768px) {
    margin: 0;
    padding: 0 0 20px 0;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

export const DetailsCell = styled(Cell)`
  flex-basis: 100%;
  padding: 0;
  text-align: left;
  border-bottom: 1px solid ${colors.gray3};
  display: ${({ $isExpanded }) => ($isExpanded ? 'flex' : 'none')};
  justify-content: flex-start;

  @media (min-width: 768px) {
    order: 4;
    flex-grow: 1;
    border-bottom: 0;
  }
`;

export const ValueCell = styled(Cell)`
  @media (min-width: 768px) {
    max-width: 180px;
    background-color: ${colors.white};
    border-bottom: ${({ $isExpanded }) =>
      $isExpanded ? `1px solid ${colors.gray3}` : 0};
  }

  &:nth-child(3) {
    border-right: 1px solid ${colors.purple7Alpha30};

    @media (min-width: 768px) {
      border-right: 1px solid ${colors.gray3};
      background-color: ${colors.white};
    }
  }
`;

export const InfoRow = styled.div`
  position: relative;
  text-align: center;
  padding: 15px 20px;
  background-color: ${colors.purple7};

  @media (min-width: 768px) {
    padding: 20px 30px;
    text-align: left;
  }
`;

export const InfoRowText = styled.p`
  margin: 0;
  font-family: ${fontFamilyCamptonSemiBold};
  color: ${colors.white};
  font-size: 15px;
  line-height: 15px;
`;
