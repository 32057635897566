import React from 'react';

type Props = {
  children?: React.ReactNode;
  color?: string;
  className?: string;
};

const DropShapeCardReverseRight = ({ children, color, className }: Props) => (
  <>
    <div
      className={`dropShapeCardReverseRight block overflow-hidden ${className} ${
        color ? color : 'bg-white'
      }`}
    >
      {children}
    </div>
    <svg className="DropShapeCardReverseRight h-0">
      <clipPath
        id="DropShapeCardReverseRight"
        clipPathUnits="objectBoundingBox"
      >
        <path
          transform="scale(-1, 1) translate(-1, 0)"
          d="M0.898,1 H0.102 C0.075,1,0.049,0.99,0.03,0.973 C0.011,0.955,0,0.932,0,0.907 V0.093 C0,0.068,0.011,0.045,0.03,0.027 C0.049,0.01,0.075,0,0.102,0 C0.11,0,0.118,0.001,0.126,0.003 L0.923,0.185 C0.945,0.19,0.965,0.201,0.979,0.218 C0.992,0.234,1,0.254,1,0.275 V0.907 C1,0.932,0.989,0.955,0.97,0.973 C0.951,0.99,0.925,1,0.898,1"
        ></path>
      </clipPath>
    </svg>
  </>
);

export default DropShapeCardReverseRight;
