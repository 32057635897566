import React from 'react';

import { HeadFC, Link, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../components/headerBanner/HeaderBanner';
import SiteMapBanner, {
  SiteMapBannerType,
} from '../components/siteMapBanner/SiteMapBanner';
import config from '../config';
import useDisclaimer from '../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../images/site_map_sm.png`}
        alt={`Site map`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../images/site_map_md.png`}
        alt={`Site map`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../images/site_map_lg.png`}
      alt={`Site map`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const siteMapData: SiteMapBannerType = {
  bgColor: 'bg-pxGrey',
  heading: 'Products',
  body: (
    <div className="flex flex-col md:flex-row">
      <div className="flex flex-col md:w-1/3">
        <p className="font-semibold text-small">
          <a href={config.HOME_URL}>Home insurance quote</a>
        </p>
        <p className="font-semibold text-small">
          <Link to="/home-insurance/policy-documents">
            Home insurance documents
          </Link>
        </p>
        <p className="font-semibold text-small">
          <Link to="/home-insurance">Home insurance</Link>
        </p>
      </div>
      <div className="flex flex-col md:w-1/3">
        <p className="font-semibold text-small">
          <a href={config.CAR_URL}>Car insurance quote</a>
        </p>
        <p className="font-semibold text-small">
          <Link to="/car-insurance/policy-documents">
            Car insurance documents
          </Link>
        </p>
        <p className="font-semibold text-small">
          <Link to="/car-insurance">Car insurance</Link>
        </p>
      </div>
    </div>
  ),
  bottomLinks: [
    {
      heading: 'Support',
      body: [
        {
          body: 'Help Centre',
          url: config.HELP_CENTRE_URL,
        },
        {
          body: 'Contact',
          url: '/contact',
        },
        {
          body: 'How to complain',
          url: '/how-to-complain',
        },
      ],
    },
    {
      heading: 'About us',
      body: [
        {
          body: 'About us',
          url: '/about-us',
        },
        {
          body: 'Media',
          url: '/about-us/media',
        },
        {
          body: 'Careers',
          url: '/about-us/careers',
        },
      ],
    },
    {
      heading: 'Useful info',
      body: [
        {
          body: 'Terms and conditions',
          url: '/terms-and-conditions',
        },
        {
          body: 'Privacy policy',
          url: '/privacy-policy',
        },
        {
          body: 'Cookie policy',
          url: '/cookie-policy',
        },
      ],
    },
  ],
};

const SiteMapPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxMint"
        headerImage={headerImage}
        styling="pt-26px pl-34px md:pt-30px md:px-91px lg:pt-9 lg:px-6 lg:max-w-1031"
      />
      <SiteMapBanner data={siteMapData} />
    </>
  );
};

export default SiteMapPage;

export const Head: HeadFC = () => (
  <>
    <title>Sitemap | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
