import React, { useMemo } from 'react';

import config from '../../config';
import usePetChanges from '../../hooks/usePetChanges';
import InsuranceCard from '../insuranceCard/InsuranceCard';

type Props = {
  marginTop: string;
};

const CtaBanner = ({ marginTop }: Props) => {
  const showPetChanges = usePetChanges();

  const cards = useMemo(() => {
    return [
      {
        heading: 'Home Insurance',
        headingMobile: 'Home',
        ctaUrl: config.HOME_URL,
        link1text: 'Find out more',
        link1Url: '/home-insurance',
        link2text: 'Retrieve quote',
        link2Url: config.HOME_LOGIN_URL,
      },
      {
        heading: 'Car Insurance',
        headingMobile: 'Car',
        ctaUrl: config.CAR_URL,
        link1text: 'Find out more',
        link1Url: '/car-insurance',
        link2text: 'Retrieve quote',
        link2Url: config.CAR_RETRIEVE_URL,
      },
      ...(showPetChanges
        ? [
            {
              heading: 'Pet Insurance',
              headingMobile: 'Pet',
              ctaUrl: config.PET_URL,
              link1text: 'Find out more',
              link1Url: '/pet-insurance',
              link2text: 'Retrieve quote',
              link2Url: config.PET_RETRIEVE_URL,
            },
          ]
        : []),
    ];
  }, [showPetChanges]);

  return (
    <div
      className={`flex flex-row z-100 justify-center space-x-5 md:space-x-30px lg:space-x-20 px-6 md:px-0 relative md:mx-10 ${marginTop}`}
    >
      {cards.map((card: any) => (
        <div
          className="md:max-w-362 lg:max-w-625 w-full md:mx-0"
          key={`carousel-${card.heading}-InsuranceCard`}
        >
          <InsuranceCard data={card} />
        </div>
      ))}
    </div>
  );
};
export default CtaBanner;
