import React from 'react';

import TickIcon from '../../../static/svgIcons/PurpleTick.svg';
import config from '../../config';
import { Section } from '../../pages/contact';
import {
  ContactUsButton,
  Description,
  DidNotFindAnswerSection,
  DidNotFindAnswerSectionTitle,
  HelpCenterLink,
  ItemText,
  LiveChatButton,
  ManagePolicyTabContainer,
  PolicyListItemContainer,
  QuestionsTabContainer,
  QuestionsTabWrapper,
  Title,
  TwoColumns,
} from './HomeTabs.style';
import MoreWaysAccordion from './MoreWaysAccordion';

export type QuestionsTabProps = {
  section: Section;
};

const policyLists = {
  home: [
    'View policy details',
    'Renew your policy',
    'Save, retrieve or get a new quote',
    'Make changes to your policy',
  ],
  car: [
    'View policy details',
    'Renew your policy',
    'Update personal details',
    'Change your address',
    'Change your vehicle',
    'Add a driver',
  ],
  pet: [
    'View policy details',
    'Renew your policy',
    'Save, retrieve or get a new quote',
  ],
};

const PolicyListItem = ({ item }: { item: string }) => (
  <PolicyListItemContainer>
    <TickIcon />
    <ItemText>{item}</ItemText>
  </PolicyListItemContainer>
);

export const ManagePolicyTab = ({ section }: QuestionsTabProps) => (
  <ManagePolicyTabContainer>
    <TwoColumns>
      <Title>Your online portal</Title>
      <Description>
        Available 24hrs a day, 365 days per year. The portal puts you in
        complete control of your policy.
      </Description>
    </TwoColumns>
    <TwoColumns>
      {section &&
        policyLists[section].map((item, index) => (
          <PolicyListItem key={`product-${section}-${index}`} item={item} />
        ))}

      <ContactUsButton
        href={
          section === 'home' || section === 'pet'
            ? config.HOME_LOGIN_URL
            : config.CAR_LOGIN_URL
        }
      >
        Log in
      </ContactUsButton>
    </TwoColumns>
  </ManagePolicyTabContainer>
);

export const QuestionsTab = ({ section }: QuestionsTabProps) => (
  <QuestionsTabContainer>
    <QuestionsTabWrapper>
      <TwoColumns>
        <Title>Looking for answers? Try the Help Centre first</Title>
      </TwoColumns>
      <TwoColumns>
        <Description>
          Our Help Centre provides answers to a wide range of popular customer
          queries.
        </Description>
        <Description>
          It's easy to search for answers. And it'll even let you know what
          issues can be resolved online via your portal.
        </Description>
        <Description>Easy!</Description>
        <HelpCenterLink
          to={config.HELP_CENTRE_URL}
          className="btn-md lg:btn-lg md:mr-space1 w-60 mb-btnSpace md:last:mr-0 lg:max-w-300 btn-secondary"
        >
          Help Centre
        </HelpCenterLink>
      </TwoColumns>
    </QuestionsTabWrapper>
    {section === 'home' && (
      <DidNotFindAnswerSection>
        <DidNotFindAnswerSectionTitle>
          Didn’t find the answer you need?
        </DidNotFindAnswerSectionTitle>
        <Description>Our team are online now, ready to help.</Description>
        <LiveChatButton
          onClick={() =>
            (
              document.querySelector('#zendesk-chat-widget') as HTMLElement
            )?.click()
          }
        >
          Start live chat
        </LiveChatButton>
      </DidNotFindAnswerSection>
    )}

    <MoreWaysAccordion section={section} />
  </QuestionsTabContainer>
);
