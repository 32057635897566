import { Link } from 'gatsby';
import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const PolicyListItemContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 15px;
`;

export const ItemText = styled.p`
  font-size: 16px;
  margin-bottom: 0;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const ManagePolicyTabContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;

  @media (min-width: 1024px) {
    display: flex;
    gap: 100px;
    padding: 80px 100px;
  }
`;

export const TwoColumns = styled.div`
  @media (min-width: 1024px) {
    width: 50%;
    max-width: 450px;
  }
`;

export const Title = styled.h2`
  font-size: 25px;
  margin-bottom: 15px;
  font-family: ${fontFamilyCamptonMedium};
  line-height: 30px;

  @media (min-width: 1024px) {
    font-size: 50px;
    margin-bottom: 30px;
    line-height: 58px;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 20px;
  font-family: ${fontFamilyCamptonBook};
  line-height: 1.3;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const ContactUsButton = styled.a`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 25px 32px;
  width: 100%;
  height: 54px;
  font-size: 15px;
  margin-top: 30px;
  max-width: 240px;
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  color: ${colors.pink2};
  background-color: ${colors.white};
  border-width: 2px;
  border-color: ${colors.pink2};
  border-radius: 15px;
  transition-property: all;
  transition-duration: 150ms;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.pink2};
  }

  @media (min-width: 1024px) {
    margin-top: 40px;
    font-size: 20px;
    height: 68px;
    max-width: 300px;
  }
`;

export const QuestionsTabContainer = styled.div`
  padding: 30px 0;

  @media (min-width: 1024px) {
    padding: 80px 100px;
  }
`;

export const QuestionsTabWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding-bottom: 80px;
    flex-direction: row;
    gap: 80px;
  }
`;

export const HelpCenterLink = styled(Link)`
  margin: 10px 0 40px;
  width: 240px;

  @media (min-width: 768px) {
    width: 300px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
`;

export const DidNotFindAnswerSection = styled.div`
  background-color: ${colors.purple3};
  border-radius: 20px;
  border: 1px solid ${colors.gray7};
  padding: 40px 20px;
  text-align: center;

  @media (min-width: 1024px) {
    padding: 60px 40px;
  }
`;

export const DidNotFindAnswerSectionTitle = styled.h3`
  font-size: 25px;
  font-family: ${fontFamilyCamptonMedium};
  line-height: 29px;

  @media (min-width: 1024px) {
    font-size: 40px;
    margin: 10px 0;
  }
`;

export const LiveChatButton = styled.button`
  width: 100%;
  height: 54px;
  font-size: 15px;
  max-width: 240px;
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  color: ${colors.pink2};
  background-color: ${colors.white};
  border-width: 2px;
  border-color: ${colors.pink2};
  border-radius: 15px;
  transition-property: all;
  transition-duration: 150ms;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.pink2};
  }

  @media (min-width: 768px) {
    margin-top: 12px;
    font-size: 20px;
    height: 68px;
    max-width: 304px;
  }

  @media (min-width: 1024px) {
    max-width: 300px;
  }
`;
