import React, { FC, ReactNode } from 'react';

import { CoverType } from './CoverSelector';
import {
  ContentWrapper,
  LogoContainer,
  PlusLogo,
  SelectLogo,
  WrapperSubtitle,
  WrapperTitle,
} from './TriageContentWrapper.style';

type HecModalContent = {
  title?: string;
  subtitle?: string;
  hasCoverLogo?: boolean;
  body?: ReactNode;
};

type Props = {
  children?: ReactNode;
  data: HecModalContent;
  coverType?: CoverType;
};

const TriageContentWrapper: FC<Props> = ({ children, data, coverType }) => {
  const coverLogo =
    coverType === 'Select' ? (
      <SelectLogo data-testid="select-logo" />
    ) : coverType === 'Plus' ? (
      <PlusLogo data-testid="plus-logo" />
    ) : null;

  return (
    <ContentWrapper data-testid="triage-content-wrapper">
      {data.hasCoverLogo && !!coverLogo && (
        <LogoContainer data-testid={`${coverType}-logo`}>
          {coverLogo}
        </LogoContainer>
      )}
      {data.title && <WrapperTitle>{data.title}</WrapperTitle>}
      {data.subtitle && <WrapperSubtitle>{data.subtitle}</WrapperSubtitle>}
      {children || data.body}
    </ContentWrapper>
  );
};
export default TriageContentWrapper;
