import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import TermsBlock, {
  TermsBlockDataType,
} from '../components/termsBlock/TermsBlock';
import TwoColumnTextBanner, {
  TwoColumnTextBannerType,
} from '../components/twoColumnTextBanner/TwoColumnTextBanner';
import useDisclaimer from '../hooks/useDisclaimer';

const twoColumnTextBannerData: TwoColumnTextBannerType = {
  headerText: 'Vulnerability disclosure policy',
  bgColor: 'bg-pxCharcoal',
  textColor: 'text-pxWhite',
  subText: [
    <p
      className="text-pxWhite lg:text-right md:mt-32px text-finerDetailsBodyCopy md:text-leadBodyCopy"
      key="policy-version"
    >
      Version: 1.0
      <br />
      <br />
      Last updated: 19/07/2023
    </p>,
  ],
};

const termsBlockData: TermsBlockDataType = {
  termsInformation: [
    {
      heading: 'Purpose',
      body: (
        <div>
          <p className="text-small mb-0">
            The purpose of this policy is to establish a standard for the
            reporting of security vulnerabilities to the organisation, so long
            as the Organisation's website/s have a published security.txt file
            that references this policy.
          </p>
        </div>
      ),
      bgColor: 'bg-pxMint',
      textColor: 'black',
    },
    {
      heading: 'Scope',
      body: (
        <div>
          <p className="text-small">
            The scope of this policy applies to any person who believes they
            have discovered a vulnerability and wishes to report it. We
            recommend reading this vulnerability disclosure policy fully before
            you report a vulnerability and always act in compliance with it.
          </p>
          <p className="text-small mb-0">
            We value those who take the time and effort to report security
            vulnerabilities according to this policy. However, we do not offer
            monetary rewards for vulnerability disclosures.
          </p>
        </div>
      ),
      bgColor: 'bg-pxTan',
      textColor: 'black',
    },
    {
      heading: 'Policy',
      partialShape: true,
      body: (
        <div>
          <p className="text-small">
            Any vulnerabilities that are being reported are to be treated as
            sensitive, confidential Policy Expert information.
          </p>
          <p className="font-bold text-pxPurple">Reporting</p>
          <p className="text-small">
            If you believe you have found a security vulnerability relating to
            the Organisation’s systems, please submit a vulnerability report to
            the address defined in the CONTACT field of the published{' '}
            <a href="/security.txt" target="_blank" className="font-campton">
              security.txt file
            </a>
            .
          </p>
          <p className="text-small">
            In your report please include details of:
          </p>
          <ul>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              The website, IP Address or page where the vulnerability can be
              observed.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              A brief description of the type of vulnerability, for example;
              “XSS vulnerability”.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Steps to reproduce. These should be a benign, non-destructive,
              proof of concept. This helps to ensure that the report can be
              triaged quickly and accurately. It also reduces the likelihood of
              duplicate reports, or malicious exploitation of some
              vulnerabilities, such as sub-domain takeovers.
            </li>
          </ul>
          <p className="font-bold text-pxPurple">What to expect</p>
          <ul>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              After you have submitted your report, we will respond to your
              report within 5 working days and aim to triage your report within
              10 working days. We'll also aim to keep you informed of our
              progress.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Priority for remediation is assessed by looking at the impact,
              severity and exploit complexity. Vulnerability reports might take
              some time to triage or address. You are welcome to enquire on the
              status but should avoid doing so more than once every 14 days.
              This allows our teams to focus on the remediation.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              If requested, we will notify you when the reported vulnerability
              is remediated, and you may be invited to confirm that the solution
              covers the vulnerability adequately.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Once the vulnerability has been resolved, we welcome requests to
              disclose your report. We'd like to unify our guidance, so please
              continue to coordinate any public release with us.
            </li>
          </ul>
          <p className="font-bold text-pxPurple">Guidance</p>
          <p className="text-small font-campton">You must NOT:</p>
          <ul>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Break any applicable law or regulations.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Access unnecessary, excessive or significant amounts of data.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Modify data in the Organisation's systems or services.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Use high-intensity invasive or destructive scanning tools to find
              vulnerabilities.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Attempt or report any form of denial of service, e.g. overwhelming
              a service with a high volume of requests.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Disrupt the Organisation's services or systems.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Submit reports detailing non-exploitable vulnerabilities, or
              reports indicating that the services do not fully align with “best
              practice”, for example missing security headers.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Submit reports detailing TLS configuration weaknesses, for example
              “weak” cipher suite support or the presence of TLS1.0 support.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Communicate any vulnerabilities or associated details other than
              by means described in the published security.txt.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Social engineer, ‘phish’ or physically attack the Organisation's
              staff or infrastructure.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Demand financial compensation in order to disclose any
              vulnerabilities.
            </li>
          </ul>
          <p className="text-small font-campton">You must:</p>
          <ul>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Always comply with data protection rules and must not violate the
              privacy of any data the Organisation holds. You must not, for
              example, share, redistribute or fail to properly secure data
              retrieved from the systems or services.
            </li>
            <li className="text-finerDetailsBodyCopy md:text-bodyCopy">
              Securely delete all data retrieved during your research as soon as
              it is no longer required or within 1 month of the vulnerability
              being resolved, whichever occurs first (or as otherwise required
              by data protection law).
            </li>
          </ul>
          <p className="font-bold text-pxPurple">Legalities</p>
          <p className="text-small mb-0">
            This policy is designed to be compatible with common vulnerability
            disclosure good practice. It does not give you permission to act in
            any manner that is inconsistent with the law, or which might cause
            the Organisation or partner organisations to be in breach of any
            legal obligations.
          </p>
        </div>
      ),
      bgColor: 'bg-pxWhite',
      textColor: 'black',
    },
    {
      heading: 'Compliance',
      overflowShape: true,
      body: (
        <div>
          <p className="font-bold text-pxPurple">Compliance Measurement</p>
          <p className="text-small mb-0">
            Compliance with Policy Expert policies and procedures is required
            and will be verified through various methods including, but not
            limited to, business tool reports, internal and external audits and
            self-assessment. Policy Expert reserves the right to modify or
            eliminate any or all parts of this document and you are responsible
            for regularly reviewing it to ensure you are up to date.
          </p>
        </div>
      ),
      bgColor: 'bg-pxMint',
      textColor: 'black',
    },
    {
      heading: 'Related documents',
      body: (
        <div>
          <p className="font-bold text-pxPurple">Internal</p>
          <p className="text-small">Vulnerability Disclosure Process</p>
          <p className="font-bold text-pxPurple">External</p>
          <p className="text-small mb-0">
            <a href="/security.txt" target="_blank" className="font-campton">
              Security.txt file
            </a>
          </p>
        </div>
      ),
      bgColor: 'bg-pxTan',
      textColor: 'black',
    },
  ],
};

const VulnerabilityDisclosurePage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  return (
    <>
      <TwoColumnTextBanner
        data={twoColumnTextBannerData}
        shape={false}
        section172
        disclosurePage
      />
      <TermsBlock data={termsBlockData} disclosurePage navigationShapeX />
    </>
  );
};

export default VulnerabilityDisclosurePage;

export const Head: HeadFC = () => (
  <>
    <title>Vulnerability disclosure policy | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
