import React from 'react';

import { ReviewData } from '../../types/reviews';
import ReviewCard from '../reviewCard/ReviewCard';

const reviews: ReviewData[] = [
  {
    title: 'Easy to set up',
    author: 'Ken Thistlethwaite',
    date: '08/03/2024',
    review:
      'It was easy to get a quote, it was a good price and easy to set up.',
  },
  {
    title: 'Friendly and helpful staff',
    author: 'Terry',
    date: '04/03/2024',
    review:
      'Dealt with a very friendly and helpful advisor who made the process easy to follow.',
  },
  {
    title: 'Simple and clear',
    author: 'Philip Constantinou',
    date: '04/03/2024',
    review:
      'The website was very clearly laid out, and easy to navigate and use.',
  },
  {
    title: 'Great company',
    author: 'Carl Dawson',
    date: '03/03/2024',
    review:
      'A great insurance company, easy to access. Always someone to help online or on the phone.',
  },
];

const PetInsuranceReviews: JSX.Element[] = reviews.map((item) => (
  <ReviewCard key={`carousel-${item.review}`} data={item} petCard />
));

export default PetInsuranceReviews;
