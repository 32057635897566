import React from 'react';

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type Props = {
  imageFile: IGatsbyImageData;
  className?: string;
  alt: string;
  imgPosition?: string;
};

const ImageComponent = ({ className, imageFile, alt, imgPosition }: Props) => (
  <GatsbyImage
    image={imageFile}
    className={className}
    alt={alt}
    objectPosition={imgPosition}
  />
);

export default ImageComponent;
