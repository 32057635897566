import React from 'react';

import { Link } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import DropShapeCard from '../dropShapeCard/DropShapeCard';
import ImageComponent from '../imageComponent/ImageComponent';
import Shape from '../Shape';
import ShapeX from '../ShapeX';

type GridButtonsType = {
  label: string;
  url: string;
  primary: boolean;
  target?: string;
};

type TwoColumnHeaderGridItemsType = {
  header: string;
  subText: string[];
  imgName: string;
  imageFile: IGatsbyImageData;
  button: GridButtonsType;
  link?: {
    header: string;
    linkText: string;
    link: string;
  };
};

export type TwoColumnHeaderGridType = {
  header: string;
  subText: string;
  hasVideo?: boolean;
  videoTitle?: string;
  videoSubTitle?: string;
  videoUrl?: string;
  bgColor: string;
  items: TwoColumnHeaderGridItemsType[];
  link?: {
    header: string;
    linkText: string;
    link: string;
  };
};

interface Props {
  data: TwoColumnHeaderGridType;
  shape: string;
  isLocation?: boolean;
}

export default function TwoColumnHeaderGrid({
  data,
  shape,
  isLocation,
}: Props) {
  return (
    <div
      className={`bg-${data.bgColor} ${
        isLocation && isLocation
          ? 'pt-2.5 pb-82px md:pt-0 md:pb-130px'
          : 'py-82px md:py-130px'
      } relative overflow-hidden`}
    >
      <div className="padding-x z-30 relative">
        <div className={`grid grid-cols-1 md:grid-cols-2 gap-x-10 `}>
          <div className="w-full ">
            <h2 className="pb-6 md:pb-82px max-w-510">{data.header}</h2>
            <p className={'max-w-510'}>{data.subText}</p>
          </div>
          <div className="w-full ">
            {data.items.map((item: TwoColumnHeaderGridItemsType) => (
              <div
                key={item.header}
                className="grid grid-cols-1 sm:grid-cols-2 sm:gap-5 first:mt-0 mb-10 md:mb-5"
              >
                <div className="mb-0 md:mb-32px md:max-w-300 max-w-full h-56">
                  <DropShapeCard fullWidth>
                    <ImageComponent
                      className="h-56 max-w-full "
                      imageFile={item.imageFile}
                      alt={item.header}
                    />
                  </DropShapeCard>
                </div>

                <div className={'pt-6'}>
                  <p className="m-0 font-semibold mb-2">{item.header}</p>
                  {item.subText.map((subText: string) => (
                    <p className="m-0 text-base" key={subText}>
                      {subText}
                    </p>
                  ))}
                  {item.link && (
                    <p className="m-0 mt-3 text-xl">
                      {item.link.header}
                      {` `}
                      <Link
                        to={item.link.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={'font-bold'}
                      >
                        {item.link.linkText}
                      </Link>
                    </p>
                  )}
                  {item.button && (
                    <div className={'flex'}>
                      <Link
                        to={item.button.url}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        className={`${
                          item.button.primary ? 'btn-primary' : 'btn-secondary'
                        } flex font-CamptonSemiBold font-bold items-center justify-center rounded-full md:max-w-210 max-w-155 flex-grow text-finerDetailsBodyCopy md:text-gridButton text-white w-full mt-3 px-0`}
                      >
                        <span className={'py-1 px-1 md:px-0'}>
                          {' '}
                          {item.button.label}
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
                {/* <div className="flex-1 pb-32px md:px-32px">
                                    <h3 className="text-bodyCopy md:text-leadBodyCopy font-camptonSemiBold">{item.name}</h3>
                                    <p className="text-footerCopy pb-0 mb-1">{item.jobTitle}</p>
                                    <p className="text-footerCopy mb-3 md:mb-6">{item.location}</p>
                                    <Link to={item.link} className="btn-secondary text-bodyCopy btn-sm m-0 cursor-pointer mr-32px">Read more</Link>
                                </div> */}
              </div>
            ))}
            {data.link && (
              <p className="m-0 mt-4 text-xl">
                <Link
                  to={data.link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={'font-bold'}
                >
                  {data.link.linkText}
                </Link>
                {` `}
                {data.link.header}
              </p>
            )}
          </div>
        </div>
      </div>
      {shape === 'bottom-left-cards' ? (
        <ShapeX position="bottom-left-cards" />
      ) : null}
      {shape === 'left-right' && (
        <>
          <Shape position="right-home-team" />
          <Shape position="left-bottom-team" />
        </>
      )}
    </div>
  );
}
