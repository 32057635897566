import { styled } from 'styled-components';

import { colors, fontFamilyCamptonSemiBold } from '../styles/sharedStyled';

export const PageWrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray3};
  align-items: center;
`;

export const DDLogoWrapper = styled.div`
  margin-bottom: 40px;
  width: 94px;

  @media (min-width: 768px) {
    width: 124px;
  }
`;

export const GCLogoWrapper = styled.div`
  margin-bottom: 40px;
  width: 134px;

  @media (min-width: 768px) {
    width: 168px;
  }
`;

export const SectionHeading = styled.h2`
  font-size: 30px;
  line-height: normal;
  letter-spacing: 0;
  margin-top: 50px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 35px;
    margin-top: 70px;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
    margin-top: 100px;
    margin-bottom: 40px;
  }
`;

export const SectionSubHeading = styled.h3`
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 18px;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 32px;
`;

export const TermsText = styled.p`
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const SectionWrapper = styled.div`
  max-width: 800px;
  margin: 0 24px;

  @media (min-width: 768px) {
    margin: 0 40px;
  }
`;

export const TermsList = styled.ul`
  margin-bottom: 32px;
`;

export const TermsListItem = styled.li`
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const FooterImageWrapper = styled.div`
  margin-top: 50px;

  @media (min-width: 768px) {
    max-width: 800px;
    margin: 70px 40px 0;
  }

  @media (min-width: 1024px) {
    margin: 100px 40px 0;
  }
`;
