import { Link } from 'gatsby';
import { styled } from 'styled-components';

import InsuranceCardShape from '../../../static/shape/insurancecardshape.svg';
import ComingSoon from '../../../static/svgIcons/ComingSoon.svg';
import {
  colors,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const CardContainer = styled.div`
  background-image: url(${InsuranceCardShape});
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 287px;
  padding-top: 33px;
  background-repeat: no-repeat;
  width: 254px;
  filter: drop-shadow(-4px 4px 8px ${colors.blackAlpha15});

  @media (min-width: 1024px) {
    width: 276px;
  }
`;

export const CardIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  height: 40px;
`;

export const CardTitle = styled.h3`
  font-family: ${fontFamilyCamptonMedium};
  line-height: normal;
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
  padding-bottom: 0;
  font-size: 22px;
  width: 255px;

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const CardLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  font-family: ${fontFamilyCamptonSemiBold};
  width: 100%;
  justify-content: space-between;
  padding: 0 45px;

  @media (min-width: 1024px) {
    justify-content: center;
  }
`;

export const CardLinks = styled(Link)<{ $isFirst?: boolean }>`
  font-weight: 600;
  margin-bottom: ${({ $isFirst }) => $isFirst && '5px;'};
  font-size: 16px;
  line-height: normal;
`;

export const ComingSoonSvg = styled(ComingSoon)`
  position: absolute;
  top: 6px;
  left: 145px;

  @media (min-width: 1024px) {
    left: 167px;
  }
`;
