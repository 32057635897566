import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ShapeX, { ShapePosition } from '../ShapeX';

type HeroBannerData = {
  title: React.JSX.Element;
  text: React.JSX.Element;
};

type HeaderBannerProps = {
  backgroundColor: string;
  headerImage: React.JSX.Element;
  heroBannerData: HeroBannerData;
  shapePosition?: ShapePosition;
  styling: string;
  maxHeight?: string;
  children: React.ReactNode;
};

const ImpactLoginV2HeaderBanner = ({
  backgroundColor,
  shapePosition,
  styling,
  heroBannerData,
  children,
  headerImage,
}: HeaderBannerProps) => {
  const { isMobile } = useBreakpoint();

  return (
    <div
      className={`${styling} ${backgroundColor} relative overflow-hidden py-40px md:py-50px lg:pt-40px lg:pb-113px`}
    >
      <div className="max-w-1250 m-auto flex flex-col gap-50px lg:gap-0 lg:flex-row lg:px-24px justify-center lg:justify-between items-center">
        <div className="mx-auto w-full">
          <div className="text-center">
            {heroBannerData.title}
            {heroBannerData.text}
          </div>
          <div className="flex items-center">{children}</div>
        </div>
        {headerImage}
      </div>
      {!isMobile && shapePosition && <ShapeX position={shapePosition} />}
    </div>
  );
};
export default ImpactLoginV2HeaderBanner;
