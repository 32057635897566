import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import Shape from '../components/Shape';
import useDisclaimer from '../hooks/useDisclaimer';
import {
  ContentWrapper,
  DDLogoWrapper,
  FooterImageWrapper,
  GCLogoWrapper,
  PageWrapper,
  SectionHeading,
  SectionSubHeading,
  SectionWrapper,
  TermsList,
  TermsListItem,
  TermsText,
} from './payment-terms-and-conditions-gc.style';

const getFooterImage = ({ isMobile }: { isMobile: boolean }) => {
  if (isMobile) {
    return (
      <StaticImage
        src="../images/cat_and_dog_mobile.png"
        alt="Cat and dog looking up"
        loading="eager"
        placeholder="blurred"
      />
    );
  }

  return (
    <StaticImage
      src="../images/cat_and_dog.png"
      alt="Cat and dog looking up"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const DirectDebitSection = () => {
  return (
    <SectionWrapper>
      <SectionHeading>The Direct Debit Guarantee</SectionHeading>
      <DDLogoWrapper>
        <StaticImage
          src="../images/logo_direct_debit.png"
          alt="Direct Debit Logo"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      </DDLogoWrapper>
      <TermsText>
        The Guarantee is offered by all banks and building societies that accept
        instruction to pay Direct Debits.
      </TermsText>
      <TermsText>
        If there are any changes to the amount, date or frequency of your Direct
        Debit, GC re Policy Expert will give you at least 2 working days’ notice
        in advance of your account being debited or as otherwise agreed.
      </TermsText>
      <TermsText>
        If you request GC re Policy Expert to collect a payment - confirmation
        of the amount and date will be given at the time of your request.
      </TermsText>
      <TermsText>
        If an error is made in the payment of your Direct Debit by GC re Policy
        Expert or your bank or building society, you’re entitled to a full and
        immediate refund of the amount paid from your bank or building society.
      </TermsText>
      <TermsText>
        If you receive a refund you’re not entitled to, you must pay this back
        when asked to by GC re Policy Expert.
      </TermsText>
      <TermsText>
        You can cancel a Direct Debit at any time by contacting your bank or
        building society. Written confirmation may be required. Please also
        notify GC re Policy Expert.
      </TermsText>
    </SectionWrapper>
  );
};

const GoCardlessSection = () => {
  return (
    <SectionWrapper>
      <SectionHeading>Payment Terms and Conditions</SectionHeading>
      <GCLogoWrapper>
        <StaticImage
          src="../images/logo_go_cardless.png"
          alt="GoCardless Logo"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      </GCLogoWrapper>
      <SectionSubHeading>
        When you purchase a policy and pay monthly
      </SectionSubHeading>
      <TermsText>
        You’re buying a 12-month insurance policy and have chosen to pay the
        premium in a number of monthly payments, rather than pay the premium in
        full at the beginning of your policy. Please note, you are not taking
        out a credit agreement.
      </TermsText>
      <TermsText>Before you buy your policy, we’ll let you know:</TermsText>
      <TermsList>
        <TermsListItem>
          the monthly premium amount that will be collected from you; and
        </TermsListItem>
        <TermsListItem>
          the date the premium will be collected each month
        </TermsListItem>
      </TermsList>
      <TermsText>
        Once you’ve bought your policy, we’ll pass your personal information and
        bank details to our trusted partner GoCardless, who’ll collect these
        monthly premiums on our behalf. They’ll set up a Direct Debit mandate
        and collect your premiums on the dates we told you about before you
        bought your policy. You’ll see these payments on your bank account as
        either “Policy Expert” or “GC re Policy Expert”.
      </TermsText>
      <TermsText>
        If the collection date falls over a weekend or an English bank holiday,
        your premium will be collected on the next working day.
      </TermsText>

      <SectionSubHeading>If you don’t pay for your premium</SectionSubHeading>
      <TermsText>
        If we can't collect your monthly premium, then we’ll try again after 2
        working days. If we can’t collect your premium within 15 days from the
        monthly due date then your policy will be cancelled, and you’ll no
        longer have cover for your pet. 
      </TermsText>
      <TermsText>
        We won’t charge you for any failed payments, but your bank or building
        society may charge you.
      </TermsText>
    </SectionWrapper>
  );
};

const PaymentTermsAndConditionsGoCardlessPage: React.FC<PageProps> = () => {
  useDisclaimer(null);
  const { isMobile } = useBreakpoint();

  return (
    <PageWrapper>
      <Shape position="right-pet-terms" />
      <Shape position="left-pet-terms" />

      <ContentWrapper>
        <DirectDebitSection />
        <GoCardlessSection />
        <FooterImageWrapper>{getFooterImage({ isMobile })}</FooterImageWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default PaymentTermsAndConditionsGoCardlessPage;

export const Head: HeadFC = () => (
  <>
    <title>Payment terms and conditions - GoCardless | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
