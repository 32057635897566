import React from 'react';

import ShapeX, { ShapePosition } from '../ShapeX';
import { TextBannerContainer } from './TextBanner.style';

type TextBannerData = {
  title: React.JSX.Element;
  text: React.JSX.Element;
};

type TextBannerProps = {
  backgroundColor: string;
  textBannerData: TextBannerData;
  shapePosition?: ShapePosition;
  isBottom?: boolean;
};

const TextBanner = ({
  backgroundColor,
  textBannerData,
  shapePosition,
  isBottom,
}: TextBannerProps) => {
  return (
    <TextBannerContainer
      $backgroundColor={backgroundColor}
      $isBottom={isBottom}
    >
      {textBannerData.title}
      {textBannerData.text}
      {shapePosition && <ShapeX position={shapePosition} />}
    </TextBannerContainer>
  );
};
export default TextBanner;
