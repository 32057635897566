import React from 'react';

import {
  Arrow,
  DocumentsContainer,
  Link,
  LinkContainer,
  LinkHeading,
  SectionTitle,
  ShowHideButton,
} from './Ipid.style';

type DocumentLink = {
  linkText: string;
  url: string;
};

type Document = {
  heading: string;
  links: DocumentLink[];
};

export type IpidProps = {
  sectionTitle: string;
  data: Document[];
};

const Ipid = ({ data, sectionTitle }: IpidProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div>
      <SectionTitle>{sectionTitle}</SectionTitle>
      {data.map(({ heading, links }: Document, index) => {
        if (!isExpanded && index > 3) {
          return null;
        }
        return (
          <LinkContainer key={links[0].url} $hasMargin={data.length > 4}>
            <LinkHeading>{heading}</LinkHeading>
            <DocumentsContainer>
              {links.map(({ linkText, url }: DocumentLink) => (
                <Link
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                  $hasMargin={links.length > 1}
                  key={`link-${url}`}
                >
                  {linkText}
                </Link>
              ))}
            </DocumentsContainer>
          </LinkContainer>
        );
      })}

      {data.length > 4 && (
        <ShowHideButton onClick={() => setIsExpanded(!isExpanded)}>
          Show {isExpanded ? 'less' : 'more'}
          <Arrow $isExpanded={isExpanded} />
        </ShowHideButton>
      )}
    </div>
  );
};

export default Ipid;
