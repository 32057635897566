import React from 'react';

import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import { ButtonType } from '../../types/button';
import { TimerSpeedWhiteSvg } from './HeaderLandingPage.style';

export type HeaderLandingPageDataType = {
  subHeadText?: string;
  buttons?: ButtonType[];
  header?: string;
  subHeader?: string;
  subHeaderText?: string;
};

type Props = {
  data: HeaderLandingPageDataType;
};

const HeaderLandingPage = ({ data }: Props) => {
  const { isMobile, isTablet } = useBreakpoint();

  return (
    <div
      className={`bg-pxMint min-h-800 sm:min-h-1000 md:min-h-700 xl:min-h-280 py-82px lg:py-28 xl:py-32 relative`}
    >
      <div className="absolute w-full h-full inset-0 -z-1 overflow-hidden">
        {isMobile ? (
          <StaticImage
            src="../../images/pe_landingpage_mobile_hero.jpg"
            alt="Get a quote"
            className="w-full bottom-0 landing-header-img"
            loading="eager"
            transformOptions={{ cropFocus: 'east' }}
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={1}
            quality={70}
          />
        ) : isTablet ? (
          <StaticImage
            src="../../images/pe_landingpage_ipad_hero.jpg"
            alt="Get a quote"
            className="w-full bottom-0 landing-header-img"
            loading="eager"
            transformOptions={{ cropFocus: 'east' }}
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={1.3}
            quality={70}
          />
        ) : (
          <StaticImage
            src="../../images/desktop_hero.jpg"
            alt="Get a quote"
            className="w-full bottom-0 landing-header-img"
            loading="eager"
            transformOptions={{ cropFocus: 'east' }}
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={1.9}
            quality={70}
          />
        )}
      </div>
      <div className="padding-x">
        <div
          className="relative z-30 flex flex-col items-center md:items-start md:ml-5  md:max-w-3/4 lg:max-w-1/2"
          style={{ transform: 'rotate(-6.86deg)', transformOrigin: '0% 10%' }}
        >
          {data !== null && (
            <div className="flex flex-col w-max text-center">
              <div>
                <h3 className={` text-pxBlack font-campton-medium`}>
                  {data.subHeadText}
                </h3>
                <h1 className="text-sectionSubHeading sm:text-sectionHeading text-pxBlack mx-auto landing-header-font">
                  {data.header}
                  <br />
                  {data.subHeader}
                </h1>

                <h3 className={` text-pxBlack  mb-32px font-campton-medium`}>
                  {data.subHeaderText}
                </h3>
              </div>

              <div className="flex flex-col lg:flex-row relative items-center justify-center pr-5 ">
                {data.buttons &&
                  data.buttons.map((btn: any) => {
                    return (
                      <Link
                        key={btn.label}
                        to={btn.url}
                        className={`flex items-center flex-row w-full md:max-w-240 btn-${
                          btn.smallestSize || 'md'
                        } lg:btn-lg lg:mr-32px mb-12px last:mb-0
                    btn-${btn.primary ? 'primary' : 'secondary'}`}
                        target={btn.target ? btn.target : '_self'}
                      >
                        {btn.label}
                        {btn.speedClockIcon && <TimerSpeedWhiteSvg />}
                      </Link>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderLandingPage;
