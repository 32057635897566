import React, { FC } from 'react';
import { useWizard } from 'react-use-wizard';

import { PerilQuestion, PreviousQuestionsType } from './HecData';
import {
  PerilContainer,
  PerilName,
  PreviousAnswer,
  PreviousQuestion,
  PreviousQuestionContainer,
  PreviousQuestionsList,
  Question,
  QuestionSubtitle,
  Tick,
} from './PerilStep.style';
import YesNoButtons from './YesNoButtons';

type PerilStepProps = {
  peril: string;
  previousQuestions: Array<PreviousQuestionsType>;
  setPreviousQuestions: (
    previousQuestions: Array<PreviousQuestionsType>
  ) => void;
  currentQuestion: PerilQuestion;
  setCoverInformation: (coverInformation: string) => void;
  setDeclineReason: (declineReason: string) => void;
  isLastPerilStep: boolean;
  noStepLocation: number | false;
};

const PerilStep: FC<PerilStepProps> = ({
  peril,
  previousQuestions,
  setPreviousQuestions,
  currentQuestion,
  setCoverInformation,
  setDeclineReason,
  isLastPerilStep,
  noStepLocation,
}) => {
  const { goToStep, nextStep, stepCount } = useWizard();

  const coverInformation = currentQuestion.coverInformation || '';
  const declineReason = currentQuestion.declineReason || '';
  setCoverInformation(coverInformation);
  setDeclineReason(declineReason);

  const mayBeCoveredStep = stepCount - 2;
  const declineStep = noStepLocation || stepCount - 1;

  const storePreviousStep = (answer: 'Yes' | 'No') => {
    const currentQuestionToSet = {
      question: currentQuestion.title,
      answer: answer,
    };
    const newPreviousQuestions = previousQuestions.length
      ? [...previousQuestions, currentQuestionToSet]
      : [currentQuestionToSet];

    setPreviousQuestions(newPreviousQuestions);
  };

  const yesStep = () => {
    storePreviousStep('Yes');
    isLastPerilStep ? goToStep(mayBeCoveredStep) : nextStep();
  };

  const noStep = () => {
    storePreviousStep('No');
    goToStep(declineStep);
  };

  return (
    <>
      <PerilContainer>
        <PerilName>{peril}</PerilName>
        <Tick />
      </PerilContainer>
      {previousQuestions.length > 0 && (
        <PreviousQuestionsList data-testid="previous-questions-list">
          {previousQuestions.map((previousInformation) => (
            <PreviousQuestionContainer key={previousInformation.question}>
              <PreviousQuestion data-testid="previous-question">
                {previousInformation.question}
              </PreviousQuestion>
              <PreviousAnswer data-testid="previous-answer">
                {previousInformation.answer}
              </PreviousAnswer>
            </PreviousQuestionContainer>
          ))}
        </PreviousQuestionsList>
      )}
      <Question>{currentQuestion.title}</Question>
      {!!currentQuestion.subtitle && (
        <QuestionSubtitle data-testid="question-subtitle">
          {currentQuestion.subtitle}
        </QuestionSubtitle>
      )}
      <YesNoButtons yesStep={yesStep} noStep={noStep} />
    </>
  );
};

export default PerilStep;
