import React from 'react';

import LoginProductButtons, {
  ProductData,
} from '../productButtons/LoginProductButtons';

export type LoginProductButtonsType = Array<ProductData>;

type Props = {
  productSelectionData: LoginProductButtonsType;
};

const LoginProductButtonsBanner = ({ productSelectionData }: Props) => {
  return (
    <div
      className="mb-0 w-full lg:w-auto"
      data-testid="login-product-buttons-banner"
    >
      <div className="flex flex-col md:flex-row justify-center space-y-15px md:space-y-0 md:space-x-15px">
        {productSelectionData.map((selectionData) => (
          <LoginProductButtons
            key={`productButton${selectionData.product}`}
            product={selectionData.product}
            buttonHeader={selectionData.buttonHeader}
            url={selectionData.url}
          />
        ))}
      </div>
    </div>
  );
};

export default LoginProductButtonsBanner;
