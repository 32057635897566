import React from 'react';

import Tick from '../../../static/svgIcons/Tick.svg';

type ListItemType = {
  icon: any;
  text: any;
};

export type HelpingYouThriveType = {
  bgColor: string;
  header: string;
  strongCopy: string;
  introCopy: string[];
  listTitle: string;
  listParagraph: string;
  listItems: ListItemType[];
};

type Props = {
  data: HelpingYouThriveType;
};

const HelpingYouThrive = ({ data }: Props) => {
  return (
    <div
      className={`bg-${data.bgColor} py-82px md:py-130px relative overflow-hidden helping-you-thrive`}
    >
      <div className="padding-x">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 ">
          <div>
            <h2 className="pb-10 md:pb-82px">{data.header}</h2>
          </div>
          <div className="copy-container">
            <div>
              <p>{data.strongCopy}</p>
              {data.introCopy.map((item) => (
                <p
                  key={item}
                  className="text-finerDetailsBodyCopy lg:text-bodyCopy mb-5"
                >
                  {item}
                </p>
              ))}
            </div>
            <div className="pt-10 md:pt-82px">
              <h4 className="pb-32px">{data.listTitle}</h4>
              <p className="text-bodyCopy">{data.listParagraph}</p>
              <ul>
                <ul className="icon-list">
                  {data.listItems.map((listItem) => (
                    <li className="flex" key={listItem.text}>
                      <Tick
                        className="icons-primary1 z-50 inline-block relative -top-1"
                        stroke="#6214bE"
                        fill="#6214bE"
                      />
                      {listItem.text}
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpingYouThrive;
