import { Link } from 'gatsby';
import { styled } from 'styled-components';

import ArrowRight from '../../../static/svgIcons/ArrowRight.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
} from '../../styles/sharedStyled';

export const CarPolicyContainer = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${colors.gray3};
  padding: 40px 24px;

  @media (min-width: 768px) {
    padding: 50px 0;
  }

  @media (min-width: 1024px) {
    padding: 120px 0;
  }
`;

export const OptionalExtrasTitle = styled.h1`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 30px;
  margin-bottom: 30px;
  line-height: normal;
  text-align: center;
  padding: 0 20px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    font-size: 35px;
    margin-bottom: 15px;
    padding: 0;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
    margin-bottom: 40px;
  }
`;

export const OptionalExtrasSubHeader = styled.p`
  font-size: 18px;
  font-family: ${fontFamilyCamptonBook};
  color: ${colors.purple7};
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 25px;
  }
`;

export const IpidTitle = styled.h2`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 25px;
  margin-bottom: 30px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 35px;
  }
`;

export const Arrow = styled(ArrowRight)`
  fill: ${colors.pink2};
  width: 7px;
  height: 12px;
  margin-bottom: 4px;
`;

export const IpidList = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    display: grid;
    margin: 0 auto;
    column-gap: 80px;
    grid-template-rows: repeat(5, auto);
    grid-auto-flow: column;
  }
`;

export const IpidListContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const IpidLink = styled(Link)`
  color: ${colors.black};
  display: flex;
  align-items: center;
`;

export const IpidListItem = styled.p`
  font-size: 16px;
  margin: 0 0 0 10px;
  line-height: normal;
  letter-spacing: 0;
`;

export const ButtonsGridContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-flow: row wrap;
    margin-bottom: 60px;
    justify-content: center;
    gap: 15px;
  }

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    margin-bottom: 80px;
  }
`;

export const Button = styled(Link)<{ $hasText?: string }>`
  display: flex;
  align-items: center;
  border-radius: 16px;
  height: 65px;
  background-color: ${colors.white};
  border: 2px solid ${colors.gray1};
  padding: 15px 25px;
  margin-bottom: 15px;
  justify-content: ${({ $hasText }) => ($hasText ? 'flex-start' : 'center')};

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 371px) and (max-width: 767px) {
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 240px;
    height: 60px;
    padding: 15px 20px;
    margin: 0;
  }

  @media (min-width: 1024px) {
    width: 200px;
    height: 116px;
    padding: 20px;
    margin: 0;
  }
`;

export const ButtonContentContainer = styled.div<{ $hasText?: string }>`
  display: flex;
  align-items: center;
  width: 210px;
  justify-content: ${({ $hasText }) => ($hasText ? 'flex-start' : 'center')};

  @media (min-width: 371px) and (max-width: 767px) {
    margin-right: 40px;
  }

  @media (min-width: 1024px) {
    flex-direction: column;
  }
`;

export const ButtonText = styled.p`
  font-size: 16px;
  font-family: ${fontFamilyCamptonMedium};
  font-weight: 500;
  letter-spacing: 0;
  margin: 0;
  color: ${colors.black};
  letter-spacing: 0;

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

export const ButtonIconContainer = styled.div<{ $hasText?: string }>`
  display: flex;
  align-items: center;
  width: ${({ $hasText }) => ($hasText ? '50px' : '90px')};
  justify-content: center;
  margin: ${({ $hasText }) => ($hasText ? '0 20px 0 0' : '0')};

  @media (min-width: 768px) and (max-width: 1023px) {
    width: auto;
    margin: ${({ $hasText }) => ($hasText ? '0 15px 0 0' : '0')};
  }

  @media (min-width: 1024px) {
    width: auto;
    min-height: 40px;
    margin: ${({ $hasText }) => ($hasText ? '0 0 15px' : '0')};
  }
`;
