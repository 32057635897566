import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import CareersNavigation from '../../../components/careersNavigation/CareersNavigation';
import HeaderBanner from '../../../components/headerBanner/HeaderBanner';
import Jobs from '../../../components/jobs/Jobs';
import useDisclaimer from '../../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../../images/jobs_sm.png`}
        alt={`Taking the next step in your career`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../../images/jobs_md.png`}
        alt={`Taking the next step in your career`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../../images/jobs_lg.png`}
      alt={`Taking the next step in your career`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const JobsPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxMint"
        headerImage={headerImage}
        shapePosition="bottom-right-header"
        maxHeight="max-h-680"
        styling="md:pl-3 md:pr-33px lg:px-24 max-w-1307"
      />
      <CareersNavigation />
      <Jobs />
    </>
  );
};

export default JobsPage;

export const Head: HeadFC = () => (
  <>
    <title>Careers with Policy Expert</title>
    <meta
      name="description"
      content="Become part of the team at Policy Expert - an award winning car and home insurance provider."
    />
    <html lang="en" />
  </>
);
