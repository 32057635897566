import { styled } from 'styled-components';

import Bike from '../../static/svgIcons/Bike.svg';
import Boiler from '../../static/svgIcons/Boiler.svg';
import BoilerPurple from '../../static/svgIcons/BoilerPurple.svg';
import Burstpipes from '../../static/svgIcons/Burstpipes.svg';
import Camera from '../../static/svgIcons/Camera.svg';
import Cards from '../../static/svgIcons/Cards.svg';
import CompareChart from '../../static/svgIcons/CompareChart.svg';
import Globe from '../../static/svgIcons/Globe.svg';
import House from '../../static/svgIcons/House.svg';
import MobilePhone from '../../static/svgIcons/MobilePhone.svg';
import OneExcess from '../../static/svgIcons/OneExcess.svg';
import OnlinePortalPurple from '../../static/svgIcons/OnlinePortalPurple.svg';
import People from '../../static/svgIcons/People.svg';
import Pest from '../../static/svgIcons/Pest.svg';
import Rated1 from '../../static/svgIcons/Rated1.svg';
import RatedNo1 from '../../static/svgIcons/RatedNo1.svg';
import Screwdriver from '../../static/svgIcons/Screwdriver.svg';
import SecurityCover from '../../static/svgIcons/SecurityCover.svg';
import Stopwatch from '../../static/svgIcons/Stopwatch.svg';
import TimerSpeed from '../../static/svgIcons/TimerSpeed.svg';
import Toilet from '../../static/svgIcons/Toilet.svg';
import Tv from '../../static/svgIcons/Tv.svg';
import { iconsBannerIconSize } from '../components/petIconsBanner/PetIconsBanner.style';
import {
  colors,
  ProductPageHeaderBannerText,
  SvgColors,
  SvgIcon,
} from '../styles/sharedStyled';

export const BoilerPurpleIcon = styled(BoilerPurple)`
  ${iconsBannerIconSize};
`;

export const CompareChartIcon = styled(CompareChart)`
  ${iconsBannerIconSize};
`;
export const OneExcessIcon = styled(OneExcess)`
  ${iconsBannerIconSize};
`;

export const OnlinePortalPurpleIcon = styled(OnlinePortalPurple)`
  ${iconsBannerIconSize};
`;

export const Rated1Icon = styled(Rated1)`
  ${iconsBannerIconSize};
`;

export const TimerSpeedIcon = styled(TimerSpeed)`
  ${iconsBannerIconSize};
`;

export const BurstpipesSvg = styled(Burstpipes)`
  ${SvgColors};
`;

export const SecurityCoverSvg = styled(SecurityCover)`
  ${SvgColors};
`;

export const BoilerSvg = styled(Boiler)`
  ${SvgColors};
`;

export const ToiletSvg = styled(Toilet)`
  ${SvgColors};
`;

export const PestSvg = styled(Pest)`
  ${SvgColors};
`;

export const HouseSvg = styled(House)`
  ${SvgColors};
`;

export const CardsSvg = styled(Cards)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const BikeSvg = styled(Bike)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const GlobeSvg = styled(Globe)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const PeopleSvg = styled(People)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const ScrewdriverSvg = styled(Screwdriver)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const TvSvg = styled(Tv)`
  ${SvgColors};
`;

export const MobilePhoneSvg = styled(MobilePhone)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const CameraSvg = styled(Camera)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const HomeInsuranceHeaderText = styled(ProductPageHeaderBannerText)`
  @media (min-width: 768px) {
    max-width: 470px;
    width: 340px;
  }

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const HomeInsuranceTextBanner = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  letter-spacing: 0;
  line-height: normal;

  @media (min-width: 768px) {
    letter-spacing: 0.5px;
  }

  @media (min-width: 1024px) {
    font-size: 25px;
  }
`;

export const StopwatchSvg = styled(Stopwatch)`
  fill: ${colors.purple7};
  height: 65px;
  width: 65px;

  @media (min-width: 768px) {
    height: 70px;
    width: 70px;
  }

  @media (min-width: 1024px) {
    height: 100px;
    width: 100px;
  }
`;

export const RatedNo1Svg = styled(RatedNo1)`
  fill: ${colors.purple7};
  height: 65px;
  width: 65px;

  @media (min-width: 768px) {
    height: 70px;
    width: 70px;
  }

  @media (min-width: 1024px) {
    height: 100px;
    width: 100px;
  }
`;
