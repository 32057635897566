import React, { useState } from 'react';

import Accordion from '../accordion/Accordion';
import Icon from '../icons/Icon';
import { TabsData } from './MultiTabTable';
import {
  ExtraInformation,
  ExtraInformationContainer,
  HecLink,
  HecLinkContainer,
  IconContainer,
  MultiTabTableMobileContainer,
  TableTabContainer,
  TableTabInformationContainer,
  TableTabInformationText,
} from './MultiTabTableMobile.style';

interface MultiTabTableMobileProps {
  data: TabsData[];
}

const MultiTabTableMobile = ({ data }: MultiTabTableMobileProps) => {
  const [openIndex, setOpenIndex] = useState<number | undefined>(undefined);

  const handleOnClick = (index?: number) =>
    setOpenIndex(openIndex === index ? undefined : index);

  return (
    <MultiTabTableMobileContainer>
      {data.map((tab, index) => {
        const body = (
          <>
            {tab.information && (
              <ExtraInformationContainer data-testid="mobile-extra-information">
                <ExtraInformation>{tab.information}</ExtraInformation>
              </ExtraInformationContainer>
            )}
            <TableTabContainer data-testid="tab-content-container">
              {tab.extras.map((extra) => (
                <TableTabInformationContainer key={extra.text}>
                  <IconContainer>
                    <Icon type="primary" size={2}>
                      {extra.icon}
                    </Icon>
                  </IconContainer>
                  <TableTabInformationText>
                    {extra.text}
                  </TableTabInformationText>
                </TableTabInformationContainer>
              ))}
            </TableTabContainer>
            {tab.link && (
              <HecLinkContainer>
                <HecLink to={tab.link}>
                  Read more about our Home Emergency cover
                </HecLink>
              </HecLinkContainer>
            )}
          </>
        );
        return (
          <Accordion
            key={tab.title}
            title={tab.title}
            body={body}
            marker
            handleOnClick={handleOnClick}
            index={index}
            openIndex={openIndex}
            isProductPage
          />
        );
      })}
    </MultiTabTableMobileContainer>
  );
};

export default MultiTabTableMobile;
