import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import PolicyBooks, {
  PolicyDocumentsData,
} from '../../components/policyBooks/PolicyBooks';
import config from '../../config';
import useDisclaimer from '../../hooks/useDisclaimer';

const CarPolicyDocumentsPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const documentsData: PolicyDocumentsData = {
    productName: 'Car',
    productTypeNames: ['Car insurance', 'Optional extras'],
    productTypeData: {
      'Car insurance': [
        {
          policyType: 'bronze',
          policyWordingLink: `${config.DOCUMENTS_URL}/car/cdl/bronzecar_wording_0323.pdf`,
          ipidLink: `${config.DOCUMENTS_URL}/car/cdl/bronzecar_ipid_0323.pdf`,
        },
        {
          policyType: 'silver',
          policyWordingLink: `${config.DOCUMENTS_URL}/car/cdl/silvercar_wording_0323.pdf`,
          ipidLink: `${config.DOCUMENTS_URL}/car/cdl/silvercar_ipid_0323.pdf`,
        },
        {
          policyType: 'gold',
          policyWordingLink: `${config.DOCUMENTS_URL}/car/cdl/goldcar_wording_0323.pdf`,
          ipidLink: `${config.DOCUMENTS_URL}/car/cdl/goldcar_ipid_0323.pdf`,
        },
      ],
    },
  };

  return <PolicyBooks documentsData={documentsData} />;
};

export default CarPolicyDocumentsPage;

export const Head: HeadFC = () => (
  <>
    <title>Car insurance policy documents | Policy Expert</title>
    <meta
      name="description"
      content="Car insurance policy documents. Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
