import React, { useContext } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import TrustPilotNoStars from '../../../static/svgIcons/TrustPilotNoStars.svg';
import { ReviewsContext } from '../../context/ReviewsProvider';
import useEnableImpactV2Update from '../../hooks/useEnableImpactV2Update';
import Carousel from '../carousel/ImpactCarousel';

type Props = {
  data?: {
    headerText: string;
  };
  cards: JSX.Element[];
  isHomePage?: boolean;
};

const getOpacityOffset = (isMobile: boolean, isTablet: boolean) => {
  if (isMobile) {
    return 1;
  }
  if (isTablet) {
    return 2;
  }
  return 4;
};

const ReviewSection = ({ cards, data, isHomePage }: Props) => {
  const trustpilotReviews = useContext(ReviewsContext);
  const isHighTrustScore =
    parseFloat(trustpilotReviews.score?.trustScore) >= 4.8;
  const { isMobile, isTablet } = useBreakpoint();

  const v2Refresh = useEnableImpactV2Update();

  const opacityOffset = getOpacityOffset(isMobile, isTablet);

  return (
    <div className="bg-pxMint relative mx-auto lg:pt-130px md:pt-60px pt-60px z-100">
      {data && (
        <h2
          className={`${
            v2Refresh && isHomePage
              ? 'md:text-fontSize-40 lg:text-fontSize-65 md:mb-60px'
              : 'lg:text-fontSize-80 md:mb-70px lg:text-left'
          } text-center text-fontSize-35 max-w-300 mx-auto md:mx-0 md:max-w-full lg:max-w-1390 lg:mx-auto mb-50px lg:mb-90px`}
        >
          {data.headerText}
        </h2>
      )}
      <div className="max-w-1390 md:mr-120px md:ml-40px lg:mx-auto">
        <Carousel
          pagination={{ arrows: false, color: 'pxWhite' }}
          slidePadding
          cards={cards}
          title=""
          offsetfirstslide
          paddingTop={false}
          opacityOffset={opacityOffset}
        />
      </div>
      <div className="pt-30px lg:pt-40px flex flex-col md:flex-row justify-center items-center lg:justify-center md:pb-140px lg:pb-130px pb-95px">
        {trustpilotReviews.score && trustpilotReviews.numberOfReviews ? (
          <>
            <div className={`mx-auto md:mx-0 md:mr-20px`}>
              <TrustPilotNoStars
                className="icons-primary5 z-50 flex w-111 h-28 mb-15px md:mb-6px"
                fill={isHighTrustScore ? '#00b67a' : '#dcdce6'}
              />
            </div>
            <p className="text-fontSize-16 md:max-w-max text-center md:text-left mb-0 max-w-220">
              {`${
                trustpilotReviews.score.trustScore
              }/5 from over ${trustpilotReviews.numberOfReviews.total.toLocaleString()} reviews. 96.7% of users recommend us.`}
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ReviewSection;
