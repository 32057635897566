import React from 'react';

import { IpidHeaderContainer } from './IpidHeader.style';

type IpidHeaderProps = {
  header: React.ReactNode;
  subHeader: React.ReactNode;
};

const IpidHeader = ({ header, subHeader }: IpidHeaderProps) => (
  <IpidHeaderContainer>
    {header}
    {subHeader}
  </IpidHeaderContainer>
);

export default IpidHeader;
