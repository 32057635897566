import { styled } from 'styled-components';

import CircleWithTick from '../../../static/svgIcons/CircleWithTick.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const NotSureText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  margin-bottom: 25px;
`;

export const AccountLink = styled.a`
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  text-decoration: none;
`;

export const ExampleLabel = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: normal;
  margin-bottom: 25px;
`;

export const ExampleWrapper = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border-radius: 5px;
  padding: 20px;
`;

export const ExampleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${colors.gray7};
`;

export const ExampleText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  margin-bottom: 0;
`;

export const Tick = styled(CircleWithTick)``;
