import React, { FC, Fragment, ReactNode, useState } from 'react';
import { RenderModalBackdropProps } from 'react-overlays/Modal';
import { Wizard } from 'react-use-wizard';

import CoverSelector, { CoverType } from './CoverSelector';
import DeclineStep from './DeclineStep';
import {
  coverSelection,
  declineTitle,
  emergencyData,
  mightHaveCoverTitle,
  notAnEmergency,
  notSureWhichCover,
  perilData,
  perilListData,
  perilStepData,
  PreviousQuestionsType,
} from './HecData';
import MightHaveCover from './MightHaveCover';
import ModalNavBar from './ModalNavBar';
import PerilList from './PerilList';
import PerilStep from './PerilStep';
import TriageContentWrapper from './TriageContentWrapper';
import {
  ModalBackdrop,
  ModalComponent,
  TriageModalContainer,
  TriageModalWrapper,
} from './TriageModal.style';

type Props = {
  show: boolean;
  hideModal: () => void;
};

const TriageModal: FC<Props> = ({ show, hideModal }) => {
  const [coverType, setCoverType] = useState<CoverType>(null);
  const [coverInformation, setCoverInformation] = useState<string>();
  const [declineReason, setDeclineReason] = useState<string>();
  const [previousQuestions, setPreviousQuestions] = useState<
    PreviousQuestionsType[]
  >([]);

  const renderBackdrop = (props: RenderModalBackdropProps) => (
    <ModalBackdrop {...props} />
  );

  const closeModal = () => {
    hideModal();
    setCoverType(null);
    setDeclineReason('');
    setCoverInformation('');
    setPreviousQuestions([]);
  };

  const perilQuestions = perilData.reduce((listOfPerils, currentPeril) => {
    const validPerilSteps = currentPeril.questions.filter((question) =>
      question.availableProducts.includes(coverType)
    );

    const perilSteps = validPerilSteps.map((question) => {
      const isLastPerilStep =
        !!question.isLastPerilStep &&
        !!coverType &&
        question.isLastPerilStep[coverType];
      const noStepLocation =
        !!question.noStepLocation &&
        !!coverType &&
        question.noStepLocation[coverType];

      return (
        <Fragment key={question.title}>
          <TriageContentWrapper
            key={question.title}
            data={perilStepData}
            coverType={coverType}
          >
            <PerilStep
              peril={currentPeril.peril}
              previousQuestions={previousQuestions}
              setPreviousQuestions={setPreviousQuestions}
              currentQuestion={question}
              setCoverInformation={setCoverInformation}
              setDeclineReason={setDeclineReason}
              isLastPerilStep={isLastPerilStep}
              noStepLocation={noStepLocation}
            />
          </TriageContentWrapper>
        </Fragment>
      );
    });

    return [...listOfPerils, ...perilSteps];
  }, [] as Array<ReactNode>);

  return (
    <ModalComponent
      show={show}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <TriageModalWrapper>
        <TriageModalContainer>
          <Wizard
            header={
              <ModalNavBar
                hideModal={closeModal}
                previousQuestions={previousQuestions}
                setPreviousQuestions={setPreviousQuestions}
              />
            }
          >
            <TriageContentWrapper data={coverSelection}>
              <CoverSelector setCoverType={setCoverType} />
            </TriageContentWrapper>
            <TriageContentWrapper data={notSureWhichCover} />
            <TriageContentWrapper data={emergencyData} coverType={coverType} />
            <TriageContentWrapper data={notAnEmergency}>
              <DeclineStep />
            </TriageContentWrapper>
            <TriageContentWrapper data={perilListData} coverType={coverType}>
              <PerilList
                perilData={perilData}
                coverType={coverType}
                setDeclineReason={setDeclineReason}
              />
            </TriageContentWrapper>
            {perilQuestions}
            <TriageContentWrapper data={mightHaveCoverTitle}>
              <MightHaveCover coverInformation={coverInformation} />
            </TriageContentWrapper>
            <TriageContentWrapper data={declineTitle}>
              <DeclineStep declineReason={declineReason} />
            </TriageContentWrapper>
          </Wizard>
        </TriageModalContainer>
      </TriageModalWrapper>
    </ModalComponent>
  );
};

export default TriageModal;
