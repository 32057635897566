import React, { ReactNode } from 'react';

import GlassDoorDesktop from '../../../static/svgIcons/GlassDoorDesktop.svg';
import GlassDoorMobile from '../../../static/svgIcons/GlassDoorMobile.svg';
import Icon from '../icons/Icon';

type OfficeDetailsType = {
  icon: ReactNode;
  text: any;
};

type LocationBenefitsType = {
  icon: ReactNode;
  text: any;
};

export type OfficeHighlightsDataType = {
  bgColor: string;
  headerText: string;
  subHeaderText: string[];
  location: string;
  commuteDetails: OfficeDetailsType[];
  locationBenefits: LocationBenefitsType[];
  glassdoor?: boolean;
};

type Props = {
  data: OfficeHighlightsDataType;
  isMK?: boolean;
};

const OfficeHighlights = ({ data, isMK }: Props) => (
  <div
    className={`bg-pxGrey py-70px md:pt-130px ${
      isMK && isMK ? 'md:pb-12' : 'md:pb-0'
    } relative overflow-hidden`}
  >
    <div className="padding-x">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 justify-between">
        <div>
          <h1 className="pb-32px md:pb-82px max-w-510  text-pxCharcoal">
            {data.headerText}
          </h1>
          {data.subHeaderText.map((item: any) => (
            <p key={item} className={'max-w-510 text-pxCharcoal'}>
              {item}
            </p>
          ))}
          {data.glassdoor && (
            <>
              <div className="hidden md:block max-w-469 pt-32px">
                <GlassDoorDesktop />
              </div>
              <div className="md:hidden mb-32px max-w-469">
                <GlassDoorMobile />
              </div>
            </>
          )}
        </div>

        <div className="location-highlights-container">
          <div className="locationCommut pb-10 md:pb-82px">
            <p className="text-finerDetailsBodyCopy md:text-bodyCopy">
              <span className="font-camptonSemiBold">Location: </span>
              {data.location}
            </p>
            <p className="text-finerDetailsBodyCopy md:text-bodyCopy font-camptonSemiBold">
              Getting to the office:
            </p>
            {data.commuteDetails.map((item: any) => (
              <div
                key={item.text}
                className="flex flex-row mb-32px office-highlights-icon-container items-start"
              >
                <Icon type="primary" size={1}>
                  {item.icon}
                </Icon>
                {item.text}
              </div>
            ))}
          </div>
          <div className="locationBenefits">
            {data.locationBenefits.map((item: any) => (
              <div
                key={item.text}
                className="flex flex-row mb-32px office-highlights-icon-container items-center"
              >
                <Icon type="primary" size={1}>
                  {item.icon}
                </Icon>
                {item.text}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OfficeHighlights;
