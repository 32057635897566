import React from 'react';

import { Link } from 'gatsby';

import Shape from '../Shape';

type BottomLinksCardType = {
  body: string;
  url: string;
};

type BottomLinksType = {
  heading: string;
  body: BottomLinksCardType[];
};

export type SiteMapBannerType = {
  bgColor: string;
  heading: string;
  body: any;
  bottomLinks: BottomLinksType[];
};

type Props = {
  data: SiteMapBannerType;
};

const SiteMapBanner = ({ data }: Props) => (
  <div
    className={`${data.bgColor} py-82px md:py-130px relative overflow-hidden`}
  >
    <div className="padding-x">
      <div className="md:pb-82px">
        <h3 className="mb-32px">{data.heading}</h3>
        <div>{data.body}</div>
      </div>
      <div className="md:pt-82px md:border-t border-pxBlack flex flex-col md:flex-row relative z-30">
        {data.bottomLinks.map((item: any) => (
          <div
            key={item.heading}
            className="flex flex-col md:w-1/3 border-t  border-pxBlack pt-32px mt-32px md:border-none md:pt-0 md:mt-0"
          >
            <h3 className="mb-32px">{item.heading}</h3>
            <div>
              {item.body.map((item: any) => (
                <p key={item.url} className="font-semibold text-small">
                  <Link to={item.url}>{item.body}</Link>
                </p>
              ))}
            </div>
          </div>
        ))}
        <div className="mt-130px hidden xl:block">
          <Shape position="right-about-banner" />
        </div>
        <div className="mt-130px block xl:hidden">
          <Shape position="right-about-banner" />
        </div>
      </div>
    </div>
  </div>
);

export default SiteMapBanner;
