import React, { ReactNode, useState } from 'react';

import Icon from '../icons/Icon';
import {
  ExtraInformation,
  ExtraInformationContainer,
  HecLink,
  HecLinkContainer,
  MultiTabHeaderContainer,
  MultiTabHeaderText,
  MultiTabHeaderTitle,
  MultiTabInformationContainer,
  MultiTabTableBackgroundContainer,
  MultiTabTableContainer,
  TableContainer,
  TableHeader,
  TableHeaderTab,
  TableHeaderTabText,
  TableTabContainer,
  TableTabInformationContainer,
  TableTabInformationText,
} from './MultiTabTable.style';
import MultiTabTableMobile from './MultiTabTableMobile';

type ExtraData = {
  icon: ReactNode;
  text: string;
};

export type TabsData = {
  title: string;
  extras: ExtraData[];
  link?: string | undefined;
  information?: ReactNode;
};

export type MultiTabTableData = {
  headerText: string;
  subText: string;
  tabs: TabsData[];
};

interface MultiTabTableProps {
  data: MultiTabTableData;
  v2Refresh?: boolean;
}

const MultiTabTable = ({ data, v2Refresh }: MultiTabTableProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <MultiTabTableBackgroundContainer
      data-testid="multi-tab-table"
      $v2Refresh={v2Refresh}
    >
      <MultiTabTableContainer>
        <MultiTabHeaderContainer>
          <MultiTabHeaderTitle $v2Refresh={v2Refresh}>
            {data.headerText}
          </MultiTabHeaderTitle>
          <MultiTabHeaderText $v2Refresh={v2Refresh}>
            {data.subText}
          </MultiTabHeaderText>
        </MultiTabHeaderContainer>

        <TableContainer>
          <TableHeader>
            {data.tabs.map((tabtop, index) => (
              <TableHeaderTab
                $isSelected={selectedTab === index}
                key={tabtop.title}
                id={`${index}`}
                onClick={() => setSelectedTab(index)}
                tabIndex={index}
                role="button"
                data-testid={`desktop-${tabtop.title}`}
              >
                <TableHeaderTabText $isSelected={selectedTab === index}>
                  {tabtop.title}
                </TableHeaderTabText>
              </TableHeaderTab>
            ))}
          </TableHeader>
          <MultiTabInformationContainer>
            {data.tabs[selectedTab].information && (
              <ExtraInformationContainer data-testid="desktop-extra-information">
                <ExtraInformation>
                  {data.tabs[selectedTab].information}
                </ExtraInformation>
              </ExtraInformationContainer>
            )}
            <TableTabContainer
              $v2Refresh={v2Refresh}
              data-testid="tab-content-container"
            >
              {data.tabs[selectedTab].extras.map((item) => (
                <TableTabInformationContainer
                  $v2Refresh={v2Refresh}
                  key={item.text}
                >
                  <Icon type="primary" size={2}>
                    {item.icon}
                  </Icon>
                  <TableTabInformationText>{item.text}</TableTabInformationText>
                </TableTabInformationContainer>
              ))}
            </TableTabContainer>
            {data.tabs[selectedTab].link && (
              <HecLinkContainer>
                <HecLink to={data.tabs[selectedTab].link as string}>
                  Read more about our Home Emergency cover
                </HecLink>
              </HecLinkContainer>
            )}
          </MultiTabInformationContainer>
        </TableContainer>

        <MultiTabTableMobile data={data.tabs} />
      </MultiTabTableContainer>
    </MultiTabTableBackgroundContainer>
  );
};

export default MultiTabTable;
