import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonSemiBold,
} from '../styles/sharedStyled';

export const ComplaintsText = styled.p<{
  hasPurpleText?: boolean;
  hasMarginTop?: boolean;
}>`
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  margin-bottom: 0;
  color: ${({ hasPurpleText }) => hasPurpleText && colors.purple7};
  margin-top: ${({ hasMarginTop }) => hasMarginTop && '30px'};

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    margin: ${({ hasMarginTop }) => hasMarginTop && '0 0 40px 0'};
  }
`;

export const ComplaintsExternalLink = styled.a`
  margin-bottom: 0;
  font-size: 15px;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0;
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const ComplaintsTelephoneLink = styled.a`
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  color: ${colors.black};
  font-family: ${fontFamilyCamptonBook};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const ComplaintsContactUsContainer = styled.div<{
  $isPetInsurance?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $isPetInsurance }) => ($isPetInsurance ? '0' : '40px')};

  @media (min-width: 768px) {
    margin-bottom: 50px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
`;

export const ComplaintsContactUsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const ComplaintsContactUsText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  min-width: 90px;
  color: ${colors.purple7};
  margin: 0 -40px 0 0;

  @media (min-width: 768px) {
    font-size: 18px;
    margin-right: -30px;
  }
`;
