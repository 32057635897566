import { css, styled } from 'styled-components';

import PolicyExpertPlus from '../../../static/svgIcons/PolicyExpertPlus.svg';
import PolicyExpertSelect from '../../../static/svgIcons/PolicyExpertSelect.svg';
import { colors, fontFamilyCamptonRegular } from '../../styles/sharedStyled';

const logoStyle = css`
  height: 25px;
  width: 110px;

  @media (min-width: 768px) {
    height: 40px;
    width: 177px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 15px 25px 30px;

  @media (min-width: 768px) {
    padding: 15px 40px 50px;
  }
`;

export const LogoContainer = styled.div`
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-bottom: 25px;
  }
`;

export const SelectLogo = styled(PolicyExpertSelect)`
  ${logoStyle}
`;

export const PlusLogo = styled(PolicyExpertPlus)`
  ${logoStyle}
`;

export const WrapperTitle = styled.h1`
  font-family: ${fontFamilyCamptonRegular};
  color: ${colors.black};
  font-size: 20px;
  line-height: 23.28px;
  letter-spacing: normal;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 34.92px;
    padding-bottom: 25px;
  }
`;

export const WrapperSubtitle = styled.h2`
  font-family: ${fontFamilyCamptonRegular};
  color: ${colors.black};
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  padding-bottom: 15px;
`;
