import { styled } from 'styled-components';

import OnlinePortalSvg from '../../static/svgIcons/OnlinePortal.svg';
import OnlinePortalSvgUpdated from '../../static/svgIcons/OnlinePortalUpdated.svg';
import PetCallCentre from '../../static/svgIcons/PetCallCentre.svg';
import PiggyBankSvg from '../../static/svgIcons/PiggyBank.svg';
import PiggyBankSvgUpdated from '../../static/svgIcons/PiggyBankUpdated.svg';
import RatedNo1Svg from '../../static/svgIcons/RatedNo1.svg';
import RatedNo1SvgUpdated from '../../static/svgIcons/RatedNo1Updated.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonSemiBold,
} from '../styles/sharedStyled';

export const IconBannerText = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  letter-spacing: 0;

  @media screen and (min-width: 1024px) {
    font-size: 25px;
  }
`;

export const V2RefreshIconBannerText = styled.p`
  font-size: 18px;
  margin-bottom: 0;
  letter-spacing: 0;
  line-height: normal;
`;

export const PiggyBankIcon = styled(PiggyBankSvg)`
  height: 65px;

  @media screen and (min-width: 768px) {
    height: 70px;
  }

  @media screen and (min-width: 1024px) {
    height: 100px;
  }
`;

export const OnlinePortalIcon = styled(OnlinePortalSvg)`
  height: 65px;

  @media screen and (min-width: 768px) {
    height: 70px;
  }

  @media screen and (min-width: 1024px) {
    height: 100px;
  }
`;

export const RatedNo1Icon = styled(RatedNo1Svg)`
  height: 65px;

  @media screen and (min-width: 768px) {
    height: 70px;
  }

  @media screen and (min-width: 1024px) {
    height: 100px;
  }
`;

export const HeroBannerTitle = styled.h1`
  font-family: ${fontFamilyCamptonBook};
  font-size: 35px;
  line-height: 41px;
  margin: 40px 0 15px;
  color: ${colors.white};

  @media screen and (min-width: 768px) {
    font-size: 50px;
    line-height: 58px;
    margin: 0 0 25px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 60px;
    line-height: 68px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 80px;
    line-height: 80px;
  }
`;

export const V2RefreshHeroBannerTitle = styled.h1`
  font-family: ${fontFamilyCamptonBook};
  font-size: 35px;
  line-height: normal;
  letter-spacing: 0;
  margin: 40px 0 15px;
  color: ${colors.white};

  @media screen and (min-width: 768px) {
    font-size: 40px;
    line-height: normal;
    margin: 0 0 25px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 50px;
    line-height: normal;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 65px;
    line-height: normal;
    letter-spacing: 1px;
  }
`;

export const V2RefreshHeroBannerText = styled.p`
  color: ${colors.white};
  font-size: 20px;
  line-height: normal;
  margin-bottom: 40px;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 50px;
    text-align: left;
  }

  @media screen and (min-width: 1024px) {
    font-size: 28px;
    line-height: normal;
    margin-bottom: 50px;
  }
`;

export const HeroBannerText = styled.p`
  color: ${colors.white};
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 40px;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 25px;
    line-height: 29.1px;
    margin-bottom: 50px;
    text-align: left;
  }
  @media screen and (min-width: 1024px) {
    font-size: 28px;
    line-height: 32.76px;
    margin-bottom: 60px;
  }
`;

export const HeroBannerSpan = styled.span`
  font-family: ${fontFamilyCamptonSemiBold};
  color: ${colors.white};
`;

export const BackgroundContainer = styled.div<{
  $v2Refresh?: boolean;
  $isHomepage?: boolean;
}>`
  background-color: ${({ $v2Refresh, $isHomepage }) =>
    $v2Refresh && $isHomepage ? `${colors.white}` : `${colors.gray3}`};
`;

export const MobileHeaderImageContainer = styled.div<{
  $v2Refresh?: boolean;
}>`
  z-index: 100;
  position: absolute;
  top: ${({ $v2Refresh }) => ($v2Refresh ? '840px' : '780px')};
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 400px) {
    top: ${({ $v2Refresh }) => ($v2Refresh ? '840px' : '750px')};
  }

  @media screen and (min-width: 420px) {
    top: ${({ $v2Refresh }) => $v2Refresh && '800px'};
  }
`;

export const MobileHeaderImageContainerRefresh = styled.div`
  z-index: 100;
  position: absolute;
  top: 840px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 423px) {
    top: 800px;
  }

  width: 330px;
  height: 370px;

  @media screen and (min-width: 390px) {
    width: 360px;
    height: 392px;
  }
`;

export const IndexContactContainer = styled.div`
  position: relative;
  z-index: 100;
  margin-top: -225px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
`;
export const PiggyBankIconUpdated = styled(PiggyBankSvgUpdated)`
  height: 80px;

  @media screen and (min-width: 1024px) {
    height: 90px;
  }
`;

export const OnlinePortalIconUpdated = styled(OnlinePortalSvgUpdated)`
  height: 80px;

  @media screen and (min-width: 1024px) {
    height: 90px;
  }
`;

export const RatedNo1IconUpdated = styled(RatedNo1SvgUpdated)`
  height: 80px;

  @media screen and (min-width: 1024px) {
    height: 90px;
  }
`;

export const CallCentreIcon = styled(PetCallCentre)`
  height: 80px;

  @media screen and (min-width: 1024px) {
    height: 90px;
  }
`;

export const ShapeContainer = styled.div`
  position: relative;
  z-index: 100;
`;
