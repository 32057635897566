import React, { useEffect, useState } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import DropShapeCard from '../dropShapeCard/DropShapeCard';
import ImageComponent from '../imageComponent/ImageComponent';
import OtherJobs from '../otherJobs/OtherJobs';
import ShapeX from '../ShapeX';

type QuestionsType = {
  question: string;
  answer: any;
};

type EmployersType = {
  name: string;
  position: string;
  img: string;
  imageFile: IGatsbyImageData;
  location: string;
  questions: QuestionsType[];
};

export type CaseStudiesType = {
  employers: EmployersType[];
};

interface CaseStudiesProps {
  data: CaseStudiesType;
}

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('smooth-scroll')('a[href*="#"]');
}

const CaseStudies = ({ data }: CaseStudiesProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const selectedData = data.employers[selectedTab];
  const { isMobile } = useBreakpoint();

  const handleResize = () => {
    const selectionSection = document.querySelector('.selected-section-tab');
    if (isMobile) {
      selectionSection?.setAttribute(
        'id',
        'selected-employer-section selected-section'
      );
    } else {
      selectionSection?.setAttribute('id', 'selected-section');
    }
  };

  const scrollOnLoad = () => {
    window.scroll(0, 1200);
  };

  const displayAnchoredTabs = () => {
    if (window.location.href.indexOf('GarryElliot') != -1) {
      setSelectedTab(0);
      scrollOnLoad();
    }
    if (window.location.href.indexOf('GregDeacon') != -1) {
      setSelectedTab(1);
      scrollOnLoad();
    }
    if (window.location.href.indexOf('VickyImrie') != -1) {
      setSelectedTab(2);
      scrollOnLoad();
    }
    if (window.location.href.indexOf('LukePomeroy') != -1) {
      setSelectedTab(3);
      scrollOnLoad();
    }
    if (window.location.href.indexOf('DaniValente') != -1) {
      setSelectedTab(4);
      scrollOnLoad();
    }
    if (window.location.href.indexOf('WaqasJaveed') != -1) {
      setSelectedTab(5);
      scrollOnLoad();
    }
  };

  useEffect(() => {
    window && window.addEventListener('resize', handleResize);
    handleResize();
    displayAnchoredTabs();
  }, [isMobile]);

  const handleClick = () => {
    const optionList = document.querySelector('.option-list');
    if (optionList) {
      // @ts-ignore
      window.scrollTo(0, optionList.offsetTop);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="bg-pxGrey pt-70px md:pt-130px relative overflow-hidden case-studies">
      <div className="padding-x">
        <div className="w-2/3 md:w-1/2 pb-32px">
          <h2 className="pb-32px md:pb-82px">
            What’s it like to work for Policy Expert?
          </h2>
          <p className={'max-w-md'}>
            Click on each of the photos below for more information:
          </p>
        </div>
        <div>
          <div
            id=""
            className="pb-8 md:pb-82px grid gap-5 grid-cols-2 md:grid-cols-6 justify-between option-list"
          >
            {data.employers.map((employer, index) => (
              <div
                key={employer.name}
                className={`shape-wrapper relative ${
                  selectedTab == index ? 'active' : ''
                }`}
              >
                <DropShapeCard className="md:h-40" fullWidth>
                  <a
                    href="#selected-employer-section selected-section"
                    className={`bg-cover bg-no-repeat	bg-center w-full h-full block anchor-link`}
                    onClick={() => setSelectedTab(index)}
                  >
                    <ImageComponent
                      className="h-full w-full"
                      imageFile={employer.imageFile}
                      alt={employer.name}
                    />
                  </a>
                </DropShapeCard>
              </div>
            ))}
          </div>

          <div id="selected-section" className="selected-section-tab"></div>
          <div className="md:rounded-radius-50 rounded-radius-15 mb-32px md:mb-82px bg-pxWhite pb-32px md:grid md:grid-cols-.25n1 overflow-hidden selected-case">
            <div>
              <DropShapeCard className="md:h-272px w-full mx-auto max-w-full -top-7 ">
                <ImageComponent
                  className="h-full w-full"
                  imageFile={selectedData.imageFile}
                  alt={selectedData.name}
                />
              </DropShapeCard>

              <div className="px-7 ">
                <h3 className="text-2xl">{selectedData.name}</h3>
                <p className="m-0 font-light md:text-lg">
                  {selectedData.position}
                </p>
                <p className="mb-32px pb-32px border-b md:border-b-0 font-light md:text-lg">
                  {selectedData.location}
                </p>
              </div>
            </div>

            <div className="px-7 md:grid md:grid-cols-2 lg:grid-cols-3 md:pt-14 gap-7">
              {selectedData.questions.map((item: any) => (
                <div className="text-base" key={item.question}>
                  <p className="text-pxPurple font-semibold md:text-xl">
                    {item.question}
                  </p>
                  <div className="md:text-xl">{item.answer}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="btn-container w-full padding-x flex flex-col justify-center md:hidden">
        <button
          onClick={handleClick}
          className="mx-auto text-pxPink font-semibold mb-32px text-xl"
        >
          Back to top
        </button>
      </div>

      <OtherJobs />
      <ShapeX position="top-right" />
    </div>
  );
};

export default CaseStudies;
