import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../components/headerBanner/HeaderBanner';
import { Table } from '../components/table/Table';
import ImpactV2TermsBlock, {
  ImpactV2TermsBlockDataType,
} from '../components/termsBlock/ImpactV2TermsBlock';
import useDisclaimer from '../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../types/breakpoints';
import {
  AddressLine,
  Bold,
  BoldPurpleText,
  BulletPoint,
  ContactUsContainer,
  ContactUsList,
  ContactUsText,
  PrivacyPolicyIntroduction,
  PrivacyPolicyIntroductionWrapper,
  PrivacyPolicyLink,
  PurpleSpan,
  SubSectionHeader,
  Text,
} from './privacy-policy.style';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../images/privacy_policy_sm.png`}
        alt={`Privacy policy`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../images/privacy_policy_md.png`}
        alt={`Privacy policy`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../images/privacy_policy_lg.png`}
      alt={`Privacy policy`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const sectionLink = (anchorLinkName: string) => (
  <PrivacyPolicyLink to={`#${encodeURIComponent(anchorLinkName)}`}>
    {anchorLinkName}
  </PrivacyPolicyLink>
);

const PrivacyPolicyPage: React.FC<PageProps> = () => {
  useDisclaimer(null);
  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const termsBlockData: ImpactV2TermsBlockDataType = {
    description: (
      <PrivacyPolicyIntroductionWrapper>
        <PrivacyPolicyIntroduction>
          Your privacy is important to us. We want you to understand what we do
          with your data
        </PrivacyPolicyIntroduction>
      </PrivacyPolicyIntroductionWrapper>
    ),
    termsInformation: [
      {
        heading: '1.0 Introduction',
        body: (
          <div>
            <Text>
              This Customer Privacy Policy will help you understand what data we
              collect from you, why we collect it and what we do with it. This
              policy explains how we store and use your personal information
              when you interact with our website, or where we otherwise obtain
              or collect your personal information.
            </Text>
            <Text>
              Please note in this Privacy Policy, "our", "we" and "us" refer to
              QMetric Group Limited, trading as Policy Expert.
            </Text>
            <Text>
              This Privacy Policy applies to all products and services offered
              by us except where otherwise stated.
            </Text>

            <Text>
              Please note:{' '}
              <Bold>
                If you give us personal information about other people such as
                joint policy holders on home insurance or additional drivers for
                motor insurance you must make sure they are aware of this
                privacy policy.
              </Bold>
            </Text>
            <Text>
              We reserve the right to change our Customer Privacy Policy from
              time to time and without notice and encourage you to check
              regularly for any changes that may have taken place. If we decide
              to change our Customer Privacy Policy, we will update the Customer
              Privacy Policy page content and last modified date on our website.
            </Text>
            <Text>
              This Customer Privacy Policy was last modified on 13th June 2024.
            </Text>
          </div>
        ),
      },
      {
        heading: '2.0 Privacy Statement',
        body: (
          <div>
            <Text>
              We are committed to keeping your personal data safe and to
              ensuring the integrity and security of any personal data we may
              process.
            </Text>
            <Text>
              You should read this privacy notice carefully as it contains
              important information on the way in which we will process your
              personal data, in particular:
            </Text>

            <BulletPoint>
              The personal information we collect about you
            </BulletPoint>
            <BulletPoint>
              Our legal bases for processing your personal information
            </BulletPoint>
            <BulletPoint>What we do with your personal information</BulletPoint>
            <BulletPoint>
              Who your personal information may be shared with
            </BulletPoint>
            <BulletPoint>
              Your rights as a data subject under data protection legislation
            </BulletPoint>
            <BulletPoint>
              The period for which we will retain your data
            </BulletPoint>
          </div>
        ),
      },
      {
        heading: '3.0 Who are we?',
        body: (
          <div>
            <Text>
              We are Policy Expert and we are the controller of our customers
              and prospective customers personal data for the purposes of data
              protection legislation. We are committed to protecting your
              privacy and adhere to the relevant data protection legislation.
            </Text>

            <Text>Our Data Protection Officer contact details are:</Text>

            <Text>Mrs Audrey McDade, 110 Bishopsgate, London, EC2N 4AY.</Text>
            <Text>
              If you have any queries or requests concerning your personal
              information or how we process it, please contact us via the Data
              Protection Officer using the details in{' '}
              {sectionLink('section 12')}.
            </Text>
          </div>
        ),
      },
      {
        heading: '4.0 Personal Information we collect or obtain about you',
        body: (
          <div>
            <Text>
              We collect personal data relating to you and your use of our
              services from a variety of sources. These are detailed below along
              with descriptions of what we do with this information.
            </Text>

            <Text>
              We collect personal data directly from you such as your name,
              occupation, date of birth, address, telephone number, email
              address, and insurance history via:{' '}
            </Text>
            <BulletPoint>
              <Bold>Service data</Bold> - your personal data that is provided in
              the course of the use of our services.
            </BulletPoint>
            <BulletPoint>
              <Bold>Enquiry data</Bold> - information contained in any enquiry
              you submit to us regarding products and/or services.
            </BulletPoint>
            <BulletPoint>
              <Bold>Transaction data</Bold> - information relating to
              transactions, including purchases of goods and services, that you
              enter into with us and/or through our website.
            </BulletPoint>
            <BulletPoint>
              <Bold>Notification data</Bold> - information that you provide in
              any promotions, competitions, feedback forms.
            </BulletPoint>
            <BulletPoint>
              <Bold>Correspondence data</Bold> - information contained in or
              relating to any communication that you send to us.
            </BulletPoint>
            <br />
            <Text>
              We may also obtain your personal data, which is gained indirectly
              or passively in the following ways:
            </Text>
            <BulletPoint>
              <Bold>Usage Data</Bold> - information we gather from your use of,
              and interaction with, our website and the devices you use to
              access them.
            </BulletPoint>
            <BulletPoint>
              <Bold>Third party data</Bold> - publicly available data from third
              parties about you as an individual, including financial history
              and insurance claim information, information from insurance price
              comparison websites.
            </BulletPoint>
          </div>
        ),
      },
      {
        heading: '5.0 Our use of cookies',
        body: (
          <div>
            <Text>
              If you visit the Policy Expert website, we will collect
              information about your online activity in a "cookie". A cookie is
              a small text file that is downloaded onto 'terminal equipment'
              (e.g. a computer or smartphone). It allows us to recognise the
              device you are using and store some information about your
              preferences or past actions.
            </Text>

            <Text>
              The cookies we use are non-privacy intrusive, which means they do
              not contain personal information that is unique to you as user of
              our website.
            </Text>
            <Text>
              There are cookies we use that are strictly necessary and essential
              for the use of our website, without these you will not be able to
              complete online quotes or purchases. We also use cookies for our
              legitimate business interests to improve the website experience
              and to analyse the number of visitors to our website.
            </Text>
            <Text>
              We also use cookies for our legitimate business interests to
              improve the website experience and to analyse the number of
              visitors to our website.
            </Text>
            <Text>
              To see full details of all the cookies we use please visit{' '}
              <PrivacyPolicyLink to="/cookie-policy">
                www.policyexpert.co.uk/cookie-policy.
              </PrivacyPolicyLink>
            </Text>
            <Text>
              Our website contains links to other websites which are outside our
              control and are not covered by this Privacy Policy. If you access
              other sites using the links provided, the operators of these sites
              may collect information from you which will be used by them in
              accordance with their Privacy Policy.
            </Text>
          </div>
        ),
      },
      {
        heading: '6.0 How we use your personal data',
        body: (
          <div>
            <Text>
              We will only process your data where we have a legal basis for
              doing so.
            </Text>

            <Text>
              We use your data to communicate with you and to offer you our
              services and products. We will also use your data to improve or
              maintain the services we offer to you. We will never use your data
              for any other purpose unless we firstly gain your consent to do
              so.
            </Text>

            <Text>
              We rely on several legal bases for collecting and further
              processing your personal data. These are:
            </Text>

            <BulletPoint>Contract</BulletPoint>

            <BulletPoint>Legal Obligation</BulletPoint>

            <BulletPoint>Legitimate Interest</BulletPoint>

            <BulletPoint>
              Special Category Data and Criminal Offences
            </BulletPoint>

            <br />

            <SubSectionHeader>6.1 Contract</SubSectionHeader>

            <Text>
              We collect personal information about you when you apply for
              products or services. We will ask you to provide some information
              about yourself for security, identification, and verification
              purposes.
            </Text>
            <Text>
              We may also monitor and record communications with you (such as
              telephone calls and emails) for quality assurance, training, fraud
              prevention and regulatory compliance purposes.
            </Text>
            <Text>
              We will also collect personal data for the purposes of the
              performance of a contract with you, such as:
            </Text>

            <BulletPoint>
              Preparing a transaction, such as an insurance quotation.
            </BulletPoint>
            <BulletPoint>
              Processing a transaction, including that with a trusted third
              party (more details of the types of third parties we work with can
              be found on our{' '}
              <PrivacyPolicyLink to="/trusted-partners">
                website
              </PrivacyPolicyLink>
              ).
            </BulletPoint>
            <BulletPoint>
              Providing access to your policy details though your online
              account.
            </BulletPoint>
            <BulletPoint>
              Administering your policy, including handling any claims.
            </BulletPoint>
            <BulletPoint>Communicating with you about your policy.</BulletPoint>
            <BulletPoint>
              Offering renewal terms, or to inform you that we are unable to
              offer such terms.
            </BulletPoint>
            <BulletPoint>
              Carrying out automated decision making, only where this is
              necessary for entering into or the performance of a contract with
              you.
            </BulletPoint>
            <br />
            <Text>
              'Automated decision-making' is where a decision is made without
              human involvement, such as providing an instant insurance
              quotation on demand without the need of human intervention. It
              forms a necessary part of our quote process because it is the only
              practical way to process your data quickly enough to give you an
              immediate quote. It is your choice as to whether to accept the
              quote, meaning it does not create a contract at point of quote and
              a quote itself carries no detriment to you. Automated decisions do
              not form part of our claims or complaints processes.
            </Text>

            <SubSectionHeader>6.2 Legal Obligation</SubSectionHeader>

            <Text>
              We may also process your personal information to allow us to
              comply with certain legal obligations we are subject to. We are
              required to adhere to several regulatory frameworks, including
              that imposed by the regulatory authority, the Financial Conduct
              Authority (FCA), with whom we work openly and co-operatively. We
              will also work co-operatively with the national data protection
              authority, the Information Commissioner's Office (ICO), in
              relation to any data protection matters that may arise. We may
              also be required to share your information with law enforcement
              agencies.
            </Text>

            <SubSectionHeader>6.3 Legitimate Interest</SubSectionHeader>

            <Text>
              We may use your personal data for our legitimate business
              interests, whilst carefully considering and balancing any
              potential impact on you and your rights as a data subject under
              the relevant data protection legislation.
            </Text>

            <Text>
              This may include processing your personal data to allow us to
              provide the best services and customer experience and to ensure
              products are relevant and tailored to your needs.
            </Text>

            <Text>
              We will always ensure that our legitimate interests will never
              override your rights and freedoms under data protection
              legislation. As an example, we may process your personal data for
              the following purposes:
            </Text>

            <BulletPoint>
              For Direct Marketing purposes, relating to products and services
              that are compatible with the original purpose for which we
              originally gained the information.
            </BulletPoint>
            <BulletPoint>
              To communicate with you in relation to the product and services
              for which you received a quotation, including newsletters and
              marketing materials.
            </BulletPoint>
            <BulletPoint>
              To communicate important updates about our products or services,
              which we think may be of interest to you.
            </BulletPoint>
            <BulletPoint>
              For evidential purposes to effectively manage and maintain records
              of our relationships/communications with you.
            </BulletPoint>
            <BulletPoint>
              To enhance, modify, personalise or otherwise improve our services
              and communications for your benefit.
            </BulletPoint>
            <BulletPoint>
              To better understand how you interact with our website and content
              in order to enhance your customer experience.
            </BulletPoint>
            <BulletPoint>
              To determine the effectiveness of promotional campaigns to inform
              our marketing strategy.
            </BulletPoint>

            <br />

            <Text>
              In addition, we may perform statistical and other analysis on the
              personal data we collect, to help us understand and improve on how
              people use our products or services. Furthermore, we may use data
              to test our system to ensure functionality.
            </Text>
            <Text>
              Please note, you have the right to object to or restrict the
              processing for which we rely on legitimate interest as the legal
              basis. To do so, please use the contact details for our DPO which
              can be found in {sectionLink('section 12')}.
            </Text>
            <Text>
              Where we rely on legitimate interest as the legal basis for
              sending you direct marketing you have the right to object to this
              processing. You can opt-out or update your marketing preferences.
              Please see {sectionLink('section 11.5')} for more information.
            </Text>

            <SubSectionHeader>
              6.4 Special Category Data and Criminal Offences Data
            </SubSectionHeader>

            <Text>
              We may ask you for information about medical conditions you have
              (considered special category data) or criminal convictions you
              have received. This is lawfully processed as it is required for
              the performance of a contract or to take steps to enter into a
              contract. However, due to the sensitive nature of this type of
              data there are additional conditions required for processing this
              type of data. This data is processed as it is 'necessary for
              reasons of substantial public interest' as set out in the Data
              Protection Act (2018).
            </Text>
          </div>
        ),
      },
      {
        heading: '7.0 Who we share your data with',
        body: (
          <div>
            <Text>
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties other than necessary sharing.
              Necessary sharing includes governmental bodies or trusted third
              parties who assist us in operating our website, conducting our
              business, servicing you and for fraud prevention, so long as those
              parties agree to keep this information confidential.
            </Text>
            <Text>
              We share your data with third parties to help provide you with our
              products and services in the following ways:
            </Text>

            <BulletPoint>
              Third parties necessarily involved in the actual or proposed
              transaction such as the insurer to set up your policy.
            </BulletPoint>
            <BulletPoint>
              Trusted suppliers who provide us with services necessary for the
              transaction or processing of your order, for example print and
              mailing services.
            </BulletPoint>
            <BulletPoint>
              If you make a claim, the relevant claims administration company or
              department appointed by us to process your claim.
            </BulletPoint>
            <BulletPoint>
              Financial Conduct Authority and other regulatory or governing
              bodies including the Police as part of our legal or regulatory
              responsibilities.
            </BulletPoint>
            <BulletPoint>Auditors for auditing purposes.</BulletPoint>
            <BulletPoint>
              Payment card and financial credit companies who process payments
              from you on our behalf.
            </BulletPoint>
            <BulletPoint>
              If you choose to pay for your policy by instalments, the relevant
              premium finance company which will provide any finance. They may
              carry out credit, affordability, identity or anti-money laundering
              checks on you, potentially using third party agencies. If a check
              is carried out and depending what type of check it is, then a
              record of the search or other information may show on your credit
              file and might affect your credit rating.
            </BulletPoint>
            <BulletPoint>
              Recognised centralised insurance industry applications, policy and
              claims checking systems, for example the Motor Insurance
              Anti-Fraud and Theft Register, all DVLA databases, the UK Fraud
              Prevention Service (CIFAS), the Employers' Liability Tracing
              Office (ELTO) and the Claims and Underwriting Exchange (CUE). CUE
              holds a record of incidents reported to insurance companies by
              policyholders and third-party claimants and provides validation of
              the information provided by you, both about yourself and about any
              other person named on the application, against the information
              held on CUE and against other databases hosted by IDSL. Insurers
              may use this information to consider whether to accept the risk.
              These checks are completed for fraud prevention purposes. For
              details relating to information held about you on the Claims and
              Underwriting Exchange please visit&nbsp;
              <PrivacyPolicyLink
                to="https://www.mib.org.uk/privacy-and-cookie-policy/"
                target="_blank"
                rel="noreferrer"
              >
                www.insurancedatabases.co.uk
              </PrivacyPolicyLink>
            </BulletPoint>
            <BulletPoint>
              Credit reference agencies for the purpose of identity verification
              and fraud prevention, their search may appear on your credit
              report whether or not your quote proceeds but will not impact your
              credit history.
            </BulletPoint>
            <BulletPoint>
              Any party named in your policy and transactional documentation.
            </BulletPoint>
            <br />
            <Text>
              The full list of 3rd Parties we use is available on our&nbsp;
              <PrivacyPolicyLink to="/trusted-partners">
                website
              </PrivacyPolicyLink>
              .
            </Text>
          </div>
        ),
      },
      {
        heading: '8.0 Data processed outside the European Economic Area (EEA)',
        body: (
          <div>
            <Text>
              Some of the third-party suppliers we use may be located in
              countries outside of the EEA.
            </Text>
            <Text>
              For example, we use third party software suppliers to process data
              such as your email address and other contact details to help
              manage our customer service.
            </Text>
            <Text>
              When data is transferred outside of the EEA, it will be kept
              securely and only used for the purposes set out in this Privacy
              Policy. We will ensure the data has the equivalent protection as
              it would be if it were processed in the UK.
            </Text>
            <Text>
              This is done by the third party obtaining our prior written
              consent. We will only provide consent where that third party's
              country or organisation provides adequate safeguards, as
              recognised by the UK's Information Commissioner's Office (ICO).
              Where the third party is located in the United States, they must
              participate in and adhere to the UK-US Data Bridge and be listed
              on the Data Protection Framework List (DPF). If they are not
              listed, an alternative appropriate safeguard must be used to
              transfer data with equivalent protection as it would be if it were
              processed in the UK.
            </Text>
            <Text>
              Our insurance partners are the data controllers in respect of the
              processing they carry out, and such processing will be subject to
              their own Privacy Policies. We do not have any control over
              whether they process your personal data outside the EEA. You
              should refer to their Privacy Policy for this information.
            </Text>
          </div>
        ),
      },
      {
        heading: '9.0 How long do we retain your personal information?',
        anchorLinkName: 'section 9',
        body: (
          <div>
            <Text>
              GPDR nor the Data Protection Act dictate how long personal data
              can be held for. Instead, it is down to the controller's
              discretion on how long to hold personal data for. This discretion
              must involve careful consideration and strike a balance in being
              proportionate to the purpose the personal data has been collected
              and not being an overly onerous period.
            </Text>
            <Text>Anonymised data does not have the same requirements.</Text>
            <Text>
              The retention periods for Policy Expert have been considered
              carefully and will be reviewed at least annually. Below are Policy
              Expert's data retention periods for customer's data:
            </Text>
            <Table
              headers={['Data', 'Retention Period', 'Reason']}
              rows={[
                [
                  <>
                    Customer Data -{' '}
                    <PurpleSpan>
                      Quote Data for customer who has not purchased policy
                    </PurpleSpan>
                  </>,
                  '10 years from the submission of the last quote enquiry from the customer',
                  <>
                    Data retained for fraud prevention purposes.
                    <br />
                    <br />
                    It allows Policy Expert to carry out investigations when
                    needed into previous insurance applications a customer may
                    have made before purchasing a policy. Retaining a customer's
                    enquiry data allows any changes in how questions are
                    answered across multiple quote requests to be understood.
                    <br />
                    <br />
                    This enquiry data has been required for specific customers
                    by the Financial Ombudsman when reviewing a dispute between
                    the customer and Policy Expert.
                    <br />
                    <br />
                    Retaining the customer's data also allows for any complaints
                    to be handled, and for communications to be sent.
                  </>,
                ],
                [
                  <>
                    Customer Data -{' '}
                    <PurpleSpan>
                      Customer has purchased one or more policies
                    </PurpleSpan>
                  </>,
                  '25 years from the beginning of a new policy',
                  <>
                    Data retained to allow a customer to pursue a future
                    liability claim.
                    <br />
                    <br />
                    25 years is in line with industry practice and strikes the
                    balance between enabling future claims and retaining no
                    longer than what is reasonable and necessary.
                  </>,
                ],
              ]}
            />
            <br />
            <Text>
              If the personal data is no longer necessary, or where we no longer
              have a legal basis for processing your data, we will delete or
              fully anonymise the data we hold on to. However, non-personally
              identifiable data elements will be perpetually retained by us for
              statistical and analytical purposes.
            </Text>
          </div>
        ),
      },
      {
        heading: '10.0 Confidentiality & security of your personal information',
        body: (
          <div>
            <Text>
              Policy Expert do not sell, trade or otherwise transfer your
              personal information to outside parties other than those detailed
              in this Privacy Policy. Necessary sharing includes trusted third
              parties who assist us in operating our website, conducting our
              business or servicing you, and governmental bodies for fraud
              prevention, so long as those parties have adequate data protection
              measures in place that align with the requirements of the relevant
              data protection legislation.
            </Text>

            <Text>
              We ensure that we have appropriate technical and organisational
              controls in place to protect our business and our customers from
              unauthorised access or alteration to, disclosure or destruction of
              information we hold. In particular:
            </Text>

            <ul>
              <BulletPoint>
                We always require verification and identification from you (or
                your authorised third parties) prior to allowing access to your
                account or before discussing any personal and account details.
              </BulletPoint>
              <BulletPoint>
                We have appropriate security measures in place relating to the
                collection, storage and processing of your personal data,
                including physical security measures, to guard against
                unauthorised access to systems.
              </BulletPoint>
              <BulletPoint>
                We restrict access to personal information to employees,
                contractors and agents who need to know that information in
                order to process it for us, and who are subject to strict
                contractual confidentiality obligations and may be disciplined
                or terminated if they fail to meet these obligations.
              </BulletPoint>
            </ul>
          </div>
        ),
      },
      {
        heading: '11.0 Your rights as a Data Subject',
        body: (
          <div>
            <Text>
              As a data subject, for whom Policy Expert processes personal data,
              you can exercise certain rights in relation to the processing of
              your personal data, under data protection legislation, as detailed
              below:
            </Text>

            <BulletPoint>
              Your Right of Access and Right to Data Portability
            </BulletPoint>
            <BulletPoint>Your Right to Rectification</BulletPoint>
            <BulletPoint>Your Right to Erasure</BulletPoint>
            <BulletPoint>Your Right to Restriction of Processing</BulletPoint>
            <BulletPoint>Changing Your Marketing Preferences</BulletPoint>
            <BulletPoint>Your Right to Object to Processing</BulletPoint>

            <br />

            <SubSectionHeader>
              11.1 Your Right of Access and Right to Data Portability
            </SubSectionHeader>

            <Text>
              You have the 'right of access' to the personal data we hold on
              you. This will include information on any data we may have sourced
              indirectly, for example from the Claims and Underwriting Exchange
              (CUE) database. We can also provide your personal data in a
              structured format (as a csv file) if required. This is referred to
              as your 'right to data portability' under the Data Protection
              legislation.
            </Text>

            <Text>
              If you would like to receive a copy of the data we hold on you
              please contact us using the details in {sectionLink('section 12')}
              .
            </Text>

            <Text>
              We will respond to your request as soon as possible, and at the
              latest within one month of receiving your request.
            </Text>

            <SubSectionHeader id={'section 11.2'}>
              11.2 Your Right to Rectification
            </SubSectionHeader>

            <Text>
              We aim to keep your data up to date and accurate. If you find we
              have incorrect information about you, please let us know by
              contacting using the details in {sectionLink('section 12')}.
            </Text>

            <Text>
              This is referred to as your 'right to rectification' in data
              protection legislation.
            </Text>

            <SubSectionHeader>11.3 Your Right to Erasure</SubSectionHeader>

            <Text>
              We cannot delete the data we hold about you until the relevant
              data retention period has expired, at which point your data will
              automatically be deleted or anonymised (see{' '}
              {sectionLink('section 9')}
              &nbsp;above). However, if you wish to stop receiving
              communications from us you can do so, see&nbsp;
              {sectionLink('section 11.5')}
              &nbsp;below.
            </Text>

            <SubSectionHeader>
              11.4 Your Right to Restriction of Processing
            </SubSectionHeader>

            <Text>
              You have the right to request us to stop processing your data in
              certain circumstances, referred to as 'right to restriction of
              processing' in data protection legislation. These circumstances
              are:
            </Text>

            <BulletPoint>
              You believe we have inaccurate data about you. In this case we
              will update your data on your request (see{' '}
              {sectionLink('section 11.2')}
              &nbsp;above).
            </BulletPoint>

            <BulletPoint>
              Our retention period for storing your data has expired and we are
              going to delete the data, but you wish us to retain your
              information for legal reasons. To do so, please contact before the
              retention period has expired.
            </BulletPoint>

            <BulletPoint>
              You no longer wish to receive marketing information from us. In
              this case you can unsubscribe from marketing (see{' '}
              {sectionLink('section 11.5')}
              &nbsp;below).
            </BulletPoint>
            <br />

            <Text>
              To make this request, please contact us using the details in{' '}
              {sectionLink('section 12')}.
            </Text>

            <SubSectionHeader id={'section 11.5'}>
              11.5 Changing Your Marketing Preferences
            </SubSectionHeader>

            <Text>
              We may communicate with you from time to time if we deem there to
              be a case for legitimate interest such as communicating with you
              in relation to the products and services for which you received a
              quotation, in relation to products and services that are
              compatible with the original purpose for which we gained your
              information, or to communicate important updates about our
              products or services which we think may be of interest to you. If
              you want to opt out of your personal information being processed
              for marketing communication purposes, you can do so at any point.
              There are four ways in which you can do this.
            </Text>

            <BulletPoint marginBottom={'20px'}>
              By clicking the <Bold>unsubscribe</Bold> link which appears at the
              bottom of all our marketing emails.
            </BulletPoint>
            <BulletPoint marginBottom={'20px'}>By emailing us.</BulletPoint>
            <BulletPoint marginBottom={'20px'}>
              Home and pet customers can{' '}
              <PrivacyPolicyLink to="https://www.policyexpert.co.uk/login/">
                log into
              </PrivacyPolicyLink>{' '}
              their customer account and change their preferences themselves.
            </BulletPoint>
            <BulletPoint marginBottom={'20px'}>By calling us.</BulletPoint>

            <Text>
              Our contact details can be found in {sectionLink('section 12')}
            </Text>

            <Text>
              Please note that due to the advance preparation required for some
              types of communication, it may take up to 30 days for the
              communications to cease.
            </Text>

            <SubSectionHeader>
              11.6 Your Right to Object to Processing
            </SubSectionHeader>

            <Text>
              You may out-out of your personal information being processed by
              contacting us using our details found in{' '}
              {sectionLink('section 12')}.
            </Text>
            <Text>
              This is referred to as your 'right to object' under data
              protection legislation.
            </Text>
          </div>
        ),
      },
      {
        heading: '12.0 Contact Details',
        anchorLinkName: 'section 12',
        body: (
          <div>
            <Text>
              For data protection enquiries related to our product, please
              choose one of the following product specific contact methods to
              get in touch.
            </Text>

            {Object.entries({
              Home: {
                phoneNumber: '0330 0600 600',
                address: [
                  'Customer Relations',
                  'Policy Expert',
                  'Floor 1, Exchange House',
                  '450 Midsummer Boulevard',
                  'Milton Keynes',
                  'MK9 2EA',
                ],
              },
              Motor: {
                phoneNumber: '0330 0600 602',
                address: [
                  'Customer Relations',
                  'Policy Expert',
                  'Maxim 3',
                  '2 Parklands Avenue',
                  'Motherwell',
                  'ML1 4WQ',
                ],
              },
              Pet: {
                phoneNumber: '0330 0600 603',
                address: [
                  'Customer Relations',
                  'Policy Expert',
                  'Floor 1, Exchange House',
                  '450 Midsummer Boulevard',
                  'Milton Keynes',
                  'MK9 2EA',
                ],
              },
            }).map(([productLine, { address, phoneNumber }]) => (
              <>
                <BoldPurpleText>{productLine} Insurance</BoldPurpleText>
                <ContactUsContainer>
                  <ContactUsList>
                    <ContactUsText>Address: </ContactUsText>
                    <ContactUsText>
                      {address.map((line) => (
                        <>
                          <AddressLine>{line}</AddressLine>
                          <br />
                        </>
                      ))}
                    </ContactUsText>
                  </ContactUsList>
                  <ContactUsList>
                    <ContactUsText>Telephone: </ContactUsText>
                    <ContactUsText>{phoneNumber}</ContactUsText>
                  </ContactUsList>
                  <ContactUsList>
                    <ContactUsText>Email: </ContactUsText>
                    <PrivacyPolicyLink
                      to={`mailto:${productLine.toLowerCase()}-dpo@policyexpert.co.uk`}
                    >
                      {productLine.toLowerCase()}-dpo@policyexpert.co.uk
                    </PrivacyPolicyLink>
                  </ContactUsList>
                </ContactUsContainer>
              </>
            ))}
          </div>
        ),
      },
      {
        heading: '13.0 Complaints',
        body: (
          <div>
            <Text>
              Policy Expert will be more than happy to help you should you have
              any complaints about the processing of your personal data. Under
              data protection legislation, you have the right to lodge a
              complaint with the supervisory authority, the Information
              Commissioner's Office (ICO), who are the national authority
              responsible for the protection of personal data.
            </Text>

            <Text>
              A complaint can be made to the ICO via their website:&nbsp;
              <PrivacyPolicyLink to="https://ico.org.uk/">
                www.ico.org.uk
              </PrivacyPolicyLink>{' '}
              or through their helpline: 0303 123 1113.
            </Text>
          </div>
        ),
      },
    ],
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxPurple"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        styling="pl-35px md:pl-20 lg:pt-1.5 lg:px-6 max-w-1386 relative z-1"
      />
      <ImpactV2TermsBlock data={termsBlockData} section={''} />
    </>
  );
};

export default PrivacyPolicyPage;

export const Head: HeadFC = () => (
  <>
    <title>Privacy policy | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
