import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Shape from '../../components/Shape';
import {
  BackgroundContainer,
  TermsContainer,
  TermsExternalLink,
  TermsHeader,
  TermsInternalLink,
  TermsList,
  TermsListContainer,
  TermsListItem,
} from './autumn-referral-competition.style';

const competitionTermsData = [
  <div key="wrapper-1">
    <TermsListItem key={'sub-header-1'} $isBold $isSubHeader $isPurple>
      The offer:
    </TermsListItem>
    <TermsList>
      Until the end of September, we are upping our refer a friend reward to £65
      for every successful friend introduced to Policy Expert. Plus, if three or
      more of your introduced friends register, we’ll enter you into a prize
      draw to win either a Dyson V8 Cordless vacuum, or a Dyson Cool™ tower fan
      AM07, to help you keep your home perfect.
    </TermsList>
  </div>,
  <div key="wrapper-2">
    <TermsListItem $isBold $isSubHeader $isPurple>
      Terms and conditions:
    </TermsListItem>
    <TermsListItem $isBold $isSubHeader>
      £65 referral rewards
    </TermsListItem>
    <TermsListItem>
      To qualify for the £65 Amazon or Caffè Nero voucher, the referrer (you)
      must be a live Policy Expert customer.
    </TermsListItem>
    <TermsListItem>
      Your referred friend or relative must purchase an insurance policy from
      Policy Expert before 1st October 2024.
    </TermsListItem>
    <TermsListItem>
      If your friend purchases after 1st October and meets the usual referral
      requirements, you will be eligible for the £50 Amazon or Caffè Nero
      voucher.
    </TermsListItem>
    <TermsListItem>
      Your referred friend must purchase their policy directly from Policy
      Expert, either on{' '}
      <TermsInternalLink to="/">www.policyexpert.co.uk</TermsInternalLink>, or
      over the phone.
    </TermsListItem>
    <TermsListItem>
      Vouchers are subject to a 90-day validation period, starting from the
      policy start date. You will not be eligible for a voucher if the referred
      party’s policy is cancelled within 90 days of the policy start date.
    </TermsListItem>
    <TermsListItem>
      Vouchers will not be payable if the referred party currently holds or has
      previously held a policy with Policy Expert.
    </TermsListItem>
    <TermsListItem>
      The voucher will be sent to you within 21 days of the validation period
      lapsing.
    </TermsListItem>
    <TermsListItem>
      This offer cannot be used in conjunction with any other offer or promotion
      except for the Dyson giveaway.
    </TermsListItem>
    <TermsListItem>
      Neither Amazon nor Caffè Nero are affiliated with this competition in any
      way.
    </TermsListItem>
    <TermsListItem>
      Amazon.co.uk is not a sponsor of this promotion. Amazon.co.uk Gift Cards
      ("GCs") may be redeemed on the Amazon.co.uk website towards the purchase
      of eligible products listed in our online catalogue and sold by
      Amazon.co.uk or any other seller selling through Amazon.co.uk. GCs cannot
      be reloaded, resold, transferred for value, redeemed for cash or applied
      to any other account. Amazon.co.uk is not responsible if a GC is lost,
      stolen, destroyed or used without permission. See{' '}
      <TermsExternalLink
        href="https://www.amazon.co.uk/gc-legal"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.amazon.co.uk/gc-legal
      </TermsExternalLink>{' '}
      for complete terms and conditions. GCs are issued by Amazon EU S.à r.l.
      All Amazon ®, ™ & © are IP of Amazon.com , Inc. or its affiliates.
    </TermsListItem>
    <div key="wrapper-3">
      <TermsListItem key={'sub-header-3'} $isBold $isSubHeader>
        Dyson giveaway
      </TermsListItem>
      <TermsListItem>
        Until the 1st October 2024, customers who introduce three or more
        friends to Policy Expert will be entered into a prize draw to win either
        a Dyson V8 Cordless vacuum, or a Dyson Cool™ tower fan AM07.
      </TermsListItem>
      <TermsListItem>
        You must be a live customer to introduce friends to Policy Expert.
      </TermsListItem>
      <TermsListItem>
        To qualify as an introduction, your friend/s must register with their
        email address when prompted.
      </TermsListItem>
      <TermsListItem>
        Your friend/s do not need to purchase a policy in order for you to be
        entered in the prize draw.
      </TermsListItem>
      <TermsListItem>
        Customers will only be entered a maximum of once each to this prize
        draw.
      </TermsListItem>
      <TermsListItem>
        Introduced friends cannot be existing customers.
      </TermsListItem>
      <TermsListItem>
        The winner will be announced on 1st October 2024.
      </TermsListItem>
      <TermsListItem>
        Employees of Policy Expert and their relatives, or anyone involved with
        the promotion, are excluded from entering the prize draw.
      </TermsListItem>
      <TermsListItem>
        The winner can choose between the Dyson V8 Cordless vacuum and the Dyson
        Cool™ tower fan AM07.
      </TermsListItem>
      <TermsListItem>
        The prizes are non-transferable and there is no cash alternative in lieu
        of the prizes or any part of the prizes.
      </TermsListItem>
      <TermsListItem>
        The Dyson product is the complete prize, even if the winner opts for the
        Dyson product with a lower value.
      </TermsListItem>
      <TermsListItem>
        Dyson is not affiliated with this competition in any way.
      </TermsListItem>
    </div>
  </div>,
];

const AutumnReferralCompetition = () => {
  const { isTablet, isMobile } = useBreakpoint();

  return (
    <>
      <title>Terms and conditions Autumn Competition | Policy Expert</title>
      <BackgroundContainer>
        <TermsContainer>
          <TermsHeader data-testid="terms-header">
            This September, we’re celebrating friendship!
          </TermsHeader>
          <TermsListContainer data-testid="terms-list">
            {competitionTermsData.map((item) => item)}
          </TermsListContainer>
        </TermsContainer>
        {!isMobile && <Shape position="top-right-terms" />}
        {!isTablet && !isMobile && <Shape position="left-prize-terms" />}
      </BackgroundContainer>
    </>
  );
};

export default AutumnReferralCompetition;
