import React, { useContext, useEffect, useState } from 'react';

import { HeadFC, navigate } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import GridWithHeader, {
  CardData,
  GridWithHeaderData,
  OptionalsData,
} from '../components/gridWithHeader/GridWithHeader';
import ImpactButtonsV2HeaderBanner from '../components/headerBanner/ImpactButtonsV2HeaderBanner';
import HelpSection, {
  HelpSectionProps,
} from '../components/helpSection/HelpSection';
import InsuranceReviews from '../components/insuranceReviews/InsuranceReviews';
import PetInsuranceReviews from '../components/insuranceReviews/PetInsuranceReviews';
import ProductButtonsBanner, {
  ImpactV2ProductSelectionData,
} from '../components/productButtonsBanner/ProductButtonsBanner';
import ReviewSection from '../components/reviewBanner/ReviewSectionNoImage';
import Shape from '../components/Shape';
import SlantedBackground from '../components/slantedBackground/SlantedBackground';
import TriageModal from '../components/triageModal/TriageModal';
import config from '../config';
import { PhoneNumbersContext } from '../context/PhoneNumbersProvider';
import formatPhoneNumber from '../helpers/formatPhoneNumber';
import useDisclaimer from '../hooks/useDisclaimer';
import usePetChanges from '../hooks/usePetChanges';
import useProductSelection from '../hooks/useProductSelection';
import useQueryParams from '../hooks/useQueryParams';
import { pushHecTriageEventToDataLayer } from '../services/windowService';
import {
  CarSvg,
  HeroBannerHeader,
  HeroBannerText,
  HouseSvg,
  LinkText,
  PetContactText,
  PetContentText,
  SectionContainer,
  StaticImageContainer,
} from './claims.style';
import { Section } from './contact';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

interface WrapperProps {
  gridWithHeaderData: GridWithHeaderData;
  helpSectionData: HelpSectionProps;
}

export interface displayData {
  home: boolean;
  car: boolean;
  pet: boolean;
}

const productSelectionData: ImpactV2ProductSelectionData = [
  {
    product: 'home',
    buttonHeader: 'Home insurance',
    svg: <HouseSvg />,
  },
  {
    product: 'car',
    buttonHeader: 'Car insurance',
    svg: <CarSvg />,
  },
];

const ClaimsWrapper = ({
  gridWithHeaderData,
  helpSectionData,
}: WrapperProps) => {
  const isPetChangesEnabled = usePetChanges();
  const { product } = useQueryParams();
  const isProductSelection = useProductSelection(['home', 'car', 'pet']);
  const [section, toggleSection] = useState<Section>('');
  const { isMobile, isTablet } = useBreakpoint();

  const handlePathUpdate = (product: Section) => {
    navigate(`/claims/?product=${product}`);
  };

  useEffect(() => {
    if (product === 'home' || product === 'car' || product === 'pet') {
      toggleSection(product);
    } else {
      toggleSection('');
    }
  }, [product]);

  const heroBannerData = {
    title: (
      <HeroBannerHeader>We're here to help with your claim</HeroBannerHeader>
    ),
    text: <HeroBannerText>Please select your product</HeroBannerText>,
  };

  const isValidSection = ['home', 'car', 'pet'].includes(section);

  return (
    <div className="overflow-hidden">
      <ImpactButtonsV2HeaderBanner
        backgroundColor="bg-pxCharcoal"
        heroBannerData={heroBannerData}
        shapePosition={'bottom-left-claims-header-V2'}
        section={section}
        styling={`m-auto px-24px ${
          section ? 'md:h-347px lg:h-613px' : 'md:h-676px lg:h-743px'
        } lg:p-0`}
      >
        <ProductButtonsBanner
          productSelectionData={
            isPetChangesEnabled ? isProductSelection : productSelectionData
          }
          toggleSection={handlePathUpdate}
          section={section}
        />
      </ImpactButtonsV2HeaderBanner>
      {isValidSection && (
        <>
          <SectionContainer>
            <SlantedBackground
              backgroundColor={'bg-pxPurple'}
              margin="mb-1-minus"
              slantDirection={'top-left'}
              height
            />
            <GridWithHeader data={gridWithHeaderData} section={section} />
            {isMobile || isTablet ? (
              <StaticImageContainer>
                <StaticImage
                  src="../images/woman_on_phone.png"
                  alt="Help Centre"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="blurred"
                />
              </StaticImageContainer>
            ) : null}
          </SectionContainer>
          <div className="relative">
            <SlantedBackground
              id={'help'}
              backgroundColor={'bg-pxWhite'}
              margin="mb-1-minus"
              slantDirection={'top-right'}
              height
            />
            <Shape position="bottom-right" />
            <HelpSection {...helpSectionData} />
            <SlantedBackground
              backgroundColor={'bg-pxMint'}
              slantDirection={'top-left'}
              margin="mb-1-minus"
              height
            />
            <ReviewSection
              cards={
                isPetChangesEnabled ? PetInsuranceReviews : InsuranceReviews
              }
              data={reviewsTitle}
            />{' '}
          </div>
        </>
      )}
    </div>
  );
};

const reviewsTitle = { headerText: 'People love what we do...' };

const ClaimsPage: React.FC = () => {
  useDisclaimer(null);
  const [showTriageModal, setShowTriageModal] = useState(false);
  const phoneNumbers = useContext(PhoneNumbersContext);

  const helpSectionData: HelpSectionProps = {
    header: 'Help yourself',
    text: 'Your claims questions answered',
    button: {
      url: config.HELP_CENTRE_URL,
      label: 'Help Centre',
    },
  };

  const hecCard = {
    content:
      'Our Home Emergency policy covers you for an emergency. In other words, a sudden and unexpected incident in your home.',
    contactContent: 'Got a Home Emergency policy? Check you’re covered.',
    buttons: [
      {
        label: 'Check if you’re covered',
        primary: true,
        handleToggle: () => {
          pushHecTriageEventToDataLayer('click_triage_journey');
          setShowTriageModal(true);
        },
      },
    ],
    numberDescription: 'Or alternatively give us a call 24/7 on',
  };

  const cards: CardData[] = [
    {
      header: 'Contents and buildings claims',
      content:
        'If you want to make a claim on your home insurance. (Includes escape of water and theft)',
      contactContent: 'Contact us 24/7 using our online claims portal.',
      buttons: [
        {
          url: 'https://newclaim.trinityclaims.co.uk/',
          label: 'Start a new claim',
          primary: true,
        },
        {
          url: 'https://portal.trinityclaims.co.uk/customerportal/',
          label: 'Manage your claims',
          primary: false,
        },
      ],
      number: `${phoneNumbers.home?.claims}`,
      numberDescription: 'Prefer to talk to someone? Call us 24/7 on',
    },
    {
      header: 'Home Emergencies',
      number: `${phoneNumbers.home?.homeEmergencyCover}`,
      ...hecCard,
    },
    {
      header: 'Legal cover',
      content:
        'Need help on a legal issue? Call the number below for access to expert legal advice.',
      number: `${phoneNumbers.home?.legalCover}`,
      contactContent: 'Lines open 24hrs a day',
    },
    {
      header: 'Key cover',
      content:
        "Call this one if your keys' whereabouts is a mystery. Or if they’ve been damaged.",
      number: `${phoneNumbers.home?.keyCover}`,
      contactContent: 'Lines open 24hrs a day',
      callDetails: 'You’ll need your keycare fob if you’re calling',
    },
  ];

  const cardsCar: CardData[] = [
    {
      header: 'Car insurance',
      content:
        'Call this number 24/7 if you’ve had an accident and want to make a claim on your car insurance.',
      number: `${phoneNumbers.motor?.claims}`,
      contactContent: 'Lines open 24hrs a day',
    },
    {
      header: 'Breakdown assistance',
      content:
        'Call this number for 24/7 breakdown assistance and support here in the UK and in Europe.',
      contactContent: 'Lines open 24hrs a day',

      number: `${phoneNumbers.motor?.breakdownAssistance}`,
    },
  ];

  const optionals: OptionalsData[] = [
    {
      text: 'Legal assistance',
      phone: `${phoneNumbers.motor?.claims}`,
    },
    {
      text: 'Key cover insurance',
      phone: `${phoneNumbers.motor?.keyCover}`,
    },
    {
      text: 'Personal accident',
      phone: `${phoneNumbers.motor?.personalAccident}`,
    },
    {
      text: 'Replacement vehicle',
      phone: `${phoneNumbers.motor?.replacementVehicle}`,
    },
    {
      text: 'Excess protection',
      phone: `${phoneNumbers.motor?.excessProtection}`,
    },
  ];

  const cardsPet: CardData[] = [
    {
      header: 'Pet insurance claims',
      content: (
        <>
          <PetContentText>
            We hope your pet remains happy, safe, and well throughout the year.
            But if, for any reason, you need to make a claim, our dedicated team
            are here to help.
          </PetContentText>
          <PetContentText>
            To report a new claim, download and complete our{' '}
            <LinkText
              href={`${config.DOCUMENTS_URL}/pet/claims-forms/pet_claimsform_0424.pdf`}
              rel="noopener noreferrer"
              target="_blank"
            >
              claims form
            </LinkText>
             below. We’re happy to deal with your vet directly, so if your vet
            is able to, you can ask them to submit the claim on your behalf. One
            less thing for you to worry about.
          </PetContentText>
        </>
      ),

      buttons: [
        {
          url: `${config.DOCUMENTS_URL}/pet/claims-forms/pet_claimsform_0424.pdf`,
          label: 'Download claim form',
          primary: true,
        },
      ],
      contactContent: (
        <PetContactText>
          Prefer to talk to someone? Call us 24/7 on{' '}
          <LinkText
            $hasTelNumber
            href={`tel:${formatPhoneNumber(phoneNumbers.pet?.claims)}`}
          >
            {phoneNumbers.pet?.claims}
          </LinkText>
        </PetContactText>
      ),
    },
    {
      header: 'Pet loss support service',
      content: (
        <>
          <PetContentText>
            We can't prepare you for this moment, but we understand the pain
            losing a pet can cause.
          </PetContentText>
          <PetContentText>
            With our free and confidential support line, we're here so you never
            have to worry about facing the loss of your pet alone. You can talk
            with a fully trained member of our team by calling the number below.
          </PetContentText>
        </>
      ),
      number: `${phoneNumbers.pet?.lossSupportService}`,
      contactContent: 'Lines open 24hrs a day',
    },
  ];

  const gridWithHeaderData: GridWithHeaderData = {
    header: "Let's get started",
    subHeader: 'Got a claims question?',
    button: { label: 'Click here', section: '#help' },
    grid: 2,
    cards: cards,
    cardsCar: cardsCar,
    optionals: optionals,
    cardsPet: cardsPet,
  };

  return (
    <>
      <ClaimsWrapper
        gridWithHeaderData={gridWithHeaderData}
        helpSectionData={helpSectionData}
      />
      <TriageModal
        show={showTriageModal}
        hideModal={() => setShowTriageModal(false)}
      />
    </>
  );
};

export default ClaimsPage;

export const Head: HeadFC = () => (
  <>
    <title>How to make a claim | Policy Expert</title>
    <meta
      name="description"
      content="Read more about how to make a car or home insurance claim with Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
