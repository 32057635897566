import React from 'react';

import config from '../../config';
import { SemiBoldSpan } from '../../styles/sharedStyled';
import { CoverType } from './CoverSelector';
import {
  AccountLink,
  ExampleContainer,
  ExampleLabel,
  ExampleText,
  ExampleWrapper,
  NotSureText,
  Tick,
} from './HecData.style';
import IsItAnEmergency from './IsItAnEmergency';

export type PerilQuestion = {
  title: string;
  subtitle?: string;
  declineReason?: string;
  coverInformation?: string;
  availableProducts: CoverType[];
  isLastPerilStep?: { Select: boolean; Plus: boolean };
  noStepLocation?: { Select: number; Plus: number };
};

type StepLocation = {
  Select: number | 'Decline';
  Plus: number | 'Decline';
};

export type PerilDataType = {
  peril: string;
  stepLocation: StepLocation;
  questions: PerilQuestion[];
}[];

export type PreviousQuestionsType = {
  question: string;
  answer: 'Yes' | 'No';
};

export const coverSelection = {
  title: 'Just a few questions...',
  subtitle: 'Which Home Emergency product do you have?',
};

export const notSureWhichCover = {
  body: (
    <>
      <NotSureText>
        You can find out if you have Home Emergency Cover by logging into your{' '}
        <AccountLink
          href={config.HOME_LOGIN_URL}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Home Account Login"
        >
          online account here
        </AccountLink>
        . Click on the <SemiBoldSpan>View policy</SemiBoldSpan> button displayed
        at the top of the page.
      </NotSureText>
      <NotSureText>
        If you have Home Emergency cover included, it will be displayed with a
        tick.
      </NotSureText>
      <ExampleLabel>(See example below)</ExampleLabel>
      <ExampleWrapper>
        <ExampleContainer data-testid="example-container">
          <ExampleText>Home Emergency Plus</ExampleText>
          <Tick />
        </ExampleContainer>
      </ExampleWrapper>
    </>
  ),
};

export const emergencyData = {
  subtitle: 'Is it an emergency?',
  hasCoverLogo: true,
  body: <IsItAnEmergency />,
};

export const notAnEmergency = {
  title:
    'Our Home Emergency policy covers you for an emergency. In other words, a sudden and unexpected incident in your home.',
};

export const perilListData = {
  title: 'What can we help you with?',
  hasCoverLogo: true,
};

export const perilStepData = {
  hasCoverLogo: true,
};

export const mightHaveCoverTitle = {
  title: 'Based on your answers, it looks like you might have cover.',
};

export const declineTitle = {
  title:
    "Based on your answers, we're sorry but you’re not covered under your Home Emergency cover.",
};

export const perilData: PerilDataType = [
  {
    peril: 'Boiler',
    stepLocation: { Select: 5, Plus: 5 },
    questions: [
      {
        title: 'Has your boiler been serviced in the last 15 months?',
        declineReason:
          "We don't cover any boiler that hasn't been serviced in the last 15 months.",
        availableProducts: ['Select', 'Plus'],
      },
      {
        title: 'Have you got the service documents to prove this?',
        declineReason:
          'We require proof of a service within the last 15 months.',
        availableProducts: ['Select', 'Plus'],
      },
      {
        title: 'Is your boiler under 15 years old?',
        declineReason: "We don't cover any boiler over 15 years old.",
        availableProducts: ['Select', 'Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
    ],
  },
  {
    peril: 'Plumbing',
    stepLocation: { Select: 8, Plus: 8 },
    questions: [
      {
        title: 'Do you have a frozen pipe?',
        availableProducts: ['Select', 'Plus'],
        noStepLocation: { Select: 10, Plus: 10 },
      },
      {
        title: 'Is the affected pipe causing damage inside your home?',
        declineReason: "We don't cover pipes which have not caused any damage.",
        availableProducts: ['Select', 'Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
      {
        title: 'Do you have a leak?',
        availableProducts: ['Select', 'Plus'],
        noStepLocation: { Select: 12, Plus: 12 },
      },
      {
        title: 'Is the affected pipe causing damage inside your home?',
        declineReason:
          "We don't cover repairs to any outside pipes or taps, or leaks where water is safely escaping down a drain, or repairs to domestic appliances.",
        availableProducts: ['Select', 'Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
      {
        title: 'Do you have a blockage?',
        declineReason:
          'We only fix damage caused by frozen, blocked or leaking pipes.',
        availableProducts: ['Select', 'Plus'],
      },
      {
        title: 'Is the blockage causing damage, or likely to cause damage?',
        declineReason:
          "We don't cover blockages which aren't causing damage, unless they are likely to cause damage if the blockage remains.",
        availableProducts: ['Select', 'Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
    ],
  },
  {
    peril: 'Toilets',
    stepLocation: { Select: 14, Plus: 14 },
    questions: [
      {
        title: 'Is there a leak coming from your toilet?',
        coverInformation:
          "If we can't see and identify the source of the leak, we may only be able to switch the water off to prevent further damage.",
        availableProducts: ['Select', 'Plus'],
        noStepLocation: { Select: 15, Plus: 15 },
        isLastPerilStep: { Select: true, Plus: true },
      },
      {
        title: 'Is the damage to the flushing mechanism?',
        availableProducts: ['Select', 'Plus'],
        noStepLocation: { Select: 16, Plus: 16 },
        isLastPerilStep: { Select: true, Plus: true },
      },
      {
        title: 'Is the toilet blocked?',
        declineReason:
          "We only cover damage to the flushing mechanism, accessible leaks, or accidental blockage. We don't cover replacement ceramics, deliberate blockage or saniflow toilets.",
        availableProducts: ['Select', 'Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
    ],
  },
  {
    peril: 'Drainage',
    stepLocation: { Select: 17, Plus: 17 },
    questions: [
      {
        title: 'Is the affected drain inside your property boundary?',
        declineReason: "We don't cover public drains.",
        availableProducts: ['Select', 'Plus'],
      },
      {
        title: 'Can the drain be accessed?',
        declineReason:
          "We don't cover the cost of identifying and accessing the source. If we can't see and identify the source of the leak, we won't accept your claim.",
        availableProducts: ['Select', 'Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
    ],
  },
  {
    peril: 'Security',
    stepLocation: { Select: 19, Plus: 19 },
    questions: [
      {
        title: 'Is there damage to an external door?',
        availableProducts: ['Select', 'Plus'],
        noStepLocation: { Select: 21, Plus: 21 },
      },
      {
        title: 'Does it make your home insecure?',
        declineReason:
          "We don't cover damage to doors if your home is still secure.",
        availableProducts: ['Select', 'Plus'],
        coverInformation:
          "We may be able to provide a temporary repair to make your home secure, but your policy won't cover a permanent repair (i.e replacement glass).",
        isLastPerilStep: { Select: true, Plus: true },
      },
      {
        title: 'Is there damage to a publicly accessible window?',
        declineReason:
          "We don't cover damage to doors or windows if your home is still secure.",
        availableProducts: ['Select', 'Plus'],
      },
      {
        title: 'Does it make your home insecure?',
        declineReason:
          "We don't cover damage to doors or windows if your home is still secure.",
        availableProducts: ['Select', 'Plus'],
        coverInformation:
          "We may be able to provide a temporary repair to make your home secure, but your policy won't cover a permanent repair (i.e replacement glass).",
        isLastPerilStep: { Select: true, Plus: true },
      },
    ],
  },
  {
    peril: 'Pest infestation',
    stepLocation: { Select: 23, Plus: 23 },
    questions: [
      {
        title:
          "Is the pest a wasps' nest, hornets' nest, mice, rats or cockroaches?",
        declineReason:
          "We don't cover any other pests, including bees or bats.",
        availableProducts: ['Select', 'Plus'],
      },
      {
        title: 'Is the pest infestation within your home? ',
        subtitle:
          '(excluding garden, detached garage, outbuilding, shed or greenhouse)',
        declineReason: 'We only cover pests within your home.',
        availableProducts: ['Select', 'Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
    ],
  },
  {
    peril: 'Electrics',
    stepLocation: { Select: 'Decline', Plus: 25 },
    questions: [
      {
        title:
          'Has the electricity failed to a socket, switch or light fitting inside your home?',
        declineReason:
          "We don't cover interruption or disconnection of public services to your home, or any wiring outside your home.",
        availableProducts: ['Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
    ],
  },
  {
    peril: 'Gas',
    stepLocation: { Select: 'Decline', Plus: 26 },
    questions: [
      {
        title: 'Can you smell gas?',
        coverInformation: `If you think you have a gas leak, you should call the National Gas Emergencies number immediately on 0800 111 999.

 You should also:
* Open all windows and doors to let fresh air in.
* Avoid switching anything electrical on or off.
* Put out any candles and refrain from lighting a cigarette or striking a match. An open flame could cause the gas to catch fire.`,
        availableProducts: ['Plus'],
        noStepLocation: { Select: 26, Plus: 27 },
        isLastPerilStep: { Select: true, Plus: true },
      },
      {
        title: 'Has the gas failed to one or more of your gas appliances?',
        declineReason:
          "We don't cover interruption or disconnection of public services to your home, or the breakdown of any domestic appliance. If you think you have a gas leak, you should call the National Gas Emergencies number immediately on 0800 111 999.",
        coverInformation:
          'If you think you have a gas leak, you should call the National Gas Emergencies number immediately on 0800 111 999.',
        availableProducts: ['Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
    ],
  },
  {
    peril: 'Roofing',
    stepLocation: { Select: 'Decline', Plus: 28 },
    questions: [
      {
        title: 'Is your roof well maintained?',
        declineReason:
          "We don't cover water leaks that are caused by poor maintenance, blocked guttering or wear and tear.",
        availableProducts: ['Plus'],
      },
      {
        title: 'Is it causing damage to the inside of your home?',
        declineReason:
          "We don't cover roof damage which isn't causing (or isn't likely to cause) damage to the inside of your home by water entering.",
        availableProducts: ['Plus'],
        isLastPerilStep: { Select: true, Plus: true },
      },
    ],
  },
];
