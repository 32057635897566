import { ReactNode, useContext, useEffect } from 'react';

import { DisclaimerContext } from '../context/DisclaimerProvider';

const useDisclaimer = (disclaimer: ReactNode) => {
  const disclaimerContext = useContext(DisclaimerContext);
  useEffect(() => {
    if (disclaimerContext?.setCurrentDisclaimer) {
      disclaimerContext.setCurrentDisclaimer(disclaimer);
    }
  }, [disclaimerContext]);
};

export default useDisclaimer;
