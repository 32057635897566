import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Ipid, { IpidProps } from '../../components/ipid/Ipid';
import { IpidContent } from '../../components/ipid/Ipid.style';
import IpidHeader from '../../components/ipidHeader/IpidHeader';
import {
  IpidHeaderTitle,
  IpidSubHeaderContainer,
  IpidSubHeaderText,
} from '../../components/ipidHeader/IpidHeader.style';
import Shape from '../../components/Shape';
import config from '../../config';
import useDisclaimer from '../../hooks/useDisclaimer';
import useUpdatedKeyCareDocuments from '../../hooks/useUpdatedKeyCareDocuments';
import { MotorDocsContainer, SemiBoldSpan } from '../../styles/sharedStyled';

const headerData = {
  header: (
    <IpidHeaderTitle $hasPadding>
      Optional extras policy wording
    </IpidHeaderTitle>
  ),
  subHeader: (
    <IpidSubHeaderContainer>
      <IpidSubHeaderText>
        The products outlined in this booklet only apply to you if shown in your
        Insurance Welcome Letter and Optional Extras Schedule.
        <SemiBoldSpan>
          View the document based on when you bought your policy.
        </SemiBoldSpan>
      </IpidSubHeaderText>
    </IpidSubHeaderContainer>
  ),
};

const carDataAug24: IpidProps = {
  sectionTitle: 'Car insurance',
  data: [
    {
      heading: 'If your purchase date is on or after 16th Aug 2024',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/caroptionalextras_wording_0624.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 1st Jun 2023 and 15th Aug 2024',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/caroptionalextras_wording_0323.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between the 1st Jul 2022 and the 31st of May 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/caroptionalextras_wording_0622.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 19th Nov 2021 and 30th Jun 2022',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_motor_opex_1121.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 1st Nov 2021 and 18th Nov 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_nov21.pdf`,
        },
      ],
    },
    {
      heading:
        'If your cover start date is between 23rd Jun 2021 and 31st Oct 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_jun21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 3rd Feb 2021 and 22nd Jun 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_jan21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 17th Dec 2020 and 2nd Feb 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_dec20.pdf`,
        },
      ],
    },
    {
      heading: 'Between 1st Sep 2020 and 16th Dec 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_sep20.pdf`,
        },
      ],
    },
    {
      heading: 'Between 1st Jul 2020 and 31st Aug 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex.pdf`,
        },
      ],
    },
    {
      heading: 'Between 14th Jan 2020 and 30th Jun 2020',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/surething_car_optionalextras_14jan20_stabilis.pdf`,
          linkText: 'For policy numbers starting with STB',
        },
        {
          url: `${config.DOCUMENTS_URL}/motor/surething_car_optionalextras_14jan20.pdf`,
          linkText: 'For all other policy numbers',
        },
      ],
    },
  ],
};

const carData: IpidProps = {
  sectionTitle: 'Car insurance',
  data: [
    {
      heading: 'If your purchase date is on or after the 1st June 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/caroptionalextras_wording_0323.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between the 1st Jul 2022 and the 31st of May 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/caroptionalextras_wording_0622.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 19th Nov 2021 and 30th Jun 2022',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_motor_opex_1121.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 1st Nov 2021 and 18th Nov 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_nov21.pdf`,
        },
      ],
    },
    {
      heading:
        'If your cover start date is between 23rd Jun 2021 and 31st Oct 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_jun21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 3rd Feb 2021 and 22nd Jun 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_jan21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 17th Dec 2020 and 2nd Feb 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_dec20.pdf`,
        },
      ],
    },
    {
      heading: 'Between 1st Sep 2020 and 16th Dec 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex_sep20.pdf`,
        },
      ],
    },
    {
      heading: 'Between 1st Jul 2020 and 31st Aug 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_car_opex.pdf`,
        },
      ],
    },
    {
      heading: 'Between 14th Jan 2020 and 30th Jun 2020',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/surething_car_optionalextras_14jan20_stabilis.pdf`,
          linkText: 'For policy numbers starting with STB',
        },
        {
          url: `${config.DOCUMENTS_URL}/motor/surething_car_optionalextras_14jan20.pdf`,
          linkText: 'For all other policy numbers',
        },
      ],
    },
  ],
};

const vanDataAug24: IpidProps = {
  sectionTitle: 'Van insurance',
  data: [
    {
      heading: 'If your purchase date is on or after 16th Aug 2024',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/vanoptionalextras_wording_0624.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 1st Jun 2023 and 15th Aug 2024',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/vanoptionalextras_wording_0323.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between the 1st Jul 2022 and the 31st of May 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/vanoptionalextras_wording_0622.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 19th Nov 2021 and 30th Jun 2022',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_1121.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 1st Nov 2021 and 18th Nov 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_nov21.pdf`,
        },
      ],
    },
    {
      heading:
        'If your cover start date is between 23rd Jun 2021 and 31st Oct 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_jun21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 3rd Feb 2021 and 22nd Jun 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_jan21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 17th Dec 2020 and 2nd Feb 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_dec20.pdf`,
        },
      ],
    },
    {
      heading: 'Between 1st Sep 2020 and 16th Dec 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_sep20.pdf`,
        },
      ],
    },
    {
      heading: 'Between 1st Jul 2020 and 30th Aug 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/PolicyExpert_OptionalExtras_Van.pdf`,
        },
      ],
    },
    {
      heading: '14th Jan 2020 to 30th Jun 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/surething_van_optionalextras_14jan20.pdf`,
        },
      ],
    },
  ],
};

const vanData: IpidProps = {
  sectionTitle: 'Van insurance',
  data: [
    {
      heading: 'If your purchase date is on or after the 1st June 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/vanoptionalextras_wording_0323.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between the 1st Jul 2022 and the 31st of May 2023',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/vanoptionalextras_wording_0622.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 19th Nov 2021 and 30th Jun 2022',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_1121.pdf`,
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 1st Nov 2021 and 18th Nov 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_nov21.pdf`,
        },
      ],
    },
    {
      heading:
        'If your cover start date is between 23rd Jun 2021 and 31st Oct 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_jun21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 3rd Feb 2021 and 22nd Jun 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_jan21.pdf`,
        },
      ],
    },
    {
      heading: 'Between 17th Dec 2020 and 2nd Feb 2021',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_dec20.pdf`,
        },
      ],
    },
    {
      heading: 'Between 1st Sep 2020 and 16th Dec 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/pe_van_opex_sep20.pdf`,
        },
      ],
    },
    {
      heading: 'Between 1st Jul 2020 and 30th Aug 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/PolicyExpert_OptionalExtras_Van.pdf`,
        },
      ],
    },
    {
      heading: '14th Jan 2020 to 30th Jun 2020',
      links: [
        {
          linkText: 'View document',
          url: `${config.DOCUMENTS_URL}/motor/surething_van_optionalextras_14jan20.pdf`,
        },
      ],
    },
  ],
};

const MotorDocsOptionalExtrasPage: React.FC<PageProps> = () => {
  useDisclaimer(null);
  const isUpdatedKeyCareDocumentsEnabled = useUpdatedKeyCareDocuments();
  const optionalExtrasCarDocuments = isUpdatedKeyCareDocumentsEnabled
    ? carDataAug24
    : carData;
  const optionalExtrasVanDocuments = isUpdatedKeyCareDocumentsEnabled
    ? vanDataAug24
    : vanData;

  const { isDesktop } = useBreakpoint();

  return (
    <MotorDocsContainer>
      <IpidHeader {...headerData} />
      <IpidContent>
        <Ipid {...optionalExtrasCarDocuments} />
        <Ipid {...optionalExtrasVanDocuments} />
      </IpidContent>
      {isDesktop && <Shape position="right-policy-books" />}
      {isDesktop && <Shape position="left-policy-books" />}
    </MotorDocsContainer>
  );
};

export default MotorDocsOptionalExtrasPage;

export const Head: HeadFC = () => (
  <>
    <title>Optional extras documents | Policy Expert</title>
    <meta
      name="description"
      content="Optional extras policy documents. Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
