import { css, styled } from 'styled-components';

import ArrowLeftSvg from '../../../static/svgIcons/ArrowLeftCircle.svg';
import CrossSvg from '../../../static/svgIcons/CircleWithCross.svg';

const iconStyle = css`
  height: 25px;
  width: 25px;

  @media (min-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;

export const ModalNavBarContainer = styled.div<{ $hideBackButton: boolean }>`
  display: flex;
  padding: 5px 5px 0;
  justify-content: ${({ $hideBackButton }) =>
    $hideBackButton ? 'end' : 'space-between'};
`;

export const BackButton = styled(ArrowLeftSvg)`
  ${iconStyle}
`;

export const CloseButton = styled(CrossSvg)`
  ${iconStyle}
`;
