import { styled } from 'styled-components';

import TrustPilotNoStars from '../../../static/svgIcons/TrustPilotNoStars.svg';
import { colors } from '../../styles/sharedStyled';

export const PetReviewBannerBackgroundContainer = styled.div`
  overflow-x: clip;
  background-color: ${colors.green1};
  position: relative;
  z-index: 100;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 60px 0 0;

  @media (min-width: 768px) {
    padding: 100px 0 0;
    justify-content: flex-start;
  }

  @media (min-width: 1390px) {
    padding: 130px 0;
    justify-content: center;
    align-items: center;
  }
`;

export const PetReviewBannerDesktopContainer = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    max-width: 1390px;
  }
`;

export const PetReviewBannerTitle = styled.h2`
  max-width: 320px;
  margin: 0 auto 50px;
  padding: 0 24px;
  text-align: center;
  font-size: 35px;
  line-height: normal;

  @media (min-width: 768px) {
    margin: 0 0 70px 40px;
    max-width: 100%;
    width: 300px;
    padding: 0;
    font-size: 40px;
    text-align: left;
  }

  @media (min-width: 1024px) {
    width: 45%;
    font-size: 65px;
  }

  @media (min-width: 1390px) {
    width: 35%;
    margin: 0 0 90px;
  }
`;

export const CarouselContainer = styled.div`
  @media (min-width: 1390px) {
    display: none;
  }
`;

export const DesktopReviewsContainer = styled.div`
  position: relative;
  display: none;
  max-width: 1390px;
  align-items: flex-start;
  gap: 20px;
  z-index: 100;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media (min-width: 1390px) {
    display: grid;
  }
`;

export const TrustpilotContainer = styled.div`
  padding: 30px 24px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0 50px 40px;
    width: 300px;
    margin-top: -15px;
  }

  @media (min-width: 1390px) {
    padding: 45px 0 0;
    flex-direction: row;
    width: 100%;
  }
`;

export const TrustpilotIcon = styled(TrustPilotNoStars)`
  width: 111px;
  height: 28px;
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

export const TrustpilotText = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
  line-height: normal;

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 1024px) {
    line-height: 33px;
  }
`;

export const ImageContainer = styled.div`
  height: 702px;
  width: 354px;
  transform: rotate(11.019deg);
  position: relative;
  margin-bottom: -260px;

  @media (min-width: 768px) {
    height: 865px;
    width: 435px;
    transform: none;
    position: absolute;
    right: -35px;
    bottom: 258px;
  }

  @media (min-width: 1024px) {
    height: 1083px;
    width: 545px;
  }

  @media (min-width: 1200px) {
    right: 260px;
    bottom: 250px;
  }
`;
