import React from 'react';

import FloodLevelOne from '../../../static/svgIcons/FloodLevelOne.svg';
import FloodLevelThree from '../../../static/svgIcons/FloodLevelThree.svg';
import FloodLevelTwo from '../../../static/svgIcons/FloodLevelTwo.svg';

const WeatherAlertHeading = ({ severityLevel, severity }: any) => {
  const alertLvlSubheading = () => {
    switch (severityLevel) {
      case 1:
        return 'Severe flooding. Danger to life. Please take immediate action. ';
      case 2:
        return 'Flooding is expected, immediate action required';
      case 3:
        return 'Flooding is possible. Be prepared';
      default:
        '';
        break;
    }
  };

  return (
    <div className="flex flex-col md:flex-row md:gap-20">
      <div className="w-full md:w-1/2">
        {severity && (
          <h2 className=" mb-32px">
            Flood warnings have been issued in your area{' '}
          </h2>
        )}
      </div>
      <div className="w-full md:w-1/2">
        {/* TODO: fix null on icon */}
        <div className="icons-primary6 z-50">
          {severityLevel === 1 ? (
            <FloodLevelOne stroke="#6214bE" fill="#6214bE" />
          ) : severityLevel === 2 ? (
            <FloodLevelTwo stroke="#6214bE" fill="#6214bE" />
          ) : severityLevel === 3 ? (
            <FloodLevelThree stroke="#6214bE" fill="#6214bE" />
          ) : null}
        </div>
        <h3>{severity}</h3>
        <p className="mb-32px">{alertLvlSubheading()}</p>
        {/* <p className="w-full md:w-2/3">{data.warning}</p> */}
        <div className="py-32px border-t border-b border-pxCharcoal">
          <p className="mb-0 text-small">
            For your own safety, please read and follow the government’s{' '}
            <a
              href="https://www.gov.uk/government/collections/flooding-health-guidance-and-advice"
              target="_blank"
              rel="noreferrer"
              className="font-semibold"
            >
              advice on what to do next.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WeatherAlertHeading;
