import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Shape from '../../components/Shape';
import { SemiBoldSpan } from '../../styles/sharedStyled';
import {
  BackgroundContainer,
  PolicyExpertSocialLink,
  TermsContainer,
  TermsHeader,
  TermsListContainer,
  TermsListItem,
} from './summer-hamper-competition.style';

const competitionTermsData = [
  <div key="wrapper-1">
    <TermsListItem key={'sub-header-1'} $isBold $isSubHeader>
      How to Enter:
    </TermsListItem>
    <TermsListItem>
      To enter our prize draw to win a Fortnum & Mason hamper, you must follow
      our official account on Instagram{' '}
      <PolicyExpertSocialLink href="https://www.instagram.com/policyexpert/">
        (@policyexpert){' '}
      </PolicyExpertSocialLink>
      and 'like' our most recent post at the time of visiting.
    </TermsListItem>
    <TermsListItem>
      Entrants must still be following us at the close of the competition to be
      considered entered.
    </TermsListItem>
    <TermsListItem>
      The competition will close on 30th May 2024 at 09:00am BST.
    </TermsListItem>
    <TermsListItem>
      Employees of Policy Expert and their relatives, or anyone involved with
      the promotion, are excluded from entering the prize draw.
    </TermsListItem>
  </div>,
  <div key="wrapper-2">
    <TermsListItem $isBold $isSubHeader>
      The prize:{' '}
    </TermsListItem>
    <TermsListItem>
      The winner can choose between Fortnum & Mason’s{' '}
      <SemiBoldSpan>Congratulations hamper</SemiBoldSpan> and the{' '}
      <SemiBoldSpan>Fortnum’s Express hamper.</SemiBoldSpan> The prizes are
      non-transferable and there is no cash alternative in lieu of the prizes or
      any part of the prizes. The hamper is the complete prize, even if the
      winner opts for the hamper with a lower value.
    </TermsListItem>
    <TermsListItem>
      The winner will be selected at random and will be announced on Policy
      Expert's Instagram account on the 30th May 2024.
    </TermsListItem>
    <TermsListItem>
      Neither Instagram nor Fortnum & Mason are affiliated with this competition
      in any way.
    </TermsListItem>
    <TermsListItem>
      Entry to the prize draw confirms that you have accepted these rules.
    </TermsListItem>
    <TermsListItem>
      Policy Expert asks that entrants are wary of scam attempts from accounts
      impersonating us - we will never contact you through any Instagram account
      besides{' '}
      <PolicyExpertSocialLink href="https://www.instagram.com/policyexpert/">
        @policyexpert.
      </PolicyExpertSocialLink>
    </TermsListItem>
  </div>,
];

const SummerHamperCompetition = () => {
  const { isTablet, isMobile } = useBreakpoint();

  return (
    <>
      <title>
        Terms and conditions Summer Hamper Competition | Policy Expert
      </title>
      <BackgroundContainer>
        <TermsContainer>
          <TermsHeader data-testid="terms-header">
            Terms and conditions
          </TermsHeader>
          <TermsListContainer data-testid="terms-list">
            {competitionTermsData.map((item) => item)}
          </TermsListContainer>
        </TermsContainer>
        {!isMobile && <Shape position="top-right-terms" />}
        {!isTablet && !isMobile && <Shape position="left-prize-terms" />}
      </BackgroundContainer>
    </>
  );
};

export default SummerHamperCompetition;
