import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../components/headerBanner/HeaderBanner';
import TwoColumnHTMLBanner from '../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import TwoColumnTextBanner, {
  TwoColumnTextBannerType,
} from '../components/twoColumnTextBanner/TwoColumnTextBanner';
import useDisclaimer from '../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src="../images/complaints_procedure_sm.png"
        alt="Complaints procedure"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src="../images/complaints_procedure_md.png"
        alt="Complaints procedure"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src="../images/complaints_procedure_lg.png"
      alt="Complaints procedure"
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const procedureIntroData: TwoColumnTextBannerType = {
  bgColor: 'bg-pxWhite',
  textColor: 'text-pxCharcoal',
  subText: [
    'Policy Expert is committed to providing the highest standard of service to our customers that is always clear and not misleading. However, we know that sometimes things go wrong. As a valued customer, we want you to tell us if our service does not meet your expectations.',
    'Should this happen, we hope to put it right. We are always seeking to improve the way we operate and our aim is to remedy any recurring problems.',
    'Any expression of dissatisfaction requesting a response is treated as a complaint and will be handled by our Complaints Department, who will follow the internal complaints handling procedure below.',
  ],
};

const procedureBlockData = {
  bgColor: 'bg-pxTan',
  textColor: 'text-pxCharcoal',
  heading: 'Our complaints procedure',
  ShapeX: false,
  body: (
    <div>
      <ul>
        <li>
          If your complaint concerns the service of another firm, we will
          promptly refer your complaint to the other firm who will follow their
          own complaints procedure. We will inform you we have done so and pass
          on the contact details of the firm to which it has been referred.
        </li>
        <li>
          Otherwise, your complaint is recorded on the day of receipt and we’ll
          undertake to resolve it as fairly and promptly as possible. This may
          include gathering evidence from a number of places (for example; call
          recordings). We may also call you to get some more information, so
          please note our calls will display as a 0330 number on some phones.
          <br />
          We hope to resolve the complaint to your satisfaction within three
          working days following the day of receipt. If so, we will send you a
          summary resolution communication letter which will explain our
          decision and detail your rights to refer the matter to the Financial
          Ombudsman Service (FOS) if you wish.
        </li>
        <li>
          If we were not able to resolve your complaint within three days,
          within five days of receipt we will send you either:
          <ol>
            <li className="mt-5 pl-32px">
              a written acknowledgement of the complaint and give you the
              contact details of the person who is handling your complaint; or
            </li>
            <li className="pl-32px">
              a resolution to your complaint which will explain our decision and
              detail your rights to refer the matter to the Financial Ombudsman
              Service (FOS) if you wish.
            </li>
          </ol>
        </li>
        <li>
          If resolution is not possible after five days, we will contact you
          within four weeks with either:
          <ol>
            <li className="mt-5 pl-32px">
              a resolution to your complaint which will explain our decision and
              detail your rights to refer the matter to the Financial Ombudsman
              Service (FOS) if you wish; or
            </li>
            <li className="pl-32px">
              an update, providing the reason why it is not yet possible to
              resolve your complaint.
            </li>
          </ol>
        </li>
        <li>
          Eight weeks after receipt of your complaint, we will either:
          <ol>
            <li className="mt-5 pl-32px">
              issue a <strong>final response letter</strong> which will explain
              our final decision and detail your rights to refer the matter to
              the Financial Ombudsman Service (FOS) if you wish; or
            </li>
            <li className="pl-32px">
              write to you to explain why we are still not in a position to
              issue a final response and when we expect to be able to issue one.
            </li>
          </ol>
        </li>
      </ul>
    </div>
  ),
};

const independentReviewData = {
  bgColor: 'bg-pxMint',
  textColor: 'text-pxCharcoal',
  heading: 'Independent review',
  ShapeX: false,
  body: (
    <div>
      <p>
        If you are unhappy with our decision and wish to take it further, you
        can ask the Financial Ombudsman Service (FOS) to look at your complaint.
        This is a free, independent service for resolving disputes.
      </p>
      <p>
        While you can refer your complaint to the FOS at any time, they’ll need
        our consent to investigate complaints where:
      </p>
      <ol>
        <li className="mt-5 pl-32px">
          we haven’t had the chance to put things right
        </li>
        <li className="pl-32px">
          we haven’t exceeded the 56-day timescale and haven’t yet issued our{' '}
          <strong>final response letter</strong>.
        </li>
      </ol>
      <p>
        If you decide to refer your complaint after we’ve issued our final
        response, you should do so within 6 months of the date on our{' '}
        <strong>final response letter</strong>.<br />
        <br />
        <strong>Write to:</strong>
        <br />
        <br />
        Financial Ombudsman Service
        <br />
        Exchange Tower
        <br />
        London
        <br />
        E14 9SR
      </p>
      <p>
        <strong>Email:</strong>{' '}
        <a href="mailto:complaint.info@financial-ombudsman.org.uk">
          complaint.info@financial-ombudsman.org.uk
        </a>
      </p>
      <p>
        <strong>Website:</strong>{' '}
        <a href="www.financial-ombudsman.org.uk">
          www.financial-ombudsman.org.uk
        </a>
      </p>
      <p>
        <strong>Call:</strong> <a href="tel:03001239123">0300 123 9 123</a>
      </p>

      <p>
        Policy Expert are committed to treating our customers fairly and take
        all client dissatisfaction seriously.
      </p>
      <p>
        We hope to resolve any complaint to your satisfaction as promptly and
        effectively as possible.
      </p>
    </div>
  ),
};

const ComplaintsProcedurePage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxPurple"
        headerImage={headerImage}
        maxHeight="max-h-750"
        styling="pt-2.5 pl-25px md:pt-47px md:pl-66px lg:pt-59px lg:px-6 max-w-1276"
      />
      <TwoColumnTextBanner data={procedureIntroData} />
      <TwoColumnHTMLBanner data={procedureBlockData} />
      <TwoColumnHTMLBanner data={independentReviewData} />
    </>
  );
};

export default ComplaintsProcedurePage;

export const Head: HeadFC = () => (
  <>
    <title>Complaints procedure | Policy Expert</title>
    <meta name="description" content="Policy Expert complaints procedure." />
    <html lang="en" />
  </>
);
