import React, { ReactNode } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { IconTextType } from '../../types/icon';
import ShapeX from '../ShapeX';
import {
  IconBannerBackgroundContainer,
  IconBannerGrid,
  IconBannerInformationContainer,
  IconBannerInformationWrapper,
  IconBannerTextContainer,
  InfoBannerContent,
} from './PetIconsBanner.style';

export type IconsBannerData = {
  bgColor: string;
  headerText: ReactNode;
  icons: IconTextType[];
  image?: ReactNode;
};

type Props = {
  data: IconsBannerData;
  shape?: boolean;
  hideShapeDesktop?: boolean;
};

const IconsBanner = ({ data, shape, hideShapeDesktop }: Props) => {
  const { isMobile, isTablet, isDesktop, isTabletLarge } = useBreakpoint();

  const hideShapeOnDesktop =
    (isDesktop || (isTabletLarge && !isMobile)) && hideShapeDesktop;
  const hideShape = (shape && isTablet && !isMobile) || hideShapeOnDesktop;
  const hasImage = data.image !== undefined;

  return (
    <IconBannerBackgroundContainer
      $backgroundColor={data.bgColor}
      $hasImage={hasImage}
    >
      <IconBannerInformationWrapper>
        {data.headerText}
        <InfoBannerContent $hasImage={hasImage}>
          {data.image && data.image}
          <IconBannerGrid $hasImage={hasImage} data-testid="icon-banner">
            {data.icons.map((item, index) => (
              <IconBannerInformationContainer
                key={`${item.text?.toString()}_${index}`}
                data-testid="icon-information-container"
              >
                {item.icon}
                <IconBannerTextContainer>{item.text}</IconBannerTextContainer>
              </IconBannerInformationContainer>
            ))}
          </IconBannerGrid>
        </InfoBannerContent>
      </IconBannerInformationWrapper>
      {!hideShape && <ShapeX position={'bottom-icon-banner'} />}
    </IconBannerBackgroundContainer>
  );
};

export default IconsBanner;
