import React, { ReactNode } from 'react';

import { Link } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { ButtonType } from '../../types/button';
import DropShapeCard from '../dropShapeCard/DropShapeCard';
import Icon from '../icons/Icon';
import ImageComponent from '../imageComponent/ImageComponent';
import Shape from '../Shape';
import ShapeX from '../ShapeX';

//Component has default structure + styling, and also has adjustment for simple image and text.
//For simple image and text use prop imgPosition else default component styling applies.

type TwoColumnTextImagePropsDataType = {
  headerText: string;
  subBody?: any;
  subText?: string;
  buttons?: ButtonType[];
  img: string;
  imageFile: IGatsbyImageData;
  tag?: {
    visible: boolean;
    label: string;
    icon: ReactNode;
  };
};

type Props = {
  data: TwoColumnTextImagePropsDataType;
  bgColor: string;
  textColor?: string;
  borderTop?: boolean;
  imgPosition?: 'left' | 'right';
  anchor?: string;
  shapeX: boolean;
};

const TwoColumnTextImage = ({
  data,
  bgColor,
  textColor,
  borderTop,
  imgPosition,
  shapeX,
}: Props) => (
  <div
    className={`bg-${bgColor} relative overflow-hidden ${
      imgPosition
        ? 'pt-60px md:pt-100px lg:pt-120px pb-112px md:pb-40'
        : 'pb-82px md:pb-130px'
    }`}
  >
    <div className={imgPosition ? '' : `padding-x`}>
      <div
        className={`flex flex-col ${
          imgPosition === 'left'
            ? 'md:flex-row-reverse lg:justify-center padding-x p-0 xl:px-6'
            : 'md:flex-row pt-82px'
        }  relative z-30 ${borderTop ? 'border-t border-dividerGrey' : ''}`}
      >
        <div
          className={`${
            imgPosition ? 'px-6 md:px-0 md:pl-16 md:pr-10% xl:pl-8' : 'md:w-3/5'
          }`}
        >
          <h2
            className={` ${
              imgPosition
                ? 'font-campton font-medium text-blackBannerTitle md:text-blackBannerTitleTablet xl:text-blackBannerTitleDesktop'
                : 'mb-32px md:mb-82px md:max-w-2/3'
            } ${textColor ? `text-${textColor}` : ''}`}
          >
            {data.headerText}
          </h2>
          {data.subBody && imgPosition && (
            <div
              className={`mt-35px md:mt-30px xl:mt-80px ${
                textColor ? `text-${textColor}` : ''
              }`}
            >
              {data.subBody}
            </div>
          )}
          <p
            className={`mb-32px md:mb-82px ${
              imgPosition ? 'hidden' : 'md:max-w-1/2'
            } ${textColor ? `text-${textColor}` : ''}`}
          >
            {data.subText}
          </p>
          {data.buttons &&
            data.buttons.map((btn) => (
              <div className="flex" key={btn.label}>
                <Link
                  key={btn.label}
                  // @ts-ignore
                  to={btn.url}
                  className={`btn-md md:btn-lg md:mr-32px mb-12px last:mr-0 btn-${
                    btn.primary ? 'primary' : 'secondary'
                  }`}
                >
                  {btn.label}
                </Link>
              </div>
            ))}
        </div>

        <div
          className={` relative  ${
            imgPosition === 'left'
              ? '-ml-20 xl:-ml-0 xl:mr-10% mt-10 md:mt-0 pr-6 md:pr-0'
              : ''
          } ${imgPosition ? 'md:w-full' : 'sm:max-w-2/3 md:w-2/5'}`}
        >
          <DropShapeCard fullWidth className={imgPosition === 'left' ? '' : ''}>
            <ImageComponent
              imageFile={data.imageFile}
              className={`block ${
                imgPosition ? 'min-h-385 md:h-408 xl:h-500' : 'md:h-96 '
              }`}
              alt={data.img}
            />
          </DropShapeCard>
          {data.tag && (
            <span
              className={`absolute w-130 h-130 lg:w-200 lg:h-200 
                                    -left-5 lg:-left-20 top-24 md:top-72 lg:top-48 xl:bottom-42
                                    text-center bg-pxTan rounded-full flex flex-col justify-center px-4 z-40`}
            >
              <div className="flex justify-center">
                <Icon type="primary" size={1}>
                  {data.tag.icon}
                </Icon>
              </div>
              <p className="tag-small mb-0 pt-2">{data.tag.label}</p>
            </span>
          )}
        </div>
        {/* } */}
      </div>
    </div>
    {borderTop ? <Shape position="left-top" /> : null}
    {shapeX ? <ShapeX position="bottom-right" /> : null}
  </div>
);

export default TwoColumnTextImage;
