import { styled } from 'styled-components';

import {
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
} from '../../styles/sharedStyled';

export const IpidHeaderContainer = styled.div`
  @media (min-width: 768px) {
    max-width: 840px;
    margin: 0 auto;
  }
`;

export const IpidHeaderTitle = styled.h1<{ $hasPadding?: boolean }>`
  text-align: center;
  font-family: ${fontFamilyCamptonMedium};
  line-height: normal;
  font-size: 30px;
  margin-bottom: 30px;
  letter-spacing: 0;
  padding: ${({ $hasPadding }) => ($hasPadding ? '0 18px' : '0')};

  @media (min-width: 768px) {
    font-size: 35px;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
    padding: 0 40px;
  }
`;

export const IpidSubHeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 60px;
    text-align: left;
  }
`;

export const IpidSubHeaderText = styled.p`
  margin: 0;
  font-family: ${fontFamilyCamptonBook};
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    font-size: 18px;
  }

  &:first-child:not(:only-child) {
    margin-bottom: 10px;
  }
`;
