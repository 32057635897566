import React, { FC, useContext } from 'react';
import { useWizard } from 'react-use-wizard';

import { PhoneNumbersContext } from '../../context/PhoneNumbersProvider';
import formatPhoneNumber from '../../helpers/formatPhoneNumber';
import { pushHecTriageEventToDataLayer } from '../../services/windowService';
import {
  DeclineReason,
  MayHaveCoverContainer,
  MayHaveCoverLink,
  MayHaveCoverText,
} from './DeclineStep.style';

type DeclineStepProps = {
  declineReason?: string;
};

const DeclineStep: FC<DeclineStepProps> = ({ declineReason }) => {
  const { activeStep } = useWizard();
  const phoneNumbers = useContext(PhoneNumbersContext);
  const claimsNumber = phoneNumbers.home?.claims;

  const isNotAnEmergencyStep = activeStep === 3;
  const onClickCallEvent = isNotAnEmergencyStep
    ? 'call_claims_decline_no_emergency'
    : 'call_claims_not_covered';
  const onClickStartClaimEvent = isNotAnEmergencyStep
    ? 'click_start_claim_no_emergency'
    : 'click_start_claim_not_covered';

  if (!isNotAnEmergencyStep) {
    pushHecTriageEventToDataLayer('show_not_covered');
  }

  return (
    <>
      {!!declineReason && (
        <DeclineReason data-testid="decline-reason">
          {declineReason}
        </DeclineReason>
      )}
      <MayHaveCoverContainer data-testid="may-have-cover-container">
        <MayHaveCoverText data-testid="default-may-have-cover">
          You may still be covered by your home insurance. Get in touch with our
          claims team on{' '}
          <MayHaveCoverLink
            href={`tel:${formatPhoneNumber(claimsNumber)}`}
            onClick={() => pushHecTriageEventToDataLayer(onClickCallEvent)}
          >
            {claimsNumber}
          </MayHaveCoverLink>{' '}
          or start a new claim{' '}
          <MayHaveCoverLink
            href="https://newclaim.trinityclaims.co.uk/newclaim"
            onClick={() =>
              pushHecTriageEventToDataLayer(onClickStartClaimEvent)
            }
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Trinity Claims"
          >
            online here.
          </MayHaveCoverLink>
        </MayHaveCoverText>
      </MayHaveCoverContainer>
    </>
  );
};

export default DeclineStep;
