import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonRegular,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const PolicyBooksWrapper = styled.div`
  background-color: ${colors.gray3};
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 24px;
  width: 840px;

  @media (min-width: 768px) {
    padding: 50px 40px;
  }

  @media (min-width: 1024px) {
    padding: 120px 0;
  }
`;

export const MainHeader = styled.h1`
  text-align: center;
  font-family: ${fontFamilyCamptonRegular};
  font-weight: 500;
  font-size: 30px;
  line-height: normal;
  color: ${colors.lightBlack};
  letter-spacing: normal;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 35px;
    margin-bottom: 15px;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
    margin-bottom: 40px;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  border-radius: 15px;
  border: 1px solid ${colors.charcoal};
  background-color: ${colors.white};
  margin-bottom: 30px;

  @media (min-width: 768px) {
    padding: 15px 30px;
    margin-bottom: 40px;
  }
`;

export const CurrentVersionText = styled.p`
  text-align: center;
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: ${colors.lightBlack};
  margin: 0;
  letter-spacing: normal;
`;

export const ExistingCustomerText = styled.p`
  text-align: center;
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: ${colors.lightBlack};
  margin: 0;
  letter-spacing: normal;
`;

export const LoginLink = styled.a`
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: ${colors.pink2};
  letter-spacing: normal;
`;

export const DocumentInformation = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: ${colors.lightBlack};
  margin: 0;
  letter-spacing: normal;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 18px;
    text-align: left;
    margin-bottom: 40px;
  }
`;

export const ProductHeader = styled.h2`
  color: ${colors.purple7};
  font-family: ${fontFamilyCamptonBook};
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
    font-size: 25px;
  }
`;
