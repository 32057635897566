import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const TextBannerContainer = styled.div<{
  $backgroundColor: string;
  $isBottom?: boolean;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  position: relative;
  overflow: hidden;
  padding: 60px 24px 95px;
  margin-bottom: ${({ $isBottom }) => ($isBottom ? '0' : '-35px')};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding: 100px 40px ${({ $isBottom }) => ($isBottom ? '150px' : '180px')};
    margin-bottom: ${({ $isBottom }) => ($isBottom ? '0' : '-80px')};
  }

  @media (min-width: 1024px) {
    padding: 130px 40px 210px;
  }
`;

export const TextBannerTitle = styled.h2`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 35px;
  margin-bottom: 24px;
  line-height: normal;

  @media (min-width: 768px) {
    font-size: 50px;
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 80px;
  }
`;

export const TextBannerTextContainer = styled.div`
  max-width: 900px;
`;

export const TextBannerText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 18px;
  margin-bottom: 20px;
  line-height: normal;

  @media (min-width: 1024px) {
    font-size: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TextBannerLink = styled.a`
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};
`;
