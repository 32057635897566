import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonRegular,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const CoverInformationContainer = styled.div`
  margin-top: -5px;
  margin-bottom: 15px;

  & > p {
    font-family: ${fontFamilyCamptonBook};
    font-size: 16px;
    line-height: 18.62px;
    margin-bottom: 0;
    letter-spacing: normal;
  }

  & > ul {
    padding-left: 15px;
  }

  & > ul > li {
    font-family: ${fontFamilyCamptonBook};
    font-size: 16px;
    line-height: 18.62px;
    margin-bottom: 0;
    list-style-position: outside;
    letter-spacing: normal;
  }

  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 25px;
  }
`;

export const CallUsText = styled.p`
  color: ${colors.purple7};
  font-family: ${fontFamilyCamptonRegular};
  font-size: 16px;
  line-height: 18.62px;
  letter-spacing: normal;
  margin-bottom: 5px;
  margin-top: -5px;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;
    margin-top: 0;
  }
`;

export const PhoneLink = styled.a`
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 25px;
  line-height: 29.1px;
  letter-spacing: normal;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 34.92px;
    margin-bottom: 5px;
  }
`;

export const OpenText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  line-height: 17px;
  letter-spacing: normal;
  margin-bottom: 0;
`;
