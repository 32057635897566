import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../../components/headerBanner/HeaderBanner';
import TwoColumnHTMLBanner from '../../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../types/breakpoints';
import TermsCopyType from '../../types/terms';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../images/media_sm.png`}
        alt={`Media page`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../images/media_md.png`}
        alt={`Media page`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../images/media_lg.png`}
      alt={`Insurance for people like you`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const MediaPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const termsCopy: TermsCopyType = {
    bgColor: 'bg-pxGrey',
    heading: 'Policy Expert media page',
    body: (
      <div>
        <p>
          If you’re a journalist and have an enquiry about Policy Expert, please
          contact us at{' '}
          <a href="mailto:media@policyexpert.co.uk" className="font-semibold">
            media@policyexpert.co.uk
          </a>
          .
        </p>
      </div>
    ),
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxPurple"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        maxHeight="max-h-600"
        styling="pt-33px pl-30px md:pt-30px md:px-110px lg:pt-34px lg:px-6 lg:max-w-975 relative z-1"
      />
      <TwoColumnHTMLBanner data={termsCopy} />
    </>
  );
};

export default MediaPage;

export const Head: HeadFC = () => (
  <>
    <title>Media | Policy Expert</title>
    <meta
      name="description"
      content="Media contact details at Policy Expert."
    />
    <html lang="en" />
  </>
);
