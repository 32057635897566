import React, { ReactNode } from 'react';

import { ReviewsData } from '../../types/reviews';
import ShapeX from '../ShapeX';
import TreeCards from '../treeCards/TreeCards';

type CardsType = {
  content: string;
  img: ReactNode;
  imgName?: string;
};

type Props = {
  data: ReviewsData;
  cards?: CardsType[];
};

const HeaderWithText = ({ data, cards }: Props) => (
  <div
    className={`bg-pxGrey header-background-image relative overflow-hidden 
      ${cards ? 'pt-82px md:pt-130px' : 'py-82px md:py-130px'}`}
  >
    <div className="padding-x flex flex-col md:flex-row relative z-30">
      <div className="md:w-3/5">
        <h2 className="mb-32px max-w-5/6">{data.headerText}</h2>
      </div>
      <div className="md:w-2/5">
        {data.content.map((item: string) => (
          <>
            <p className="mb-32px last:mb-0" key="item">
              {item}
            </p>
          </>
        ))}
      </div>
    </div>
    {cards ? (
      <div className="pb-82px md:pb-130px pt-32px md:pt-82px relative overflow-hidden ">
        <div className="padding-x relative z-30">
          <TreeCards cards={cards} />
        </div>
        <ShapeX position="bottom-left-cards" />
      </div>
    ) : (
      ''
    )}
    {data.corner ? <ShapeX position="bottom-left" /> : null}
  </div>
);

export default HeaderWithText;
