import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import CtaBannerImpactV2 from '../ctaBanner/CtaBannerImpactV2';
import Shape, { ShapeProps } from '../Shape';
import ShapeX, { ShapePosition } from '../ShapeX';
import {
  MessageHeaderBannerContainer,
  MessageHeaderBannerDataContainer,
  MessageHeaderBannerTitle,
} from './MessageHeaderBannerImpactV2.style';

type MessageHeaderData = {
  title: string;
  message: React.ReactNode;
};

export type StyleProps = {
  mobile: string;
  tablet: string;
  desktop: string;
};

export type MessageHeaderBannerProps = {
  backgroundColor: string;
  height: StyleProps;
  marginTop: StyleProps;
  shapePosition1?: ShapeProps['position'];
  shapePosition2?: ShapeProps['position'];
  shapeXPosition?: ShapePosition;
  data: MessageHeaderData;
};

const MessageHeaderBannerImpactV2 = ({
  backgroundColor,
  height,
  marginTop,
  shapePosition1,
  shapePosition2,
  shapeXPosition,
  data,
}: MessageHeaderBannerProps) => {
  const { isMobile } = useBreakpoint();
  return (
    <MessageHeaderBannerContainer
      $backgroundColor={backgroundColor}
      $height={height}
    >
      <MessageHeaderBannerDataContainer $marginTop={marginTop}>
        <MessageHeaderBannerTitle>{data.title}</MessageHeaderBannerTitle>
        {data.message}
        {isMobile && <CtaBannerImpactV2 />}
      </MessageHeaderBannerDataContainer>
      {shapeXPosition && <ShapeX position={shapeXPosition} />}
      {shapePosition1 && <Shape position={shapePosition1} />}
      {shapePosition2 && <Shape position={shapePosition2} />}
    </MessageHeaderBannerContainer>
  );
};
export default MessageHeaderBannerImpactV2;
