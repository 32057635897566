import React from 'react';

import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Car from '../../../static/svgIcons/Car.svg';
import House from '../../../static/svgIcons/House.svg';

const InsuranceCard = ({ data }: any) => {
  const { isMobile } = useBreakpoint();
  return (
    <div className="bg-white rounded-radius-15 py-25px px-15px md:rounded-radius-70 md:px-40px md:py-60px lg:p-60px flex flex-col justify-end items-center h-full">
      <h3 className="text-fontSize-20 md:p-0 md:text-fontSize-40 lg:text-fontSize-50 md:w-255px lg:w-full text-center">
        {isMobile ? data.headingMobile : data.heading}
      </h3>
      {isMobile && (
        <div className="h-40px md:h-20 flex items-center mb-15px">
          {data.heading == 'Car Insurance' ? (
            <Car className="icons-primary5 z-50 h-30px" />
          ) : (
            <House
              className="icons-primary3 z-50 h-40px"
              stroke="#6214bE"
              fill="#6214bE"
            />
          )}
        </div>
      )}
      <Link
        to={data.ctaUrl}
        className="btn-primary btn-sm-impact md:btn-md lg:btn-lg md:w-full md:mx-auto md:mt-25px"
        data-testid={`${data.headingMobile.toLowerCase()}-insurance-button`}
      >
        Get a quote
      </Link>

      <div className="flex flex-col md:w-full md:flex-row md:justify-between lg:justify-center semiBold text-center mt-15px lg:mt-35px">
        <Link
          to={data.link1Url}
          className="pb-2.5 md:pb-0 lg:mr-32px text-fontSize-14 md:text-fontSize-18 lg:text-fontSize-20 font-semiBold"
        >
          {data.link1text}
        </Link>
        <Link
          to={data.link2Url}
          className="text-fontSize-14 md:text-fontSize-18 lg:text-fontSize-20 font-semiBold"
        >
          {data.link2text}
        </Link>
      </div>
    </div>
  );
};

export default InsuranceCard;
