import { css, styled } from 'styled-components';

import Calendar from '../../static/svgIcons/CalendarPet.svg';
import Chart from '../../static/svgIcons/Chart.svg';
import Claims from '../../static/svgIcons/ClaimsPet.svg';
import LostPet from '../../static/svgIcons/LostPet.svg';
import Medicine from '../../static/svgIcons/MedicinePet.svg';
import Vet from '../../static/svgIcons/Nurse.svg';
import Plane from '../../static/svgIcons/PlanePet.svg';
import Coins from '../../static/svgIcons/Pounds_Coins.svg';
import Urn from '../../static/svgIcons/UrnPet.svg';
import { colors } from '../styles/sharedStyled';

const iconSize = css`
  width: 45px;
  height: 45px;
`;

export const PetTableBannerContainer = styled.div`
  position: relative;
`;

export const VetIcon = styled(Vet)`
  ${iconSize}
  stroke: ${colors.purple7};
`;

export const PlaneIcon = styled(Plane)`
  ${iconSize}
`;

export const CalendarIcon = styled(Calendar)`
  ${iconSize}
`;

export const ClaimsIcon = styled(Claims)`
  ${iconSize}
`;

export const ChartIcon = styled(Chart)`
  ${iconSize}
`;

export const MedicineIcon = styled(Medicine)`
  ${iconSize}
`;

export const CoinsIcon = styled(Coins)`
  ${iconSize}
`;

export const LostPetIcon = styled(LostPet)`
  ${iconSize}
`;

export const UrnIcon = styled(Urn)`
  ${iconSize}
`;
