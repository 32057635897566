import { Link } from 'gatsby';
import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
  fontFamilyCamptonRegular,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

export const MultiTabTableBackgroundContainer = styled.div<{
  $v2Refresh?: boolean;
}>`
  background-color: ${colors.purple7};
  position: relative;
  overflow: hidden;
  padding: 60px 0 95px;
  margin-bottom: -35px;

  @media (min-width: 768px) {
    padding: 100px 0 ${({ $v2Refresh }) => ($v2Refresh ? '180px' : '260px')};
    margin-bottom: -80px;
  }

  @media (min-width: 1024px) {
    padding: 130px 0 210px;
  }
`;

export const MultiTabTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  max-width: 1390px;
  margin: 0 auto;
  position: relative;
  z-index: 30;

  @media (min-width: 768px) {
    padding: 0 40px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 90px;
    padding: 0 40px;
  }

  @media (min-width: 1400px) {
    padding: 0;
  }
`;

export const MultiTabHeaderContainer = styled.div`
  text-align: center;
  font-family: ${fontFamilyCamptonBook};

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 35%;
    text-align: left;
  }
`;

export const MultiTabHeaderTitle = styled.h2<{ $v2Refresh?: boolean }>`
  font-family: ${fontFamilyCamptonMedium};
  color: ${colors.white};
  font-size: 35px;
  margin: ${({ $v2Refresh }) => ($v2Refresh ? ' 0 15px 20px' : ' 0 15px 30px')};
  line-height: normal;

  @media (min-width: 768px) {
    font-size: ${({ $v2Refresh }) => ($v2Refresh ? '40px' : '50px')};
    margin: ${({ $v2Refresh }) => ($v2Refresh ? '0 0 20px' : '0 0 30px')};
  }

  @media (min-width: 1024px) {
    font-size: ${({ $v2Refresh }) => ($v2Refresh ? '65px' : '80px')};
    margin-bottom: ${({ $v2Refresh }) => ($v2Refresh ? '40px' : '50px')};
  }
`;

export const MultiTabHeaderText = styled.p<{ $v2Refresh?: boolean }>`
  color: ${colors.white};
  font-size: ${({ $v2Refresh }) => ($v2Refresh ? '18px' : '20px')};
  margin-bottom: 50px;
  line-height: normal;

  @media (min-width: 768px) {
    margin-bottom: ${({ $v2Refresh }) => ($v2Refresh ? '60px' : '70px')};
  }

  @media (min-width: 1024px) {
    font-size: ${({ $v2Refresh }) => ($v2Refresh ? '20px' : '25px')};
    margin-bottom: 0;
  }
`;

export const TableContainer = styled.div`
  display: none;
  background-color: ${colors.white};
  border-radius: 20px;
  overflow: hidden;
  width: 58.33%;

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.gray3};
  border-radius: 20px 20px 0 0;
  position: relative;
  z-index: 10;
`;

export const TableHeaderTab = styled.div<{ $isSelected: boolean }>`
  padding: 30px 16px;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected
      ? `
        background-color: ${colors.white};
        border-radius: 20px 20px 0 0;
        box-shadow: 4px 0px 10px 0px ${colors.boxShadowGray}, -4px 0px 10px 0px ${colors.boxShadowGray};    `
      : `
        border-right-width: 1px;
    `}
`;

export const TableHeaderTabText = styled.p<{ $isSelected: boolean }>`
  font-size: 20px;
  font-family: ${fontFamilyCamptonMedium};
  color: ${colors.purple7};
  text-align: center;
  margin-bottom: 0;
  width: 100%;
  line-height: normal;

  ${({ $isSelected }) =>
    $isSelected
      ? `font-family: ${fontFamilyCamptonSemiBold};`
      : `color: ${colors.pink2};`}
`;

export const ExtraInformationContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 40px 30px 0;
  z-index: 21;
  background-color: ${colors.white};
`;

export const ExtraInformation = styled.div`
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  background-color: ${colors.purple14};
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;

  & > span {
    color: ${colors.purple7};
    font-family: ${fontFamilyCamptonRegular};
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
  }
`;

export const MultiTabInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableTabContainer = styled.div<{ $v2Refresh?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  height: ${({ $v2Refresh }) => ($v2Refresh ? 'auto' : '500px')};
  position: relative;
  z-index: 20;
  background-color: ${colors.white};
  padding: ${({ $v2Refresh }) => ($v2Refresh ? '40px 30px' : '32px 0')};
  margin-top: -1px;

  ${({ $v2Refresh }) =>
    $v2Refresh &&
    `
  row-gap: 40px;
  column-gap: 30px;
  `};
`;

export const TableTabInformationContainer = styled.div<{
  $v2Refresh?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ $v2Refresh }) => ($v2Refresh ? '0' : '32px')};
`;

export const TableTabInformationText = styled.p`
  font-size: 15px;
  font-family: ${fontFamilyCamptonMedium};
  line-height: normal;
  text-align: center;
  margin: 32px 0 0;
`;

export const HecLinkContainer = styled.div`
  text-align: center;
  position: relative;
  z-index: 100;
  padding-bottom: 40px;
`;

export const HecLink = styled(Link)`
  font-family: ${fontFamilyCamptonSemiBold};
  font-size: 15px;
`;
