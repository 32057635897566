import React from 'react';

import scrollTo from 'gatsby-plugin-smoothscroll';

import waitForElement from '../../helpers/waitForElement';
import { Section } from '../../pages/contact';
import ProductButtons from '../productButtons/ProductButtons';

type ProductData = {
  product: Section;
  buttonHeader: string;
  svg: React.JSX.Element;
};

export type ImpactV2ProductSelectionData = Array<ProductData>;

type Props = {
  productSelectionData: ImpactV2ProductSelectionData;
  toggleSection: (section: Section) => void;
  section: Section;
};

const ProductButtonsBanner = ({
  productSelectionData,
  toggleSection,
  section,
}: Props) => {
  return (
    <div
      className={`${section ? 'md:pb-95px pb-40px mb-35-minus' : 'mb-0'}`}
      data-testid="product-buttons-banner"
    >
      <div className="flex flex-col md:flex-row justify-center space-y-15px md:space-y-0 md:space-x-15px">
        {productSelectionData.map((selectionData) => {
          const product = selectionData.product;

          return (
            <ProductButtons
              key={`productButton${product}`}
              isActive={section === product}
              onClick={() => {
                toggleSection(product);

                waitForElement(product).then(() => {
                  scrollTo(`#${product}`);
                });
              }}
              svg={selectionData.svg}
              buttonHeader={selectionData.buttonHeader}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductButtonsBanner;
