import React from 'react';

import { Link } from 'gatsby';

import PolicyExpertBronze from '../../../static/svgIcons/PolicyExpertBronze.svg';
import PolicyExpertGold from '../../../static/svgIcons/PolicyExpertGold.svg';
import PolicyExpertSilver from '../../../static/svgIcons/PolicyExpertSilver.svg';
import TickAngles from '../../../static/svgIcons/TickAngles.svg';
import XTable from '../../../static/svgIcons/XTable.svg';
import { ButtonType } from '../../types/button';
import Carousel from '../carousel/Carousel';
import Icon from '../icons/Icon';
import ProductCard from '../productCard/ProductCard';
import Shape from '../Shape';

type ProductRowType = {
  hasLightGreyBackground?: boolean;
  hasBlackText?: boolean;
  label: string;
  subLabel?: string;
  included: string[];
  includedHTML?: any;
  link?: string;
};

type RightPanelInfoType = {
  infoTitle: string;
  info: string;
};

type TableTitlesType = {
  tableTitle: string;
};

export type ProductTableDataType = {
  bgColor: string;
  textColor?: string;
  headerText: string;
  subText?: string;
  subHeaderText?: string;
  isMaxWidth?: boolean;
  rightPanelInfo?: RightPanelInfoType[];
  productRows: ProductRowType[];
  buttons?: ButtonType[];
  columns: number;
  tableTitles?: TableTitlesType[];
  firstRowDark?: boolean;
  firstColLarge?: boolean;
};

export type ImpactV2ProductTableDataType = {
  subText?: string;
  subHeaderText?: string;
  isMaxWidth?: boolean;
  productRows: ProductRowType[];
  buttons?: ButtonType[];
  columns: number;
  tableTitles?: TableTitlesType[];
};

interface ProductTableProps {
  data: ProductTableDataType;
  home?: boolean;
  car?: boolean;
  bottomPadding: boolean;
  tableLeft?: boolean;
  landingPage?: boolean;
}

const ProductTable = ({
  data,
  bottomPadding,
  tableLeft,
  landingPage,
}: ProductTableProps) => {
  const icons = [
    <PolicyExpertBronze key="policy-expert-bronze" className="mx-auto" />,
    <PolicyExpertSilver key="policy-expert-silver" className="mx-auto" />,
    data.columns === 3 ? (
      <PolicyExpertGold key="policy-expert-gold" className="mx-auto" />
    ) : null,
  ];

  const cards: JSX.Element[] = [];

  data.productRows[0].included.forEach((item, index) => {
    cards.push(
      <ProductCard
        key={`carousel-${item}`}
        data={data}
        img={icons[index]}
        column={index}
      />
    );
  });

  return (
    <div
      className={`${data.bgColor} ${
        bottomPadding ? 'py-82px md:py-130px' : 'pt-82px md:pt-130px'
      }  relative overflow-hidden`}
    >
      <div className="padding-x relative z-20">
        <div className="lg:w-3/4">
          <h2>{data.headerText}</h2>
          <p className="py-32px md:pt-82px md:pb-32px">{data.subText}</p>
          <h3 className="pb-32px md:pb-82px">{data.subHeaderText}</h3>
        </div>

        <table
          className={`${data.isMaxWidth ? 'w-full' : 'max-w-992'} hidden ${
            tableLeft ? '' : 'mx-auto'
          } lg:block bg-white rounded-xl relative overflow-hidden`}
        >
          <thead
            className="flex flex-col"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <tr className={`grid grid-cols-1n${data.columns}`}>
              <td className="w-full border-pxGrey border-r border-b" />

              {icons.map((item, index) => {
                if (!item) {
                  return null;
                }
                return (
                  <td
                    key={item.key}
                    className="w-full py-32px border-b border-pxGrey border-r border-l"
                  >
                    <Icon type="primary" size={5} className="mx-auto">
                      {icons[index]}
                    </Icon>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody
            className="flex flex-col"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {data.productRows.map((item: any) => (
              <tr
                key={item.label}
                className={`grid grid-cols-1n${data.columns}`}
              >
                <td className="border-pxGrey border items-center flex flex-col justify-center">
                  <p className="body-copy mb-0 px-32px text-finerDetailsBodyCopy w-full">
                    {item.label}
                  </p>
                  {item.subLabel && (
                    <p className="text-footer mb-0 px-32px w-full">
                      {item.subLabel}
                    </p>
                  )}
                </td>

                {item.included.map((includes: any) => (
                  <td key={includes} className="pt-32px border-pxGrey border ">
                    {includes === '✔' || includes === '✖' ? (
                      includes === '✔' ? (
                        <Icon
                          type="secondary"
                          size={3}
                          className="mx-auto mb-8"
                        >
                          <TickAngles />
                        </Icon>
                      ) : (
                        <Icon
                          type="secondary"
                          size={3}
                          className="mx-auto mb-8"
                        >
                          <XTable />
                        </Icon>
                      )
                    ) : (
                      <p className="text-center text-finerDetailsBodyCopy text-pxPurple">
                        {includes}
                      </p>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="lg:hidden mx-32px">
        <Carousel slidesPerView={1.2} offsetfirstslide cards={cards} />
      </div>

      <div
        className={`relative z-40 padding-x flex ${
          bottomPadding ? 'mt-32px md:mt-82px' : 'mt-0'
        } justify-center flex-col md:flex-row items-center`}
      >
        {data.buttons
          ? data.buttons.map((btn: ButtonType) => (
              <Link
                key={btn.label}
                // @ts-ignore
                to={btn.url}
                className={`btn-md md:btn-lg md:mr-32px mb-12px last:mr-0 btn-${
                  btn.primary ? 'primary' : 'secondary'
                }`}
              >
                {btn.label}
              </Link>
            ))
          : null}
      </div>
      <div className="hidden xl:block">
        <Shape position="right-home-ins" />
      </div>
      <div className={`xl:hidden ${landingPage !== true ? 'block' : 'hidden'}`}>
        <Shape position="left-home-ins" />
      </div>
      {landingPage === true ? <Shape position="left-bottom" /> : null}
    </div>
  );
};
export default ProductTable;
