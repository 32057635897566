import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderImageBreakpoints from '../../types/breakpoints';
import ShapeX from '../ShapeX';

type HeaderImageName = 'quidco' | 'topcash' | 'vodafone' | 'lidl';

type CampaignHeaderBannerProps = {
  headerImageName: HeaderImageName;
};

const getHeaderImage = (
  name: HeaderImageName,
  { isMobile, isTablet }: HeaderImageBreakpoints
) => {
  switch (name) {
    case 'quidco':
      if (isMobile) {
        return (
          <StaticImage
            src="../../images/campaign_quidco_sm.png"
            alt="Cashback in three easy steps"
            className="h-full"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        );
      }
      if (isTablet) {
        return (
          <StaticImage
            src="../../images/campaign_quidco_md.png"
            alt="Cashback in three easy steps"
            className="h-full"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        );
      }
      return (
        <StaticImage
          src="../../images/campaign_quidco_lg.png"
          alt="Cashback in three easy steps"
          className="h-full"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      );
    case 'topcash':
      if (isMobile) {
        return (
          <StaticImage
            src="../../images/campaign_topcash_sm.png"
            alt="Cashback in three easy steps"
            className="h-full"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        );
      }
      if (isTablet) {
        return (
          <StaticImage
            src="../../images/campaign_topcash_md.png"
            alt="Cashback in three easy steps"
            className="h-full"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        );
      }
      return (
        <StaticImage
          src="../../images/campaign_topcash_lg.png"
          alt="Cashback in three easy steps"
          className="h-full"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      );
    case 'vodafone':
      if (isMobile) {
        return (
          <StaticImage
            src="../../images/campaign_vodafone_sm.png"
            alt="Earn a voucher in three easy steps"
            className="h-full"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        );
      }
      if (isTablet) {
        return (
          <StaticImage
            src="../../images/campaign_vodafone_md.png"
            alt="Earn a voucher in three easy steps"
            className="h-full"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        );
      }
      return (
        <StaticImage
          src="../../images/campaign_vodafone_lg.png"
          alt="Earn a voucher in three easy steps"
          className="h-full"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      );
    case 'lidl':
      if (isMobile) {
        return (
          <StaticImage
            src="../../images/campaign_lidl_sm.png"
            alt="Earn a voucher in three easy steps"
            className="h-full"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        );
      }
      if (isTablet) {
        return (
          <StaticImage
            src="../../images/campaign_lidl_md.png"
            alt="Earn a voucher in three easy steps"
            className="h-full"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        );
      }
      return (
        <StaticImage
          src="../../images/campaign_lidl_lg.png"
          alt="Earn a voucher in three easy steps"
          className="h-full"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      );
    default:
      return null;
  }
};

const getImageStyle = (name: HeaderImageName) => {
  switch (name) {
    case 'quidco':
    case 'topcash':
      return 'pt-40px pl-23px md:pt-26px md:pl-16 lg:pt-11px lg:px-6 max-w-1255';
    case 'vodafone':
    case 'lidl':
      return 'pt-35px pl-20px md:pt-43px md:pl-px md:pr-55px lg:pt-52px lg:px-6 max-w-1207 relative z-1';
    default:
      return null;
  }
};

const CampaignHeaderBanner = ({
  headerImageName,
}: CampaignHeaderBannerProps) => {
  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage(headerImageName, { isMobile, isTablet });
  const imageStyle = getImageStyle(headerImageName);
  const isCashback =
    headerImageName === 'quidco' || headerImageName === 'topcash';

  return (
    <div className="bg-pxPurple md:max-h-850 relative overflow-hidden">
      <div className={`my-0 mx-auto relative z-1 ${imageStyle}`}>
        {headerImage}
      </div>
      <ShapeX
        position={`${
          isCashback ? 'bottom-left-header-low' : 'bottom-alternating-header'
        }`}
      />
    </div>
  );
};

export default CampaignHeaderBanner;
