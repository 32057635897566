import React from 'react';

import Shape from '../Shape';
import ShapeX from '../ShapeX';
import { RightSvg } from './TermsBlock.style';

type TermsDataType = {
  heading: string;
  titleShape?: boolean;
  partialShape?: boolean;
  overflowShape?: boolean;
  ShapeX?: boolean;
  additionalClass?: string;
  body: React.JSX.Element;
  bgColor: string;
  textColor: string;
}[];

export type TermsBlockDataType = {
  description?: string;
  termsInformation: TermsDataType;
};

type Props = {
  data: TermsBlockDataType;
  disclosurePage?: boolean;
  navigationShapeX?: boolean;
};

const TermsBlock = ({ data, disclosurePage, navigationShapeX }: Props) => {
  return (
    <div className="overflow-hidden">
      <div className="bg-pxWhite py-82px md:py-130px bg-left-bottom bg-no-repeat bg-80 md:bg-50 relative overflow-hidden">
        <div
          className={'bg-pxWhite max-w-1390 padding-x justify-between'}
          id="links-top"
        >
          {data.description && (
            <p className="w-11/12 mb-60px text-termsBlockDescription">
              {data.description}
            </p>
          )}
          <h4 className="w-3/4 md:w-full  mb-60px font-camptonMedium">
            Jump to the relevant section using the links below:
          </h4>
          <div
            className={`lg:grid ${
              disclosurePage ? 'grid-rows-5' : 'grid-rows-10'
            } grid-flow-col`}
          >
            {data.termsInformation.map((item: any) => (
              <a
                key={item.heading}
                className="flex flex-row items-center align-middle object-contain text-pxBlack mb-32px cursor-pointer"
                href={`#${item.heading}`}
              >
                <RightSvg />
                <p className="text-small mb-0 "> {item.heading}</p>
              </a>
            ))}
          </div>
        </div>
        {navigationShapeX ? (
          <ShapeX position="bottom-right-terms" />
        ) : (
          <Shape position="right-about-banner" />
        )}
      </div>
      {data.termsInformation.map((section: any) => {
        return (
          <section
            key={section.heading}
            id={section.heading}
            className={`relative ${
              section.overflowShape ? '' : 'overflow-y-hidden'
            }`}
          >
            <div
              className={`${section.bgColor} ${
                disclosurePage ? 'py-60px md:py-130px' : 'py-82px'
              } bg-left-bottom bg-no-repeat bg-80 md:bg-50 relative overflow-hidden`}
            >
              <div
                className={`${
                  section.textColor
                } max-w-1390 padding-x justify-between ${
                  section.additionalClass || ''
                } flex flex-col md:flex-row terms-and-conditions`}
              >
                <div className="w-full md:w-1/2">
                  <div>
                    {section.heading && (
                      <h2
                        className={`${
                          disclosurePage ? 'mb-0' : 'mb-32px'
                        } pr-82px break-words`}
                      >
                        {section.heading}{' '}
                      </h2>
                    )}
                  </div>

                  {section.titleShape && (
                    <div className="hidden xl:block">
                      <Shape position="left-near" />
                    </div>
                  )}
                  {section.partialShape && (
                    <div className="hidden xl:block">
                      <Shape position="left-terms-partial" />
                    </div>
                  )}
                </div>

                <div
                  className={`w-full md:w-1/2 ${
                    disclosurePage
                      ? 'mb-0 mt-40px md:mt-0 xl:pr-24px'
                      : 'mb-32px'
                  } text-small`}
                >
                  {section.body}
                  {disclosurePage ? null : (
                    <p className="text-small mt-32px font-camptonSemiBold">
                      <a href="#links-top">Back to top</a>
                    </p>
                  )}
                </div>
              </div>
            </div>
            {section.overflowShape && (
              <div className="hidden xl:block">
                <Shape position="right-terms-overflow" />
              </div>
            )}
            {section.ShapeX && (
              <ShapeX
                position="bottom-left"
                {...(section.heading === 'Our charges'
                  ? { useSmallShape: true }
                  : {})}
              />
            )}
          </section>
        );
      })}
    </div>
  );
};

export default TermsBlock;
