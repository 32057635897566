import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import { HammerSvg } from '../components/businessLineButtons/BusinessLineButtons.style';
import CarPolicy from '../components/carPolicy/CarPolicy';
import useDisclaimer from '../hooks/useDisclaimer';
import { MaxDesktopSvg, MaxMobileSvg } from './motor-docs.style';

type WordingLinkType = {
  icon: JSX.Element[];
  text?: string;
  url: string;
};

type ProductType = {
  title: string;
  link: string;
};

export type PolicyDataType = {
  wordingLinks: WordingLinkType[];
  products: ProductType[];
};

const policyData: PolicyDataType = {
  wordingLinks: [
    {
      icon: [<HammerSvg key="hammer-icon" />],
      text: 'Optional extras',
      url: '/motor-docs/optional-extras',
    },
    {
      icon: [
        <MaxDesktopSvg key="max-desktop" />,
        <MaxMobileSvg key="max-mobile" />,
      ],
      url: '/motor-docs/max',
    },
  ],
  products: [
    {
      title: 'Excess protection',
      link: '/motor-docs/car-excessprotection-ipid/',
    },
    { title: 'Key cover', link: '/motor-docs/car-key-ipid/' },
    { title: 'Legal assistance', link: '/motor-docs/car-legal-ipid/' },
    {
      title: 'Personal accident',
      link: '/motor-docs/car-personalaccident-ipid/',
    },
    {
      title: 'Replacement vehicle',
      link: '/motor-docs/car-replacementvehicle-ipid/',
    },
    { title: 'Roadside and recovery', link: '/motor-docs/car-roadside-ipid/' },
    {
      title: 'Roadside recovery and at home',
      link: '/motor-docs/car-homestart-ipid/',
    },
    {
      title: 'Roadside recovery, at home and EU',
      link: '/motor-docs/car-europeanbreakdown-ipid/',
    },
    { title: 'Tools in transit', link: '/motor-docs/car-tools-in-transit/' },
    { title: 'Windscreen cover', link: '/motor-docs/car-windscreen-ipid/' },
  ],
};

const MotorDocsPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  return <CarPolicy data={policyData} />;
};

export default MotorDocsPage;

export const Head: HeadFC = () => (
  <>
    <title>Car insurance documents | Policy Expert</title>
    <meta
      name="description"
      content="Car insurance policy documents. Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
