import React from 'react';

import Icon from '../../components/icons/Icon';
import { ComplainDetailsDataType } from '../../pages/how-to-complain';

type Props = {
  data: ComplainDetailsDataType;
};

const ComplaintsDetailsBanner = ({ data }: Props) => (
  <div className={`${data.bgColor} py-82px md:py-130px`}>
    <div className="padding-x flex flex-col justify-between md:flex-row">
      <div className="flex flex-col w-full md:w-5/12 pb-82px md:pb-0">
        <h1 className="sectionHeadingMobile md:sectionHeading md:w-1/2">
          {data.sectionHeader}
        </h1>
      </div>
      <div className="flex flex-col w-full md:w-5/12">
        {data.cards.map((item: any) => (
          <div key={item.header}>
            <div key={item.header}>
              <h4 className="text-pxPurple pb-32px">{item.header}</h4>
            </div>

            {item.cardCopy.map((item: any) => (
              <div key={item.text} className="flex flex-row break-words">
                <div className="pr-3.5">
                  <Icon type="primary" size={1}>
                    {item.icon}
                  </Icon>
                </div>
                {item.text}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default ComplaintsDetailsBanner;
