import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import startCase from 'lodash/fp/startCase';

import appConfig from '../../config';
import { Section } from '../../pages/contact';

type Props = {
  section?: Section;
};

const ContactUs = ({ section }: Props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [reg, setReg] = useState('');
  const [option, setOption] = useState<null | string>(null);
  const [validOption, setValidOption] = useState<null | boolean>(null);
  const [validEmail, setValidEmail] = useState<null | boolean>(null);
  const [validName, setValidName] = useState<null | boolean>(null);
  const [validMessage, setValidMessage] = useState<null | boolean>(null);
  const [validReg, setValidReg] = useState<null | boolean>(null);

  const nameField = useRef<null | any>(null);
  const emailField = useRef<null | any>(null);
  const regField = useRef<null | any>(null);

  const [success, setSuccess] = useState(false);

  //validating autofill email
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        emailField != null &&
        emailField.current &&
        emailField.current.value != null &&
        emailField.current.value.length >= 1
      ) {
        // @ts-ignore
        setEmail(emailField.current.value);
        clearInterval(interval);
      }
    }, 1);
  });

  //validating autofill name
  useEffect(() => {
    const interval = setInterval(() => {
      if (nameField.current && nameField.current.value.length >= 1) {
        // @ts-ignore
        setName(nameField.current.value);

        clearInterval(interval);
      }
    }, 1);
  });
  //validating autofill car registration
  useEffect(() => {
    const interval = setInterval(() => {
      if (regField.current && regField.current.value.length >= 1) {
        // @ts-ignore
        setReg(regField.current.value);

        clearInterval(interval);
      }
    }, 1);
  });

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (email.length >= 1) {
      if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
        setValidEmail(true);
      } else {
        setValidEmail(false);
      }
    } else {
      setValidEmail(null);
    }
  }, [email]);
  const handleName = (e: any) => {
    setName(e.target.value);
  };
  useEffect(() => {
    if (name.length >= 1) {
      if (name.length >= 2) {
        setValidName(true);
      } else {
        setValidName(false);
      }
    } else {
      setValidName(null);
    }
  }, [name]);
  const handleReg = (e: any) => {
    setReg(e.target.value);
  };
  useEffect(() => {
    if (reg.length >= 1) {
      if (reg.length >= 2) {
        setValidReg(true);
      } else {
        setValidReg(false);
      }
    } else {
      setValidReg(null);
    }
  }, [reg]);
  useEffect(() => {
    if (option != null && option.length >= 1) {
      setValidOption(true);
    }
  }, [option]);
  const handleMessage = (e: any) => {
    setMessage(e.target.value);
    if (message.length >= 1) {
      if (message.length >= 5) {
        setValidMessage(true);
      } else {
        setValidMessage(false);
      }
    } else {
      setValidMessage(null);
    }
  };

  const handleValidSubmit = () => {
    if (
      (validEmail &&
        validName &&
        validOption &&
        validMessage &&
        section === 'home') ||
      section === 'pet'
    ) {
      return true;
    }
    if (validEmail && validName && validOption && validMessage && validReg) {
      return true;
    }
    return false;
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setMessage('');
    setReg('');
    setOption(null);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    if (validEmail == null) {
      setValidEmail(false);
    }
    if (validName == null) {
      setValidName(false);
    }
    if (validMessage == null) {
      setValidMessage(false);
    }
    if (validReg == null) {
      setValidReg(false);
    }
    if (validOption == null) {
      setValidOption(false);
    }

    if (handleValidSubmit()) {
      let editedOption;
      if (option != null) {
        editedOption = option.slice(0, -1); // Nik - 29/09 - Slicing dropdown option values as we need different values for the selection to display the correct label on the front-end
      }
      let messageForm = message + '\n\nEmail: ' + email + '\n\nName: ' + name;
      if (section === 'car') {
        messageForm = messageForm + '\n\nVehicle Registration: ' + reg;
      }

      let dataStart: any = {
        host: 'PE',
        name: name,
        email: email,
        subject: editedOption,
        message: messageForm,
        dataTitle: 'contacthome form',
      };

      if (section === 'car') {
        dataStart = {
          override: editedOption + '@policyexpert.co.uk',
          ...dataStart,
        };
      }

      if (section === 'pet') {
        dataStart = {
          ...dataStart,
          override: 'pet-webform@policyexpert.co.uk',
          subject: `Pet - ${startCase(editedOption!)}`,
        };
      }

      const dataStringified = JSON.stringify(dataStart);

      const config = {
        headers: {},
      };
      axios.post(`${appConfig.FORM_URL}`, dataStringified, config).then(() => {
        setSuccess(true);
        clearForm();
      });
    }
  };

  return (
    <div className="pt-40px lg:pt-0 pb-40px border-b lg:border-0" id={`formId`}>
      <h3 className="font-camptonMedium text-fontSize-25 lg:text-fontSize-30 pb-15px lg:pb-30px">
        Send us an email
      </h3>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <input
          ref={nameField}
          name="name"
          className="impact-input"
          type="text"
          placeholder="Your name"
          value={name}
          onChange={(e) => handleName(e)}
        />
        {validName == false && (
          <p className="mb-0 ml-4 mt-6px text-pxPurple text-small">
            Please enter your name
          </p>
        )}
        <input
          ref={emailField}
          name="email"
          className="impact-input mt-20px"
          type="text"
          id="email"
          placeholder="Your email"
          value={email}
          onChange={(e) => handleEmail(e)}
        />
        {validEmail == false && (
          <p className="mb-0 ml-4 mt-6px text-pxPurple text-small">
            Please enter a valid email address
          </p>
        )}
        {/* START VEHICILE REGISTRATION */}
        {section === 'car' ? (
          <>
            <input
              ref={regField}
              name="vehicle-registration"
              className="impact-input mt-20px"
              type="text"
              id="vehicle-registration"
              placeholder="Vehicle Registration"
              value={reg}
              onChange={(e) => handleReg(e)}
            />
            {validReg == false && (
              <p className="mb-0 ml-4 mt-6px text-pxPurple text-small">
                Please enter your vehicle's registration
              </p>
            )}
          </>
        ) : (
          ''
        )}
        {/* END VEHICILE REGISTRATION */}

        <select
          className="impact-select mt-20px"
          // @ts-ignore
          value={option}
          // @ts-ignore
          onChange={(e) => setOption(e.target.value)}
          placeholder="Please choose"
        >
          <option value="" disabled selected>
            Please choose
          </option>
          {section === 'home' || section === 'pet' ? (
            <>
              <option value={'renewals1'}>Renewal</option>
              <option value={'adjustments1'}>Policy Change</option>
              <option value={'claims1'}>Claim</option>
              <option value={'payments1'}>Payment</option>
              <option value={'general1'}>Everything else</option>
            </>
          ) : (
            <>
              <option value="motor-documents1">Documents</option>
              <option value={'motor-renewals1'}>Renewal</option>
              <option value={'motor1'}>Policy Change</option>
              <option value={'motor2'}>Claim</option>
              <option value={'motor3'}>Payment</option>
              <option value={'motor4'}>Everything else</option>
            </>
          )}
        </select>
        {validOption == false && (
          <p className="mb-0 ml-4 mt-6px text-pxPurple text-small">
            Please choose an option
          </p>
        )}
        <textarea
          className="impact-input mt-20px"
          rows={5}
          cols={40}
          placeholder="Your message"
          value={message}
          onChange={(e) => handleMessage(e)}
        />
        {validMessage == false && (
          <p className="mb-0 ml-4 mt-6px text-pxPurple text-small">
            Please enter a message
          </p>
        )}
        <button
          className="btn-secondary btn-md md:btn-lg mt-30px"
          id="submit"
          type="submit"
          aria-label="Submit form"
        >
          Submit
        </button>
      </form>
      {success && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50">
          <div className="w-full  md:max-w-576 mx-auto  text-center border-4 shadow-2xl p-82px pb-32px md:rounded-radius-70 border-pxPink top-2/3 relative bg-white opacity-100 z-1000">
            <p className="text-pxPink">Thank you</p>
            <p className="text-small mb-0">
              Your request has been submitted successfully
            </p>
            <p className="text-small">We will be in touch shortly</p>
            <button
              onClick={() => setSuccess(false)}
              aria-label="Close alert"
              className={`mx-auto btn-md
            lg:btn-lg 
                    btn-primary`}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
