import React from 'react';

import {
  CarIcon,
  ContentsContainer,
  HouseIcon,
  IconContainer,
  ImpactLinks,
  PetIcon,
} from './LoginProductButtons.style';

type ProductType = 'home' | 'car' | 'pet';

export type ProductData = {
  product: ProductType;
  buttonHeader: string;
  url: string;
};

const getProductIcon = (product: ProductType) => {
  switch (product) {
    case 'home':
      return <HouseIcon data-testid="login-house-icon" />;
    case 'car':
      return <CarIcon data-testid="login-car-icon" />;
    case 'pet':
      return <PetIcon data-testid="login-pet-icon" />;
    default:
      return null;
  }
};

const LoginProductButtons = ({ product, buttonHeader, url }: ProductData) => {
  return (
    <ImpactLinks href={url} data-testid="login-product-button">
      <ContentsContainer>
        <IconContainer>{getProductIcon(product)}</IconContainer>
        {buttonHeader}
      </ContentsContainer>
    </ImpactLinks>
  );
};

export default LoginProductButtons;
