import React, { useContext } from 'react';

import Trustpilot from '../../../static/svgIcons/TrustPilotHorizontal.svg';
import TrustpilotWhite from '../../../static/svgIcons/TrustPilotHorizontalWhite.svg';
import { ReviewsContext } from '../../context/ReviewsProvider';
import useEnableImpactV2Update from '../../hooks/useEnableImpactV2Update';

type Props = {
  isHomepage?: boolean;
};

const TrustPilotHeader = ({ isHomepage }: Props) => {
  const trustpilotReviews = useContext(ReviewsContext);
  const isHighTrustScore =
    parseFloat(trustpilotReviews.score?.trustScore) >= 4.8;

  const v2Refresh = useEnableImpactV2Update();

  return trustpilotReviews.score && trustpilotReviews.numberOfReviews ? (
    <div
      className={`${
        isHomepage
          ? `${
              v2Refresh ? 'mb-380px' : 'mb-260px'
            } flex flex-col items-center md:block`
          : 'mb-45px pb-40px'
      } md:pb-0 md:mb-0`}
      data-testid="trustpilot-header"
    >
      <div className="mb-10px lg:mb-20px">
        {isHomepage ? (
          <TrustpilotWhite
            data-testid="trustpilot-header-white-svg"
            className="z-50 flex h-25px md:w-208px lg:h-30px"
            fill={isHighTrustScore ? '#00b67a' : '#dcdce6'}
          />
        ) : (
          <Trustpilot
            data-testid="trustpilot-header-svg"
            className="z-50 flex h-25px md:w-208px lg:h-30px"
            fill={isHighTrustScore ? '#00b67a' : '#dcdce6'}
          />
        )}
      </div>
      <p
        data-testid="trustpilot-header-text"
        className={`${
          isHomepage && 'text-pxWhite'
        } text-fontSize-15 md:text-fontSize-16 lg:text-fontSize-18 text-center md:text-left mb-0`}
      >
        Rated{' '}
        <span className="font-camptonSemiBold">
          {trustpilotReviews.score.trustScore}/5
        </span>{' '}
        from over {trustpilotReviews.numberOfReviews.total.toLocaleString()}{' '}
        reviews.
      </p>
    </div>
  ) : null;
};

export default TrustPilotHeader;
