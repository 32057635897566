import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import RefreshHomeEmergencyComparison from '../../../components/hecLandingPage/home-emergency-comparison';
import OldHomeEmergencyComparison from '../../../components/hecLandingPage/old-home-emergency-comparison';
import useDisclaimer from '../../../hooks/useDisclaimer';
import useEnableHECLandingPage from '../../../hooks/useEnableHECLandingPage';

const HomeEmergencyComparisonPage: React.FC<PageProps> = () => {
  useDisclaimer(null);
  const enableHecLandingPage = useEnableHECLandingPage();

  return (
    <>
      {enableHecLandingPage ? (
        <RefreshHomeEmergencyComparison />
      ) : (
        <OldHomeEmergencyComparison />
      )}
    </>
  );
};

export default HomeEmergencyComparisonPage;

export const Head: HeadFC = () => (
  <>
    <title>Home insurance policy documents | Policy Expert</title>
    <meta
      name="description"
      content="Home insurance policy documents from Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
