import { styled } from 'styled-components';

import pawPrintsTop from '../../images/paw_prints_top.png';
import {
  fontFamilyCamptonBook,
  fontFamilyCamptonMedium,
} from '../../styles/sharedStyled';

export const ProductBannerBackgroundContainer = styled.div<{
  $backgroundColor: string;
}>`
  position: relative;
  padding-top: 60px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || ''};

  @media (min-width: 768px) {
    padding-top: 100px;
  }

  @media (min-width: 1024px) {
    padding-top: 130px;
    background-image: url(${pawPrintsTop});
    background-repeat: no-repeat;
    background-size: 1640px 2191px;
    background-position: top 51% left 55%;
  }
`;

export const ProductBannerInformationWrapper = styled.div`
  z-index: 20;
  margin: 0 auto;

  @media (min-width: 1024px) {
    max-width: 900px;
  }
`;

export const ProductBannerTitle = styled.h2`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 35px;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 40px;
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 65px;
  }
`;

export const ProductBannerText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 18px;
  margin: 0 24px 50px;
  text-align: center;

  @media (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto 70px;
  }

  @media (min-width: 1024px) {
    font-size: 25px;
    margin: 0 0 90px;
    max-width: none;
  }
`;

export const ProductBannerImagesContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -35px;

  @media (min-width: 768px) {
    margin-bottom: -80px;
    justify-content: center;
  }
`;

export const PawPrintsBottomImageContainer = styled.div`
  height: 170px;
  width: 400px;
  position: absolute;
  right: 220px;
  bottom: 40px;

  @media (min-width: 768px) {
    height: 220px;
    width: 450px;
    position: relative;
    right: 0;
    bottom: 0;
  }

  @media (min-width: 1024px) {
    height: 400px;
    width: 760px;
  }
`;

export const CatTailImageContainer = styled.div`
  height: 200px;
  width: 205px;

  @media (min-width: 768px) {
    height: 276px;
    width: 279px;
    position: relative;
    right: -25px;
  }

  @media (min-width: 1024px) {
    height: 460px;
    width: 500px;
    right: -25px;
  }
`;

export const TermsAndConditionsStatement = styled.div`
  font-family: ${fontFamilyCamptonBook};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 30px 29px 0;
  text-align: center;
`;
