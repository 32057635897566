import React from 'react';

import DropShapeCardBlogPost from '../dropShapeCardBlogPost/DropShapeCardBlogPost';
import ImageComponent from '../imageComponent/ImageComponent';

const RelatedArticles = ({ data }: any) => {
  return (
    <div className="pt-32px pb-130px z-50 relative ">
      <h3 className="pb-32px md:pb-82px">More information: </h3>
      <div className="flex flex-col md:flex-row gap-10">
        {data.relatedArticles &&
          data.relatedArticles.map((article: any) => (
            <DropShapeCardBlogPost fullWidth key={article.title}>
              <div className="flex-1 bg-white">
                <a href={article.url} target="_blank" rel="noreferrer">
                  <div>
                    <DropShapeCardBlogPost fullWidth>
                      <ImageComponent
                        className="max-w-full h-269 md:h-200 lg:h-269"
                        imageFile={article.imageFile}
                        alt={article.heading}
                      />
                    </DropShapeCardBlogPost>
                  </div>
                  <div className="px-4 py-8">
                    <p className="text-pxCharcoal text-small">
                      {article.title}
                    </p>
                    <a
                      href={article.url}
                      className="font-semibold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read more
                    </a>
                  </div>
                </a>
              </div>
            </DropShapeCardBlogPost>
          ))}
      </div>
    </div>
  );
};

export default RelatedArticles;
