import React from 'react';

import execAdam from '../../images/about-us/adampowell.png';
import execAlex from '../../images/about-us/alexbearpark.png';
import execAgreen from '../../images/about-us/antonygreen.png';
import execAudreymcdade from '../../images/about-us/audreymcdade.png';
import execChristine from '../../images/about-us/christineminetou.png';
import comingSoon from '../../images/about-us/coming_soon.svg';
import execDavid from '../../images/about-us/davidprior.png';
import execKevin from '../../images/about-us/kevinchidwick.png';
import execMillie from '../../images/about-us/millienash.png';
import execSteve from '../../images/about-us/stevehardy.png';
import { AboutUsEmployee } from '../imageWithCarouselReveal/ImageWithCarouselReveal';
import {
  AboutOurExecContainer,
  AboutOurExecWrapper,
  CardsWrapper,
  Title,
  TitleWrapper,
} from './AboutOurExec.style';
import ExecCard from './ExecCard';

const aboutUsExecs: Array<AboutUsEmployee> = [
  {
    name: 'Steve Hardy',
    role: 'Executive Chairman',
    image: execSteve,
  },
  {
    name: 'Kevin Chidwick',
    role: 'Chief Financial Officer',
    image: execKevin,
  },
  {
    name: 'Adam Powell',
    role: 'Chief Operating Officer',
    image: execAdam,
  },
  {
    name: 'David Prior',
    role: 'Chief Underwriting Officer',
    image: execDavid,
  },
  {
    name: 'Linda Crichton',
    role: 'Chief Customer Officer',
    image: comingSoon,
  },
  {
    name: 'Audrey McDade',
    role: 'Chief Compliance Officer',
    image: execAudreymcdade,
  },
  {
    name: 'Millie Nash',
    role: 'Chief People Officer',
    image: execMillie,
  },
  {
    name: 'Christine Minetou',
    role: 'Chief Pricing Officer',
    image: execChristine,
  },
  {
    name: 'Alex Bearpark',
    role: 'Chief Insurance Risk Officer',
    image: execAlex,
  },
  {
    name: 'Antony Green',
    role: 'Chief Product & Tech Officer',
    image: execAgreen,
  },
  {
    name: 'Gary Barker',
    role: 'Managing Director Trinity',
    image: comingSoon,
  },
];

const AboutOurExec = () => {
  return (
    <AboutOurExecContainer id="about-execs">
      <AboutOurExecWrapper>
        <TitleWrapper>
          <Title>Our Executive Team</Title>
        </TitleWrapper>
        <CardsWrapper>
          {aboutUsExecs.map((person: AboutUsEmployee) => (
            <ExecCard person={person} key={person.name} />
          ))}
        </CardsWrapper>
      </AboutOurExecWrapper>
    </AboutOurExecContainer>
  );
};

export default AboutOurExec;
