import React, { useContext, useEffect, useState } from 'react';

import { HeadFC, navigate } from 'gatsby';

import ImpactButtonsV2HeaderBanner from '../components/headerBanner/ImpactButtonsV2HeaderBanner';
import ProductButtonsBanner from '../components/productButtonsBanner/ProductButtonsBanner';
import NoClaimsDiscountTable from '../components/productTable/NoClaimsDiscountTable';
import ProductChargesTable, {
  ProductDataType,
} from '../components/productTable/ProductChargesTable';
import SlantedBackground from '../components/slantedBackground/SlantedBackground';
import ImpactV2TermsBlock, {
  ImpactV2TermsBlockDataType,
} from '../components/termsBlock/ImpactV2TermsBlock';
import config from '../config';
import { PhoneNumbersContext } from '../context/PhoneNumbersProvider';
import formatPhoneNumber from '../helpers/formatPhoneNumber';
import waitForElement from '../helpers/waitForElement';
import useDisclaimer from '../hooks/useDisclaimer';
import usePetChanges from '../hooks/usePetChanges';
import useProductSelection from '../hooks/useProductSelection';
import useQueryParams from '../hooks/useQueryParams';
import { SemiBoldSpan } from '../styles/sharedStyled';
import { Section } from './contact';
import {
  NumberedTermsListWrapper,
  SectionTextWrapper,
  TermsContactUsContainer,
  TermsContactUsList,
  TermsContactUsText,
  TermsExternalLink,
  TermsInternalLink,
  TermsListData,
  TermsListWrapper,
  TermsTelephoneLink,
  TermsText,
  TermsTextHeader,
} from './terms-and-conditions.style';

const TermsAndConditionsPage = () => {
  useDisclaimer(null);
  const phoneNumbers = useContext(PhoneNumbersContext);

  const { product } = useQueryParams();
  const isPetChangesEnabled = usePetChanges();
  const renderAllProductSelection = useProductSelection(['home', 'car', 'pet']);
  const renderTwoProductSelection = useProductSelection(['home', 'car']);
  const [section, toggleSection] = useState<Section>('');
  const windowHashExists =
    typeof window !== 'undefined' && window.location.hash;

  const handlePathUpdate = (product: Section) => {
    navigate(`/terms-and-conditions/?product=${product}`);
  };

  useEffect(() => {
    if (product === 'home' || product === 'car' || product === 'pet') {
      toggleSection(product);
    } else {
      toggleSection('');
    }
  }, [product]);

  useEffect(() => {
    if (windowHashExists) {
      const sectionId = decodeURIComponent(
        window.location.hash.replace('#', '')
      );
      waitForElement(sectionId).then(() => {
        navigate(
          `/terms-and-conditions/?product=${product}${window.location.hash}`
        );
      });
    }
  }, [windowHashExists]);

  const productInfo: Record<Section, { email: string; phoneNumber: string }> = {
    home: {
      email: 'home@policyexpert.co.uk',
      phoneNumber: phoneNumbers.home?.mainMenu,
    },
    car: {
      email: 'motor@policyexpert.co.uk',
      phoneNumber: phoneNumbers.motor?.mainMenu,
    },
    pet: {
      email: 'pet@policyexpert.co.uk',
      phoneNumber: phoneNumbers.pet?.mainMenu,
    },
    '': {
      email: '',
      phoneNumber: '',
    },
  };

  const renderProductEmailHref = (section: Section) =>
    `mailto:${productInfo[section].email}`;

  const renderProductEmail = (section: Section) => productInfo[section].email;

  const renderProductPhoneNumberHref = (section: Section) =>
    `tel:${formatPhoneNumber(productInfo[section].phoneNumber)}`;

  const renderProductPhoneNumber = (section: Section) =>
    productInfo[section].phoneNumber;

  const heroBannerData = {
    title: (
      <h1 className="text-pxWhite px-10px md:px-0 text-fontSize-30 md:text-fontSize-35 lg:text-fontSize-50 font-medium mb-15px md:mb-25px lg:mb-30px text-center">
        Terms and conditions
      </h1>
    ),
    text: (
      <p className="text-pxWhite text-fontSize-18 lg:text-fontSize-25 text-center font-normal mb-50px md:mb-40px">
        Please select your product
      </p>
    ),
  };

  const homeProductTableData: ProductDataType = {
    bgColor: 'bg-pxGrey',
    textColor: 'text-black',
    headerText: 'Home insurance',
    type: 'home',
    isMaxWidth: true,
    columns: 2,
    subColumns: 4,
    productRows: [
      {
        label: '',
        included: [
          '*Quoted before 06/06/23',
          '*Quoted on or after 06/06/23',
          '*Quoted before 06/06/23',
          '*Quoted on or after 06/06/23',
        ],
        hasBlackText: true,
      },
      {
        label: 'Buying your policy over the phone',
        included: ['£0', '£12.50', '£0', '£0'],
      },
      {
        label: 'Arrangement fee',
        included: ['£0', '£0', '£0', '£0'],
      },
      {
        label: 'Making a change to your policy online after purchase',
        included: ['£0', '£15', '£0', '£15'],
      },
      {
        label:
          'Making a change to your policy via our call-centre after purchase',
        included: ['£0', '£20', '£0', '£20'],
      },
      {
        label:
          'If you or we cancel your policy within the 14-day cooling-off period',
        included: ['£0', '£15', '£0', '£0'],
      },
      {
        label:
          'If you or we cancel your policy after the 14-day cooling-off period',
        included: ['£25', '£30', '£25', '£30'],
      },
      {
        label:
          'If you do not sign and return the loan agreement within 28 days',
        included: ['£25', '£25', 'Not applicable', 'Not applicable'],
      },
      {
        label: 'For each monthly payment that you miss',
        included: ['£25', '£25', 'Not applicable', '£15'],
      },
    ],
  };

  const carProductTableData: ProductDataType = {
    bgColor: 'bg-pxGrey',
    textColor: 'text-black',
    headerText: 'Car insurance',
    isMaxWidth: true,
    columns: 1,
    type: 'car',
    subColumns: 1,
    productRows: [
      {
        label: 'Buying your policy over the phone ',
        included: ['£12.50'],
      },
      {
        label: 'Arrangement fee',
        included: ['£0'],
      },
      {
        label: 'Making a change to your policy online after purchase',
        included: ['£30'],
      },
      {
        label:
          'Making a change to your policy via our call-centre after purchase',
        included: ['£30'],
      },
      {
        label:
          'If you or we cancel your policy within the 14-day cooling-off period',
        included: ['£30'],
      },
      {
        label:
          'If you or we cancel your policy after the 14-day cooling-off period',
        included: ['£60'],
      },
      {
        label:
          'If you do not sign and return the loan agreement within 28 days',
        included: ['£25'],
      },
      {
        label:
          'If you call the lender direct to change the date of your monthly payments (if you call us instead, we can arrange this without charge)',
        included: ['£0'],
      },
      {
        label: 'For each monthly payment that you miss ',
        included: ['£25'],
      },
    ],
  };

  const noClaimsDiscountTableData: any = {
    columns: 2,
    type: 'car',
    subColumns: 3,
    headerRow: {
      label: (
        <p
          className={
            'px-0 md:px-20px mb-0 text-fontSize-15 text-normal font-camptonLight md:font-camptonBook'
          }
        >
          NCD at the start of your current period of insurance
        </p>
      ),
      included: (
        <p
          className={
            'px-15px text-center md:px-20px mb-0 text-fontSize-15 text-normal font-camptonLight md:font-camptonBook'
          }
        >
          Your NCD at the start of renewal if you make…
        </p>
      ),

      hasBlackText: true,
    },

    productRows: [
      {
        label: '',
        included: ['0 claims', '1 claims', '2 claims'],
        hasBlackText: true,
        hasLightGreyBackground: true,
      },
      {
        label: '0 years',
        included: ['1 year', '0 years', '0 years'],
      },
      {
        label: '1 year',
        included: ['2 years', '0 years', '0 years'],
      },
      {
        label: '2 years',
        included: ['3 years', '0 years', '0 years'],
      },
      {
        label: '3 years',
        included: ['4 years', '1 year', '0 years'],
      },
      {
        label: '4 years',
        included: ['5 years', '2 years', '0 years'],
      },
      {
        label: '5 or more years',
        included: ['6+ years', '4 years', '2 years'],
      },
    ],
  };

  const optOutEmail = `${
    section === 'car' ? 'motor' : section
  }-optout@policyexpert.co.uk`;

  const termsBlockData: ImpactV2TermsBlockDataType = {
    description: (
      <SectionTextWrapper>
        <TermsText>
          We are committed to providing the highest standard of service to our
          customers. Our dealings with you should always be clear and easy to
          understand.
        </TermsText>
        {section === 'home' && (
          <TermsText>
            Protect policies have their own terms and conditions, which you can
            read{' '}
            <TermsExternalLink
              href={`${config.DOCUMENTS_URL}/home/qgl15/policyexpert_terms_conditions_0923.pdf`}
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </TermsExternalLink>
            . Protect policy fees are listed in the 'Our charges' section below.
          </TermsText>
        )}
      </SectionTextWrapper>
    ),
    termsInformation: [
      {
        heading: 'Introduction and important information',
        body: (
          <div>
            <TermsText>
              Welcome to our terms and conditions. These terms and conditions
              form the basis of the contract between you (the customer) and us
              (Policy Expert), so it's important that you read them carefully
              each time you purchase an insurance policy with us. These terms
              and conditions set out:
            </TermsText>
            <TermsListWrapper>
              <TermsListData>
                who we are and how you can contact us;
              </TermsListData>
              <TermsListData>
                what services we provide, including any fees and charges; and
              </TermsListData>
              <TermsListData>
                how we use theinformation you give us.
              </TermsListData>
            </TermsListWrapper>
            <TermsText>
              You should use the information we provide here to decide if our
              services are right for you.
            </TermsText>
            <TermsText>
              If you'd like to read our privacy policy, please{' '}
              <TermsInternalLink to="/privacy-policy">
                click here
              </TermsInternalLink>{' '}
              which outlines how we'll use any personal information you give us.
              By communicating with us or using our services (whether on our
              website, by phone, email or social media, or in writing) you agree
              to keep to these terms and conditions and accept our privacy
              policy.
            </TermsText>
            <TermsText>
              Please note: in these terms and conditions, 'our', 'we' and 'us'
              refer to Policy Expert. Policy Expert is a trading name of QMetric
              Group Limited, which is authorised and regulated by the Financial
              Conduct Authority (FCA). FCA registered number: 529506,{' '}
              <TermsExternalLink
                href="https://www.fca.org.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.fca.org.uk
              </TermsExternalLink>{' '}
              for more information. The FCA is the independent body that
              regulates financial services. QMetric's registration with the FCA
              allows us to provide insurance services, intermediation and credit
              broking.
            </TermsText>
            <TermsText>
              The registered address of QMetric Group Limited is: 110
              Bishopsgate, London, EC2N 4AY and its company registration number
              is: 07151701, registered in England and Wales.
            </TermsText>
            <TermsText>
              We have the right to amend these terms and conditions without
              giving you notice. If you have any questions about the terms and
              conditions, please get in touch, a member of our team will be
              happy to help:
            </TermsText>
            <TermsText>
              Email us at{' '}
              <TermsExternalLink href={renderProductEmailHref(section)}>
                {renderProductEmail(section)}
              </TermsExternalLink>{' '}
              or call us on{' '}
              <TermsTelephoneLink href={renderProductPhoneNumberHref(section)}>
                {renderProductPhoneNumber(section)}
              </TermsTelephoneLink>
            </TermsText>
          </div>
        ),
      },
      {
        heading: 'Our service to you',
        body: (
          <div>
            <TermsText>
              By asking a series of questions we aim to identify your insurance
              demands and needs and provide you with quotes for the products and
              services that we offer.
            </TermsText>
            <TermsText>
              We don't offer advice or make recommendations on which of our
              products or services will suit you best. We'll provide you with
              all the relevant information relating to our products, however,
              you need to decide what product you'd like to purchase. If you're
              still unsure, we suggest you get advice from an independent
              insurance adviser.
            </TermsText>
            <TermsText>
              We work with a number of carefully chosen third parties to offer
              you the products and services that you can buy from us. The full
              list of these is available{' '}
              <TermsInternalLink to="/trusted-partners">
                here.
              </TermsInternalLink>{' '}
            </TermsText>
            <TermsText>
              We're an authorised intermediary, acting on behalf of our insurer
              to provide quotes, issue policies, collect premiums and manage
              claims.
            </TermsText>
            <TermsText>
              We have the right to add more insurers or change existing
              insurers, as we see fit, to make sure we maintain the high
              standards we aim for in offering and providing you with insurance
              cover.
            </TermsText>
            <TermsText>
              There's more information on the insurers who are underwriting
              (providing) your policy in the policy wording or insurance product
              information documents. Policy documents are available on our
              website.
            </TermsText>
            <TermsText>
              If you'd like us to send you paper copies of your insurance
              documents by standard class post, these are available free of
              charge. If you require a special mailing of insurance documents
              (for example guaranteed next day delivery), you'll need to pay any
              additional postage costs we incur. All documents are available
              free of charge on our website.
            </TermsText>
          </div>
        ),
      },
      {
        heading: 'Contacting you',
        body: (
          <div>
            <TermsText>
              As part of our Privacy Policy, we may communicate with you from
              time to time if we deem there to be a case for legitimate interest
              such as communicating with you in relation to the products and
              services for which you received a quotation, in relation to
              products and services that are compatible with the original
              purpose for which we gained your information, or to communicate
              important updates about our products or services which we think
              may be of interest to you. You can read more about{' '}
              <TermsInternalLink to="/privacy-policy/#6.3%20Legitimate%20Interest">
                legitimate interest here.
              </TermsInternalLink>
            </TermsText>
            <TermsText>
              If you want to opt out of your personal information being
              processed for marketing communication purposes, you can do so at
              any point. There are {section === 'car' ? 'two' : 'three'} ways in
              which you can do this.
            </TermsText>
            <NumberedTermsListWrapper>
              <TermsListData>
                By clicking the <SemiBoldSpan>unsubscribe</SemiBoldSpan> link
                which appears at the bottom of all our marketing emails OR
              </TermsListData>
              <TermsListData>
                By emailing{' '}
                <TermsExternalLink href={`mailto:${optOutEmail}`}>
                  {optOutEmail}
                </TermsExternalLink>{' '}
                {section !== 'car' && 'OR'} 
              </TermsListData>
              {section !== 'car' && (
                <TermsListData>
                  By{' '}
                  <TermsInternalLink to="/login">
                    logging into{' '}
                  </TermsInternalLink>
                  your customer account and changing your preference settings
                </TermsListData>
              )}
            </NumberedTermsListWrapper>
          </div>
        ),
      },
      {
        heading: 'Payment',
        body: (
          <div>
            {section === 'home' && (
              <TermsText>
                The following applies to Bronze, Silver and Gold policies only.
                Protect policies have their own terms and conditions (see
                above).
              </TermsText>
            )}
            <TermsTextHeader>How to make a payment</TermsTextHeader>
            <TermsText>
              {section === 'pet'
                ? 'You can pay for your policy in full by credit card or debit card, or by monthly payments.'
                : 'You can pay for your policy by credit card or debit card, or by premium finance (subject to acceptance by the lender).'}
            </TermsText>
            <TermsTextHeader>Payments by card</TermsTextHeader>
            <TermsText>
              If you use a credit or debit card to pay for a policy, then your
              payment will be processed on our behalf by one of our payment
              service providers (PSP). The PSP will keep a record of your credit
              card or debit card details so that we can use these details at a
              later date, for example:
            </TermsText>
            <TermsListWrapper>
              <TermsListData>to make a refund;</TermsListData>
              <TermsListData>
                take payment for extra insurance products you buy from us;
              </TermsListData>
              <TermsListData>
                to take payment following cancellation of your policy of any
                amount you owe us
              </TermsListData>
              <TermsListData>
                to take payment for any changes made to your policy mid term
              </TermsListData>
              <TermsListData>
                check your card is still valid for the next year's payments at
                renewal; and
              </TermsListData>
              <TermsListData>
                to take payment for the renewal of your policy.
              </TermsListData>
              <TermsListData>
                If we make changes to the way in which we use your card details
                we will send you details of these change via email.
              </TermsListData>
            </TermsListWrapper>
            <TermsText>
              Our PSP stores your card details in an encrypted format and keeps
              to the Payment Card Industry Data Security Standard, which
              includes strict requirements for how card details must be handled.
            </TermsText>
            {section === 'home' || section === 'car' ? (
              <>
                {' '}
                <TermsTextHeader>Payments by premium finance</TermsTextHeader>
                <TermsText>
                  We are registered with the Financial Conduct Authority as a
                  credit broker. If you choose to pay your insurance premium by
                  monthly payments, we will act as your agent in helping you
                  arrange finance with a lender, subject to acceptance of the
                  loan application by the lender. We will not charge you fees or
                  interest for this service, but we will receive a commission
                  from the lender. The lender will charge you interest on the
                  money they lend you. They may also charge you fees, which are
                  set out in their agreement with you and are listed in Our
                  Charges section below. If you chose to pay for your policy in
                  monthly instalments using premium finance this will mean that
                  your overall cost for the policy will be higher than if you
                  chose to pay for your policy in full.
                </TermsText>
                <TermsText>
                  The premium finance company will keep a record of your bank
                  details.
                </TermsText>
                <TermsText>
                  Your first monthly payment may be different from the rest of
                  your monthly payments. This is because the total amount you
                  will pay cannot always be divided into equal monthly amounts.
                </TermsText>
                <TermsText>
                  If you miss any monthly payment your credit rating might be
                  affected, which may mean it is difficult for you to get credit
                  in the future.
                </TermsText>
                <TermsText>
                  If you do not keep up to date with your monthly payments your
                  credit agreement will be cancelled. If this happened, you
                  would need to pay for your insurance in one payment or cancel
                  your policy.
                </TermsText>{' '}
              </>
            ) : (
              <>
                <TermsTextHeader>
                  When you purchase a policy and pay monthly
                </TermsTextHeader>
                <TermsText>
                  You’re buying a 12-month insurance policy and have chosen to
                  pay the premium in a number of monthly payments, rather than
                  pay the premium in full at the beginning of your policy.
                  Please note, you are not taking out a credit agreement.
                </TermsText>
                <TermsText>
                  Before you buy your policy, we’ll let you know:
                </TermsText>
                <TermsListWrapper>
                  <TermsListData>
                    the monthly premium amount that will be collected from you;
                    and
                  </TermsListData>
                  <TermsListData>
                    the date the premium will be collected each month
                  </TermsListData>
                </TermsListWrapper>
                <TermsText>
                  Once you’ve bought your policy, we’ll pass your personal
                  information and bank details to our trusted partner
                  GoCardless, who’ll collect these monthly premiums on our
                  behalf. They’ll set up a Direct Debit mandate and collect your
                  premiums on the dates we told you about before you bought your
                  policy. You’ll see these payments on your bank account as
                  either “Policy Expert” or “GC re Policy Expert”.
                </TermsText>
                <TermsText>
                  If the collection date falls over a weekend or an English bank
                  holiday, your premium will be collected on the next working
                  day.
                </TermsText>

                <TermsTextHeader>
                  If you don’t pay for your premium
                </TermsTextHeader>
                <TermsText>
                  If we can't collect your monthly premium, then we’ll try again
                  after 2 working days. If we can’t collect your premium within
                  15 days from the monthly due date then your policy will be
                  cancelled, and you’ll no longer have cover for your pet. 
                </TermsText>
                <TermsText>
                  We won’t charge you for any failed payments, but your bank or
                  building society may charge you.
                </TermsText>
              </>
            )}
          </div>
        ),
      },
      {
        heading: 'Payment (how we handle your money)',
        body: (
          <div>
            <TermsTextHeader>How we handle your money</TermsTextHeader>
            <TermsText>
              We act as an agent of the insurers on our panel in collecting,
              holding, receiving or paying (as appropriate) premiums, premium
              refunds, claims, reinsurance premiums, recoveries and other
              related money. You are protected because:
            </TermsText>
            <TermsText>
              premiums are treated as being received by the insurer when we
              receive them in our bank account; and a premium refund or claims
              payment is treated as being received by you when you actually
              receive it.
            </TermsText>
            <TermsText>
              We arrange the policy with the insurer on your behalf. You do not
              pay us a fee for doing this. We receive a payment from the
              insurer, which comes out of the premium you pay. This amount can
              vary.
            </TermsText>
          </div>
        ),
      },
      {
        heading: 'Administration process and our charges',
        body: (
          <div>
            <TermsTextHeader>Making a change to your policy</TermsTextHeader>
            <TermsText>
              Please call us immediately if there are any changes to the
              information set out in the following documents:
            </TermsText>
            <TermsListWrapper>
              <TermsListData>Your schedule documents</TermsListData>
              <TermsListData>
                {section === 'home' || section === 'pet'
                  ? "Your 'Questions and Answers' "
                  : 'Statement of Fact'}
                document
              </TermsListData>
            </TermsListWrapper>
            <TermsText>
              We will use the new details to amend your answers and we will
              confirm the new quotation.{' '}
              {section === 'home' &&
                `If there is an additional payment due, then we’ll take this via a card. Or, if there’s a refund due to you, we’ll refund this via the card that you previously paid with.`}
              {section === 'car' &&
                `If there is an additional payment due, then we’ll either take this via a card, or adjust your
                future monthly payments. Or, if there’s a refund due to you,
                we’ll adjust your future monthly payments.`}
              {section === 'pet' &&
                `If there is an additional payment due or a refund needed, then we’ll adjust your future 
                monthly payments.`}
            </TermsText>

            <TermsText>
              Changes in your circumstances may result in us not being able to
              offer you cover and your current policy may be cancelled. See 'Our
              refund policy' below.
            </TermsText>
            <TermsTextHeader>How to cancel your policy</TermsTextHeader>
            <TermsText>
              You can cancel any policy at any time during the policy term,
              please get in touch:
            </TermsText>
            <TermsContactUsContainer>
              <TermsContactUsList>
                <TermsContactUsText>Email: </TermsContactUsText>
                <TermsExternalLink href={renderProductEmailHref(section)}>
                  {renderProductEmail(section)}
                </TermsExternalLink>
              </TermsContactUsList>
              <TermsContactUsList>
                <TermsContactUsText>Call us: </TermsContactUsText>
                <TermsTelephoneLink
                  href={renderProductPhoneNumberHref(section)}
                >
                  {renderProductPhoneNumber(section)}
                </TermsTelephoneLink>
              </TermsContactUsList>
              <TermsContactUsList>
                <TermsContactUsText>Write to us: </TermsContactUsText>
                {section === 'home' || section === 'pet' ? (
                  <TermsText>
                    Customer Relations,
                    <br />
                    Policy Expert Home,
                    <br />
                    1st Floor,
                    <br />
                    Exchange House,
                    <br />
                    450 Midsummer Boulevard,
                    <br />
                    Milton Keynes,
                    <br />
                    MK9 2EA
                  </TermsText>
                ) : (
                  <TermsText>
                    Policy Expert Motor,
                    <br />
                    2 Parklands Avenue,
                    <br />
                    Maxim 3,
                    <br />
                    Eurocentral,
                    <br />
                    Motherwell,
                    <br />
                    ML1 4WQ
                  </TermsText>
                )}
              </TermsContactUsList>
            </TermsContactUsContainer>
            <TermsText>
              There is a cooling-off period for you to check that the policy you
              have bought meets your needs. If we provide you with electronic
              access to your policy documents, this is the first 14 days from
              the date you purchase the policy. If we do not provide you with
              electronic access to your documents, the cooling-off period is 14
              days from the date the policy documents are delivered to your
              correspondence address.
            </TermsText>
            <TermsText>
              If you cancel your policy, we may charge you a cancellation fee.
              Please refer to our fee table below.
            </TermsText>
            <TermsText>
              Any optional extra policies you have bought will also be cancelled
              on the same date as the main policy is cancelled. If cancellation
              is within the 'cooling off' period, the optional extra policies
              will be refunded in full. If the cancellation is after the
              'cooling off' period, the optional extras will be charged in full.
            </TermsText>
            <TermsTextHeader>Our refund policy</TermsTextHeader>
            <TermsText>
              If you cancel a policy and have not made a claim, we will refund
              you an amount of premium for the period from the cancellation date
              to the policy expiry date.
            </TermsText>
            <TermsText>
              If you have made a claim under your policy, or a claim has been
              made against you by a third party before you cancel then:
            </TermsText>
            <TermsListWrapper>
              <TermsListData>
                if you have paid in full you will not receive a refund
              </TermsListData>
              <TermsListData>
                if you are paying by direct-debit the full annual premium is due
                and you will be required to pay any outstanding balance on the
                instalment agreement.
              </TermsListData>
            </TermsListWrapper>
            <TermsText>
              If you have paid in full by card, we will pay refunds back to the
              original debit card or credit card you used to buy the policy. If
              this is not possible (for example, if your card has expired) we
              will arrange to send the refund via bank transfer. We do not issue
              refunds via cheque.
            </TermsText>
            <TermsTextHeader>
              If you pay monthly, we will cancel your direct debit.
            </TermsTextHeader>
            <TermsText>
              If a direct-debit payment is due from you within five days of the
              date you request to cancel, it may not be possible to stop that
              payment. If so, we will only be able to inform you of an
              approximate refund amount and will process the cancellation once
              the payment has completed.
            </TermsText>
            <TermsText>
              If you have chosen to pay by direct debit your effective date of
              policy cancellation will be the last date of paid cover, or the
              cancellation date you request, whichever date is the earliest.
            </TermsText>
            <TermsTextHeader>Our right to cancel your policy</TermsTextHeader>
            <TermsText>
              We have the right to cancel any policy at any time if there is a
              valid reason for doing so (for example, if you haven't given us
              the correct information). We will give you at least 7 days' notice
              in writing to the email address you have provided before
              cancelling any policy. If you have not provided us with an email
              address then we will send this notification by post to the
              correspondence address you have provided. If fraud has been
              proven, we do not have to give you notice and can cancel the
              policy immediately.
            </TermsText>
          </div>
        ),
      },
      {
        heading: 'Our charges',
        body: (
          <>
            <SectionTextWrapper>
              <TermsText>
                If you want to amend your{' '}
                {section === 'home' ? 'home ' : 'car '}
                policy and the change is acceptable to your insurer, your
                premium will be adjusted to reflect this new risk. If you pay
                monthly, your monthly amounts will be adjusted.
              </TermsText>
            </SectionTextWrapper>

            {section === 'home' && (
              <ProductChargesTable data={homeProductTableData} />
            )}

            {section === 'car' && (
              <ProductChargesTable data={carProductTableData} />
            )}

            {section === 'home' ? (
              <SectionTextWrapper>
                <TermsText>
                  *Quoted refers to the date on which your new business or
                  renewal price was created for you
                </TermsText>
              </SectionTextWrapper>
            ) : section === 'car' ? (
              <SectionTextWrapper>
                <TermsText>
                  In addition to the above fees, some of our insurers may charge
                  you a fee if you or they
                </TermsText>
                <TermsListWrapper>
                  <TermsListData>
                    make a change to your policy after you’ve bought it; or
                  </TermsListData>
                  <TermsListData>cancel your policy.</TermsListData>
                </TermsListWrapper>
              </SectionTextWrapper>
            ) : null}
          </>
        ),
      },
      {
        heading: 'How to renew your policy',
        body: (
          <>
            <TermsText>
              Before your policy is due to end we will let you know by email,
              phone, or letter if we are able to continue offering you
              insurance. We aim to give you 14 days' notice of this.
            </TermsText>
            {section !== 'pet' ? (
              <>
                <TermsTextHeader>Automatic renewal</TermsTextHeader>
                <TermsText>
                  If we can offer you cover at renewal, we will send you a quote
                  for your next year of cover{' '}
                  {section === 'home' ? '30 ' : 'at least 21 '}days before your
                  renewal date. Your policy will automatically renew which
                  ensures that you maintain continual cover. If you would prefer
                  to not automatically renew your cover with us, you can opt
                  out.
                </TermsText>
                <TermsText>
                  You can opt out of automatic renewal up until the day before
                  your policy is due to be renewed. Please note that if you are
                  opting out in the last 30 days of your policy, you will need
                  to do so via live chat, email or telephone.
                </TermsText>
                <TermsText>
                  Please be aware that if you opt out of automatic renewal, we
                  cannot ensure your continual cover.{' '}
                  {section === 'home'
                    ? 'If your home is uninsured, you are at risk of having to pay for replacing, repairing or rebuilding your home or possessions if something unexpected happens, such as theft, fire or flood. Being uninsured may also affect the conditions for any loans you may have secured against your home.'
                    : 'Driving without insurance may result in a fine, penalty points, a motoring conviction, your car being seized by the police and/or disqualification from driving.'}
                </TermsText>
                <TermsText>
                  Please note that we usually take renewal payments up to{' '}
                  {section === 'home' ? 'four ' : 'ten '}days before your policy
                  renewal date in order to ensure payment(s) are taken on time
                  to make sure that your cover is not interrupted. For this
                  reason, if you cancel with less than{' '}
                  {section === 'home' ? 'four ' : 'ten '}
                  days before the renewal date, a payment may still be taken but
                  rest assured that it will be refunded if you choose not to
                  renew with us.
                </TermsText>
                <TermsTextHeader>
                  Automatic renewal (paid in full policies)
                </TermsTextHeader>
                <TermsText>
                  If you have paid for your policy in full we will try and renew
                  your policy using the last payment card details that you used
                  with us.
                </TermsText>

                {section === 'home' && (
                  <>
                    <TermsText>
                      About one month before automatically renewing a policy, we
                      will pre-authorise your previous payment card with £2.
                      This does not transfer any money to us, but temporarily
                      reduces the money available to spend on your card. After a
                      few days (depending on your bank) this reserved amount is
                      released and is available again on your card.
                    </TermsText>
                    <TermsText>
                      If we find that your card details have changed, we will
                      ask our payment service provider to update the card
                      details they hold.
                    </TermsText>
                    <TermsText>
                      {' '}
                      These are standard procedures in the insurance and
                      financial services industry. We do this to:
                    </TermsText>
                    <TermsListWrapper>
                      <TermsListData>
                        check that your method of payment is still valid;
                      </TermsListData>
                      <TermsListData>
                        give us time to contact you if there is a problem; and{' '}
                      </TermsListData>
                      <TermsListData>
                        make sure that your insurance will renew on time,
                        without a break in your cover.{' '}
                      </TermsListData>
                    </TermsListWrapper>
                  </>
                )}
                <TermsText>
                  Please note that if we are unable to update the card details,
                  we will not be able to automatically renew your policy
                </TermsText>
                <TermsTextHeader>
                  {' '}
                  Automatic renewal (Direct Debit policies)
                </TermsTextHeader>
                <TermsText>
                  If you pay monthly for your policy by Direct Debit, we will
                  renew your policy using the same bank details you provided for
                  your previous policy.
                </TermsText>
                <TermsTextHeader>What we need from you</TermsTextHeader>
                <TermsText>
                  You must tell us about any change in your circumstances (based
                  on the answers you gave to our questions) which happens before
                  or during the period of insurance.
                </TermsText>
                <TermsText>
                  It is important that you tell us about a change before it
                  happens or as soon as you know about it. When you tell us
                  about a change we will let you know if it will affect your
                  insurance.
                </TermsText>

                {section === 'home' ? (
                  <TermsText>
                    We may need you to provide us with documents as proof of
                    information you have provided. This may include proof of
                    ownership of various items. If we do ask you for proof, your
                    policy will be invalidated if you do not provide it to us in
                    the time frame that we specify.
                  </TermsText>
                ) : (
                  <>
                    <TermsText>
                      We may need you to provide us with documents as proof of
                      information you have provided.
                      <br />
                      This may include:
                    </TermsText>

                    <TermsListWrapper>
                      <TermsListData>
                        proof of previous insurance, e.g. no-claim discount
                      </TermsListData>
                      <TermsListData>
                        copies of your driving licence
                      </TermsListData>
                      <TermsListData>your vehicle V5 document</TermsListData>
                    </TermsListWrapper>
                  </>
                )}
                <TermsText>
                  We rely on the information you give us when deciding whether
                  or not to offer you an insurance quotation. You must take
                  reasonable care to provide full and accurate answers to the
                  questions we ask when you take out, make changes to and renew
                  your policy. If you do not give us full and accurate
                  information, we or your insurer may cancel your policy (or
                  policies), refuse to pay any claim, pay only part of a claim,
                  alter the extent of your cover or treat your policy as though
                  it never existed. Also, your details may be added to the
                  national Insurance Fraud Register.
                </TermsText>
              </>
            ) : (
              <>
                <TermsTextHeader>Automatic renewal</TermsTextHeader>
                <TermsText>
                  If we can offer you renewal terms, we will attempt to renew
                  your policy automatically.
                </TermsText>
                <TermsText>
                  You can opt out of automatic renewal at any time during your
                  policy with us. This can be done online, by using the contact
                  us link or via live chat or, you can call or email us. You can
                  also use the same contact methods to opt into automatic
                  renewal.
                </TermsText>
                <TermsTextHeader>
                  Automatic renewal (paid in full policies)
                </TermsTextHeader>
                <TermsText>
                  If you have paid for your policy in full we will try and renew
                  your policy using the last payment card details that you used
                  with us.
                </TermsText>
                <TermsText>
                  About one month before automatically renewing a policy, we
                  will pre-authorise your previous payment card with £2. This
                  does not transfer any money to us, but temporarily reduces the
                  money available to spend on your card. After a few days
                  (depending on your bank) this reserved amount is released and
                  is available again on your card.
                </TermsText>
                <TermsText>
                  If we find that your card details have changed, we will ask
                  our payment service provider to update the card details they
                  hold.
                </TermsText>
                <TermsText>
                  These are standard procedures in the insurance and financial
                  services industry. We do this to:
                </TermsText>
                <TermsListWrapper>
                  <TermsListData>
                    check that your method of payment is still valid;
                  </TermsListData>
                  <TermsListData>
                    give us time to contact you if there is a problem; and
                  </TermsListData>
                  <TermsListData>
                    make sure that your insurance will renew on time, without a
                    break in your cover.
                  </TermsListData>
                </TermsListWrapper>
                <TermsText>
                  Please note that if we are unable to update the card details,
                  we will not be able to automatically renew your policy
                </TermsText>
                <TermsTextHeader>
                  Automatic renewal (Direct Debit policies)
                </TermsTextHeader>
                <TermsText>
                  If you pay monthly for your policy by Direct Debit, we will
                  renew your policy using the same bank details you provided for
                  your previous policy.
                </TermsText>
                <TermsTextHeader>What we need from you</TermsTextHeader>
                <TermsText>
                  You must tell us about any change in your circumstances (based
                  on the answers you gave to our questions) which happens before
                  or during the period of insurance.
                </TermsText>
                <TermsText>
                  It is important that you tell us about a change before it
                  happens or as soon as you know about it. When you tell us
                  about a change we will let you know if it will affect your
                  insurance.
                </TermsText>
                <TermsText>
                  You are legally required to have your pet microchipped. We may
                  request confirmation of your pet's microchip number. If we do
                  ask you, your policy will be invalidated if you do not provide
                  it to us in the time frame that we specify.
                </TermsText>
                <TermsText>
                  We rely on the information you give us when deciding whether
                  or not to offer you an insurance quotation. You must take
                  reasonable care to provide full and accurate answers to the
                  questions we ask when you take out, make changes to and renew
                  your policy. If you do not give us full and accurate
                  information, we or your insurer may cancel your policy (or
                  policies), refuse to pay any claim, pay only part of a claim,
                  alter the extent of your cover or treat your policy as though
                  it never existed. Also, your details may be added to the
                  national Insurance Fraud Register.
                </TermsText>
              </>
            )}
          </>
        ),
      },
      {
        heading: 'No-claim discount',
        body: (
          <>
            <SectionTextWrapper>
              <TermsTextHeader>Proof of no-claim discount</TermsTextHeader>
              <TermsText>What proof do you need?</TermsText>
              <TermsText>
                If we ask you to provide evidence of your no-claim discount our
                Insurers will only accept:
              </TermsText>
              <TermsListWrapper>
                <TermsListData>
                  the renewal notice or letter from your last insurer confirming
                  your no-claim bonus or discount. We will not accept a
                  certificate of insurance, a statement of fact or a new
                  business schedule.
                </TermsListData>
                <TermsListData>
                  no-claim discount earned on a private motor policy in your own
                  name
                </TermsListData>
                <TermsListData>
                  no-claim discount proof from UK insurers, written in English
                </TermsListData>
              </TermsListWrapper>
              <TermsText>The proof of no-claim discount:</TermsText>
              <TermsListWrapper>
                <TermsListData>
                  must be on the insurer's headed paper
                </TermsListData>
                <TermsListData>
                  must show the no-claim discount in years (not a period of
                  cover)
                </TermsListData>
                <TermsListData>
                  must show the date the policy ended or was due to be renewed
                  (which must be less than 2 years ago) and the registration of
                  the vehicle.
                </TermsListData>
              </TermsListWrapper>
              <TermsText>
                You must have had continuous insurance, (unless you've
                registered your vehicle off the road (Statutory Off Road
                Notification/SORN) or it's in the process of being transferred
                to a new keeper by the DVLA).
              </TermsText>
              <TermsText>
                If there has been a gap since your previous motor insurance
                ended, we will need a covering letter giving the reason for the
                break in cover.
              </TermsText>
              <TermsText>
                We will not accept the same no-claim discount on more than one
                policy at the same time.
              </TermsText>
              <TermsText>
                If you have any difficulty in getting proof of your no-claim
                discount, please call us on{' '}
                <TermsTelephoneLink
                  href={`tel:${formatPhoneNumber(
                    productInfo[section].phoneNumber
                  )}`}
                >
                  {productInfo[section].phoneNumber}
                </TermsTelephoneLink>
              </TermsText>
              <TermsTextHeader>
                No-claim discount earned on company cars
              </TermsTextHeader>
              <TermsText>
                Our Insurers do not accept no-claim discount earned from driving
                a company car.
              </TermsText>
              <TermsTextHeader>
                What happens if I don't provide proof of no-claim discount
              </TermsTextHeader>
              <TermsText>
                If we do not receive proof which matches the no-claim discount
                you have told us about within 21 days from the date you buy the
                policy, we have the right to take either of the following
                actions based on the individual insurer rules which apply to
                your policy.
              </TermsText>
              <TermsListWrapper>
                <TermsListData>
                  Automatically take any extra premium, based on a lower
                  no-claim discount, from the payment card you used to buy the
                  policy.
                </TermsListData>
                <TermsListData>
                  Cancel your policy. If we cancel your policy because we did
                  not receive your proof of no-claim discount, you may have to
                  pay a cancellation fee.
                </TermsListData>
              </TermsListWrapper>
              <TermsTextHeader>
                What happens to my no-claim discount after a claim?
              </TermsTextHeader>
              <TermsText>
                Your no-claim discount will increase when your policy renews as
                long as you have not made a claim during the policy year. If you
                have made a claim your discount will reduce as shown in the
                table below.
              </TermsText>
            </SectionTextWrapper>
            <NoClaimsDiscountTable data={noClaimsDiscountTableData} />

            <SectionTextWrapper>
              <TermsText hasPurpleText hasMarginTop>
                Windscreen and glass damage claims will not affect your no-claim
                discount.
              </TermsText>
            </SectionTextWrapper>

            <SectionTextWrapper>
              <TermsTextHeader>
                What if I've protected my no-claim discount?
              </TermsTextHeader>
              <TermsText>
                You can also choose to protect your no-claim discount. This
                means that your no-claim discount will not be reduced if you
                make a claim.
              </TermsText>
              <TermsText>
                If you make no more than two fault claims in any three-year
                period, your no-claim discount will not be change at renewal (it
                will stay at the same level).
              </TermsText>
              <TermsText>
                If you make three of more fault claims in any three-year period,
                at the next renewal date we will reduce your no-claim discount
                to:
              </TermsText>
              <TermsListWrapper>
                <TermsListData>
                  two years' no-claim discount (if you have made three claims);
                  or
                </TermsListData>
                <TermsListData>
                  zero (if you have made more than three claims)
                </TermsListData>
              </TermsListWrapper>
            </SectionTextWrapper>
          </>
        ),
      },
      {
        heading: 'Staff remuneration',
        body: (
          <TermsText>
            Our employees are not paid a sales commission or bonus payment, but
            instead receive a basic salary for selling and administering
            insurance policies.
          </TermsText>
        ),
      },
      {
        heading: 'Website content and use',
        body: (
          <>
            <TermsText>
              The information, services and products on our website{' '}
              <TermsInternalLink to="/">
                www.policyexpert.co.uk
              </TermsInternalLink>{' '}
              are intended for people who live in the UK, the Channel Islands
              and the Isle of Man only. They are not meant for people living
              anywhere else.
            </TermsText>
            <TermsText>
              Our website is designed to be accessed through its main product
              home pages and other pages, as we decide. If you access the site
              through individual pages direct, you may not see important
              information which you will need to fully understand the products
              and services we are offering.
            </TermsText>
            <TermsText>
              Before buying any product or service, we will refer you to certain
              site pages which contain details of the relevant product or
              service and important legal or regulatory information, such as the
              terms and conditions of your purchase. It is your responsibility
              to read these pages.
            </TermsText>
          </>
        ),
      },
      {
        heading: 'Online safety',
        body: (
          <>
            <TermsText>
              If you've registered with us online, please keep your log-in
              details and password safe to prevent anyone else from accessing
              your Policy Expert account. You're responsible for all activities
              that happen in connection with your Policy Expert account, so it's
              important you contact us straight away if you suspect someone has
              accessed your account.
            </TermsText>
            <TermsText>
              We can't guarantee that any emails you send us are secure. So you
              must not send us confidential information by email, such as your
              credit-card or debit-card information.
            </TermsText>
          </>
        ),
      },
      {
        heading: 'Intellectual property',
        body: (
          <>
            <TermsText>
              All copyright, trademarks and other intellectual property rights
              in all material or content in any documents we provide or which
              are part of our website belong to us or other companies who we
              have licensed to use it. You are allowed to use this material only
              if we give you permission to do so. You agree that the material
              and content in any documents we provide and on our website are
              only for your personal use. We do not allow you to use the
              material and content for any other purpose. You agree not to (and
              agree not to help anyone else to) copy, reproduce, transmit,
              publish, display, distribute, commercially exploit or create other
              documents using our material and content. Our website and any
              documents we provide, whether in hard (printed) or soft
              (electronic) copy, contain trademarks which belong to QMetric
              Group Limited. You do not have any rights relating to any of the
              trademarks.
            </TermsText>
          </>
        ),
      },
      {
        heading: 'Excluding liability',
        body: (
          <>
            <TermsText>
              We have taken all reasonable steps to make sure the information we
              provide on our website or elsewhere is accurate and complete.
              However, we do not guarantee that this is the case. Except as set
              out in these terms and conditions, all guarantees, conditions and
              other terms implied by law are, as far as this is allowed by law,
              excluded from your contract with us.
            </TermsText>
            <TermsText>
              We accept no liability for any losses or damages (whether direct,
              indirect, special or otherwise) arising out of mistakes in (or
              details that are missing from) the information we provide on our
              website or elsewhere. We also do not guarantee that our website
              and services will never be interrupted. We will not be liable for
              any losses or damages which arise as a result of these being
              unavailable. Also, we are not liable for losses or damages which
              arise out of changes unauthorised third parties make to our
              website.
            </TermsText>
            <TermsText>
              We aim to correct any mistakes as soon as possible. However, there
              may sometimes be an error in the price or type of product or
              service shown. If this happens we have the right to cancel any
              contract we have entered into with you and we will offer you a
              full refund.
            </TermsText>
            <TermsText>
              When you access and use our website you do so at your own risk. We
              do not guarantee that using our website or downloading any
              material from it will not cause damage to any property (this
              includes loss of data or computer virus).
            </TermsText>
            <TermsText>
              Nothing in these terms and conditions will exclude, limit or
              restrict our duties and liabilities to you under the Financial
              Services and Markets Act 2000, or any conduct of business rules
              which we must keep to. Also, nothing in these terms and conditions
              will exclude liability for death or personal injury, or for fraud.
            </TermsText>
            <TermsText>
              Our website contains links to other websites which are hosted and
              maintained by third parties. We have no control over the content
              or security of any such site. If you use these links or access the
              other websites, you do so at your own risk, and we make no
              guarantees regarding the content of those websites. We are not
              liable for any loss or damage which arises as a result of using
              any other website.
            </TermsText>
          </>
        ),
      },
      {
        heading: 'Jurisdiction and governing law',
        body: (
          <>
            <TermsText>
              These terms and conditions are governed by the law of England and
              Wales and are subject to the jurisdiction of the courts of England
              and Wales.
            </TermsText>
          </>
        ),
      },
      {
        heading: 'Refer a friend',
        body: (
          <>
            <TermsText>
              To qualify for the £50 Amazon or Caffè Nero voucher, the referrer
              (you) must be a live Policy Expert customer. Your referred friend
              or relative must purchase an insurance policy from Policy Expert.
              They must purchase their policy directly from Policy Expert,
              either on policyexpert.co.uk, or over the phone. Vouchers are
              subject to a 90-day validation period, starting from the policy
              start date. You will not be eligible for a voucher if the referred
              party’s policy is cancelled within 90 days of the policy start
              date. Vouchers will not be payable if the referred party currently
              holds or has previously held a policy with Policy Expert. The
              voucher will be sent to you within 21 days of the validation
              period lapsing. This offer cannot be used in conjunction with any
              other offer or promotion.
            </TermsText>
          </>
        ),
      },
    ],
  };

  return (
    <div>
      <ImpactButtonsV2HeaderBanner
        backgroundColor="bg-pxCharcoal"
        heroBannerData={heroBannerData}
        shapePosition={'bottom-left-claims-header-V2'}
        section={section}
        styling={`m-auto px-24px ${
          section ? 'md:h-347px lg:h-613px' : 'md:h-676px lg:h-743px'
        } lg:p-0`}
      >
        <ProductButtonsBanner
          productSelectionData={
            isPetChangesEnabled
              ? renderAllProductSelection
              : renderTwoProductSelection
          }
          toggleSection={handlePathUpdate}
          section={section}
        />
      </ImpactButtonsV2HeaderBanner>

      {section && (
        <div className="mb-35-minus md:mb-80-minus bg-pxGrey ">
          <SlantedBackground
            backgroundColor={'bg-pxGrey'}
            margin="mb-1-minus"
            slantDirection={'top-left'}
            height
          />
          <ImpactV2TermsBlock data={termsBlockData} section={section} />
        </div>
      )}
    </div>
  );
};
export default TermsAndConditionsPage;

export const Head: HeadFC = () => (
  <>
    <title>Terms and conditions | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
