import React, { FC } from 'react';

import { ButtonsContainer, NoButton, YesButton } from './YesNoButtons.style';

type Props = {
  yesStep: () => void;
  noStep: () => void;
};

const YesNoButtons: FC<Props> = ({ yesStep, noStep }) => (
  <ButtonsContainer>
    <YesButton onClick={yesStep}>Yes</YesButton>
    <NoButton onClick={noStep}>No</NoButton>
  </ButtonsContainer>
);

export default YesNoButtons;
