import React, { ReactNode, useContext } from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import Reviewcentre from '../../static/svgIcons/Reviewcentre.svg';
import Reviewsio from '../../static/svgIcons/Reviewsio.svg';
import TrustPilot from '../../static/svgIcons/TrustPilot.svg';
import FullScreenCarousel, {
  FullScreenCarouselDataType,
} from '../components/fullScreenCarousel/FullScreenCarousel';
import HeaderBanner from '../components/headerBanner/HeaderBanner';
import HeaderWithCarousel, {
  HeaderWithCarouselDataType,
} from '../components/headerWithCarousel/HeaderWithCarousel';
import HeaderWithSideCarousel, {
  HeaderWithSideCarouselDataType,
} from '../components/headerWithSideCarousel/HeaderWithSideCarousel';
import HeaderWithText from '../components/headerWithText/HeaderWithText';
import NineInsuranceReviews from '../components/insuranceReviews/NineInsuranceReviews';
import { ReviewsContext } from '../context/ReviewsProvider';
import useDisclaimer from '../hooks/useDisclaimer';
import ica2020 from '../images/ICA-2020_Winner-Badge_Personal-Ins-Broker_low-res.png';
import ctm2020 from '../images/logo_ctm_simples_awards_service_winner.png';
import mfc2021 from '../images/MFC-2021_Win-homeins2.jpg';
import HeaderImageBreakpoints from '../types/breakpoints';
import { ReviewsData } from '../types/reviews';

type CardsType = {
  content: string;
  img: ReactNode;
  imgName?: string;
};

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../images/reviews_sm.png`}
        alt={`We love reviews!`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../images/reviews_md.png`}
        alt={`We love reviews!`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../images/reviews_lg.png`}
      alt={`We love reviews!`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const loveReviews: ReviewsData = {
  headerText: 'We love reviews',
  content: [
    'We love reviews from people like you. Why? Because they help us be even better. And we seem to be doing what you want.',
    "We’ve been voted the UK's No. 1 home insurance provider on Reviewcentre.com for 10 years running.*",
    'Thanks to over 57,965 of you who have voted.*',
  ],
};

const headerWithCarouselData: HeaderWithCarouselDataType = {
  header: 'What people say',
  slidesPerView: 3,
  links: [
    {
      name: 'Review Centre',
      link: 'https://www.reviewcentre.com/reviews264344.html',
      target: '_blank',
    },
    {
      name: 'Trustpilot',
      link: 'https://uk.trustpilot.com/review/www.policyexpert.co.uk',
      target: '_blank',
    },
    {
      name: 'Reviews.io',
      link: 'https://www.reviews.io/company-reviews/store/gc-policyexpert',
      target: '_blank',
    },
  ],
};

const fullScreenCarouselData: FullScreenCarouselDataType = {
  header: 'How your reviews make a difference',
  cards: [
    {
      content: `Customers wanted peace of mind knowing they could contact our claims department at any time. So we ensured they could receive 24/7 home emergency contact for support in a crisis.`,
    },
    {
      content: `You wanted to get back into your home faster after a flooding event. So we introduced first responders with advanced drying equipment to get you in your home quicker than anyone else.`,
    },
    {
      content: `You wanted an easier claims process. So we’ve introduced an online claims portal that helps resolve simple claims quickly.`,
    },
    {
      content: `You wanted car repair work to be stress-free. No problem. We now have 220 recommended motor repairers nationwide so your car can be sorted without the hassle.`,
    },
  ],
};

const headerWithSideCarouselData: HeaderWithSideCarouselDataType = {
  header: 'And you like what we do...',
  cards: [
    {
      header: 'Home Insurance Provider of the Year',
      content: 'Moneyfacts Consumer Awards',
      imgName: mfc2021,
    },
    {
      header: 'Best Personal Insurance Broker',
      content: 'Insurance Choice Awards',
      imgName: ica2020,
    },
    {
      header: 'Best Home Insurance Service Provider',
      content: 'Compare the Market',
      imgName: ctm2020,
    },
  ],
};

const reviewsPageDisclaimer = (
  <>
    <br />
    *Policy Expert is rated the number 1 home insurance provider in the UK on
    independent review website – Reviewcentre.com. Based on 58,736 customer
    reviews and a 96.6% recommendation rating, June 2024.
  </>
);

const ReviewsPage: React.FC<PageProps> = () => {
  useDisclaimer(reviewsPageDisclaimer);
  const trustpilotReviews = useContext(ReviewsContext);
  const isHighTrustScore =
    parseFloat(trustpilotReviews.score?.trustScore) >= 4.8;

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const reviewsCardsData: CardsType[] = [
    {
      content: 'Voted by 57,965 customers',
      img: <Reviewcentre stroke="none" fill="black" />,
      imgName: 'Reviewcentre',
    },
    {
      content: trustpilotReviews.numberOfReviews
        ? `Voted by ${trustpilotReviews.numberOfReviews.total.toLocaleString()} customers`
        : '',
      img: <TrustPilot fill={isHighTrustScore ? '#00b67a' : '#dcdce6'} />,
      imgName: 'TrustPilot',
    },
    {
      content: 'Voted for by 19,270 customers',
      img: <Reviewsio stroke="none" fill="black" />,
      imgName: 'Reviewsio',
    },
  ];

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxPurple"
        headerImage={headerImage}
        styling="pt-30px px-23px md:pt-74px md:px-91px lg:pt-9 lg:px-6 lg:max-w-995"
      />
      <HeaderWithText data={loveReviews} cards={reviewsCardsData} />
      <HeaderWithCarousel
        data={headerWithCarouselData}
        cards={NineInsuranceReviews}
      />
      <FullScreenCarousel data={fullScreenCarouselData} />
      <HeaderWithSideCarousel data={headerWithSideCarouselData} />
    </>
  );
};

export default ReviewsPage;

export const Head: HeadFC = () => (
  <>
    <title>Customer reviews | Policy Expert</title>
    <meta
      name="description"
      content="With over 38,000 customer reviews, Policy Expert is rated Excellent on Trust Pilot. Read more about how customer reviews have helped shape the service you receive."
    />
    <html lang="en" />
  </>
);
