import { styled } from 'styled-components';

import Minus from '../../../static/svgIcons/Minus.svg';
import Plus from '../../../static/svgIcons/Plus.svg';
import TickClean from '../../../static/svgIcons/TickClean.svg';
import { colors, SvgColors, SvgIcon } from '../../styles/sharedStyled';

export const TickCleanSvg = styled(TickClean)`
  ${SvgColors};
`;

export const MinusSvg = styled(Minus)`
  ${SvgColors};
`;

export const PlusSvg = styled(Plus)`
  ${SvgIcon};
  fill: ${colors.pink2};
`;
