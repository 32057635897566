import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import useEnableImpactV2Update from '../../hooks/useEnableImpactV2Update';
import CtaBannerImpactV2 from '../ctaBanner/CtaBannerImpactV2';
import ShapeX, { ShapePosition } from '../ShapeX';
import SlantedBackground, {
  SlantDirection,
} from '../slantedBackground/SlantedBackground';
import TrustPilotHeader from '../trustPilotHeader/TrustPilotHeader';
import {
  HeaderBannerContainer,
  HeaderBannerInformationContainer,
  HeaderImageContainer,
} from './HeaderBannerV2.style';

type HeroBannerData = {
  title: React.JSX.Element;
  text: React.JSX.Element;
};

type HeaderBannerProps = {
  backgroundColor: string;
  heroBannerData: HeroBannerData;
  slantDirection: SlantDirection;
  headerImage: React.JSX.Element;
  shapePosition?: ShapePosition;
  isHomepage?: boolean;
  displayTrustpilot?: boolean;
};

const ImpactV2HeaderBanner = ({
  backgroundColor,
  slantDirection,
  headerImage,
  shapePosition,
  heroBannerData,
  isHomepage,
  displayTrustpilot = true,
}: HeaderBannerProps) => {
  const { isMobile, isTablet } = useBreakpoint();

  const v2Refresh = useEnableImpactV2Update();

  return (
    <SlantedBackground
      backgroundColor={backgroundColor}
      slantDirection={slantDirection}
      hideOverflow
    >
      <HeaderBannerContainer $isHomepage={isHomepage} $v2Refresh={v2Refresh}>
        <HeaderBannerInformationContainer
          $isHomepage={isHomepage}
          $v2Refresh={v2Refresh}
        >
          {heroBannerData.title}
          {heroBannerData.text}
          {displayTrustpilot &&
            ((!isTablet && !isMobile) || (isTablet && !isMobile)) && (
              <TrustPilotHeader isHomepage={isHomepage} />
            )}
        </HeaderBannerInformationContainer>
        {(!isHomepage ||
          (isHomepage &&
            ((!isTablet && !isMobile) || (isTablet && !isMobile)))) && (
          <HeaderImageContainer $v2Refresh={v2Refresh}>
            {headerImage}
          </HeaderImageContainer>
        )}
        {isMobile && (
          <>
            {isHomepage && <CtaBannerImpactV2 />}
            {displayTrustpilot && <TrustPilotHeader isHomepage={isHomepage} />}
          </>
        )}
      </HeaderBannerContainer>
      {shapePosition && <ShapeX position={shapePosition} />}
    </SlantedBackground>
  );
};
export default ImpactV2HeaderBanner;
