import { styled } from 'styled-components';

import AssistanceRefresh from '../../static/svgIcons/AssistanceRefresh.svg';
import Car from '../../static/svgIcons/Car.svg';
import CoinsRefresh from '../../static/svgIcons/CoinsRefresh.svg';
import Lock from '../../static/svgIcons/Lock.svg';
import Nurse from '../../static/svgIcons/Nurse.svg';
import OnlinePortal from '../../static/svgIcons/OnlinePortal.svg';
import RepairWarranty from '../../static/svgIcons/RepairWarranty.svg';
import Tick from '../../static/svgIcons/Tick.svg';
import TowTruck from '../../static/svgIcons/TowTruck.svg';
import Uk from '../../static/svgIcons/Uk.svg';
import { iconsBannerIconSize } from '../components/petIconsBanner/PetIconsBanner.style';
import {
  colors,
  ProductPageHeaderBannerText,
  SvgColors,
} from '../styles/sharedStyled';

export const AssistanceRefreshIcon = styled(AssistanceRefresh)`
  ${iconsBannerIconSize}
`;

export const RepairWarrantyIcon = styled(RepairWarranty)`
  ${iconsBannerIconSize}
`;

export const CoinsRefreshIcon = styled(CoinsRefresh)`
  ${iconsBannerIconSize}
`;

export const TowTruckIcon = styled(TowTruck)`
  ${iconsBannerIconSize}
`;

export const TickSvg = styled(Tick)`
  ${SvgColors};
`;

export const NurseSvg = styled(Nurse)`
  ${SvgColors};
`;

export const UkSvg = styled(Uk)`
  ${SvgColors};
`;

export const LockSvg = styled(Lock)`
  ${SvgColors};
`;

export const CarInsuranceHeaderText = styled(ProductPageHeaderBannerText)`
  @media (min-width: 768px) {
    max-width: 440px;
    width: 320px;
  }

  @media (min-width: 1024px) {
    max-width: 480px;
    width: 100%;
  }
`;

export const CarInsuranceTextBanner = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  line-height: normal;

  @media (min-width: 1024px) {
    font-size: 25px;
  }
`;

export const MotorSvg = styled(Car)`
  fill: ${colors.purple7};
  position: relative;
  height: 65px;
  width: 65px;
  top: 17px;

  @media (min-width: 768px) {
    height: 70px;
    width: 70px;
    top: 18px;
  }

  @media (min-width: 1024px) {
    height: 100px;
    width: 100px;
    top: 25px;
  }
`;

export const OnlinePortalSvg = styled(OnlinePortal)`
  fill: ${colors.purple7};
  height: 65px;
  width: 65px;

  @media (min-width: 768px) {
    height: 70px;
    width: 70px;
  }

  @media (min-width: 1024px) {
    height: 100px;
    width: 100px;
  }
`;
