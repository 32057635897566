import React from 'react';

interface JobCard {
  title: string;
  location: string;
  department: string;
  closing_date?: string;
  status: string;
}

function JobCard({
  title,
  location,
  department,
  closing_date,
  status,
}: JobCard) {
  const handleOnClick = () => {
    window.location.href = `/about-us/careers/job-details/?job=${encodeURI(
      title
    )}`;
  };

  return (
    <div style={{ filter: 'url(#round)' }} className={'mb-12'}>
      <div style={{ clipPath: 'polygon(0 0, 100% 0, 100% 86%, 0% 100%)' }}>
        <div className={'p-6 bg-white'}>
          <p className={'text-bodyCopy mb-3'}>
            <span className={'font-bold'}>Job Title:</span> {title}
          </p>
          <p className={'text-finerDetailsBodyCopy  mb-3'}>
            <span className={'font-bold'}>Location:</span> {location}
          </p>
          <p className={'text-finerDetailsBodyCopy  mb-3'}>
            <span className={'font-bold'}>Department:</span> {department}
          </p>
          <p className={'text-finerDetailsBodyCopy  mb-5'}>
            <span className={'font-bold'}>Closing date:</span> {closing_date}
          </p>
          <div className={'flex'}>
            <button
              onClick={handleOnClick}
              className={
                'btn-secondary md:text-gridButton font-camptonSemiBold flex items-center justify-center rounded-xl flex-grow max-w-155 md:max-w-305'
              }
            >
              <span className={'py-2 md:py-4'}>View job</span>
            </button>
          </div>
        </div>
        {status === 'Open' ? (
          <div className={'bg-pxMint p-6 mb-8'}>
            <p
              className={
                'text-finerDetailsBodyCopy md:text-bodyCopy  font-bold'
              }
            >
              {'We’re hiring!'}
            </p>
          </div>
        ) : (
          <></>
        )}
        {status === 'Closed' ? (
          <div className={'bg-pxTan px-6 pt-6 pb-12 mb-8'}>
            <p
              className={
                'text-finerDetailsBodyCopy md:text-bodyCopy mb-3 font-bold'
              }
            >
              Sorry, applications for this role are closed
            </p>
            <p className={'text-finerDetailsBodyCopy mb-5 max-w-lg'}>
              Please email us your CV at{' '}
              <a href="mailto:careers@policyexpert.co.uk">
                careers@policyexpert.co.uk
              </a>{' '}
              if you'd like to be considered for future roles.
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default JobCard;
