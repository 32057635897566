import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import {
  ImageContainer,
  PetBlueCrossBannerBackgroundContainer,
  PetBlueCrossBannerContainer,
  PetBlueCrossBannerTitle,
  PetBlueCrossContentContainer,
  PetBlueCrossContentText,
  PetBlueCrossContentTitle,
} from './PetBlueCrossBanner.style';

const blueCrossImage = (
  <ImageContainer>
    {' '}
    <StaticImage
      src="../../images/pet_blue_cross.png"
      alt="Picture of a dog and the Blue Cross logo"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  </ImageContainer>
);

const PetBlueCrossBanner = () => {
  return (
    <PetBlueCrossBannerBackgroundContainer>
      <PetBlueCrossBannerContainer>
        <PetBlueCrossBannerTitle>Proud to support</PetBlueCrossBannerTitle>
        <PetBlueCrossContentContainer>
          {blueCrossImage}
          <div>
            <PetBlueCrossContentTitle>
              We know your pet is a much-loved member of your family.
            </PetBlueCrossContentTitle>
            <PetBlueCrossContentText>
              That's why, as part of launching our pet insurance product, we've
              partnered with pet charity Blue Cross.
            </PetBlueCrossContentText>
            <PetBlueCrossContentText>
              For over 125 years, Blue Cross have played an essential role in
              supporting pets and their owners. Together, we're here to help you
              care for your pet.
            </PetBlueCrossContentText>
          </div>
        </PetBlueCrossContentContainer>
      </PetBlueCrossBannerContainer>
    </PetBlueCrossBannerBackgroundContainer>
  );
};

export default PetBlueCrossBanner;
