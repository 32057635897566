import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import SectionHeadingBanner, {
  SectionHeadingBannerDataType,
} from '../components/sectionHeadingBanner/SectionHeadingBanner';
import TwoColumnTextBanner, {
  TwoColumnTextBannerType,
} from '../components/twoColumnTextBanner/TwoColumnTextBanner';
import useDisclaimer from '../hooks/useDisclaimer';

const section172HeaderData: TwoColumnTextBannerType = {
  bgColor: 'bg-pxCharcoal',
  textColor: 'text-white',
  headerText: 'Section 172 (1) statement',
  subBody: (
    <div className="lg:mb-140px">
      <p className="text-bannerCopy md:text-sectionSubHeadingMobile">
        Section 172 of the Companies Act 2006 requires a director of a company
        to act in the way he or she considers, in good faith, would be most
        likely to promote the success of the company for the benefit of its
        members as a whole. In doing this, section 172 requires a director to
        have regard, amongst other matters, to the following:
      </p>

      <ul className="section172">
        <li className="text-bannerCopy md:text-sectionSubHeadingMobile">
          The likely consequences of any decision in the long term,
        </li>
        <li className="text-bannerCopy md:text-sectionSubHeadingMobile">
          The interests of the company's employees,
        </li>
        <li className="text-bannerCopy md:text-sectionSubHeadingMobile">
          The need to foster the company's business relationships with
          suppliers, customers and others,
        </li>
        <li className="text-bannerCopy md:text-sectionSubHeadingMobile">
          The impact of the company's operations on the community and the
          environment,
        </li>
        <li className="text-bannerCopy md:text-sectionSubHeadingMobile">
          The desirability of the company maintaining a reputation for high
          standards of business conduct, and
        </li>
        <li className="text-bannerCopy md:text-sectionSubHeadingMobile">
          The need to act fairly between members of the company.
        </li>
      </ul>

      <p className="text-bannerCopy md:text-sectionSubHeadingMobile">
        In discharging our section 172 duties we have regard to the factors set
        out above. We also have regard to other factors which we consider
        relevant to the decision being made. Those factors, for example, include
        the interests and views of our shareholders, other group companies,
        employees and other relevant stakeholders.
      </p>
    </div>
  ),
};

const sectionHeadingBannerData: SectionHeadingBannerDataType = {
  headerText: 'Decision making',
  subText:
    'The board remains mindful that its strategic decisions can have long term implications for the business and its stakeholders, and these implications are carefully assessed.',
};

const section172EmployeeData: TwoColumnTextBannerType = {
  bgColor: 'bg-pxGrey',
  textColor: 'text-black',
  headerText: 'Employee engagement',
  subText: [
    "The company's success depends heavily on the integrity, quality and effort of its staff at all levels. Accordingly, it is vital for the company to attract and retain staff of the highest quality in competitive labour markets for UK technology and financial services.",
    "The board and senior management are responsible for ensuring that the company's purpose, vision and values are effectively communicated to employees and that the company's activities reflect the culture we wish to instil in employees and drive appropriate behaviours. Employee engagement is achieved by communication at all levels including meetings, intranet and email on matters important to the company and the industry; employee feedback, including surveys and employee forums.",
  ],
};

const section172BusinessData: TwoColumnTextBannerType = {
  bgColor: 'bg-pxGrey',
  textColor: 'text-black',
  headerText: 'Business relationships',
  subText: [
    "The company's strategy prioritises organic growth by bringing new customers into the business and retaining them by providing an excellent service. To do this, it needs to develop and maintain a strong customer journey. It values all suppliers and has strong working relationships with key providers.",
  ],
};

const section172CommunityData: TwoColumnTextBannerType = {
  bgColor: 'bg-pxGrey',
  textColor: 'text-black',
  headerText: 'Community and the environment',
  subText: [
    'The company makes monetary contributions to charitable bodies and encourages staff to become involved in local fundraising and awareness events in their local community and where appropriate will sponsor staff actively taking part in these events.',
    'The company is reviewing what process changes can be made to its operation to reduce the impact on the environment.',
  ],
};

const section172BusinessConductData: TwoColumnTextBannerType = {
  bgColor: 'bg-pxGrey',
  textColor: 'text-black',
  headerText: 'Business conduct',
  subText: [
    'The company has long term shareholders who are members of senior management. This close involvement assists greatly in ensuring that their interests are not only aligned but also addressed in an effective manner.',
  ],
};

const Section172Page: React.FC<PageProps> = () => {
  useDisclaimer(null);

  return (
    <>
      <TwoColumnTextBanner data={section172HeaderData} section172 lastElement />
      <SectionHeadingBanner data={sectionHeadingBannerData} hideShape />
      <TwoColumnTextBanner
        data={section172EmployeeData}
        section172
        shape={false}
      />
      <TwoColumnTextBanner
        data={section172BusinessData}
        section172
        shape={false}
      />
      <TwoColumnTextBanner
        data={section172CommunityData}
        section172
        shape={false}
      />
      <TwoColumnTextBanner
        data={section172BusinessConductData}
        section172
        lastElement
        shape
      />
    </>
  );
};

export default Section172Page;

export const Head: HeadFC = () => (
  <>
    <title>Section 172 | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
