import React, { useContext } from 'react';

import TrustPilot from '../../../static/svgIcons/TrustPilot.svg';
import { ReviewsContext } from '../../context/ReviewsProvider';
import Carousel from '../carousel/Carousel';

type Props = {
  data?: {
    headerText?: string;
  };
  cards: any;
};

const ReviewCarousel = ({ cards, data }: Props) => {
  const trustpilotReviews = useContext(ReviewsContext);
  const isHighTrustScore =
    parseFloat(trustpilotReviews.score?.trustScore) >= 4.8;

  return (
    <div className="bg-pxMint review-carousel">
      {data && data.headerText ? (
        <div className="flex relative z-20">
          <div className="text-center md:text-left px-6 mx-auto max-w-1584 flex flex-col justify-center w-full">
            <h2 className="pb-18px md:pb-38px lg:pb-58px md:pt-10px">
              {data.headerText}
            </h2>
          </div>
        </div>
      ) : null}
      <Carousel
        pagination={{ arrows: true, color: 'pxWhite' }}
        slidePadding
        cards={cards}
        title=""
        offsetfirstslide
      />
      <div className="padding-x pt-32px pb-82px flex flex-col md:flex-row justify-center items-center">
        {trustpilotReviews.score && trustpilotReviews.numberOfReviews ? (
          <>
            <div className="md:mr-32px mr-0 ">
              <TrustPilot
                className="icons-primary5 z-50 flex"
                fill={isHighTrustScore ? '#00b67a' : '#dcdce6'}
              />
            </div>
            <p className="text-small text-center md:text-left mb-0">
              {`${
                trustpilotReviews.score.trustScore
              }/5 from over ${trustpilotReviews.numberOfReviews.total.toLocaleString()} reviews. 96.7% of users recommend us.`}
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ReviewCarousel;
