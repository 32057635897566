import React from 'react';

import { ReviewData } from '../../types/reviews';
import ReviewCard from '../reviewCard/ReviewCard';

const reviews: ReviewData[] = [
  {
    title: 'Brilliant customer service',
    author: 'Mr P Windows',
    date: '20/03/2024',
    review:
      'Good value policy with great customer service. Unfortunately had a claim last year when a lorry damaged our property, but Policy Expert quickly sorted it out.',
  },
  {
    title: 'No problems with claims',
    author: 'Joan Malyon',
    date: '20/03/2024',
    review: 'Great company - paid out my carpet claim with no problem.',
  },
  {
    title: 'Efficient claims process',
    author: 'Eileen B',
    date: '03/04/2024',
    review:
      'I made one claim for damage to a shower room a few years back which was dealt with efficiently. This year I auto renewed as I could not find a more competitive deal elsewhere. When I have contacted the staff they have always been very knowledgeable, helpful and polite.',
  },
  {
    title: 'Outstanding service',
    author: 'Ruth Lambert',
    date: '13/03/2024',
    review:
      'We recently had a burst in the boiler in the loft affecting a bedroom and lounge. The response from Policy Expert was outstanding... Excellent service from start to finish. Highly recommended.',
  },
];

const InsuranceReviews: JSX.Element[] = [];
reviews.map((item) => {
  InsuranceReviews.push(
    <ReviewCard key={`carousel-${item.review}`} data={item} />
  );
});

export default InsuranceReviews;
