import React, { FC } from 'react';
import { useWizard } from 'react-use-wizard';

import { pushHecTriageEventToDataLayer } from '../../services/windowService';
import {
  EmergencyDefinition,
  Reason,
  ReasonsList,
} from './IsItAnEmergency.style';
import YesNoButtons from './YesNoButtons';

const IsItAnEmergency: FC = () => {
  const { goToStep } = useWizard();

  const yesStep = () => {
    pushHecTriageEventToDataLayer('click_emergency_yes');
    goToStep(4);
  };
  const noStep = () => {
    pushHecTriageEventToDataLayer('click_emergency_no');
    goToStep(3);
  };

  return (
    <>
      <EmergencyDefinition data-testid="emergency-definition">
        An Emergency is a sudden and unexpected incident in your home, which
        means that immediate action is needed to:
      </EmergencyDefinition>
      <ReasonsList>
        <Reason>Make your home safe or secure;</Reason>
        <Reason>
          Prevent damage or further damage to your home or its contents;
        </Reason>
        <Reason>
          Provide your home with essential services such as heating, lighting or
          water; or
        </Reason>
        <Reason>
          Relieve any difficulty or risk to your or someone else's health.
        </Reason>
      </ReasonsList>
      <YesNoButtons yesStep={yesStep} noStep={noStep} />
    </>
  );
};

export default IsItAnEmergency;
