import React, { useEffect, useState } from 'react';

import { HeadFC, PageProps } from 'gatsby';

import Shape from '../../components/Shape';
import ShapeX from '../../components/ShapeX';
import useDisclaimer from '../../hooks/useDisclaimer';
import useQueryParams from '../../hooks/useQueryParams';

type UserData = {
  firstname: string;
  lastname: string;
  email: string;
};

const RafDashboardPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const [gotDetails, setGotDetails] = useState(false);
  const [userData, setUserData] = useState<UserData>();
  const { firstname: nameF, surname: nameL, email: emailAd } = useQueryParams();

  useEffect(() => {
    setUserData({ firstname: nameF, lastname: nameL, email: emailAd });
  }, []);

  useEffect(() => {
    if (
      userData?.firstname != null &&
      userData?.lastname != null &&
      userData?.email != null
    ) {
      setGotDetails(true);
    }
  }, [userData]);

  useEffect(() => {
    if (gotDetails) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://tag.mention-me.com/api/v2/dashboard/mm3debec24/${userData?.email}?firstname=${userData?.firstname}&surname=${userData?.lastname}&situation=dashboard&locale=en_GB`;
      document.body.appendChild(s);
    }
  }, [gotDetails]);
  return (
    <>
      <div className="mmContainer bg-pxGrey py-82px overflow-hidden relative">
        <h2 className="lg:pr-32px text-center pb-82px px-32px">
          Introduce us to your friends
        </h2>
        <Shape position="left-far" />
        {gotDetails ? (
          <div id="mmWrapper" className="relative z-40"></div>
        ) : null}
        <ShapeX position="bottom-right" />
      </div>
    </>
  );
};

export default RafDashboardPage;

export const Head: HeadFC = () => (
  <>
    <title>Refer a friend | Policy Expert</title>
    <meta
      name="description"
      content="Introduce us to your friends! If they join us, we’ll give you £50 Caffè Nero or Amazon.co.uk vouchers."
    />
    <html lang="en" />
    <meta name="robots" content="noindex"></meta>
  </>
);
