import React from 'react';

import { AboutUsEmployee } from '../imageWithCarouselReveal/ImageWithCarouselReveal';
import {
  ExecCardContainer,
  ExecImage,
  ExecName,
  ExecRole,
} from './ExecCard.style';

const ExecCard = ({ person }: { person: AboutUsEmployee }) => {
  return (
    <ExecCardContainer data-testid="executive-card">
      <ExecImage
        src={person.image}
        alt={'picture of :' + person.name + person.role}
      />
      <ExecName>{person.name}</ExecName>
      <ExecRole>{person.role}</ExecRole>
    </ExecCardContainer>
  );
};

export default ExecCard;
