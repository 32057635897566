import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import './carousel.css';

import React, { ReactNode, useState } from 'react';

import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// install Swiper modules
SwiperCore.use([Autoplay]);

type CarouselType = {
  title?: string;
  cards: any;
  slidesPerView?: number | 'auto';
  slidePadding?: boolean;
  pagination?: {
    color: string;
    arrows: boolean;
  };
  pag?: boolean;
  offsetfirstslide?: boolean;
  opacityOffset?: number;
  autoPlay?: boolean;
  paddingTop?: boolean;
  slidesOffsetBefore?: number;
  slidesOffsetAfter?: number;
  petMargins?: boolean;
};

const Carousel = ({
  title,
  cards,
  slidesPerView,
  pagination,
  pag = true,
  opacityOffset = 3,
  autoPlay,
  paddingTop = true,
  slidesOffsetAfter,
  slidesOffsetBefore,
  petMargins,
}: CarouselType) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const paginationStats = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class=" ' + className + '"></span>';
    },
  };

  const handleChangeSlide = (event: any) => {
    setCurrentSlide(event.realIndex);
  };

  return (
    <div
      style={{ marginRight: 'calc(-50vw + 50%)' }}
      className={`relative z-50 overflow-visible swiper-container mx-auto`}
    >
      {title && <h4 className="text-forestgreen px-4">{title}</h4>}

      <Swiper
        wrapperClass={`w-screen-xl ${
          paddingTop && 'pt-32px'
        } flex flex-row relative bg-blue swiper-wrapper`}
        navigation={pagination?.arrows ? pagination.arrows : false}
        autoplay={autoPlay ? { delay: 2000 } : false}
        spaceBetween={10}
        uniqueNavElements
        breakpoints={{
          768: { spaceBetween: 20, centeredSlides: false },
        }}
        slidesPerView={slidesPerView ? slidesPerView : 'auto'}
        pagination={pag ? paginationStats : false}
        scrollbar={false}
        centeredSlides
        slideToClickedSlide
        onSlideChange={handleChangeSlide}
        slidesOffsetBefore={slidesOffsetBefore ? slidesOffsetBefore : 0}
        slidesOffsetAfter={slidesOffsetAfter ? slidesOffsetAfter : 0}
      >
        {cards.map((item: ReactNode, index: number) => {
          return (
            <SwiperSlide
              style={{ width: 'auto', maxWidth: '100%', height: 'auto' }}
              className={`${petMargins ? 'mb-40px' : 'mb-14 mt-4'} ${
                index === currentSlide + opacityOffset ? 'opacity-50' : ''
              }`}
              key={index}
            >
              {item}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Carousel;
