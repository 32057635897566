import React, { useState } from 'react';

import CookieAccordion from '../accordion/CookieAccordion';
import { TableData, TableRowData } from './CookieTable';

type Props = {
  table: TableData;
  tableHeadings: string[];
  openTable?: string;
  setOpenTable: (tableId: string) => void;
};

const MobileCookieTable = ({
  table,
  tableHeadings,
  openTable,
  setOpenTable,
}: Props) => {
  const [openIndex, setOpenIndex] = useState<number | undefined>(undefined);
  const handleOnClick = (index?: number) => {
    setOpenIndex(openIndex === index ? undefined : index);
    setOpenTable(table.tableId);
  };

  return (
    <div className="mb-60px">
      <h3 className="mb-25px pb-0 text-fontSize-25 text-pxPurple font-camptonMedium font-medium lg:text-fontSize-30 ">
        {table.tableTitle}
      </h3>
      <div data-testid={`${table.tableId}`}>
        {table.tableRows.map((item: TableRowData, index: number) => {
          const title = (
            <div>
              <p className="mb-20px text-fontSize-15 font-camptonSemiBold">
                {tableHeadings[0]}:{' '}
                <span className="text-pxPurple font-camptonBook">
                  {item.name}
                </span>
              </p>
              <p className="mb-20px text-fontSize-15 font-camptonSemiBold">
                {tableHeadings[1]}:{' '}
                <span className="text-pxPurple font-camptonBook">
                  {item.setBy}
                </span>
              </p>
            </div>
          );
          const body = (
            <p className="mb-20px text-fontSize-15 font-camptonSemiBold">
              {tableHeadings[2]}:{' '}
              <span className="font-camptonBook">{item.purpose}</span>
            </p>
          );
          return (
            <CookieAccordion
              key={item.name}
              title={title}
              body={body}
              first={index == 0}
              last={index === table.tableRows.length - 1}
              handleOnClick={handleOnClick}
              index={index}
              openIndex={openIndex}
              tableId={table.tableId}
              openTable={openTable}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MobileCookieTable;
