import { styled } from 'styled-components';

import { ProductBannerTitle as PetProductBannerTitle } from '../petProductBanner/PetProductBanner.style';

export const MainContainer = styled.div<{
  $backgroundColor: string;
}>`
  position: relative;
  padding: 60px 0 95px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || ''};

  @media (min-width: 768px) {
    padding: 100px 0 180px;
  }

  @media (min-width: 1024px) {
    padding: 130px 0 210px;
  }
`;

export const ProductBannerTitle = styled(PetProductBannerTitle)`
  margin-bottom: 50px;

  @media (min-width: 768px) {
    margin-bottom: 60px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 90px;
  }
`;
