import React, { FC, useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';

import { PreviousQuestionsType } from './HecData';
import {
  BackButton,
  CloseButton,
  ModalNavBarContainer,
} from './ModalNavBar.style';

type Props = {
  hideModal: () => void;
  previousQuestions: Array<PreviousQuestionsType>;
  setPreviousQuestions: (
    previousQuestions: Array<PreviousQuestionsType>
  ) => void;
};

const ModalNavBar: FC<Props> = ({
  hideModal,
  previousQuestions,
  setPreviousQuestions,
}) => {
  const { goToStep, activeStep } = useWizard();
  const [currentStep, setCurrentStep] = useState(0);
  const [previousSteps, setPreviousSteps] = useState([0]);

  useEffect(() => {
    if (activeStep > currentStep) {
      setPreviousSteps([...previousSteps, currentStep]);
    }
    setCurrentStep(activeStep);
  }, [activeStep]);

  const goBack = () => {
    if (previousQuestions.length) {
      previousQuestions.pop();
      setPreviousQuestions(previousQuestions);
    }
    goToStep(previousSteps[previousSteps.length - 1]);
    previousSteps.pop();
    setPreviousSteps(previousSteps);
  };

  const hideBackButton = activeStep === 0;

  return (
    <ModalNavBarContainer
      $hideBackButton={hideBackButton}
      data-testid="modal-nav-bar"
    >
      {!hideBackButton && (
        <button
          aria-label="Back"
          data-testid="triage-back-button"
          onClick={goBack}
        >
          <BackButton />
        </button>
      )}
      <button
        aria-label="Close"
        data-testid="triage-close-button"
        onClick={hideModal}
      >
        <CloseButton />
      </button>
    </ModalNavBarContainer>
  );
};

export default ModalNavBar;
