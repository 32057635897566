import React, { Fragment, useContext } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import TrustPilotNoStars from '../../../static/svgIcons/TrustPilotNoStars.svg';
import { ReviewsContext } from '../../context/ReviewsProvider';
import Carousel from '../carousel/Carousel';

type Props = {
  data?: {
    headerText: string;
  };
  cards: JSX.Element[];
  isRenewal?: boolean;
};

export const getOpacityOffset = (isMobile: boolean, isTablet: boolean) => {
  if (isMobile) {
    return 1;
  }
  if (isTablet) {
    return 2;
  }
  return 3;
};

const ReviewSection = ({ cards, data, isRenewal }: Props) => {
  const { isMobile, isTablet, isTabletLarge, isDesktop } = useBreakpoint();

  const trustpilotReviews = useContext(ReviewsContext);
  const isHighTrustScore =
    parseFloat(trustpilotReviews.score?.trustScore) >= 4.8;

  const opacityOffset = getOpacityOffset(isMobile, isTablet);

  return (
    <div
      className={`bg-pxMint relative mx-auto  ${
        data
          ? 'pt-60px md:pt-100px lg:pt-130px md:mt-480-minus lg:mt-0'
          : 'md:pl-40px mt-50-minus z-100'
      } ${isDesktop || isTabletLarge ? ' w-full lg:pl-24px max-w-1390' : ''}`}
    >
      {data && (
        <h2
          className={`max-w-320 mx-auto md:mx-0 md:max-w-full md:w-339 lg:w-1/2 px-24px md:px-0 text-center text-fontSize-35 md:text-fontSize-50 lg:text-fontSize-80 md:text-left mb-50px lg:mb-90px lg:pr-40px`}
        >
          {data.headerText}
        </h2>
      )}
      {isMobile || isTablet ? (
        <div className="max-w-1390 mx-auto">
          <Carousel
            pagination={{ arrows: false, color: 'pxWhite' }}
            slidePadding
            cards={cards}
            title=""
            offsetfirstslide
            paddingTop={false}
            opacityOffset={opacityOffset}
          />
        </div>
      ) : (
        <div className="flex lg:flex-row">
          <div className="grid grid-cols-2 gap-20px z-100">
            {cards.map((card: JSX.Element, index: number) => {
              return index <= 3 ? (
                <Fragment key={card.key}>{card}</Fragment>
              ) : null;
            })}
          </div>
          {isDesktop || isTabletLarge ? (
            <div className="relative">
              <StaticImage
                src="../../images/girl_on_laptop.png"
                alt="People love what we do..."
                className={`${
                  isRenewal ? 'mt-595-minus' : 'mt-515-minus'
                } w-1027 h-1283 z-75 right-780-minus`}
                layout="fullWidth"
                loading="eager"
                placeholder="blurred"
                style={{ position: 'absolute' }}
              />
            </div>
          ) : null}
        </div>
      )}
      <div
        className={`pt-30px lg:pt-40px flex flex-col md:flex-row md:justify-center md:items-center lg:justify-start pl-24px md:pl-0 pb-100px lg:pl-40px md:pb-0`}
      >
        {trustpilotReviews.score && trustpilotReviews.numberOfReviews ? (
          <>
            <div className={`md:mr-20px mr-0`}>
              <TrustPilotNoStars
                className="icons-primary5 z-50 flex w-111 h-28 mb-15px md:mb-6px"
                fill={isHighTrustScore ? '#00b67a' : '#dcdce6'}
              />
            </div>
            <p
              className={`text-fontSize-16 md:max-w-max md:text-left mb-0 max-w-190`}
            >
              {`${
                trustpilotReviews.score.trustScore
              }/5 from over ${trustpilotReviews.numberOfReviews.total.toLocaleString()} reviews. 96.7% of users recommend us.`}
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ReviewSection;
