import React, { useContext } from 'react';

import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';

import HeartOne from '../../../static/svgIcons/HeartOne.svg';
import Screen from '../../../static/svgIcons/Screen.svg';
import HeaderLandingPage, {
  HeaderLandingPageDataType,
} from '../../components/headerLandingPage/HeaderLandingPage';
import { ImageWithTextDataType } from '../../components/imageWithText/ImageWithText';
import ImageWithTextLandingPage from '../../components/imageWithTextLandingPage/ImageWithTextLandingPage';
import ProductTable, {
  ProductTableDataType,
} from '../../components/productTable/ProductTable';
import TwoColumnIconBanner, {
  TwoColumnIconBannerDataType,
} from '../../components/twoColumnIconBanner/TwoColumnIconBanner';
import TwoColumnIconText, {
  TwoColumnIconTextDataType,
} from '../../components/twoColumnIconText/TwoColumnIconText';
import config from '../../config';
import { ReviewsContext } from '../../context/ReviewsProvider';
import useDisclaimer from '../../hooks/useDisclaimer';
import { ScreenSvg, TickSvg, TimerSpeedIcon } from '../../styles/sharedStyled';

const headerLandingPageData: HeaderLandingPageDataType = {
  header: 'I GAINED',
  subHeader: '79 POUNDS',
  subHeaderText: 'OVERNIGHT*',
  buttons: [
    {
      label: 'Get a quote',
      url: config.HOME_URL,
      primary: true,
    },
  ],
};

const twoColumnIconBannerData: TwoColumnIconBannerDataType = {
  bgColor: 'bg-pxWhite',
  headerText: 'Believe it. And here’s more about us...',
  headerSubtext: '',
  icons: [
    {
      icon: <TimerSpeedIcon />,
      text: 'New and improved customer journey - get a quote in under 3 mins',
    },
    {
      icon: <TickSvg />,
      text: 'Choose from 3 levels of cover to suit your needs',
    },
    {
      icon: <ScreenSvg />,
      text: '96.7% of our customers are happy to recommend us**',
    },
  ],
  buttons: [
    {
      label: 'Get a quote',
      url: config.HOME_URL,
      primary: true,
    },
  ],
};

const productTableData: ProductTableDataType = {
  bgColor: 'bg-pxGrey',
  textColor: 'text-black',
  headerText: 'Insurance for people like you',
  isMaxWidth: false,
  columns: 3,
  subText:
    'We put you first: Starting with finding you the right cover for your needs, then getting you the best price.',
  subHeaderText: 'What’s covered?',
  productRows: [
    {
      label: 'Buildings insurance sum covered',
      included: ['£600,000', '£1,000,000', '£1,000,000'],
    },
    {
      label: 'Contents insurance sum covered',
      included: ['£60,000', '£75,000', '£100,000'],
    },
    {
      label: 'New for old cover as standard',
      included: ['✔', '✔', '✔'],
    },
    {
      label: 'Alternative accommodation (buildings)',
      included: ['Up to £75,000', 'Up to £100,000', 'Up to £150,000'],
    },
    {
      label: 'Alternative accommodation (contents)',
      included: ['£10,000', '£10,000', '£25,000'],
    },
    {
      label: 'Credit cards in and away from home',
      included: ['£1,000', '£1,000', '£1,000'],
    },
    {
      label: 'Personal money in the home',
      included: ['£250', '£250', '£1,000'],
    },
    {
      label: 'Deep freezer contents',
      included: ['£300', '£500', '£750'],
    },
    {
      label: 'Property owners liability',
      included: ['£2,000,000', '£2,000,000', '£5,000,000'],
    },
    {
      label: 'Replacement locks or keys',
      included: ['£300', '£500', '£1,000'],
    },
    {
      label: 'Legal Expenses Select',
      included: ['✖', '✔', '✔'],
    },
    {
      label: 'Home Emergency Select',
      included: ['✖', '✔', '✔'],
    },
  ],
  buttons: [
    {
      label: 'Get a quote',
      url: config.HOME_URL,
      primary: true,
    },
  ],
};

const gained79PageDisclaimer = (
  <>
    <br />
    *Savings with Policy Expert: number of home and car insurance customers who
    reported savings when they switched their policies with Policy Expert, based
    on 1091 survey responses carried out by QMetric Group Limited in July 2022.
    <br />
    **Policy Expert is rated the number 1 home insurance provider in the UK on
    independent review website – Reviewcentre.com. Based on 49,000 customer
    reviews and a 96.7% recommendation rating, July 2022.
  </>
);

const Gained79Page: React.FC<PageProps> = () => {
  useDisclaimer(gained79PageDisclaimer);
  const trustpilotReviews = useContext(ReviewsContext);

  const Gained79ImageQuery = useStaticQuery(graphql`
    query Gained79Images {
      LandingPageFemale: imageSharp(
        original: { src: { regex: "/pe_landingpage_femaleimage/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const twoColumnIconTextData: TwoColumnIconTextDataType = {
    icons: [
      {
        icon: <HeartOne stroke="white" />,
        text: 'We’re rated the UK’s no.1 home insurance provider**',
        link: 'Reviewcentre.com',
        linkUrl: 'https://www.reviewcentre.com/reviews264344.html',
      },
      {
        icon: <Screen stroke="white" />,
        text: `Rated ${
          trustpilotReviews.score?.trustScore
        } out of 5 from over ${trustpilotReviews.numberOfReviews?.total.toLocaleString()} customer reviews`,
        link: 'Trustpilot.com',
        linkUrl: 'https://uk.trustpilot.com/review/www.policyexpert.co.uk',
      },
    ],
  };

  const imageWithTextData: ImageWithTextDataType = {
    bgColor: 'bg-pxMint',
    blendsWithTop: true,
    imageLeft: true,
    imgName: 'pe_landingpage_femaleimage.png',
    imageFile: Gained79ImageQuery.LandingPageFemale.gatsbyImageData,
    copyBlock: [
      {
        headerText: 'You first',
        subText: [
          <p key={1}>At Policy Expert we put you, the customer, first.</p>,
          <div key={2}>
            <p>
              Don’t just take our word for it,{' '}
              <span className="font-semibold">
                we’re trusted by over 1 million customers!
              </span>{' '}
            </p>
          </div>,
        ],
      },
    ],
  };

  return (
    <>
      <HeaderLandingPage data={headerLandingPageData} />
      <TwoColumnIconBanner shape={false} data={twoColumnIconBannerData} />
      {trustpilotReviews.score && trustpilotReviews.numberOfReviews ? (
        <TwoColumnIconText data={twoColumnIconTextData} />
      ) : null}
      <ProductTable data={productTableData} landingPage bottomPadding home />
      <ImageWithTextLandingPage
        data={imageWithTextData}
        topCoverColor="pxGrey"
        topCoverColorMobile="pxMint"
      />
    </>
  );
};

export default Gained79Page;

export const Head: HeadFC = () => (
  <>
    <title>Home insurance for people like you</title>
    <meta
      name="description"
      content="Award winning home insurance from Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
