import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonSemiBold,
} from '../../styles/sharedStyled';

const tableHeaderStyle = `
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  font-size: 15px;
  color: ${colors.white};
  background-color: ${colors.charcoal};
  padding: 20px;
`;

const tableCellStyle = `
  padding: 20px;
  background-color: ${colors.white};
  text-align: left;
  vertical-align: top;

  font-family: ${fontFamilyCamptonBook};
  font-weight: 400;
  font-size: 15px;
`;

const tableBorderRadius = '20px';

export const TableStyle = styled.table`
  & > thead {
    ${tableHeaderStyle};

    & > tr {
      &:first-child {
        & > td:first-child {
          border-top-left-radius: ${tableBorderRadius};
        }

        & > td:last-child {
          border-top-right-radius: ${tableBorderRadius};
        }
      }

      & > td {
        padding: 20px;

        &:not(:first-child) {
          border-left: 1px solid ${colors.white};
        }
      }
    }
  }

  & > tbody {
    & > tr {
      &:last-child {
        & > td:first-child {
          border-bottom-left-radius: ${tableBorderRadius};
        }

        & > td:last-child {
          border-bottom-right-radius: ${tableBorderRadius};
        }
      }

      & > td {
        ${tableCellStyle};

        border-bottom: 1px solid ${colors.gray3};

        &:not(:first-child) {
          border-left: 1px solid ${colors.gray3};
        }
      }
    }
  }
`;

const mobileTableBorderRadius = '10px';

export const MobileTableWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  & > *:first-child {
    border-top-left-radius: ${mobileTableBorderRadius};
    border-top-right-radius: ${mobileTableBorderRadius};
  }

  & > *:last-child {
    border-bottom-left-radius: ${mobileTableBorderRadius};
    border-bottom-right-radius: ${mobileTableBorderRadius};
  }
`;

export const MobileTableHeader = styled.div`
  ${tableHeaderStyle};
`;

export const MobileTableCell = styled.div`
  ${tableCellStyle};
  padding: 15px 20px;
`;
