import React from 'react';

import { Link } from 'gatsby';

import Carousel from '../carousel/Carousel';

type LinksType = {
  name: string;
  link: string;
  target?: string;
};

export type HeaderWithCarouselDataType = {
  header: string;
  slidesPerView: number;
  links: LinksType[];
};

type Props = {
  data: HeaderWithCarouselDataType;
  cards: any;
};

const HeaderWithCarousel = ({ data, cards }: Props) => (
  <div className="bg-pxMint py-82px md:py-130px ">
    <div className="padding-x flex">
      <h2 className="mb-82px md:max-w-1/2">{data.header}</h2>
    </div>

    <div>
      <Carousel
        cards={cards}
        pagination={{ color: 'white', arrows: true }}
        offsetfirstslide
      />

      <div className="hidden md:flex justify-around px-130px padding-x">
        {data.links.map((link) => (
          <p
            key={link.name}
            className="text-finerDetailsBodyCopy md:text-bodyCopy"
          >
            See more on {` `}
            <strong>
              <a
                className="text-pxPink font-semibold"
                href={link.link}
                // @ts-ignore
                target={link.target ? link.target : '_self'}
              >
                {link.name}
              </a>
            </strong>
          </p>
        ))}
      </div>
      <div className="flex md:hidden justify-around px-82px padding-x">
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy">
          See more on{' '}
          <Link className="text-pxPink font-semibold" to="/reviews">
            Trustpilot
          </Link>
        </p>
      </div>
    </div>
  </div>
);

export default HeaderWithCarousel;
