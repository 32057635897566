import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Ipid, { IpidProps } from '../../components/ipid/Ipid';
import { IpidContent } from '../../components/ipid/Ipid.style';
import IpidHeader from '../../components/ipidHeader/IpidHeader';
import {
  IpidHeaderTitle,
  IpidSubHeaderContainer,
  IpidSubHeaderText,
} from '../../components/ipidHeader/IpidHeader.style';
import Shape from '../../components/Shape';
import config from '../../config';
import useDisclaimer from '../../hooks/useDisclaimer';
import { MotorDocsContainer, SemiBoldSpan } from '../../styles/sharedStyled';

const headerData = {
  header: <IpidHeaderTitle>Excess protection</IpidHeaderTitle>,
  subHeader: (
    <IpidSubHeaderContainer>
      <IpidSubHeaderText>
        The products outlined in this booklet only apply to you if shown in your
        Insurance Welcome Letter and Optional Extras Schedule.
      </IpidSubHeaderText>
      <IpidSubHeaderText>
        We advise you read this booklet carefully for details of the cover
        provided for each of the optional extras you have purchased.{' '}
        <SemiBoldSpan>
          View the document based on when you bought your policy.
        </SemiBoldSpan>
      </IpidSubHeaderText>
    </IpidSubHeaderContainer>
  ),
};

const carData: IpidProps = {
  sectionTitle: 'Car insurance',
  data: [
    {
      heading: 'If your purchase date is on or after the 1st Jul 2022',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/vehicle_excess_ipid_0622.pdf`,
          linkText: 'View document',
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 1st Nov 2021 and 30th Jun 2022',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/excessipid_pc_nov21.pdf`,
          linkText: 'View document',
        },
      ],
    },
    {
      heading:
        'If your cover start date is between 1st Jul 2020 and 31st Oct 2021',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/excessipid_pc.pdf`,
          linkText: 'View document',
        },
      ],
    },
    {
      heading: 'Between 1st Nov 2019 and 30th Jun 2020',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/excessipid_011119.pdf`,
          linkText: 'View document',
        },
      ],
    },
  ],
};

const vanData: IpidProps = {
  sectionTitle: 'Van insurance',
  data: [
    {
      heading: 'If your purchase date is on or after the 1st Jul 2022',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/vehicle_excess_ipid_0622.pdf`,
          linkText: 'View document',
        },
      ],
    },
    {
      heading:
        'If your purchase date is between 1st Nov 2021 and 30th Jun 2022',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/excessipid_cv_nov21.pdf`,
          linkText: 'View document',
        },
      ],
    },
    {
      heading:
        'If your cover start date is between 1st Jul 2020 and 31st Oct 2021',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/excessipid_van.pdf`,
          linkText: 'View document',
        },
      ],
    },
    {
      heading: 'Between 1st Nov 2019 and 30th Jun 2020',
      links: [
        {
          url: `${config.DOCUMENTS_URL}/motor/excessipid_van_011119.pdf`,
          linkText: 'View document',
        },
      ],
    },
  ],
};

const CarExcessProtectionIpidPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isDesktop } = useBreakpoint();

  return (
    <MotorDocsContainer>
      <IpidHeader {...headerData} />
      <IpidContent>
        <Ipid {...carData} />
        <Ipid {...vanData} />
      </IpidContent>
      {isDesktop && <Shape position="right-policy-books" />}
      {isDesktop && <Shape position="left-policy-books" />}
    </MotorDocsContainer>
  );
};

export default CarExcessProtectionIpidPage;

export const Head: HeadFC = () => (
  <>
    <title>Car insurance documents | Policy Expert</title>
    <meta
      name="description"
      content="Car insurance policy documents. Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
