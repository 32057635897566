import React, { useEffect, useState } from 'react';
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';

import dayjs from 'dayjs';
import { HeadFC } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';

import AmazonCardTablet from '../../../static/svgIcons/AmazonCardTablet.svg';
import ShapeX from '../../components/ShapeX';
import useDisclaimer from '../../hooks/useDisclaimer';
import useQueryParams from '../../hooks/useQueryParams';
import AmazonCardDesktopImage from '../../images/amazon_card_desktop.png';
import {
  AmazonCardDesktop,
  AmazonCardMobile,
  ConfirmationContainer,
  ConfirmationMessage,
  ConfirmationTitle,
  ConfirmationTitleContainer,
  DescriptionContainer,
  DescriptionText,
  DescriptionTitle,
  EmailInput,
  FormContainer,
  MainBackground,
  MainContainer,
  MainContentContainer,
  MonthSelector,
  PrizeBannerContainer,
  PrizeBannerDesktopContainer,
  PrizeDrawContainer,
  PrizeInformationContainer,
  PrizeInformationNote,
  PrizeInformationText,
  PrizeInformationTitle,
  SelectAndSubmitContainer,
  ShowMoreArrow,
  ShowMoreContainer,
  ShowMoreText,
  SubmitButton,
  Subtitle,
  TickCircleIcon,
  Title,
  TitlesContainer,
  VoucherSpan,
} from './renewal-date.style';

const prizeTitle = 'Enter our £1,000 prize draw!';

const prizeDescription = (
  <PrizeInformationText data-testid="prize-information-text">
    For a limited time, when you submit your email and your pet's insurance
    renewal month, we'll enter you into a prize draw to win{' '}
    <VoucherSpan>£1,000 worth of Amazon vouchers</VoucherSpan>. Good luck! Terms
    and conditions apply.*
  </PrizeInformationText>
);

const disclaimerData = (
  <>
    <br />
    *Terms and conditions: By inputting your email and selecting your pet
    insurance’s renewal month in the landing page, your details will be entered
    into a prize draw to win £1,000 worth of Amazon vouchers. In doing so, you
    consent to Policy Expert contacting you to discuss your pet insurance prior
    to its renewal date. If you have multiple pets with different renewal dates,
    please submit just one renewal month. The prizes are non-transferable and
    there is no cash alternative in lieu of the prizes or any part of the
    prizes. Only one entry per household will be accepted. Employees of Policy
    Expert and their relatives, or anyone involved with the promotion, are
    excluded from entering the prize draw. Entry to the prize draw confirms that
    you have accepted these rules. Prize draw closes: 01/11/24.
    <br />
    Amazon.co.uk is not a sponsor of this promotion. Amazon.co.uk Gift Cards
    ("GCs") may be redeemed on the Amazon.co.uk website towards the purchase of
    eligible products listed in our online catalogue and sold by Amazon.co.uk or
    any other seller selling through Amazon.co.uk. GCs cannot be reloaded,
    resold, transferred for value, redeemed for cash or applied to any other
    account. Amazon.co.uk is not responsible if a GC is lost, stolen, destroyed
    or used without permission. See www.amazon.co.uk/gc-legal for complete terms
    and conditions. GCs are issued by Amazon EU S.à r.l. All Amazon ®, ™ & © are
    IP of Amazon.com, Inc. or its affiliates.
  </>
);

type FormValues = {
  email: string;
  fhins_xsell_pet_month: string;
};

const PetRenewalDatePage: React.FC = () => {
  const { register, handleSubmit, control, reset } =
    useFormContext<FormValues>();
  const [submitted, isSubmitted] = useState(false);
  const [openShowMore, setOpenShowMore] = useState(false);
  const { isMobile, isTablet } = useBreakpoint();
  const { origin } = useQueryParams();
  const today = dayjs.tz(Date.now(), 'Europe/London').format('DD/MM/YYYY');
  const unixTimestamp = dayjs().unix();

  const watchEmailInput = useWatch({ name: 'email', control });
  const watchRenewalMonth = useWatch({
    name: 'fhins_xsell_pet_month',
    control,
  });

  const isValidEmail = watchEmailInput && watchEmailInput.match(/.+@.+\..+/);
  const isSubmitDisabled = !isValidEmail || !watchRenewalMonth;

  useDisclaimer(disclaimerData);

  const submitEvent: SubmitHandler<FormValues> = (data) => {
    const eventName = 'xsell_pet_dates';
    const eventProperties = {
      UID: uuidv4(),
      xsell_pet_month: data.fhins_xsell_pet_month,
      xsell_pet_collected_date: today,
      xsell_pet_collected_date_unix_timestamp: unixTimestamp,
      origin: origin === 'email' ? 'EMAIL' : 'LAUNCH-HEADER',
      status: 'CREATED',
    };

    if (origin === 'email') {
      window.dataLayer.push({
        namespace: 'exponea',
        event_name: eventName,
        event_properties: eventProperties,
      });
    } else {
      window.exponea?.identify({
        email_id: data.email.toLowerCase().trim(),
      });

      window.exponea?.track(eventName, eventProperties);
    }

    isSubmitted(true);
  };

  useEffect(() => {
    reset({ email: '', fhins_xsell_pet_month: '' });
  }, [isSubmitted]);

  return (
    <>
      {isTablet && (
        <PrizeDrawContainer>
          {!isMobile && <AmazonCardTablet data-testid="amazon-card-tablet" />}
          <PrizeBannerContainer data-testid="prize-banner-container">
            <PrizeInformationContainer>
              <PrizeInformationTitle>{prizeTitle}</PrizeInformationTitle>
              <PrizeInformationNote>(Limited time only)</PrizeInformationNote>
            </PrizeInformationContainer>
            {openShowMore && prizeDescription}
            {isMobile && <AmazonCardMobile data-testid="amazon-card-mobile" />}
            <ShowMoreContainer onClick={() => setOpenShowMore(!openShowMore)}>
              <ShowMoreText>Show {openShowMore ? 'less' : 'more'}</ShowMoreText>
              <ShowMoreArrow $isShowMoreOpen={openShowMore} />
            </ShowMoreContainer>
          </PrizeBannerContainer>
        </PrizeDrawContainer>
      )}
      <MainBackground>
        <MainContainer>
          <MainContentContainer>
            <TitlesContainer>
              <Title>Coming soon: Pet insurance</Title>
              <Subtitle>
                Cat and dog lovers of Britain rejoice: this summer we'll be
                launching Policy Expert pet insurance!
              </Subtitle>
            </TitlesContainer>
            {isMobile && (
              <StaticImage
                src="../../images/pet_insurance_hero.png"
                alt="Pet insurance for people like you"
                layout="fullWidth"
                loading="eager"
                placeholder="blurred"
                style={{
                  height: '300px',
                  width: '254px',
                  margin: '-10px auto 0',
                }}
              />
            )}
            {!isMobile && !isTablet && (
              <PrizeBannerDesktopContainer data-testid="desktop-prize-container">
                <AmazonCardDesktop
                  src={AmazonCardDesktopImage}
                  alt="Amazon gift card"
                />
                <PrizeInformationContainer>
                  <PrizeInformationTitle>{prizeTitle}</PrizeInformationTitle>
                  {prizeDescription}
                </PrizeInformationContainer>
              </PrizeBannerDesktopContainer>
            )}
            <DescriptionContainer>
              <DescriptionTitle>
                Interested in our pet insurance?
              </DescriptionTitle>
              <DescriptionText>
                We've not launched yet, but let us know when your renewal date
                is and we'll drop you an email about our cover at the right
                time.
              </DescriptionText>
            </DescriptionContainer>
            {submitted ? (
              <ConfirmationContainer>
                <ConfirmationTitleContainer>
                  <TickCircleIcon data-testid="tick-circle-icon" />
                  <ConfirmationTitle>Thank you</ConfirmationTitle>
                </ConfirmationTitleContainer>
                <ConfirmationMessage>
                  Your details have been saved
                </ConfirmationMessage>
              </ConfirmationContainer>
            ) : (
              <FormContainer onSubmit={handleSubmit(submitEvent)}>
                <EmailInput
                  type="email"
                  {...register('email', { required: true })}
                  id="email"
                  placeholder="Your email address"
                />
                <SelectAndSubmitContainer>
                  <MonthSelector
                    {...register('fhins_xsell_pet_month', { required: true })}
                    id="fhins_xsell_pet_month"
                    $hasValue={!!watchRenewalMonth}
                  >
                    <option disabled selected hidden value="">
                      Renewal month
                    </option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </MonthSelector>
                  <SubmitButton type="submit" disabled={isSubmitDisabled}>
                    Submit
                  </SubmitButton>
                </SelectAndSubmitContainer>
              </FormContainer>
            )}
          </MainContentContainer>
          {!isMobile && (
            <StaticImage
              src="../../images/pet_insurance_hero.png"
              alt="Pet insurance for people like you"
              layout="fullWidth"
              loading="eager"
              placeholder="blurred"
              style={{
                height: isTablet ? '320px' : '435px',
                width: isTablet ? '271px' : '368px',
                minWidth: isTablet ? '271px' : '368px',
              }}
            />
          )}
        </MainContainer>
        <ShapeX position="bottom-left-pet-renewals" />
      </MainBackground>
    </>
  );
};

export default PetRenewalDatePage;

export const Head: HeadFC = () => (
  <>
    <title>Pet insurance for people like you</title>
    <meta
      name="description"
      content="Pet insurance from Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
