import React from 'react';

import Carousel from '../carousel/Carousel';
import ProductCard from '../productCard/ProductCard';

type NoClaimDiscountRowType = {
  label: string;
  included: string[];
  hasLightGreyBackground?: boolean;
  hasBlackText?: boolean;
};

export type NoClaimHeaderRowType = {
  label: string;
  included: string;
  hasBlackText?: boolean;
};

export type NoClaimDiscountDataType = {
  type: 'car';
  columns: number;
  subColumns: number;
  productRows: NoClaimDiscountRowType[];
  headerRow: NoClaimHeaderRowType;
};

type NoClaimDiscountTableProps = {
  data: NoClaimDiscountDataType;
};

const NoClaimsDiscountTable = ({ data }: NoClaimDiscountTableProps) => {
  const cards: JSX.Element[] = [];

  data.productRows[0].included.forEach((item, index: number) => {
    cards.push(
      <ProductCard
        key={`carousel_${item}_${index}`}
        data={data}
        column={index}
        heading={data.headerRow}
      />
    );
  });

  return (
    <div className="lg:mx-30px md:flex md:justify-center">
      <div className="relative z-20">
        <table
          className={
            'w-full max-w-297 md:max-w-770 lg:min-w-1000 hidden md:block lg:mb-40px lg:mt-20px bg-white rounded-xl relative overflow-hidden'
          }
        >
          <thead className="flex flex-col">
            <tr
              className={`grid grid-cols-1n${data.subColumns} md:items-center md:h-80px`}
            >
              <td className="col-span-1 border-pxGrey border-r md:font-camptonLight md:font-normal md:h-80px md:flex md:justify-end md:items-center ">
                {data.headerRow.label}
              </td>
              <td className="col-span-3 text-center md:h-80px md:flex md:justify-center md:items-center">
                {data.headerRow.included}
              </td>
            </tr>
          </thead>
          <tbody className="flex flex-col">
            {data.productRows.map((item, index) => (
              <tr
                key={`${item.label}_${index}`}
                className={`grid grid-cols-1n${data.subColumns}`}
              >
                <td
                  className={`border-pxGrey border items-center flex flex-col justify-center ${
                    item.label.length === 0 && 'bg-pxMenu'
                  }`}
                >
                  <p className="body-copy mb-0 px-20px text-finerDetailsBodyCopy w-full">
                    {item.label}
                  </p>
                </td>

                {item.included.map((includes, index) => (
                  <td
                    key={`${includes}_${index}`}
                    className={`pt-32px md:py-20px md:text-center border-pxGrey border ${
                      item.hasLightGreyBackground && 'bg-pxMenu'
                    } `}
                  >
                    <p
                      className={`text-center font-camptonLight text-fontSize-15 md:font-camptonBook ${
                        item.hasBlackText && 'text-pxBlack'
                      } text-pxPurple md:mb-0`}
                    >
                      {includes}
                    </p>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="md:hidden">
        <Carousel
          slidesPerView={1.2}
          offsetfirstslide
          cards={cards}
          paddingTop={false}
        />
      </div>
    </div>
  );
};

export default NoClaimsDiscountTable;
