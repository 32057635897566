import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import PolicyExpertBronze from '../../../static/svgIcons/PolicyExpertBronze.svg';
import PolicyExpertGold from '../../../static/svgIcons/PolicyExpertGold.svg';
import PolicyExpertSilver from '../../../static/svgIcons/PolicyExpertSilver.svg';
import TickAngles from '../../../static/svgIcons/TickAngles.svg';
import XTable from '../../../static/svgIcons/XTable.svg';
import { ButtonType } from '../../types/button';
import Carousel from '../carousel/Carousel';
import Icon from '../icons/Icon';
import ProductCard from '../productCard/ImpactProductCard';
import Shape from '../Shape';

type ProductRowType = {
  label: string;
  subLabel?: string;
  included: string[];
  includedHTML?: any;
  link?: string;
};

type RightPanelInfoType = {
  infoTitle: string;
  info: string;
};

type TableTitlesType = {
  tableTitle: string;
};

export type ProductTableDataType = {
  bgColor: string;
  textColor?: string;
  headerText: string;
  subText?: string;
  subHeaderText?: string;
  isMaxWidth?: boolean;
  rightPanelInfo?: RightPanelInfoType[];
  productRows: ProductRowType[];
  buttons?: ButtonType[];
  columns: number;
  tableTitles?: TableTitlesType[];
  firstRowDark?: boolean;
  firstColLarge?: boolean;
};

interface ProductTableProps {
  data: ProductTableDataType;
  home?: boolean;
  car?: boolean;
  bottomPadding: boolean;
  tableLeft?: boolean;
}

const ProductTable = ({
  data,
  bottomPadding,
  tableLeft,
}: ProductTableProps) => {
  const { isTablet, isTabletLarge, isDesktop } = useBreakpoint();
  const showShape = (isTabletLarge && !isTablet) || isDesktop;

  const icons = [
    <PolicyExpertBronze
      key="policy-expert-bronze"
      data-testid="policy-expert-bronze"
      className="mx-auto"
    />,
    <PolicyExpertSilver
      key="policy-expert-silver"
      data-testid="policy-expert-silver"
      className="mx-auto"
    />,
    data.columns === 3 ? (
      <PolicyExpertGold
        key="policy-expert-gold"
        data-testid="policy-expert-gold"
        className="mx-auto"
      />
    ) : null,
  ];

  const cards: JSX.Element[] = [];

  data.productRows[0].included.forEach((item, index) => {
    cards.push(
      <ProductCard
        key={`carousel-${item}`}
        data={data}
        img={icons[index]}
        column={index}
      />
    );
  });

  return (
    <div
      className={`${data.bgColor} ${
        bottomPadding ? 'md:py-82px md:py-130px' : ''
      }  z-101 relative overflow-hidden`}
    >
      {showShape && <Shape position="left-product-table" />}

      <div className="padding-x relative z-20 text-center">
        <div className="lg:w-3/4 mx-auto">
          <h2 className="mx-auto pb-18px md:pb-38px lg:pb-90px text-fontSize-35 md:text-fontSize-50 lg:text-fontSize-80">
            {data.headerText}
          </h2>
          {data.subText && (
            <p className="py-32px md:pt-82px md:pb-32px">{data.subText}</p>
          )}
          {data.subHeaderText && (
            <h3 className="pb-32px md:pb-82px">{data.subHeaderText}</h3>
          )}
        </div>

        <table
          className={`${data.isMaxWidth ? 'w-full' : 'max-w-992'} hidden ${
            tableLeft ? '' : 'mx-auto'
          } lg:block bg-transparent rounded-radius-20 relative overflow-hidden`}
        >
          <thead
            className="flex flex-col"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <tr className={`grid grid-cols-1n${data.columns}`}>
              <td className="w-full border-pxGrey border-r border-b opacity-0" />
              {icons.map((item, index) => {
                if (!item) {
                  return null;
                }
                return (
                  <td
                    key={item.key}
                    className={`w-full bg-pxWhite py-35px border-b border-pxGrey border-r border-l ${
                      index === 0 && 'rounded-tl-radius-20'
                    }`}
                  >
                    <Icon className="mx-auto h-60px w-77">{icons[index]}</Icon>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody
            className="flex flex-col "
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {data.productRows.map((item: ProductRowType, index: number) => (
              <tr
                key={item.label}
                className={`grid grid-cols-1n${data.columns}`}
              >
                <td
                  className={`bg-pxWhite border-pxGrey border p-30px ${
                    index === 0 && 'rounded-tl-radius-20'
                  }`}
                >
                  <p className="mb-0 text-fontSize-15 text-left">
                    {item.label}
                  </p>
                  {item.subLabel && (
                    <p className="text-fontSize-13 mb-0 text-left">
                      {item.subLabel}
                    </p>
                  )}
                </td>

                {item.included.map((includes: string) => (
                  <td
                    key={includes}
                    className="bg-pxWhite py-30px border-pxGrey border flex justify-center items-center	"
                  >
                    {includes === '✔' || includes === '✖' ? (
                      includes === '✔' ? (
                        <Icon type="secondary" size={3} className="mx-auto">
                          <TickAngles />
                        </Icon>
                      ) : (
                        <Icon type="secondary" size={3} className="mx-auto">
                          <XTable />
                        </Icon>
                      )
                    ) : (
                      <p className="text-center text-fontSize-15 mb-0 text-pxPurple">
                        {includes}
                      </p>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="lg:hidden mx-0 md:ml-40px" data-testid="product-carousel">
        <Carousel slidesPerView={1.2} offsetfirstslide cards={cards} />
      </div>
    </div>
  );
};
export default ProductTable;
