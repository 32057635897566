import React, { ReactNode } from 'react';

import CarSmall from '../../../static/svgIcons/CarSmall.svg';
import DogCat from '../../../static/svgIcons/DogCat.svg';
import House from '../../../static/svgIcons/House.svg';
import PageInspect from '../../../static/svgIcons/PageInspect.svg';
import Person from '../../../static/svgIcons/Person.svg';
import WarningTriangle from '../../../static/svgIcons/WarningTriangle.svg';
import config from '../../config';

export type UrlLink = {
  label: string;
  url: string;
  isExternal?: boolean;
  icon?: ReactNode;
};

export const headerLinks: UrlLink[][] = [
  [
    { label: 'Home', url: '/home-insurance/' },
    { label: 'Car', url: '/car-insurance/' },
    { label: 'Pet', url: '/pet-insurance/' },
    {
      label: 'Help Centre',
      url: config.HELP_CENTRE_URL,
      isExternal: true,
    },
  ],
  [
    { label: 'Claims', url: '/claims/' },
    { label: 'Contact us', url: '/contact/' },
  ],
];

export const bottomNavLinks: UrlLink[][] = [
  [
    {
      label: 'Home',
      url: '/home-insurance/',
      icon: <House stroke="#6214bE" className="h-15px mb-1" alt="House icon" />,
    },
    {
      label: 'Car',
      url: '/car-insurance/',
      icon: <CarSmall className="mb-1" alt="Car icon" />,
    },
    {
      label: 'Pet',
      url: '/pet-insurance/',
      icon: <DogCat className="mb-1" alt="Pet icon" />,
    },
  ],
  [
    {
      label: 'Help Centre',
      url: config.HELP_CENTRE_URL,
      icon: (
        <PageInspect className="mb-1" alt="Paper icon with magnifying glass" />
      ),
      isExternal: true,
    },
  ],
  [
    {
      label: 'Claims',
      url: '/claims/',
      icon: (
        <WarningTriangle
          className="h-15px mb-1"
          alt="Triangle with exclamation mark"
        />
      ),
    },
    {
      label: 'Log in',
      url: '/login/',
      icon: <Person className="mb-1" alt="Person icon" />,
    },
  ],
];

export const bottomMenuLinks: UrlLink[][] = [
  [
    { label: 'Home insurance', url: '/home-insurance/' },
    { label: 'Car insurance', url: '/car-insurance/' },
    { label: 'Pet insurance', url: '/pet-insurance/' },
  ],
  [
    {
      label: 'Help centre',
      url: config.HELP_CENTRE_URL,
      isExternal: true,
    },
    { label: 'Claims', url: '/claims/' },
    { label: 'Contact us', url: '/contact/' },
  ],
  [{ label: 'Your online account', url: '/login/' }],
];

export const footerLinks: UrlLink[][] = [
  [
    { label: 'Reviews', url: '/reviews' },
    { label: 'About', url: '/about-us' },
    {
      label: 'In the know',
      url: 'https://blog.policyexpert.co.uk/',
      isExternal: true,
    },
    { label: 'Awards', url: '/about-us/awards' },
    { label: 'Careers', url: '/about-us/careers' },
  ],
  [
    { label: 'T&Cs', url: '/terms-and-conditions' },
    { label: 'Cookie policy', url: '/cookie-policy' },
    { label: 'Modern slavery', url: '/modern-slavery-statement' },
    { label: 'Privacy', url: '/privacy-policy' },
    { label: 'Site Map', url: '/site-map' },
    { label: 'How to complain', url: '/how-to-complain' },
    { label: 'Gender pay gap', url: '/gender-pay-gap' },
    { label: 'Section 172', url: '/section172' },
    { label: 'Media', url: '/about-us/media' },
    {
      label: 'Vulnerability disclosure policy',
      url: '/vulnerability-disclosure',
    },
  ],
];

export const formatUrl = ({
  url,
  isPetChangesEnabled,
}: {
  url: string;
  isPetChangesEnabled: boolean;
}): string => {
  if (url.includes('/pet-insurance/') && !isPetChangesEnabled) {
    return `${url}renewal-date/`;
  }
  return url;
};
