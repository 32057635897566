import React, { useEffect, useState } from 'react';

import { HeadFC } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import Facebook from '../../../../static/svgIcons/Facebook.svg';
import LinkedIn from '../../../../static/svgIcons/LinkedIn.svg';
import XIcon from '../../../../static/svgIcons/X-Icon.svg';
import CareersNavigation from '../../../components/careersNavigation/CareersNavigation';
import HeaderBanner from '../../../components/headerBanner/HeaderBanner';
import Icon from '../../../components/icons/Icon';
import Shape from '../../../components/Shape';
import ShapeX from '../../../components/ShapeX';
import useDisclaimer from '../../../hooks/useDisclaimer';
import { useJobs } from '../../../hooks/useJobs';
import { colors } from '../../../styles/sharedStyled';
import HeaderImageBreakpoints from '../../../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../../images/job_details_sm.png`}
        alt={`Job specification`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../../images/job_details_md.png`}
        alt={`Job specification`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../../images/job_details_lg.png`}
      alt={`Job specification`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const JobDetailsPage = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const [title, setTitle] = useState<null | string>(null);
  const [items, setItems] = useState<null | string>(null);
  const [currentUrl, setCurrentUrl] = useState('');
  const [selectedJob, setSelectedJob] = useState<null | any>(null);

  const { careers } = useJobs();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const job = params.get('job');
    setTitle(job);
    const url = typeof window !== 'undefined' ? window.location.href : '';
    setCurrentUrl(url);
  }, []);

  useEffect(() => {
    if (careers === null) {
      return;
    }
    if (title === null) {
      return;
    }
    try {
      const filteredSelectedJob = careers.filter((v) => v.title === title);
      setSelectedJob(filteredSelectedJob[0]);
    } catch {
      (e: any) => console.error('error: ' + e);
    }
  }, [title, careers]);

  useEffect(() => {
    if (selectedJob === null) {
      return;
    }
    // Split the content on new lines
    const items = selectedJob.body.split('\r\n');
    const removeTabs = items.map((v: any) => {
      // Remove any tabs
      const step1 = v.replace('\t', '').trim();
      // Replace h4 with strong for consistancy
      const step2 = step1.replace('<h4', '<strong');
      const step3 = step2.replace('</h4', '</strong');
      return step3;
    });
    const result: any[] = [];
    let listHolder = '';
    let isList = false;
    // Wrap plain text in <p>
    // Group lists
    // Remove blanks
    for (let i = 0; i < removeTabs.length; i++) {
      // console.log(i)
      // console.log(removeTabs[i])
      if (
        removeTabs[i] === '' ||
        removeTabs[i] === '&nbsp;' ||
        removeTabs[i] === '<strong>&nbsp;</strong>'
      ) {
        // console.log("is blank, skip")
      } else if (removeTabs[i].substr(0, 4) === '<ul>') {
        // console.log("is list start, init list")
        listHolder = removeTabs[i];
        isList = true;
      } else if (removeTabs[i].substr(0, 5) === '</ul>') {
        // console.log("is list end, end list")
        listHolder = listHolder + removeTabs[i];
        result.push(listHolder);
        isList = false;
      } else if (isList) {
        // console.log("is part of a list, append")
        listHolder = listHolder + removeTabs[i];
      } else {
        // console.log("is regular text, add")
        result.push(`<p>${removeTabs[i]}</p>`);
      }
    }
    // console.log(result)
    // console.log(result.join(""))

    setItems(result.join(''));
  }, [selectedJob]);

  return (
    <div>
      <HeaderBanner
        backgroundColor="bg-pxPurple"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        maxHeight="max-h-680"
        styling="pt-11px pl-21px pr-3.5 md:pt-25px md:px-100px lg:pt-9 lg:pl-93px lg:pr-86px max-w-1107 relative z-1"
      />
      <CareersNavigation />
      <div className={'bg-pxGrey relative  overflow-hidden'}>
        <div className={' grid-cols-1 md:grid-cols-2 grid pt-24 padding-x'}>
          {/* COL 1 */}
          <div className={'md:pr-20'}>
            <h3 className={'mb-5'}>{selectedJob && selectedJob.title}</h3>
            <p className={'text-bodyCopy'}>
              Location: {selectedJob && selectedJob.location}
              <br />
              Department: {selectedJob && selectedJob.department}
              <br />
              Closing date: {selectedJob && selectedJob.closing_date}
              <br />
            </p>
            <div className={'flex'}>
              <a
                href={`mailto:careers@policyexpert.co.uk?subject=${title}`}
                className={'max-w-194 flex-grow mb-8'}
              >
                <button
                  className={
                    'btn-secondary rounded-xl flex flex-row justify-center items-center font-Campton-Semibold md:btn-lg font-bold w-full'
                  }
                >
                  <span className={'py-1'}>Submit your CV</span>
                </button>
              </a>
            </div>
          </div>

          {/* COL 2 */}
          <div>
            {/* Content  */}
            <div
              className={'job-description-container mb-20'}
              dangerouslySetInnerHTML={{ __html: items === null ? '' : items }}
            />
            {/* Buttons */}
            <div className={'flex'}>
              <a
                href={`mailto:careers@policyexpert.co.uk?subject=${title}`}
                className={'max-w-194 flex-grow mb-8'}
              >
                <button
                  className={
                    'btn-secondary rounded-xl flex flex-row justify-center items-center font-Campton-Semibold md:btn-lg font-bold w-full'
                  }
                >
                  <span className={'py-1'}>Submit your CV</span>
                </button>
              </a>
            </div>{' '}
            <div className={'flex flex-row mb-20 items-center'}>
              <span className={'text-bodyCopy  '}>Share this job:</span>
              <div className={'flex flex-row'}>
                <a
                  className={'ml-5'}
                  target="_blank"
                  href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                    currentUrl
                  )}`}
                  rel="noreferrer"
                >
                  <Icon size={3} className="h-10 w-10">
                    <Facebook fill={colors.pink2} className="h-10 w-10" />
                  </Icon>
                </a>

                <a
                  className={'ml-5 '}
                  target="_blank"
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    currentUrl
                  )}`}
                  rel="noreferrer"
                >
                  <Icon size={3} className="h-10 w-10">
                    <XIcon fill={colors.pink2} className="h-10 w-10" />
                  </Icon>
                </a>
                <a
                  className={'ml-5'}
                  target="_blank"
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    currentUrl
                  )}`}
                  rel="noreferrer"
                >
                  <Icon size={3} className="h-10 w-10">
                    <LinkedIn fill={colors.pink2} className="h-10 w-10" />
                  </Icon>
                </a>
              </div>
            </div>
          </div>
          <ShapeX position={'bottom-left'} />
          <div className="hidden md:block">
            <Shape position={'left-far'} />
          </div>
          <div className="md:hidden">
            <Shape position={'right-far-top-job-details'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsPage;

export const Head: HeadFC = () => (
  <>
    <title>Careers with Policy Expert</title>
    <html lang="en" />
  </>
);
