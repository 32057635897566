import React from 'react';

import { IGatsbyImageData } from 'gatsby-plugin-image';

import DropShapeCardReverseRight from '../dropShapeCardReverseRight/DropShapeCardReverseRight';
import ImageComponent from '../imageComponent/ImageComponent';

type CareersButtonsType = {
  label: string;
  url: string;
  primary: boolean;
  target?: string;
};

export type ImageWithTextCareersDataType = {
  bgColor: string;
  noPaddingTop?: boolean;
  imageLeft?: boolean;
  blendsWithTop?: boolean;
  headerTextFirst: string;
  subTextFirst: any;
  headerTextSecond?: string;
  subTextSecond?: any;
  imgName: string;
  imageFile: IGatsbyImageData;
  imgMaxHeightMob?: string;
  imgHeightMob?: string;
  imgHeight?: string;
  buttons: CareersButtonsType[];
};

type Props = {
  data: ImageWithTextCareersDataType;
  isCareers?: boolean;
};

const ImageWithHTML = ({ data, isCareers }: Props) => (
  <div className={`${data.bgColor} relative overflow-hidden`}>
    {/* Grid container  */}
    <div
      className={`${
        data.noPaddingTop ? 'pt-82px' : 'py-32px md:py-130px '
      } padding-x grid grid-cols-1 md:grid-cols-2 gap-x-10 `}
    >
      {/* Grid 1 */}
      <div className="flex flex-col w-full md:pr-130px -mb-8 md:mb-0">
        {/* heading */}
        <h2 className="md:max-w-710 pb-8 md:pb-16 text-pxCharcoal">
          {data.headerTextFirst}
        </h2>
        {/* body */}
        <div className="md:mb-32">{data.subTextFirst}</div>
        <div className={'mb-70 md:hidden'}>
          <div className={'ml-0 -mr-12 pl-0 md:hidden'}>
            <DropShapeCardReverseRight
              className={`${
                isCareers && isCareers
                  ? ` ${data.imgMaxHeightMob} ${data.imgHeightMob} ${data.imgHeight}`
                  : ''
              }`}
            >
              <ImageComponent
                imageFile={data.imageFile}
                alt={data.imgName}
                className={`${
                  isCareers && isCareers ? `${data.imgHeightMob} md:h-full` : ''
                }`}
              />
            </DropShapeCardReverseRight>
          </div>
        </div>

        {/* heading */}
        <h2 className="md:w-2/3 text-pxCharcoal md:pb-16 pb-8">
          {data.headerTextSecond}
        </h2>
        {/* body */}
        <div className="pb-7 md:pb-0 md:mb-82px ">{data.subTextSecond}</div>
        {/* Buttons */}
        {data.buttons &&
          data.buttons.map((btn: any) => {
            return (
              <a
                key={btn.label}
                href={btn.url}
                className={`btn-${
                  btn.smallestSize || 'md'
                } lg:btn-lg lg:mr-32px mb-32px last:mb-0 btn-${
                  btn.primary ? 'primary' : 'secondary'
                }`}
                target={btn.target ? btn.target : '_self'}
              >
                {btn.label}
              </a>
            );
          })}
      </div>
      {/* Grid 2 */}
      <div className=" hidden md:block">
        <div className={`'ml-0 mr-40%-minus pl-0 relative -top-20 `}>
          <DropShapeCardReverseRight
            className={`${
              isCareers && isCareers
                ? `${data.imgHeightMob} ${data.imgHeight}`
                : ''
            }`}
          >
            <ImageComponent
              imageFile={data.imageFile}
              alt={data.imgName}
              className={`${
                isCareers && isCareers ? `${data.imgHeightMob} md:h-full` : ''
              }`}
            />
          </DropShapeCardReverseRight>
        </div>
      </div>
    </div>
  </div>
);

export default ImageWithHTML;
