import { css, styled } from 'styled-components';

import { colors, fontFamilyCamptonBook } from '../styles/sharedStyled';

const standardText = css`
  font-family: ${fontFamilyCamptonBook};
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.5px;
  font-weight: 300;
  margin-bottom: 32px;
`;

export const DataWrapper = styled.div``;

export const SectionHeader = styled.p`
  ${standardText}
  font-weight: 700;
  color: ${colors.purple7};

  @media (min-width: 1024px) {
    font-size: 30px;
    letter-spacing: -0.6px;
    line-height: 32px;
  }
`;

export const SectionText = styled.p`
  ${standardText}
`;

export const StatementList = styled.ul``;

export const StatementListText = styled.li`
  ${standardText}
`;
