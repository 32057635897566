import React from 'react';

import DropshapeLarge from '../../../static/svgIcons/DropshapeLarge.svg';
import DropshapeSmall from '../../../static/svgIcons/DropshapeSmall.svg';

type Props = {
  children: React.ReactNode;
  color?: string;
  background?: string;
  className?: string;
  fullWidth?: boolean;
  largeShape?: boolean;
  claimsCard?: boolean;
  petCard?: boolean;
};

const ImpactDropShapeCard = ({
  children,
  color,
  background,
  className,
  largeShape = false,
  fullWidth = false,
  claimsCard = false,
  petCard = false,
}: Props) => {
  const backgroundStyle = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div className={`flex flex-col h-full ${className || ''}`}>
      <div
        className={`overflow-hidden ${
          fullWidth
            ? ''
            : `max-w-332 md:max-w-339 ${
                claimsCard
                  ? 'lg:max-w-413'
                  : petCard
                  ? 'lg:max-w-332'
                  : 'lg:w-339'
              }`
        } relative rounded-t-radius-50 w-full h-full
         ${color ? color : 'bg-pxWhite'}`}
        style={{
          backgroundImage: background ? `url(${background})` : undefined,
          ...backgroundStyle,
        }}
      >
        {children}
      </div>
      {largeShape ? (
        <DropshapeLarge
          preserveAspectRatio="none"
          className={`w-full h-100px ${
            fullWidth
              ? ''
              : `max-w-332 md:max-w-339 ${
                  claimsCard
                    ? 'lg:max-w-413'
                    : petCard
                    ? 'lg:max-w-332'
                    : 'lg:w-339'
                }`
          } mt-1-minus`}
        />
      ) : (
        <DropshapeSmall
          preserveAspectRatio="none"
          className={`w-full h-100px ${
            fullWidth
              ? ''
              : `max-w-332 md:max-w-339 ${
                  claimsCard
                    ? 'lg:max-w-413'
                    : petCard
                    ? 'lg:max-w-332'
                    : 'lg:w-339'
                }`
          } mt-1-minus`}
        />
      )}
    </div>
  );
};

export default ImpactDropShapeCard;
