import React from 'react';

import { Link } from 'gatsby';

import { TwoColumnTextBannerType } from '../twoColumnTextBanner/TwoColumnTextBanner';

type Props = {
  data: TwoColumnTextBannerType;
  attached?: boolean;
  home?: boolean | null;
};

const ErrorBanner = ({ data, attached, home }: Props) => {
  return (
    <div
      id="anchor"
      className={`
        ${data.bgColor}
          py-82px md:py-130px
        pb-82px md:pb-130px bg-left-bottom bg-no-repeat bg-80 md:bg-50 relative overflow-hidden`}
    >
      <div className="padding-x ">
        <div
          className={`
          ${data.textColor} 
          ${attached ? 'border-t border-dividerGrey pt-82px md:pt-130px' : ''} 
          justify-between flex flex-col md:flex-row`}
        >
          <div className="w-full md:w-5/12 relative z-50">
            {data.headerText && home != null && (
              <h2 className=" mb-32px">
                {home ? data.headerText : 'Manage your car policy'}
              </h2>
            )}
            {data.headerText && home == null && (
              <h2 className=" mb-32px">{data.headerText}</h2>
            )}
            {data.headerSubText && (
              <p className="mb-32px">{data.headerSubText} </p>
            )}
            {data.subContentLink && data.subContentLink.url && (
              <a
                className="text-pxPink font-semibold"
                href={data.subContentLink.url}
              >
                {data.subContentLink.label}
              </a>
            )}
          </div>
          <div className={`w-full md:w-5/12 ${data.subText ? '' : ''}`}>
            {data.subText != null
              ? data.subText.map((text) => (
                  <p
                    className="mb-32px "
                    key={text}
                    dangerouslySetInnerHTML={{ __html: text }}
                  ></p>
                ))
              : ''}
            {data.btns != null
              ? data.btns.map((button) => (
                  <Link
                    className={`
                        ${button.primary ? 'btn-primary' : 'btn-secondary'} 
                        btn-md md:btn-lg mx-auto md:ml-0 mb-32px last:mb-0 first:mt-32px md:first:mt-0`}
                    // @ts-ignore
                    to={
                      button.url
                        ? button.url
                        : button.homeUrl &&
                          button.carUrl &&
                          (home ? button.homeUrl : button.carUrl)
                    }
                    key={'bannerbutton' + button.label}
                  >
                    {button.label}
                  </Link>
                ))
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBanner;
