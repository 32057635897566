import React from 'react';

import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import CareersNavigation from '../../../components/careersNavigation/CareersNavigation';
import CaseStudies, {
  CaseStudiesType,
} from '../../../components/caseStudies/CaseStudies';
import HeaderBanner from '../../../components/headerBanner/HeaderBanner';
import useDisclaimer from '../../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../../images/working_here_sm.png`}
        alt={`Working here`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../../images/working_here_md.png`}
        alt={`Working here`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../../images/working_here_lg.png`}
      alt={`Working here`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const WorkingHerePage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const WorkingHereImageQuery = useStaticQuery(graphql`
    query WorkingHereImages {
      Gary: imageSharp(original: { src: { regex: "/gary/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Greg: imageSharp(original: { src: { regex: "/greg/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Vicky: imageSharp(original: { src: { regex: "/vicky/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Luke: imageSharp(original: { src: { regex: "/luke/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      DaniValente: imageSharp(original: { src: { regex: "/dani_valente/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Waqas: imageSharp(original: { src: { regex: "/waqas/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const caseStudiesData: CaseStudiesType = {
    employers: [
      {
        name: 'Gary Elliot',
        position: 'L & D Manager',
        img: 'gary.jpg',
        imageFile: WorkingHereImageQuery.Gary.gatsbyImageData,
        location: 'Motherwell',
        questions: [
          {
            question: "What's your role here at Policy Expert?",
            answer: (
              <p className="md:text-xl">
                I’m responsible for training, which includes inductions,
                upskilling, annual knowledge reviews, additional/external
                qualifications for all areas, management training and
                recruitment of new staff.
              </p>
            ),
          },
          {
            question: 'What attracted you to work at Policy Expert?',
            answer: (
              <>
                <p className="md:text-xl">
                  It was a new and up and coming business with great people. My
                  interview in 2014 with Linda left me buzzing about the
                  opportunity of building a career.
                </p>
                <p className="md:text-xl">
                  I could see how massive the business was going to be and that
                  I wanted to be a big part of that.
                </p>
              </>
            ),
          },
          {
            question: 'What do you enjoy most about working here?',
            answer: (
              <p className="md:text-xl">
                The people, the atmosphere, the fast-paced environment and the
                responsibilities that I am trusted to take on daily. I feel like
                our voices are heard and we are valuable to the business.
              </p>
            ),
          },
        ],
      },
      {
        name: 'Greg Deacon',
        position: 'Marketing Manager',
        img: 'greg.jpg',
        imageFile: WorkingHereImageQuery.Greg.gatsbyImageData,
        location: 'London',
        questions: [
          {
            question: 'What’s your role here at Policy Expert?',
            answer: (
              <p className="md:text-xl">
                I work in the marketing team and I’m responsible for our
                commercial relationships with our aggregators.
              </p>
            ),
          },
          {
            question: 'What attracted you to work at Policy Expert?  ',
            answer: (
              <>
                <p className="md:text-xl">
                  Policy Expert was my first job coming out of education – and
                  the appeal for me was working in a role where I was exposed to
                  the full marketing mix.{' '}
                </p>
                <p className="md:text-xl">
                  I was able to learn on the job, whilst studying for
                  professional qualifications. I’m really proud of my CIM
                  digital qualifications and I’m excited to continue learning.
                  I’m grateful for the support I’ve had which, along with hard
                  work, has helped me to grow into my current role.
                </p>
              </>
            ),
          },
          {
            question: 'What do you enjoy most about working here?',
            answer: (
              <>
                <p className="md:text-xl">
                  As well as the encouragement to learn and develop, I really
                  enjoy the people I work with. We all have our unique skills
                  and personalities, but everyone is hard working and passionate
                  which makes for a great working environment.
                </p>
                <p className="md:text-xl">
                  The office is amazing and I enjoy the social side of work too,
                  which includes a bit of healthy competition playing pool!
                </p>
              </>
            ),
          },
        ],
      },
      {
        name: 'Vicky Imrie',
        position: 'Sales & Service Manager',
        img: 'vicky.jpg',
        imageFile: WorkingHereImageQuery.Vicky.gatsbyImageData,
        location: 'Motherwell',
        questions: [
          {
            question: 'What’s your role here at Policy Expert?',
            answer: (
              <p className="md:text-xl">
                I look after a team of service agents, making sure that they
                meet or exceed their targets and deliver the best service. I
                also provide support and guidance in their personal development.
              </p>
            ),
          },
          {
            question: 'What attracted you to work at Policy Expert?',
            answer: (
              <p className="md:text-xl">
                I was attracted to the values that we have as a business.
                Everyone is on the same page and to me that makes for a great
                working environment. You get the best out of your induction.
              </p>
            ),
          },
          {
            question: 'What do you enjoy most about working here?',
            answer: (
              <>
                <p className="md:text-xl">
                  I really enjoy the people that work here and the environment
                  that we work in. The development opportunities are also great,
                  speaking from personal experience.{' '}
                </p>
                <p className="md:text-xl">
                  When I started with Policy Expert, I was a Customer Service
                  Agent and as the business has grown, I have progressed to a
                  Team Manager.
                </p>
              </>
            ),
          },
        ],
      },
      {
        name: 'Luke Pomeroy',
        position: 'Reporting Analyst',
        img: 'luke.jpg',
        imageFile: WorkingHereImageQuery.Luke.gatsbyImageData,
        location: 'London',
        questions: [
          {
            question: "What's your role here at Policy Expert?",
            answer: (
              <>
                <p className="md:text-xl">
                  I produce both regular and ad-hoc business reports, which
                  analyses Policy Expert’s business performance.
                </p>
                <p className="md:text-xl">
                  My main focus is the home insurance side of the business.{' '}
                </p>
              </>
            ),
          },
          {
            question: 'What attracted you to work at Policy Expert?',
            answer: (
              <p className="md:text-xl">
                For me, everything hit the mark. A well-established business
                that clearly cares about its customers and people and an
                impressive office in the city, with a great atmosphere. I found
                the benefits to be really competitive too.
              </p>
            ),
          },
          {
            question: 'What do you enjoy most about working here?',
            answer: (
              <>
                <p className="md:text-xl">
                  We’re encouraged to work collaboratively and share our ideas –
                  this motivates me as I really believe my contributions are
                  valued. The people I work with are great and the office is
                  always buzzing. Everyone has a similar mindset – we all work
                  hard and we care about what we do, but we have fun too!{' '}
                </p>
                <p className="md:text-xl">
                  The offices are great with excellent facilities – especially
                  the pool table!
                </p>
              </>
            ),
          },
        ],
      },
      {
        name: 'Dani Valente',
        position: 'Customer Service',
        img: 'dani_valente.jpg',
        imageFile: WorkingHereImageQuery.DaniValente.gatsbyImageData,
        location: 'Milton Keynes',
        questions: [
          {
            question: "What's your role here at Policy Expert?",
            answer: (
              <p className="md:text-xl">
                I work within New Business selling home insurance. I speak with
                new customers to help them get the right policy that suits their
                needs.
              </p>
            ),
          },
          {
            question: 'What attracted you to work at Policy Expert?',
            answer: (
              <p className="md:text-xl">
                Working within a call centre environment. The atmosphere is nice
                and buzzy and it’s a fun place to work. It is social and really
                friendly.
              </p>
            ),
          },
          {
            question: 'What do you enjoy most about working here?',
            answer: (
              <p className="md:text-xl">
                The amazing work colleagues, head of businesses and the people -
                they make it such a great place to work. We’re like one big
                family.
              </p>
            ),
          },
        ],
      },
      {
        name: 'Waqas Javeed',
        position: 'Team Manager',
        img: 'waqas.jpg',
        imageFile: WorkingHereImageQuery.Waqas.gatsbyImageData,
        location: 'Milton Keynes',
        questions: [
          {
            question: "What's your role here at Policy Expert?",
            answer: (
              <>
                <p className="md:text-xl">
                  My journey here at Policy Expert started off in the New
                  Business department where I was selling Home insurance
                  Policies.{' '}
                </p>
                <p className="md:text-xl">
                  After eight months I moved into the Quality Assurance
                  department which was a fantastic career move and after a year
                  or so I was promoted to Floor Manager within the Customer
                  Service Department. I’ve just succeeded in becoming a Team
                  Manager, achieving one of my long term career goals!
                </p>
              </>
            ),
          },
          {
            question: 'What attracted you to work at Policy Expert?',
            answer: (
              <>
                <p className="md:text-xl">
                  I was attracted by the excellent reviews on Glassdoor. This
                  was further backed up when everyone was so friendly at my
                  interview.{' '}
                </p>
                <p className="md:text-xl">
                  I’ve found that if you’re willing to put the work and effort
                  in, you’ll be rewarded. This is proven by my successful
                  application on to the ADP course, a management qualification.
                  This should put me in a good position to progress my career
                  here.
                </p>
              </>
            ),
          },
          {
            question: 'What do you enjoy most about working here?',
            answer: (
              <>
                <p className="md:text-xl">
                  I feel valued and an important part of the Policy Expert
                  family. I love the monthly incentives rewarding us for the
                  hard work we put in. The business is always looking at ways to
                  improve our skill sets too, supporting those who want to
                  progress their career.
                </p>
                <p className="md:text-xl">
                  The breakout area is also great - it's refreshing being able
                  to take my mind off work at lunchtime, and I enjoy spending
                  some chilled out time with my colleagues.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxMint"
        headerImage={headerImage}
        maxHeight="max-h-680"
        styling="pt-17px pl-25px md:pt-45px md:pl-74px md:pr-2 lg:pt-59px lg:px-6 lg:max-w-1089"
      />
      <CareersNavigation />
      <CaseStudies data={caseStudiesData} />
    </>
  );
};

export default WorkingHerePage;

export const Head: HeadFC = () => (
  <>
    <title>Careers with Policy Expert</title>
    <meta
      name="description"
      content="Become part of the team at Policy Expert - an award winning car and home insurance provider."
    />
    <html lang="en" />
  </>
);
