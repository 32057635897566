import { styled } from 'styled-components';

export const HeaderBannerContainer = styled.div<{
  $isHomepage?: boolean;
  $v2Refresh?: boolean;
}>`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
  align-items: center;
  max-width: 1330px;
  margin: auto;

  @media (min-width: 768px) {
    text-align: left;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
    height: ${({ $isHomepage, $v2Refresh }) =>
      $isHomepage && $v2Refresh ? '639px' : $isHomepage && '654px'};
    padding: ${({ $isHomepage, $v2Refresh }) =>
      $isHomepage && $v2Refresh
        ? '40px 40px 180px'
        : $v2Refresh
        ? '40px 60px 120px'
        : '40px 40px 120px'};
  }

  @media (min-width: 1024px) {
    justify-content: ${({ $v2Refresh }) =>
      $v2Refresh ? 'center' : ' space-between'};
    gap: ${({ $v2Refresh, $isHomepage }) =>
      $v2Refresh && !$isHomepage && '100px'};
    ${({ $isHomepage }) =>
      $isHomepage
        ? ` height: auto; 
            padding: 40px 0 190px 40px; 
            align-items: stretch;`
        : ` padding: 30px 40px 100px;`}
  }

  @media (min-width: 1180px) {
    padding-bottom: ${({ $isHomepage }) => $isHomepage && '110px'};
  }
`;

export const HeaderBannerInformationContainer = styled.div<{
  $isHomepage?: boolean;
  $v2Refresh?: boolean;
}>`
  @media (min-width: 768px) {
    margin-top: ${({ $isHomepage, $v2Refresh }) =>
      $isHomepage && $v2Refresh && '-60px'};
  }

  @media (min-width: 1024px) {
    margin: ${({ $isHomepage }) => $isHomepage && '60px 0 0 10px'};
    margin-bottom: ${({ $isHomepage, $v2Refresh }) =>
      $isHomepage && $v2Refresh && '170px'};
  }
`;

export const HeaderImageContainer = styled.div<{
  $v2Refresh?: boolean;
}>`
  margin-bottom: ${({ $v2Refresh }) => ($v2Refresh ? '30px' : '20px')};

  @media (min-width: 768px) {
    margin-bottom: 0;
    align-self: flex-start;
  }
`;
