import React from 'react';

import { graphql, HeadFC, PageProps, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import BreakArea from '../../../../static/svgIcons/BreakArea.svg';
import Games from '../../../../static/svgIcons/Games.svg';
import GlassesCelebrate from '../../../../static/svgIcons/GlassesCelebrate.svg';
import HeartHealth from '../../../../static/svgIcons/HeartHealth.svg';
import HotDrinks from '../../../../static/svgIcons/HotDrinks.svg';
import IconicBuilding from '../../../../static/svgIcons/IconicBuilding.svg';
import ThumbsUp from '../../../../static/svgIcons/ThumbsUp.svg';
import TrainStation from '../../../../static/svgIcons/TrainStation.svg';
import CareersNavigation from '../../../components/careersNavigation/CareersNavigation';
import HeaderBanner from '../../../components/headerBanner/HeaderBanner';
import ImageWithText, {
  ImageWithTextDataType,
} from '../../../components/imageWithText/ImageWithText';
import JobsLondon from '../../../components/jobs/JobsLondon';
import OfficeHighlights, {
  OfficeHighlightsDataType,
} from '../../../components/officeHighlights/OfficeHighlights';
import TwoColumnHeaderGrid, {
  TwoColumnHeaderGridType,
} from '../../../components/twoColumnHeaderGrid/TwoColumnHeaderGrid';
import TwoColumnHTMLBanner from '../../../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../../images/london_sm.png`}
        alt={`London office. Head office`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../../images/london_md.png`}
        alt={`London office. Head office`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../../images/london_lg.png`}
      alt={`London office. Head office`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const officeHighlightsData: OfficeHighlightsDataType = {
  bgColor: 'bg-pxGrey',
  headerText: 'Office highlights',
  subHeaderText: [
    "Our brand-new office is aptly located in the heart of the city - London's insurance district.",
    'Our modern, open plan space offers a dynamic and collaborative environment where everyone can thrive and enjoy working in a fantastic office environment!',
  ],
  location: 'London city',
  commuteDetails: [
    {
      icon: <TrainStation stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          London Liverpool Street station <br className="md:hidden" />
          <span className="text-footerCopy md:text-finerDetailsBodyCopy">
            (0.1 miles)
          </span>
        </p>
      ),
    },
    {
      icon: <TrainStation stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Moorgate station <br className="md:hidden" />
          <span className=" text-footerCopy md:text-finerDetailsBodyCopy">
            (0.5 miles)
          </span>
        </p>
      ),
    },
  ],
  locationBenefits: [
    {
      icon: <IconicBuilding stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Iconic building{' '}
          <span className="text-footerCopy md:text-finerDetailsBodyCopy">
            (one of the tallest in the city)
          </span>
        </p>
      ),
    },
    {
      icon: <HotDrinks stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Hot/cold drinks, breakfast, and fruit provided
        </p>
      ),
    },
    {
      icon: <BreakArea stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Plenty of break out areas to relax
        </p>
      ),
    },
    {
      icon: <Games stroke="#6214bE" fill="#6214bE" />,
      text: (
        <p className="text-finerDetailsBodyCopy md:text-bodyCopy ml-3">
          Games room and music room too!
        </p>
      ),
    },
  ],
};

const videoData = {
  bgColor: 'bg-pxGrey',
  heading: 'Want to see more?',
  subText: 'Meet our tech team!',
  body: (
    <div>
      <div className="relative h-0 pb-56.25">
        <iframe
          className="absolute top-0 left-0 w-full h-full border-0 border-none"
          src="https://player.vimeo.com/video/662623174?h=53dbb46570"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Policy Expert - insurance for people like you - October 2021"
        />
      </div>
    </div>
  ),
};

const LondonPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  const LondonImageQuery = useStaticQuery(graphql`
    query LondonImages {
      LondonOfficeDesks: imageSharp(
        original: { src: { regex: "/london_office_desks/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Luke: imageSharp(original: { src: { regex: "/luke/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      Greg: imageSharp(original: { src: { regex: "/greg/" } }) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
      LondonOfficePoolTable: imageSharp(
        original: { src: { regex: "/london_office_pooltable/" } }
      ) {
        gatsbyImageData(layout: CONSTRAINED, quality: 60)
      }
    }
  `);

  const whoWorksHereData: ImageWithTextDataType = {
    bgColor: 'bg-white',
    imageLeft: false,
    blendsWithTop: true,
    copyBlock: [
      {
        headerText: 'Who works here',
        subHeader: 'Our head office is home to: ',
        listText: [
          'Senior Leaders',
          'Developers and Tech',
          'Business Analysts',
          'Marketing',
          'Actuaries',
          'Finance',
          'Pricing and Analytics teams',
        ],
      },
    ],
    imgName: 'london_office_desks.jpg',
    imageFile: LondonImageQuery.LondonOfficeDesks.gatsbyImageData,
    imgMaxHeightMob: 'max-h-360',
    imgHeightMob: 'h-360',
    imgHeight: 'md:h-860',
  };

  const locationTeam: TwoColumnHeaderGridType = {
    bgColor: 'white',
    hasVideo: true,
    videoTitle: 'Policy Expert - Meet our tech team!',
    videoSubTitle: 'Want to see more? Meet our tech team!',
    videoUrl: 'https://player.vimeo.com/video/662623174?h=53dbb46570',
    header: 'What’s it like to work here?',
    subText: 'Meet some of the London team',
    items: [
      {
        imgName: 'luke.jpg',
        imageFile: LondonImageQuery.Luke.gatsbyImageData,
        header: 'Luke Pomery',
        subText: ['Reporting Analyst', 'London'],
        button: {
          label: 'Read more',
          url:
            '/about-us/careers/working-here/#selected-section?name=' +
            'LukePomeroy' +
            '',
          primary: false,
        },
      },
      {
        imgName: 'greg.jpg',
        imageFile: LondonImageQuery.Greg.gatsbyImageData,
        header: 'Greg Deacon',
        subText: ['Marketing Manager', 'London'],
        button: {
          label: 'Read more',
          url:
            '/about-us/careers/working-here/#selected-section?name=' +
            'GregDeacon' +
            '',
          primary: false,
        },
      },
    ],
  };

  const companyBenefits: ImageWithTextDataType = {
    bgColor: 'bg-pxTan',
    imageLeft: false,
    blendsWithTop: false,
    copyBlock: [
      {
        headerText: 'Company benefits',
        subTextIcon: [
          {
            icon: <ThumbsUp stroke="#6214bE" fill="#6214bE" />,
            iconName: 'ThumbsUp',
            benefits: [
              'Competitive salary',
              'Pension',
              'Travel season ticket loan',
            ],
          },
          {
            icon: <HeartHealth stroke="#6214bE" fill="#6214bE" />,
            iconName: 'HeartHealth',
            benefits: [
              'Access to mental health support',
              'Free fruit',
              'Free drinks and breakfast',
            ],
          },
          {
            icon: <GlassesCelebrate stroke="#6214bE" fill="#6214bE" />,
            iconName: 'GlassesCelebrate',
            benefits: ['Holidays', 'Regular social events'],
          },
        ],
      },
    ],

    imgName: 'london_office_pooltable.jpg',
    imageFile: LondonImageQuery.LondonOfficePoolTable.gatsbyImageData,
    imgMaxHeightMob: 'max-h-360',
    imgHeightMob: 'h-360',
    imgHeight: 'md:h-780',
  };

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxCharcoal"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        maxHeight="max-h-680"
        styling="pt-26px pl-30px md:pt-12 md:pl-82px md:pr-54px lg:pt-45px lg:px-6 lg:max-w-893 relative z-1"
      />
      <CareersNavigation />
      <OfficeHighlights data={officeHighlightsData} />
      <ImageWithText
        data={whoWorksHereData}
        topCoverColorMobile="white"
        topCoverColor="pxGrey"
        isCareers
        isCareersWorks
      />
      <TwoColumnHeaderGrid shape="none" data={locationTeam} isLocation />
      <TwoColumnHTMLBanner data={videoData} />
      <ImageWithText
        data={companyBenefits}
        topCoverColorMobile="pxTan"
        topCoverColor="pxTan"
        isCareers
        isCareersWorks
      />
      <JobsLondon />
    </>
  );
};

export default LondonPage;

export const Head: HeadFC = () => (
  <>
    <title>Careers with Policy Expert</title>
    <meta
      name="description"
      content="Become part of the team at Policy Expert - an award winning car and home insurance provider."
    />
    <html lang="en" />
  </>
);
