import React, { ReactNode } from 'react';

import Stars from '../../../static/svgIcons/Stars.svg';
import Icon from '../icons/Icon';
import RoundCard from '../roundCard/RoundCard';

type CardsType = {
  content: string;
  img: ReactNode;
  imgName?: string;
};

type Props = {
  cards: CardsType[];
};

const TreeCards = ({ cards }: Props) => (
  <div className="flex flex-col md:flex-row grid md:grid-cols-3 grid-gap-space-small">
    {cards.map(({ img, imgName, content }) => (
      <div
        key={content}
        className="flex-1 pb-32px last:pb-0 md:pb-0 round-shape-right"
      >
        <RoundCard color="white" left={false}>
          <div className=" mx-auto w-40 h-20 my-auto flex justify-center">
            <Icon type="primary" size={imgName === 'TrustPilot' ? 5 : 6}>
              {img}
            </Icon>
          </div>
          <div className=" mx-auto w-36 mb-32px">
            <Stars className="z-50" stroke="none" />
          </div>
          <p className="text-small text-center mb-0">{content}</p>
        </RoundCard>
      </div>
    ))}
  </div>
);

export default TreeCards;
