import React from 'react';

import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import Chat from '../../../../static/svgIcons/Chat.svg';
import CV from '../../../../static/svgIcons/CV.svg';
import ThumbsUp from '../../../../static/svgIcons/ThumbsUp.svg';
import Way3 from '../../../../static/svgIcons/Way3.svg';
import CareersNavigation from '../../../components/careersNavigation/CareersNavigation';
import HeaderBanner from '../../../components/headerBanner/HeaderBanner';
import OtherJobs from '../../../components/otherJobs/OtherJobs';
import TwoColumnIconList, {
  TwoColumnIconListDataType,
} from '../../../components/twoColumnIconList/TwoColumnIconList';
import TwoColumnListDbl from '../../../components/twoColumnListDbl/TwoColumnListDbl';
import useDisclaimer from '../../../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../../../types/breakpoints';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src={`../../../images/application_process_sm.png`}
        alt={`Insurance for people like you`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src={`../../../images/application_process_md.png`}
        alt={`Insurance for people like you`}
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src={`../../../images/application_process_lg.png`}
      alt={`Insurance for people like you`}
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const twoColumnIconListData: TwoColumnIconListDataType = {
  header: 'Your journey to joining us',
  bgColor: 'bg-pxGrey',
  content:
    "Our application process may vary depending on the role you're applying for - but here are some of the ways we get to know you:",
  list: [
    {
      icon: <CV stroke="#6214bE" fill="#6214bE" />,
      title: 'Your CV',
      content:
        "Once we've reviewed your CV, we'll be in touch to let you know what the next steps are.",
    },
    {
      icon: <Chat stroke="#6214bE" fill="#6214bE" />,
      title: 'We have a chat',
      content:
        "You can tell us what you're looking for and we'll tell you more about the role.",
    },
    {
      icon: <Way3 stroke="#6214bE" fill="#6214bE" />,
      title: "We'll invite you to an interview",
      content:
        "Where we'll discuss the skills, knowledge and behaviours required for the role. We encourage you to ask us questions too.",
      contentLink: {
        name: 'See our top interview tips below.',
        url: '#interview-tips',
      },
    },
    {
      icon: <ThumbsUp stroke="#6214bE" fill="#6214bE" />,
      title: 'Welcome to the team',
      content:
        "If you're successful we'll make you an offer, do all the necessary checks and confirm your start date. We'll keep you updated every step of the way.",
    },
  ],
};

const twoColumnListDblData = {
  header: 'Our top interview tips',
  bgColor: 'bg-pxTan',
  content: [
    'We know that interviews can be daunting – but remember, as much as you want to do well, we want to see the best of you too.',
    'Here is our top advice, to help you get the most out of your interview:',
  ],
  list: [
    {
      title: 'Be prepared',
      innerList: [
        "Make sure you know where you're going in advance, so you don't have to rush.",
        "Be on time – it's good to get there slightly earlier, allowing for traffic and travel delays.",
        "If you're having a video interview, make sure you have all the technology ready ahead of time.",
      ],
    },
    {
      title: 'Know the role you’re applying for',
      innerList: [
        'Make sure you know the job role inside out, including the key skills and behaviours that are required.',
        'Be ready to share relevant examples and experiences, to show your suitability for the role.',
        'Make sure we’re right for you! Research us and have some questions ready to ask. Feel free to bring your notes with you.',
      ],
    },
    {
      title: "Don't let nerves get the better of you",
      innerList: [
        'Try to remain calm and positive.',
        "Take your time, don't rush your answers.",
        'Be yourself – we want to get to know the real you.',
      ],
    },
  ],
};

const ApplicationProcessPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxCharcoal"
        headerImage={headerImage}
        maxHeight="max-h-680"
        styling="pt-15px pl-20px md:pt-66px md:pl-70px lg:pt-59px lg:px-6 max-w-1184"
      />
      <CareersNavigation />
      <TwoColumnIconList shape data={twoColumnIconListData} />
      <TwoColumnListDbl shape={false} data={twoColumnListDblData} />
      <OtherJobs />
    </>
  );
};

export default ApplicationProcessPage;

export const Head: HeadFC = () => (
  <>
    <title>Application process | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
